import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {SearchSpan,TableCheckBox,DialogSaveButton,FilterCheckBox,TableIcon} from '../../../common/Global/globalStyles.style';
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
import Pagination from '../../../common/Global/pagination';
import {getLocalStorageVariables,isEmptyVariable,sortTable,truncateString,
    isEmptyArray,ifEmptyReturnEmptyStr} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import DocumentSamplesListLayout from './listDocumentSample';
import DocumentERPListLayout from './listDocumentERPMaster';
import AlertDialog from '../../../common/AlertDialog';

const userDetails  = getLocalStorageVariables();
const sortShortName = "d.shortName";
const sortName = "d.name";
const sortDescription = "d.description";
const ActiveStatus = "Active";

class DocumentSampleList extends Component{
    constructor(props){
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            resultSizePlaceholder:Constants.RESULT_SIZE,
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            sort:"",
            sortDir:"",

            selectedDocumentMap:{},
            document_NR:"",

            showDetailsDialog:false,
            selectedItem:"",

            showDocumentListing:true,
            showDocumentSamplesListing:false,
            showDocumentERPListing:false,
            selectedDocumentId:"",
            selectedDocumentName:"",
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showDocumentDialog){
            let docMap = {};
            // this.props.selectedDocumentObjArr.map((item)=>{
            //     docMap[item.documentId] = item;
            // })
            this.setState({
                componentDidMountFlag:false,
                apiSearchKey:"",
                searchkey:"",
                currentPageNo: 1,
                showLoader:false,
                resultSize:Constants.RESULT_SIZE,
                totalCount:0,
                datalist:[],
                resultSizePlaceholder:Constants.RESULT_SIZE,
                resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
                sort:"",
                sortDir:"",
                selectedDocumentMap:docMap,
                // document_NR:this.props.document_NR,

                showDetailsDialog:false,
                selectedItem:"",

                showDocumentListing:true,
                showDocumentSamplesListing:false,
                showDocumentERPListing:false,
                selectedDocumentId:"",
                selectedDocumentName:"",
            },()=>{
                this.getDocumentList();
            })
            
        }
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getDocumentList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getDocumentList();
            })
        }
    }

    handleCheck = (e) =>{
        let isChecked = e.target.checked;
        let name = e.target.name;

        if(isChecked){
            let datalist = this.state.datalist;
            datalist.map((item,idx)=>{
                datalist[idx].isSelected=false;
            });

            this.setState({
                [name]:"Y",
                selectedDocumentMap:{},
                datalist:datalist
            })
        }else{
            this.setState({
                [name]:"N"
            })
        }
    }

    handleDetailsDialogClose = () =>{
        this.setState({
            showDetailsDialog:false,
            selectedItem:"",
        });
    }

    handleDetailsDialogShow = (item) => {
        this.setState({
            showDetailsDialog:true,
            selectedItem:item,
        });
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getDocumentList();
        })
    }

    handleSelection = (e) => {
        e.stopPropagation();
        let isChecked = e.target.checked;
        let index = e.target.name;
        let selectedDocArrTemp = this.state.selectedDocumentMap;

        if(isChecked){
            selectedDocArrTemp[this.state.datalist[index].documentId] = this.state.datalist[index];
        }else{
            delete selectedDocArrTemp[this.state.datalist[index].documentId]
        }
        this.state.datalist[index].isSelected = isChecked;

        this.setState({
            selectedDocumentMap:selectedDocArrTemp
        });
    }

    handleStopPropagation = (e) => {
        e.stopPropagation();
    }

    handleShowDocumentSamplesListing = (document,e) => {
        e.stopPropagation();
        this.setState({
            selectedDocumentId:document.documentId,
            selectedDocumentName:document.name,
            showDocumentListing:false,
            showDocumentSamplesListing:true,
            showDocumentERPListing:false,
        })
    }

    handleShowDocumentERPListing = (document,e) => {
        e.stopPropagation();
        this.setState({
            selectedDocumentId:document.documentId,
            selectedDocumentName:document.name,
            showDocumentListing:false,
            showDocumentSamplesListing:false,
            showDocumentERPListing:true,
        })
    }

    handleBackBtnClickFromSamplesandERP = () => {
        this.setState({
            selectedDocumentId:"",
            selectedDocumentName:"",
            showDocumentListing:true,
            showDocumentSamplesListing:false,
            showDocumentERPListing:false,
        })
    }

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getDocumentList();
        })
    }

    returnSelectedDocumentId = () => {
        if(this.state.document_NR === "Y"){
            this.props.handleSelectDocDialogSaveClose([],this.state.document_NR);
        }else{
            let selectedDocumentObjArr= [];
            // let documentFormErrTemp = "";
    
            for (const [key, item] of Object.entries(this.state.selectedDocumentMap)) {
                if(item.isSelected){
                    selectedDocumentObjArr.push({
                        documentId:item.documentId,
                        name:item.name,
                        documentType:item.documentType,
                        // description:item.description, //removed as per client requirement
                        description:"",
                    });
                }else{
                }
            }
    
            if(isEmptyArray(selectedDocumentObjArr)){
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:"Select atleast one document"
                })
            }else{
                this.props.handleSelectDocDialogSaveClose(selectedDocumentObjArr);
            }
        }
        
    }
    /*****************************API*********************************/
    getDocumentList = () => {
        this.setState({
            showLoader:true,
        });
        
        fetch(Constants.GetDocumentMaster,
        {
            method:"POST",
            mode:"cors",
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                status:ActiveStatus,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
                departmentId:this.props.departmentId,
                activeFlag:"Y"
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let dataList = data.data.result;
                dataList.map((item,idx)=>{
                    if(!isEmptyVariable(this.state.selectedDocumentMap[item.documentId])){
                        dataList[idx].isSelected=true;
                    }
                })

                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:dataList,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:[],
                    totalCount:0,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            fetch(Constants.GetDocumentMaster,
            {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.searchkey,
                    status:ActiveStatus,
                    resultsize:this.state.resultSize,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    departmentId:this.props.departmentId,
                    activeFlag:"Y"
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    let dataList = data.data.result
                    dataList.map((item,idx)=>{
                        if(!isEmptyVariable(this.state.selectedDocumentMap[item.documentId])){
                            dataList[idx].isSelected=true;
                        }
                    })

                    this.setState({
                        showLoader:false,
                        componentDidMountFlag:true,
                        datalist:dataList,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        showLoader:false,
                        componentDidMountFlag:true,
                        datalist:[],
                        totalCount:0,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    render(){
        return(
            <section>
                <Modal className="task-select-table-dialog custom-dialog"
                    show={this.props.showDocumentDialog} 
                    backdrop="static">
                    <Modal.Header>
                        <h5>Select Documents</h5>
                        <button 
                            onClick={this.props.handleSelectDocDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        {
                            this.state.showLoader &&
                            <div class="loader"></div>
                        }
                        {
                            // Need to check component did mount flag, other wise until api is called,
                            // it shows no items found layout.
                            this.state.showDocumentListing &&
                            this.state.componentDidMountFlag &&
                            <div className="body-wrapper">
                                <div className="search-addnew-bg" style={{marginTop:0}}>
                                    <div className="search-addnew">
                                        
                                        <div className="search-filter-layout" style={{position:"relative"}}>
                                            <FilterDropDown
                                                placeholder={this.state.resultSizePlaceholder}
                                                dropdownArr={this.state.resultSizeArr}
                                                onDropDownItemClick={this.resultSizeDropdownClick}
                                                addBorder={true}
                                                themeSettings={this.props.themeSettings}
                                                mleft={0}
                                            />
                                            <div className="search-col m-l-10" style={{border:"1px solid #e5e5e5"}}>
                                                <input 
                                                    name="searchkey" 
                                                    type="text"
                                                    onChange={this.handleChangeSearch}
                                                    placeholder="Search" 
                                                    onKeyPress={this.onEnterBtnPress}
                                                    value={this.state.searchkey}
                                                />
                                                <SearchSpan 
                                                themeSettings={this.props.themeSettings}
                                                onClick = {this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                                            </div>

                                           
                                            {/* <div className="overlay-nr" style={{display:this.state.document_NR === "Y"?"block":"none"}}></div> */}
                                        </div>
                                        {/* <FilterCheckBox themeSettings={this.props.themeSettings}
                                        style={{marginLeft:0}}>
                                            <input 
                                                type="checkbox"
                                                name="document_NR"
                                                id="document_NR"
                                                onChange={this.handleCheck}
                                                checked={this.state.document_NR === "Y"?true:false}
                                            />
                                            <label for="document_NR">Not Required</label>
                                        </FilterCheckBox> */}
                                    </div>
                                </div>
                                <div style={{position:"relative"}}>
                                    {
                                        !isEmptyArray(this.state.datalist) && 
                                        <div className="common-tab-bg">
                                            <div className="common-table" style={{width:"100%",border:"1px solid #e5e5e5"}}>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center" width={"8%"}></th>
                                                            <th className="text-center" width={"5%"}>No.</th>
                                                            <th width={"25%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortName)}>
                                                                <div className="sort-header">Name
                                                                <span className={(this.state.sort === sortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDescription)}>
                                                                <div className="sort-header">Description
                                                                <span className={(this.state.sort === sortDescription)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            {
                                                                this.props.subscriptionType === Constants.SUBSCRIPTION_PROFESSIONAL &&
                                                                <th className="text-center"  width={"5%"}>ERP</th>
                                                            }
                                                            <th className="text-center"  width={"10%"}>Samples</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        !isEmptyArray(this.state.datalist) && 
                                                        this.state.datalist.map((item,idx) => {

                                                            return <tr onClick={this.handleDetailsDialogShow.bind(this,item)}>
                                                                <td>
                                                                    <TableCheckBox onClick={this.handleStopPropagation}
                                                                        themeSettings={this.props.themeSettings}>
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={idx}
                                                                            id={item.documentId}
                                                                            onChange={this.handleSelection}
                                                                            checked={item.isSelected?true:false}
                                                                        />
                                                                        <label for={item.documentId} 
                                                                        style={{marginLeft:6}}></label>
                                                                    </TableCheckBox>
                                                                </td>
                                                                <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                                <td>{item.name}</td>
                                                                <td>{truncateString(item.description,Constants.OTHERMASTERS_STRING_LENGTH)}</td>
                                                                {
                                                                    this.props.subscriptionType === Constants.SUBSCRIPTION_PROFESSIONAL &&
                                                                    <TableIcon 
                                                                    themeSettings={this.props.themeSettings} className="text-center">
                                                                        <span class="material-icons" onClick={this.handleShowDocumentERPListing.bind(this, item)}>visibility</span>
                                                                    </TableIcon>
                                                                }
                                                                <TableIcon 
                                                                    themeSettings={this.props.themeSettings} className="text-center">
                                                                    <span class="material-icons" onClick={this.handleShowDocumentSamplesListing.bind(this, item)}>topic</span>
                                                                </TableIcon>
                                                            </tr>
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                        <div class="no-items-layout">
                                            <div class="no-items-card">
                                                <h6>
                                                {
                                                    isEmptyVariable(this.state.apiSearchKey)?
                                                    Constants.NO_RECORDS_WARNING
                                                    :
                                                    Constants.EMPTY_SEARCH_WARNING
                                                }
                                                </h6>
                                            </div>
                                        </div>
                                    }
                                    <div className="pagination-layout">
                                        <div className="row custom-row">
                                            <div className="col-md-9 custom-col">
                                            {
                                                (this.state.totalCount > this.state.datalist.length) && 
                                                <Pagination 
                                                    totalLength ={this.state.totalCount} 
                                                    items={this.state.datalist} 
                                                    onChangePage={this.onChangePage} 
                                                    pageSize={this.state.resultSize}
                                                    currentPageNo = {this.state.currentPageNo}
                                                    initialPage={this.state.currentPageNo}
                                                    themeSettings={this.props.themeSettings} />
                                            }
                                            </div>
                                            <div className="col-md-3 custom-col">
                                                <p>{"Total Records: "+this.state.totalCount}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="overlay-nr" style={{display:this.state.document_NR === "Y"?"block":"none"}}>
                                    </div> */}
                                </div>

                                {/* {
                                    !isEmptyVariable(this.state.documentFormErr) &&
                                    <span className="cm-error">{this.state.documentFormErr}</span>
                                } */}
                                <div className="modal-close-save m-t-15">
                                    <div className="close-save-col">
                                        <button onClick={this.props.handleSelectDocDialogClose} type="button" 
                                        className="modal-close m-b-0">Cancel</button>
                                        <DialogSaveButton  themeSettings={this.props.themeSettings} 
                                        onClick={this.returnSelectedDocumentId} type="button" 
                                        className="modal-save m-b-0">Save</DialogSaveButton>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            this.state.showDocumentSamplesListing &&
                            <DocumentSamplesListLayout
                                documentId={this.state.selectedDocumentId}
                                documentName={this.state.selectedDocumentName}
                                handleBackBtnClickFromSamplesandERP={this.handleBackBtnClickFromSamplesandERP}
                                themeSettings={this.props.themeSettings}
                            />
                        }
                        {
                            this.state.showDocumentERPListing &&
                            <DocumentERPListLayout
                                documentId={this.state.selectedDocumentId}
                                documentName={this.state.selectedDocumentName}
                                handleBackBtnClickFromSamplesandERP={this.handleBackBtnClickFromSamplesandERP}
                                themeSettings={this.props.themeSettings}
                            />
                        }
                    </div>
                </Modal>
                <Modal className="custom-dialog" show={this.state.showDetailsDialog} onHide={this.handleDetailsDialogClose}>
                    <Modal.Header>
                        <h5>{this.state.selectedItem.shortName}</h5>
                        <button 
                            onClick={this.handleDetailsDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div class="modal-body">
                        <div className="details-table-layout">
                            <div className="row custom-row">
                                <div className="col-md-12 custom-col">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td width={"24%"}>Short Name</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.shortName}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Name</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.name}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Function</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.functionName}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Description</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{ifEmptyReturnEmptyStr(this.state.selectedItem.description,"-")}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Department</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.departmentName}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <AlertDialog
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type={Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                    themeSettings={this.props.themeSettings}
                />
            </section>
        )
    }
}

export default DocumentSampleList;