import React, { Component } from "react";
import * as Constants from "../../../../common/Global/constants";
import { getLocalStorageVariables, isEmptyVariable, isEmptyArray } from "../../../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";
import { ExpandCollapseWrapper } from "../../../../common/Global/globalStyles.style";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-navigations/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";

const userDetails = getLocalStorageVariables();
const activeStatus = "Active";
const createdStatus = "Created";
const updatedStatus = "Updated";

const AddChildDropdownObj = {
	icon: "add_circle",
	label: "Add Child",
};
const AddSiblingDropdownObj = {
	icon: "add_box",
	label: "Add Sibling",
};
const editDropdownObj = {
	icon: "edit",
	label: "Edit",
};
const delDropdownObj = {
	icon: "delete",
	label: "Delete",
};
const linkDropdownObj = {
	icon: "link",
	label: "Link",
};

const actionArrRoot = [AddChildDropdownObj, editDropdownObj];
const actionArrNode = [AddChildDropdownObj, AddSiblingDropdownObj, editDropdownObj, delDropdownObj];
const actionArrChild = [AddChildDropdownObj, AddSiblingDropdownObj, editDropdownObj, linkDropdownObj, delDropdownObj];
const actionArrChildNLink = [AddSiblingDropdownObj, editDropdownObj, linkDropdownObj, delDropdownObj];
const actionArrRootNChild = [AddChildDropdownObj, editDropdownObj, linkDropdownObj];
const actionArrRootNChildNLink = [editDropdownObj, linkDropdownObj];

class ProcessTreeDialog extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showLoader: false,

			processMap: {},
			syncFusionData: [],

			showCreateDialog: false,
			isEdit: false,
			createNodeType: "",
			selectedId: "",
			processName: "",
			relativeProcessId: "",
			relativeParentProcessId: "",
			clearAndResetProcessTree: true,
			idToBeExpandedAfterAddingChild: "",

			operationType: "",
			deleteReactivateId: "",
			deleteReactivateParentId: "",
			showAlertWithRadioDialog: false,
			showLinksAlertWithRadioDialog: false,
			alertWithRadioDialogMessage: "",
			DeleteNodeShowRadio: true,

			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",

			showTargetProcessTreeDialog: false,
		};
		this.fields = {};
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showProcessTreeDialog) {
			this.fields = {
				dataSource: [],
				id: "processId",
				text: "processName",
				parentID: "parentId",
				child: "newChildren",
				expanded: "isExpanded",
			};

			this.setState(
				{
					showLoader: false,

					processMap: {},
					syncFusionData: [],

					showCreateDialog: false,
					isEdit: false,
					createNodeType: "",
					selectedId: "",
					processName: "",
					relativeProcessId: "",
					relativeParentProcessId: "",
					clearAndResetProcessTree: true,
					idToBeExpandedAfterAddingChild: "",

					operationType: "",
					deleteReactivateId: "",
					deleteReactivateParentId: "",
					showAlertWithRadioDialog: false,
					showLinksAlertWithRadioDialog: false,
					alertWithRadioDialogMessage: "",

					showAlertDialogInfo: false,
					alertDialogMessageInfo: "",

					showTargetProcessTreeDialog: false,
				},
				() => {
					this.getProcessTree();
				}
			);
		}
	}

	handleAddEditDialogClose = (reloadFlag) => {
		let id = "";
		if (this.state.createNodeType === "child") id = this.state.relativeProcessId;
		this.setState(
			{
				showCreateDialog: false,
				clearAndResetProcessTree: false,
				isEdit: false,
				createNodeType: "",
				selectedId: "",
				processName: "",
				relativeProcessId: "",
				relativeParentProcessId: "",
				idToBeExpandedAfterAddingChild: id,
			},
			() => {
				if (reloadFlag === true) this.getProcessTree();
			}
		);
	};

	handleTargetProcessTreeDialogClose = (reloadFlag) => {
		this.setState(
			{
				showTargetProcessTreeDialog: false,
				deleteReactivateId: "",
			},
			() => {
				if (reloadFlag === true) this.getProcessTree();
			}
		);
	};

	onDropDownItemClick = (item, processObj) => {
		if (item.label === "Add Child") {
			this.setState({
				showCreateDialog: true,
				createNodeType: "child",
				relativeProcessId: processObj.processId,

				isEdit: false,
			});
		} else if (item.label === "Add Sibling") {
			this.setState({
				showCreateDialog: true,
				createNodeType: "sibling",
				relativeProcessId: processObj.processId,
				relativeParentProcessId: processObj.parentId,
				isEdit: false,
			});
		} else if (item.label === "Delete") {
			if (this.state.processMap[processObj.processId].hasChildren) {
				//Since it has children show the radio buttons, and ask user
				//whether to delete the children or move the children to parent
				this.setState({
					operationType: item.label,
					deleteReactivateId: processObj.processId,
					deleteReactivateParentId: processObj.parentProcessId,
					alertWithRadioDialogMessage: "Are you sure you want to delete " + processObj.processName + "?",

					showAlertWithRadioDialog: true,
					DeleteNodeShowRadio: true,
				});
			} else {
				if (processObj.processId === this.props.rootProcessId && processObj.linkCount > 0) {
					//root process will have usually link count 0,
					//root process is both parent and child, and it is linked to other process then
					//it will have linkCount > 0
				} else if (processObj.linkCount > 1) {
					this.setState({
						operationType: item.label,
						deleteReactivateId: processObj.processId,
						deleteReactivateParentId: processObj.parentProcessId,
						alertWithRadioDialogMessage: "Are you sure you want to delete " + processObj.processName + "?",

						showLinksAlertWithRadioDialog: true,
						DeleteNodeShowRadio: true,
					});
				} else {
					//Node with no children and no links
					this.setState({
						operationType: item.label,
						deleteReactivateId: processObj.processId,
						deleteReactivateParentId: processObj.parentProcessId,
						alertWithRadioDialogMessage: "Are you sure you want to delete " + processObj.processName + "?",

						showAlertWithRadioDialog: true,
						DeleteNodeShowRadio: false,
					});
				}
			}
		} else if (item.label === "Edit") {
			this.setState({
				showCreateDialog: true,
				createNodeType: "sibling",
				relativeProcessId: processObj.processId,

				isEdit: true,
				selectedId: processObj.processId,
				processName: processObj.processName,
			});
		} else if (item.label === "Link") {
			this.setState({
				showTargetProcessTreeDialog: true,
				deleteReactivateId: processObj.processId,
			});
		}
	};

	handleChange = (e) => {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	};

	handleAlertWithDialogDialogClose = () => {
		this.setState({
			showAlertWithRadioDialog: false,
			alertWithRadioDialogMessage: "",
		});
	};

	handleLinkAlertWithDialogDialogClose = () => {
		this.setState({
			showLinksAlertWithRadioDialog: false,
			alertWithRadioDialogMessage: "",
		});
	};

	handleAlertDialogCloseInfo = () => {
		this.setState({
			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",
		});
	};

	getRootLevelProcesses = (processMapTemp) => {
		let rootProcessArr = [];
		Object.values(processMapTemp).map((item) => {
			//this is backend level, it starts from 0, but front end level starts from 1
			if (item.level === 0) {
				rootProcessArr.push(item);
			}
		});
		return rootProcessArr;
	};

	//No need of return value since the array itself is passed and updated.
	//Javascript maintains single array with pointers. it will not deep copy the array
	addParentIdandHasChildrenFlag = (tempArr, processId, level) => {
		tempArr[processId].level = level;

		if (!isEmptyArray(tempArr[processId].children)) {
			tempArr[processId].hasChildren = true;
			tempArr[processId].children.map((childNode) => {
				this.addParentIdandHasChildrenFlag(tempArr, childNode.processId, level + 1);
			});
		} else {
			tempArr[processId].hasChildren = false;
		}
	};

	createSyncHRFusionData = (parentId, processId, processMap, isExpanded) => {
		//Now create an object
		let processObj = processMap[processId];
		processObj.newChildren = [];
		processObj.isExpanded = isExpanded;
		if (parentId !== processId) {
			processObj.parentId = parentId;
		}
		//now add children recursively
		processObj.children.map((item) => {
			processObj.newChildren.push(this.createSyncHRFusionData(processId, item.processId, processMap, false));
		});
		return processObj;
	};

	updateSyncHRFusionData = (parentId, processId, processMap) => {
		//Now create an object
		let processObj = processMap[processId];
		processObj.newChildren = [];

		//Check the old process map
		if (!isEmptyVariable(this.state.processMap[processId])) {
			processObj.isExpanded = this.state.processMap[processId].isExpanded;
			processMap[processId].isExpanded = this.state.processMap[processId].isExpanded;
		}

		if (parentId !== processId) {
			processObj.parentId = parentId;
		}
		//now add children recursively
		processObj.children.map((item) => {
			processObj.newChildren.push(this.updateSyncHRFusionData(processId, item.processId, processMap));
		});
		return processObj;
	};

	ProcessTree = (processObj) => {
		console.log(JSON.stringify(processObj));
		return (
			<div className="dialog-tree-structure-sync">
				{!isEmptyVariable(processObj) && (
					<div className="tree-node-sync">
						<div className="tree-text-layout">
							<p className={processObj.fontbold ? "font-bold" : ""}>{processObj.processName}</p>
							{(processObj.status === createdStatus || processObj.status === updatedStatus) && <span className="new-tree-tag">New</span>}
						</div>
					</div>
				)}
			</div>
		);
	};

	expandAll = () => {
		this.reference.expandAll();
	};

	collapseAll = () => {
		this.reference.collapseAll();
	};

	dragStop = (args) => {
		// console.log("Drag Node: "+JSON.stringify(args.draggedNodeData))
		// console.log("Drop Node: "+JSON.stringify(args.droppedNodeData))
		// console.log("Drop Level: "+args.dropLevel)

		//if the node is dropped above the parent node then cancel the drag
		if (args.dropLevel === 1) {
			args.cancel = true;
		}
	};
	nodeExpanded = (args) => {
		this.state.processMap[args.nodeData.id].isExpanded = true;
	};
	nodeCollapsed = (args) => {
		this.state.processMap[args.nodeData.id].isExpanded = false;
	};
	nodeDrop = (args) => {
		console.log("Drag Node: " + JSON.stringify(args.draggedNodeData));
		console.log("Drop Node: " + JSON.stringify(args.droppedNodeData));
		console.log("Drop Index: " + args.dropIndex);
		console.log("Drop Level: " + args.dropLevel);

		let draggedNodeId = "";
		let draggedNodePid = "";

		let droppedNodeid = "";
		let droppedNodePid = "";
		let droppedPos = "";
		let dropLevel = "";

		if (!isEmptyVariable(args.draggedNodeData) && !isEmptyVariable(args.droppedNodeData)) {
			draggedNodeId = args.draggedNodeData.id;
			draggedNodePid = args.draggedNodeData.parentID;

			droppedNodeid = args.droppedNodeData.id;
			droppedNodePid = args.droppedNodeData.parentID;
			droppedPos = args.dropIndex + 1;
			dropLevel = args.dropLevel;

			// now check what is the level of drop id
			let dropRelativeNodeLevel = this.state.processMap[droppedNodeid].level;
			console.log("Drop R Level: " + dropRelativeNodeLevel);
			//dropped level is greater than relative node, then it is a child of that relative node
			//hence parent id is relative node id
			if (dropLevel > dropRelativeNodeLevel) {
				droppedNodePid = droppedNodeid;
			}

			this.moveTask(draggedNodeId, draggedNodePid, droppedNodePid, droppedPos);
		}
	};

	/************************API CALLS **************************/
	moveTask = (draggedNodeId, draggedNodePid, droppedNodePid, droppedPos) => {
		fetch(Constants.MoveProcess, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				rootProcessId: this.props.rootProcessId,
				processId: draggedNodeId,
				fromProcessId: draggedNodePid,
				toProcessId: droppedNodePid,
				toChildOrder: droppedPos,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else {
					this.getProcessTree();
				}
			});
	};

	getProcessTree = () => {
		this.setState({
			showLoader: true,
		});

		fetch(Constants.GetProcessesSubtree, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				rootProcessId: this.props.rootProcessId,
				departmentId:this.props.rootNode.departmentId,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					let temp = data.result.processMap;
					let rootProcessArr = this.getRootLevelProcesses(temp);

					rootProcessArr.map((rootNode) => {
						this.addParentIdandHasChildrenFlag(temp, rootNode.processId, 1);
					});

					let syncFusionData = [];
					//clearAndResetProcessTree - if it is false, then copy the isShowingChildren param
					//from old state array, this is required because whenever the user adds a new node at the nth level
					//all the nodes are collapsed and shows only first level nodes since the API is called again
					if (!isEmptyArray(Object.keys(this.state.processMap)) && !this.state.clearAndResetProcessTree) {
						let processObj = this.updateSyncHRFusionData(this.props.rootProcessId, this.props.rootProcessId, temp);
						processObj.fontbold = true;
						syncFusionData.push(processObj);
					} else {
						//create datastructure and Expand root level node's children by default
						let processObj = this.createSyncHRFusionData(this.props.rootProcessId, this.props.rootProcessId, temp, true);
						temp[this.props.rootProcessId].isExpanded = true;
						processObj.fontbold = true;
						syncFusionData.push(processObj);
					}
					//set the tree fields
					this.fields = {
						dataSource: syncFusionData,
						id: "processId",
						text: "processName",
						parentID: "parentId",
						child: "newChildren",
						expanded: "isExpanded",
					};

					this.setState(
						{
							processMap: temp,
							idToBeExpandedAfterAddingChild: "",
							syncFusionData: syncFusionData,
							showLoader: false,
							clearAndResetProcessTree: false,
						},
						() => {
							this.reference.refresh();
						}
					);
				} else {
					this.setState({
						processMap: {},
						showLoader: false,
						syncFusionData: [],
						clearAndResetProcessTree: false,
					});
				}
			});
	};

	render() {
		return (
			<div>
				<Modal className="show-process-tree-dialog custom-dialog" show={this.props.showProcessTreeDialog} onHide={this.props.handleProcessTreeDialogClose}>
					<Modal.Header>
						<h5>Process Tree</h5>
						<button onClick={this.props.handleProcessTreeDialogClose} type="button" data-dismiss="modal">
							<span class="material-icons">close</span>
						</button>
					</Modal.Header>
					<hr />
					<div className="modal-body">
						{this.state.showLoader && (
							<div class="loader"></div>
						)}
						<ExpandCollapseWrapper themeSettings={this.props.themeSettings}>
							<div className="expand-collapse-layout" onClick={this.expandAll} style={{ marginRight: 10 }} type="button">
								<span class="material-icons">unfold_more</span>
								<p>Expand All</p>
							</div>
							<div className="expand-collapse-layout" onClick={this.collapseAll} style={{ marginLeft: 10 }} type="button">
								<span class="material-icons">unfold_less</span>
								<p>Collapse All</p>
							</div>
						</ExpandCollapseWrapper>

						<TreeViewComponent
							fields={this.fields}
							allowDragAndDrop={false}
							nodeTemplate={this.ProcessTree}
							ref={(treeNode) => {
								this.reference = treeNode;
							}}
							nodeDragStop={this.dragStop}
							nodeDropped={this.nodeDrop}
							nodeExpanded={this.nodeExpanded}
							nodeCollapsed={this.nodeCollapsed}
						/>
					</div>
				</Modal>
			</div>
		);
	}
}

export default ProcessTreeDialog;
