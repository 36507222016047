import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import { matchPath, withRouter } from 'react-router-dom';
import { isEmptyVariable, getLocalStorageVariables,getThemeSettingsVariables, isEmptyArray } from '../../../common/Global/commonFunctions';
import { TopTabsLayout } from '../../../common/Global/globalStyles.style';
import AlertDialog from '../../../common/AlertDialog';
const allPrivilege = "ALL";
const viewPrivilege = "VIEW";

let isExecute = '';
let isFollowUp = '';
let isTasks = '';
let isSampleChecked = '';
let isObservations ='';
let isWorkingPapers = '';
let isAuditee = '';
let isOverall = '';
let themeColor = ``;
let isTimeline = '';
const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();
class MasterTopMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignmentDetails:{},
            showDetailsDialog: false,
			selectedItem: "",
            showAlertDialog:false,
            alertDialogMessage:"",
            screenName:""
        }

        isExecute = this.checkActivePath("/executeassignmentlist")
        isFollowUp = this.checkActivePath("/executefollowups")
        isTasks = this.checkActivePath("/executetasklist");
        isObservations = this.checkActivePath([ "/observationlist","/addobservation","/editobservation" ]);
        isSampleChecked = this.checkActivePath("/samplecheckedlist");
        isWorkingPapers = this.checkActivePath("/workingpaperslist" );
        isAuditee = this.checkActivePath("/assignmentemployee");
        isOverall = this.checkActivePath("/overallexecutetasklist");
        isTimeline = this.checkActivePath("/timeline");
    }
    
    checkActivePath = (path) =>{
        const themeSettings = getThemeSettingsVariables();
        themeColor = `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`;
       return matchPath(this.props.history.location.pathname, { path });
    }

	handleDetailsDialogShow = (item) => {
		this.setState({
			showDetailsDialog: true,
			selectedItem: item,
		});
	};

    handleAlertDialogOkayButtonClick  = () => {
        this.props.saveTimeLineData();
        let screenName = this.state.screenName;
        if(screenName==="Tasks"){
            window.location= Constants.URL_EXECUTETASK +
                              "/" +
                              this.props.assignmentId
        }else if(screenName==="Observations"){
            window.location= Constants.URL_OBSERVATIONLIST +
                              "/" +
                              this.props.assignmentId
        }else if(screenName==="Sample Checked"){
            window.location= Constants.URL_SAMPLECHECKEDLIST +
                              "/" +
                              this.props.assignmentId
        }else if(screenName==="Working Papers"){
            window.location= Constants.URL_WORKINGPAPERSLIST +
                              "/" +
                              this.props.assignmentId
        }else if(screenName==="Auditee"){
            window.location= Constants.URL_ASSIGNMENTEMPLOYEE +
                              "/" +
                              this.props.assignmentId
        }
    }

    checkChangeUrl = (screenName) => {
        this.setState({
            screenName:screenName
        })
       if(!isEmptyArray(this.props.timeLineData)){
            let timeLineDataFilter = this.props.timeLineData.filter(e => e.isEditable == true && !isEmptyVariable(e.timelineType) && !isEmptyVariable(e.startDate));
            if(timeLineDataFilter.length > 0){
                this.setState({
                    showAlertDialog:true,
                    alertDialogMessage:"There is unsaved data. Do you want to save the data?"
                })
            }else{
                if(screenName==="Tasks"){
                    window.location= Constants.URL_EXECUTETASK +
                                    "/" +
                                    this.props.assignmentId
                }else if(screenName==="Observations"){
                    window.location= Constants.URL_OBSERVATIONLIST +
                                    "/" +
                                    this.props.assignmentId
                }else if(screenName==="Sample Checked"){
                    window.location= Constants.URL_SAMPLECHECKEDLIST +
                                    "/" +
                                    this.props.assignmentId
                }else if(screenName==="Working Papers"){
                    window.location= Constants.URL_WORKINGPAPERSLIST +
                                    "/" +
                                    this.props.assignmentId
                }else if(screenName==="Auditee"){
                    window.location= Constants.URL_ASSIGNMENTEMPLOYEE +
                                    "/" +
                                    this.props.assignmentId
                }
            }
       }else{
            if(screenName==="Tasks"){
                window.location= Constants.URL_EXECUTETASK +
                "/" + this.props.assignmentId
            }else if(screenName==="Observations"){
                window.location= Constants.URL_OBSERVATIONLIST +
                "/" + this.props.assignmentId
            }else if(screenName==="Sample Checked"){
                window.location= Constants.URL_SAMPLECHECKEDLIST + 
                "/" + this.props.assignmentId
            }else if(screenName==="Working Papers"){
                window.location= Constants.URL_WORKINGPAPERSLIST +
                "/" + this.props.assignmentId
            }else if(screenName==="Auditee"){
                window.location= Constants.URL_ASSIGNMENTEMPLOYEE +
                "/" + this.props.assignmentId
            }
        }
    }

    handleAlertDialogClose = () => {
        let screenName = this.state.screenName;
        if(screenName==="Tasks"){
            window.location= Constants.URL_EXECUTETASK +
                              "/" +
                              this.props.assignmentId
        }else if(screenName==="Observations"){
            window.location= Constants.URL_OBSERVATIONLIST +
                              "/" +
                              this.props.assignmentId
        }else if(screenName==="Sample Checked"){
            window.location= Constants.URL_SAMPLECHECKEDLIST +
                              "/" +
                              this.props.assignmentId
        }else if(screenName==="Working Papers"){
            window.location= Constants.URL_WORKINGPAPERSLIST +
                              "/" +
                              this.props.assignmentId
        }else if(screenName==="Auditee"){
            window.location= Constants.URL_ASSIGNMENTEMPLOYEE +
                              "/" +
                              this.props.assignmentId
        }
        
    }

    render() {
        let width = 12.5;
        if (!isEmptyVariable(this.props.masterMenus)) {
            width = 12.5 * Object.keys(this.props.masterMenus).length
        }
        if (!isEmptyVariable(this.props.masterExecuteMenus)) {
            width = 12.5 * Object.keys(this.props.masterExecuteMenus).length
        }


        return (
            <TopTabsLayout themeSettings={this.props.themeSettings} style={{marginTop:5}}>
                {this.state.showLoader && (
                    <div class="loader"></div>
                )}
                <div className="question-tabs" style={{ marginRight: 0 }}>
                    <ul style={{ width: width + "%" }}>
                        {
                        !isEmptyVariable(this.props.masterExecuteMenus) &&
                        Object.keys(this.props.masterExecuteMenus).map(key => {
                        return <div>
                            {
                                key === Constants.SCREEN_EXECUTE &&
                                    <li
                                        className={isExecute? "questiontab-active" : "" ? "questiontab-active" : ""}>
                                        <a href={Constants.URL_EXECUTEASSIGNMENTS} 
                                        style={{color: !isExecute ? themeColor : '', fontWeight: !isExecute ? "bold" : "100px"}}>Execute</a>
                                    </li>
                            }
                            {
                                ((key === Constants.SCREEN_EXECUTE_FOLLOW_UP_ASSIGNMENT &&
                                (this.props.masterExecuteMenus[key] === allPrivilege || this.props.masterExecuteMenus[key] === viewPrivilege))) &&
                                    <li
                                        className={isFollowUp? "questiontab-active" : "" ? "questiontab-active" : ""}>
                                        <a href={Constants.URL_EXECUTEFOLLOWUP}
                                        style={{color: !isFollowUp ? themeColor : '', fontWeight: !isFollowUp ? "bold" : "100px"}}>Follow Up</a>
                                    </li>
                            }
                            </div>
                        })}
                    </ul>
                    {
                        !isEmptyVariable(this.props.masterMenus) &&
                        <ul style={{ width: width + "%" }}>
                            <li className={isTasks? "questiontab-active" : "" ? "questiontab-active" : ""}>
                                <a href={Constants.URL_EXECUTETASK+"/"+this.props.assignmentId} 
                                    style={{color: !isTasks ? themeColor : '', fontWeight: !isTasks ? "bold" : "100px"}}>Tasks</a>
                            </li>
                            {
                                !isEmptyVariable(this.props.masterMenus?.Observations) &&
                                <li className={isObservations? "questiontab-active" : "" ? "questiontab-active" : ""}>
                                    <a href={Constants.URL_OBSERVATIONLIST+"/"+this.props.assignmentId} 
                                        style={{color: !isObservations ? themeColor : '', fontWeight: !isObservations ? "bold" : "100px"}}>Observations</a>
                                </li>
                            }
                            <li className={isSampleChecked? "questiontab-active" : "" ? "questiontab-active" : ""}>
                                <a href={Constants.URL_SAMPLECHECKEDLIST+"/"+this.props.assignmentId} 
                                    style={{color: !isSampleChecked ? themeColor : '', fontWeight: !isSampleChecked ? "bold" : "100px"}}>Sample Checked</a>
                            </li>
                            <li className={isWorkingPapers? "questiontab-active" : "" ? "questiontab-active" : ""}>
                                <a href={Constants.URL_WORKINGPAPERSLIST+"/"+this.props.assignmentId} 
                                    style={{color: !isWorkingPapers ? themeColor : '', fontWeight: !isWorkingPapers ? "bold" : "100px"}}>Working Papers</a>
                            </li>
                            <li className={isAuditee? "questiontab-active" : "" ? "questiontab-active" : ""}>
                                <a href={Constants.URL_ASSIGNMENTEMPLOYEE+"/"+this.props.assignmentId} 
                                    style={{color: !isAuditee ? themeColor : '', fontWeight: !isAuditee ? "bold" : "100px"}}>Auditee</a>
                            </li>
                            <li className={isTimeline? "questiontab-active" : "" ? "questiontab-active" : ""}>
                                <a href={Constants.URL_TIMELINE+"/"+this.props.assignmentId}
                                    style={{color: !isTimeline ? themeColor : '', fontWeight: "bold"}}>Timeline</a>
                            </li>
                            {!isEmptyVariable(this.props.masterMenus['Assignment Level Template']) && !isEmptyVariable(this.props.assignmentLevelTemplateId) &&
                                <li
                                    className={isOverall? "questiontab-active" : "" ? "questiontab-active" : ""}>
                                    <a href={Constants.URL_OVERALL_EXECUTETASK+"/"+this.props.assignmentId}
                                    style={{color: !isOverall ? themeColor : '', fontWeight: "bold"}}>Overall</a>
                                </li>
                            }
                        </ul>
                    }
                </div>
                <AlertDialog
                    showAlertDialog={this.state.showAlertDialog}
                    handleAlertDialogClose={this.handleAlertDialogClose}
                    type={Constants.ALERT_TYPE_WARNING}
                    alertDialogMessage={this.state.alertDialogMessage}
                    proceedBtnClick={this.handleAlertDialogOkayButtonClick}
                    proceedBtnLabel={"Yes"}
                    themeSettings={themeSettings}
                />
                <div style={{ opacity: this.props.hideInfo ? "0" : "1" }} className="infocard-showhide-col">
                    
                </div>
            </TopTabsLayout>
        )
    }
}

export default withRouter(MasterTopMenu);