import React, { Component } from "react";
import * as Constants from "../../../../common/Global/constants";
import { TableCheckBox, DialogSaveButton } from "../../../../common/Global/globalStyles.style";
import { getLocalStorageVariables, isEmptyVariable, isEmptyArray, removeHtmlTags } from "../../../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";

const userDetails = getLocalStorageVariables();

class TagTasksList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,

			currentPageNo: 1,
			showLoader: false,
			resultSize: Constants.RESULT_SIZE,

			datalist: [],
		};
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showOtherMasterDialog) {
			let docMap = {};

			this.setState(
				{
					componentDidMountFlag: false,
					showLoader: false,

					datalist: [],
					selectedDocumentMap: docMap,
				},()=>{
					this.GetExecuteTasksList();
				}
			);
		}
	}



	/*****************************API*********************************/
	GetExecuteTasksList = () => {
		this.setState({
			showLoader: true,
		});

		let url = "";
		// if(this.props.isDraftOnward === true){
		// 	url = Constants.GetDraftReportsTasksAll;
		// }else{
		// 	url = Constants.GetReportsTasksAll;
		// }
		
		if(this.props.isDraftOnward === true && !this.props.isInitDraftReport){
			url = Constants.GetDraftReportsTasksAll;
		}else if(!this.props.isDraftOnward && !this.props.isInitDraftReport){
			url = Constants.GetReportsTasksAll;
		}else if( this.props.isInitDraftReport){
			url = Constants.GetInitDraftReportsTasksAll;
		}

		fetch(url, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				assignmentId: this.props.assignmentId,
				observationId: this.props.observationId,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					let dataList = [];
					if(this.props.isDraftOnward === true){
						
						let processTaskList = data.data.assignedTasks;
						let taskMap = data.data.taskMap;

						processTaskList.forEach((item)=>{
							let taskArr = [];
							item.taskList.forEach((taskItem)=>{
								taskItem.task = taskMap[taskItem.taskId];
								taskArr.push(taskItem);
							});
							item.taskList = taskArr;
							dataList.push(item);
						});

					}else{

						dataList = data.data.assignedTasks;
					}

					let dataListNew = [];
					dataList.forEach((processObj) => {
						let taskArrNew = [];
						processObj.taskList.forEach((taskItem) => {
							// if (this.props.selectedTagTasksObjArr.indexOf(taskItem.taskId) !== -1) {
								if(taskItem.observationId+""===this.props.observationId+""){
								taskItem.isTagged = true;
								taskArrNew.push(taskItem);
							}
						});
						processObj.addedTasks = taskArrNew;
						dataListNew.push(processObj);
					});

					console.log(JSON.stringify(this.props.selectedTagTasksObjArr))
					console.log(this.props.observationId)

					this.setState({
						showLoader: false,
						componentDidMountFlag: true,
						datalist: dataListNew,
					});
				} else {
					this.setState({
						showLoader: false,
						componentDidMountFlag: true,
						datalist: [],
					});
				}
			});
	};

	/*****************************API ENDS HERE*********************************/
	render() {
		return (
			<section>
				<Modal className="task-select-table-dialog custom-dialog" show={this.props.showOtherMasterDialog} onHide={this.props.handleSelectOtherMasterDialogClose}>
					<Modal.Header>
						<h5>{this.props.masterType}</h5>
						<button onClick={this.props.handleSelectOtherMasterDialogClose} type="button" data-dismiss="modal">
							<span class="material-icons">close</span>
						</button>
					</Modal.Header>
					<hr />
					<div className="modal-body">
						{this.state.showLoader && (
							<div class="loader"></div>
						)}
						{
							// Need to check component did mount flag, other wise until api is called,
							// it shows no items found layout.
							this.state.componentDidMountFlag && (
								<div className="body-wrapper">
									<div style={{ position: "relative" }}>
										{!isEmptyArray(this.state.datalist) &&
											this.state.datalist.map((item, index) => {
												if(!isEmptyArray(item.addedTasks))
												return (
													<div>
														{/* <h6 style={{ marginTop: index === 0 ? 15 : 10 }}>{item.processName}</h6> */}
                                                        <h6 style={{ marginTop: index === 0 ? 15 : 10 }} className="small-h6">
                                                            {
                                                                isEmptyVariable(item.processName)
                                                                ?(isEmptyVariable(item.functionName)
                                                                    ?(item.heading)
                                                                    :item.functionName)
                                                                :item.processName
                                                            }
                                                        </h6>
														<div className="common-tab-bg">
															<div className="common-table" style={{ width: "100%", border: "1px solid #e5e5e5" }}>
																<table className="table">
																	<thead>
																		<tr>
																			<th className="text-center" width={"8%"}></th>
																			<th className="text-center" width={"5%"}>
																				No.
																			</th>
																			<th>Task Name</th>
																			{this.props.isRisk && <th width={"5%"}>Risk</th>}
																		</tr>
																	</thead>
																	<tbody>
																		{
																			// !isEmptyArray(this.state.datalist) &&
																			// 	this.state.datalist.map((item, index) => {
																			// 		return (
																					!isEmptyArray(item.addedTasks) &&
																					item.addedTasks.map((taskItem, idx) => {
																						if(taskItem.observationId+""===this.props.observationId+"") 
																						return (
																					<tr>
																						<td>
																							<TableCheckBox themeSettings={this.props.themeSettings}>
																								<input
																									type="checkbox"
																									// name={ index}
																									name={idx + ":-:" + index}
																									id={taskItem.taskId}
																									checked={taskItem.isTagged ? true : false}
																								/>
																								<label for={taskItem.taskId} style={{ marginLeft: 6 }}></label>
																							</TableCheckBox>
																						</td>
																						<td className="text-center">
																							{(this.state.currentPageNo - 1) * this.state.resultSize + ++idx}
																						</td>
																						<td>{removeHtmlTags(taskItem.task)}</td>
																						{this.props.isRisk &&
																							<td>
																								{
																									isEmptyVariable(taskItem.riskLevel) ? "-" : (
																									<div className="flex-center-layout">
																										{isEmptyVariable(taskItem.colorCode) ? taskItem.riskLevel:""}
																										
																										{
																											!isEmptyVariable(taskItem.colorCode) &&
																											<div
																												className="risk-level-color-wrapper-round"
																												style={{ width: "38px",marginLeft: (!isEmptyVariable(taskItem.colorCode) ?0:8)}}
																											>
																												<div
																													className="risk-level-color-round"
																													style={{ background: taskItem.colorCode }}
																												/>
																											</div>
																										}
																									</div>
																								)}
																							</td>
																						}
																					</tr>
																				);
																			})
																		}
																	</tbody>
																</table>
															</div>
														</div>
													</div>
                                                    
                                                 );
											})} 
										{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
											<div class="no-items-layout">
												<div class="no-items-card">
													<h6>{isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}</h6>
												</div>
											</div>
										)}
									</div>
									{/* {!isEmptyVariable(this.state.selectAtleastOneTaskErr) && <span className="cm-error">{this.state.selectAtleastOneTaskErr}</span>} */}
									<div className="modal-close-save m-t-15">
										<div className="close-save-col">
											<button onClick={this.props.handleSelectOtherMasterDialogClose} type="button" className="modal-close m-b-0">
												Cancel
											</button>
										</div>
									</div>
								</div>
							)
						}
					</div>
				</Modal>
			</section>
		);
	}
	// render() {
	// 	return (
	// 		<section>
	// 			<Modal className="task-select-table-dialog custom-dialog" show={this.props.showOtherMasterDialog} onHide={this.props.handleSelectOtherMasterDialogClose}>
	// 				<Modal.Header>
	// 					<h5>{this.props.masterType}</h5>
	// 					<button onClick={this.props.handleSelectOtherMasterDialogClose} type="button" data-dismiss="modal">
	// 						<span class="material-icons">close</span>
	// 					</button>
	// 				</Modal.Header>
	// 				<hr />
	// 				<div className="modal-body">
	// 					{this.state.showLoader && (
	// 						<div className="modal-loading-text">
	// 							<p
	// 								style={{
	// 									background: `rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
	// 									color: `rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`,
	// 								}}
	// 							>
	// 								Loading....
	// 							</p>
	// 						</div>
	// 					)}
	// 					{
	// 						// Need to check component did mount flag, other wise until api is called,
	// 						// it shows no items found layout.
	// 						this.state.componentDidMountFlag && (
	// 							<div className="body-wrapper">
	// 								<div style={{ position: "relative" }}>
	// 									{!isEmptyArray(this.state.datalist) &&
	// 										this.state.datalist.map((item, index) => {
	// 											return (
    //                                                 !isEmptyArray(item.taskList)?
	// 												<div>
	// 													{/* <h6 style={{ marginTop: index === 0 ? 15 : 10 }}>{item.processName}</h6> */}
    //                                                     <h6 style={{ marginTop: index === 0 ? 15 : 10 }} className="small-h6">
    //                                                         {
    //                                                             isEmptyVariable(item.processName)
    //                                                             ?(isEmptyVariable(item.functionName)
    //                                                                 ?(item.heading)
    //                                                                 :item.functionName)
    //                                                             :item.processName
    //                                                         }
    //                                                     </h6>
	// 													<div className="common-tab-bg">
	// 														<div className="common-table" style={{ width: "100%", border: "1px solid #e5e5e5" }}>
	// 															<table className="table">
	// 																<thead>
	// 																	<tr>
	// 																		<th className="text-center" width={"8%"}></th>
	// 																		<th className="text-center" width={"5%"}>
	// 																			No.
	// 																		</th>
	// 																		<th>Task Name</th>
	// 																		<th width={"27%"}>Risk</th>
	// 																	</tr>
	// 																</thead>
	// 																<tbody>
	// 																	{
	// 																		!isEmptyArray(item.taskList) &&
	// 																		item.taskList.map((taskItem, idx) => {
	// 																			if(taskItem.observationId+""===this.props.observationId+"")
	// 																			return (
	// 																				<tr>
	// 																					<td>
	// 																						<TableCheckBox themeSettings={this.props.themeSettings}>
	// 																							<input
	// 																								type="checkbox"
	// 																								name={idx + ":-:" + index}
	// 																								id={taskItem.taskId}
	// 																								checked={taskItem.isTagged ? true : false}
	// 																							/>
	// 																							<label for={taskItem.taskId} style={{ marginLeft: 6 }}></label>
	// 																						</TableCheckBox>
	// 																					</td>
	// 																					<td className="text-center">
	// 																						{(this.state.currentPageNo - 1) * this.state.resultSize + ++idx}
	// 																					</td>
	// 																					<td>{removeHtmlTags(taskItem.task)}</td>
	// 																					<td>
	// 																						{isEmptyVariable(taskItem.riskLevel) ? null : (
	// 																							<div className="flex-center-layout">
	// 																								{taskItem.riskLevel}
	// 																								<div
	// 																									className="risk-level-color-wrapper"
	// 																									style={{ width: "38px" }}
	// 																								>
	// 																									<div
	// 																										className="risk-level-color"
	// 																										style={{ background: taskItem.colorCode }}
	// 																									/>
	// 																								</div>
	// 																							</div>
	// 																						)}
	// 																					</td>
	// 																				</tr>
	// 																			);
	// 																		})
	// 																	}
	// 																</tbody>
	// 															</table>
	// 														</div>
	// 													</div>
	// 												</div>
    //                                                 :
    //                                                 <div/>
    //                                             );
	// 										})}
	// 									{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
	// 										<div class="no-items-layout">
	// 											<div class="no-items-card">
	// 												<h6>{isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}</h6>
	// 											</div>
	// 										</div>
	// 									)}
	// 								</div>
	// 								{!isEmptyVariable(this.state.selectAtleastOneTaskErr) && <span className="cm-error">{this.state.selectAtleastOneTaskErr}</span>}
	// 								<div className="modal-close-save m-t-15">
	// 									<div className="close-save-col">
	// 										<button onClick={this.props.handleSelectOtherMasterDialogClose} type="button" className="modal-close m-b-0">
	// 											Cancel
	// 										</button>
	// 									</div>
	// 								</div>
	// 							</div>
	// 						)
	// 					}
	// 				</div>
	// 			</Modal>
	// 		</section>
	// 	);
	// }
}

export default TagTasksList;
