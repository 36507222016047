import React, { Component } from 'react'
import Excel from "exceljs";
import RegularDD from '../../../common/DropdownMenus/RegularDropdown';
import { isEmptyVariable } from '../../../common/Global/commonFunctions';
import { LineChart, Line, ResponsiveContainer } from 'recharts';
let fixedName = '';
let isSpecialCase = false;
const specialCriteriaList = ["clauseWithNcrTable","AuditCycleBase","BUAndNCBased","BUAndObsBased","AuditWithNC","reviewEffectiveness","projectWiseQms"];
const displatPercentageList = ["ProcessAndClauseBase","ProcessAndFunctionBase","BUBased","ProcessBase","auditCycleCompliance","projectWiseQms"]
export default class DashboardDisplayTable extends Component {
  constructor(props) {
      super(props);
      this.state = {
        componentDidMountFlag: false,
        isShowPercentage: displatPercentageList.includes(this.props.fixedName),
        series: [],
        auditComplianceLevalData:[],
        sortColumnIndexAudit: null,
        sortColumnIndexAuditCompliance: null,
        sortOrderAudit: "asc",
        sortOrderAuditCompliance: "asc",
    }
  }

  componentDidMount() {
    this.generateTable(this.props.datalist);
  }

  componentDidUpdate(prevProps) {
    if(JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.generateTable(this.props.datalist);
    }
  }
    
  //* This function generates a Table based on the data provided through parameter.
  generateTable = (datalist)=>{
    let tmpAuditComplianceData =[];
    let tempData = [];

    if(datalist.length>0){
      fixedName = this.props.fixedName;
      isSpecialCase =specialCriteriaList.includes(fixedName);
      this.setState({
        componentDidMountFlag:false
      })

      let tempTableHeader=[];

      //* The condition checks whether the provided fixedName is present in the array or not.
        if(fixedName=="clauseWithNcrTable"){
          tempData = [ this.props.tableHeader, ...Object.values(datalist) ];
        } else if(fixedName=="areaOfImpovementTable"){
         this.createAreaOfImprovementSheet();
        } else if(fixedName=="masterSheet"){
        if(datalist.length>0){
          this.createAndSaveMasterSheet();
        }
      }
      else{
        this.props.tableHeader.forEach((item,idx) => {
        tempTableHeader.push(item);
        });

        if(fixedName=="AuditCycleBase"){
          tmpAuditComplianceData.push([...this.props.tableHeader.filter(item=>item!=='PNC')])
        }

        tempData.push(tempTableHeader)
        datalist.forEach(item => {
          if(fixedName=="AuditCycleBase"){
            tmpAuditComplianceData.push([item.name,...item.complianceLevel.map(item=> item.total)])
          }
          let tempRow = [];
          tempRow.push(item.name);
          const value = Object.values(item.result);
          if(value.length>0){
            this.props.tableHeader.forEach((head,index) => {
            index!==0 && value.map((res)=>{
                if(head==res.value ) {
                  let v = res.total;
                  tempRow.push(v);
                }
              })
              if(!value.some(element=>element.value==head) && index!==0) {
                let v = fixedName=="projectWiseQms"?"-":0;
                tempRow.push(v);
              }
            })
          } else{
          Object.values(this.props.tableHeader).map((item,idx)=>{
            if(idx!=0) {
              let v = v = fixedName=="projectWiseQms"?"-":0;
              tempRow.push(v);
            }

            
          })
        }
        
        tempData.push(tempRow)
        });
      }
    }

    tempData = this.sortTableRows(tempData, this.props.sortColumnIndexAudit, this.props.sortOrderAudit);
    tmpAuditComplianceData = this.sortTableRows(tmpAuditComplianceData, this.props.sortColumnIndexAuditCompliance, this.props.sortOrderAuditCompliance);


    this.setState({
      series:tempData,
      componentDidMountFlag:true,
      chartName:this.props.chartName,
      auditComplianceLevalData:tmpAuditComplianceData,
    })
  }

  numberToColumn(number) {
    let column = "";
    while (number > 0) {
      let remainder = (number - 1) % 26;
      column = String.fromCharCode(65 + remainder) + column;
      number = Math.floor((number - remainder) / 26);
    }
    return column.toUpperCase();
  }

  //* This function creates and saves a master sheet of afcons data in excel
  createAndSaveMasterSheet = async()=>{
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    if(this.props.datalist.length>0){
      const colData = this.props.datalist[0].colList;
      const rowData = this.props.datalist[0].rowList;
      const displayCol = colData.map(e=>e.value) ||[];
      worksheet.addRow(displayCol)

      const header = worksheet.getRow(1);
      header.eachCell({ includeEmpty: true }, function(cell, colNumber) {
        const style = worksheet.getCell(header.number, colNumber).style;
        style.font = { bold: true };
      });

      for(const rowItem of rowData){
        let tmRowData=[];
        for(const colItem of colData){
           let tmpData = rowItem.filter(e=>e.key==colItem.key)||[];
           if(tmpData.length>0){
            tmRowData.push(tmpData[0].value)
           }else{
            tmRowData.push(0)
           }
        }
        worksheet.addRow(tmRowData);
      }

      worksheet.getColumn(1).width=30; 
      worksheet.getColumn(2).width=15; 
      worksheet.getColumn(3).width=20; 
      worksheet.getColumn(4).width=30; 
      worksheet.getColumn(5).width=15; 
      worksheet.getColumn(6).width=15; 
      worksheet.getColumn(7).width=15; 

      worksheet.eachRow({ includeEmpty: true }, function(row, rowNumber) {
        row.eachCell({ includeEmpty: true }, function(cell, colNumber) {
          const style = worksheet.getCell(rowNumber, colNumber).style;
          style.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
        });
      });

      await workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${this.props.criteriaPlaceholder}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
    }

  }

  // old Master Sheet Data Code
  // createAndSaveMasterSheet = async()=>{
  //   const workbook = new Excel.Workbook();
  //   const worksheet = workbook.addWorksheet('Sheet1');
  //   let rowNoTraker=1; 
  //   let prvEndCount =0;

  //   for(const data of this.props.datalist){
  //     prvEndCount =4+data.clauseList.length+1;
  //     worksheet.addRow([data.auditCycle]) //* Display  AuditCycle
  //     let colStart = this.numberToColumn(1); //* A -> 1, B ->2, C->3 Like That
  //     let colEnd = this.numberToColumn(prvEndCount-1+data.functionList.length*data.clauseList.length);
  //     worksheet.mergeCells(`${colStart}${rowNoTraker}:${colEnd}${rowNoTraker}`) //* mergerCell for UnitType

  //     const auditCyclerow = worksheet.getRow(rowNoTraker);
  //     auditCyclerow.eachCell({ includeEmpty: true }, function(cell, colNumber) {
  //       const style = worksheet.getCell(auditCyclerow.number, colNumber).style;
  //       style.font = { bold: true };
  //     });

  //     rowNoTraker++;
      
  //     worksheet.addRow([data.unitType]) //* Display  UnitType  
  //     colStart = this.numberToColumn(1); //* A -> 1, B ->2, C->3 Like That
  //     colEnd = this.numberToColumn(prvEndCount-1+data.functionList.length*data.clauseList.length);
  //     worksheet.mergeCells(`${colStart}${rowNoTraker}:${colEnd}${rowNoTraker}`) //* mergerCell for UnitType
  //     const unitTyperow = worksheet.getRow(rowNoTraker);
  //     unitTyperow.eachCell({ includeEmpty: true }, function(cell, colNumber) {
  //       const style = worksheet.getCell(unitTyperow.number, colNumber).style;
  //       style.font = { bold: true };
  //     });
  //     rowNoTraker++;  //* Increase Row Count
  //     const clauseListPadding = data.clauseList.slice(1).map(() => "");
  //     const headingRow =["S.No","BU",`${data.unitType}`,"Audit Findings",...clauseListPadding,"Avg"]  //* Table Header List
  //     colStart = this.numberToColumn(4); //* Audit Findis Start
  //     colEnd = this.numberToColumn(4+data.clauseList.length-1) //* Audit Findins End
     
  //     let mergeHeaderCellArr = [`${colStart}${rowNoTraker}:${colEnd}${rowNoTraker}`]; //* meregerCell for Audit Finding 
      
  //     //* Add Function in Header And Dynamic MergerCell
  //     data.functionList.map((func)=>{
  //       headingRow.push(func);
  //       data.clauseList.map((item,i)=>{
  //         if(i!==0){
  //           headingRow.push("")
  //         }
  //       })
  //       colStart = this.numberToColumn(prvEndCount); 
  //       colEnd = this.numberToColumn(prvEndCount+data.clauseList.length-1);
  //       mergeHeaderCellArr.push(`${colStart}${rowNoTraker}:${colEnd}${rowNoTraker}`)
  //       prvEndCount+=data.clauseList.length
  //     })
  //     worksheet.addRow(headingRow) //* Adding Table Header

  //     //* make the text in the second row bold
  //     const row = worksheet.getRow(rowNoTraker);
  //     row.eachCell({ includeEmpty: true }, function(cell, colNumber) {
  //       const style = worksheet.getCell(row.number, colNumber).style;
  //       style.font = { bold: true };
  //     });


  //     //* mergerCell For All Function 
  //     mergeHeaderCellArr.map((item)=>{
  //       worksheet.mergeCells(item);
  //     })
  //     rowNoTraker++; //* Increase Row Count

  //     //* Add Clause Row To excel
  //     let clauseHeader = ["","","",...data.clauseList,""];
  //     data.functionList.map((func)=>{
  //      clauseHeader.push(...data.clauseList)
  //     })
  //     worksheet.addRow(clauseHeader) //* Adding Clause List Header
  //     //* change the bottom border color of the third row
  //    const rowBorder = worksheet.getRow(rowNoTraker);
  //    rowBorder.eachCell({ includeEmpty: true }, function(cell, colNumber) {
  //    const style = worksheet.getCell(rowBorder.number, colNumber).style;
  //    style.border = {
  //      bottom: { style: 'thin', color: { argb: '000000' } }
  //    };
  //   });
  //     rowNoTraker++; //* Increase Row Count

  //     //* Add Data to MasterSheet
  //     let lastIndexCount=0;
  //     for(const grouping of data.groupingList){
  //       let prvRowNoTrack=rowNoTraker;
  //       grouping.unitList.map((unit,index)=>{
  //         let tmpClauseCountList = unit.result.map(item=>item.total)||[];
  //         let GroupingRowData =[lastIndexCount+index+1,grouping.groupingName,unit.unitName,...tmpClauseCountList,unit.average];
  //         unit.fuctionList.map((func)=>{
  //           let tempFuncClauseList = func.result.map(item=>item.total)||[];
  //           GroupingRowData.push(...tempFuncClauseList)
  //         })
  //         worksheet.addRow(GroupingRowData) //* Adding total Data
  //         rowNoTraker++; //* Increase Row Count
  //       })
  //       worksheet.mergeCells(`B${prvRowNoTrack}:B${prvRowNoTrack+grouping.unitList.length-1}`)
  //       lastIndexCount+=grouping.unitList.length
  //     }
  //     rowNoTraker++
  //   }

  //   //* Increase Width Of Needed Column
  //   worksheet.getColumn(2).width=30;  //* BU Column
  //   worksheet.getColumn(3).width=30;  //* UnitType Column

  //   //* Center & Wrap All Text For ExeclSheet
  //   worksheet.eachRow({ includeEmpty: true }, function(row, rowNumber) {
  //     row.eachCell({ includeEmpty: true }, function(cell, colNumber) {
  //       const style = worksheet.getCell(rowNumber, colNumber).style;
  //       style.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
  //     });
  //   });

  //   await workbook.xlsx.writeBuffer().then((data) => {
  //     const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.download = `${this.props.criteriaPlaceholder}.xlsx`;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  // })

  // }

  createAreaOfImprovementSheet = async()=>{
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    let rowNoTraker=1; 
    const {functionArr,unitArr,auditCycleName} = this.props.datalist[0]||{};
    worksheet.addRow([auditCycleName])
    worksheet.mergeCells(`A1:F1`)

    worksheet.addRow()
    rowNoTraker+=2
    if(functionArr.length>0){
      let tmpHeader =["Department","Procedure","Process","Description","Total",...unitArr]
      worksheet.addRow(tmpHeader);
      const headerRow = worksheet.getRow(rowNoTraker);
      headerRow.eachCell({ includeEmpty: true }, function(cell, colNumber) {
        const style = worksheet.getCell(headerRow.number, colNumber).style;
        style.font = { bold: true };
      });
      headerRow.eachCell({ includeEmpty: true }, (cell) => {
        cell.alignment = { textRotation: 90 };
      });
    const column0 = worksheet.columns[0]; 
    column0.width = 20; 
    const column1 = worksheet.columns[1]; 
    column1.width = 35; 
    const column2 = worksheet.columns[2];
    column2.width = 15;

      rowNoTraker++; //* Increase Row Count
      for(const funcArr of functionArr){
        const colStart = `A${rowNoTraker}`
        for (const reqArr of funcArr.requiremntList){
          const tmpFuncArr = [funcArr.functionName, reqArr.requirement, reqArr.processName,reqArr.qms, reqArr.total];
          
          tmpFuncArr.push(...unitArr.map((unit) => {
            const unitObj = reqArr.unitList.find((u) => u.unitName === unit);
            return unitObj ? unitObj.total : '';
          }));
          
          // tmpRowsArr.push(tmpFuncArr);
          worksheet.addRow(tmpFuncArr)
          rowNoTraker++; //* Increase Row Count
        }
        const colEnd = `A${rowNoTraker-1}`
        worksheet.mergeCells(`${colStart}:${colEnd}`)
        
      }


       //* Center & Wrap All Text For ExeclSheet
    worksheet.eachRow({ includeEmpty: true }, function(row, rowNumber) {
      row.eachCell({ includeEmpty: true }, function(cell, colNumber) {
        const style = worksheet.getCell(rowNumber, colNumber).style;
        style.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
      });
    });

    await workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${this.props.criteriaPlaceholder}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
  })


    }
  }

  //* This function creates excel file from API data.
  saveExcelSheet = async() =>{
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');
    let newArray = [] 
    let tmpSeries = this.state.series;
  
    Object.values(tmpSeries).map((item,idx)=>{
        if(idx==0) {
          newArray.push(item)
        } else {
          let tempArray=[];
          Object.values(item).map((arr,i)=>{
              tempArray.push(arr);
          })
          newArray.push(tempArray)
        }
      })

      worksheet.addRows(newArray)
      worksheet.addRow()
      if(this.props.fixedName=="AuditCycleBase"){
        Object.values(this.state.auditComplianceLevalData.slice(1)).map((item)=>{
          worksheet.addRow([item[0],...item.slice(1).map(item=>item.f)])
        })
      }

    worksheet.getRow(1).font = { bold: true };
    worksheet.getRow(1).alignment = { vertical: 'middle' };
    worksheet.eachRow(function(row, rowNumber) {
      row.eachCell(function(cell, colNumber) {
          cell.alignment = { horizontal: 'start', vertical: 'center' };
      });
    });
    worksheet.columns.forEach(column => {
      const maxLength = column.values.reduce((prev, curr) => {
        const currString = curr ? curr.toString() : '';
        return Math.max(prev, currString.length);
      }, 0);
      column.width = maxLength + 1;
    });
  
    await workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${this.props.criteriaPlaceholder}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
  }

  sortTableRows = (data, columnIndex, sortOrder) => {
    if (data.length > 1) {
      const header = data.shift();
      data.sort((a, b) => {
        if (a[columnIndex] > b[columnIndex]) {
          return sortOrder === "asc" ? 1 : -1;
        }
        else {
          return sortOrder === "asc" ? -1 : 1
        }
      });

      if (this.props.formulaArrPlaceholder === "Top" ) {
        data.sort((a, b) => b[1] - a[1]);
        if(!isEmptyVariable(this.props.datalisTopBottomCount)){
          data = data.slice(0, this.props.datalisTopBottomCount);
        }
        
      }
      else if (this.props.formulaArrPlaceholder === "Bottom") {
        data.sort((a, b) => a[1] - b[1]);
        if(!isEmptyVariable(this.props.datalisTopBottomCount))
        {
          data = data.slice(0, this.props.datalisTopBottomCount);
        }
      }

      data = [ header, ...data ];
    }
    return data;
  }

  displayLineChart = (series) =>{

    let displayData = series.slice(3).map(e=>({value:e})) || [];
    let bgColor = ''
    // console.log("displayData ::",displayData)

    if (displayData.length >1) {
      const lastElement = displayData[displayData.length - 1].value;
      const secondLastElement = displayData[displayData.length - 2].value;
      if (lastElement > secondLastElement) {
        bgColor = "#79E796";
      } else {
        bgColor = "orange";
      }
    }else{
      bgColor= "#79E796"
    }

    return <td style={{ verticalAlign: "top" }} className="chart-without-margin">
            <div style={{ margin:'5px',backgroundColor:bgColor }}>
              <ResponsiveContainer width={80} height={30}>
                <LineChart data={displayData}>
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke="black"
                    strokeWidth={2}
                    dot={true} 
                    isAnimationActive={false}
                  />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </td>
  }

  render() {
    return (
      this.props.isDisplay && !["masterSheet","areaOfImpovementTable"].includes(fixedName) &&
      <div className="card-1 h-100 overflow-auto show-scrollbar" style={{ maxHeight: "calc(100vh - 315px)", }}>
        <div className="card-body">
          <div className="d-flex flex-wrap" style={{ gap: 15 }}>
            { !isSpecialCase &&
              <div style={{width:"160px"}} className="">
                <RegularDD
                    placeholder={this.props.formulaArrPlaceholder}
                    dropdownArr={this.props.formulaArr}
                    labelParam="label"
                    onDropDownItemClick={this.props.formulaArrOnClick}
                />
              </div>
            }
            { !isSpecialCase &&
              <div style={{width:"160px"}} class="addnew-modal-form-group">
                <input type="number" min={1} placeholder="Enter Number" 
                name="perSearchKey" value={this.props.perSearchKey} 
                onChange={this.props.perSearchHandleChange}/>
                {/* {
                  !isEmptyVariable(this.props.formErrors['perReqFilter']) &&
                  <span class="cm-error">{this.props.formErrors['perReqFilter']}</span>
                } */}
              </div>
            }
            {
            !["masterSheet","projectWiseQms"].includes(fixedName)
              ? <div className='ml-auto mb-3'>
                  <a onClick={this.saveExcelSheet} className='btn-download-icon line-height-1'>
                    <span className="material-icons" style={{ fontSize:20 }}>download</span>
                  </a>
                </div>
              : null
            }
          </div>
          <div>
            {
              this.state.series.length > 1
              ? <div className="common-table mx-auto" style={{ width: "fit-content" }}>
                  <table className="table">
                    <thead>
                      <tr>
                        {this.state.series[0].map(((series, i) => (
                          <th key={"series_heading_" + i} onClick={() => this.props.sortAuditTableRows(i, i=== 0 ? "name" : series)}>
                            {series}
                            {this.props.sortColumnIndexAudit === i && 
                              <span className='material-icons-outlined' style={{ fontSize: 14, verticalAlign: "middle" }}>
                                {this.props.sortOrderAudit === "asc" ? "arrow_upward" : "arrow_downward"}
                              </span>
                            }
                          </th>
                          
                        )))}
                        {fixedName == "projectWiseQms" && this.state.series[0].slice(3).length>1 &&
                          <th style={{fontWeight:'bold'}}>Line Chart </th>

                        }
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.series.slice(1).map(((series, i) => (
                        <tr key={"series_row_" + i}>
                          {series.map(((data, j) => (
                            <td key={"series_data_" + j}>{data + ((fixedName=="projectWiseQms"?j>1:j!==0) && this.state.isShowPercentage && data!="-"?'%':'')}</td>
                          )))}

                          {fixedName=="projectWiseQms" && series.slice(3).length>1 &&
                              this.displayLineChart(series)
                          }
                        </tr>
                      )))}
                    </tbody>
                  </table>
                </div>
              : null
            }
            { this.props.fixedName =="AuditCycleBase" &&
            <>
              <br/>
              <hr/>
              <br/>
              {
                this.state.auditComplianceLevalData.length > 1
                ? <div className="common-table mx-auto" style={{ width: "fit-content" }}>
                    <table className="table">
                      <thead>
                        <tr>
                          {this.state.auditComplianceLevalData[0].map(((data, i) => (
                            <th key={"audit_heading_" + i} onClick={() => this.props.sortComplianceTableRows(i, i=== 0 ? "name" : data)}>
                              {data}
                              {this.props.sortColumnIndexAuditCompliance === i &&
                                <span className='material-icons-outlined' style={{ fontSize: 14, verticalAlign: "middle" }}>
                                  {this.props.sortOrderAuditCompliance === "asc" ? "arrow_upward" : "arrow_downward"}
                                </span>
                              }
                            </th>
                          )))}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.auditComplianceLevalData.slice(1).map(((data, i) => (
                          <tr key={"audit_row_" + i}>
                            {data.map(((column, j) => (
                              <td key={"audit_data_" + j}>{column + (j!==0?'%':'')}</td>
                            )))}
                          </tr>
                        )))}
                      </tbody>
                    </table>
                  </div>
                : null
              }
            </>
            }
          </div>
        </div>
      </div>
    )
  }
}
