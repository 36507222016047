import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {PageSaveButton,ColorSaveButton,TreeNodeCheckBox,
    TableRadioButton} from '../../common/Global/globalStyles.style';
import RegularDropdown from '../../common/DropdownMenus/RegularDropdown';
import {isEmptyVariable,ifEmptyReturn,isEmptyArray,getLocalStorageVariables} from '../../common/Global/commonFunctions';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import { SketchPicker } from 'react-color';
import RankingColorPicker from "./rankingColorCodeDialog";
import AlertDialog from '../../common/AlertDialog';

const userDetails  = getLocalStorageVariables();
const clientPlaceholder = "Select Client";
const draftStatus = "draft";

const refChecklist = "Ref";
const yesNoChecklist = "Y/N";
const rankingChecklist = "R";
const wrankingChecklist = "WR";

const minrPlaceholder = "Select Min Ranking";
const maxrPlaceholder = "Select Max Ranking";
const rankingArr = [
    {label:0,id:0},
    {label:1,id:1},
    {label:2,id:2},
    {label:3,id:3},
    {label:4,id:4},
    {label:5,id:5},
    {label:6,id:6},
    {label:7,id:7},
    {label:8,id:8},
    {label:9,id:9},
    {label:10,id:10}
];
const maxRankingArr = [
    {label:1,id:1},
    {label:2,id:2},
    {label:3,id:3},
    {label:4,id:4},
    {label:5,id:5},
    {label:6,id:6},
    {label:7,id:7},
    {label:8,id:8},
    {label:9,id:9},
    {label:10,id:10}
];
const minwPlaceholder = "Select Min Weight";
const maxwPlaceholder = "Select Max Weight";
const weightRankingArr = [
    {label:1,id:1},
    {label:2,id:2},
    {label:3,id:3},
    {label:4,id:4},
    {label:5,id:5},
    {label:6,id:6},
    {label:7,id:7},
    {label:8,id:8},
    {label:9,id:9},
    {label:10,id:10}
];
const departmentDropdownDefaultValue = "Select Department";
const assignmentTypeDropdownDefaultValue = "Select Assignment Type";

class AddNewCheckList extends Component{
    constructor(props) {
        super(props);

        this.state = {
            componentDidMountFlag:false,
            checkListVersionId:"",
            checklistName:"",
            checkListType:"",

            selectedClient:clientPlaceholder,
            selectedClientIds:[],
            isGeneric:false,

            yesColorCode:"",
            yesShowColorPicker:false,
            NoColorCode:"",
            NoShowColorPicker:false,
            YNColorPickerIndex:"",
            
            minRanking:minrPlaceholder,
            maxRanking:maxrPlaceholder,
            rankingArr:[],

            minWeight:minwPlaceholder,
            maxWeight:maxwPlaceholder,
            
            percentageScoring:[
                {
                    startPct:0,
                    endPct:"",
                    colorCode:"",
                    showColorPicker:false,
                    percErrors:"",
                    colorErr:"",
                },
                {
                    startPct:"",
                    endPct:"",
                    colorCode:"",
                    showColorPicker:false,
                    percErrors:"",
                    colorErr:"",
                },
                {
                    startPct:"",
                    endPct:"",
                    colorCode:"",
                    showColorPicker:false,
                    percErrors:"",
                    colorErr:"",
                },
                {
                    startPct:"",
                    endPct:"",
                    colorCode:"",
                    showColorPicker:false,
                    percErrors:"",
                    colorErr:"",
                },
                {
                    startPct:"",
                    endPct:"",
                    colorCode:"",
                    showColorPicker:false,
                    percErrors:"",
                    colorErr:"",
                },
            ],
            openColorPickerIdx:"",
            formErrors:{},

            selectedDepartmentName:departmentDropdownDefaultValue,
            selectedDepartmentId:"",
            submitClickedAtleastOnce:false,
            showRankingColorCodeDialog:false,

            selectedAssignmentTypeName:assignmentTypeDropdownDefaultValue,
            selectedAssignmentTypeId:"",
            taskCount:0,
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        }
    }

    componentDidMount(){
        this.initData();
    }

    handleRankingColorPickerDialogClose = () => {
        this.setState({
            showRankingColorCodeDialog:false,
        })
    }

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
    }

    getRankingColorCodes = (rankingArr) => {
        this.setState({
            rankingArr:rankingArr,
            showRankingColorCodeDialog:false,
        })
    }
    
    deptDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['deparment'] = "";
        }

        this.setState({
            selectedDepartmentName:item.name,
            selectedDepartmentId:item.departmentId,
            formErrors:FormErrorsTemp
        })
    }

    assignmentTypeDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['assignmentType'] = "";
        }

        this.setState({
            selectedAssignmentTypeName:item.name,
            selectedAssignmentTypeId:item.assignmentTypeId,
            formErrors:FormErrorsTemp
        })
    }

    minrDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

            FormErrorsTemp['minranking'] = "";

        this.setState({
            minRanking:item.id,
            formErrors:FormErrorsTemp,
            rankingArr:[]
        })
    }

    maxrDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

            FormErrorsTemp['maxranking'] = "";

        this.setState({
            maxRanking:item.id,
            formErrors:FormErrorsTemp,
            rankingArr:[]
        })
    }

    minwDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;
        FormErrorsTemp['minweight'] = "";

        this.setState({
            minWeight:item.id,
            formErrors:FormErrorsTemp,
        })
    }

    maxwDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;
        FormErrorsTemp['maxweight'] = "";

        this.setState({
            maxWeight:item.id,
            formErrors:FormErrorsTemp,
        })
    }

    addSelection = (args) => {
        let FormErrorsTemp  = this.state.formErrors;
        FormErrorsTemp.selectedClient = "";
        if(!isEmptyVariable(args.itemData.companyId))
        {
            this.state.selectedClientIds.push(args.itemData.companyId)
        }
        this.setState({
            selectedClientIds:this.state.selectedClientIds,
            FormErrors:FormErrorsTemp
        })
    }

    removeSelection = (args) => {
        if(!isEmptyVariable(args.itemData.companyId)){
            let idx = this.state.selectedClientIds.indexOf(args.itemData.companyId);
            if(idx !== -1){
                this.state.selectedClientIds.splice(idx,1);
            }
        }
        this.setState({
            selectedClientIds:this.state.selectedClientIds
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        let FormErrorsTemp = this.state.formErrors;
            
        if(this.state.submitClickedAtleastOnce){
            if(name === "checklistName"){
                if(isEmptyVariable(value)){
                    FormErrorsTemp['checklistNameErr'] = "Please enter user role name";
                }else{
                    FormErrorsTemp['checklistNameErr'] = "";
                }
            }
        }

        this.setState({ 
            [name]: value,
            formErrors:FormErrorsTemp
        });
    }

    handleChangePerc = (e) => {
        const { name, value } = e.target;
        let percentageScoring = this.state.percentageScoring;

        //check if it is a number
        var valueInt = parseInt(value);
        var index = parseInt(name);

        //if not a number
        var regexp = new RegExp(/^[0-9]*$/);
        if(!isEmptyVariable(value)){
            if(!regexp.test(valueInt)){
                return;
            }
        }

        if(index < 4){
            percentageScoring[index].endPct = value;
            percentageScoring[index+1].startPct = value;
        }else if(index === 4){
            percentageScoring[index].endPct = value;
        }

        //if entered value is greater than 100
        percentageScoring[index].percErrors = "";
        if(valueInt > 100){
            percentageScoring[index].percErrors = "Value should be less than 100";
        }

        //if entered value is less then previous end percentage
        if(index !== 0 && index < 5){
            if(isEmptyVariable(percentageScoring[index-1].endPct)){
                percentageScoring[index-1].percErrors = "Please enter the percentage";
            }

            if(percentageScoring[index-1].endPct >= valueInt){
                percentageScoring[index].percErrors = "Value should be greater than previous percentage";
            }
        }

        this.setState({
            percentageScoring:percentageScoring
        })
    }

    isAllEmptyPS = () => {
        let percentageScoring = this.state.percentageScoring;
        let isAllEmpty = true;

        percentageScoring.map((item)=>{
            if(!isEmptyVariable(item.endPct)){
                isAllEmpty = false;
            }
        })

        return isAllEmpty;
    }

    validatePS = () => {
        let lastPct = "";
        let lastIdx = 0;
        let percentageScoring = this.state.percentageScoring;

        this.state.percentageScoring.map((item,idx)=>{
            if(!isEmptyVariable(item.endPct)){
                lastPct = item.endPct;
                lastIdx = idx;
            }
        })

        let isError = false;
        this.state.percentageScoring.map((item,index)=>{

            if(index <= lastIdx){
                percentageScoring[index].percErrors = "";

                if(isEmptyVariable(item.endPct)){
                    isError = true;
                    percentageScoring[index].percErrors = "Please enter the percentage";
                }else{
                    if(item.endPct > 100){
                        isError = true;
                        percentageScoring[index].percErrors = "Value should be less than 100";
                    }

                    if(index !== 0 && index <= lastIdx){
                        if(parseInt(percentageScoring[index-1].endPct) >= parseInt(item.endPct)){
                            isError = true;
                            percentageScoring[index].percErrors = "Value should be greater than previous percentage";
                        }
                    }
                }

                // if(isEmptyVariable(item.colorCode)){
                //     isError = true;
                //     percentageScoring[index].colorErr = "Please select the color"
                // }
            }
        })

        //finally check if the last percentage is 100
        let isNotEqualToHundred = false;
        if(lastPct+"" !== "100"){
            isNotEqualToHundred = true;
        }

        return {
            isError:isError,
            percentageScoring:percentageScoring,
            isNotEqualToHundred:isNotEqualToHundred
        }

    }

    handleSelectionGeneric = (e) => {
        let isChecked = e.target.checked;
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp.checkListType = "";

        this.setState({
            isGeneric:isChecked,
            FormErrors:FormErrorsTemp
        })
    }
    handleSelection = (e) => {
        // e.stopPropagation();
        let isChecked = e.target.checked;
        let checklistTypeIdx = e.target.name;
        let selectedId = "";
        let FormErrorsTemp = this.state.formErrors;
        
        if(isChecked){
            selectedId = this.props.checkListType[checklistTypeIdx].checkListType;
            FormErrorsTemp.checkListType = "";
        }

        this.setState({
            checkListType:selectedId,
            FormErrors:FormErrorsTemp
        });
    }

    openColorPicker = (idx) => {
        let percentageScoring = this.state.percentageScoring;
        percentageScoring[idx].showColorPicker = true;

        this.setState({
            percentageScoring:percentageScoring,
            openColorPickerIdx:idx
        })
    }

    handleColorPickerClose = (idx) => {
        let percentageScoring = this.state.percentageScoring;
        percentageScoring[idx].showColorPicker = false;
        this.setState({
            percentageScoring:percentageScoring,
            openColorPickerIdx:""
        })
    }

    handleColorPickerChange = (color) =>{
        let percentageScoring = this.state.percentageScoring;
        percentageScoring[this.state.openColorPickerIdx].colorCode = color.hex;
        percentageScoring[this.state.openColorPickerIdx].colorErr = "";
        this.setState({
            percentageScoring:percentageScoring,
        })
    }

    openColorPickerYN = (flag) => {
        if(flag === "yesShowColorPicker"){
            this.setState({
                yesShowColorPicker:true,
                YNColorPickerIndex:"Y"
            })
        }else{
            this.setState({
                NoShowColorPicker:true,
                YNColorPickerIndex:"N"
            })
        }
    }

    handleColorPickerCloseYN = (flag) => {
        if(flag === "yesShowColorPicker"){
            this.setState({
                yesShowColorPicker:false,
                YNColorPickerIndex:""
            })
        }else{
            this.setState({
                NoShowColorPicker:false,
                YNColorPickerIndex:""
            })
        }
    }

    handleColorPickerChangeYN = (color) =>{
        if(this.state.YNColorPickerIndex === "Y"){
            this.setState({
                yesColorCode:color.hex
            })
        }else if(this.state.YNColorPickerIndex === "N"){
            this.setState({
                NoColorCode:color.hex
            })
        }
    }

    chooseRankingColorsDialog = () => {
        let rankingErr = false;

        let formErrors = {};
        let isError = false;

        if(isEmptyVariable(this.state.minRanking) ||
        this.state.minRanking === minrPlaceholder){
            isError = true;
            rankingErr = true;
            formErrors.minranking = "Please select minimum ranking"
        }

        if(isEmptyVariable(this.state.maxRanking) ||
        this.state.maxRanking === maxrPlaceholder){
            isError = true;
            rankingErr = true;
            formErrors.maxranking = "Please select maximum ranking"
        }

        if(!rankingErr){
            if(this.state.minRanking > this.state.maxRanking){
                isError = true;
                formErrors.minranking = "Min Ranking must be less than max ranking"
            }
        }

        if(isError){
            this.setState({
                formErrors:formErrors
            })
        }else{
            this.setState({
                showRankingColorCodeDialog:true,
            })
        }
    }

    submitChecklist = () => {
        this.saveChecklist("N")
    }

    saveChecklist = (draftStatus) => {
        this.setState({
            showLoader:true,
            submitClickedAtleastOnce:true,
        })
        let formErrors = {};
        let isError = false;
        let percentageScoring = this.state.percentageScoring;

        if(!this.props.isEdit){
            if(isEmptyVariable(this.state.selectedDepartmentId)){
                isError = true;
                formErrors.deparment = "Please select the department"
            }
        }

        if(!this.props.tasksAdded){
            if(isEmptyVariable(this.state.selectedAssignmentTypeId)){
                isError = true;
                formErrors.assignmentType = "Please select the assignment type"
            }
        }

        if(isEmptyVariable(this.state.checklistName)){
            isError = true;
            formErrors.checklistNameErr = "Please enter checklist name"
        }
        if(!this.state.isGeneric && isEmptyArray(this.state.selectedClientIds)){
            isError = true;
            formErrors.selectedClient = "Please select the clients"
        }
        if(isEmptyVariable(this.state.checkListType)){
            isError = true;
            formErrors.checkListType = "Please select the checklist type"
        }else{
            if(this.state.checkListType === yesNoChecklist){
                //need to change in 3 pages
                if(this.isAllEmptyPS()){
                    isError = true;
                    formErrors.allEmptyPS = "Please enter percentage scoring"
                }else{
                    let validateObj = this.validatePS();
                    if(validateObj.isError){
                        isError = true;
                        percentageScoring = validateObj.percentageScoring
                    }

                    if(validateObj.isNotEqualToHundred){
                        isError = true;
                        formErrors.allEmptyPS = "Last percentage must be equal to 100"
                    }
                }
            }else if(this.state.checkListType === rankingChecklist ||
                this.state.checkListType === wrankingChecklist){
                let rankingErr = false;

                if(isEmptyVariable(this.state.minRanking) ||
                this.state.minRanking === minrPlaceholder){
                    isError = true;
                    rankingErr = true;
                    formErrors.minranking = "Please select minimum ranking"
                }

                if(isEmptyVariable(this.state.maxRanking) ||
                this.state.maxRanking === maxrPlaceholder){
                    isError = true;
                    rankingErr = true;
                    formErrors.maxranking = "Please select maximum ranking"
                }

                if(!rankingErr){
                    if(this.state.minRanking > this.state.maxRanking){
                        isError = true;
                        formErrors.minranking = "Min Ranking must be less than max ranking"
                    }
                }

                if(this.state.checkListType === wrankingChecklist){
                    if(isEmptyVariable(this.state.minWeight) ||
                    this.state.minWeight === minwPlaceholder){
                        isError = true;
                        formErrors.minWeight = "Please select minimum weight"
                    }

                    if(isEmptyVariable(this.state.maxWeight) ||
                    this.state.maxWeight === maxwPlaceholder){
                        isError = true;
                        formErrors.maxWeight = "Please select maximum weight"
                    }
                    if(!isEmptyVariable(this.state.maxWeight) && 
                        !isEmptyVariable(this.state.minWeight) &&
                        this.state.minWeight > this.state.maxWeight){
                        isError = true;
                        formErrors.minWeight = "Min weight must be less than max weight"
                    }
                }
                //need to change in 3 pages
                if(this.isAllEmptyPS()){
                    isError = true;
                    formErrors.allEmptyPS = "Please enter percentage scoring"
                }else{
                    let validateObj = this.validatePS();
                    if(validateObj.isError){
                        isError = true;
                        percentageScoring = validateObj.percentageScoring
                    }

                    if(validateObj.isNotEqualToHundred){
                        isError = true;
                        formErrors.allEmptyPS = "Last percentage must be equal to 100"
                    }
                }
            }
        }

        if(isError){
            this.setState({
                showLoader:false,
                formErrors:formErrors,
                percentageScoring:percentageScoring
            })
        }else{

            let url = "";

            let postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                draft:draftStatus,

                checkListName:this.state.checklistName,
                checkListType:this.state.checkListType,
                assignmentTypeId:this.state.selectedAssignmentTypeId,
                companyIds:this.state.isGeneric?JSON.stringify([]):JSON.stringify(this.state.selectedClientIds)
            }

            if(this.props.isEdit){
                url = Constants.UpdateChecklist
                postParam.checkListVersionId = this.state.checkListVersionId
            }else{
                url = Constants.CreateChecklist
                postParam.departmentId = this.state.selectedDepartmentId
            }

            if(this.state.checkListType === refChecklist){
                
            }else if(this.state.checkListType === yesNoChecklist){
                postParam.yesColorCode = this.state.yesColorCode;
                postParam.noColorCode = this.state.NoColorCode;
            }else if(this.state.checkListType === rankingChecklist){
                postParam.minRank = this.state.minRanking
                postParam.maxRank = this.state.maxRanking

                !isEmptyArray(this.state.rankingArr) &&
                this.state.rankingArr.map((item,idx)=>{
                    postParam["rank"+idx] = item.ranking;
                    postParam["rank"+idx+"ColorCode"] = item.rankingColor;
                })
            }else if(this.state.checkListType === wrankingChecklist){
                postParam.minRank = this.state.minRanking
                postParam.maxRank = this.state.maxRanking
                postParam.minWeight = this.state.minWeight
                postParam.maxWeight = this.state.maxWeight

                !isEmptyArray(this.state.rankingArr) &&
                this.state.rankingArr.map((item,idx)=>{
                    postParam["rank"+idx] = item.ranking;
                    postParam["rank"+idx+"ColorCode"] = item.rankingColor;
                })
            }

            if(this.state.checkListType === yesNoChecklist ||
            this.state.checkListType === rankingChecklist ||
            this.state.checkListType === wrankingChecklist){
                this.state.percentageScoring.map((item,idx)=>{

                    if(!isEmptyVariable(item.endPct)){
                        postParam["startPct"+(idx+1)] = item.startPct
                        postParam["endPct"+(idx+1)] = item.endPct
                        postParam["colorCode"+(idx+1)] = item.colorCode
                    }
                })
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.props.handleBackBtnClick();
                }else{
                    this.setState({
                        showLoader:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    })
                }
            });
        }
    }

    initData = () => {
        if(this.props.isEdit){
            const checkListItem = this.props.checklistItem

            //selected company array
            let resArr = [];
            let splitArr = checkListItem.companyList.split(",");
            if(!isEmptyArray(splitArr)){
                splitArr.map((item)=>{
                    resArr.push(parseInt(item.split(":-:")[1]));
                })
            }
            //check if it is generic
            let isGeneric = false;
            if(!isEmptyArray(resArr)){
                if(resArr[0]+"" === "0"){
                    isGeneric = true;
                }
            }

            let minRank = minrPlaceholder;
            let maxRank = maxrPlaceholder;
            let minWeight = minwPlaceholder;
            let maxWeight = maxwPlaceholder;
            let rankingArr = [];
            let selectedAssignmentTypeId = checkListItem.assignmentTypeId;
            let selectedAssignmentTypeName = checkListItem.assignmentTypeName;


            if(checkListItem.checkListType === rankingChecklist ||
                checkListItem.checkListType === wrankingChecklist){
                //Now we need to create the ranking arr
                minRank = isEmptyVariable(checkListItem.minRank)?0:checkListItem.minRank
                maxRank = isEmptyVariable(checkListItem.maxRank)?0:checkListItem.maxRank
                const diff = maxRank - minRank;
                for(var i=0;i<=diff;i++){
                    if(!isEmptyVariable(checkListItem["rank"+i])){
                        let rankingObj = {};
                        rankingObj = {
                            ranking:checkListItem["rank"+i],
                            rankingColor:checkListItem["rank"+i+"ColorCode"],
                            showColorPicker:false,
                        }
                        rankingArr.push(rankingObj)
                    }
                }

                minWeight = isEmptyVariable(checkListItem.minWeight)?0:checkListItem.minWeight
                maxWeight = isEmptyVariable(checkListItem.maxWeight)?0:checkListItem.maxWeight
            }

            //PercentageScoring array
            let percArr = [];
            for(var i=1;i<=5;i++){
                if(i===0){
                    let percObj = {
                        startPct:0,
                        endPct:ifEmptyReturn(checkListItem["endPct"+i]),
                        colorCode:ifEmptyReturn(checkListItem["colorCode"+i]),
                        showColorPicker:false,
                        percErrors:"",
                        colorErr:"",
                    }
                    percArr.push(percObj)
                }else{
                    let percObj = {
                        startPct:ifEmptyReturn(checkListItem["startPct"+i]),
                        endPct:ifEmptyReturn(checkListItem["endPct"+i]),
                        colorCode:ifEmptyReturn(checkListItem["colorCode"+i]),
                        showColorPicker:false,
                        percErrors:"",
                        colorErr:"",
                    }
                    percArr.push(percObj)
                }
            }

            let taskCount = checkListItem.taskCount;

            this.setState({
                componentDidMountFlag:true,

                checkListVersionId:checkListItem.checkListVersionId,
                checklistName:checkListItem.checkListName,
                checkListType:checkListItem.checkListType,
                selectedClientIds:resArr,
                selectedAssignmentTypeId:selectedAssignmentTypeId,
                selectedAssignmentTypeName:selectedAssignmentTypeName,
                isGeneric:isGeneric,
                yesColorCode:ifEmptyReturn(checkListItem.yesColorCode),
                NoColorCode:ifEmptyReturn(checkListItem.noColorCode),
                minRanking:minRank,
                maxRanking:maxRank,
                minWeight:minWeight,
                maxWeight:maxWeight,
                rankingArr:rankingArr,
                percentageScoring:percArr,
                taskCount:taskCount,
            })

        }else{
            let isGeneric = false;

            if(!isEmptyVariable(this.props.company) && this.props.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF){
                isGeneric= true;
            }
            //if user belongs to single dept then dont show the dropdown
            let selectedDepartmentId = "";
            let selectedDepartmentName = departmentDropdownDefaultValue;
            if(!isEmptyArray(this.props.departments) && this.props.departments.length===1){
                selectedDepartmentId = this.props.departments[0].departmentId;
                selectedDepartmentName = this.props.departments[0].name;
            }
            
            this.setState({
                componentDidMountFlag:true,
                selectedDepartmentId:selectedDepartmentId,
                selectedDepartmentName:selectedDepartmentName,
                isGeneric:isGeneric
            })
        }
        
    }

    render(){
        let fields = {text:"companyName",value:"companyId"}
        return(
            <section>
                {
                    // Need to check component did mount flag, other wise until api is called,
                    // it shows no items found layout.
                    this.state.componentDidMountFlag &&
                    <div className="body-wrapper">
                        <div className="flex-center-layout secondary-top-bar">
                            <div className="back-btn-layout" onClick={this.props.handleBackBtnClick}>
                            <span class="material-icons-outlined">arrow_back_ios</span>                            </div>
                            <h6>{this.props.isEdit?"Edit Checklist":"Add Checklist"}</h6>
                        </div>

                        <div className="card-col m-b-0 m-t-0">

                            <div className="row custom-row">
                                <div className='col-md-5'>
                                    {
                                        !this.props.isEdit &&
                                        !isEmptyArray(this.props.departments) &&
                                        this.props.departments.length > 1 &&
                                        <div className="col-md-12 custom-col">
                                            <div class="addnew-modal-form-group">
                                                <p>Department*</p>
                                                <RegularDropdown 
                                                    placeholder={this.state.selectedDepartmentName}
                                                    dropdownArr={this.props.departments}
                                                    labelParam="name"
                                                    onDropDownItemClick={this.deptDropdownClick}
                                                    isFocusRequired={true}
                                                    defaultPlaceholderDropDown={departmentDropdownDefaultValue}
                                                />
                                                {
                                                    !isEmptyVariable(this.state.formErrors['deparment']) &&
                                                    <span class="cm-error">{this.state.formErrors['deparment']}</span>
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div className="col-md-12 custom-col">
                                        <div className="add-new-form-group">
                                            <p>Checklist Name*</p>
                                            <input 
                                                name="checklistName" 
                                                type="text" 
                                                value={this.state.checklistName}
                                                onChange ={this.handleChange}
                                                ref={this.inputText}
                                                placeholder="Type the checklist name here" />
                                            {
                                                !isEmptyVariable(this.state.formErrors['checklistNameErr']) &&
                                                <span class="cm-error">{this.state.formErrors['checklistNameErr']}</span>
                                            }
                                        </div>
                                    </div>
                                    { this.props.company?.companyType!== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                        <div className="col-md-12 custom-col">
                                            <div className="add-new-form-group">
                                                <p>Clients*</p>
                                                <TreeNodeCheckBox
                                                    style={{padding:"7px 0px",marginLeft:0}}
                                                    themeSettings={this.props.themeSettings}>
                                                    <input 
                                                        type="checkbox"
                                                        name="isGeneric"
                                                        id="isGeneric"
                                                        onChange={this.handleSelectionGeneric}
                                                        checked={this.state.isGeneric}
                                                    />
                                                    <label for="isGeneric">is Generic</label>
                                                </TreeNodeCheckBox>
                                                <MultiSelectComponent 
                                                    id="clientCheckbox"
                                                    dataSource={this.props.clients}
                                                    fields={fields}
                                                    placeholder={this.state.selectedClient} 
                                                    mode="CheckBox"
                                                    closePopupOnSelect={false}
                                                    allowFiltering={false}
                                                    showSelectAll={true}
                                                    select={this.addSelection}
                                                    removed={this.removeSelection}
                                                    cssClass="clientsChecklist dialogcl mb-0"
                                                    value={this.state.selectedClientIds}
                                                    showDropDownIcon={true}
                                                    showClearButton={false}
                                                    enabled={!this.state.isGeneric}
                                                >
                                                    <Inject services={[CheckBoxSelection]} />
                                                </MultiSelectComponent>
                                                {
                                                    !isEmptyVariable(this.state.formErrors['selectedClient']) &&
                                                    <span className="cm-error">{this.state.formErrors['selectedClient']}</span>
                                                }
                                            </div>
                                        </div>
                                    }
                                    <div className="col-md-12 custom-col">
                                        {
                                            this.state.taskCount === 0 &&
                                            <div class="add-new-form-group">
                                                <p>Assignment Type*</p>
                                                <RegularDropdown 
                                                    placeholder={this.state.selectedAssignmentTypeName}
                                                    dropdownArr={this.props.assignmentTypes}
                                                    labelParam="name"
                                                    onDropDownItemClick={this.assignmentTypeDropdownClick}
                                                    defaultPlaceholderDropDown={assignmentTypeDropdownDefaultValue}
                                                />
                                                {
                                                    !isEmptyVariable(this.state.formErrors['assignmentType']) &&
                                                    <span class="cm-error">{this.state.formErrors['assignmentType']}</span>
                                                }
                                            </div>
                                        }
                                    </div>
                                    <div className="col-md-12 custom-col">
                                        <div className="add-new-form-group">  
                                            <p>Checklist Type*</p>
                                            {
                                                this.props.checkListType.map((item,idx)=>{
                                                    return <TableRadioButton
                                                    style={{marginLeft:0,marginTop:10}}
                                                    themeSettings={this.props.themeSettings}>
                                                        <input 
                                                            type="checkbox"
                                                            name={idx}
                                                            disabled = {this.props.isEdit}
                                                            id={item.checkListTypeOrder}
                                                            onChange={this.handleSelection}
                                                            checked={item.checkListType===this.state.checkListType?true:false}
                                                        />
                                                        <label for={item.checkListTypeOrder} 
                                                        style={{marginLeft:0}}>{item.checkListTypeDesc}</label>
                                                    </TableRadioButton>
                                                })
                                            }
                                            {
                                                !isEmptyVariable(this.state.formErrors['checkListType']) &&
                                                <span className="cm-error">{this.state.formErrors['checkListType']}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-7'>
                                    {
                                        this.state.checkListType === yesNoChecklist &&
                                            <div className="row custom-row">
                                                <div className="col-md-3 custom-col">
                                                    <div className="add-new-form-group">  
                                                        <p>Yes Color Code</p>
                                                        <div className="color-layout-wrapper" style={{maxWidth:80}}>
                                                            <div style={{background:this.state.yesColorCode}} className="color-layout"
                                                            onClick={this.openColorPickerYN.bind(this,"yesShowColorPicker")} />
                                                        </div>
                                                        {
                                                            this.state.yesShowColorPicker &&
                                                            <div className="popover">
                                                                <div className="popover-cover" onClick={ this.handleColorPickerCloseYN.bind(this,"yesShowColorPicker") }/>
                                                                <SketchPicker 
                                                                color={this.state.yesColorCode} 
                                                                onChange={ this.handleColorPickerChangeYN} />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-3 custom-col">
                                                    <div className="add-new-form-group">  
                                                        <p>No Color Code</p>
                                                        <div className="color-layout-wrapper" style={{maxWidth:80}}>
                                                            <div style={{background:this.state.NoColorCode}} className="color-layout"
                                                            onClick={this.openColorPickerYN.bind(this,"NoShowColorPicker")} />
                                                        </div>
                                                        {
                                                            this.state.NoShowColorPicker &&
                                                            <div className="popover">
                                                                <div className="popover-cover" onClick={ this.handleColorPickerCloseYN.bind(this,"NoShowColorPicker") }/>
                                                                <SketchPicker 
                                                                color={this.state.NoColorCode} 
                                                                onChange={ this.handleColorPickerChangeYN} />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                    {
                                        //rankingChecklist
                                        (this.state.checkListType === rankingChecklist ||
                                        this.state.checkListType === wrankingChecklist) &&
                                            <div className="row custom-row">
                                                <div className="col-md-4 custom-col">
                                                    <div className="add-new-form-group">  
                                                        <p>Min Rating</p>
                                                        <RegularDropdown 
                                                            placeholder={this.state.minRanking}
                                                            dropdownArr={rankingArr}
                                                            labelParam="label"
                                                            onDropDownItemClick={this.minrDropdownClick}
                                                            defaultPlaceholderDropDown={minrPlaceholder}
                                                        />
                                                        {
                                                            !isEmptyVariable(this.state.formErrors['minranking']) &&
                                                            <span class="cm-error">{this.state.formErrors['minranking']}</span>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                                <div className="col-md-4 custom-col">
                                                    <div className="add-new-form-group">  
                                                        <p>Max Rating</p>
                                                        <RegularDropdown 
                                                            placeholder={this.state.maxRanking}
                                                            dropdownArr={maxRankingArr}
                                                            labelParam="label"
                                                            onDropDownItemClick={this.maxrDropdownClick}
                                                            defaultPlaceholderDropDown={maxrPlaceholder}
                                                        />
                                                        {
                                                            !isEmptyVariable(this.state.formErrors['maxranking']) &&
                                                            <span class="cm-error">{this.state.formErrors['maxranking']}</span>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                                <div className="col-md-2 custom-col">
                                                    <div className="add-new-form-group"> 
                                                    <p>&nbsp;</p> 
                                                    <ColorSaveButton 
                                                        themeSettings = {this.props.themeSettings}
                                                        onClick={this.chooseRankingColorsDialog} type="button">
                                                        Colors
                                                        {
                                                            !isEmptyArray(this.state.rankingArr) &&
                                                            <span className="material-icons">check_circle</span>
                                                        }
                                                        
                                                        </ColorSaveButton>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                    {
                                        //rankingChecklist
                                        (this.state.checkListType === wrankingChecklist) &&
                                            <div className="row custom-row">
                                                <div className="col-md-4 custom-col">
                                                    <div className="add-new-form-group">  
                                                        <p>Min Weight</p>
                                                        <RegularDropdown 
                                                            placeholder={this.state.minWeight}
                                                            dropdownArr={weightRankingArr}
                                                            labelParam="label"
                                                            onDropDownItemClick={this.minwDropdownClick}
                                                            defaultPlaceholderDropDown={minwPlaceholder}
                                                        />
                                                        {
                                                            !isEmptyVariable(this.state.formErrors['minWeight']) &&
                                                            <span class="cm-error">{this.state.formErrors['minWeight']}</span>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                                <div className="col-md-4 custom-col">
                                                    <div className="add-new-form-group">  
                                                        <p>Max Weight</p>
                                                        <RegularDropdown 
                                                            placeholder={this.state.maxWeight}
                                                            dropdownArr={weightRankingArr}
                                                            labelParam="label"
                                                            onDropDownItemClick={this.maxwDropdownClick}
                                                            defaultPlaceholderDropDown={maxwPlaceholder}
                                                        />
                                                        {
                                                            !isEmptyVariable(this.state.formErrors['maxWeight']) &&
                                                            <span class="cm-error">{this.state.formErrors['maxWeight']}</span>
                                                        }
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                    {
                                        (this.state.checkListType === yesNoChecklist ||
                                        this.state.checkListType === rankingChecklist ||
                                        this.state.checkListType === wrankingChecklist) &&
                                        <div className="mt-3">
                                            <div className='row custom-row'>
                                                <div className='col-md-3 offset-1'>
                                                    <p>Min Percentage</p>
                                                </div>
                                                <div className='col-md-4'>
                                                    <p>Max Percentage</p>
                                                </div>
                                                <div className='col-md-3'>
                                                    <p>Color</p>
                                                </div>
                                            </div>
                                        {
                                            this.state.percentageScoring.map((item,idx) => {
                                                return <div className="row custom-row">
                                                    <div className='col-md-1 custom-col'>
                                                        <p style={{padding:"7px 0px"}}>{idx+1}</p>
                                                    </div>
                                                    <div className="col-md-3 custom-col">
                                                        <div className={idx === 4?"add-new-form-group m-b-0":"add-new-form-group"}>  
                                                            <p style={{padding:"7px 0px"}}>
                                                            {
                                                                idx===0
                                                                ?
                                                                item.startPct
                                                                :
                                                                (isEmptyVariable(item.startPct)||item.startPct+"" === "100")?"":"> "+item.startPct
                                                            }
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 custom-col">
                                                        <div className={idx === 4?"add-new-form-group m-b-0":"add-new-form-group"}>  
                                                            <input 
                                                                name={idx} 
                                                                type="text" 
                                                                value={item.endPct}
                                                                onChange ={this.handleChangePerc}
                                                                placeholder="Enter Max percentage" />
                                                            {
                                                                !isEmptyVariable(item.percErrors) &&
                                                                <span class="cm-error">{item.percErrors}</span>
                                                            }
                                                            
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4 custom-col">
                                                    <div className={idx === 4?"add-new-form-group m-b-0":"add-new-form-group"}>  
                                                        <div className="color-layout-wrapper" style={{maxWidth:80}}>
                                                            <div style={{background:item.colorCode}} className="color-layout"
                                                            onClick={this.openColorPicker.bind(this,idx)} />
                                                        </div>
                                                        {
                                                            item.showColorPicker &&
                                                            <div className="popover formcolor">
                                                                <div className="popover-cover" onClick={ this.handleColorPickerClose.bind(this,idx) }/>
                                                                <SketchPicker 
                                                                color={item.colorCode} 
                                                                onChange={ this.handleColorPickerChange} />
                                                            </div>
                                                        }
                                                        {
                                                            !isEmptyVariable(item.colorErr) &&
                                                            <span class="cm-error">{item.colorErr}</span>
                                                        }
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            })
                                        }
                                        {
                                            !isEmptyVariable(this.state.formErrors["allEmptyPS"]) &&
                                            <span class="cm-error">{this.state.formErrors["allEmptyPS"]}</span>
                                        }
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="row custom-row">
                            <div className="col-md-12 custom-col">
                                <div className="common-close-save">
                                    <div className="common-close-save-col">
                                        <button onClick={this.props.handleBackBtnClick} type="button" className="common-close">Cancel</button>
                                        {
                                            this.state.status === draftStatus &&
                                            <PageSaveButton 
                                            themeSettings = {this.props.themeSettings}
                                            onClick={this.saveAsDraft} type="button">Save as draft</PageSaveButton>
                                        }
                                        
                                        <PageSaveButton 
                                        themeSettings = {this.props.themeSettings}
                                        onClick={this.submitChecklist} type="button">
                                            {this.state.showLoader ? 
                                                <div class="loader-btn mt-2"></div> : 'Submit'
                                            }
                                             </PageSaveButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <RankingColorPicker
                    minRanking={this.state.minRanking}
                    maxRanking={this.state.maxRanking}
                    showRankingColorPickerDialog = {this.state.showRankingColorCodeDialog}
                    handleRankingColorPickerDialogClose = {this.handleRankingColorPickerDialogClose}
                    themeSettings = {this.props.themeSettings}
                    returnRankingColorCodes = {this.getRankingColorCodes}
                    rankingArr={this.state.rankingArr}
                />
                <AlertDialog
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type={Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                    themeSettings={this.props.themeSettings}
                />
            </section>
        )
    }
}

export default AddNewCheckList;