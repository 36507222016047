import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout,DialogSaveButton,FilterCheckBox} from '../../../common/Global/globalStyles.style';
import Sidebar from '../../../common/Sidebar';
import Topbar from '../../../common/Topbar';
import MasterMenuTabs from '../Common/topmenu';
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
import TableDropDown from '../../../common/DropdownMenus/TableDropdown';
import AlertDialog from '../../../common/AlertDialog';
import Pagination from '../../../common/Global/pagination';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import UpdateReviewStatusDialog from './othermastersupdatereviewstatusdialog'
import ReviewHistoryDialog from '../../../common/reviewhistorydialog'
import {getLocalStorageVariables,getThemeSettingsVariables,isEmptyVariable,sortTable,truncateString,
    GetOrderedMasterTabMenu,getStatusSpan,
    isEmptyArray,ifEmptyReturnEmptyStr} from '../../../common/Global/commonFunctions';
import {getDropdownActionArray} from '../../../common/Global/reviewFunctions';
import {Modal} from 'react-bootstrap';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import SelectCopyOtherMasterDialog from "./selectCopyOtherMasterDialog";
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import ReactSelectDropdown from '../../../common/DropdownMenus/ReactSelectDropdownWithSearch';

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();
const sortShortName = "shortName";
const sortName = "name";
const sortDescription = "description";
const sortMasterType = "masterType";
const sortDeptName = "departmentName";

const masterTypeDefaultPlaceholder = "Select Master Type";
const masterTypeFilterDefaultPlaceholder = "All Masters";
const departmentDropdownDefaultValue = "Select Department";
const departmentFilterDropdownDefaultValue = "All";
const auditTypeDefaultPlaceholder = "Select Audit Type";
const assignmentType = "Assignment Type";
const activeStatus="Active";
const inActiveStatus="Inactive";
const underReviewStatus="Under Review";

const requestChangeCheckText="Request Change";

class OtherMasters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"",
            deletePermission:"N",

            masterTypeArr:[],
            masterTypeSelected:"All",
            masterFilterPlaceholder:masterTypeFilterDefaultPlaceholder,

            departmentArr:[],
            selectedDepartmentName:departmentDropdownDefaultValue,
            selectedDepartmentId:"",

            deptOnly:"N",

            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            deleteReactivateMasterType:"",
            reviewLevel:"",
            alertDialogMessage:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            showBtnLoader:false,
            actionType:"",
            
            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showUpdateReviewDialog:false,
            showReviewHistoryDialog:false,

            status:activeStatus,
            statusArr:[
                {
                    label:activeStatus,
                    isSelected:true
                },
                {
                    label:underReviewStatus,
                    isSelected:false
                },
                {
                    label:inActiveStatus,
                    isSelected:false
                },
            ],
            resultSizeArr:JSON.parse(JSON.stringify(Constants.RESULT_SIZE_DD_ARR)),

            name:"",
            shortName:"",
            description:"",
            masterType:"",
            formErrors:{},
            submitClickedAtleastOnce:false,
            
            showDetailsDialog:false,
            selectedItem:"",
            clientAuditType:[],
            selectedAuditType:[]
        }
        this.masterTypeFilterArr = [];
    }

    componentDidMount(){
        this.getInitData();
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        let FormErrorsTemp = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){

            if(name === "shortName"){
                if(isEmptyVariable(value)){
                    FormErrorsTemp['shortName'] = "Please enter master short name";
                }else{
                    FormErrorsTemp['shortName'] = "";
                }
            }else if(name === "name"){
                if(isEmptyVariable(value)){
                    FormErrorsTemp['name'] = "Please enter master name";
                }else{
                    FormErrorsTemp['name'] = "";
                }
            }else if(name === "description"){
                if(!isEmptyVariable(value) && value.length > Constants.DESC_STRING_LENGTH){
                    FormErrorsTemp['description'] = "Description must be less then 500 characters";
                }else{
                    FormErrorsTemp['description'] = "";
                }
            }
        }

        this.setState({ 
            [name]: value ,
            formErrors:FormErrorsTemp
        });
    }

    handleCheck = (e) =>{
        let isChecked = e.target.checked;
        let name = e.target.name;

        if(isChecked){
            this.setState({
                deptOnly:"Y"
            },()=>{
                this.getOtherMastersList()
            })
        }else{
            this.setState({
                deptOnly:"N"
            },()=>{
                this.getOtherMastersList()
            })
        }

    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getOtherMastersList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getOtherMastersList();
            })
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleUpdateReviewDialogClose = (reloadFlag) =>{
        this.setState({
            showUpdateReviewDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
            reviewLevel:"",
        },()=>{
            if(reloadFlag === true){
                this.getOtherMastersList();
            }
        });
    }

    handleReviewHistoryDialogClose = () =>{
        this.setState({
            showReviewHistoryDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
        });
    }

    handleCreateDialogClose = () =>{
        this.setState({
            showCreateDialog:false,
            isEdit:false,
            selectedId:"",
            name:"",
            shortName:"",
            description:"",
        });
    }

    handleDetailsDialogClose = () =>{
        this.setState({
            showDetailsDialog:false,
            selectedItem:"",
        });
    }

    handleDetailsDialogShow = (item) => {
        this.setState({
            showDetailsDialog:true,
            selectedItem:item,
        });
    }

    //TODO
    showCreateDialog = () => {
        let selectedDepartmentId = this.state.selectedDepartmentId;
        let selectedDepartmentName = this.state.selectedDepartmentName;

        if(this.state.departmentArr.length > 1){
            selectedDepartmentId="";
            selectedDepartmentName=departmentDropdownDefaultValue;
        }

        this.setState({
            showCreateDialog:true,
            isEdit:false,
            selectedId:"",
            name:"",
            shortName:"",
            description:"",
            masterType:masterTypeDefaultPlaceholder,
            selectedDepartmentId:selectedDepartmentId,
            selectedDepartmentName:selectedDepartmentName,
            submitClickedAtleastOnce:false,
            formErrors:{}
        });
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo:1,
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getOtherMastersList();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getOtherMastersList();
        })
    }

    masterFilterDropdownClick = (item,selectedIndex) => {
        let placeholder = "";

        for(const [i,value] of this.masterTypeFilterArr.entries()){
            if(i === selectedIndex){
                this.masterTypeFilterArr[i].isSelected = true;
                placeholder = value.masterTypeDesc;
            }else{
                this.masterTypeFilterArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo: 1,
            masterTypeSelected:placeholder,
            masterFilterPlaceholder:placeholder==="All"?masterTypeFilterDefaultPlaceholder:placeholder
        },() => {
            this.getOtherMastersList();
        })
    }

    deptFilterDropdownClick = (item,selectedIndex) => {
        let placeholder = "";
        let id = "";
        let filterDeptArr = this.state.filterDeptArr;

        for(const [i,value] of filterDeptArr.entries()){
            if(i === selectedIndex){
                filterDeptArr[i].isSelected = true;
                placeholder = value.name;
                id = value.departmentId;
            }else{
                filterDeptArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo: 1,
            selectedFilterDepartmentName:placeholder,
            selectedFilterDepartmentId:id
        },() => {
            this.getOtherMastersList();
        })
    }

    masterTypeDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;
        let selectedAuditType = [];
        if(this.state.submitClickedAtleastOnce){
            if(isEmptyVariable(this.state.masterType) || this.state.masterType === masterTypeDefaultPlaceholder){
                FormErrorsTemp['masterType'] = "";
            }else{
                FormErrorsTemp['masterType'] = "";
            }
        }
        if(this.state.clientAuditType.length === 1){
            selectedAuditType = this.state.clientAuditType;
        }
        this.setState({
            masterType:item.masterTypeDesc,
            formErrors:FormErrorsTemp,
            selectedAuditType:selectedAuditType
        })
    }

    handleAuditTypeDropdownClick = (item) => {
        let auditTypeItem = [];
        if(Array.isArray(item)){
            auditTypeItem = item;
        }else{
            auditTypeItem.push(item);
        }
        let selectedAuditType=[];
        let FormErrorsTemp = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['selectedAuditTypes'] = "";
        }
        for(var i =0;i<auditTypeItem.length;i++)
        {
            selectedAuditType.push(auditTypeItem[i])
        }

        this.setState({
            selectedAuditType:selectedAuditType,
            formErrors:FormErrorsTemp
        })
    }

    deptDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['deparment'] = "";
        }

        this.setState({
            selectedDepartmentName:item.name,
            selectedDepartmentId:item.departmentId,
            formErrors:FormErrorsTemp
        })
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Delete Master",
                proceedBtnLabel:"Delete",
                deleteReactivateId:dropdownItem.id,
                deleteReactivateMasterType:dropdownItem.masterType,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete master "+dropdownItem.name_new+"?"
            })
        }else if(item.label === "Reactivate"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Activate Master",
                proceedBtnLabel:"Reactivate",
                deleteReactivateId:dropdownItem.id,
                deleteReactivateMasterType:dropdownItem.masterType,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate master "+dropdownItem.name_new+"?"
            })
        }else if(item.label === "Edit"){
            this.setState({
                showCreateDialog:true,
                isEdit:true,
                selectedId:dropdownItem.id,
                masterType:dropdownItem.masterType,
                name:dropdownItem.name_new,
                shortName:dropdownItem.shortName_new,
                description:dropdownItem.description_new,
                selectedAuditType:dropdownItem.auditType?JSON.parse(dropdownItem.auditType.replace(/'/g, '"')).map(auditType => ({ auditType })):[],
                formErrors:{}
            });
        }else if(item.label === "Submit For Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Submit For Review",
                proceedBtnLabel:"Submit",
                deleteReactivateId:dropdownItem.id,
                deleteReactivateMasterType:dropdownItem.masterType,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to submit "+dropdownItem.name_new+" for review?"
            })
        }else if(item.label === "Start Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Start Review",
                proceedBtnLabel:"Start",
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.id,
                deleteReactivateMasterType:dropdownItem.masterType,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to start review of "+dropdownItem.name_new
            })
        }else if(item.label === "Update Review Status"){
            this.setState({
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.id,
                deleteReactivateMasterType:dropdownItem.masterType,
                showUpdateReviewDialog:true,
            })
        }else if(item.label === "Review History"){
            this.setState({
                deleteReactivateId:dropdownItem.id,
                deleteReactivateMasterType:dropdownItem.masterType,
                showReviewHistoryDialog:true,
            })
        }else if(item.label === "Review Remarks"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Review Remarks",
                proceedBtnLabel:"Okay",
                showAlertDialog:true,
                alertDialogMessage:'\"'+dropdownItem.lastReviewRemark+'\"'
            })
        }else if(item.label === "Discard Changes"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Discard Changes",
                proceedBtnLabel:"Discard",
                deleteReactivateId:dropdownItem.id,
                deleteReactivateMasterType:dropdownItem.masterType,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to discard changes of "+dropdownItem.name_new+"?",
                actionType: dropdownItem.previousStatus === "Updated"?"Update":(dropdownItem.previousStatus === "Deleted"?"Delete":"")
            })
        }
        
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getOtherMastersList();
        })
    }
    //copy data dialog
    showCopyOtherMasterDialog = () => {
        let selectedDepartmentId = this.state.selectedDepartmentId;
        let selectedDepartmentName = this.state.selectedDepartmentName;

        if(this.state.departmentArr.length > 1){
            selectedDepartmentId="";
            selectedDepartmentName=departmentDropdownDefaultValue;
        }

        this.setState({
            showCopyOtherMasterDialog:true,
           
            masterType:masterTypeDefaultPlaceholder,
            selectedDepartmentId:selectedDepartmentId,
            selectedDepartmentName:selectedDepartmentName,
            submitClickedAtleastOnce:false,
            formErrors:{}
        });
    }
    handleCopyOtherMasterClose = () =>{
        this.setState({
            showCopyOtherMasterDialog:false,
           
        },()=>{
            this.getOtherMastersList();
        });
    }
    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetOtherMastersCreateMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            }),
            fetch(Constants.GetOtherMasters,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status_new:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    departmentOnly:this.state.deptOnly,
                    masterType:this.state.masterTypeSelected==="All"?"ALL":this.state.masterTypeSelected
                })
            }),
            fetch(Constants.GetUserMenu,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            })
        ])
        .then(([masterRes,generalRes,menuRes]) => { 
            return Promise.all([masterRes.json(),generalRes.json(),menuRes.json()]) 
        })
        .then(([masterRes,generalRes,menuRes]) => {
            let masterTypeArrTemp = [];
            let datalistTemp = [];
            let clientAuditTypeTemp = [];
            let totalCountTemp = 0;
            let menusTemp = {};
            let deptTemp = [];
            let allSettings = [];

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                    masterTypeArrTemp = masterRes.data.masterType;
                    deptTemp = masterRes.data.departments;
                    clientAuditTypeTemp = masterRes.data.clientAuditType;
            }else{
                masterTypeArrTemp = [];
            }

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = generalRes.data.result;
                totalCountTemp = generalRes.data.count;
            }else{
                datalistTemp = [];
            }

            if(menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(menuRes.responseCode === Constants.CODE_SUCCESS){
                menusTemp = menuRes.data.menus;
                allSettings = menuRes.data.allSettings;
            }else{
                menusTemp = {};
            }

            //Order the menu tabs in a specific way
            let menusTemp2 = GetOrderedMasterTabMenu(menusTemp)
            if(allSettings.find(item => item.settingDisplayColumn === Constants.SCREEN_INDUSTRY).settingColumnEnabled === "N"){
                menusTemp2 = Object.fromEntries(Object.entries(menusTemp2).filter(([key, value]) => key !== Constants.SCREEN_INDUSTRY))
            }
            let deletePermission = "N";

            if(menusTemp["Delete Other Masters"] === "ALL"){
                deletePermission = "Y";
            }

            //Initialise Master Type Filter Dropdown Arr
            this.masterTypeFilterArr = JSON.parse(JSON.stringify(masterTypeArrTemp));
            let allObj = {
                masterType:"All",
                masterTypeDesc:"All",
                isSelected:true
            }            
            this.masterTypeFilterArr.splice(0,0,allObj);

            //if user belongs to single dept then dont show the dropdown
            let selectedDepartmentId = "";
            let selectedDepartmentName = departmentDropdownDefaultValue;
            if(!isEmptyArray(deptTemp) && deptTemp.length===1){
                selectedDepartmentId = deptTemp[0].departmentId;
                selectedDepartmentName = deptTemp[0].name;
            }

            //Filter Dept array
            let filterDeptArr = [];
            let selectedFilterDepartmentId = "-100";
            let selectedFilterDepartmentName = "All";
            if(!isEmptyArray(deptTemp) && deptTemp.length===1){

            }else{
                let obj = {
                    departmentId:"-100",
                    name:departmentFilterDropdownDefaultValue,
                    isSelected:true
                }
                filterDeptArr = [...deptTemp];
                filterDeptArr.splice(0,0,obj);
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                masterTypeArr:masterTypeArrTemp,
                clientAuditType:clientAuditTypeTemp,
                datalist:datalistTemp,
                totalCount:totalCountTemp,
                menus:menusTemp2,
                departmentArr:deptTemp,
                deletePermission:deletePermission,

                filterDeptArr:filterDeptArr,
                selectedFilterDepartmentId:selectedFilterDepartmentId,
                selectedFilterDepartmentName:selectedFilterDepartmentName,

                selectedDepartmentId:selectedDepartmentId,
                selectedDepartmentName:selectedDepartmentName
            })
        })
    }

    getOtherMastersList = () => {
        this.setState({
            showLoader:true,
        });

        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            status_new:this.state.status,
            sort:this.state.sort,
            sortDir:this.state.sortDir,
            masterType:this.state.masterTypeSelected==="All"?"ALL":this.state.masterTypeSelected
        }

        if(this.state.filterDeptArr.length > 1 &&
            this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
            postParam.departmentId = this.state.selectedFilterDepartmentId;
        }

        fetch(Constants.GetOtherMasters,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParam)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            let postParam = {
                email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status_new:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    departmentOnly:this.state.deptOnly,
                    masterType:this.state.masterTypeSelected==="All"?"ALL":this.state.masterTypeSelected
            }
    
            if(this.state.filterDeptArr.length > 1 &&
                this.state.selectedFilterDepartmentName !== "All"){
                postParam.departmentId = this.state.selectedFilterDepartmentId;
            }

            fetch(Constants.GetOtherMasters,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    handleRecordOperation = () => {
        let url = "";
        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            masterType:this.state.deleteReactivateMasterType,
            masterDataId:this.state.deleteReactivateId
        }
        let callApi = true;

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteOtherMasters;
        }else if(this.state.operationType === "Reactivate"){
            url = Constants.ReactivateOtherMasters;
        }else if(this.state.operationType === "Submit For Review"){
            url = Constants.SubmitForReviewOtherMasters;
        }else if(this.state.operationType === "Start Review"){
            url = Constants.updateReviewStatusOtherMasters;
            postParam.reviewLevel = this.state.reviewLevel;
            postParam.reviewStatus = Constants.REVIEW_STATUS_STARTED;
        }else if(this.state.operationType === "Discard Changes"){
            url = Constants.DiscardChangeOtherMasters;
            postParam.actionType = this.state.actionType;
        }else if(this.state.operationType === "Review Remarks"){
            callApi = false;
            this.setState({
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
            })
        }

        if(callApi){
            this.setState({
                showBtnLoader:true,
            });

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        deleteReactivateId:"",
                        deleteReactivateMasterType:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        actionType:"",
                        showBtnLoader:false
                    },() => {
                        this.getOtherMastersList();
                    });
                }else{
                    this.setState({
                        showBtnLoader:false,
                        deleteReactivateId:"",
                        deleteReactivateMasterType:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage,
                    })
                }
            });
        }
    }

    handleCreateMaster = () => {
        this.setState({
            showLoader:true,
            submitClickedAtleastOnce:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.masterType) || this.state.masterType === masterTypeDefaultPlaceholder){
            isError = true;
            FormErrorsTemp['masterType'] = "Please select master type";
        }

        if((isEmptyArray(this.state.selectedAuditType) || this.state.selectedAuditType === auditTypeDefaultPlaceholder)
        && this.state.masterType === assignmentType){
            isError=true;
            FormErrorsTemp['selectedAuditTypes'] = "Please select the audit type";
        }

        if(isEmptyVariable(this.state.name)){
            isError = true;
            FormErrorsTemp['name'] = "Please enter master name";
        }

        if(isEmptyVariable(this.state.shortName)){
            isError = true;
            FormErrorsTemp['shortName'] = "Please enter master short name";
        }

        if(!isEmptyVariable(this.state.description)){
            if(this.state.description.length > Constants.DESC_STRING_LENGTH){
                isError = true;
                FormErrorsTemp['description'] = "Description must be less then 500 characters";
            }
        }

        if(!this.state.isEdit){
            if(isEmptyVariable(this.state.selectedDepartmentName) || this.state.selectedDepartmentName === departmentDropdownDefaultValue){
                isError = true;
                FormErrorsTemp['deparment'] = "Please select the department";
            }
        }

        let selectedAuditTypes =[]
        Object.values(this.state.selectedAuditType).map((item)=>{
            selectedAuditTypes.push(item.auditType)
        })

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            let url = "";

            if(this.state.isEdit){
                url = Constants.EditOtherMasters;
            }else{
                url = Constants.CreateOtherMasters;
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    shortName:this.state.shortName,
                    name:this.state.name,
                    description:this.state.description,
                    masterType:this.state.masterType,
                    masterDataId:this.state.selectedId,
                    departmentId:this.state.selectedDepartmentId,
                    selectedAuditTypes:JSON.stringify(selectedAuditTypes)
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showCreateDialog:false,
                        showLoader:false,
                        isEdit:false,
                        selectedId:"",
                        name:"",
                        shortName:"",
                        description:"",
                    },() => {
                        this.getOtherMastersList();
                    });
                }else{
                    this.setState({
                        showLoader:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    });
                }
            });
        }
    }

    /*******************API CALLS END HERE*******************/

    render() {
        let fields = {text:"auditType",value:"auditType"}
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar  />
                                <MasterMenuTabs 
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}
                                />
                                {
                                    this.state.showLoader &&
                                    <div class="loader"></div>
                                }
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    <div className="body-wrapper">
                                        <div className="search-addnew-bg">
                                            <div className="search-addnew">
                                                
                                                <div className="search-filter-layout">
                                                    <FilterDropDown
                                                        placeholder={this.state.resultSizePlaceholder}
                                                        dropdownArr={this.state.resultSizeArr}
                                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                                        themeSettings={themeSettings}
                                                        mleft={0}
                                                    />
                                                    <div className="search-col m-l-10">
                                                        <input 
                                                            name="searchkey" 
                                                            type="text"
                                                            onChange={this.handleChangeSearch}
                                                            placeholder="Search" 
                                                            onKeyPress={this.onEnterBtnPress}
                                                            value={this.state.searchkey}
                                                        />
                                                        <SearchSpan 
                                                        themeSettings={themeSettings}
                                                        onClick = {this.onSearchIconClick} 
                                                        className="material-icons"> search </SearchSpan>
                                                    </div>

                                                    <FilterDropDown
                                                        placeholder={this.state.statusPlaceholder}
                                                        dropdownArr={this.state.statusArr}
                                                        onDropDownItemClick={this.statusDropdownClick}
                                                        themeSettings={themeSettings}
                                                    />
                                                    
                                                    <FilterDropDown
                                                        placeholder={this.state.masterFilterPlaceholder}
                                                        dropdownArr={this.masterTypeFilterArr}
                                                        onDropDownItemClick={this.masterFilterDropdownClick}
                                                        name={"masterTypeDesc"}
                                                        themeSettings={themeSettings}
                                                    />
                                                    {
                                                        this.state.filterDeptArr.length > 0 &&
                                                        <FilterDropDown
                                                            placeholder={this.state.selectedFilterDepartmentName}
                                                            dropdownArr={this.state.filterDeptArr}
                                                            onDropDownItemClick={this.deptFilterDropdownClick}
                                                            name={"name"}
                                                            themeSettings={themeSettings}
                                                            dropdownWidth={"120px"}
                                                        />
                                                    }
                                                </div>
                                                {
                                                    this.state.menus[Constants.SCREEN_OTHER] ==="ALL" && (
                                                        <div className="flex-center-layout" >
                                                            <AddNewButtonLayout themeSettings={themeSettings} style={{marginRight:10}}>
                                                                <a onClick={this.showCopyOtherMasterDialog} href="javascript:void(0);">
                                                                    <span class="material-icons" style={{fontSize:15}}> content_copy </span>
                                                                    <p>Copy Data</p>
                                                                </a>
                                                            </AddNewButtonLayout>

                                                            <AddNewButtonLayout themeSettings={themeSettings}>
                                                                <a onClick={this.showCreateDialog} href="javascript:void(0);">
                                                                    <span className="material-icons">add_circle</span>
                                                                    <p>Add New</p>
                                                                </a>
                                                            </AddNewButtonLayout>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>

                                        {
                                            !isEmptyArray(this.state.datalist) && 
                                            <div className="common-tab-bg">
                                                <div className="common-table" style={{width:"100%"}}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                            <th className="text-center" width={"5%"}>No.</th>
                                                            <th className="c-pointer"  width={"20%"} onClick={this.sortTableLocal.bind(this,sortName)}>
                                                                <div className="sort-header">Name
                                                                <span className={(this.state.sort === sortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDescription)}>
                                                                <div className="sort-header">Description
                                                                <span className={(this.state.sort === sortDescription)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th width={"12%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortMasterType)}>
                                                                <div className="sort-header">Type
                                                                <span className={(this.state.sort === sortMasterType)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            {!isEmptyArray(this.state.departmentArr) && this.state.departmentArr.length > 1 && 
                                                                <th width={"12%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDeptName)}>
                                                                    <div className="sort-header">Department
                                                                    <span className={(this.state.sort === sortDeptName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                    </div>
                                                                </th>
                                                            }
                                                            
                                                            {/* <th width={"10%"}>Users Count</th> */}
                                                            <th className="text-center"  width={"5%"}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            !isEmptyArray(this.state.datalist) && 
                                                            this.state.datalist.map((item,idx) => {

                                                                let actionObj = getDropdownActionArray(item.allowEdit, item.allowReview, 
                                                                    item.status, item.currentReviewStatus, this.state.deletePermission,item.status_new,item.previousStatus);
                                                                let spanObj = getStatusSpan(item.previousStatus,themeSettings);
                                                                return <tr onClick={this.handleDetailsDialogShow.bind(this,item)}>
                                                                    <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                                    {/* <td>{item.name_new+" ("+ item.previousStatus+")"}</td> */}
                                                                    <td>{this.state.status === activeStatus ? item.name : item.name_new} 
                                                                    {
                                                                        this.state.status === underReviewStatus &&
                                                                        <span style={spanObj.spanStyle} className="span-tag">
                                                                            {spanObj.spanText}
                                                                        </span>
                                                                    }   
                                                                    </td>
                                                                    <td>{this.state.status === activeStatus ? truncateString(item.description,55) : truncateString(item.description_new,55)}</td>
                                                                    <td>{item.masterType}</td>
                                                                    {!isEmptyArray(this.state.departmentArr) && this.state.departmentArr.length > 1 && 
                                                                        <td>{item.departmentName}</td>
                                                                    }
                                                                    
                                                                    <td className="text-center">
                                                                        {
                                                                            actionObj.actionArr && actionObj.actionArr.length > 0 &&
                                                                            <TableDropDown 
                                                                                actionArr={actionObj.actionArr}
                                                                                onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                                dropDownId = {item}
                                                                                dropdownWidth={actionObj.dropdownWidth}
                                                                                themeSettings={themeSettings}
                                                                            />
                                                                        }
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                            <div class="no-items-layout">
                                                <div class="no-items-card">
                                                    <h6>
                                                    {
                                                        isEmptyVariable(this.state.apiSearchKey)?
                                                        Constants.NO_RECORDS_WARNING
                                                        :
                                                        Constants.EMPTY_SEARCH_WARNING
                                                    }
                                                    </h6>
                                                </div>
                                            </div>
                                        }
                                        <div className="pagination-layout">
                                            <div className="row custom-row">
                                                <div className="col-md-9 custom-col">
                                                {
                                                    (this.state.totalCount > this.state.datalist.length) && 
                                                    <Pagination 
                                                        totalLength ={this.state.totalCount} 
                                                        items={this.state.datalist} 
                                                        onChangePage={this.onChangePage} 
                                                        pageSize={this.state.resultSize}
                                                        currentPageNo = {this.state.currentPageNo}
                                                        initialPage={this.state.currentPageNo} 
                                                        themeSettings={themeSettings}/>
                                                }
                                                </div>
                                                <div className="col-md-3 custom-col">
                                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                (this.state.operationType === "Review Remarks")
                                ?
                                Constants.ALERT_TYPE_ALERT
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogHeading={this.state.alertDialogHeading}
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.handleRecordOperation}
                            proceedBtnLabel={this.state.proceedBtnLabel}
                            showLoader={this.state.showBtnLoader}
                            themeSettings={themeSettings}
                        />

                         <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings={themeSettings}
                        />

                        <UpdateReviewStatusDialog
                            showUpdateReviewDialog={this.state.showUpdateReviewDialog}
                            handleUpdateReviewDialogClose={this.handleUpdateReviewDialogClose}
                            masterType = {this.state.deleteReactivateMasterType}
                            masterDataId = {this.state.deleteReactivateId}
                            reviewLevel={this.state.reviewLevel}
                            themeSettings={themeSettings}
                        />

                        <ReviewHistoryDialog
                            showReviewHistoryDialog={this.state.showReviewHistoryDialog}
                            handleReviewHistoryDialogClose={this.handleReviewHistoryDialogClose}
                            masterType = {this.state.deleteReactivateMasterType}
                            masterDataId = {this.state.deleteReactivateId}
                        />

                        <SelectCopyOtherMasterDialog
                            showCopyOtherMasterDialog={this.state.showCopyOtherMasterDialog}
                            handleCopyOtherMasterClose={this.handleCopyOtherMasterClose}
                            documentId={this.state.selectedDocumentId}
                            documentName={this.state.selectedDocumentName}
                            handleBackBtnClickFromSamplesandERP={this.handleBackBtnClickFromSamplesandERP}
                            themeSettings={themeSettings}
                        />
                        <Modal enforceFocus={false} className="custom-dialog" show={this.state.showCreateDialog} backdrop="static">
                            <Modal.Header>
                                <h5>{this.state.isEdit?"Edit Master":"Add New Master"}</h5>
                                <button 
                                    onClick={this.handleCreateDialogClose}
                                    type="button" data-dismiss="modal">
                                    <span class="material-icons">close</span>
                                </button>
                            </Modal.Header>
                            <hr />
                            <div class="modal-body">
                                <div class="addnew-modal-form">
                                    {
                                        !this.state.isEdit &&
                                        <div class="addnew-modal-form-group">
                                            <p>Master Type*</p>
                                            <RegularDropdown 
                                                placeholder={this.state.masterType}
                                                dropdownArr={this.state.masterTypeArr}
                                                labelParam="masterTypeDesc"
                                                onDropDownItemClick={this.masterTypeDropdownClick}
                                                isFocusRequired={true}
                                                defaultPlaceholderDropDown = {masterTypeDefaultPlaceholder}
                                            />
                                            {
                                                !isEmptyVariable(this.state.formErrors['masterType']) &&
                                                <span class="cm-error">{this.state.formErrors['masterType']}</span>
                                            }
                                        </div>
                                    }
                                    {this.state.masterType === assignmentType &&
                                        <div class="addnew-modal-form-group">
                                            <p>Assignment Entity*</p>
                                            <ReactSelectDropdown
                                                handleReactSelectClose={this.handleAuditTypeDropdownClick}
                                                selectedDDObj= {this.state.selectedAuditType}
                                                selectDropdownArr={this.state.clientAuditType}
                                                label={"auditType"}
                                                value={"auditType"}
                                                isMultiselect={true}
                                                placeholder={auditTypeDefaultPlaceholder}
                                                isClearSelect={true}
                                            />
                                            {
                                                !isEmptyVariable(this.state.formErrors['selectedAuditTypes']) &&
                                                <span class="cm-error">{this.state.formErrors['selectedAuditTypes']}</span>
                                            }
                                        </div>
                                    }
                                    {
                                        !this.state.isEdit &&
                                        !isEmptyArray(this.state.departmentArr) &&
                                        this.state.departmentArr.length > 1 &&
                                        <div class="addnew-modal-form-group">
                                            <p>Department*</p>
                                            <RegularDropdown 
                                                placeholder={this.state.selectedDepartmentName}
                                                dropdownArr={this.state.departmentArr}
                                                labelParam="name"
                                                onDropDownItemClick={this.deptDropdownClick}
                                                defaultPlaceholderDropDown = {departmentDropdownDefaultValue}
                                            />
                                            {
                                                !isEmptyVariable(this.state.formErrors['deparment']) &&
                                                <span class="cm-error">{this.state.formErrors['deparment']}</span>
                                            }
                                        </div>
                                    }
                                    <div class="addnew-modal-form-group">
                                        <p>Short Name*</p>
                                        <input type="text" placeholder="Enter Short Name"
                                        name="shortName" value={this.state.shortName} onChange={this.handleChange}/>
                                        {
                                            !isEmptyVariable(this.state.formErrors['shortName']) &&
                                            <span class="cm-error">{this.state.formErrors['shortName']}</span>
                                        }
                                    </div>
                                    <div class="addnew-modal-form-group">
                                        <p>Name*</p>
                                        <input type="text" placeholder="Enter Name" spellCheck="true" 
                                        name="name" value={this.state.name} onChange={this.handleChange}/>
                                        {
                                            !isEmptyVariable(this.state.formErrors['name']) &&
                                            <span class="cm-error">{this.state.formErrors['name']}</span>
                                        }
                                    </div>
                                    <div class="addnew-modal-form-group">
                                        <p>Description</p>
                                        <textarea type="text" placeholder="Enter Description" spellCheck="true" 
                                        name="description" value={this.state.description} onChange={this.handleChange}/>
                                        {
                                            !isEmptyVariable(this.state.formErrors['description']) &&
                                            <span class="cm-error">{this.state.formErrors['description']}</span>
                                        }
                                        {/* {
                                            !isEmptyVariable(this.state.formErrors['apiErr']) &&
                                            <span class="cm-error">{this.state.formErrors['apiErr']}</span>
                                        } */}
                                    </div>
                                </div>
                                <div class="modal-close-save">
                                    <div class="close-save-col">
                                        <button onClick={this.handleCreateDialogClose} type="button" class="modal-close">Cancel</button>
                                        <DialogSaveButton themeSettings={themeSettings} onClick={this.handleCreateMaster} type="button">Save</DialogSaveButton>
                                    </div>
                                </div>
                            </div>

                        </Modal>

                        <Modal className="custom-dialog" show={this.state.showDetailsDialog} onHide={this.handleDetailsDialogClose}>
                            <Modal.Header>
                                <h5>{this.state.status === activeStatus ? this.state.selectedItem.shortName
                                : this.state.selectedItem.shortName_new}</h5>
                                <button 
                                    onClick={this.handleDetailsDialogClose}
                                    type="button" data-dismiss="modal">
                                    <span class="material-icons">close</span>
                                </button>
                            </Modal.Header>
                            <hr />
                            <div class="modal-body">
                                <div className="details-table-layout">
                                    <div className="row custom-row">
                                        <div className="col-md-12 custom-col">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td width={"24%"}>Master Type</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.state.selectedItem.masterType}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Short Name</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>
                                                            {this.state.status === activeStatus ? this.state.selectedItem.shortName : this.state.selectedItem.shortName_new}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Name</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>
                                                            {this.state.status === activeStatus ? this.state.selectedItem.name : this.state.selectedItem.name_new}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Description</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.state.status === activeStatus ? 
                                                        ifEmptyReturnEmptyStr(this.state.selectedItem.description,"-") :
                                                        ifEmptyReturnEmptyStr(this.state.selectedItem.description_new,"-")}</td>
                                                    </tr>
                                                    {
                                                        this.state.selectedItem.status !== activeStatus &&
                                                        this.state.selectedItem.status !== inActiveStatus &&
                                                        <tr>
                                                            <td width={"24%"}>Review Status</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{this.state.selectedItem.currentReviewStatus}</td>
                                                        </tr>
                                                    }
                                                    {
                                                        this.state.selectedItem.allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW &&
                                                        this.state.selectedItem.currentReviewStatus.includes(requestChangeCheckText) &&
                                                        <tr>
                                                            <td width={"24%"}>Review Remarks</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"72%"}>{this.state.selectedItem.lastReviewRemark}</td>
                                                        </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </section>
                </main>
            </Router>
        );
    }
}

export default OtherMasters;