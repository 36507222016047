import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout,UserRoleCheckBoxLayout} from '../../common/Global/globalStyles.style';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import UserManagementTopMenu from './topmenu';
import TableDropDown from '../../common/DropdownMenus/TableDropdown';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import AlertDialog from '../../common/AlertDialog';
import Pagination from '../../common/Global/pagination';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import {Modal} from 'react-bootstrap';
import {getLocalStorageVariables,isEmptyVariable,sortTable,truncateString,ifEmptyReturnEmptyStr,
     isEmptyArray,getThemeSettingsVariables} from '../../common/Global/commonFunctions';

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();
const sortReviewRoleName = "r.reviewRole";
const sortDescription = "r.description";
const sortRoleUserCount = "roleUserCount";

class ListUserRoles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"",

            datalist:[],
            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            alertDialogMessage:"",
            showBtnLoader:false,

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showDetailsDialog:false,
            showDialogLoader:false,
            masterScreens:[],
            roleArr:{},
            singleLevelRoleArr :{},
            selectedReviewRoleId:"",
            selectedReviewRoleName:"",
            selectedReviewRoleDesc:"",

            status:"Active",
            statusArr:[
                {
                    label:"Active",
                    isSelected:true
                },
                {
                    label:"Inactive",
                    isSelected:false
                }
            ],
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            
            masterScreensObj:{}
        }
    }

    componentDidMount(){
        this.getFilterValues();
    }

    saveFilterValues = () => {
        let filterParams = {
            resultSize:this.state.resultSize,
            status:this.state.status,
            searchkey:this.state.apiSearchKey,
            currentPageNo:this.state.currentPageNo,
            sort:this.state.sort,
            sortDir:this.state.sortDir,
        }
        localStorage.setItem('userRoleFilters', JSON.stringify(filterParams));
    }

    getFilterValues = () => {
        let resultSize = typeof window !== 'undefined'?localStorage.getItem('userRoleFilters')?JSON.parse(localStorage.getItem('userRoleFilters')).resultSize:'':'';
        let status = typeof window !== 'undefined'?localStorage.getItem('userRoleFilters')?JSON.parse(localStorage.getItem('userRoleFilters')).status:'':'';
        let searchkey = typeof window !== 'undefined'?localStorage.getItem('userRoleFilters')?JSON.parse(localStorage.getItem('userRoleFilters')).searchkey:'':'';
        let currentPageNo = typeof window !== 'undefined'?localStorage.getItem('userRoleFilters')?JSON.parse(localStorage.getItem('userRoleFilters')).currentPageNo:'':'';
        let sort = typeof window !== 'undefined'?localStorage.getItem('userRoleFilters')?JSON.parse(localStorage.getItem('userRoleFilters')).sort:'':'';
        let sortDir = typeof window !== 'undefined'?localStorage.getItem('userRoleFilters')?JSON.parse(localStorage.getItem('userRoleFilters')).sortDir:'':'';

        if(isEmptyVariable(resultSize) &&
        isEmptyVariable(status) && isEmptyVariable(currentPageNo) &&
        isEmptyVariable(searchkey) && isEmptyVariable(sort) && isEmptyVariable(sortDir)){
            this.getUserRolesList();
        }else{
            let resultSizeTemp = (resultSize === undefined)?Constants.RESULT_SIZE:resultSize;
            let statusTemp = (status===undefined)?"Active":status;
            let tempArr = this.state.resultSizeArr;
            for(const [i,value] of tempArr.entries()){
                if(value.label === resultSizeTemp+""){
                    tempArr[i].isSelected = true;
                }else{
                    tempArr[i].isSelected = false;
                }
            }
            
            let statusTempArr = this.state.statusArr;
            let statusPlaceholderTemp = "Active";

            for(const [i,value] of statusTempArr.entries()){
                if(value.label === statusTemp){
                    statusTempArr[i].isSelected = true;
                    statusPlaceholderTemp = statusTemp;
                }else{
                    statusTempArr[i].isSelected = false;
                }
            }

            this.setState({
                resultSizeArr:tempArr,
                statusArr:statusTempArr,
                statusPlaceholder:statusPlaceholderTemp,
                resultSize:resultSizeTemp,
                status:statusTemp,
                searchkey:searchkey===undefined?"":searchkey,
                currentPageNo:currentPageNo===undefined?"":currentPageNo,
                sort:sort===undefined?"":sort,
                sortDir:sortDir===undefined?"":sortDir,
            },() =>{
                this.getUserRolesList();
            })
        }
    }

    getUserRolesList = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetRoles,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                status:this.state.status,
                sort:this.state.sort,
                sortDir:this.state.sortDir
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                },() => {
                    this.saveFilterValues();
                })
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                })
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });
            fetch(Constants.GetRoles,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(
                {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    },() => {
                        this.saveFilterValues();
                    })
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    })
                }
            });
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getUserRolesList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getUserRolesList();
            })
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleDetailsDialogClose = () =>{
        this.setState({
            showDetailsDialog:false,
            selectedReviewRoleId:"",
            selectedReviewRoleName:"",
            masterScreens:[],
            roleArr:{},
        });
    }

    handleDetailsDialogShow = (reviewItem) =>{
        this.setState({
            showDetailsDialog:true,
            selectedReviewRoleId:reviewItem.reviewRoleId,
            selectedReviewRoleName:reviewItem.reviewRole,
            selectedReviewRoleDesc:reviewItem.description
        },()=>{
            this.getUserRoleDetails();
        });
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.reviewRoleId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete user role "+dropdownItem.reviewRole+"?"
            })
        }else if(item.label === "Reactivate"){
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.reviewRoleId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate user role "+dropdownItem.reviewRole+"?"
            })
        }else if(item.label === "Edit"){
            this.props.history.push(Constants.URL_EDITROLE+"/"+dropdownItem.reviewRoleId, {
                 roleUserCount:dropdownItem.roleUserCount 
            });
        }else if(item.label === "Details"){
            this.handleDetailsDialogShow(dropdownItem)
        }
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getUserRolesList();
        })
    }

    deleteActivateUserRole = () => {
        let url = "";

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteRole;
        }else if(this.state.operationType === "Reactivate"){
            url = Constants.ActivateRole;
        }

        this.setState({
            showBtnLoader:true,
        });

        fetch(url,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                reviewRoleId:this.state.deleteReactivateId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                    showBtnLoader:false
                },() => {
                    this.getUserRolesList();
                });
            }else{
                this.setState({
                    showBtnLoader:false,
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                })
            }
        });
    }

    getUserRoleDetails = () => {
        this.setState({
            showDialogLoader:true,
        });

        Promise.all([
            fetch(Constants.MasterScreens,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            }),
            fetch(Constants.GetRoleMatrix,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    reviewRoleId:this.state.selectedReviewRoleId
                })
            }),
        ])
        .then(([masterRes,roleMatrixRes]) => { 
            return Promise.all([masterRes.json(),roleMatrixRes.json()]) 
        })
        .then(([masterRes,roleMatrixRes]) => {
            let masterScreens = [];
            let matrix = [];
            let masterScreensObj = {};
            let newScreensObj = {};

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                masterScreens = masterRes.data.screens;
                
                //map screens based on screenType
                //SAMPLE OUTPUT: {"Tasks":[ {"screenName":"Task", "isMatrix":"Y","screenType":"Tasks" } "Masters":[ { "screenName":"Other Masters", "isMatrix":"Y", "screenType":"Masters"} ]
                masterScreens.forEach((item)=>{
                    if(item.isMatrix==="Y"){
                        if(isEmptyArray(masterScreensObj[item.screenType] )){
                            masterScreensObj[item.screenType]=[];
                        }
                        masterScreensObj[item.screenType].push(item);
                    }    
                })
                newScreensObj = {
                    "Assignment":masterScreensObj["Assignment"],
                    "Tasks":masterScreensObj["Tasks"],
                    "Masters":masterScreensObj["Masters"],
                    "Settings":masterScreensObj["Settings"],
                }
            }else{
                //TODO - show error message inside dialog if possible
            }

            if(roleMatrixRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                roleMatrixRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(roleMatrixRes.responseCode === Constants.CODE_SUCCESS){
                matrix = roleMatrixRes.data.matrix;
            }else{
                //TODO - show error message inside dialog if possible
            }

            let roleArr = {};
            let singleLevelRoleArr = {};

            for(const [idx,value] of matrix.entries()){
                let roleObjTemp = value.screenName + "_";
                let temp = "";

                if(value.ViewOnly === "Y"){
                    temp = roleObjTemp + "ViewOnly";
                    roleArr[temp] = "Y";
                }
                if(value.L0 === "Y"){
                    if (
                        value.screenName === Constants.USER_ROLE_TASK_SEQUENCING ||
                        value.screenName === Constants.USER_ROLE_DASHBOARD_CURRENT ||
                        value.screenName === Constants.USER_ROLE_DASHBOARD_SCHEDULED ||
                        value.screenName === Constants.USER_ROLE_DASHBOARD_PLANNED ||
                        value.screenName === Constants.USER_ROLE_DASHBOARD_DRAFT ||
                        value.screenName === Constants.USER_ROLE_DASHBOARD_COMPLETED ||
                        value.screenName === Constants.USER_ROLE_DASHBOARD_NEW_TASKS ||
                        value.screenName === Constants.USER_ROLE_DASHBOARD_FIGURE_AND_CHART ||
                        value.screenName === Constants.USER_ROLE_DASHBOARD_ASSIGNMENT_SUMMARY ||
                        value.screenName === Constants.USER_ROLE_DEPARTMENT_SETTINGS
                    ) {
                        singleLevelRoleArr[value.screenName] = "Y";
                    }
                    else{
                        temp = roleObjTemp + "0";
                        roleArr[temp] = "Y";
                    }
                }
                if(value.L1 === "Y"){
                    temp = roleObjTemp + "1";
                    roleArr[temp] = "Y";
                }
                if(value.L2 === "Y"){
                    temp = roleObjTemp + "2";
                    roleArr[temp] = "Y";
                }
                if(value.L3 === "Y"){
                    temp = roleObjTemp + "3";
                    roleArr[temp] = "Y";
                }
                if(value.L4 === "Y"){
                    temp = roleObjTemp + "4";
                    roleArr[temp] = "Y";
                }
                if(value.L5 === "Y"){
                    temp = roleObjTemp + "5";
                    roleArr[temp] = "Y";
                }
                if(value.L6 === "Y"){
                    temp = roleObjTemp + "6";
                    roleArr[temp] = "Y";
                }
                if(value.L7 === "Y"){
                    temp = roleObjTemp + "7";
                    roleArr[temp] = "Y";
                }
            }
            this.setState({
                masterScreens:masterScreens,
                masterScreensObj:newScreensObj,
                roleArr:roleArr,
                showDialogLoader:false,
                singleLevelRoleArr:singleLevelRoleArr,
            })
        });
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getUserRolesList();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getUserRolesList();
        })
    }

    render() {
        const actionArr = [
            // {
            //     icon:"info",
            //     label:"Details"
            // },
            {
                icon:"edit",
                label:"Edit"
            },
            {
                icon:"delete",
                label:"Delete"
            },
        ]

        const actionArrReactivate = [
            // {
            //     icon:"info",
            //     label:"Details"
            // },
            {
                icon:"check_circle",
                label:"Reactivate"
            },
        ]

        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                {
                                    this.state.showLoader &&
                                    <div class="loader"></div>
                                }
                                <UserManagementTopMenu 
                                    themeSettings = {themeSettings}
                                />

                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    <div className="body-wrapper">
                                        <div className="search-addnew-bg">
                                            <div className="search-addnew">
                                                <div className="search-filter-layout">
                                                    <FilterDropDown
                                                        placeholder={this.state.resultSizePlaceholder}
                                                        dropdownArr={this.state.resultSizeArr}
                                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                                        themeSettings = {themeSettings}
                                                        mleft={0}
                                                    />
                                                    <div className="search-col m-l-10">
                                                        <input 
                                                            name="searchkey" 
                                                            type="text"
                                                            onChange={this.handleChange}
                                                            placeholder="Search" 
                                                            onKeyPress={this.onEnterBtnPress}
                                                            value={this.state.searchkey} />
                                                        <SearchSpan 
                                                            className="material-icons"
                                                            themeSettings = {themeSettings}
                                                            onClick = {this.onSearchIconClick}>search</SearchSpan>
                                                    </div>
                                                    <FilterDropDown
                                                        placeholder={this.state.statusPlaceholder}
                                                        dropdownArr={this.state.statusArr}
                                                        onDropDownItemClick={this.statusDropdownClick}
                                                        themeSettings = {themeSettings}
                                                    />
                                                </div>
                                                <AddNewButtonLayout
                                                    themeSettings = {themeSettings}
                                                >
                                                    <a href={Constants.URL_ADDROLE} data-toggle="modal" data-target="#addnewModal">
                                                        <span className="material-icons">add_circle</span>
                                                        <p>Add New</p>
                                                    </a>
                                                </AddNewButtonLayout>
                                            </div>
                                        </div>
                                        {
                                            !isEmptyArray(this.state.datalist) && 
                                            <div className="common-tab-bg">
                                                <div className="common-table" style={{width:"100%"}}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                            <th className="text-center" width={"5%"}>No.</th>
                                                            <th className="c-pointer" width={"20%"} onClick={this.sortTableLocal.bind(this,sortReviewRoleName)}>
                                                                <div className="sort-header">User Role
                                                                <span className={(this.state.sort === sortReviewRoleName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDescription)}>
                                                                <div className="sort-header">Description
                                                                <span className={(this.state.sort === sortDescription)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th width={"8%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortRoleUserCount)}>
                                                                <div className="sort-header">Users
                                                                <span className={(this.state.sort === sortRoleUserCount)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="text-center" width={"5%"}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            !isEmptyArray(this.state.datalist) && 
                                                            this.state.datalist.map((item,idx) => {
                                                                return <tr onClick={this.handleDetailsDialogShow.bind(this,item)}>
                                                                    <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                                    <td>{item.reviewRole}</td>
                                                                    <td>{truncateString(item.description,120)}</td>
                                                                    <td>{item.roleUserCount}</td>
                                                                    <td className="text-center">
                                                                        <TableDropDown 
                                                                            actionArr={item.status === "Active"?actionArr:actionArrReactivate}
                                                                            onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                            dropDownId = {item}
                                                                            themeSettings = {themeSettings}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                            <div class="no-items-layout">
                                                <div class="no-items-card">
                                                    <h6>
                                                    {
                                                        isEmptyVariable(this.state.apiSearchKey)?
                                                        Constants.NO_RECORDS_WARNING
                                                        :
                                                        Constants.EMPTY_SEARCH_WARNING
                                                    }
                                                    </h6>
                                                </div>
                                            </div>
                                        }
                                        <div className="pagination-layout">
                                            <div className="row custom-row">
                                                <div className="col-md-9 custom-col">
                                                {
                                                    (this.state.totalCount > this.state.datalist.length) && 
                                                    <Pagination 
                                                        totalLength ={this.state.totalCount} 
                                                        items={this.state.datalist} 
                                                        onChangePage={this.onChangePage} 
                                                        pageSize={this.state.resultSize}
                                                        currentPageNo = {this.state.currentPageNo}
                                                        initialPage={this.state.currentPageNo}
                                                        themeSettings = {themeSettings}
                                                    />
                                                }
                                                </div>
                                                <div className="col-md-3 custom-col">
                                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogHeading={
                                (this.state.operationType === "Delete")
                                ?
                                "Delete User Role"
                                :
                                "Activate User Role"
                            }
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.deleteActivateUserRole}
                            proceedBtnLabel={
                                (this.state.operationType === "Delete")
                                ?
                                "Delete"
                                :
                                "Reactivate"
                            }
                            showLoader={this.state.showBtnLoader}
                            themeSettings = {themeSettings}
                        />

                         <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings = {themeSettings}
                        />

                        <Modal className="user-role-dialog custom-dialog" show={this.state.showDetailsDialog} backdrop="static">
                            <Modal.Header>
                                <h5>{this.state.selectedReviewRoleName}</h5>
                                <button 
                                    onClick={this.handleDetailsDialogClose}
                                    type="button" data-dismiss="modal">
                                    <span class="material-icons">close</span>
                                </button>
                            </Modal.Header>
                            <hr />
                            <Modal.Body>
                                <div className="details-table-layout">
                                    <table className="m-b-10">
                                        <tbody>
                                            <tr>
                                                <td width={"12%"}>Role Name</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"84%"}>{this.state.selectedReviewRoleName}</td>
                                            </tr>
                                            <tr>
                                                <td width={"12%"}>Description</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"84%"}>{ifEmptyReturnEmptyStr(this.state.selectedReviewRoleDesc,"-")}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <h6>Role Matrix</h6>
                                <div className="common-table user-role-table" style={{width: "100%"}}>
                                {
                                    Object.entries(this.state.masterScreensObj).map(([key,value])=>{
                                        return <div class="m-b-10">
                                        <p className="user-role-heading">{key}*</p>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th width="35%">
                                                        Screens
                                                    </th>
                                                    <th>
                                                        View Only
                                                    </th>
                                                    <th>
                                                        Level 0
                                                    </th>
                                                    <th>
                                                        Level 1
                                                    </th>
                                                    <th>
                                                        Level 2
                                                    </th>
                                                    <th>
                                                        Level 3
                                                    </th>
                                                    <th>
                                                        Level 4
                                                    </th>
                                                    <th>
                                                        Level 5
                                                    </th>
                                                    {/* <th>
                                                        Level 6
                                                    </th>
                                                    <th>
                                                        Level 7
                                                    </th> */}
                                                </tr>
                                            </thead>
                                            {
                                                value.map((item)=>{
                                                    return <tbody>
                                                        {
                                                        (item.isMatrix === "Y" ) &&
                                                            <tr>
                                                                <td>
                                                                    {item.screenName}
                                                                </td>
                                                                <td>
                                                                    <UserRoleCheckBoxLayout
                                                                        themeSettings = {themeSettings}
                                                                    >
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={item.screenName+"_ViewOnly"}
                                                                            id={item.screenName+"_ViewOnly"}
                                                                            onChange={this.handleCheck}
                                                                            checked={this.state.roleArr[item.screenName+"_ViewOnly"] === "Y"?true:false}
                                                                        />
                                                                        <label for={item.screenName+"_ViewOnly"}></label>
                                                                    </UserRoleCheckBoxLayout>
                                                                </td>
                                                                <td>
                                                                    <UserRoleCheckBoxLayout
                                                                        themeSettings = {themeSettings}
                                                                    >
                                                                    <input 
                                                                        type="checkbox"
                                                                        name={item.screenName+"_0"}
                                                                        id={item.screenName+"_0"}
                                                                        disabled
                                                                        checked={this.state.roleArr[item.screenName+"_0"] === "Y"?true:false}
                                                                    />
                                                                    <label for={item.screenName+"_0"}></label>
                                                                    </UserRoleCheckBoxLayout>
                                                                </td>
                                                                <td>
                                                                    <UserRoleCheckBoxLayout
                                                                        themeSettings = {themeSettings}
                                                                    >
                                                                    <input 
                                                                        type="checkbox"
                                                                        name={item.screenName+"_1"}
                                                                        id={item.screenName+"_1"}
                                                                        disabled
                                                                        checked={this.state.roleArr[item.screenName+"_1"] === "Y"?true:false}
                                                                    />
                                                                    <label for={item.screenName+"_1"}></label>
                                                                    </UserRoleCheckBoxLayout>
                                                                </td>
                                                                <td>
                                                                    <UserRoleCheckBoxLayout
                                                                        themeSettings = {themeSettings}
                                                                    >
                                                                    <input 
                                                                        type="checkbox"
                                                                        name={item.screenName+"_2"}
                                                                        id={item.screenName+"_2"}
                                                                        disabled
                                                                        checked={this.state.roleArr[item.screenName+"_2"] === "Y"?true:false}
                                                                    />
                                                                    <label for={item.screenName+"_2"}></label>
                                                                    </UserRoleCheckBoxLayout>
                                                                </td>
                                                                <td>
                                                                    <UserRoleCheckBoxLayout
                                                                        themeSettings = {themeSettings}
                                                                    >
                                                                    <input 
                                                                        type="checkbox"
                                                                        name={item.screenName+"_3"}
                                                                        id={item.screenName+"_3"}
                                                                        disabled
                                                                        checked={this.state.roleArr[item.screenName+"_3"] === "Y"?true:false}
                                                                    />
                                                                    <label for={item.screenName+"_3"}></label>
                                                                    </UserRoleCheckBoxLayout>
                                                                </td>
                                                                <td>
                                                                    <UserRoleCheckBoxLayout
                                                                        themeSettings = {themeSettings}
                                                                    >
                                                                    <input 
                                                                        type="checkbox"
                                                                        name={item.screenName+"_4"}
                                                                        id={item.screenName+"_4"}
                                                                        disabled
                                                                        checked={this.state.roleArr[item.screenName+"_4"] === "Y"?true:false}
                                                                    />
                                                                    <label for={item.screenName+"_4"}></label>
                                                                    </UserRoleCheckBoxLayout>
                                                                </td>
                                                                <td>
                                                                    <UserRoleCheckBoxLayout
                                                                        themeSettings = {themeSettings}
                                                                    >
                                                                    <input 
                                                                        type="checkbox"
                                                                        name={item.screenName+"_5"}
                                                                        id={item.screenName+"_5"}
                                                                        disabled
                                                                        checked={this.state.roleArr[item.screenName+"_5"] === "Y"?true:false}
                                                                    />
                                                                    <label for={item.screenName+"_5"}></label>
                                                                    </UserRoleCheckBoxLayout>
                                                                </td>
                                                                {/* <td>
                                                                    <UserRoleCheckBoxLayout
                                                                        themeSettings = {themeSettings}
                                                                    >
                                                                    <input 
                                                                        type="checkbox"
                                                                        name={item.screenName+"_6"}
                                                                        id={item.screenName+"_6"}
                                                                        disabled
                                                                        checked={this.state.roleArr[item.screenName+"_6"] === "Y"?true:false}
                                                                    />
                                                                    <label for={item.screenName+"_6"}></label>
                                                                    </UserRoleCheckBoxLayout>
                                                                </td>
                                                                <td>
                                                                    <UserRoleCheckBoxLayout
                                                                        themeSettings = {themeSettings}
                                                                    >
                                                                    <input 
                                                                        type="checkbox"
                                                                        name={item.screenName+"_7"}
                                                                        id={item.screenName+"_7"}
                                                                        disabled
                                                                        checked={this.state.roleArr[item.screenName+"_7"] === "Y"?true:false}
                                                                    />
                                                                    <label for={item.screenName+"_7"}></label>
                                                                    </UserRoleCheckBoxLayout>
                                                                </td> */}
                                                            </tr>
                                                        }    
                                                    </tbody>
                                                })
                                            }
                                        </table>
                                </div>
                            })
                        }
                                    <div className="non-matrix-role m-t-20">
                                        <table>
                                        {
                                            !isEmptyArray(this.state.masterScreens) && 
                                            this.state.masterScreens.map((item) => {
                                                if(item.isMatrix === "N"){
                                                    return <tr>
                                                        <td width="40%">
                                                            {item.screenName}
                                                        </td>
                                                        <td>
                                                            <UserRoleCheckBoxLayout
                                                            themeSettings = {themeSettings}
                                                            >
                                                            <input 
                                                                type="checkbox"
                                                                name={item.screenName}
                                                                id={item.screenName}
                                                                disabled
                                                                checked={this.state.singleLevelRoleArr[item.screenName] === "Y"?true:false}
                                                            />
                                                            <label for={item.screenName}></label>
                                                            </UserRoleCheckBoxLayout>
                                                        </td>
                                                    </tr>
                                                }
                                            })
                                        }
                                        </table>
                                    </div>
                                </div>
                            </Modal.Body>

                        </Modal>
                    </section>
                </main>
            </Router>
        );
    }
}

export default ListUserRoles;