import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable, 
    isEmptyArray} from '../../../common/Global/commonFunctions';
import {DialogSaveButton} from '../../../common/Global/globalStyles.style';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import ReactSelectDropdown from '../../../common/DropdownMenus/ReactSelectDropdownWithSearch';
import AlertDialog from '../../../common/AlertDialog';

const selectedUserPlaceholder = "Select User";

const userDetails  = getLocalStorageVariables();
class AssignResources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            datalist:[],
            allDataList:[],
            isScheduledEdit:this.props.isEdit?false:true,
            totalCount:0,
            currentPageNo: 1,
            selectedL0Ids:'',
            selectedL1Ids:'',
            selectedL2Ids:'',
            selectedL3Ids:'',
            selectedL4Ids:'',
            selectedL5Ids:'',

            selectedL0User:"Select User",
            selectedL1User:"Select User",
            selectedL2User:"Select User",
            selectedL3User:"Select User",
            selectedL4User:"Select User",
            selectedL5User:"Select User",
            selectedL6User:"Select User",
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        }
    }

    componentDidMount(){
        if(this.props.isEdit){
            this.setState({
                componentDidMountFlag:false,
                showLoader:false,
                datalist:[],
                totalCount:0,
                currentPageNo: 1,

                selectedL0User:"Select User",
                selectedL1User:"Select User",
                selectedL2User:"Select User",
                selectedL3User:"Select User",
                selectedL4User:"Select User",
                selectedL5User:"Select User",
                selectedL6User:"Select User",

            },()=>{
                this.getEditInitData();
            })
        }else{
            this.setState({
                componentDidMountFlag:true,
                showLoader:false,
                datalist:[],
                totalCount:0,
                currentPageNo: 1,
                // formErrors:{},

                selectedL0Ids:[],
                selectedViewIds:[],

                selectedL0User:"Select User",
                selectedL1User:"Select User",
                selectedL2User:"Select User",
                selectedL3User:"Select User",
                selectedL4User:"Select User",
                selectedL5User:"Select User",
                selectedL6User:"Select User",

            },()=>{
                this.getInitData();
            })
        }
    }

    getEditInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetAssignedReportResources,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId:this.props.assignmentId,
                })
            }),
            fetch(Constants.AssignResourceMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId:this.props.assignmentId,
                })
            })
        ])
        .then(([assignedRes, masterRes]) => { 
            return Promise.all([assignedRes.json(), masterRes.json()]) 
        })
        .then(([assignedRes, masterRes]) => {
            let datalist =[];
            let assignmentReviewMatrix =[];

            if(assignedRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignedRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(assignedRes.responseCode === Constants.CODE_SUCCESS){
                assignmentReviewMatrix = assignedRes.data.assignedReportResources;
            }else{
            }

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                datalist = masterRes.data.users;
            }else{
            }

            //Lets modify the datalist
            let selectedReviewUsers = {}

            let finalDataList = [];
            for(const [index,value] of assignmentReviewMatrix.entries()){
                selectedReviewUsers["selectedL"+value.level+"Ids"] = value.userId
                selectedReviewUsers["selectedL"+value.level+"User"] = value.fullName;
                finalDataList = this.changeMainArray(datalist,true,"L"+value.level,value.userId)
            }
            
            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                datalist:finalDataList,
                allDataList:[...finalDataList],
                ...selectedReviewUsers
            })
        })
    }

    getInitData = () => {
        this.setState({
            showLoader:true,
        });
        fetch(Constants.AssignResourceMasterData,
        {
            method:"POST",
            mode:"cors",
            body: new URLSearchParams(
            {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                assignmentId:this.props.assignmentId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    datalist:data.data.users,
                    allDataList:[...data.data.users],
                    showLoader:false,
                    componentDidMountFlag:true
                })
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    componentDidMountFlag:true
                })
            }
        });
    }

    handleCreateMaster = () => {
        let assignmentReviewMatrix = {};
        let atLeastOneUserSelected  = false;
        // let formErrorsTemp = {}

        this.state.datalist.forEach(item=>{
            if(item.isSelected){
                atLeastOneUserSelected  = true;
                let obj = {
                    userId:item.userId,
                    userName:item.firstName+" "+item.lastName,
                    designation:item.designation
                }
                assignmentReviewMatrix[item.position] = obj;
            }
        })

        if(!atLeastOneUserSelected){
            this.setState({
                showAlertDialogInfo:true,
                alertDialogMessageInfo:"Please select atleast one user"
            })
        }else{
            fetch(Constants.AssignReportResources,
                {
                    method: "POST",
                    mode:'cors',
                    body: new URLSearchParams({
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        assignmentId:this.props.assignmentId,
                        reportReviewMatrix:JSON.stringify(assignmentReviewMatrix),
                    })
                })
                .then(response => { return response.json(); } )
                .then(data =>
                {
                    if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                        localStorage.clear();
                        window.location="/";
                    }else if(data.responseCode === Constants.CODE_SUCCESS){
                        this.setState({
                            isScheduledEdit:false,
                            showAlertDialogInfo:true,
                            alertDialogMessageInfo:this.props.isEdit?
                            "Report Resources updated successfully":"Report Resources added successfully"
                        })
                    }else{
                        this.setState({
                            showAlertDialogInfo:true,
                            alertDialogMessageInfo:data.responseMessage
                        })
                    }
                });
        }
    }

    changeMainArray = (arr, flag, position,targetUserId) => {
        let newArr = arr.map((item)=>{
            if(item.userId === targetUserId){
                item.isSelected = flag;
                item.position = position;
            }else{
                if(item.position !== "" &&
                    item.position === position &&
                    item.position !== "View" &&
                    item.position !== "TM"){
                    item.isSelected = !flag;
                    item.position = "";
                }
            }
            return item;
        })
        return newArr;
    }

    l0DropdownClick = (item) => {
        let datalist = [];
        let selectedL0Ids = ''
        if(!isEmptyVariable(item)){
            datalist=this.changeMainArray(this.state.datalist,true,"L0",item.userId)
            selectedL0Ids=item.userId
        }else{
            datalist=this.changeMainArray(this.state.datalist,true,"L0",'')
        }
        this.setState({
            datalist:datalist,
            selectedL0User:item?item.fullName:'',
            selectedL0Ids:selectedL0Ids
        })
    }

    l1DropdownClick = (item) => {
        let datalist = [];
        let selectedL1Ids = ''
        if(!isEmptyVariable(item)){
            datalist=this.changeMainArray(this.state.datalist,true,"L1",item.userId)
            selectedL1Ids = item.userId
        }else{
            datalist=this.changeMainArray(this.state.datalist,true,"L1",'')
        }
        this.setState({
            datalist:datalist,
            selectedL1User:item?item.fullName:'',
            selectedL1Ids:selectedL1Ids
        })
    }

    l2DropdownClick = (item) => {
        let datalist = [];
        let selectedL2Ids =''
        if(!isEmptyVariable(item)){
            datalist=this.changeMainArray(this.state.datalist,true,"L2",item.userId)
            selectedL2Ids = item.userId
        }else{
            datalist=this.changeMainArray(this.state.datalist,true,"L2",'')
        }
        this.setState({
            datalist:datalist,
            selectedL2User:item?item.fullName:'',
            selectedL2Ids:selectedL2Ids
        })
    }

    l3DropdownClick = (item) => {
        let datalist = [];
        let selectedL3Ids = ''
        if(!isEmptyVariable(item)){
            datalist=this.changeMainArray(this.state.datalist,true,"L3",item.userId)
            selectedL3Ids = item.userId
        }else{
            datalist=this.changeMainArray(this.state.datalist,true,"L3",'')
        }
        this.setState({
            datalist:datalist,
            selectedL3User:item?item.fullName:'',
            selectedL3Ids:selectedL3Ids
        })
    }

    l4DropdownClick = (item) => {
        let datalist = this.changeMainArray(this.state.datalist,true,"L4",item.userId)
        this.setState({
            datalist:datalist,
            selectedL4User:item?item.fullName:'',
        })
    }

    l5DropdownClick = (item) => {
        let datalist = this.changeMainArray(this.state.datalist,true,"L5",item.userId)
        this.setState({
            datalist:datalist,
            selectedL5User:item.fullName,
        })
    }

    l6DropdownClick = (item) => {
        let datalist = this.changeMainArray(this.state.datalist,true,"L6",item.userId)
        this.setState({
            datalist:datalist,
            selectedL6User:item.fullName,
        })
    }

    getL0Users = () => {
        let datalist = this.state.datalist;
        //filter
        let filteredArr = datalist.filter(item => item.isSelected !== true);
        return filteredArr;
    }

    getOtherUsers = (position) => {
        let datalist = this.state.datalist;
        //filter
        let filteredArr = datalist.filter(item => item.isSelected !== true 
            || (item.isSelected === true && item.position === position));
        return filteredArr;
    }
    
    handleEditOnClick = ()=>{
        this.setState({
            isScheduledEdit:true
        })
    }

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
    }

    render() {

        let L0List  = this.getOtherUsers("L0");
        let L1List  = this.getOtherUsers("L1");
        let L2List  = this.getOtherUsers("L2");
        let L3List  = this.getOtherUsers("L3");
        let L4List  = this.getOtherUsers("L4");
        let L5List  = this.getOtherUsers("L5");
        let L6List  = this.getOtherUsers("L6");
        

        return(
            
                <div className="modal-body">
                    {
                        this.state.showLoader &&
                        <div class="loader"></div>
                    }
                    
                    {
                        this.state.componentDidMountFlag &&
                        <div class="addnew-modal-form">
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Review L1</p>
                                        <ReactSelectDropdown
                                            handleReactSelectClose={this.l0DropdownClick}
                                            selectDropdownArr={L0List}
                                            selectedDDObj= {this.state.allDataList.filter(e=>this.state.selectedL0Ids==e.userId)}
                                            label={"fullName"}
                                            value={"userId"}
                                            placeholder={this.state.selectedL0User}
                                            isClearSelect={true}
                                            disabled ={!this.state.isScheduledEdit}
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Review L2</p>
                                         <ReactSelectDropdown
                                            handleReactSelectClose={this.l1DropdownClick}
                                            selectedDDObj= {this.state.allDataList.filter(e=>this.state.selectedL1Ids==e.userId)}
                                            selectDropdownArr={L1List}
                                            label={"fullName"}
                                            value={"userId"}
                                            placeholder={this.state.selectedL1User}
                                            isClearSelect={true}
                                            disabled ={!this.state.isScheduledEdit}

                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Review L3</p>
                                         <ReactSelectDropdown
                                            handleReactSelectClose={this.l2DropdownClick}
                                            selectedDDObj= {this.state.allDataList.filter(e=>this.state.selectedL2Ids==e.userId)}
                                            selectDropdownArr={L2List}
                                            label={"fullName"}
                                            value={"userId"}
                                            placeholder={this.state.selectedL2User}
                                            isClearSelect={true}
                                            disabled ={!this.state.isScheduledEdit}

                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div class="addnew-modal-form-group">
                                        <p>Review L4</p>
                                        <ReactSelectDropdown
                                            handleReactSelectClose={this.l3DropdownClick}
                                            selectedDDObj= {this.state.allDataList.filter(e=>this.state.selectedL3Ids==e.userId)}
                                            selectDropdownArr={L3List}
                                            label={"fullName"}
                                            value={"userId"}
                                            placeholder={this.state.selectedL3User}
                                            isClearSelect={true}
                                            disabled ={!this.state.isScheduledEdit}

                                        />

                                        
                                    </div>
                                </div>
                            </div>
                            
                            <div class="modal-close-save">
                                <div class="close-save-col">
                                    <button onClick={this.props.handleAllResourcesDialogClose} type="button" class="modal-close">Cancel</button>
                                    <DialogSaveButton themeSettings={this.props.themeSettings} 
                                    onClick={(this.props.selectedAssignmentStatus=="Scheduled"&&!this.state.isScheduledEdit)?this.handleEditOnClick:this.handleCreateMaster} type="button">
                                    {(this.props.selectedAssignmentStatus=="Scheduled"&&!this.state.isScheduledEdit)?"Edit":"Save"}
                                    </DialogSaveButton>
                                </div>
                            </div>
                        </div>
                    }
                    <AlertDialog
                        showAlertDialog={this.state.showAlertDialogInfo}
                        handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                        type={Constants.ALERT_TYPE_ALERT}
                        alertDialogMessage={this.state.alertDialogMessageInfo}
                        proceedBtnClick={this.handleAlertDialogCloseInfo}
                        proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                        themeSettings={this.props.themeSettings}
                    />
                </div>
        );
    }
}

export default AssignResources;