import React from "react";
import { Modal, Spinner } from "react-bootstrap";
import * as Constants from "../Global/constants";
import { AlertDialogSpan, DialogProceedButton } from "../Global/globalStyles.style";
import { isEmptyVariable } from "../Global/commonFunctions";

class CommonAlertMessageDialog extends React.Component {
    constructor(props) {
      super(props);
    }
  
    render() {
      return (
        <Modal show={this.props.showAlertDialog} backdrop="static">
          <Modal.Body>
            <div class="dialog-layout">
              {this.props.handleAlertDialogClose &&
                <button onClick={this.props.handleAlertDialogClose}>
                  <span className="material-icons">close</span>
                </button>
              }
              {this.props.type === Constants.ALERT_TYPE_WARNING && (
                <span className="material-icons">{this.props.alertDialogHeadingIcon ?? 'warning'}</span>
              )}
              {(this.props.type === Constants.ALERT_TYPE_ALERT ||
                this.props.type === Constants.ALERT_TYPE_INFO) && (
                <AlertDialogSpan
                  themeSettings={this.props.themeSettings}
                  className="material-icons"
                >
                  {this.props.alertDialogHeadingIcon ?? 'error_outline'}
                </AlertDialogSpan>
              )}
              {
                !isEmptyVariable(this.props.alertDialogHeading) && (
                  <h5>{this.props.alertDialogHeading}</h5>
                )
              }
              <p style={{ wordBreak: "break-word" }} dangerouslySetInnerHTML={{__html: this.props.alertDialogMessage}}></p>
             
              <div class="dialog-btn-layout">
                {!(this.props.type === Constants.ALERT_TYPE_ALERT) && (
                  <a
                    class="alert-cancel"
                    href="javascript:void(0);"
                    onClick={this.props.cancelBtnClick}
                  >
                    {this.props.cancelBtnLabel ?? 'Cancel'}
                  </a>
                )}
                {this.props.type === Constants.ALERT_TYPE_WARNING && (
                  <a
                    class="alert-dialog-warning"
                    href="javascript:void(0);"
                    onClick={this.props.proceedBtnClick}
                    style={{
                      marginLeft:
                        this.props.type === Constants.ALERT_TYPE_ALERT
                          ? "0px"
                          : "10px",
                    }}
                  >
                    {this.props.showLoader ? <div class="loader-btn mt-2"></div> : this.props.proceedBtnLabel}
                  </a>
                )}
  
                {(this.props.type === Constants.ALERT_TYPE_ALERT ||
                  this.props.type === Constants.ALERT_TYPE_INFO) && (
                  <DialogProceedButton
                    themeSettings={this.props.themeSettings}
                    class="alert-proceed"
                    href="javascript:void(0);"
                    onClick={this.props.proceedBtnClick}
                    style={{
                      marginLeft:
                        this.props.type === Constants.ALERT_TYPE_ALERT
                          ? "0px"
                          : "10px",
                    }}
                  >
                    {this.props?.loading ? <Spinner animation="border" variant="light" className='spinner-border-sm' /> : this.props.proceedBtnLabel}
                  </DialogProceedButton>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      );
    }
  }
  
  export default CommonAlertMessageDialog;