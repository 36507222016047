import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { SearchSpan, TableIcon } from "../../../common/Global/globalStyles.style";
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";
import Pagination from "../../../common/Global/pagination";
import { getLocalStorageVariables, isEmptyVariable, sortTable, truncateString, isEmptyArray, getOnlyFileNameFromPath } from "../../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";

const userDetails = getLocalStorageVariables();
const sortDocumentName = "documentPath";
const sortRemarks = "remark";

class DocumentSampleList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,
			apiSearchKey: "",
			searchkey: "",
			currentPageNo: 1,
			showLoader: false,
			resultSize: Constants.RESULT_SIZE,
			totalCount: 0,
			datalist: [],
			resultSizePlaceholder: Constants.RESULT_SIZE ,
			resultSizeArr: Constants.RESULT_SIZE_DD_ARR,
			sort: "",
			sortDir: "",

			showDetailsDialog: false,
			selectedItem: "",
		};
	}

	componentDidMount() {
		// this.getDocumentSampleList();
	}
	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showSampleDocumentDialog) {
            this.getDocumentSampleList();
        }
	}

	// handleChangeSearch = (e) => {
	// 	const { name, value } = e.target;

	// 	this.setState({
	// 		[name]: value,
	// 	});
	// };

	// onSearchIconClick = () => {
	// 	this.setState(
	// 		{
	// 			currentPageNo: 1,
	// 		},
	// 		() => {
	// 			this.getDocumentSampleList();
	// 		}
	// 	);
	// };

	// onEnterBtnPress = (e) => {
	// 	var code = e.keyCode || e.which;
	// 	if (code === 13) {
	// 		this.setState(
	// 			{
	// 				currentPageNo: 1,
	// 			},
	// 			() => {
	// 				this.getDocumentSampleList();
	// 			}
	// 		);
	// 	}
	// };

	// resultSizeDropdownClick = (item, selectedIndex) => {
	// 	let tempArr = this.state.resultSizeArr;
	// 	let placeholder = "";

	// 	for (const [i, value] of tempArr.entries()) {
	// 		if (i === selectedIndex) {
	// 			tempArr[i].isSelected = true;
	// 			placeholder = value.label ;
	// 		} else {
	// 			tempArr[i].isSelected = false;
	// 		}
	// 	}

	// 	this.setState(
	// 		{
	// 			resultSizeArr: tempArr,
	// 			resultSize: item.label,
	// 			resultSizePlaceholder: placeholder,
	// 		},
	// 		() => {
	// 			this.getDocumentSampleList();
	// 		}
	// 	);
	// };

	handleDetailsDialogClose = () => {
		this.setState({
			showDetailsDialog: false,
			selectedItem: "",
		});
	};

	handleDetailsDialogShow = (item) => {
		this.setState({
			showDetailsDialog: true,
			selectedItem: item,
		});
	};

	sortTableLocal = (sortColumn) => {
		let sortObj = sortTable(sortColumn, this.state.sort, this.state.sortDir);

		this.setState(
			{
				sort: sortObj.sortTemp,
				sortDir: sortObj.sortDirTemp,
			},
			() => {
				this.getDocumentSampleList();
			}
		);
	};
	/*****************************API*********************************/
	downloadAttachment = (documentPath, e) => {
		e.stopPropagation();
		fetch(Constants.API_SERVER_PATH + documentPath)
			.then((response) => {
				return response.blob();
			})
			.then((blob) => {
				let fileName = getOnlyFileNameFromPath(documentPath);
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			});
	};

	getDocumentSampleList = () => {
		fetch(Constants.GetDocumentSamples, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				documentId: this.props.documentId,
				// pageNo: this.state.currentPageNo,
				// search: this.state.searchkey,
				// resultsize: this.state.resultSize,
				sort: this.state.sort,
				sortDir: this.state.sortDir,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState({
						showLoader: false,
						componentDidMountFlag: true,
						datalist: data.data.result,
						totalCount: data.data.count,
						apiSearchKey: this.state.searchkey,
					});
				} else {
					this.setState({
						showLoader: false,
						componentDidMountFlag: true,
						datalist: [],
						totalCount: 0,
						apiSearchKey: this.state.searchkey,
					});
				}
			});
	};

	// onChangePage = (page) => {
	// 	// update state with new page of items
	// 	if (page !== this.state.currentPageNo) {
	// 		this.setState({
	// 			showLoader: true,
	// 		});

	// 		fetch(Constants.GetDocumentSamples, {
	// 			method: "POST",
	// 			mode: "cors",
	// 			body: new URLSearchParams({
	// 				email: userDetails.email,
	// 				accessToken: userDetails.accessToken,
	// 				documentId: this.props.documentId,
	// 				pageNo: page,
	// 				search: this.state.searchkey,
	// 				resultsize: this.state.resultSize,
	// 				sort: this.state.sort,
	// 				sortDir: this.state.sortDir,
	// 			}),
	// 		})
	// 			.then((response) => {
	// 				return response.json();
	// 			})
	// 			.then((data) => {
	// 				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
	// 					localStorage.clear();
	// 					window.location = "/";
	// 				} else if (data.responseCode === Constants.CODE_SUCCESS) {
	// 					this.setState({
	// 						showLoader: false,
	// 						componentDidMountFlag: true,
	// 						datalist: data.data.result,
	// 						totalCount: data.data.count,
	// 						apiSearchKey: this.state.searchkey,
	// 						currentPageNo: page,
	// 					});
	// 				} else {
	// 					this.setState({
	// 						showLoader: false,
	// 						componentDidMountFlag: true,
	// 						datalist: [],
	// 						totalCount: 0,
	// 						apiSearchKey: this.state.searchkey,
	// 					});
	// 				}
	// 			});
	// 	}
	// };

	render() {
		return (
			<section>
				<Modal className="task-select-table-dialog custom-dialog" show={this.props.showSampleDocumentDialog} onHide={this.props.handleSampleDocDialogClose}>
					<Modal.Header>
						<h5>{this.props.documentName + " Samples"}</h5>
						<button onClick={this.props.handleSampleDocDialogClose} type="button" data-dismiss="modal">
							<span class="material-icons">close</span>
						</button>
					</Modal.Header>
					<hr />
					<div className="modal-body">
						{this.state.showLoader && (
							<div class="loader"></div>
						)}

						{
							// Need to check component did mount flag, other wise until api is called,
							// it shows no items found layout.
							this.state.componentDidMountFlag && (
								<div className="body-wrapper">
									{/* <div className="flex-center-layout secondary-top-bar" style={{ marginTop: -10 }}>
									<div className="back-btn-layout" onClick={this.props.handleBackBtnClickFromSamplesandERP}>
										<span class="material-icons-outlined">arrow_back_ios</span>
									</div>
									<h6>{this.props.documentName + "\u00A0\u00A0>\u00A0\u00A0Samples"}</h6>
								</div> */}

									{!isEmptyArray(this.state.datalist) && (
										<div className="common-tab-bg">
											<div className="common-table" style={{ width: "100%" }}>
												<table className="table">
													<thead>
														<tr>
															<th className="text-center" width={"5%"}>
																No.
															</th>
															<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortDocumentName)}>
																<div className="sort-header">
																	File Name
																	<span
																		className={
																			this.state.sort === sortDocumentName ? "material-icons" : "material-icons hide-sort-arrow"
																		}
																	>
																		{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																	</span>
																</div>
															</th>
															<th width={"35%"} className="c-pointer" onClick={this.sortTableLocal.bind(this, sortRemarks)}>
																<div className="sort-header">
																	Remarks
																	<span
																		className={this.state.sort === sortRemarks ? "material-icons" : "material-icons hide-sort-arrow"}
																	>
																		{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																	</span>
																</div>
															</th>
															<th className="text-center" width={"10%"}>
																Download
															</th>
														</tr>
													</thead>
													<tbody>
														{!isEmptyArray(this.state.datalist) &&
															this.state.datalist.map((item, idx) => {
																return (
																	<tr onClick={this.handleDetailsDialogShow.bind(this, item)}>
																		<td className="text-center">{(this.state.currentPageNo - 1) * this.state.resultSize + ++idx}</td>
																		<td>{getOnlyFileNameFromPath(item.documentPath)}</td>
																		<td>{truncateString(item.remark, Constants.REMARKS_STRING_LENGTH)}</td>
																		<TableIcon themeSettings={this.props.themeSettings} className="text-center">
																			<span class="material-icons" onClick={this.downloadAttachment.bind(this, item.documentPath)}>
																				get_app
																			</span>
																		</TableIcon>
																	</tr>
																);
															})}
													</tbody>
												</table>
											</div>
										</div>
									)}
									{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
										<div class="no-items-layout">
											<div class="no-items-card" style={{ padding: 40 }}>
												<h6>{isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}</h6>
											</div>
										</div>
									)}
									{/* <div className="pagination-layout">
									<div className="row custom-row">
										<div className="col-md-9 custom-col">
											{this.state.totalCount > this.state.datalist.length && (
												<Pagination
													totalLength={this.state.totalCount}
													items={this.state.datalist}
													onChangePage={this.onChangePage}
													pageSize={this.state.resultSize}
													currentPageNo={this.state.currentPageNo}
													initialPage={this.state.currentPageNo}
													themeSettings={this.props.themeSettings}
												/>
											)}
										</div>
										<div className="col-md-3 custom-col">
											<p>{"Total Records: " + this.state.totalCount}</p>
										</div>
									</div>
								</div> */}
								</div>
							)
						}
					</div>
				</Modal>

				<Modal className="custom-dialog" show={this.state.showDetailsDialog} onHide={this.handleDetailsDialogClose}>
					<Modal.Header>
						<h5>{this.props.documentName + " Sample"}</h5>
						<button onClick={this.handleDetailsDialogClose} type="button" data-dismiss="modal">
							<span class="material-icons">close</span>
						</button>
					</Modal.Header>
					<hr />
					<div class="modal-body">
						<div className="details-table-layout">
							<div className="row custom-row">
								<div className="col-md-12 custom-col">
									<table>
										<tbody>
											<tr>
												<td width={"24%"}>Document Name</td>
												<td width={"4%"}>:</td>
												<td width={"72%"}>{this.props.documentName}</td>
											</tr>
											<tr>
												<td width={"24%"}>Document Sample Name</td>
												<td width={"4%"}>:</td>
												<td width={"72%"} style={{ wordBreak: "break-word" }}>
													{getOnlyFileNameFromPath(this.state.selectedItem.documentPath)}
												</td>
											</tr>
											<tr>
												<td width={"24%"}>Remarks</td>
												<td width={"4%"}>:</td>
												<td width={"72%"}>{this.state.selectedItem.remark}</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			</section>
		);
	}
}

export default DocumentSampleList;
