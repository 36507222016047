import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,
    isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import {TreeNodeCheckBox,DialogSaveButton} from '../../../common/Global/globalStyles.style';
import AlertDialog from '../../../common/AlertDialog';

const userDetails  = getLocalStorageVariables();

const ProcessTree = (props) => {
    console.log(JSON.stringify(props.processObj))
    return <div className="dialog-tree-structure">
        {
            !isEmptyVariable(props.processObj) &&
            <div className="tree-node checkboxparent" style={{marginLeft:(props.level*40)+"px"}}>
                <span 
                    onClick={()=>props.onClickNode(props.processObj)}
                    class="material-icons tree-span-bg" style={{opacity:isEmptyArray(props.processObj.children)?"0":"1"}}>
                    {props.processObj.isShowingChildren?"keyboard_arrow_down":"keyboard_arrow_right"}
                </span>

                <TreeNodeCheckBox themeSettings={props.themeSettings}>
                    <input 
                        type="checkbox"
                        name={props.processObj.processId}
                        id={props.processObj.processId}
                        onChange={props.handleSelection}
                        checked={(props.processObj.processId+""===props.checkedProcessId+"")?true:false}
                        disabled={(props.processObj.isLink || props.processObj.isParentLink)}
                    />
                    <label for={props.processObj.processId} style={{marginLeft:0}} className={props.fontbold?"font-bold":""}>
                        {props.processObj.processName}
                    </label>
                </TreeNodeCheckBox>
                
                {
                    //show link tag if it is a link
                    <div className="tree-text-layout">
                        {
                            (props.processObj.isLink || props.processObj.isParentLink) &&
                            <span className="link-tree-tag">Link</span>
                        }
                    </div>
                }
            </div>
        }

        {
            //Exit Condition
            !isEmptyVariable(props.processObj) &&
            props.processObj.isShowingChildren &&
            props.getChildNodes(props.processObj).map((child)=>{
                let levelTemp = props.level + 1;
                return <ProcessTree 
                    processObj = {child} 
                    getChildNodes = {props.getChildNodes}
                    level={levelTemp}
                    onClickNode={props.onClickNode}
                    handleSelection={props.handleSelection}
                    checkedProcessId={props.checkedProcessId}
                    childProcessId={props.childProcessId}
                    themeSettings={props.themeSettings}
                />
            })
        }
    </div>
}

class TargetProcessTreeDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader:false,

            processMap:{},
            rootProcessArr:[],
            checkedProcessId:"",
            functionErr:"",
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showTargetProcessTreeDialog){
            this.setState({
                showLoader:false,
    
                processMap:{},
                rootProcessArr:[],
                checkedProcessId:"",
                checkedRootProcessId:"",
                functionErr:"",
            },()=>{
                this.getProcessTree();
            })
            
        }
    }

    getRootLevelProcesses = (processMapTemp) => {
        let rootProcessArr = [];
        Object.values(processMapTemp).map((item)=>{
            if(item.level === 0){
                rootProcessArr.push(item);
            }
        })
        return rootProcessArr;
    }

    addRootProcessId = (processMapTemp, currentProcessId, rootProcessId,isParentLink) => {
        processMapTemp[currentProcessId].rootProcessId = rootProcessId;
        processMapTemp[currentProcessId].isParentLink = isParentLink;

        let isLink = false;
        if(currentProcessId !== rootProcessId){
            if(processMapTemp[currentProcessId].linkCount > 1){
                isLink = true;
            }
        }else{
            if(processMapTemp[currentProcessId].linkCount > 0){
                isLink = true;
            }
        }
        processMapTemp[currentProcessId].isLink = isLink;

        processMapTemp[currentProcessId].children.map((child)=>{
            this.addRootProcessId(processMapTemp,child.processId,rootProcessId,processMapTemp[currentProcessId].isLink);
        })
    }

    addRootProcessIdToAllNodes = (processMapTemp,rootProcessArr) => {
        rootProcessArr.map((rootProcess) => {
            this.addRootProcessId(processMapTemp,rootProcess.processId,rootProcess.processId,false);
        })
    }

    handleSelection = (e) => {
        // e.stopPropagation();
        let isChecked = e.target.checked;
        let processId = e.target.name;

        if(isChecked){
            let checkedRootProcessId = this.state.processMap[processId].rootProcessId;
            this.setState({
                checkedProcessId:processId,
                checkedRootProcessId:checkedRootProcessId
            })
        }else{
            this.setState({
                checkedProcessId:"",
                checkedRootProcessId:""
            })
        }
    }

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
    }
    /***************************Process Dialog functions************************/

    getChildNodes = (node) => {
        if(isEmptyArray(node.children)){
            return [];
        }else{
            return node.children.map(item => this.state.processMap[item.processId]);
        }
    }

    onClickNode = (node) => {
        if(this.state.processMap[node.processId].isShowingChildren){
            this.state.processMap[node.processId].isShowingChildren = false;
        }else{
            this.state.processMap[node.processId].isShowingChildren = true;
        }
        
        this.setState({
            refreshFlag:!this.state.refreshFlag
        })
    }

    /************************API CALLS **************************/

    getProcessTree = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetProcessesSubtree,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                // rootProcessId:10
                // rootProcessId:this.props.rootProcessId,
                // status:"ALL"
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let rootProcessArr = this.getRootLevelProcesses(data.result.processMap);
                let processMapTemp = data.result.processMap;
                this.addRootProcessIdToAllNodes(processMapTemp,rootProcessArr);
                console.log(JSON.stringify(processMapTemp));
                this.setState({
                    rootProcessArr:rootProcessArr,
                    processMap:processMapTemp,
                    showLoader:false,
                });
            }else{
                this.setState({
                    processMap:{},
                    showLoader:false,
                });
            }
        });
    }

    linkProcessToTarget = () => {
        this.setState({
            showLoader:true,
        });

        if(isEmptyVariable(this.state.checkedProcessId)){
            this.setState({
                showLoader:false,
                showAlertDialogInfo:true,
                alertDialogMessageInfo:"Please select a process"
            });
        }else{
            fetch(Constants.LinkProcesses,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    parentProcessId:this.state.checkedProcessId,
                    childProcess:JSON.stringify({processId:this.props.childProcess}),
                    rootProcessId:this.state.checkedRootProcessId
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                    },()=>{
                        this.props.handleTargetProcessTreeDialogClose(true);
                    })
                }else{
                    this.setState({
                        showLoader:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    })
                }
            });
        }
    }

    render() {
        return(
            <div>
                <Modal className="show-process-tree-dialog custom-dialog" 
                show={this.props.showTargetProcessTreeDialog} backdrop="static">
                    <Modal.Header>
                        <h5>Select Target Process</h5>
                        <button 
                            onClick={this.props.handleTargetProcessTreeDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        {
                            this.state.showLoader &&
                            <div class="loader"></div>
                        }
                        <div className="body-wrapper" style={{minHeight:260}}>
                            {
                                this.state.rootProcessArr.map((processItem)=>{
                                    return <ProcessTree 
                                        processObj = {processItem} 
                                        getChildNodes = {this.getChildNodes}
                                        level={0}
                                        onClickNode={this.onClickNode}  //EXPAND/COLLAPSE
                                        fontbold={true}
                                        handleSelection={this.handleSelection}
                                        checkedProcessId={this.state.checkedProcessId}
                                        childProcessId={this.props.childProcess}
                                        themeSettings={this.props.themeSettings}
                                    />
                                })
                            }
                        </div>
                        {/* {
                            !isEmptyVariable(this.state.processErr) &&
                            <span className="cm-error">{this.state.processErr}</span>
                        } */}
                        <div className="modal-close-save m-t-15">
                            <div className="close-save-col">
                                <button onClick={this.props.handleTargetProcessTreeDialogClose} type="button" className="modal-close m-b-0">Cancel</button>
                                <DialogSaveButton 
                                themeSettings={this.props.themeSettings}
                                onClick={this.linkProcessToTarget} type="button" className="m-b-0">Save</DialogSaveButton>
                            </div>
                        </div>
                    </div>
                </Modal>
                <AlertDialog
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type={Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                    themeSettings={this.props.themeSettings}
                />
            </div>
        );
    }
}

export default TargetProcessTreeDialog;