import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import AlertDialog from '../../common/AlertDialog';
import {ProfileSidebarMenuSample,HeaderRight,SaveButton} from './profile.style';
import {PageSaveButton,ProfileIcon} from '../../common/Global/globalStyles.style';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import {getLocalStorageVariables,isEmptyVariable, validatePassword,getThemeSettingsVariables,
    getDefaultStylesObj} from '../../common/Global/commonFunctions';
import { SketchPicker } from 'react-color';
import { AFCONS_VERSION } from '../../common/Global/url';
import RegularDD from '../../common/DropdownMenus/RegularDropdown';

let userDetails  = getLocalStorageVariables();
let themeSettings  = getThemeSettingsVariables();

class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader:false,

            curpass:"",
            newpass:"",
            conpass:"",
            formErrors:{},

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            sidebarBg:"",
            sidebarCPFlag:false,
            
            sidebarBorderRight:"",
            sidebarBorderRightCPFlag:false,
            
            sidebarTextColor:"",
            sidebarTextColorCPFlag:false,

            sidebarDividerColor:"",
            sidebarDividerColorCPFlag:false,

            sidebarIconColor:"",
            sidebarIconColorCPFlag:false,
            
            sidebarSelectedItemBg:"",
            sidebarSelectedItemBgCPFlag:false,
            
            sidebarSelectedItemTextColor:"",
            sidebarSelectedItemTextColorCPFlag:false,
            
            sidebarSelectedItemIconColor:"",
            sidebarSelectedItemIconColorCPFlag:false,
            
            sidebarHoverItemBg:"",
            sidebarHoverItemBgCPFlag:false,
            
            sidebarHoverItemTextColor:"",
            sidebarHoverItemTextColorCPFlag:false,
            
            sidebarHoverItemIconColor:"",
            sidebarHoverItemIconColorCPFlag:false,
            
            topbarBg:"",
            topbarBgCPFlag:false,
            
            topbarIconColor:"",
            topbarIconColorCPFlag:false,
            
            topbarSelectedIconBg:"",
            topbarSelectedIconBgCPFlag:false,
            
            notificationBadgeBg:"",
            notificationBadgeBgCPFlag:false,
            
            badgeTextColor:"",
            badgeTextColorCPFlag:false,
            
            themeColor:"",
            themeColorCPFlag:false,
            
            themeTextColor:"",
            themeTextColorCPFlag:false,
            
            themeHoverColor:"",
            themeHoverColorCPFlag:false,
            
            themeHoverTextColor:"",
            themeHoverTextColorCPFlag:false,

            openColorPickerStr:"",

        }
    }

    componentDidMount(){
        this.initializeStateFromStorage();
    }

    initializeStateFromStorage = () => {
        let fullName = userDetails.firstName+" "+userDetails.lastName
        let initialIcon = fullName.split(" ").map(name => name[0]).join('').toUpperCase();
        
        this.setState({
            themeColor:themeSettings.themeColor,
            themeTextColor:themeSettings.themeTextColor,
            themeHoverColor:themeSettings.themeHoverColor,
            themeHoverTextColor:themeSettings.themeHoverTextColor,

            sidebarBg:themeSettings.sidebarBg,
            sidebarBorderRight:themeSettings.sidebarBorderRight,
            sidebarDividerColor:themeSettings.sidebarDividerColor,
            sidebarTextColor:themeSettings.sidebarTextColor,
            sidebarIconColor:themeSettings.sidebarIconColor,
            sidebarSelectedItemBg:themeSettings.sidebarSelectedItemBg,
            sidebarSelectedItemTextColor:themeSettings.sidebarSelectedItemTextColor,
            sidebarSelectedItemIconColor:themeSettings.sidebarSelectedItemIconColor,
            sidebarHoverItemBg:themeSettings.sidebarHoverItemBg,
            sidebarHoverItemTextColor:themeSettings.sidebarHoverItemTextColor,
            sidebarHoverItemIconColor:themeSettings.sidebarHoverItemIconColor,
            
            topbarBg:themeSettings.topbarBg,
            topbarIconColor:themeSettings.topbarIconColor,
            topbarSelectedIconBg:themeSettings.topbarSelectedIconBg,
            notificationBadgeBg:themeSettings.notificationBadgeBg,
            badgeTextColor:themeSettings.badgeTextColor,
            initialIcon:initialIcon
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    openColorPicker = (idStr) => {
        if(idStr==="themeColor"){
            this.setState({
                themeColorCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="themeTextColor"){
            this.setState({
                themeTextColorCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="themeHoverColor"){
            this.setState({
                themeHoverColorCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="themeHoverTextColor"){
            this.setState({
                themeHoverTextColorCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="sidebarBg"){
            this.setState({
                sidebarCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="sidebarBorderRight"){
            this.setState({
                sidebarBorderRightCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="sidebarDividerColor"){
            this.setState({
                sidebarDividerColorCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="sidebarTextColor"){
            this.setState({
                sidebarTextColorCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="sidebarIconColor"){
            this.setState({
                sidebarIconColorCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="sidebarSelectedItemBg"){
            this.setState({
                sidebarSelectedItemBgCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="sidebarSelectedItemTextColor"){
            this.setState({
                sidebarSelectedItemTextColorCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="sidebarSelectedItemIconColor"){
            this.setState({
                sidebarSelectedItemIconColorCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="sidebarHoverItemBg"){
            this.setState({
                sidebarHoverItemBgCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="sidebarHoverItemTextColor"){
            this.setState({
                sidebarHoverItemTextColorCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="sidebarHoverItemIconColor"){
            this.setState({
                sidebarHoverItemIconColorCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="topbarBg"){
            this.setState({
                topbarBgCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="topbarIconColor"){
            this.setState({
                topbarIconColorCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="topbarSelectedIconBg"){
            this.setState({
                topbarSelectedIconBgCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="notificationBadgeBg"){
            this.setState({
                notificationBadgeBgCPFlag:true,
                openColorPickerStr:idStr
            })
        }else if(idStr==="badgeTextColor"){
            this.setState({
                badgeTextColorCPFlag:true,
                openColorPickerStr:idStr
            })
        }
    }

    handleColorPickerClose = (idStr) => {
        if(idStr==="themeColor"){
            this.setState({
                themeColorCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="themeTextColor"){
            this.setState({
                themeTextColorCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="themeHoverColor"){
            this.setState({
                themeHoverColorCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="themeHoverTextColor"){
            this.setState({
                themeHoverTextColorCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="sidebarBg"){
            this.setState({
                sidebarCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="sidebarBorderRight"){
            this.setState({
                sidebarBorderRightCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="sidebarDividerColor"){
            this.setState({
                sidebarDividerColorCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="sidebarTextColor"){
            this.setState({
                sidebarTextColorCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="sidebarIconColor"){
            this.setState({
                sidebarIconColorCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="sidebarSelectedItemBg"){
            this.setState({
                sidebarSelectedItemBgCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="sidebarSelectedItemTextColor"){
            this.setState({
                sidebarSelectedItemTextColorCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="sidebarSelectedItemIconColor"){
            this.setState({
                sidebarSelectedItemIconColorCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="sidebarHoverItemBg"){
            this.setState({
                sidebarHoverItemBgCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="sidebarHoverItemTextColor"){
            this.setState({
                sidebarHoverItemTextColorCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="sidebarHoverItemIconColor"){
            this.setState({
                sidebarHoverItemIconColorCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="topbarBg"){
            this.setState({
                topbarBgCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="topbarIconColor"){
            this.setState({
                topbarIconColorCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="topbarSelectedIconBg"){
            this.setState({
                topbarSelectedIconBgCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="notificationBadgeBg"){
            this.setState({
                notificationBadgeBgCPFlag:false,
                openColorPickerStr:""
            })
        }else if(idStr==="badgeTextColor"){
            this.setState({
                badgeTextColorCPFlag:false,
                openColorPickerStr:""
            })
        }
    }

    handleColorPickerChange = (color) => {
        if(this.state.openColorPickerStr==="themeColor"){
            this.setState({
                themeColor:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="themeTextColor"){
            this.setState({
                themeTextColor:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="themeHoverColor"){ 
            this.setState({
                themeHoverColor:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="themeHoverTextColor"){ 
            this.setState({
                themeHoverTextColor:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="sidebarBg"){
            this.setState({
                sidebarBg:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="sidebarBorderRight"){ 
            this.setState({
                sidebarBorderRight:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="sidebarDividerColor"){ 
            this.setState({
                sidebarDividerColor:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="sidebarTextColor"){ 
            this.setState({
                sidebarTextColor:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="sidebarIconColor"){ 
            this.setState({
                sidebarIconColor:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="sidebarSelectedItemBg"){ 
            this.setState({
                sidebarSelectedItemBg:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="sidebarSelectedItemTextColor"){ 
            this.setState({
                sidebarSelectedItemTextColor:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="sidebarSelectedItemIconColor"){ 
            this.setState({
                sidebarSelectedItemIconColor:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="sidebarHoverItemBg"){ 
            this.setState({
                sidebarHoverItemBg:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="sidebarHoverItemTextColor"){ 
            this.setState({
                sidebarHoverItemTextColor:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="sidebarHoverItemIconColor"){ 
            this.setState({
                sidebarHoverItemIconColor:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="topbarBg"){ 
            this.setState({
                topbarBg:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="topbarIconColor"){ 
            this.setState({
                topbarIconColor:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="topbarSelectedIconBg"){ 
            this.setState({
                topbarSelectedIconBg:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="notificationBadgeBg"){ 
            this.setState({
                notificationBadgeBg:color.rgb,
            })
        }else if(this.state.openColorPickerStr==="badgeTextColor"){ 
            this.setState({
                badgeTextColor:color.rgb,
            })
        }
    }

    saveSettingsLocalStorage = () => {
        let themeSettingsObj = {
            themeColor:this.state.themeColor,
            themeTextColor:this.state.themeTextColor,
            themeHoverColor:this.state.themeHoverColor,
            themeHoverTextColor:this.state.themeHoverTextColor,

            sidebarBg:this.state.sidebarBg,
            sidebarBorderRight:this.state.sidebarBorderRight,
            sidebarDividerColor:this.state.sidebarDividerColor,
            sidebarTextColor:this.state.sidebarTextColor,
            sidebarIconColor:this.state.sidebarIconColor,
            sidebarSelectedItemBg:this.state.sidebarSelectedItemBg,
            sidebarSelectedItemTextColor:this.state.sidebarSelectedItemTextColor,
            sidebarSelectedItemIconColor:this.state.sidebarSelectedItemIconColor,
            sidebarHoverItemBg:this.state.sidebarHoverItemBg,
            sidebarHoverItemTextColor:this.state.sidebarHoverItemTextColor,
            sidebarHoverItemIconColor:this.state.sidebarHoverItemIconColor,
            
            topbarBg:this.state.topbarBg,
            topbarIconColor:this.state.topbarIconColor,
            topbarSelectedIconBg:this.state.topbarSelectedIconBg,
            notificationBadgeBg:this.state.notificationBadgeBg,
            badgeTextColor:this.state.badgeTextColor,
        }

        localStorage.setItem('themeSettings', JSON.stringify(themeSettingsObj));
    }

    resetLocalStorage = () => {
        this.setState({
            showLoader:false
        })

        // get default styles and save it in local storage
        let themeSettingsObj = getDefaultStylesObj();
        localStorage.setItem('themeSettings', JSON.stringify(themeSettingsObj));
        
        //reinitialise state and themesettings
        themeSettings  = getThemeSettingsVariables();
        this.initializeStateFromStorage();
    }

    fileValidation(id){
        var fileInput = document.getElementById(id);
        var filePath = fileInput.value;

        var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

        if(!allowedExtensions.exec(filePath)){
            fileInput.value = '';
            return false;
        }else{
            return true;
        }
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }
    
    /******************** API CALLS **********************/
    changeImage = (e) => {
        e.preventDefault();

        this.setState({
            showLoader:true
        });
       
        var sizeInMB    =   (e.target.files[0].size / (1024*1024)).toFixed(2);
        if(!this.fileValidation("uploadProfileImage"))
        {
            this.setState(
                {
                    showLoader:false,
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:'Please upload file having extensions jpeg,jpg and png only.'
                }
            );
        }
        else if(sizeInMB > Constants.VALID_PROFILE_IMAGE_SIZE)
        {
            this.setState(
                {
                    showLoader:false,
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:'Please upload profile image size less than '+Constants.VALID_PROFILE_IMAGE_SIZE+' MB'
                }
            );
        }
        else
        {
            const formData = new FormData();
            formData.append('profilePic',e.target.files[0]);
            formData.append('email',userDetails.email);
            formData.append('accessToken',userDetails.accessToken);

            fetch(Constants.UpdateProfilePic,
            {
                method: "POST",
                mode: 'cors',
                body: formData
            })
            .then(response => { return response.json(); })
            .then(data => {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    let profilePicPath = data.data.profilePic;
                    let parameters = {
                        loggedInUserId:userDetails.userId,
                        loggedInUserEmail:userDetails.email,
                        loggedInProPic:profilePicPath,
                        loggedInUserType:userDetails.userType,
                        loggedInFirstName:userDetails.firstName,
                        loggedInLastName:userDetails.lastName,
                        loggedInAccessToken:userDetails.accessToken,
                    };
                    localStorage.clear();
                    localStorage.setItem('loggedInUserDetails', JSON.stringify(parameters));

                    userDetails  = getLocalStorageVariables();

                    this.setState({
                        showLoader:false
                    });

                }else{
                    this.setState({
                        showLoader:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    });
                }
            });
        }
    }

   
    handleChangePassword = () => {
        this.setState({
            showLoader:true,
        });

        let isError = false;
        let FormErrorsTemp = {};


        if(isEmptyVariable(this.state.curpass)){
            isError = true;
            FormErrorsTemp['curpass'] = "Please enter current password";
        }

        if(isEmptyVariable(this.state.newpass)){
            isError = true;
            FormErrorsTemp['newpass'] = "Please enter new password";
        }

        if(!isEmptyVariable(this.state.newpass)){
            let respObj = validatePassword(this.state.newpass);

            if(respObj.err){
                isError = true;
                FormErrorsTemp['newpass'] = respObj.responseMsg;
            }
        }

        if(isEmptyVariable(this.state.conpass)){
            isError = true;
            FormErrorsTemp['conpass'] = "Please enter confirm password";
        }

        if(!isEmptyVariable(this.state.newpass) && 
        !isEmptyVariable(this.state.conpass) &&
        this.state.newpass !== this.state.conpass){
            isError = true;
            FormErrorsTemp['conpass'] = "Password mismatch";
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            })

            setTimeout(function(){
                this.setState({
                    formErrors:{},
                });
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }else{
            fetch(Constants.ChangePassword,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    oldPassword:this.state.curpass,
                    newPassword:this.state.newpass,
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        curpass:"",
                        newpass:"",
                        conpass:"",
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    })
                }else{
                    this.setState({
                        showLoader:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    });
                }
            });
        }
    }

    saveThemeSettings = () => {
        //call api
        //Save it in local storage
        this.setState({
            showLoader:true
        })

        let themeSettingsObj = {
            themeColor:this.state.themeColor,
            themeTextColor:this.state.themeTextColor,
            themeHoverColor:this.state.themeHoverColor,
            themeHoverTextColor:this.state.themeHoverTextColor,
            
            sidebarBg:this.state.sidebarBg,
            sidebarBorderRight:this.state.sidebarBorderRight,
            sidebarDividerColor:this.state.sidebarDividerColor,
            sidebarTextColor:this.state.sidebarTextColor,
            sidebarIconColor:this.state.sidebarIconColor,
            sidebarSelectedItemBg:this.state.sidebarSelectedItemBg,
            sidebarSelectedItemTextColor:this.state.sidebarSelectedItemTextColor,
            sidebarSelectedItemIconColor:this.state.sidebarSelectedItemIconColor,
            sidebarHoverItemBg:this.state.sidebarHoverItemBg,
            sidebarHoverItemTextColor:this.state.sidebarHoverItemTextColor,
            sidebarHoverItemIconColor:this.state.sidebarHoverItemIconColor,
            
            topbarBg:this.state.topbarBg,
            topbarIconColor:this.state.topbarIconColor,
            topbarSelectedIconBg:this.state.topbarSelectedIconBg,
            notificationBadgeBg:this.state.notificationBadgeBg,
            badgeTextColor:this.state.badgeTextColor,
        }

        fetch(Constants.SaveUserThemeSettings,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                themeSettings:JSON.stringify(themeSettingsObj)
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.saveSettingsLocalStorage();
                this.setState({
                    showLoader:false
                })
                window.location.reload(false);
            }
        });
    }

    resetToDefaltAPI = () => {
        //call api
        //Save it to local storage
        this.setState({
            showLoader:true
        })
        fetch(Constants.ResetUserThemeSettings,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.resetLocalStorage();
                this.setState({
                    showLoader:false
                })
                window.location.reload(false);
            }
        });
    }

    render() {
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                {
                                    this.state.showLoader &&
                                    <div class="loader"></div>
                                }

                                <div className="body-wrapper">
                                    <div className="profile-outer-layout">
                                        <div className="row custom-row">

                                            <div className="col-md-6 custom-col">
                                                <div className="card-col">
                                                    <h6>My Profile</h6>
                                                    <div className="profile-layout">
                                                        <div className="img-layout position-relative">
                                                            {isEmptyVariable(userDetails.profilePic)?
                                                                <ProfileIcon themeSettings={themeSettings}>
                                                                    <div className="profile-pic-circle">
                                                                        <h6>{this.state.initialIcon}</h6>
                                                                    </div>
                                                                </ProfileIcon>
                                                                :
                                                                <img src={Constants.ImageBaseUrl + userDetails.profilePic}/>
                                                            }
                                                            <div className={'upload-image bg-theme'}>
                                                                <input name="file" 
                                                                    type="file"
                                                                    id="uploadProfileImage"
                                                                    onChange={this.changeImage}
                                                                    disabled={this.state.showLoader}
                                                                />
                                                                <label disabled={this.state.showLoader} className='m-0' for="uploadProfileImage">
                                                                    <span class="material-icons-outlined" style={{ verticalAlign: "middle" }}>edit</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="profile-text-layout">
                                                            <h6>{userDetails.firstName+" "+userDetails.lastName}</h6>
                                                            <p>{userDetails.email}</p>
                                                            {!isEmptyVariable(userDetails.phoneNo) && <p style={{marginTop:"10px"}}>{userDetails.phoneNo}</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 custom-col">
                                                <div className="card-col">
                                                    <h6>Change Password</h6>
                                                    <div className="change-pass-layout">
                                                    
                                                        <div className="add-new-form-group">
                                                            <p>Current Password*</p>
                                                            <input 
                                                                type="password" 
                                                                placeholder="Enter current password" 
                                                                name="curpass"
                                                                onChange ={this.handleChange}
                                                                value={this.state.curpass}
                                                            />
                                                            {
                                                                !isEmptyVariable(this.state.formErrors['curpass']) &&
                                                                <span className="cm-error">{this.state.formErrors['curpass']}</span>
                                                            }
                                                        </div>

                                                        <div className="add-new-form-group">
                                                            <p>New Password*</p>
                                                            <input 
                                                                type="password" 
                                                                placeholder="Enter new password" 
                                                                name="newpass"
                                                                onChange ={this.handleChange}
                                                                value={this.state.newpass}
                                                            />
                                                            {
                                                                !isEmptyVariable(this.state.formErrors['newpass']) &&
                                                                <span className="cm-error">{this.state.formErrors['newpass']}</span>
                                                            }
                                                        </div>

                                                        <div className="add-new-form-group">
                                                            <p>Confirm New Password*</p>
                                                            <input 
                                                                type="password" 
                                                                placeholder="Confirm new password" 
                                                                name="conpass"
                                                                onChange ={this.handleChange}
                                                                value={this.state.conpass}
                                                            />
                                                            {
                                                                !isEmptyVariable(this.state.formErrors['conpass']) &&
                                                                <span className="cm-error">{this.state.formErrors['conpass']}</span>
                                                            }
                                                        </div>

                                                        <div className="common-close-save">
                                                            <div className="common-close-save-col">
                                                                <PageSaveButton themeSettings={themeSettings} style={{width:"100%", margin:0}} disabled={this.state.showLoader}
                                                                onClick={this.handleChangePassword} type="button" className="common-save">Update Password</PageSaveButton>
                                                            </div>
                                                            {/* {
                                                                !isEmptyVariable(this.state.formErrors['formResult']) &&
                                                                <span className="cm-error">{this.state.formErrors['formResult']}</span>
                                                            } */}
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row custom-row">
                                            <div className="col-md-12 custom-col">
                                                <div className="card-col">
                                                    <div className="row custom-row">
                                                        <div className="col-md-6 border-right-theme">
                                                            <h6>Theme Settings</h6>
                                                            <div className="sidebar-color-picker-layout">
                                                                <p className="theme-settings-heading font-weight-bold">Overall Settings</p>
                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Theme Color</p>    
                                                                    </div>
                                                                    <div className="col-md-5 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.themeColor.r }, ${ this.state.themeColor.g }, ${ this.state.themeColor.b }, ${ this.state.themeColor.a })`}} className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"themeColor")} />
                                                                        </div>
                                                                        {
                                                                            this.state.themeColorCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" onClick={ this.handleColorPickerClose.bind(this,"themeColor") }/>
                                                                                <SketchPicker color={this.state.themeColor} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Text Color</p>    
                                                                    </div>
                                                                    <div className="col-md-5 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.themeTextColor.r }, ${ this.state.themeTextColor.g }, ${ this.state.themeTextColor.b }, ${ this.state.themeTextColor.a })`}} className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"themeTextColor")} />
                                                                        </div>
                                                                        {
                                                                            this.state.themeTextColorCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" onClick={ this.handleColorPickerClose.bind(this,"themeTextColor") }/>
                                                                                <SketchPicker color={this.state.themeTextColor} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Theme Hover Color</p>    
                                                                    </div>
                                                                    <div className="col-md-5 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.themeHoverColor.r }, ${ this.state.themeHoverColor.g }, ${ this.state.themeHoverColor.b }, ${ this.state.themeHoverColor.a })`}} 
                                                                            className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"themeHoverColor")} />
                                                                        </div>
                                                                        {
                                                                            this.state.themeHoverColorCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" onClick={ this.handleColorPickerClose.bind(this,"themeHoverColor") }/>
                                                                                <SketchPicker color={this.state.themeHoverColor} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Theme Hover Text Color</p>    
                                                                    </div>
                                                                    <div className="col-md-5 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.themeHoverTextColor.r }, ${ this.state.themeHoverTextColor.g }, ${ this.state.themeHoverTextColor.b }, ${ this.state.themeHoverTextColor.a })`}} 
                                                                            className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"themeHoverTextColor")} />
                                                                        </div>
                                                                        {
                                                                            this.state.themeHoverTextColorCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" onClick={ this.handleColorPickerClose.bind(this,"themeHoverTextColor") }/>
                                                                                <SketchPicker color={this.state.themeHoverTextColor} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="sidebar-color-picker-layout mt-4">
                                                                <p className="theme-settings-heading font-weight-bold">Sidebar Settings</p>
                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Background Color</p>    
                                                                    </div>
                                                                    <div className="col-md-5 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.sidebarBg.r }, ${ this.state.sidebarBg.g }, ${ this.state.sidebarBg.b }, ${ this.state.sidebarBg.a })`}} className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"sidebarBg")} />
                                                                        </div>
                                                                        {
                                                                            this.state.sidebarCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" onClick={ this.handleColorPickerClose.bind(this,"sidebarBg") }/>
                                                                                <SketchPicker color={this.state.sidebarBg} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                {/* <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Border Color</p>    
                                                                    </div>
                                                                    <div className="col-md-5 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.sidebarBorderRight.r }, ${ this.state.sidebarBorderRight.g }, ${ this.state.sidebarBorderRight.b }, ${ this.state.sidebarBorderRight.a })`}} className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"sidebarBorderRight")} />
                                                                        </div>
                                                                        {
                                                                            this.state.sidebarBorderRightCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" onClick={ this.handleColorPickerClose.bind(this,"sidebarBorderRight") }/>
                                                                                <SketchPicker color={this.state.sidebarBorderRight} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div> */}

                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Text Color</p>    
                                                                    </div>
                                                                    <div className="col-md-5 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.sidebarTextColor.r }, ${ this.state.sidebarTextColor.g }, ${ this.state.sidebarTextColor.b }, ${ this.state.sidebarTextColor.a })`}} className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"sidebarTextColor")} />
                                                                        </div>
                                                                        {
                                                                            this.state.sidebarTextColorCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" onClick={ this.handleColorPickerClose.bind(this,"sidebarTextColor") }/>
                                                                                <SketchPicker color={this.state.sidebarTextColor} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Divider Color</p>    
                                                                    </div>
                                                                    <div className="col-md-5 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.sidebarDividerColor.r }, ${ this.state.sidebarDividerColor.g }, ${ this.state.sidebarDividerColor.b }, ${ this.state.sidebarDividerColor.a })`}} className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"sidebarDividerColor")} />
                                                                        </div>
                                                                        {
                                                                            this.state.sidebarDividerColorCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" onClick={ this.handleColorPickerClose.bind(this,"sidebarDividerColor") }/>
                                                                                <SketchPicker color={this.state.sidebarDividerColor} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Icon Color</p>    
                                                                    </div>
                                                                    <div className="col-md-5 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.sidebarIconColor.r }, ${ this.state.sidebarIconColor.g }, ${ this.state.sidebarIconColor.b }, ${ this.state.sidebarIconColor.a })`}} className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"sidebarIconColor")} />
                                                                        </div>
                                                                        {
                                                                            this.state.sidebarIconColorCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" onClick={ this.handleColorPickerClose.bind(this,"sidebarIconColor") }/>
                                                                                <SketchPicker color={this.state.sidebarIconColor} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Selected Menu Background</p>    
                                                                    </div>
                                                                    <div className="col-md-5 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.sidebarSelectedItemBg.r }, ${ this.state.sidebarSelectedItemBg.g }, ${ this.state.sidebarSelectedItemBg.b }, ${ this.state.sidebarSelectedItemBg.a })`}} className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"sidebarSelectedItemBg")} />
                                                                        </div>
                                                                        {
                                                                            this.state.sidebarSelectedItemBgCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" 
                                                                                onClick={ this.handleColorPickerClose.bind(this,"sidebarSelectedItemBg") }/>
                                                                                <SketchPicker color={this.state.sidebarSelectedItemBg} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Selected Menu Text Color</p>    
                                                                    </div>
                                                                    <div className="col-md-5 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.sidebarSelectedItemTextColor.r }, ${ this.state.sidebarSelectedItemTextColor.g }, ${ this.state.sidebarSelectedItemTextColor.b }, ${ this.state.sidebarSelectedItemTextColor.a })`}} className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"sidebarSelectedItemTextColor")} />
                                                                        </div>
                                                                        {
                                                                            this.state.sidebarSelectedItemTextColorCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" 
                                                                                onClick={ this.handleColorPickerClose.bind(this,"sidebarSelectedItemTextColor") }/>
                                                                                <SketchPicker color={this.state.sidebarSelectedItemTextColor} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Selected Menu Icon Color</p>    
                                                                    </div>
                                                                    <div className="col-md-5 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.sidebarSelectedItemIconColor.r }, ${ this.state.sidebarSelectedItemIconColor.g }, ${ this.state.sidebarSelectedItemIconColor.b }, ${ this.state.sidebarSelectedItemIconColor.a })`}} className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"sidebarSelectedItemIconColor")} />
                                                                        </div>
                                                                        {
                                                                            this.state.sidebarSelectedItemIconColorCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" 
                                                                                onClick={ this.handleColorPickerClose.bind(this,"sidebarSelectedItemIconColor") }/>
                                                                                <SketchPicker color={this.state.sidebarSelectedItemIconColor} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Menu Hover Background</p>    
                                                                    </div>
                                                                    <div className="col-md-5 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.sidebarHoverItemBg.r }, ${ this.state.sidebarHoverItemBg.g }, ${ this.state.sidebarHoverItemBg.b }, ${ this.state.sidebarHoverItemBg.a })`}} className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"sidebarHoverItemBg")} />
                                                                        </div>
                                                                        {
                                                                            this.state.sidebarHoverItemBgCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" 
                                                                                onClick={ this.handleColorPickerClose.bind(this,"sidebarHoverItemBg") }/>
                                                                                <SketchPicker color={this.state.sidebarHoverItemBg} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Menu Hover Text Color</p>    
                                                                    </div>
                                                                    <div className="col-md-5 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.sidebarHoverItemTextColor.r }, ${ this.state.sidebarHoverItemTextColor.g }, ${ this.state.sidebarHoverItemTextColor.b }, ${ this.state.sidebarHoverItemTextColor.a })`}} className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"sidebarHoverItemTextColor")} />
                                                                        </div>
                                                                        {
                                                                            this.state.sidebarHoverItemTextColorCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" 
                                                                                onClick={ this.handleColorPickerClose.bind(this,"sidebarHoverItemTextColor") }/>
                                                                                <SketchPicker color={this.state.sidebarHoverItemTextColor} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Menu Hover Icon Color</p>    
                                                                    </div>
                                                                    <div className="col-md-5 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.sidebarHoverItemIconColor.r }, ${ this.state.sidebarHoverItemIconColor.g }, ${ this.state.sidebarHoverItemIconColor.b }, ${ this.state.sidebarHoverItemIconColor.a })`}} className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"sidebarHoverItemIconColor")} />
                                                                        </div>
                                                                        {
                                                                            this.state.sidebarHoverItemIconColorCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" 
                                                                                onClick={ this.handleColorPickerClose.bind(this,"sidebarHoverItemIconColor") }/>
                                                                                <SketchPicker color={this.state.sidebarHoverItemIconColor} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>

                                                            <div className="sidebar-color-picker-layout mt-4">
                                                                <p className="theme-settings-heading font-weight-bold">Topbar Settings</p>

                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Background Color</p>    
                                                                    </div>
                                                                    <div className="col-md-7 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.topbarBg.r }, ${ this.state.topbarBg.g }, ${ this.state.topbarBg.b }, ${ this.state.topbarBg.a })`}} className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"topbarBg")} />
                                                                        </div>
                                                                        {
                                                                            this.state.topbarBgCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" 
                                                                                onClick={ this.handleColorPickerClose.bind(this,"topbarBg") }/>
                                                                                <SketchPicker color={this.state.topbarBg} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Icon Color</p>    
                                                                    </div>
                                                                    <div className="col-md-7 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.topbarIconColor.r }, ${ this.state.topbarIconColor.g }, ${ this.state.topbarIconColor.b }, ${ this.state.topbarIconColor.a })`}} className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"topbarIconColor")} />
                                                                        </div>
                                                                        {
                                                                            this.state.topbarIconColorCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" 
                                                                                onClick={ this.handleColorPickerClose.bind(this,"topbarIconColor") }/>
                                                                                <SketchPicker color={this.state.topbarIconColor} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Hovered Icon Background Color</p>    
                                                                    </div>
                                                                    <div className="col-md-7 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.topbarSelectedIconBg.r }, ${ this.state.topbarSelectedIconBg.g }, ${ this.state.topbarSelectedIconBg.b }, ${ this.state.topbarSelectedIconBg.a })`}} className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"topbarSelectedIconBg")} />
                                                                        </div>
                                                                        {
                                                                            this.state.topbarSelectedIconBgCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" 
                                                                                onClick={ this.handleColorPickerClose.bind(this,"topbarSelectedIconBg") }/>
                                                                                <SketchPicker color={this.state.topbarSelectedIconBg} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Notification Badge Background</p>    
                                                                    </div>
                                                                    <div className="col-md-7 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div style={{background:`rgba(${ this.state.notificationBadgeBg.r }, ${ this.state.notificationBadgeBg.g }, ${ this.state.notificationBadgeBg.b }, ${ this.state.notificationBadgeBg.a })`}} className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"notificationBadgeBg")} />
                                                                        </div>
                                                                        {
                                                                            this.state.notificationBadgeBgCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" 
                                                                                onClick={ this.handleColorPickerClose.bind(this,"notificationBadgeBg") }/>
                                                                                <SketchPicker color={this.state.notificationBadgeBg} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="row custom-row" style={{alignItems:"center"}}>
                                                                    <div className="col-md-5 custom-col">
                                                                        <p>Badge Text Color</p>    
                                                                    </div>
                                                                    <div className="col-md-7 custom-col">
                                                                        <div className="profile-color-layout-wrapper">
                                                                            <div 
                                                                            style={{background:`rgba(${ this.state.badgeTextColor.r }, ${ this.state.badgeTextColor.g }, ${ this.state.badgeTextColor.b }, ${ this.state.badgeTextColor.a })`}} 
                                                                            className="profile-color-layout"
                                                                            onClick={this.openColorPicker.bind(this,"badgeTextColor")} />
                                                                        </div>
                                                                        {
                                                                            this.state.badgeTextColorCPFlag &&
                                                                            <div className="popover">
                                                                                <div className="popover-cover" 
                                                                                onClick={ this.handleColorPickerClose.bind(this,"badgeTextColor") }/>
                                                                                <SketchPicker color={this.state.badgeTextColor} onChange={ this.handleColorPickerChange} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <h6>Preview</h6>
                                                            <div className='text-center mb-4'>
                                                                <SaveButton 
                                                                    themeColor = {this.state.themeColor}
                                                                    themeTextColor = {this.state.themeTextColor}
                                                                    themeHoverColor = {this.state.themeHoverColor}
                                                                    themeHoverTextColor = {this.state.themeHoverTextColor}
                                                                > Button </SaveButton>
                                                            </div>

                                                            <ProfileSidebarMenuSample
                                                                sidebarTextColor={this.state.sidebarTextColor}
                                                                sidebarIconColor={this.state.sidebarIconColor}
                                                                sidebarSelectedItemBg={this.state.sidebarSelectedItemBg}
                                                                sidebarSelectedItemTextColor={this.state.sidebarSelectedItemTextColor}
                                                                sidebarSelectedItemIconColor={this.state.sidebarSelectedItemIconColor}
                                                                sidebarHoverItemBg={this.state.sidebarHoverItemBg}
                                                                sidebarHoverItemTextColor={this.state.sidebarHoverItemTextColor}
                                                                sidebarHoverItemIconColor={this.state.sidebarHoverItemIconColor}
                                                            >

                                                                <div className="profile-sidebar-layout" 
                                                                style={{background:`rgba(${ this.state.sidebarBg.r }, ${ this.state.sidebarBg.g }, ${ this.state.sidebarBg.b }, ${ this.state.sidebarBg.a })`,borderRight:"2px solid "+`rgba(${ this.state.sidebarBorderRight.r }, ${ this.state.sidebarBorderRight.g }, ${ this.state.sidebarBorderRight.b }, ${ this.state.sidebarBorderRight.a })`}}>
                                                                    <div className="vertical-menu-profile-type1">
                                                                        <div className="client-profile-bg-type1">
                                                                            <img className="logo-type1" src="/assets/images/client-logo.png" />
                                                                            <div className="client-profile-type1">
                                                                                <a href="#">
                                                                                    <h6 className="m-b-0" style={{color:this.state.sidebarTextColor}}>
                                                                                        {isEmptyVariable(userDetails)?"":userDetails.firstName+" "+userDetails.lastName}
                                                                                    </h6>
                                                                                </a>
                                                                            </div>
                                                                            {/* <span className="material-icons">person</span> <p>Profile</p></a> */}
                                                                        </div>
                                                                        {/* <a className="m-b-10" href="#">info@amssoftwares.co.in</a> */}
                                                                        <a href="#">
                                                                            {isEmptyVariable(userDetails.profilePic)?
                                                                                <div className="profile-pic-circle">
                                                                                    <h6>{this.state.initialIcon}</h6>
                                                                                </div>
                                                                                :
                                                                                <img src={Constants.ImageBaseUrl + userDetails.profilePic}/>
                                                                            }
                                                                        </a>
                                                                        <hr 
                                                                        style={{background:`rgba(${ this.state.sidebarDividerColor.r }, ${ this.state.sidebarDividerColor.g }, ${ this.state.sidebarDividerColor.b }, ${ this.state.sidebarDividerColor.a })`}}
                                                                        />
                                                                    </div>

                                                                    <div className="veritical-menu-profile">
                                                                        <ul>
                                                                            <li className="active">
                                                                                <a href="#"><span className="material-icons">dashboard</span> <p>Dashboard</p></a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#"><span className="material-icons">task_alt</span> <p>Tasks</p></a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#"><span className="material-icons">description</span> <p>Masters</p></a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#"><span className="material-icons">settings</span> <p>Settings</p></a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="#"><span className="material-icons">exit_to_app</span> <p>Logout</p></a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </ProfileSidebarMenuSample>

                                                            <div className="profile-top-bar-wrapper mt-4">
                                                                <div className="profile-top-bar" 
                                                                style={{background:`rgba(${ this.state.topbarBg.r }, ${ this.state.topbarBg.g }, ${ this.state.topbarBg.b }, ${ this.state.topbarBg.a })`}}>
                                                                    <HeaderRight
                                                                        iconColor={this.state.topbarIconColor}
                                                                        selectedIconBg={this.state.topbarSelectedIconBg}
                                                                        badgeTextColor={this.state.badgeTextColor}
                                                                        badgeBg={this.state.notificationBadgeBg}
                                                                    >
                                                                        <div className="info-header">
                                                                            {/* <div className="contact-suppport-layout">
                                                                                <span className="material-icons">mail</span>
                                                                            </div> */}

                                                                            {/* <div className="info-layout-wrapper">
                                                                                <div className="topbar-info-layout">
                                                                                    <img src="/assets/images/topbar-info.svg" />
                                                                                </div>
                                                                            </div> */}

                                                                            <div className="header-notification-wrapper">
                                                                                <div className="header-notification">
                                                                                    <div className="noti-badge">
                                                                                        <span>5</span>
                                                                                    </div>
                                                                                    <span className="material-icons">notifications</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="vertical-line"></div>
                                                                            <div className="info-header-wrapper">
                                                                                <div className="ams-logo">
                                                                                    <img src="/assets/images/sameeksha.png" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </HeaderRight>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="common-close-save mt-4">
                                                        <div className="common-close-save-col">
                                                            <PageSaveButton themeSettings={themeSettings} style={{marginRight:10}} disabled={this.state.showLoader}
                                                            onClick={this.resetToDefaltAPI} type="button">Reset To Default</PageSaveButton>
                                                            <PageSaveButton themeSettings={themeSettings} style={{marginLeft:10}} disabled={this.state.showLoader}
                                                            onClick={this.saveThemeSettings} type="button">Save Settings</PageSaveButton>
                                                        </div>
                                                    </div>
                                                    <p style={{textAlign:"center",marginTop:30}}> {"(V"+AFCONS_VERSION+")"}</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* END OF THEME SETTINGS */}
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type={Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                            themeSettings={themeSettings}
                        />
                    </section>
                </main>
            </Router>
        );
    }
}

export default Profile;