import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {DialogSaveButton,TableRadioButton} from '../../../common/Global/globalStyles.style';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import {getLocalStorageVariables,isEmptyVariable,
    isEmptyArray} from '../../../common/Global/commonFunctions';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import moment from "moment";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import {Modal} from 'react-bootstrap';
import SelectFunctionDialog from '../Common/selectFunction';
import SelectProcessDialog from '../Common/selectProcess';
import ReactSelectDropdown from '../../../common/DropdownMenus/ReactSelectDropdownWithSearch';

const functionDropdownDefaultValue = "Select Function";
const processDropdownDefaultValue = "Select Process";
const companyDefaultPlaceholder = "Select Client";
const unitDefaultPlaceholder = "Select Units";
const projectDefaultPlaceholder = "Select Project";
const employeeDefaultPlaceholder = "Select Employee";
const departmentDropdownDefaultValue = "Select Department";
const assignmentTypeDefaultPlaceholder = "Select Assignment Type";
const auditTypeDefaultPlaceholder = "Select Audit Type";
const selectedAssignmentEntityEmployee = "Employee";
const selectedAssignmentEntityProject = "Project";
const userDetails  = getLocalStorageVariables();
class CreateAnnualPlan extends Component{
    constructor(props){
        super(props);
        this.state = {
            planDate:"",
            selectedCompanyId:"",
            selectedCompanyPlaceholder:companyDefaultPlaceholder,
            
            companyUnits:[],
            selectedUnitObj:[],
            projects:[],
            employees:[],
            selectedProjectObj:[],
            selectedEmployeeObj:[],
            selectedUnitPlaceholder:unitDefaultPlaceholder,
            selectedProjectPlaceholder:projectDefaultPlaceholder,
            selectedEmployeePlaceholder:employeeDefaultPlaceholder,
            isUnitsDropdownEnabled:false,
            selectedAssignmentTypeId:"",
            assignmentTypeFiltered:[],
            selectedAssignmentTypePlaceholder:assignmentTypeDefaultPlaceholder,
            selectedAuditType:auditTypeDefaultPlaceholder,
            selectAssignmentEntityFlag:false,
            auditTypesFiltered:[],
            assignmentTypeFlag:false,
            assignmentArea:"",
            selectedFunctionObjArr:[],
            selectedProcessObjArr:[],

            selectedDepartmentId:"",
            selectedDepartmentName:departmentDropdownDefaultValue,
            submitClickedAtleastOnce:false,
            formErrors:{},
            selectedCompanyObj:{}
        }
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showCreateDialog){
            let selectedDepartmentId = this.props.selectedDepartmentId;
            let selectedDepartmentName = this.props.selectedDepartmentName;
            

            if(this.props.departmentArr.length > 1){
                selectedDepartmentId="";
                selectedDepartmentName=departmentDropdownDefaultValue;
               

            }

            if(this.props.isEdit){
              
                let dropdownItem = this.props.selectedItem;
                let companyUnits = [];
                companyUnits = this.props.units.filter(unit => unit.companyId === dropdownItem.companyId);
                let assignmentTypeFiltered = this.props.assignmentType.filter(at => at.departmentId === dropdownItem.departmentId);
                
                let newCompanyUnits = [];
                if(dropdownItem.auditType === selectedAssignmentEntityProject){
                    newCompanyUnits = [{
                        unitNameAndCity:"All",
                        unitId:""
                    }]
                }
                Object.values(companyUnits).map((item)=>{
                    let tempItem = item
                    tempItem["unitNameAndCity"] = item.unitName+", "+item.city
                    newCompanyUnits.push(item)
                })
                
                let selectedUnitObj = [];
                if(dropdownItem.auditType === selectedAssignmentEntityProject){
                    let projectUnitId = dropdownItem.projectUnitId?JSON.parse(dropdownItem.projectUnitId):[];
                    projectUnitId.map(item => {
                        newCompanyUnits.map((unit)=>{
                            if(unit.unitId==parseInt(item))
                            {
                                selectedUnitObj.push(unit)
                            }
                        })
                    })
                    
                    if(selectedUnitObj.length === companyUnits.length){
                        selectedUnitObj = [{
                            unitNameAndCity:"All",
                            unitId:""
                        }];
                    }
                }else if(dropdownItem.auditType === selectedAssignmentEntityEmployee){
                    let employeeUnitId = dropdownItem.employeeUnitId?JSON.parse(dropdownItem.employeeUnitId):[];
                    employeeUnitId.map(item => {
                        newCompanyUnits.map((unit)=>{
                            if(unit.unitId==parseInt(item)){
                                selectedUnitObj.push(unit)
                            }
                        })
                    })
                }else{
                    let splitArr = dropdownItem?.unitList?.split(";;") ?? [];
                    if(!isEmptyArray(splitArr)){
                        splitArr.map((item)=>{
                            newCompanyUnits.map((unit)=>{
                                if(unit.unitId==parseInt(item.split(":-:")[1]))
                                {
                                    selectedUnitObj.push(unit)
                                }
                            })
                        })
                    }
                }
    
                let selectedProjectObj = [];
                if(!isEmptyVariable(dropdownItem.projectList)){
                    this.props.projects.map((project)=>{
                        if(project.projectId==parseInt(dropdownItem.projectList.split(":-:")[2]))
                        {
                            selectedProjectObj.push(project)
                        }
                    })
                }

                let newEmployees = []
                Object.values(this.props.employees).map((item)=>{
                    let tempItem = item
                    tempItem["empAndDesignation"] = item.salutation+" "+item.firstName+" "+item.lastName+" ("+item.designation+")"
                    newEmployees.push(item)
                })

                let selectedEmployeeObj = [];
                let splitArr = dropdownItem.employeeList.split(";;");
                if(!isEmptyArray(splitArr)){
                    splitArr.map(item => {
                        newEmployees.map(emp => {
                            if(emp.employeeId == parseInt(item.split(":-:")[1])){
                                selectedEmployeeObj.push(emp)
                            }
                        })
                    })
                }

                let selectedProcessObjArr = [];
                splitArr = dropdownItem.processList.split(",");
                if(!isEmptyArray(splitArr)){
                    splitArr.forEach((item)=>{
                        if(!isEmptyVariable(item)){
                            selectedProcessObjArr.push({
                                processId:item.split(":-:")[1]
                            });
                        }
                    })
                }

               
                this.state.selectedCompanyObj['companyName']=!isEmptyVariable(dropdownItem.companyName)?dropdownItem.companyName:""
                this.state.selectedCompanyObj['companyId']=!isEmptyVariable(dropdownItem.companyId)?dropdownItem.companyId:""
    
                
    
                let selectedFunctionObjArr = [];
                splitArr = dropdownItem.functionList.split(",");
                if(!isEmptyArray(splitArr)){
                    splitArr.forEach((item)=>{
                        if(!isEmptyVariable(item)){
                            selectedFunctionObjArr.push({
                                functionId:item.split(":-:")[1]
                            });
                        }
                    })
                }
                let planDate = moment(dropdownItem.planDate).format("YYYY-MM-DD");
                
                if(assignmentTypeFiltered.length === 1){
                    const selectedAuditType = assignmentTypeFiltered[0].auditType?JSON.parse(assignmentTypeFiltered[0].auditType.replace(/'/g, '"')).map(auditType => ({ auditType })):[];
                    this.setState({
                        auditTypesFiltered:selectedAuditType
                    })
                }else{
                    const assignmentTypeFilter = assignmentTypeFiltered.filter(item => item.name === dropdownItem.assignmentTypeName);
                    const selectedAuditType = assignmentTypeFiltered[0].auditType?JSON.parse(assignmentTypeFilter[0].auditType.replace(/'/g, '"')).map(auditType => ({ auditType })):[];

                    this.setState({
                        auditTypesFiltered:selectedAuditType
                    })
                }

                this.setState({
                    planDate:planDate,
                    selectedCompanyId:dropdownItem.companyId,
                    selectedCompanyPlaceholder:dropdownItem.companyName,
    
                    companyUnits:newCompanyUnits,
                    selectedUnitObj:selectedUnitObj,
                    projects:this.props.projects,
                    employees:this.props.employees,
                    selectedProjectObj:selectedProjectObj,
                    selectedEmployeeObj:selectedEmployeeObj,
                    isUnitsDropdownEnabled:true,
    
                    assignmentTypeFiltered:assignmentTypeFiltered,
                    selectedAssignmentTypeId:dropdownItem.plannedAssignmentTypeId,
                    selectedAssignmentTypePlaceholder:dropdownItem.assignmentTypeName,
                    selectedAuditType:dropdownItem.auditType,
                    assignmentTypeFlag:false,
                    selectAssignmentEntityFlag:true,
                    selectedFunctionObjArr:selectedFunctionObjArr,
                    selectedProcessObjArr:selectedProcessObjArr,
                    assignmentArea:dropdownItem.plannedAssignmentArea,
                    selectedId:dropdownItem.assignmentId,
                    selectedDepartmentId:dropdownItem.departmentId,
                    submitClickedAtleastOnce:false,
                    formErrors:{},
                });
            }else{
              
                let companyName=companyDefaultPlaceholder;
                let companyId="";
                let isUnitsDropdownEnabled = false;
                // let selectedUnitIds = this.state.selectedUnitIds;
                // let selectedUnitPlaceholder = this.state.selectedUnitPlaceholder;
                let companyUnits = [];
                // For engagement type Self- add companyId & name
                if( this.props.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF ){
                    companyName=this.props.company.companyName;
                    companyId=this.props.company.companyId;
                    companyUnits = this.props.units.filter(unit => unit.companyId === this.props.company?.companyId);
                    isUnitsDropdownEnabled= true;
                    // if(!isChecked){
                    //     selectedUnitPlaceholder=unitDefaultPlaceholder
                    //     selectedUnitIds=[];
                    // }
                }
                let projects = this.props.projects;
                let employees = this.props.employees;

                let assignmentTypeFiltered = [];
                if(!isEmptyVariable(selectedDepartmentId)){
                    assignmentTypeFiltered = this.props.assignmentType.filter(at => at.departmentId === selectedDepartmentId);
                }
                if(assignmentTypeFiltered.length === 1){
                    const selectedAuditType = assignmentTypeFiltered[0].auditType?JSON.parse(assignmentTypeFiltered[0].auditType.replace(/'/g, '"')).map(auditType => ({ auditType })):[];

                    if(selectedAuditType.length === 1){
                        this.setState({
                            selectedAuditType:selectedAuditType[0].auditType,
                            selectAssignmentEntityFlag:true
                        })
                    }else{
                        this.setState({
                            selectedAuditType:auditTypeDefaultPlaceholder,
                            selectAssignmentEntityFlag:false
                        })
                    }
                    this.setState({
                        selectedAssignmentTypePlaceholder:assignmentTypeFiltered[0].name,
                        selectedAssignmentTypeId:assignmentTypeFiltered[0].assignmentTypeId,
                        auditTypesFiltered:selectedAuditType
                    })
                }else{
                    // If there are no assignment types filtered, reset the state to default values.
                    this.setState({
                        selectedAssignmentTypePlaceholder:assignmentTypeDefaultPlaceholder,
                        selectedAssignmentTypeId:"",
                        auditTypesFiltered:[],
                        selectedAuditType:auditTypeDefaultPlaceholder
                    })
                }
                let newCompanyUnits =[]
                Object.values(companyUnits).map((item)=>{
                    let  tempItem = item
                    tempItem["unitNameAndCity"] = item.unitName+", "+item.city
                    newCompanyUnits.push(item)
                })
               
                let newEmployees = []
                Object.values(employees).map((item)=>{
                    let tempItem = item
                    tempItem["empAndDesignation"] = item.salutation+" "+item.firstName+" "+item.lastName+" ("+item.designation+")"
                    newEmployees.push(item)
                })
                this.state.selectedCompanyObj={};
                this.setState({
                    planDate:"",
                    selectedCompanyId:companyId,
                    selectedCompanyPlaceholder:companyName,

                    companyUnits:newCompanyUnits,
                    projects:projects,
                    employees:newEmployees,
                    selectedUnitObj:[],
                    selectedProjectObj:[],
                    selectedEmployeeObj:[],
                    selectedUnitPlaceholder:unitDefaultPlaceholder,
                    selectedProjectPlaceholder:projectDefaultPlaceholder,
                    selectedEmployeePlaceholder:employeeDefaultPlaceholder,
                    isUnitsDropdownEnabled:isUnitsDropdownEnabled,
                    assignmentTypeFiltered:assignmentTypeFiltered,
                    assignmentArea:"",
                    selectedFunctionObjArr:[],
                    selectedProcessObjArr:[],

                    selectedDepartmentId:selectedDepartmentId,
                    selectedDepartmentName:selectedDepartmentName,
                    submitClickedAtleastOnce:false,
                    formErrors:{},
                })
            }
        }
    }

    handleCreateDialogClose = (flag) => {
        this.setState({
            companyUnits:[],
            selectedCompanyObj:{}
        },()=>{
            this.props.handleCreateDialogClose(flag);
        })
        
    }

    deptDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['department'] = "";
            FormErrorsTemp['functionList'] = "";
            FormErrorsTemp['processList'] = "";
        }

        let filteredAT = this.props.assignmentType.filter(at => at.departmentId === item.departmentId)
        this.setState({
            selectedDepartmentName:item.name,
            selectedDepartmentId:item.departmentId,
            formErrors:FormErrorsTemp,
            assignmentTypeFiltered:filteredAT
        })
    }

    onDateChange = (date, dateString) => {
		let planDate = "";
		if (!isEmptyVariable(date)) {
			planDate = date.format("YYYY-MM-DD");
        }
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp['planDate'] = "";
		this.setState({
            planDate:planDate,
            formErrors:FormErrorsTemp
        })
    };
       
    disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }
    //Functions
    handleSelectFunctionDialogClose = () => {
        this.setState({
            showSelectFunctionDialog:false,
        })
    }
    handleSelectFunctionDialogSaveClose = (selectedFunctionObjArr) => {
        let FormErrorsTemp  = this.state.formErrors;
        FormErrorsTemp['functionList'] = "";
        this.setState({
            showSelectFunctionDialog:false,
            selectedFunctionObjArr:selectedFunctionObjArr,
            formErrors:FormErrorsTemp,
        })
    }
    handleSelectFunctionDialogShow = () => {
        let formErr = this.state.formErrors;
        if(isEmptyVariable(this.state.selectedDepartmentId) && !this.props.isEdit){
            formErr["functionList"] = "Please select the department before function selection"
            this.setState({
                formErrors:formErr
            })
        }else{
            this.setState({
                showSelectFunctionDialog:true,
            })
        }
        
    }

    //Process
    handleSelectProcessDialogClose = () => {
        this.setState({
            showSelectProcessDialog:false,
        })
    }

    handleSelectProcessDialogSaveClose = (selectedProcessObjArr) => {
        let FormErrorsTemp  = this.state.formErrors;
        FormErrorsTemp['processList'] = "";
        this.setState({
            showSelectProcessDialog:false,
            formErrors:FormErrorsTemp,
            selectedProcessObjArr:selectedProcessObjArr
        })
    }

    handleSelectProcessDialogShow = () => {
        let formErr = this.state.formErrors;
        if(isEmptyVariable(this.state.selectedDepartmentId) && !this.props.isEdit){
            formErr["processList"] = "Please select the department before process selection"
            this.setState({
                formErrors:formErr
            })
        }else{
            this.setState({
                showSelectProcessDialog:true,
            })
        }
        
    }

    companyDropdownClick = (item) => {
        this.setState({
            showLoader:true,
        })
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['company'] = "";
        }

        let companyUnits = this.props.units.filter(unit => unit.companyId === item.companyId);
        let newCompanyUnits = []
        if(this.state.selectedAuditType === selectedAssignmentEntityProject){
            newCompanyUnits = [{
                unitNameAndCity:"All",
                unitId:""
            }]
        }
        Object.values(companyUnits).map((item)=>{
            let tempItem = item
            tempItem["unitNameAndCity"] = item.unitName+", "+item.city
            newCompanyUnits.push(item)
        })
       

        this.setState({
            selectedCompanyPlaceholder:item.companyName,
            selectedCompanyId:item.companyId,
            formErrors:FormErrorsTemp,

            //reset units when company is changed
            isUnitsDropdownEnabled:true,
            selectedUnitObj:[],
            selectedProjectObj:[],
            selectedUnitPlaceholder:unitDefaultPlaceholder,
            selectedProjectPlaceholder:projectDefaultPlaceholder,
            selectedEmployeePlaceholder:employeeDefaultPlaceholder,
            companyUnits:newCompanyUnits,

            showLoader:false,
        })
    }

       

    assignmentTypeDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['assignmentType'] = "";
        }

        const selectedAuditType = item.auditType?JSON.parse(item.auditType?.replace(/'/g, '"')).map(auditType => ({ auditType })):[];

        if(selectedAuditType.length === 1){
            this.setState({
                selectedAuditType:selectedAuditType[0].auditType,
                auditTypesFiltered:selectedAuditType,
                selectAssignmentEntityFlag:true
            })
        }else{
            this.setState({
                selectedAuditType:auditTypeDefaultPlaceholder,
                auditTypesFiltered:selectedAuditType,
                selectAssignmentEntityFlag:false
            })
        }
        this.setState({
            selectedAssignmentTypePlaceholder:item.name,
            selectedAssignmentTypeId:item.assignmentTypeId,
            formErrors:FormErrorsTemp,
        })
    }

    auditTypeDropdownClick = (item) => {
        let FormErrorsTemp = this.state.formErrors;
        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['selectedAuditType'] = "";
        }
        this.setState({
            selectedAuditType:item.auditType,
            selectedEmployeeObj:[],
            selectedProjectObj:[],
            formErrors:FormErrorsTemp,
            selectedCompanyObj:{},
            selectedUnitObj:{},
            isUnitsDropdownEnabled:false
        })
    }

    handleSelection = (e) => {
        // e.stopPropagation();
        let type = e.target.name;

        let FormErrorsTemp = this.state.formErrors;
        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['assignmentArea'] = "";
        }

        this.setState({
            assignmentArea:type,
            formErrors:FormErrorsTemp
        });
    }

    handleCreateMaster = () => {
        this.setState({
            submitClickedAtleastOnce:true,
            showLoader:true
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(!this.props.isEdit){
            if(isEmptyVariable(this.state.selectedDepartmentName) 
            || this.state.selectedDepartmentName === departmentDropdownDefaultValue){
                isError = true;
                FormErrorsTemp['department'] = "Please select the Department";
            }
        }
        
        if(isEmptyVariable(this.state.planDate)){
            isError=true;
            FormErrorsTemp['planDate'] = "Please select the Plan Date";
        }

        if(isEmptyVariable(this.state.selectedCompanyPlaceholder) 
        || this.state.selectedCompanyPlaceholder === companyDefaultPlaceholder){
            isError = true;
            FormErrorsTemp['company'] = "Please select Company";
        }

        if(isEmptyVariable(this.state.selectedAssignmentTypePlaceholder) 
        || this.state.selectedAssignmentTypePlaceholder === assignmentTypeDefaultPlaceholder){
            isError = true;
            FormErrorsTemp['assignmentType'] = "Please select Assignment Type";
        }

        if(isEmptyVariable(this.state.selectedAuditType)
        || this.state.selectedAuditType === auditTypeDefaultPlaceholder){
            isError = true;
            FormErrorsTemp['selectedAuditType'] = "Please select Audit Type";
        }

        if(this.state.selectedAuditType !== selectedAssignmentEntityProject && isEmptyArray(this.state.selectedUnitObj)){
            isError = true;
            FormErrorsTemp['selectedUnit'] = "Please select Units";
        }

        if(isEmptyArray(this.state.selectedProjectObj) &&
        this.state.selectedAuditType === selectedAssignmentEntityProject){
            isError = true;
            FormErrorsTemp['selectedProject'] = "Please select Project";
        }

        if(isEmptyArray(this.state.selectedEmployeeObj) && 
        this.state.selectedAuditType === selectedAssignmentEntityEmployee){
            isError = true;
            FormErrorsTemp['selectedEmployees'] = "Please select Employees";
        }

        if(isEmptyVariable(this.state.assignmentArea)){
            isError = true;
            FormErrorsTemp['assignmentArea'] = "Please select Scope";
        }

        if(this.state.assignmentArea==="Process" &&
        isEmptyArray(this.state.selectedProcessObjArr)){
            isError = true;
            FormErrorsTemp['processList'] = "Please select Process";
        }

        if(this.state.assignmentArea==="Function" &&
        isEmptyArray(this.state.selectedFunctionObjArr)){
            isError = true;
            FormErrorsTemp['functionList'] = "Please select Function";
        }
        let selectedUnitIds = [];
        let unit = this.state.companyUnits;
        Object.values(this.state.selectedUnitObj).map((item)=>{
            if(item.unitNameAndCity === "All"){
                selectedUnitIds = unit.map(item => item.unitId);
                selectedUnitIds.shift();
            }else{
                selectedUnitIds.push(item.unitId)
            }
        })
        let selectedProjectIds = [];
        Object.values(this.state.selectedProjectObj).map((item)=>{
            selectedProjectIds.push(item.projectId)
        })

        let selectedEmployeeIds = [];
        Object.values(this.state.selectedEmployeeObj).map((item)=>{
            selectedEmployeeIds.push(item.employeeId)
        })
        
        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            let url = "";
            let postParams = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                planDate:this.state.planDate,
                departmentId:this.state.selectedDepartmentId,
                companyId:this.state.selectedCompanyId,
                projectIds:JSON.stringify(selectedProjectIds),
                employeeIds:JSON.stringify(selectedEmployeeIds),
                assignmentTypeId:this.state.selectedAssignmentTypeId,
                assignmentArea:this.state.assignmentArea,
                auditType:this.state.selectedAuditType,
            }
            if(this.state.selectedAuditType === selectedAssignmentEntityProject){
                postParams.projectUnits = JSON.stringify(selectedUnitIds);
            }else if(this.state.selectedAuditType === selectedAssignmentEntityEmployee){
                postParams.employeeUnits = JSON.stringify(selectedUnitIds);
            }else{
                postParams.units = JSON.stringify(selectedUnitIds);
            }
            if(this.state.assignmentArea === "Process"){
                let processIds = [];

                this.state.selectedProcessObjArr.map(item=>{
                    processIds.push(item.processId)
                })
                postParams.processIds = JSON.stringify(processIds)

            }else if(this.state.assignmentArea === "Function"){
                let functionIds = [];
                this.state.selectedFunctionObjArr.map(item=>{
                    functionIds.push(item.functionId)
                })
                postParams.functionIds = JSON.stringify(functionIds)
            }else if(this.state.assignmentArea === "CheckList"){
                
            }

            if(this.props.isEdit){
                url = Constants.updatePlannedAssignment;
                postParams.assignmentId = this.state.selectedId;
            }else{
                url = Constants.createAssignment;
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParams)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showCreateDialog:false,
                        showLoader:false,
                        isEdit:false,
                        selectedId:"",
                        name:"",
                        shortName:"",
                        description:"",
                    },() => {
                        this.handleCreateDialogClose(true);
                    });
                }else{
                    FormErrorsTemp['apiErr'] = data.responseMessage;
                    this.setState({
                        formErrors:FormErrorsTemp,
                        showLoader:false
                    });
                }
            });
        }
    }
    unitDropdownClick = (item) => {
        let unitItem = [];
        let selectedUnitObj=[];
        let FormErrorsTemp = this.state.formErrors;
        if(Array.isArray(item)){
            unitItem = item;
        }else{
            unitItem.push(item);
        }
        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['selectedUnit'] = "";
        }
        for(var i =0;i<unitItem.length;i++)
        {
            selectedUnitObj.push(unitItem[i])
        }
        this.setState({
            selectedUnitObj:selectedUnitObj,
            formErrors:FormErrorsTemp
        })
    }

    projectDropdownClick = (item) => {
        let projectItem = [];
        if(Array.isArray(item)){
            projectItem = item;
        }else{
            projectItem.push(item);
        }
        let selectedProjectObj=[];
        let FormErrorsTemp = this.state.formErrors;
        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['selectedProject'] = "";
        }
        for(var i =0;i<projectItem.length;i++)
        {
            selectedProjectObj.push(projectItem[i])
        }
        this.setState({
            selectedProjectObj:selectedProjectObj,
            formErrors:FormErrorsTemp
        })
    }

    employeeDropdownClick = (item) => {
        let employeeItem = [];
        if(Array.isArray(item)){
            employeeItem = item;
        }else{
            employeeItem.push(item);
        }
        let selectedEmployeeObj=[];
        let FormErrorsTemp = this.state.formErrors;
        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['selectedEmployees'] = "";
        }
        for(var i =0;i<employeeItem.length;i++)
        {
            selectedEmployeeObj.push(employeeItem[i])
        }
        this.setState({
            selectedEmployeeObj:selectedEmployeeObj,
            formErrors:FormErrorsTemp
        })
    }

    render(){
        let fields = {text:"unitNameAndCity",value:"unitId"}
        return(
            <div>
                <Modal className="custom-dialog" show={this.props.showCreateDialog} 
                backdrop="static">
                    <Modal.Header>
                        <h5>{this.props.isEdit?"Edit Assignment":"Add Assignment"}</h5>
                        <button 
                            onClick={this.handleCreateDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div class="modal-body">
                        {
                            this.state.showLoader &&
                            <div class="loader"></div>
                        }
                        <div class="addnew-modal-form">
                            {
                                !this.props.isEdit &&
                                !isEmptyArray(this.props.departmentArr) &&
                                this.props.departmentArr.length > 1 &&
                                <div class="addnew-modal-form-group">
                                    <p>Department*</p>
                                    <RegularDropdown 
                                        placeholder={this.state.selectedDepartmentName}
                                        dropdownArr={this.props.departmentArr}
                                        labelParam="name"
                                        onDropDownItemClick={this.deptDropdownClick}
                                        defaultPlaceholderDropDown={departmentDropdownDefaultValue}
                                    />
                                    {
                                        !isEmptyVariable(this.state.formErrors['department']) &&
                                        <span class="cm-error">{this.state.formErrors['department']}</span>
                                    }
                                </div>
                            }
                            <div class="addnew-modal-form-group">
                                <p>Assignment Type*</p>
                                <RegularDropdown 
                                    placeholder={this.state.selectedAssignmentTypePlaceholder}
                                    dropdownArr={this.state.assignmentTypeFiltered}
                                    labelParam="name"
                                    onDropDownItemClick={this.assignmentTypeDropdownClick}
                                    defaultPlaceholderDropDown={assignmentTypeDefaultPlaceholder}
                                />
                                {
                                    !isEmptyVariable(this.state.formErrors['assignmentType']) &&
                                    <span class="cm-error">{this.state.formErrors['assignmentType']}</span>
                                }
                            </div>
                            {
                            !isEmptyVariable(this.state.selectedAssignmentTypePlaceholder) 
                            && this.state.selectedAssignmentTypePlaceholder !== assignmentTypeDefaultPlaceholder &&
                            <>
                            <div class="addnew-modal-form-group">
                                <p>Assignment Entity*</p>
                                <RegularDropdown 
                                    placeholder={this.state.selectedAuditType}
                                    dropdownArr={this.state.auditTypesFiltered}
                                    labelParam="auditType"
                                    disabled={this.state.selectAssignmentEntityFlag}
                                    onDropDownItemClick={this.auditTypeDropdownClick}
                                    defaultPlaceholderDropDown={auditTypeDefaultPlaceholder}
                                />
                                {
                                    !isEmptyVariable(this.state.formErrors['selectedAuditType']) &&
                                    <span class="cm-error">{this.state.formErrors['selectedAuditType']}</span>
                                }
                            </div>
                            <div class="addnew-modal-form-group">
                                <p>Date*</p>
                                <DatePicker
                                    onChange={this.onDateChange}
                                    format={"DD/MM/YYYY"}
                                    // disabledDate={this.disabledDate}
                                    value={!isEmptyVariable(this.state.planDate) ? moment(this.state.planDate) : ""}
                                />
                                {
                                    !isEmptyVariable(this.state.formErrors['planDate']) &&
                                    <span class="cm-error">{this.state.formErrors['planDate']}</span>
                                }
                            </div>
                            {
                                this.props.company?.companyType!== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                <div class="addnew-modal-form-group">
                                    <p>Client*</p>
                                    {/* <RegularDropdown 
                                        placeholder={this.state.selectedCompanyPlaceholder}
                                        dropdownArr={this.props.companies}
                                        labelParam="companyName"
                                        onDropDownItemClick={this.companyDropdownClick}
                                        defaultPlaceholderDropDown={companyDefaultPlaceholder}
                                    /> */}
                                  

                                  <ReactSelectDropdown
                                        handleReactSelectClose={this.companyDropdownClick}
                                        selectedDDObj= {this.state.selectedCompanyObj}
                                        selectDropdownArr={this.props.companies}
                                        label={"companyName"}
                                        value={"companyId"}
                                        disabled={this.state.selectedAuditType === auditTypeDefaultPlaceholder}
                                        placeholder={this.state.selectedCompanyPlaceholder}
                                    />
                                    {
                                        !isEmptyVariable(this.state.formErrors['company']) &&
                                        <span class="cm-error">{this.state.formErrors['company']}</span>
                                    }
                                </div>
                            }
                            {this.state.selectedAuditType !== auditTypeDefaultPlaceholder &&
                                this.state.selectedAuditType === selectedAssignmentEntityProject &&
                                <div className="addnew-modal-form-group">
                                    <p>{this.state.selectedAuditType[0].toUpperCase() + this.state.selectedAuditType.slice(1)}*</p>
                                    <ReactSelectDropdown
                                        handleReactSelectClose={this.projectDropdownClick}
                                        selectedDDObj= {this.state.selectedProjectObj}
                                        selectDropdownArr={this.state.projects}
                                        label={"projectName"}
                                        value={"projectId"}
                                        placeholder={this.state.selectedProjectPlaceholder}
                                    />
                                    {
                                        !isEmptyVariable(this.state.formErrors['selectedProject']) &&
                                        <span className="cm-error">{this.state.formErrors['selectedProject']}</span>
                                    }
                                </div>
                            }
                            {this.state.selectedAuditType !== auditTypeDefaultPlaceholder &&
                                this.state.selectedAuditType === selectedAssignmentEntityEmployee &&
                                <div className="addnew-modal-form-group">
                                    <p>{this.state.selectedAuditType[0].toUpperCase() + this.state.selectedAuditType.slice(1)}*</p>
                                    <ReactSelectDropdown
                                        handleReactSelectClose={this.employeeDropdownClick}
                                        selectedDDObj= {this.state.selectedEmployeeObj}
                                        selectDropdownArr={this.state.employees}
                                        label={"empAndDesignation"}
                                        value={"employeeId"}
                                        placeholder={this.state.selectedEmployeePlaceholder}
                                        isMultiselect={true}
                                    />
                                    {
                                        !isEmptyVariable(this.state.formErrors['selectedEmployees']) &&
                                        <span className="cm-error">{this.state.formErrors['selectedEmployees']}</span>
                                    }
                                </div>
                            }
                            <div className="addnew-modal-form-group">
                                <p>Units{this.state.selectedAuditType !== selectedAssignmentEntityProject && "*"}</p>
                                {/* <MultiSelectComponent 
                                    id="unitsCheckbox"
                                    dataSource={this.state.companyUnits}
                                    fields={fields}
                                    placeholder={this.state.selectedUnitPlaceholder} 
                                    mode="CheckBox"
                                    closePopupOnSelect={false}
                                    allowFiltering={false}
                                    select={this.addSelection}
                                    removed={this.removeSelection}
                                    cssClass="clientsChecklist dialogcl"
                                    value={this.state.selectedUnitIds}
                                    showDropDownIcon={true}
                                    showClearButton={false}
                                    enabled={this.state.isUnitsDropdownEnabled}
                                >
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent> */}
                                 <ReactSelectDropdown
                                    handleReactSelectClose={this.unitDropdownClick}
                                    selectedDDObj= {this.state.selectedUnitObj}
                                    selectDropdownArr={this.state.companyUnits}
                                    label={"unitNameAndCity"}
                                    value={"unitId"}
                                    placeholder={this.state.selectedUnitPlaceholder}
                                    disabled={!this.state.isUnitsDropdownEnabled}
                                    // isMultiselect ={true}
                                />
                                {
                                    !isEmptyVariable(this.state.formErrors['selectedUnit']) &&
                                    <span className="cm-error">{this.state.formErrors['selectedUnit']}</span>
                                }
                            </div>
                            <div class="addnew-modal-form-group">
                                <p>Scope*</p>
                                <TableRadioButton
                                style={{marginLeft:0,display:"inline-flex"}}
                                themeSettings={this.props.themeSettings}>
                                    <input 
                                        type="checkbox"
                                        name={"Process"}
                                        id={"processCb"}
                                        onChange={this.handleSelection}
                                        checked={this.state.assignmentArea==="Process"?true:false}
                                    />
                                    <label for={"processCb"} 
                                    style={{marginLeft:0}}>Process</label>
                                </TableRadioButton>
                                <TableRadioButton
                                style={{marginLeft:10,display:"inline-flex"}}
                                themeSettings={this.props.themeSettings}>
                                    <input 
                                        type="checkbox"
                                        name={"Function"}
                                        id={"functionCb"}
                                        onChange={this.handleSelection}
                                        checked={this.state.assignmentArea==="Function"?true:false}
                                    />
                                    <label for={"functionCb"} 
                                    style={{marginLeft:0}}>Function</label>
                                </TableRadioButton>
                                <TableRadioButton
                                style={{marginLeft:10,display:"inline-flex"}}
                                themeSettings={this.props.themeSettings}>
                                    <input 
                                        type="checkbox"
                                        name={"CheckList"}
                                        id={"checklistCb"}
                                        onChange={this.handleSelection}
                                        checked={this.state.assignmentArea==="CheckList"?true:false}
                                    />
                                    <label for={"checklistCb"} 
                                    style={{marginLeft:0}}>Checklist</label>
                                </TableRadioButton>
                                {
                                    !isEmptyVariable(this.state.formErrors['assignmentArea']) &&
                                    <span className="cm-error">{this.state.formErrors['assignmentArea']}</span>
                                }
                            </div>
                            {
                                this.state.assignmentArea==="Function" &&
                                <div className="addnew-modal-form-group">
                                    <p>Function</p>
                                    <div className="dummy-dropdown" tabIndex={0} onKeyPress={this.onKeyBtnPress}  
                                    onClick={this.handleSelectFunctionDialogShow}>
                                        <p class="placeholder-text">
                                            {isEmptyArray(this.state.selectedFunctionObjArr)
                                            ?functionDropdownDefaultValue
                                            :this.state.selectedFunctionObjArr.length === 1
                                            ?"1 Function Selected"
                                            :this.state.selectedFunctionObjArr.length+" Functions Selected"}
                                        </p>
                                        <span class="material-icons">keyboard_arrow_right</span>
                                    </div>
                                    {
                                        !isEmptyVariable(this.state.formErrors['functionList']) &&
                                        <span className="cm-error">{this.state.formErrors['functionList']}</span>
                                    }
                                </div>
                            }   
                            {
                                this.state.assignmentArea==="Process" &&
                                <div className="addnew-modal-form-group">
                                    <p>Process</p>
                                    <div className="dummy-dropdown" tabIndex={0} onKeyPress={this.onKeyBtnPress}  
                                    onClick={this.handleSelectProcessDialogShow}>
                                        <p class="placeholder-text">
                                            {isEmptyArray(this.state.selectedProcessObjArr)
                                            ?processDropdownDefaultValue
                                            :this.state.selectedProcessObjArr.length === 1
                                            ?"1 Process Selected"
                                            :this.state.selectedProcessObjArr.length+" Processes Selected"}
                                        </p>
                                        <span class="material-icons">keyboard_arrow_right</span>
                                    </div>
                                    {
                                        !isEmptyVariable(this.state.formErrors['processList']) &&
                                        <span className="cm-error">{this.state.formErrors['processList']}</span>
                                    }
                                </div>
                            }   
                            </>
                            }
                        </div>
                        <div class="modal-close-save">
                            <div class="close-save-col">
                                <button onClick={this.handleCreateDialogClose} type="button" class="modal-close">Cancel</button>
                                <DialogSaveButton themeSettings={this.props.themeSettings} onClick={this.handleCreateMaster} type="button">Save</DialogSaveButton>
                            </div>
                        </div>
                    </div>

                </Modal>
                <SelectFunctionDialog
                    showFunctionsDialog={this.state.showSelectFunctionDialog}
                    handleSelectFuncDialogClose={this.handleSelectFunctionDialogClose}
                    handleSelectFunctionDialogSaveClose={this.handleSelectFunctionDialogSaveClose}
                    selectedFunctionObjArr={this.state.selectedFunctionObjArr}
                    departmentId={this.state.selectedDepartmentId}
                    themeSettings = {this.props.themeSettings}
                />
                <SelectProcessDialog
                    showProcessesDialog={this.state.showSelectProcessDialog}
                    handleSelectProcessDialogClose={this.handleSelectProcessDialogClose}
                    handleSelectProcessDialogSaveClose={this.handleSelectProcessDialogSaveClose}
                    selectedProcessObjArr={this.state.selectedProcessObjArr}
                    departmentId={this.state.selectedDepartmentId}
                    themeSettings = {this.props.themeSettings}
                />
            </div>
        )
    }
}

export default CreateAnnualPlan;