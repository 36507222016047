import React, { Component } from "react";
import * as Constants from "../../common/Global/constants";
import {  DialogSaveButton } from "../../common/Global/globalStyles.style";
import Sidebar from "../../common/Sidebar";
import Topbar from "../../common/Topbar";
import AlertDialog from "../../common/AlertDialog";
import {
	getLocalStorageVariables,
	getThemeSettingsVariables,
} from "../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";
import { BrowserRouter as Router } from "react-router-dom";
import { AsyncPaginate } from "react-select-async-paginate";

const themeSettings = getThemeSettingsVariables();
const userDetails = getLocalStorageVariables();

// City dropdown result size
const resultSize = 50;
const pageNo = 1;

class CitiesList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			apiSearchKey: "",
			searchkey: "",
			currentPageNo: 1,
			showLoader: false,
			totalCount: 0,
			datalist: [],


			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",

			showSearchCityDialog: false,
			selectedVal: "",
		};
	}

	componentDidMount() {}

	handleSearchCityDialogClose = () => {
		this.setState({
			showSearchCityDialog: false,
		});
	};

	showSearchCityDialog = () => {
		this.setState({
			showSearchCityDialog: true,

		});
	};

	handleAlertDialogCloseInfo = () => {
		this.setState({
			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",
		});
	};

	handleOnChangeSelectedOption = (e) => {
		console.log(e);
		this.setState({ 
            showSearchCityDialog: false,

            showAlertDialogInfo: true,
			alertDialogMessageInfo: e.city,

            selectedVal: e.city,

         });
	};

	/*******************API CALLS*******************/

	
	getCitiesList = (inputValue,loadedOptions, { page }) => {
		this.setState({
			showLoader: true,
		});

		return fetch(Constants.GetCities, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				city: inputValue,
				resultsize: resultSize,
                pageNo:page,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {

				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState({
						showLoader: false,
						datalist: data.data.result,
						totalCount: data.data.count,
						apiSearchKey: this.state.searchkey,
					});
					// return data.data.result;
					// alert((data.data.start + resultSize)  <= (data.data.count));
					return {
						options: data.data.result,
						hasMore: (data.data.start+resultSize) <= (data.data.count),
						additional: {
						  page: page + 1,
						},
					  };
				} else {
					this.setState({
						datalist: [],
						showLoader: false,
						apiSearchKey: this.state.searchkey,
					});
				}
			});
	};


	/*******************API CALLS END HERE*******************/

	render() {
		return (
			<Router>
				<main className="main-dashboard">
					<section className="dashboard-bg">
						<Sidebar />

						<div className="dashboard-right-bg position-relative">
							<div className="dashboard-wrapper">
								<Topbar />
								{this.state.showLoader && (
									<div class="loader"></div>
								)}
								<div className="body-wrapper">
									<div className="search-addnew">
										<DialogSaveButton themeSettings={themeSettings} onClick={this.showSearchCityDialog} type="button">
											Search City
										</DialogSaveButton>
									</div>
								</div>
							</div>
						</div>

						

						<AlertDialog
							showAlertDialog={this.state.showAlertDialogInfo}
							handleAlertDialogClose={this.handleAlertDialogCloseInfo}
							type={Constants.ALERT_TYPE_ALERT}
							alertDialogMessage={this.state.alertDialogMessageInfo}
							proceedBtnClick={this.handleAlertDialogCloseInfo}
							proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
							themeSettings={themeSettings}
						/>

						{/* Modal to Search dropdown city */}
						<Modal
							enforceFocus={false}
							className="custom-dialog add-user-dialog"
							show={this.state.showSearchCityDialog}
							onHide={this.handleSearchCityDialogClose}
						>
							<Modal.Header>
								<h5>Search City</h5>
								<button onClick={this.handleSearchCityDialogClose}>
									<span className="material-icons">close</span>
								</button>
							</Modal.Header>
							<hr />

							<div className="modal-body">
								<div className="addnew-modal-form">
									<div className="row custom-row">
										<div className="col-md-12 custom-col">
											<div className="addnew-modal-form-group">
												<AsyncPaginate
													loadOptions={this.getCitiesList}
													defaultOptions
													cacheOptions
													getOptionLabel={(e) => e.city}
													getOptionValue={(e) => e.city}
													onChange={this.handleOnChangeSelectedOption}
													additional={{
														page: pageNo,
													  }}
												/>
											</div>
										</div>
									</div>
								</div>

								<div className="modal-close-save">
									<div className="close-save-col">
										<button onClick={this.handleSearchCityDialogClose} type="button" className="modal-close">
											Cancel
										</button>
										
									</div>
								</div>
							</div>
						</Modal>
					</section>
				</main>
			</Router>
		);
	}
}

export default CitiesList;
