import React, { Component } from "react";
import * as Constants from "../../../../common/Global/constants";
import { DialogSaveButton, FilterCheckBox } from "../../../../common/Global/globalStyles.style";
import { getLocalStorageVariables, isEmptyVariable, isEmptyArray, getThemeSettingsVariables } from "../../../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();

class SelectValueAddtion extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,

			currentPageNo: 1,
			showLoader: false,
			resultSize: Constants.RESULT_SIZE,

			datalist: [],
		};
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showVADialog) {
			let map = {};
			this.props.selectedVAObj.forEach((item) => {
				map[item.valueAdditionId] = item;
			});
			console.log(JSON.stringify(map));

			let valueAdditionArrNew = [];
			this.props.valueAdditionArr.forEach((item) => {
				if (!isEmptyVariable(map[item.valueAdditionId])) {
					item.value = map[item.valueAdditionId].valueAdditionValue;
					item.is_NRSelected = map[item.valueAdditionId].valueAddition_NR === "Y" ? true : false;
				}
				valueAdditionArrNew.push(item);
			});
			this.setState({
				datalist: valueAdditionArrNew,
				componentDidMountFlag: true,
			});
		}
	}
	
	/*****************************API*********************************/

	render() {
		return (
			<section>
				<Modal className="task-select-table-dialog custom-dialog" show={this.props.showVADialog} backdrop="static">
					<Modal.Header>
						<h5>Value Addition</h5>
						<button onClick={this.props.handleSelectVADialogClose} type="button" data-dismiss="modal">
							<span class="material-icons">close</span>
						</button>
					</Modal.Header>
					<hr />
					<div className="modal-body">
						{this.state.showLoader && (
							<div class="loader"></div>
						)}
						{
							// Need to check component did mount flag, other wise until api is called,
							// it shows no items found layout.
							this.state.componentDidMountFlag && (
								<div className="body-wrapper">
									<div style={{ position: "relative" }}>
										{!isEmptyArray(this.state.datalist) &&
											this.state.datalist.map((item, index) => {
												return (
													<div>
														<div class="addnew-modal-form-group">
															<div class="row custom-row">
																<div class="col-md-6 custom-col">
																	<div className="add-new-form-group">
																		<p>{item.name}</p>
																		<input
																			type="text"
																			name={index}
																			value={item.value}
																			disabled={true}
																		/>
																	</div>
																</div>

																<div class="col-md-6 custom-col">
																	<div className="add-new-form-group">
																		<p>&nbsp;</p>
																		<FilterCheckBox themeSettings={themeSettings} style={{ padding: "7px 0px", marginLeft: 0 }}>
																			<input
																				type="checkbox"
																				name={index}
																				id={item.valueAdditionId}
																				checked={item.is_NRSelected ? true : false}
																			/>
																			<label for={item.valueAdditionId}>Not Required</label>
																		</FilterCheckBox>
																	</div>
																</div>
															</div>
														</div>
													</div>
												);
											})}
										{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
											<div class="no-items-layout">
												<div class="no-items-card">
													<h6>{isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}</h6>
												</div>
											</div>
										)}

										{/* <div className="overlay-nr" style={{ display: this.state.assertion_NR === "Y" ? "block" : "none" }}></div> */}
									</div>
									{/* {!isEmptyVariable(this.state.otherMasterFormErr) && <span className="cm-error">{this.state.otherMasterFormErr}</span>} */}
									<div className="modal-close-save m-t-15">
										<div className="close-save-col">
											<button onClick={this.props.handleSelectVADialogClose} type="button" className="modal-close m-b-0">
												Cancel
											</button>
											
										</div>
									</div>
								</div>
							)
						}
					</div>
				</Modal>
			</section>
		);
	}
}

export default SelectValueAddtion;
