import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { isEmptyVariable, getLocalStorageVariables,getStringWithSpaceAfterComma,isJsonString } from '../../../common/Global/commonFunctions';
import { AssigmentDetailsIconLayout } from '../../../common/Global/globalStyles.style';
import moment from "moment";
import ReportDetails from "../../Reports/Common/reportDetails";
import InfoCardComponent from "../infoCard/infoCardComponent";
import {
    getCheckListScoresFlag,
    getAllReportTasks,
} from '../Tasks/execTaskUtilFunctions';
const userDetails = getLocalStorageVariables();
const projectAuditType = "Project";
const employeeAuditType = "Employee";

class AssignmentHeading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignmentDetails:{},
            showDetailsDialog: false,
			selectedItem: "",
            showInfoCard:false,
        }
    }

    handleDetailsDialogClose = () => {
		this.setState({
			showDetailsDialog: false,
			selectedItem: "",
		});
	};

	handleDetailsDialogShow = (item, unitList) => {
		this.setState({
			showDetailsDialog: true,
			selectedItem: item,
            unitList: unitList
		});
	};

    /*******************API CALLS*******************/

    handleGetAssignmentDetails = () => {
		
		fetch(Constants.GetAssignmentsDetails, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				assignmentId: this.props.assignmentId,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				let assignmentDetails = {};
                let unitList = [];
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
                    assignmentDetails = data.data.assignmentDetails;
                    if(assignmentDetails.auditType === projectAuditType){
                        unitList = data.data.projectUnitList;
                    }else if(assignmentDetails.auditType === employeeAuditType){
                        unitList = data.data.defaultEmployeeUnitList;
                    }
				} else {
				}
                this.setState({
                    assignmentDetails: assignmentDetails,
                    showLoader: false,
                    unitList:unitList
                },()=>{
                    this.handleDetailsDialogShow(this.state.assignmentDetails, this.state.unitList);
                });
			});
	};
    handleGetInfoCardDetials =() =>{
        let getTaskListUrl = Constants.GetExecutetasks;
        let getAssignmentDetailsUrl = Constants.GetAssignmentsDetails;
        Promise.all([
			fetch(getTaskListUrl, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.props.assignmentId,
				}),
			}),
			fetch(getAssignmentDetailsUrl, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.props.assignmentId,
				}),
			}),
		])
			.then(([taskRes, assignmentRes]) => {
				return Promise.all([taskRes.json(),assignmentRes.json()]);
			})
			.then(([taskRes,assignmentRes]) => {
				let allDatalistTemp = [];
				let myDatalistTemp = [];
				let assignmentArea = "";
				
                //Since we need assignment area lets check this first
                let selectedTaskTemplateFields = [];
                let execTaskTemplateFields = [];
                let taskTemplateValuesMap = {};
                let executionTemplateValuesMap = {};
                let executionTemplateFixedFields = {};
                let assignmentRiskMatrix = [];
                let valueAdditions = [];
                if (assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
                        assignmentArea = assignmentRes.data.assignmentDetails.assignmentArea;

                    //TASK TEMPLATE RELATED STUFFS
                    taskTemplateValuesMap = assignmentRes.data.taskTemplateValuesMap;
                    let selectedTaskTemplateFieldsStr = assignmentRes.data.executionTemplateFields?.taskTemplateFields;//Template field IDs	
                    if(!isEmptyVariable(selectedTaskTemplateFieldsStr) && isJsonString(selectedTaskTemplateFieldsStr)){
                        selectedTaskTemplateFields = JSON.parse(selectedTaskTemplateFieldsStr);
                    }
                    
                    //EXEC TASK RELATED STUFFS
                    executionTemplateValuesMap = assignmentRes.data.executionTemplateValuesMap;
                    let execTaskTemplateFieldsStr = assignmentRes.data.executionTemplateFields?.templateFields;
                    if(!isEmptyVariable(execTaskTemplateFieldsStr) && isJsonString(execTaskTemplateFieldsStr)){
                        execTaskTemplateFields = JSON.parse(execTaskTemplateFieldsStr);
                    }

                    //EXEC TASK FIXED FIELDS
                    let fixedTemplateFieldsStr = assignmentRes.data.executionTemplateFields?.fixedTemplateFields;
                    if(!isEmptyVariable(fixedTemplateFieldsStr) && isJsonString(fixedTemplateFieldsStr)){
                        executionTemplateFixedFields = JSON.parse(fixedTemplateFieldsStr);
                    }

                    assignmentRiskMatrix = assignmentRes.data.riskLevels;
                    valueAdditions = assignmentRes.data.valueAdditions;

				} else {
				}

                let maxRank = 0;
                let temptotalTaksCount=0;
                let arr =[];
             
				if (taskRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || taskRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (taskRes.responseCode === Constants.CODE_SUCCESS) {
					allDatalistTemp = taskRes.data.assignedTasks;
					let allDataList = JSON.parse(JSON.stringify(allDatalistTemp))
					myDatalistTemp = getAllReportTasks(allDataList,assignmentArea,maxRank);
                    // let userId ="";
                    // if(!isEmptyVariable(this.props.selectedResourceId))
                    // {
                    //     userId = this.props.selectedResourceId
                    // }
                    // else 
                    // {
                    //     userId = this.props.userDetails.userId;
                    // }
                    //  arr = this.getUserTasksFromUserId(userId,allDatalistTemp);

                    
                   
                    Object.values(arr).map((item)=>{
                        temptotalTaksCount+= Object.values(item.taskList).length
                    })
				} 
               
               
				this.setState({
					datalist: myDatalistTemp,
                    selectedTaskTemplateFields:selectedTaskTemplateFields,
                    taskTemplateValuesMap:taskTemplateValuesMap,
                    execTaskTemplateFields:execTaskTemplateFields,
                    executionTemplateValuesMap:executionTemplateValuesMap,
                    executionTemplateFixedFields:executionTemplateFixedFields,
                    showInfoCard:true,
                    totalTaksCount:temptotalTaksCount,

				});
			});
            
    }
    closeInfoCard = () =>{
        this.setState({
            showInfoCard:false
        })
    }
    getUserTasksFromUserId = (userId,allData) => {
        let arr = allData.map((process) => {
            let taskList = [];
            let maxScore = 0;
            let totalScore = 0;
            process.taskList.forEach((task) => {
                if (task.userId + "" === userId + "") {
                    taskList.push(task);

                    //Now if it is checklist, then based on the type of checklist we need to assign scores
                    if (this.state.assignmentArea === "CheckList") {
                        let weight = isEmptyVariable(task.weight) ? 1 : task.weight;
                        let rank =
                            isEmptyVariable(task.rank) || task.rank === -1 ? 0 : task.rank;

                        //if rank is NA then that should be considered for max score also
                        let maxRankNew = task.rank === -1 ? 0 : this.state.checklistMaxRank;
                        maxScore += weight * maxRankNew;
                        totalScore += weight * rank;
                    }
                }
            });
            process.taskList = taskList;
            process.maxScore = maxScore;
            process.totalScore = totalScore;

            return process;
        });
        return arr;
    };
    render(){
        return (
            <div>
                <div className="assignment-heading" style={{marginTop:5}}>
                    {
                        this.props.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                        !isEmptyVariable(this.props.assignmentDetails.unitCityList) &&
                        <p className="m-b-0 font-bold" style={{marginRight:20}}>
                            {getStringWithSpaceAfterComma(this.props.assignmentDetails.unitNameCityList)} 
                        </p>
                    }
                    {
                        this.props.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                        !isEmptyVariable(this.props.assignmentDetails.companyName) &&
                        <p className="m-b-0 font-bold" style={{marginRight:20}}>
                            {this.props.assignmentDetails.companyName} 
                        </p>
                    }
                    {!isEmptyVariable(this.props.assignmentDetails.assignmentTypeName) &&
                        <p className="m-b-0 font-bold" style={{marginRight:20}}>
                            {this.props.assignmentDetails.assignmentTypeShortName} 
                        </p>
                    }
                    {/* {!isEmptyVariable(this.props.assignmentDetails.assignmentArea) &&
                        <p className="m-b-0 font-bold" style={{marginRight:20}}>
                            {this.props.assignmentDetails.assignmentArea} 
                        </p>
                    } */}
                    {!isEmptyVariable(this.props.assignmentDetails.endDate) &&
                        <p className="m-b-0 font-bold" style={{marginRight:20}}>
                            End Date: {moment(this.props.assignmentDetails.endDate).format("DD/MM/YYYY")} 
                        </p>
                    }
                    {!isEmptyVariable(this.props.assignmentDetails.periodToDate) && !isEmptyVariable(this.props.assignmentDetails.periodFromDate) &&
                        <p className="m-b-0 font-bold" style={{marginRight:20}}>
                            {"Sample Period: "+moment(this.props.assignmentDetails.periodFromDate).format("DD/MM/YYYY")} - {moment(this.props.assignmentDetails.periodToDate).format("DD/MM/YYYY")}
                        </p>
                    }
                    <AssigmentDetailsIconLayout themeSettings={this.props.themeSettings}>
                        <span class="material-icons" onClick={this.handleGetInfoCardDetials}>analytics</span>
                        <span 
                        className="material-icons" onClick={this.handleGetAssignmentDetails}>info</span>

                     </AssigmentDetailsIconLayout>
                </div>
                <ReportDetails
                    showDetailsDialog={this.state.showDetailsDialog}
                    handleDetailsDialogClose={this.handleDetailsDialogClose}
                    selectedItem={this.state.selectedItem}
                    unitList={this.state.unitList}
                    themeSettings={this.props.themeSettings}
                    company={this.props.company}
                />
                 {
                 this.state.showInfoCard&&   
                <InfoCardComponent 
                execTaskTemplateFields = {this.state.execTaskTemplateFields}
                taskTemplateFields ={this.state.selectedTaskTemplateFields}
                datalist = {this.state.datalist}
                showUploadAnnexureDialog ={ this.state.showInfoCard}
                themeSettings={this.props.themeSettings}
                executionTemplateValuesMap ={this.state.executionTemplateValuesMap}
                handleSelectUploadAnnexureDialogClose={this.closeInfoCard}
                totalTaskCount={this.state.totalTaksCount}
                taskTemplateValuesMap ={this.state.taskTemplateValuesMap}
                assignmentArea = {this.props.assignmentDetails.assignmentArea}
                />}
            </div>
        );
    }
}
export default AssignmentHeading;
