import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan} from '../../common/Global/globalStyles.style';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import TableDropDown from '../../common/DropdownMenus/TableDropdown';
import Pagination from '../../common/Global/pagination';
import {getLocalStorageVariables,isEmptyVariable, sortTable,
    getThemeSettingsVariables, isEmptyArray} from '../../common/Global/commonFunctions';
import {BrowserRouter as Router} from 'react-router-dom';
import AddEditState from "../CityMaster/addEditState";

const themeSettings  = getThemeSettingsVariables();
const userDetails  = getLocalStorageVariables();
const countryFilterDropdownDefaultValue = "India";
const sortState = "state";
const sortCountry = "country";

class StateList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            totalCount:0,
            datalist:[],
            resultSize:Constants.RESULT_SIZE,
            resultSizePlaceholder:Constants.RESULT_SIZE,
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            sort:"",
            sortDir:"",
            selectedState:"",
            selectedFilterCountryName:countryFilterDropdownDefaultValue,

            showCreateDialog:false,
            isEdit:false
        }
    }

    componentDidMount(){
        this.getStatesList();
    }

    // Handle input change event for the search input field
    handleChangeSearch = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    // Handle search icon click event
    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getStatesList();
        })
    }

    // Handle enter key press event for the search input field
    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getStatesList();
            })
        }
    }

    // Handle Edit State dialog close event
    handleCreateDialogClose = (reloadFlag) =>{
        this.setState({
            showCreateDialog:false,
            isEdit:false,
            selectedState:"",
        },()=>{
            if(reloadFlag === true){
                this.getStatesList();
            }
        });
    }

    // Handle to display total records based on dropdown selection items
    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        // Iterate through the dropdown array and update the selected item
        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            // Call the getStatesList function after updating the state
            this.getStatesList();
        })
    }

    countryFilterDropdownClick = (item,selectedIndex) => {
        let placeholder = "";
        let filterCountryArr = this.state.filterCountryArr;
        for(const [i,value] of filterCountryArr.entries()){
            if(i === selectedIndex){
                filterCountryArr[i].isSelected = true;
                placeholder = value.label;
            }else{
                filterCountryArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo: 1,
            selectedFilterCountryName:placeholder
        },() => {
            this.getStatesList();
        })
    }

    // Handle table action menu items click events
    onTableDropDownItemClick = (item, dropdownItem) => {
         if(item.label === "Edit"){
            this.setState({
                showCreateDialog:true,
                isEdit:true,
                selectedState:dropdownItem.state
            });
        }
    }

    // This function handle sorting of the table
    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getStatesList();
        })
    }

    /*******************API CALLS*******************/

    getStatesList = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetStates, {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                country:this.state.selectedFilterCountryName,
                state:this.state.searchkey,
                resultsize:this.state.resultSize,
                sort:this.state.sort,
                sortDir:this.state.sortDir
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let filterCountryArr = data.data.countries;
                let filterCountry = [];
                if(!isEmptyArray(filterCountryArr)){
                    filterCountry = filterCountryArr.map((item) => ({ 
                        label:item.country,
                        isSelected:item.country == this.state.selectedFilterCountryName ? true : false
                    }))
                }
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                    filterCountryArr:filterCountry,
                    componentDidMountFlag:true
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    apiSearchKey:this.state.searchkey,
                    componentDidMountFlag:true
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            fetch(Constants.GetStates, {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    state:this.state.searchkey,
                    country:this.state.selectedFilterCountryName,
                    resultsize:this.state.resultSize,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir
                })
            })
            .then(response => { return response.json(); } )
            .then(data => {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    /*******************API CALLS END HERE*******************/

    render() {
        const actionArr = [
            {
                icon:"edit",
                label:"Edit"
            }
        ]
        
        return(
            <Router>
                <section>
                    {
                        this.state.showLoader &&
                        <div class="loader"></div>
                    }
                    {
                        // Need to check component did mount flag, other wise until api is called,
                        // it shows no items found layout.
                        this.state.componentDidMountFlag &&
                        <div className="body-wrapper">
                            <div className="search-addnew-bg">
                                <div className="search-addnew">
                                    <div className="search-filter-layout">
                                        <FilterDropDown
                                            placeholder={this.state.resultSizePlaceholder}
                                            dropdownArr={this.state.resultSizeArr}
                                            onDropDownItemClick={this.resultSizeDropdownClick}
                                            themeSettings = {themeSettings}
                                            mleft={0}
                                        />
                                        <div className="search-col m-l-10">
                                            <input 
                                                name="searchkey" 
                                                type="text"
                                                onChange={this.handleChangeSearch}
                                                placeholder="Search" 
                                                onKeyPress={this.onEnterBtnPress}
                                                value={this.state.searchkey}
                                            />
                                            <SearchSpan 
                                                className="material-icons"
                                                themeSettings = {themeSettings}
                                                onClick = {this.onSearchIconClick}>search</SearchSpan>
                                        </div>
                                        <FilterDropDown
                                            placeholder={this.state.selectedFilterCountryName}
                                            dropdownArr={this.state.filterCountryArr}
                                            onDropDownItemClick={this.countryFilterDropdownClick}
                                            themeSettings={themeSettings}
                                            name={"label"}
                                            dropdownWidth={"120px"}
                                        />
                                    </div>
                                </div>
                            </div>
                            {
                                !isEmptyArray(this.state.datalist) && 
                                <div className="common-tab-bg">
                                    <div className="common-table" style={{width:"100%"}}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                <th className="text-center" width={"5%"}>No.</th>
                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortState)}>
                                                    <div className="sort-header">State
                                                    <span className={(this.state.sort === sortState)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                    </div>
                                                </th>
                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortCountry)}>
                                                    <div className="sort-header">Country
                                                    <span className={(this.state.sort === sortCountry)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                    </div>
                                                </th>
                                                <th className="text-center" width={"5%"}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                !isEmptyArray(this.state.datalist) && 
                                                this.state.datalist.map((item,idx) => {
                                                    return <tr >
                                                        <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                        <td>{item.state}</td>
                                                        <td>{item.country}</td>
                                                        <td className="text-center">
                                                            <TableDropDown 
                                                                actionArr={actionArr}
                                                                onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                dropDownId = {item}
                                                                themeSettings = {themeSettings}
                                                            />
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            {
                                this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                <div className="no-items-layout">
                                    <div className="no-items-card">
                                        <h6>
                                        {
                                            isEmptyVariable(this.state.apiSearchKey)?
                                            Constants.NO_RECORDS_WARNING
                                            :
                                            Constants.EMPTY_SEARCH_WARNING
                                        }
                                        </h6>
                                    </div>
                                </div>
                            }
                            <div className="pagination-layout">
                                <div className="row custom-row">
                                    <div className="col-md-9 custom-col">
                                    {
                                        (this.state.totalCount > this.state.datalist.length) && 
                                        <Pagination 
                                            totalLength ={this.state.totalCount} 
                                            items={this.state.datalist} 
                                            onChangePage={this.onChangePage} 
                                            pageSize={this.state.resultSize}
                                            currentPageNo = {this.state.currentPageNo}
                                            initialPage={this.state.currentPageNo} 
                                            themeSettings = {themeSettings}
                                        />
                                    }
                                    </div>
                                    <div className="col-md-3 custom-col">
                                        <p>{"Total Records: "+this.state.totalCount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <AddEditState 
                        showCreateDialog={this.state.showCreateDialog}
                        handleCreateDialogClose={this.handleCreateDialogClose}
                        isEdit={this.state.isEdit}
                        themeSettings={themeSettings}
                        selectedState= {this.state.selectedState}
                    />
                </section>
            </Router>
        );
    }
}

export default StateList;