import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable, ifEmptyReturnEmptyStr,
    ifEmptyReturn} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';

let userDetails  = {};
class ClientDetailsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            clientdata:{}
        }
    }

    componentDidMount(){
        userDetails  = getLocalStorageVariables();

    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showClientDetailsDialog){
            this.setState({
                componentDidMountFlag:false,
            },() =>{
                this.getClientDetails();
            })
        }
    }

    getClientDetails = () => {
        this.setState({
            showLoader:true
        })

        fetch(Constants.GetClientDetails,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                clientId:this.props.clientId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    clientdata:data.data.result,
                    componentDidMountFlag:true
                });
            }else{
                this.setState({
                    clientdata:{},
                    showLoader:false,
                    componentDidMountFlag:true
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            fetch(Constants.GetClientDetails,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    clientId:this.props.clientId
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        clientdata:data.data.result,
                    });
                }else{
                    this.setState({
                        clientdata:{},
                        showLoader:false,
                    });
                }
            });
        }
    }

    render() {
        return(
            <Modal className="user-details-dialog custom-dialog" 
            show={this.props.showClientDetailsDialog} onHide={this.props.handleClientDetailsDialogClose}>
                <Modal.Header>
                    <h5>{this.props.selectedClientName+" Details"}</h5>
                    <button 
                        onClick={this.props.handleClientDetailsDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    {
                        this.state.showLoader &&
                        <div class="loader"></div>
                    }

                    {
                        // Need to check component did mount flag, other wise until api is called,
                        // it shows no items found layout.
                        this.state.componentDidMountFlag &&
                        <div className="body-wrapper">
                            {
                                !isEmptyVariable(this.state.clientdata.clientId) && 
                                <div className="details-table-layout">
                                    <h6 className="m-b-10">Company Details</h6>
                                    <div className="row custom-row">
                                        <div className="col-md-6 custom-col">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td width={"48%"}>Company Name</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"48%"}>{this.state.clientdata.companyName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"48%"}>Short Name</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"48%"}>{this.state.clientdata.companyShortName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"48%"}>Client Code</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"48%"}>{this.state.clientdata.clientCode}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"48%"}>Engagement Type</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"48%"}>{this.state.clientdata.companyType}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"48%"}>Subscription Type</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"48%"}>{ifEmptyReturnEmptyStr(this.state.clientdata.subscriptionType,"-")}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="col-md-6 custom-col">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td width={"48%"}>Address line 1</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"48%"}>{this.state.clientdata.address1}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"48%"}>Address line 2</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"48%"}>{isEmptyVariable(this.state.clientdata.address2)?"-":this.state.clientdata.address2}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"48%"}>City</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"48%"}>{this.state.clientdata.city}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"48%"}>Pin code</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"48%"}>{this.state.clientdata.pinCode}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"48%"}>State</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"48%"}>{this.state.clientdata.state}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"48%"}>Country</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"48%"}>{this.state.clientdata.country}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                    <div className="row custom-row m-t-15">
                                        {
                                            !isEmptyVariable(this.state.clientdata.firstName1) &&
                                            <div className="col-md-6 custom-col">
                                                <h6 className="m-b-10">Point of Contact 1</h6>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td width={"48%"}>Name</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"48%"}>
                                                            {ifEmptyReturn(this.state.clientdata.salutation1)+" "+this.state.clientdata.firstName1+" "+
                                                            ifEmptyReturn(this.state.clientdata.middleName1)+" "+this.state.clientdata.lastName1}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width={"48%"}>Email</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"48%"}>{this.state.clientdata.email1}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={"48%"}>Phone Number</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"48%"}>{ifEmptyReturnEmptyStr(this.state.clientdata.phoneNo1,"-")}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={"48%"}>Mobile Number</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"48%"}>{ifEmptyReturnEmptyStr(this.state.clientdata.mobileNo1,"-")}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={"48%"}>Designation</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"48%"}>{this.state.clientdata.designation1}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                        {
                                            !isEmptyVariable(this.state.clientdata.firstName2) &&
                                            <div className="col-md-6 custom-col">
                                                <h6 className="m-b-10">Point of Contact 2</h6>
                                                <table>
                                                    <tbody>
                                                        <tr>
                                                            <td width={"48%"}>Name</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"48%"}>
                                                            {ifEmptyReturn(this.state.clientdata.salutation2)+" "+this.state.clientdata.firstName2+" "+
                                                            ifEmptyReturn(this.state.clientdata.middleName2)+" "+this.state.clientdata.lastName2}
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td width={"48%"}>Email</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"48%"}>{this.state.clientdata.email2}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={"48%"}>Phone Number</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"48%"}>{ifEmptyReturnEmptyStr(this.state.clientdata.phoneNo2,"-")}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={"48%"}>Mobile Number</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"48%"}>{ifEmptyReturnEmptyStr(this.state.clientdata.mobileNo2,"-")}</td>
                                                        </tr>
                                                        <tr>
                                                            <td width={"48%"}>Designation</td>
                                                            <td width={"4%"}>:</td>
                                                            <td width={"48%"}>{this.state.clientdata.designation2}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        }
                                    </div>



                                    
                                </div>
                            }
                        </div>
                    }
                    {
                        this.state.componentDidMountFlag && isEmptyVariable(this.state.clientdata.clientId) &&
                        <div class="no-items-layout">
                            <div class="no-items-card" style={{boxShadow:"none"}}>
                                <h6>
                                {
                                    Constants.NO_RECORDS_WARNING
                                }
                                </h6>
                            </div>
                        </div>
                    }

                </Modal.Body>
            </Modal>
        );
    }
}

export default ClientDetailsDialog;