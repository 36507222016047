import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,
    isEmptyVariable, 
    isEmptyArray,
    ifEmptyReturn
} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import moment from 'moment';
const userDetails  = getLocalStorageVariables();

class ReviewHistoryDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            datalist:[],
            totalCount:0,
            currentPageNo: 1,
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showReviewHistoryDialog){
            this.setState({
                componentDidMountFlag:false,
                showLoader:false,
                datalist:[],
                totalCount:0,
                currentPageNo: 1,
            },()=>{
                this.getInitData();
            })
            
        }
    }


    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetSampleCheckedAuditTrail,
        {
            method:"POST",
            mode:"cors",
            body: new URLSearchParams(
            {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                assignmentSampleCheckedId:this.props.masterDataId,
                pageNo:this.state.currentPageNo
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    datalist:data.data.auditTrail,
                    // totalCount:data.data.count,
                    showLoader:false,
                    componentDidMountFlag:true
                })
            }else{
                this.setState({
                    datalist:[],
                    totalCount:0,
                    showLoader:false,
                    componentDidMountFlag:true
                })
            }
        });
    }

    render() {
        return(
            <Modal className="review-history-dialog custom-dialog" 
            show={this.props.showReviewHistoryDialog} onHide={this.props.handleReviewHistoryDialogClose}>
                <Modal.Header>
                    <h5>Review History</h5>
                    <button 
                        onClick={this.props.handleReviewHistoryDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div className="modal-body">
                    {
                        this.state.showLoader &&
                        <div class="loader"></div>
                    }
                    <div className="review-history-wrapper">
                        {
                            this.state.componentDidMountFlag &&
                            <div className="review-history-list">
                                {
                                    !isEmptyArray(this.state.datalist) &&
                                    this.state.datalist.map(item => {
                                        return <div className="history-item-layout">
                                            {/* <p className="font-bold">{item.reviewStatus +" ["+item.screenName+(isEmptyVariable(item.dataType)?"]":" > "+item.dataType+"]")}</p> */}
                                            <p className="font-bold">{item.column}</p>
                                            
                                            <p className="review-history-remarks"><strong> </strong>{ (isEmptyVariable(item.oldValue)?"empty": item.oldValue) +" -> "+item.newValue}</p>
                                        
                                            <span>
                                                {ifEmptyReturn(item.salutation)+" "+item.firstName+" "+
                                                ifEmptyReturn(item.middleName)+" "+item.lastName}
                                            </span>
                                            <span className="review-history-date-span">{moment(item.createDate, "YYYY-MM-DDTHH:mm:ss.SSSZ").format("DD-MMM-YYYY hh:mm a")}</span>
                                        </div>
                                    })
                                }
                            </div>
                        }
                        {
                            this.state.componentDidMountFlag &&
                            isEmptyArray(this.state.datalist) &&
                            <div className="dialog-no-records">
                                <p>{Constants.NO_RECORDS_WARNING}</p>
                            </div>
                        }
                    </div>
                </div>
            </Modal>
        );
    }
}

export default ReviewHistoryDialog;