import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,getStatusSpan,
    isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import TreeNodeDropdown from '../../../common/DropdownMenus/TreeNodeDropdown';
import AddFunctionDialog from './addfunctiondialog'
import AlertDialog from '../../../common/AlertDialog';
import AlertWithRadioDialog from '../../../common/AlertDialog/alertwithradio';
import {ExpandCollapseWrapper} from '../../../common/Global/globalStyles.style';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-navigations/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";

const userDetails  = getLocalStorageVariables();
const activeStatus="Active";
const createdStatus="Created";
const updatedStatus="Updated";
const deletedStatus="Deleted";
const underReviewStatus="Under Review";

const AddChildDropdownObj = {
    icon:"add_circle",
    label:"Add Child"
}
const AddSiblingDropdownObj = {
    icon:"add_box",
    label:"Add Sibling"
}
const editDropdownObj = {
    icon:"edit",
    label:"Edit"
}
const delDropdownObj = {
    icon:"delete",
    label:"Delete"
}

class FunctionTreeDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader:false,
            showBtnLoader:false,
            functionMap:{},
            actionArr:[],
            syncFusionData:[],

            showCreateDialog:false,
            isEdit:false,
            createNodeType:"",
            selectedId:"",
            functionName_new:"",
            functionShortName:"",
            relativeFunctionId:"",
            clearAndResetFunctionTree:true,
            idToBeExpandedAfterAddingChild:"",

            operationType:"",
            deleteReactivateId:"",
            showAlertWithRadioDialog:false,
            alertWithRadioDialogMessage:"",
            showRadio:true,
            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",
        }
        this.fields = {};
        this.functionObj = {};
        this.reloadListing = false;
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showFunctionsTreeDialog){
            this.fields = {
                dataSource:[],
                id:"functionId",
                text:"functionName_new",
                parentID:"parentId",
                child:"newChildren",
                expanded:"isExpanded"
            };

            this.setState({
                showLoader:false,
                showBtnLoader:false,
                functionMap:{},
                actionArr:[],
                syncFusionData:[],
    
                showCreateDialog:false,
                isEdit:false,
                createNodeType:"",
                selectedId:"",
                functionName_new:"",
                functionShortName:"",
                relativeFunctionId:"",
                clearAndResetFunctionTree:true,
                idToBeExpandedAfterAddingChild:"",

                operationType:"",
                deleteReactivateId:"",
                showAlertWithRadioDialog:false,
                alertWithRadioDialogMessage:"",

                showAlertDialogInfo:false,
                alertDialogMessageInfo:"",
            },()=>{
                this.getFunctionTree();
            })
            
        }
    }

    handleAddEditDialogClose = (reloadFlag) =>{
        let id = "";
        if(this.state.createNodeType === "child") id = this.state.relativeFunctionId;
        this.setState({
            showCreateDialog:false,
            clearAndResetFunctionTree:false,
            isEdit:false,
            createNodeType:"",
            selectedId:"",
            functionName_new:"",
            functionShortName:"",
            relativeFunctionId:"",
            idToBeExpandedAfterAddingChild:id,
        },()=>{
            if(reloadFlag === true){
                this.reloadListing = true;
                this.getFunctionTree();
            }
        })
    }

    onDropDownItemClick = (item,functionObj) => {
        if(item.label === "Add Child"){
            this.setState({
                showCreateDialog:true,
                createNodeType:"child",
                relativeFunctionId:functionObj.functionId,
                
                isEdit:false,
            })
        }else if(item.label === "Add Sibling"){
            this.setState({
                showCreateDialog:true,
                createNodeType:"sibling",
                relativeFunctionId:functionObj.functionId,
                
                isEdit:false,
            })
        }else if(item.label === "Delete"){
            let showRadio = true;
            if(isEmptyArray(this.state.functionMap[functionObj.functionId].children)){
                showRadio = false
            }

            this.setState({
                operationType:item.label,
                deleteReactivateId:functionObj.functionId,
                showAlertWithRadioDialog:true,
                showRadio:showRadio,
                alertWithRadioDialogMessage:"Are you sure you want to delete "+functionObj.functionName_new+"?"
            })
        }else if(item.label === "Edit"){
            this.setState({
                showCreateDialog:true,
                createNodeType:"sibling",
                relativeFunctionId:functionObj.functionId,
                
                isEdit:true,
                selectedId:functionObj.functionId,
                functionName_new:functionObj.functionName_new,
                functionShortName:functionObj.functionShortName
            })
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleAlertWithDialogDialogClose = () =>{
        this.setState({
            showAlertWithRadioDialog:false,
            alertWithRadioDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    getRootLevelFunctions = (functionMapTemp) => {
        let rootFunctionArr = [];
        Object.values(functionMapTemp).map((item)=>{
            //this is backend level, it starts from 0, but front end level starts from 1
            if(item.level === 0){
                rootFunctionArr.push(item);
            }
        })
        return rootFunctionArr;
    }

    //No need of return value since the array itself is passed and updated.
    //Javascript maintains single array with pointers. it will not deep copy the array
    addParentIdandHasChildrenFlag = (tempArr, functionId, level) => {
        tempArr[functionId].level = level;

        if(!isEmptyArray(tempArr[functionId].children)){
            tempArr[functionId].children.map(childNode => {
                this.addParentIdandHasChildrenFlag(tempArr,childNode.functionId,level+1);
            });
        }else{
            tempArr[functionId].hasChildren = false;
        }
    }

    /************************API CALLS **************************/
    FunctionTree = (functionObj) => {
        let spanObj = getStatusSpan(functionObj.previousStatus,this.props.themeSettings);
        return <div className="dialog-tree-structure-sync">
        {
            !isEmptyVariable(functionObj) &&
                <div className="tree-node-sync">
                    {
                        this.props.rootNode.allowEdit === "N" &&
                        <div className="tree-text-layout">
                            <p className={functionObj.fontbold?"font-bold":""}>
                                {functionObj.functionName_new}
                                {/* {functionObj.functionName_new+" "+functionObj.functionId+"-"+functionObj.childOrder_new} */}
                            </p>
                            {
                                this.props.newFlag === "Y" && 
                                (functionObj.status_new === underReviewStatus ||
                                    functionObj.status_new === createdStatus ||
                                    functionObj.status_new === updatedStatus ||
                                    functionObj.status_new === deletedStatus) &&
                                <span style={spanObj.spanStyle} className="span-tag">
                                    {spanObj.spanText}
                                </span>
                            }
                        </div>
                    }
                    {
                        this.props.rootNode.allowEdit === "Y" &&
                        <div className="tree-dropdown-layout-sync">
                            <TreeNodeDropdown
                                placeholder={functionObj.functionName_new}
                                // placeholder={functionObj.functionName_new+" "+functionObj.functionId+"-"+functionObj.childOrder_new}
                                dropdownArr={
                                    functionObj.level === 1
                                    ?
                                    this.state.actionArrRoot
                                    :
                                    this.state.actionArr
                                }
                                labelParam={"label"}
                                onDropDownItemClick={this.onDropDownItemClick}
                                dropdownId={functionObj}
                                fontbold={functionObj.fontbold}
                                paraMT={"1.5px"}
                                themeSettings={this.props.themeSettings}
                            />
                            {
                                this.props.newFlag === "Y" && 
                                (functionObj.status_new === underReviewStatus ||
                                    functionObj.status_new === createdStatus ||
                                    functionObj.status_new === updatedStatus ||
                                    functionObj.status_new === deletedStatus) &&
                                <span style={spanObj.spanStyle} className="span-tag">
                                    {spanObj.spanText}
                                </span>
                            }
                        </div>
                    }
                    
                </div>
            }
        </div>
    }

    createSyncHRFusionData = (parentId,functionId,functionMap,isExpanded) => {
        //Now create an object
        let functionObj = functionMap[functionId];
        functionObj.newChildren = [];
        functionObj.isExpanded = isExpanded;
        if(parentId !== functionId){
            functionObj.parentId = parentId;
        }
        //now add children recursively
        functionObj.children.map((item)=>{
            functionObj.newChildren.push(this.createSyncHRFusionData(functionId,item.functionId,functionMap,false));
        });
        return functionObj;
    }

    updateSyncHRFusionData = (parentId,functionId,functionMap) => {
        //Now create an object
        let functionObj = functionMap[functionId];
        functionObj.newChildren = [];

        //Check the old function map
        if(!isEmptyVariable(this.state.functionMap[functionId])){
            functionObj.isExpanded = this.state.functionMap[functionId].isExpanded;
            functionMap[functionId].isExpanded = this.state.functionMap[functionId].isExpanded;
        }

        if(parentId !== functionId){
            functionObj.parentId = parentId;
        }
        //now add children recursively
        functionObj.children.map((item)=>{
            functionObj.newChildren.push(this.updateSyncHRFusionData(functionId,item.functionId,functionMap));
        });
        return functionObj;
    }

    deleteActivateFunctionNode = (deleteChildren) => {
        let url = "";

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteFunction;
        }

        this.setState({
            showBtnLoader:true,
        });

        fetch(url,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                functionId:this.state.deleteReactivateId,
                rootFunctionId:this.props.rootFunctionId,
                deleteChildren:deleteChildren
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    deleteReactivateId:"",
                    operationType:"",
                    alertWithRadioDialogMessage:"",
                    showAlertWithRadioDialog:false,
                    clearAndResetFunctionTree:false,
                    showBtnLoader:false
                },() => {
                    this.reloadListing = true;
                    this.getFunctionTree();
                });
            }else{
                this.setState({
                    showBtnLoader:false,
                    deleteReactivateId:"",
                    operationType:"",
                    alertWithRadioDialogMessage:"",
                    showAlertWithRadioDialog:false,
                    clearAndResetFunctionTree:false,

                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                })
            }
        });
    }

    getFunctionTree = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetFunctionsSubtree,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                rootFunctionId:this.props.rootFunctionId,
                newFlag:this.props.newFlag,
                status:"ALL"
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let temp = data.result.functionMap;
                let rootFunctionArr = this.getRootLevelFunctions(temp);

                rootFunctionArr.map((rootNode)=>{
                    this.addParentIdandHasChildrenFlag(temp,rootNode.functionId,1)
                })

                let syncFusionData = [];
                //clearAndResetFunctionTree - if it is false, then copy the isShowingChildren param 
                //from old state array, this is required because whenever the user adds a new node at the nth level
                //all the nodes are collapsed and shows only first level nodes since the API is called again
                if(!isEmptyArray(Object.keys(this.state.functionMap)) && 
                !this.state.clearAndResetFunctionTree){
                    let functionObj = this.updateSyncHRFusionData(this.props.rootFunctionId,this.props.rootFunctionId,temp);
                    functionObj.fontbold = true;
                    syncFusionData.push(functionObj);
                }else{
                    //create datastructure and Expand root level node's children by default
                    let functionObj = this.createSyncHRFusionData(this.props.rootFunctionId,this.props.rootFunctionId,temp,true);
                    temp[this.props.rootFunctionId].isExpanded = true;
                    functionObj.fontbold = true;
                    syncFusionData.push(functionObj);
                }

                //Action item arrays based on allowEdit flag
                let actionArrTemp = [];
                let actionArrRootTemp = [];
                if(this.props.rootNode.allowEdit === "Y"){
                    actionArrTemp = [AddChildDropdownObj,AddSiblingDropdownObj,editDropdownObj,delDropdownObj]
                    actionArrRootTemp = [AddChildDropdownObj,editDropdownObj]
                }

                //set the tree fields
                this.fields = {
                    dataSource:syncFusionData,
                    id:"functionId",
                    text:"functionName_new",
                    parentID:"parentId",
                    child:"newChildren",
                    expanded:"isExpanded"
                };

                this.setState({
                    functionMap:temp,
                    actionArr:actionArrTemp,
                    actionArrRoot:actionArrRootTemp,
                    idToBeExpandedAfterAddingChild:"",
                    syncFusionData:syncFusionData,
                    showLoader:false,
                    clearAndResetFunctionTree:false
                },()=>{
                    this.reference.refresh();
                });
            }else{
                this.setState({
                    functionMap:{},
                    showLoader:false,
                    syncFusionData:[],
                    clearAndResetFunctionTree:false
                });
            }
        });
    }

    moveTask = (draggedNodeId,draggedNodePid,droppedNodePid,droppedPos) => {
        fetch(Constants.Movefunction,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                rootFunctionId:this.props.rootFunctionId,
                functionId:draggedNodeId,
                fromFunctionId:draggedNodePid,
                toFunctionId:droppedNodePid,
                toChildOrder:droppedPos,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            } else {
                this.reloadListing = true;
                this.getFunctionTree();
            }
        });
    }

    expandAll = () => {
        this.reference.expandAll();
    }

    collapseAll = () => {
        this.reference.collapseAll();
    }

    dragStop = (args)=>{
        // console.log("Drag Node: "+JSON.stringify(args.draggedNodeData))
        // console.log("Drop Node: "+JSON.stringify(args.droppedNodeData))
        // console.log("Drop Level: "+args.dropLevel)

        //if the node is dropped above the parent node then cancel the drag
        if(args.dropLevel === 1){
            args.cancel = true;
        }
    }

    nodeExpanded = (args) =>{
        this.state.functionMap[args.nodeData.id].isExpanded = true;
    }

    nodeCollapsed = (args) =>{
        this.state.functionMap[args.nodeData.id].isExpanded = false;
    }

    nodeDrop = (args) => {
        console.log("Drag Node: "+JSON.stringify(args.draggedNodeData))
        console.log("Drop Node: "+JSON.stringify(args.droppedNodeData))
        console.log("Drop Index: "+args.dropIndex);
        console.log("Drop Level: "+args.dropLevel)

        let draggedNodeId = "";
        let draggedNodePid = "";

        let droppedNodeid = "";
        let droppedNodePid = "";
        let droppedPos = "";
        let dropLevel = "";

        if(!isEmptyVariable(args.draggedNodeData) && !isEmptyVariable(args.droppedNodeData)){
            draggedNodeId = args.draggedNodeData.id;
            draggedNodePid = args.draggedNodeData.parentID;

            droppedNodeid = args.droppedNodeData.id;
            droppedNodePid = args.droppedNodeData.parentID;
            droppedPos = args.dropIndex + 1;
            dropLevel = args.dropLevel;

            // now check what is the level of drop id
            let dropRelativeNodeLevel = this.state.functionMap[droppedNodeid].level;
            console.log("Drop R Level: "+dropRelativeNodeLevel)
            //dropped level is greater than relative node, then it is a child of that relative node
            //hence parent id is relative node id
            if(dropLevel > dropRelativeNodeLevel){
                droppedNodePid = droppedNodeid;
            }

            this.moveTask(draggedNodeId,draggedNodePid,droppedNodePid,droppedPos);
        }
    }

    handleFunctionTreeClose = () => {
        this.props.handleFunctionTreeDialogClose(this.reloadListing);
    }

    render() {
        // console.log(JSON.stringify(this.fields))
        let dnd = false;
        let deptId = "";
        if(!isEmptyVariable(this.props.rootNode) && this.props.rootNode.allowEdit === "Y"){
            dnd = true;
        }
        if(!isEmptyVariable(this.props.rootNode)){
            deptId = this.props.rootNode.departmentId;
        }
        
        return(
            <div>
                <Modal className="show-functions-tree-dialog custom-dialog" 
                show={this.props.showFunctionsTreeDialog} 
                onHide={this.handleFunctionTreeClose}>
                    <Modal.Header>
                        <h5>Function Tree</h5>
                        <button 
                            onClick={this.handleFunctionTreeClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        {
                            this.state.showLoader &&
                            <div class="loader"></div>
                        }
                        <ExpandCollapseWrapper themeSettings={this.props.themeSettings}>
                            <div className="expand-collapse-layout"
                                onClick={this.expandAll}
                                style={{marginRight:10}}
                                type="button">
                                <span class="material-icons">unfold_more</span>
                                <p>Expand All</p>
                            </div>
                            <div
                                className="expand-collapse-layout"
                                onClick={this.collapseAll}
                                style={{marginLeft:10}}
                                type="button">
                                <span class="material-icons">unfold_less</span>
                                <p>Collapse All</p>
                            </div>

                        </ExpandCollapseWrapper>
                        <TreeViewComponent 
                            fields={this.fields} 
                            allowDragAndDrop={dnd}
                            nodeTemplate={this.FunctionTree}
                            ref = {(treeNode) => {this.reference = treeNode}}
                            nodeDragStop={this.dragStop}
                            nodeDropped = {this.nodeDrop}
                            nodeExpanded = {this.nodeExpanded}
                            nodeCollapsed = {this.nodeCollapsed}
                            class="lalala"
                        />
                    </div>
                </Modal>

                <AddFunctionDialog
                    isEdit={this.state.isEdit}
                    showCreateDialog={this.state.showCreateDialog}
                    handleAddEditDialogClose={this.handleAddEditDialogClose}
                    createNodeType={this.state.createNodeType}
                    relativeFunctionId={this.state.relativeFunctionId}

                    departmentId={deptId}
                    rootFunctionId={this.props.rootFunctionId}

                    selectedId={this.state.selectedId}
                    functionName={this.state.functionName_new}
                    functionShortName={this.state.functionShortName}
                    themeSettings={this.props.themeSettings}
                />

                <AlertWithRadioDialog
                    showAlertDialog={this.state.showAlertWithRadioDialog}
                    handleAlertDialogClose={this.handleAlertWithDialogDialogClose}
                    alertDialogHeading={"Delete"}
                    alertDialogMessage={this.state.alertWithRadioDialogMessage}
                    proceedBtnClick={this.deleteActivateFunctionNode}
                    proceedBtnLabel={"Delete"}
                    showLoader={this.state.showBtnLoader}
                    showRadio={this.state.showRadio}
                />

                <AlertDialog 
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type= {Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                    themeSettings={this.props.themeSettings}
                />
            </div>
        );
    }
}

export default FunctionTreeDialog;