import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout,DialogSaveButton,TreeNodeCheckBox} from '../../common/Global/globalStyles.style';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import UserManagementTopMenu from './topmenu';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import RegularDropdown from '../../common/DropdownMenus/RegularDropdown';
import TableDropDown from '../../common/DropdownMenus/TableDropdown';
import AlertDialog from '../../common/AlertDialog';
import Pagination from '../../common/Global/pagination';
import {getLocalStorageVariables,isEmptyVariable, sortTable,truncateString,ifEmptyReturnEmptyStr,
    getThemeSettingsVariables,SplitJoinString, isEmptyArray,ifEmptyReturn} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";

const themeSettings  = getThemeSettingsVariables();
const userDetails  = getLocalStorageVariables();
const sortFirstName = "firstName";
const sortDesignation = "u.designation";
const sortClient = "v1.companyList";

const defaultEmpPlaceholder = "Select Employee";
const defaultUserPlaceholder = "Select User";
const defaultClientPlaceholder = "Select Client";

class ListManagers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            status:"Active",
            sort:"",
            sortDir:"",
            statusArr:[
                {
                    label:"Active",
                    isSelected:true
                },
                {
                    label:"Inactive",
                    isSelected:false
                }
            ],
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            allUsers:[],
            allDeptEmployees:[],
            allCompanies:[],

            showCreateDialog:false,
            submitClickedAtleastOnce:false,
            isEdit:false,
            formErrors:{},
            employeePlaceholder:defaultEmpPlaceholder,
            selectedEmployeeId:"",
            managerTypeClients:false,
            managerTypeUsers:true,
            selectedUserIds:[],
            selectedClientIds:[],

            operationType:"",
            deleteReactivateId:"",
            showAlertDialog:false,
            alertDialogMessage:"",
            showBtnLoader:false,

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showDetailsDialog:false,
            selectedItem:{}
        }
    }

    componentDidMount(){
        this.getInitialData();
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getManagersList();
            })
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleSelection = (e) => {
        // e.stopPropagation();
        let isChecked = e.target.checked;
        let type = e.target.name;
        let managerType = "";
        
        if(isChecked){
            managerType = type;
        }

        this.setState({
            managerType:managerType
        });
    }

    handleSelection = (e) => {
        // e.stopPropagation();
        let isChecked = e.target.checked;
        let name = e.target.name;

        this.setState({
            [name]:isChecked
        });
    }

    handleCreateDialogClose = () =>{
        this.setState({
            showCreateDialog:false,
            submitClickedAtleastOnce:false,
            isEdit:false,
            selectedUserId:"",
            employeePlaceholder:defaultEmpPlaceholder,
            selectedEmployeeId:"",
            managerTypeUsers:true,
            managerTypeClients:false,
            selectedUserIds:[],
            selectedClientIds:[],
            formErrors:{},
        });
    }

    employeeDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;
        if(this.state.submitClickedAtleastOnce){
            if(isEmptyVariable(item.firstName) || item.name === defaultEmpPlaceholder){
                FormErrorsTemp['employee'] = "Please select employee";
            }else{
                FormErrorsTemp['employee'] = "";
            }
        }

        this.setState({
            employeePlaceholder:item.displayName,
            selectedEmployeeId:item.userId,
            formErrors:FormErrorsTemp,
            selectedUserIds:[]
        },()=>{
            this.getDeptUsersList(item.userId)
        })
    }

    showCreateDialog = () => {
        this.setState({
            showCreateDialog:true,
            submitClickedAtleastOnce:false,
            isEdit:false,
            selectedUserId:"",
            employeePlaceholder:defaultEmpPlaceholder,
            selectedEmployeeId:"",
            selectedUserIds:[],
            managerTypeUsers:true,
            managerTypeClients:false,
            selectedClientIds:[],
            formErrors:{},
        });
    }

    addSelectionUsers = (args) => {
        let FormErrorsTemp  = this.state.formErrors;
        FormErrorsTemp['selectedUsers'] = "";

        if(!isEmptyVariable(args.itemData.userId))
        {
            this.state.selectedUserIds.push(args.itemData.userId)
        }
        this.setState({
            selectedUserIds:this.state.selectedUserIds,
            formErrors:FormErrorsTemp
        })

        console.log(this.state.selectedUserIds);
    }

    removeSelectionUsers = (args) => {
        if(!isEmptyVariable(args.itemData.userId)){
            let idx = this.state.selectedUserIds.indexOf(args.itemData.userId);
            if(idx !== -1){
                this.state.selectedUserIds.splice(idx,1);
            }
        }
        this.setState({
            selectedUserIds:this.state.selectedUserIds
        })
        console.log(this.state.selectedUserIds);
    }

    addSelectionClients = (args) => {
        let FormErrorsTemp  = this.state.formErrors;
        FormErrorsTemp['selectedClients'] = "";

        if(!isEmptyVariable(args.itemData.companyId))
        {
            this.state.selectedClientIds.push(args.itemData.companyId)
        }
        this.setState({
            selectedClientIds:this.state.selectedClientIds,
            formErrors:FormErrorsTemp
        })

        console.log(this.state.selectedClientIds);
    }

    removeSelectionClients = (args) => {
        if(!isEmptyVariable(args.itemData.companyId)){
            let idx = this.state.selectedClientIds.indexOf(args.itemData.companyId);
            if(idx !== -1){
                this.state.selectedClientIds.splice(idx,1);
            }
        }
        this.setState({
            selectedClientIds:this.state.selectedClientIds
        })
        console.log(this.state.selectedClientIds);
    }

    handleDetailsDialogClose = () =>{
        this.setState({
            showDetailsDialog:false,
            selectedItem:"",
        });
    }

    handleDetailsDialogShow = (item) => {
        this.setState({
            showDetailsDialog:true,
            selectedItem:item,
        });
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getManagersList();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getManagersList();
        })
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.userId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete manager "+dropdownItem.firstName+" "+dropdownItem.lastName+"?"
            })
        }else if(item.label === "Edit"){
            this.getDeptUsersListEdit(dropdownItem);
        }
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getManagersList();
        })
    }

    getInitialData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetManagerUsers,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir
                })
            }),
            fetch(Constants.GetUsers,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:1,
                    resultsize:500,
                    status:"Active",
                })
            }),
            fetch(Constants.GetCompanies,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:1,
                    resultsize:500,
                    status:"Active",
                })
            })
        ])
        .then(([managerListRes,userListRes,companyRes]) => { 
            return Promise.all([managerListRes.json(),userListRes.json(),companyRes.json()]) 
        })
        .then(([managerListRes,userListRes,companyRes]) => { 
            let managerList = [];
            let userList = [];
            let companyList = [];
            let totalCount = 0;

            if(managerListRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                managerListRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(managerListRes.responseCode === Constants.CODE_SUCCESS){
                managerList = managerListRes.data.result;
                totalCount = managerListRes.data.count;
            }else{
                //TODO - show error message inside dialog if possible
            }

            if(userListRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                userListRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(userListRes.responseCode === Constants.CODE_SUCCESS){
                let userListTemp = userListRes.data.result;

                userList = userListTemp.map(item=>{
                    let deptStr = SplitJoinString(item.departmentList,",",":-:",", ")
                    item.displayName = item.firstName+" "+item.lastName+", "+item.designation+", "+deptStr
                    return item;
                })
            }else{
                //TODO - show error message inside dialog if possible
            }

            if(companyRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                companyRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(companyRes.responseCode === Constants.CODE_SUCCESS){
                companyList = companyRes.data.result;
            }else{
                //TODO - show error message inside dialog if possible
            }

            this.setState({
                datalist:managerList,
                totalCount:totalCount,
                allUsers:userList,
                allCompanies:companyList,
                showLoader:false,
                componentDidMountFlag:true,
            })

        });
    }

    getManagersList = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetManagerUsers,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                status:this.state.status,
                sort:this.state.sort,
                sortDir:this.state.sortDir
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    showLoader:false,
                    apiSearchKey:this.state.searchkey,
                })
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    apiSearchKey:this.state.searchkey,
                })
            }
        })
    }

    getDeptUsersListEdit = (dropdownItem) => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetDeptUsers,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                userId:dropdownItem.userId,
                resultsize:500
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let managerTypeUsers = false;
                let managerTypeClients = false;
                let selectedUserIds = [];
                let selectedClientIds = [];

                if(!isEmptyVariable(dropdownItem.childUserNameList)){
                    managerTypeUsers = true;
                    let resArr = [];
                    let splitArr = dropdownItem.childUserNameList.split(",");
                    if(!isEmptyArray(splitArr)){
                        splitArr.map((item)=>{
                            resArr.push(parseInt(item.split(":-:")[1]));
                        })
                    }
                    selectedUserIds = resArr;
                }

                if(!isEmptyVariable(dropdownItem.companyList)){
                    managerTypeClients = true;
                    let resArr = [];
                    let splitArr = dropdownItem.companyList.split(",");
                    if(!isEmptyArray(splitArr)){
                        splitArr.map((item)=>{
                            resArr.push(parseInt(item.split(":-:")[1]));
                        })
                    }
                    selectedClientIds = resArr;
                }
                let deptEmpList = data.data.result;
                let userList = deptEmpList.map(item=>{
                    let deptStr = SplitJoinString(item.departmentList,",",":-:",", ")
                    item.displayName = item.firstName+" "+item.lastName+", "+item.designation+", "+deptStr
                    return item;
                })
                this.setState({
                    allDeptEmployees:userList,
                    showLoader:false,

                    showCreateDialog:true,
                    submitClickedAtleastOnce:false,
                    isEdit:true,
                    formErrors:{},
                    employeePlaceholder:dropdownItem.firstName+" "+dropdownItem.lastName,
                    selectedEmployeeId:dropdownItem.userId,
                    managerTypeUsers:managerTypeUsers,
                    managerTypeClients:managerTypeClients,
                    selectedUserIds:selectedUserIds,
                    selectedClientIds:selectedClientIds,
                })
            }else{
                this.setState({
                    allDeptEmployees:[],
                    showLoader:false,
                })
            }
        })
    }
    getDeptUsersList = (selectedUserId) => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetDeptUsers,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                userId:selectedUserId,
                resultsize:500
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let deptEmpList = data.data.result;
                let userList = deptEmpList.map(item=>{
                    let deptStr = SplitJoinString(item.departmentList,",",":-:",", ")
                    item.displayName = item.firstName+" "+item.lastName+", "+item.designation+", "+deptStr
                    return item;
                })
                this.setState({
                    allDeptEmployees:userList,
                    showLoader:false,
                })
            }else{
                this.setState({
                    allDeptEmployees:[],
                    showLoader:false,
                })
            }
        })
    }

    onChangePage = (page) => {
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            fetch(Constants.GetManagerUsers,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    })
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    })
                }
            })
        }
    }

    handleCreateManager = () => {
        this.setState({
            showLoader:true,
            submitClickedAtleastOnce:true,
            formErrors:{}
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.selectedEmployeeId)){
            isError = true;
            FormErrorsTemp['employee'] = "Please select employee";
        }

        if(!this.state.managerTypeUsers && !this.state.managerTypeClients){
            isError = true;
            FormErrorsTemp['type'] = "Please select manager type";
        }else{
            if(this.state.managerTypeUsers){
                if(isEmptyArray(this.state.selectedUserIds)){
                    isError = true;
                    FormErrorsTemp['selectedUsers'] = "Please select the users";
                }

                if(this.state.selectedUserIds.includes(this.state.selectedEmployeeId)){
                    isError = true;
                    FormErrorsTemp['selectedUsers'] = "Selected Employee cannot be in users list";
                }
            }

            if(this.state.managerTypeClients){
                if(isEmptyArray(this.state.selectedClientIds)){
                    isError = true;
                    FormErrorsTemp['selectedClients'] = "Please select the clients";
                }
            }
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });
        }else{
            let url = Constants.UpdateManagerUsersClients;
            let childUserIds = [];
            let companyIds = [];
            let postParams = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                userId:this.state.selectedEmployeeId,
            }

            if(this.state.managerTypeUsers){
                childUserIds = this.state.selectedUserIds;
            }

            if(this.state.managerTypeClients){
                companyIds = this.state.selectedClientIds;
            }

            postParams.childUserIds = JSON.stringify(childUserIds);
            postParams.companyIds = JSON.stringify(companyIds);

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParams)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        showCreateDialog:false,
                        submitClickedAtleastOnce:false,
                        isEdit:false,
                        selectedUserId:"",
                        employeePlaceholder:defaultEmpPlaceholder,
                        selectedEmployeeId:"",
                        managerType:"users",
                        selectedUserIds:[],
                        selectedClientIds:[],
                        formErrors:{},
                    },()=>{
                        this.getManagersList();
                    })
                }else{
                    this.setState({
                        showLoader:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    })
                }
            })
        }
    }

    deleteActivateManager = () => {
        let url = "";
        let postParam = {};

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteManagerUsersClients;
            postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                userId:this.state.deleteReactivateId
            }
        }

        this.setState({
            showBtnLoader:true,
        });

        fetch(url,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParam)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showBtnLoader:false,
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                },() => {
                    this.getManagersList();
                });
            }else{
                this.setState({
                    showBtnLoader:false,
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                    
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                })
            }
        });
    }

    render(){
        const actionArr = [
            {
                icon:"edit",
                label:"Edit"
            },
            {
                icon:"delete",
                label:"Delete"
            },
        ]

        let userFields = {text:"displayName",value:"userId"};
        let clientFields = {text:"companyName",value:"companyId"};
        
        return(
            <Router>
                <main className="main-dashboard">   
                    <section className="dashboard-bg">
                        <Sidebar />
                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                {
                                    this.state.showLoader &&
                                    <div class="loader"></div>
                                }
                                <UserManagementTopMenu 
                                    themeSettings = {themeSettings}
                                />
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    <div className="body-wrapper">
                                        <div className="search-addnew-bg">
                                            <div className="search-addnew">
                                                
                                                <div className="search-filter-layout">
                                                    <FilterDropDown
                                                        placeholder={this.state.resultSizePlaceholder}
                                                        dropdownArr={this.state.resultSizeArr}
                                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                                        themeSettings = {themeSettings}
                                                        mleft={0}
                                                    />
                                                    <div className="search-col m-l-10">
                                                        <input 
                                                            name="searchkey" 
                                                            type="text"
                                                            onChange={this.handleChangeSearch}
                                                            placeholder="Search" 
                                                            onKeyPress={this.onEnterBtnPress}
                                                            value={this.state.searchkey}
                                                        />
                                                        <SearchSpan 
                                                            className="material-icons"
                                                            themeSettings = {themeSettings}
                                                            onClick = {this.onSearchIconClick}>search</SearchSpan>
                                                    </div>

                                                    
                                                </div>

                                                <AddNewButtonLayout
                                                    themeSettings = {themeSettings}
                                                >
                                                    <a onClick={this.showCreateDialog} href="javascript:void(0);">
                                                        <span className="material-icons">add_circle</span>
                                                        <p>Add New</p>
                                                    </a>
                                                </AddNewButtonLayout>
                                            </div>
                                        </div>

                                        {
                                            !isEmptyArray(this.state.datalist) && 
                                            <div className="common-tab-bg">
                                                <div className="common-table" style={{width:"100%"}}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                            <th className="text-center" width={"5%"}>No.</th>
                                                            <th className="c-pointer" width={"15%"} onClick={this.sortTableLocal.bind(this,sortFirstName)}>
                                                                <div className="sort-header">Employee Name
                                                                <span className={(this.state.sort === sortFirstName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" width={"15%"} onClick={this.sortTableLocal.bind(this,sortDesignation)}>
                                                                <div className="sort-header">Designation
                                                                <span className={(this.state.sort === sortDesignation)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            {/* <th className="c-pointer" width={"5%"} onClick={this.sortTableLocal.bind(this,sortType)}>
                                                                <div className="sort-header">Type
                                                                <span className={(this.state.sort === sortType)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th> */}
                                                            <th >
                                                                <div className="sort-header">Users
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" width={"25%"}  onClick={this.sortTableLocal.bind(this,sortClient)}>
                                                                <div className="sort-header">Clients
                                                                <span className={(this.state.sort === sortClient)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th >
                                                                <div className="sort-header">Users/Clients
                                                                </div>
                                                            </th>
                                                            <th className="text-center" width={"5%"}></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            !isEmptyArray(this.state.datalist) && 
                                                            this.state.datalist.map((item,idx) => {
                                                                // let managerType = ""
                                                                // if(!isEmptyVariable(item.companyList) && !isEmptyVariable(item.childUserNameList)){
                                                                //     managerType = "Both"
                                                                // }else{
                                                                //     managerType = isEmptyVariable(item.companyList)
                                                                //                     ?
                                                                //                     isEmptyVariable(item.childUserNameList)?"":"Users"
                                                                //                     :
                                                                //                     "Clients"
                                                                // }

                                                                return <tr onClick={this.handleDetailsDialogShow.bind(this,item)}>
                                                                    <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                                    <td>{item.firstName + " " + item.lastName}</td>
                                                                    <td>{ifEmptyReturnEmptyStr(item.designation,"-")}</td>
                                                                    {/* <td>{managerType}</td> */}
                                                                    <td>{
                                                                        isEmptyVariable(item.childUserNameList)
                                                                        ?
                                                                        "-"
                                                                        :
                                                                        truncateString(SplitJoinString(item.childUserNameList,",",":-:",", "),Constants.MANAGERS_STRING_LENGTH)
                                                                    }</td>
                                                                    <td>{
                                                                        isEmptyVariable(item.companyList)
                                                                        ?
                                                                        "-"
                                                                        :
                                                                        truncateString(SplitJoinString(item.companyList,",",":-:",","),Constants.MANAGERS_STRING_LENGTH)
                                                                    }</td>
                                                                    <td>{
                                                                        (isEmptyVariable(item.childUserNameList)?"0":item.childCount)
                                                                        +"/"+
                                                                        (isEmptyVariable(item.companyList)?"0":item.companyCount)
                                                                    }</td>
                                                                    <td className="text-center">
                                                                        <TableDropDown 
                                                                            actionArr={actionArr}
                                                                            onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                            dropDownId = {item}
                                                                            themeSettings = {themeSettings}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                            <div className="no-items-layout">
                                                <div className="no-items-card">
                                                    <h6>
                                                    {
                                                        isEmptyVariable(this.state.apiSearchKey)?
                                                        Constants.NO_RECORDS_WARNING
                                                        :
                                                        Constants.EMPTY_SEARCH_WARNING
                                                    }
                                                    </h6>
                                                </div>
                                            </div>
                                        }
                                        <div className="pagination-layout">
                                            <div className="row custom-row">
                                                <div className="col-md-9 custom-col">
                                                {
                                                    (this.state.totalCount > this.state.datalist.length) && 
                                                    <Pagination 
                                                        totalLength ={this.state.totalCount} 
                                                        items={this.state.datalist} 
                                                        onChangePage={this.onChangePage} 
                                                        pageSize={this.state.resultSize}
                                                        currentPageNo = {this.state.currentPageNo}
                                                        initialPage={this.state.currentPageNo} 
                                                        themeSettings = {themeSettings}
                                                    />
                                                }
                                                </div>
                                                <div className="col-md-3 custom-col">
                                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <Modal enforceFocus={false} className="custom-dialog" 
                        show={this.state.showCreateDialog} backdrop="static">
                            <Modal.Header>
                                <h5>{this.state.isEdit?"Edit Manager":"Add New Manager"}</h5>
                                <button onClick={this.handleCreateDialogClose}>
                                    <span className="material-icons">close</span>
                                </button>
                            </Modal.Header>
                            <hr />

                            <div className="modal-body">
                                <div className="addnew-modal-form">
                                    <div className="row custom-row">
                                        
                                        <div className="col-md-12 custom-col">
                                            <div className="addnew-modal-form-group">
                                                <p>Employee*</p>
                                                <RegularDropdown 
                                                    placeholder={this.state.employeePlaceholder}
                                                    dropdownArr={this.state.allUsers}
                                                    labelParam="displayName"
                                                    onDropDownItemClick={this.employeeDropdownClick}
                                                    isFocusRequired = {true}
                                                    defaultPlaceholderDropDown={defaultEmpPlaceholder}
                                                />
                                                {
                                                    !isEmptyVariable(this.state.formErrors['employee']) &&
                                                    <span className="cm-error">{this.state.formErrors['employee']}</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row custom-row">
                                        <div className="col-md-6 custom-col">
                                            <div className="addnew-modal-form-group">
                                                <p>Manager Type*</p>
                                                <div className="radio-group">
                                                    <TreeNodeCheckBox
                                                    ml={"0px"}
                                                    themeSettings={themeSettings}>
                                                        <input 
                                                            type="checkbox"
                                                            name="managerTypeUsers"
                                                            id={"managerTypeUsers"}
                                                            onChange={this.handleSelection}
                                                            checked={this.state.managerTypeUsers}
                                                        />
                                                        <label for="managerTypeUsers"
                                                        style={{marginLeft:0}}>Users</label>
                                                    </TreeNodeCheckBox>
                                                    <TreeNodeCheckBox
                                                    themeSettings={themeSettings}>
                                                        <input 
                                                            type="checkbox"
                                                            name="managerTypeClients"
                                                            id={"managerTypeClients"}
                                                            onChange={this.handleSelection}
                                                            checked={this.state.managerTypeClients}
                                                        />
                                                        <label for="managerTypeClients"
                                                        style={{marginLeft:6}}>Clients</label>
                                                    </TreeNodeCheckBox>
                                                </div>
                                                {
                                                    !isEmptyVariable(this.state.formErrors['type']) &&
                                                    <span className="cm-error">{this.state.formErrors['type']}</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.managerTypeUsers &&
                                        <div className="row custom-row">
                                            <div className="col-md-12 custom-col">
                                                <div className="addnew-modal-form-group">
                                                    <p>Users</p>
                                                    <MultiSelectComponent 
                                                        id="usersCheckbox"
                                                        dataSource={this.state.allDeptEmployees}
                                                        fields={userFields}
                                                        placeholder={defaultUserPlaceholder} 
                                                        mode="CheckBox"
                                                        closePopupOnSelect={false}
                                                        allowFiltering={false}
                                                        select={this.addSelectionUsers}
                                                        removed={this.removeSelectionUsers}
                                                        cssClass="clientsChecklist dialogcl" 
                                                        value={this.state.selectedUserIds}
                                                        showDropDownIcon={true}
                                                        showClearButton={false}
                                                    >
                                                        <Inject services={[CheckBoxSelection]} />
                                                    </MultiSelectComponent>
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['selectedUsers']) &&
                                                        <span className="cm-error">{this.state.formErrors['selectedUsers']}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.managerTypeClients &&
                                        <div className="row custom-row">
                                            <div className="col-md-12 custom-col">
                                                <div className="addnew-modal-form-group">
                                                    <p>Clients</p>
                                                    <MultiSelectComponent 
                                                        id="clientsCheckbox"
                                                        dataSource={this.state.allCompanies}
                                                        fields={clientFields}
                                                        placeholder={defaultClientPlaceholder} 
                                                        mode="CheckBox"
                                                        closePopupOnSelect={false}
                                                        allowFiltering={false}
                                                        select={this.addSelectionClients}
                                                        removed={this.removeSelectionClients}
                                                        cssClass="clientsChecklist dialogcl"
                                                        value={this.state.selectedClientIds}
                                                        showDropDownIcon={true}
                                                        showClearButton={false}
                                                    >
                                                        <Inject services={[CheckBoxSelection]} />
                                                    </MultiSelectComponent>
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['selectedClients']) &&
                                                        <span className="cm-error">{this.state.formErrors['selectedClients']}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {/* {
                                        !isEmptyVariable(this.state.formErrors['apiErr']) &&
                                        <span className="cm-error">{this.state.formErrors['apiErr']}</span>
                                    } */}
                                </div>
                                
                                <div className="modal-close-save">
                                    <div className="close-save-col">
                                        <button onClick={this.handleCreateDialogClose} type="button" className="modal-close">Cancel</button>
                                        <DialogSaveButton
                                        themeSettings = {themeSettings}
                                        onClick={this.handleCreateManager} type="button">Save</DialogSaveButton>
                                    </div>
                                </div>
                            </div>

                        </Modal>

                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogHeading={
                                (this.state.operationType === "Delete")
                                ?
                                "Delete Manager"
                                :
                                "Activate Manager"
                            }
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.deleteActivateManager}
                            proceedBtnLabel={
                                (this.state.operationType === "Delete")
                                ?
                                "Delete"
                                :
                                "Reactivate"
                            }
                            showLoader={this.state.showBtnLoader}
                            themeSettings = {themeSettings}
                        />

                         <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings = {themeSettings}
                        />
                        
                        <Modal className="custom-dialog" show={this.state.showDetailsDialog} onHide={this.handleDetailsDialogClose}>
                            <Modal.Header>
                                <h5>Details</h5>
                                <button 
                                    onClick={this.handleDetailsDialogClose}
                                    type="button" data-dismiss="modal">
                                    <span class="material-icons">close</span>
                                </button>
                            </Modal.Header>
                            <hr />
                            <div class="modal-body">
                                <div className="details-table-layout">
                                    <div className="row custom-row">
                                        <div className="col-md-12 custom-col">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td width={"24%"}>Employee Name</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.state.selectedItem.firstName+" "+ifEmptyReturn(this.state.selectedItem.middleName)+" "+ this.state.selectedItem.lastName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Designation</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{ifEmptyReturnEmptyStr(this.state.selectedItem.designation,"-")}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Type</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>
                                                        {

                                                            !isEmptyVariable(this.state.selectedItem.companyList) && 
                                                            !isEmptyVariable(this.state.selectedItem.childUserNameList)
                                                            ?
                                                            "Both"
                                                            :
                                                            isEmptyVariable(this.state.selectedItem.companyList)
                                                            ?
                                                            isEmptyVariable(this.state.selectedItem.childUserNameList)?"":"Users"
                                                            :
                                                            "Clients"
                                                        }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Users</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>
                                                        {
                                                            isEmptyVariable(this.state.selectedItem.childUserNameList)?"-":SplitJoinString(this.state.selectedItem.childUserNameList,",",":-:",", ")
                                                        }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Clients</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>
                                                        {
                                                            isEmptyVariable(this.state.selectedItem.companyList)
                                                            ?
                                                            "-"
                                                            :
                                                            SplitJoinString(this.state.selectedItem.companyList,",",":-:",",")
                                                        }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Users Count</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>
                                                        {
                                                            isEmptyVariable(this.state.selectedItem.childUserNameList)?"0":this.state.selectedItem.childCount
                                                        }
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Clients Count</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>
                                                        {
                                                            isEmptyVariable(this.state.selectedItem.companyList)
                                                            ?
                                                            "0"
                                                            :
                                                            this.state.selectedItem.companyCount
                                                        }
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </section>
                </main>
            </Router>
        )
    }
}

export default ListManagers;