import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable, 
    isEmptyArray,
    ifEmptyReturnEmptyStr} from '../../../common/Global/commonFunctions';
import {DialogSaveButton,TreeNodeCheckBox} from '../../../common/Global/globalStyles.style';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import AlertDialog from '../../../common/AlertDialog';
import ReactSelectDropdown from '../../../common/DropdownMenus/ReactSelectDropdownWithSearch';
import CommonAlertMessageDialog from '../../../common/AlertDialog/commonAlertMessage';

const selectedUserPlaceholder = "Select User";

const userDetails  = getLocalStorageVariables();
class AssignResources extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            datalist:[],
            allDataList:[],
            totalCount:0,
            currentPageNo: 1,
            isScheduledEdit:this.props.isEdit?false:true,
            reviewCycleReq:"Y",
            showAlertDeleteDialog1:false,
            showAlertDeleteDialog2:false,
            showAlertDialog:false,
            showBtnLoader:false,
            selectedL0Ids:[],
            selectedL1Ids:'',
            selectedL2Ids:'',
            selectedL3Ids:'',
            selectedL4Ids:'',
            selectedL5Ids:'',
            selectedViewIds:[],
            
            selectedL1User:"Select User",
            selectedL2User:"Select User",
            selectedL3User:"Select User",
            selectedL4User:"Select User",
            selectedL5User:"Select User",
            selectedL6User:"Select User",
            selectedL7User:"Select User",
            loading:false,
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        }
    }
    

    componentDidMount(){
        if(this.props.isEdit){
            this.setState({
                componentDidMountFlag:false,
                showLoader:false,
                datalist:[],
                totalCount:0,
                currentPageNo: 1,

                selectedL0Ids:[],
                selectedL1Ids:'',
                selectedL2Ids:'',
                selectedL3Ids:'',
                selectedL4Ids:'',
                selectedL5Ids:'',
                selectedViewIds:[],
                
                selectedL1User:"Select User",
                selectedL2User:"Select User",
                selectedL3User:"Select User",
                selectedL4User:"Select User",
                selectedL5User:"Select User",
                selectedL6User:"Select User",
                selectedL7User:"Select User",
                reviewCycleReq:ifEmptyReturnEmptyStr(this.props.reviewAssignmentTask,"Y")
            },()=>{
                this.getEditInitData();
            })
        }else{
            this.setState({
                componentDidMountFlag:true,
                showLoader:false,
                datalist:[],
                totalCount:0,
                currentPageNo: 1,

                selectedL0Ids:[],
                selectedViewIds:[],
                
                selectedL1User:"Select User",
                selectedL2User:"Select User",
                selectedL3User:"Select User",
                selectedL4User:"Select User",
                selectedL5User:"Select User",
                selectedL6User:"Select User",
                selectedL7User:"Select User",
                reviewCycleReq:ifEmptyReturnEmptyStr(this.props.reviewAssignmentTask,"Y")
            },()=>{
                this.getInitData();
            })
        }
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    getEditInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetAssignedResources,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId:this.props.assignmentId,
                })
            }),
            fetch(Constants.AssignResourceMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId:this.props.assignmentId,
                })
            })
        ])
        .then(([assignedRes,masterRes]) => { 
            return Promise.all([assignedRes.json(),masterRes.json()]) 
        })
        .then(([assignedRes,masterRes]) => {
            let datalist =[];
            let assignmentReviewMatrix =[];
            let assignmentMemberMatrix =[];
            let assignmentViewMatrix =[];

            if(assignedRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignedRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(assignedRes.responseCode === Constants.CODE_SUCCESS){
                assignmentMemberMatrix = assignedRes.data.assignmentMemberMatrix;
                assignmentReviewMatrix = assignedRes.data.assignmentReviewMatrix;
                assignmentViewMatrix = assignedRes.data.assignmentViewMatrix;
            }else{
            }

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                datalist = masterRes.data.users;
            }else{
            }

            //Lets modify the datalist
            let selectedL0Ids = assignmentMemberMatrix.map(resItem => {
                return parseInt(resItem.userId);
            }) 

            let datalistNew = datalist.map(item=>{
                let itemNew = item;
                for (const [index, value] of assignmentMemberMatrix.entries()) {
                    if(value.userId+"" === item.userId+""){
                        itemNew.isSelected = true;
                        itemNew.position = "TM";
                    }
                }
                return itemNew;
            })
            //Lets modify the datalist
            let selectedViewIds = assignmentViewMatrix.map(resItem => {
                return parseInt(resItem.userId);
            }) 
            let datalistNew2 = datalistNew.map(item=>{
                let itemNew = item;
                for (const [index, value] of assignmentViewMatrix.entries()) {
                    if(value.userId+"" === item.userId+""){
                        itemNew.isSelected = true;
                        itemNew.position = "View";
                    }
                }
                return itemNew;
            })

            let selectedReviewUsers = {}

            let finalDataList = [];

            if(isEmptyArray(assignmentReviewMatrix)){
                finalDataList = datalistNew2;
            }else{
                for(const [index,value] of assignmentReviewMatrix.entries()){
                    selectedReviewUsers["selectedL"+value.level+"Ids"] = value.userId
                    selectedReviewUsers["selectedL"+value.level+"User"] = value.fullName;
                    finalDataList = this.changeMainArray(datalistNew2,true,"L"+value.level,value.userId)
                }
            }
            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                datalist:finalDataList,
                allDataList:[...finalDataList],
                selectedL0Ids:selectedL0Ids,
                selectedViewIds:selectedViewIds,
                ...selectedReviewUsers
            })
        })
    }

    getInitData = () => {
        this.setState({
            showLoader:true,
        });
        fetch(Constants.AssignResourceMasterData,
        {
            method:"POST",
            mode:"cors",
            body: new URLSearchParams(
            {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                assignmentId:this.props.assignmentId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    datalist:data.data.users,
                    allDataList:[...data.data.users],
                    showLoader:false,
                    componentDidMountFlag:true
                })
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    componentDidMountFlag:true
                })
            }
        });
    }

    handleSelectionReviewCycle = (e) => {
        let isChecked = e.target.checked;

        this.setState({
            reviewCycleReq:isChecked?"Y":"N",
        },()=>{
            //TODO API CALL - updatereviewassignmenttask

            fetch(Constants.UpdateReviewAssignmentTask,
                {
                    method: "POST",
                    mode:'cors',
                    body: new URLSearchParams({
                        email:userDetails.email,
                        accessToken:userDetails.accessToken,
                        reviewAssignmentTask:this.state.reviewCycleReq,
                        assignmentId:this.props.assignmentId
                    })
                })
                .then(response => { return response.json(); } )
                .then(data =>
                {
                    if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                        localStorage.clear();
                        window.location="/";
                    }else if(data.responseCode === Constants.CODE_SUCCESS){
                    }else{
                        this.setState({
                            showAlertDialogInfo:true,
                            alertDialogMessageInfo:data.responseMessage
                        })
                    }
                });
        })
    }
    deleteAlertBoxDisplay1 =() => {
        this.setState({
            showAlertDeleteDialog1:true,
            showAlertDeleteDialog2:false
        })
    }

    deleteAlertBoxDisplay2 =() => {
        this.setState({
            showAlertDeleteDialog1:true
        })
    }
    handleDeleteBox1 = () =>{
        this.setState({
            showAlertDeleteDialog2:true,
            showAlertDeleteDialog1:false
        })
    }
    handleDeleteBox2 = () =>{
        this.saveResourece()
        this.setState({
            showAlertDeleteDialog2:false,
            showAlertDeleteDialog1:false
        })
    }
    handleAlertDialogClose2 = () =>{
        this.setState({
            showAlertDeleteDialog2:false,
            showAlertDeleteDialog1:false
        })
    }
    handleAlertDialogClose1 = () =>{
        this.setState({
            showAlertDeleteDialog2:false,
            showAlertDeleteDialog1:false
        })
    }

    handleAlertDialogClose = () => {
        this.setState({
            showAlertDialog: false
        })
    }
    
    handleAlertDialogNoButtonClick = () => {
        this.handleAssignResources();
    }

    handleAlertDialogYesButtonClick = () => {
        this.setState({
            isAssignAllTask: "Y",
            loading:true
        }, () => {
            this.handleAssignResources(this.state.isAssignAllTask)
        })
    }

    saveResourece = () =>{
        let assignmentReviewMatrix = {};
        let assignmentMemberMatrix = [];
        let assignmentViewMatrix = [];
        let atLeastOneUserSelected  = false;

        this.state.datalist.forEach(item=>{
            if(item.isSelected && item.position === "TM"){
                atLeastOneUserSelected  = true;
                let obj = {
                    userId:item.userId,
                    userName:item.firstName+" "+item.lastName,
                    designation:item.designation
                }
                assignmentMemberMatrix.push(obj);
            }else if(item.isSelected && item.position === "View"){
                atLeastOneUserSelected  = true;
                let obj = {
                    userId:item.userId,
                    userName:item.firstName+" "+item.lastName,
                    designation:item.designation
                }
                assignmentViewMatrix.push(obj);
            }else if(item.isSelected){
                atLeastOneUserSelected  = true;
                let obj = {
                    userId:item.userId,
                    userName:item.firstName+" "+item.lastName,
                    designation:item.designation
                }
                assignmentReviewMatrix[item.position] = obj;
            }
        })
        const totalSelectedUser = Object.entries(assignmentReviewMatrix).length + assignmentMemberMatrix.length;
        if(!atLeastOneUserSelected){
            this.setState({
                showAlertDialogInfo:true,
                alertDialogMessageInfo:"Please select atleast one user"
            })
        }else if(this.props.selectedAssignmentStatus=="Scheduled" && totalSelectedUser == 1){
            this.setState({
                showAlertDialog:true,
                assignmentReviewMatrix: assignmentReviewMatrix,
                assignmentMemberMatrix: assignmentMemberMatrix,
                assignmentViewMatrix: assignmentViewMatrix,
            })
        }else{
            this.setState({
                showBtnLoader:true
            })
            fetch(Constants.AssignResources, {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId:this.props.assignmentId,
                    assignmentReviewMatrix:JSON.stringify(assignmentReviewMatrix),
                    assignmentMemberMatrix:JSON.stringify(assignmentMemberMatrix),
                    assignmentViewMatrix:JSON.stringify(assignmentViewMatrix),
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        isScheduledEdit:false,
                        showBtnLoader:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:this.props.isEdit?
                        "Resources updated successfully":"Resources added successfully"
                    })
                }else{
                    this.setState({
                        showBtnLoader:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    })
                }
            });
        }
    }

    handleAssignResources = (isAssignAllTask) => {
        this.setState({
            showBtnLoader:true
        })
        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            assignmentId:this.props.assignmentId,
            assignmentReviewMatrix:JSON.stringify(this.state.assignmentReviewMatrix),
            assignmentMemberMatrix:JSON.stringify(this.state.assignmentMemberMatrix),
            assignmentViewMatrix:JSON.stringify(this.state.assignmentViewMatrix),
        }
        if(isAssignAllTask){
            postParam.isAssignAllTask = "Y"
        }
        fetch(Constants.AssignResources, {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParam)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    isScheduledEdit:false,
                    showAlertDialog:false,
                    showBtnLoader:false,
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:this.props.isEdit?
                    "Resources updated successfully":"Resources added successfully"
                })
            }else{
                this.setState({
                    showBtnLoader:false,
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage
                })
            }
        });
    }

    handleCreateMaster = () => {
        if(this.props.isEdit && this.props.selectedAssignmentStatus=="Scheduled"){
            this.deleteAlertBoxDisplay1()
        }else{
            this.saveResourece();
        }
    }

    teamMemberSelection = (selectedItem) => {
    
        let selectedL0Ids =this.state.selectedL0Ids;
        let datalist  = [];

        for (const item of selectedL0Ids){
            if(!selectedItem.some(e=>e.userId==item)){
                selectedL0Ids=selectedL0Ids.filter(e=>e!==item)
                datalist=this.changeMainArray(this.state.datalist,false,"",item)
            }
        }
        for (const item of selectedItem){
            if(!selectedL0Ids.includes(item.userId)){
                selectedL0Ids.push(item.userId)
                datalist=this.changeMainArray(this.state.datalist,true,"TM",item.userId)
            }
        }

        this.setState({
            selectedL0Ids:selectedL0Ids,
            datalist:datalist,
        })
    }
    viewMemberSelection = (selectedItem) => {
        let selectedViewIds =this.state.selectedViewIds;
        let datalist  = [];
        for (const item of selectedViewIds){
            if(!selectedItem.some(e=>e.userId==item)){
                selectedViewIds=selectedViewIds.filter(e=>e!==item)
                datalist=this.changeMainArray(this.state.datalist,false,"",item)
            }
        }
        for (const item of selectedItem){
            if(!selectedViewIds.includes(item.userId)){
                selectedViewIds.push(item.userId)
                datalist=this.changeMainArray(this.state.datalist,true,"View",item.userId)
            }
        }
        this.setState({
            selectedViewIds:selectedViewIds,
            datalist:datalist,
        })
    }

    changeMainArray = (arr, flag, position,targetUserId) => {
        let newArr = arr.map((item)=>{
            if(item.userId === targetUserId){
                item.isSelected = flag;
                item.position = position;
            }else{
                if(item.position !== "" &&
                item.position === position &&
                item.position !== "View" &&
                item.position !== "TM"){
                    item.isSelected = !flag;
                    item.position = "";
                }
            }
            return item;
        })
        return newArr;
    }

    l1DropdownClick = (item) => {
        
        let datalist = [];
        let selectedL1Ids = ''
        if(!isEmptyVariable(item)){
            datalist=this.changeMainArray(this.state.datalist,true,"L1",item.userId)
            selectedL1Ids = item.userId
        }else{
            datalist=this.changeMainArray(this.state.datalist,true,"L1",'')
        }
        this.setState({
            datalist:datalist,
            selectedL1User:item?item.fullName:'',
            selectedL1Ids:selectedL1Ids
        })
    }

    l2DropdownClick = (item) => {
        let datalist = [];
        let selectedL2Ids = ''
        if(!isEmptyVariable(item)){
            datalist=this.changeMainArray(this.state.datalist,true,"L2",item.userId)
            selectedL2Ids = item.userId
        }else{
            datalist=this.changeMainArray(this.state.datalist,true,"L2",'')
        }
        this.setState({
            datalist:datalist,
            selectedL2User:item?item.fullName:'',
            selectedL2Ids:selectedL2Ids
        })
    }

    l3DropdownClick = (item) => {
        let datalist = [];
        let selectedL3Ids = ''
        if(!isEmptyVariable(item)){
            datalist = this.changeMainArray(this.state.datalist,true,"L3",item.userId)
            selectedL3Ids = item.userId
        }else{
            datalist=this.changeMainArray(this.state.datalist,true,"L3",'')
        }
        this.setState({
            datalist:datalist,
            selectedL3User:item?item.fullName:'',
            selectedL3Ids:selectedL3Ids
        })
    }

    l4DropdownClick = (item) => {
        let datalist = [];
        let selectedL4Ids = ''
        if(!isEmptyVariable(item)){
            datalist = this.changeMainArray(this.state.datalist,true,"L4",item.userId)
            selectedL4Ids = item.userId
        }else{
            datalist=this.changeMainArray(this.state.datalist,true,"L4",'')
        }
        this.setState({
            datalist:datalist,
            selectedL4User:item?item.fullName:'',
            selectedL4Ids:selectedL4Ids

        })
    }

    l5DropdownClick = (item) => {
        let datalist = [];
        let selectedL5Ids = ''
        if(!isEmptyVariable(item)){
            datalist = this.changeMainArray(this.state.datalist,true,"L5",item.userId)
            selectedL5Ids = item.userId
        }else{
            datalist=this.changeMainArray(this.state.datalist,true,"L5",'')
        }
        this.setState({
            datalist:datalist,
            selectedL5User:item?item.fullName:'',
            selectedL5Ids:selectedL5Ids

        })
    }

    l6DropdownClick = (item) => {
        let datalist = this.changeMainArray(this.state.datalist,true,"L6",item.userId)
        this.setState({
            datalist:datalist,
            selectedL6User:item.fullName,
            selectedL6Ids:item.userId

        })
    }

    l7DropdownClick = (item) => {
        let datalist = this.changeMainArray(this.state.datalist,true,"L7",item.userId)
        this.setState({
            datalist:datalist,
            selectedL7User:item.fullName,
            selectedL7Ids:item.userId
        })
    }

    getL0Users = () => {
        let datalist = this.state.datalist;
        //filter
        let filteredArr = datalist.filter(item => item.isSelected !== true);
        return filteredArr;
    }

    getOtherUsers = (position) => {
        let datalist = this.state.datalist;
        //filter
        let filteredArr = datalist.filter(item => item.isSelected !== true 
            || (item.isSelected === true && item.position === position));
        return filteredArr;
    }

    displayReviewCycleCheckBox = () =>{
        if(this.props.selectedAssignmentStatus=='Scheduled' && this.props.isEdit){
            return this.state.isScheduledEdit
        }else{
            return this.props.showReviewCycleCheckbox
        }
    }

    handleEditOnClick = ()=>{
        this.setState({
            isScheduledEdit:true
        })
    }
    render() {
        let fields = {text:"fullName",value:"userId"}

        let TeamMembersList  = this.state.datalist.filter(item=>item.isSelected !== true
            || (item.isSelected === true && item.position === "TM"))

        let ViewList  = this.state.datalist.filter(item=>item.isSelected !== true
            || (item.isSelected === true && item.position === "View"))

        let L1List  = this.getOtherUsers("L1");
        let L2List  = this.getOtherUsers("L2");
        let L3List  = this.getOtherUsers("L3");
        let L4List  = this.getOtherUsers("L4");
        let L5List  = this.getOtherUsers("L5");
        let L6List  = this.getOtherUsers("L6");
        let L7List  = this.getOtherUsers("L7");
        

        return(
            
            <div className="modal-body">
                {
                    this.state.showLoader &&
                    <div class="loader mt-4"></div>
                }
                
                {
                    this.state.componentDidMountFlag &&
                    <div class="addnew-modal-form">
                        <div className="row custom-row">
                            <div className="col-md-6 custom-col">
                                <div className="addnew-modal-form-group">
                                    <p>Team Members</p>
                                    <ReactSelectDropdown
                                        handleReactSelectClose={this.teamMemberSelection}
                                        selectedDDObj= {this.state.allDataList.filter(e=>this.state.selectedL0Ids.includes(e.userId))}
                                        selectDropdownArr={TeamMembersList}
                                        label={"fullName"}
                                        value={"userId"}
                                        isMultiselect = {true}
                                        disabled ={!this.state.isScheduledEdit}
                                    />
                                </div>
                            </div>
                            <div className="col-md-6 custom-col">
                                <div class="addnew-modal-form-group">
                                    <p>Team Leader</p>
                                    <ReactSelectDropdown
                                        handleReactSelectClose={this.l1DropdownClick}
                                        selectedDDObj= {this.state.allDataList.filter(e=>this.state.selectedL1Ids==e.userId)}
                                        selectDropdownArr={L1List}
                                        label={"fullName"}
                                        value={"userId"}
                                        placeholder={this.state.selectedL1User}
                                        isClearSelect={true}
                                        disabled ={!this.state.isScheduledEdit}

                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row custom-row">
                            <div className="col-md-6 custom-col">
                                <div class="addnew-modal-form-group">
                                    <p>Review L1</p>
                                    <ReactSelectDropdown
                                        handleReactSelectClose={this.l2DropdownClick}
                                        selectedDDObj= {this.state.allDataList.filter(e=>this.state.selectedL2Ids==e.userId)}
                                        selectDropdownArr={L2List}
                                        label={"fullName"}
                                        value={"userId"}
                                        placeholder={this.state.selectedL2User}
                                        isClearSelect={true}
                                        disabled ={!this.state.isScheduledEdit}
                                    />
                                </div>

                            </div>
                            <div className="col-md-6 custom-col">
                                <div class="addnew-modal-form-group">
                                    <p>Review L2</p>
                                    <ReactSelectDropdown
                                        handleReactSelectClose={this.l3DropdownClick}
                                        selectedDDObj= {this.state.allDataList.filter(e=>this.state.selectedL3Ids==e.userId)}
                                        selectDropdownArr={L3List}
                                        label={"fullName"}
                                        value={"userId"}
                                        placeholder={this.state.selectedL3User}
                                        isClearSelect={true}
                                        disabled ={!this.state.isScheduledEdit}

                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div className="row custom-row">
                            <div className="col-md-6 custom-col">
                                <div class="addnew-modal-form-group">
                                    <p>Review L3</p>
                                    <ReactSelectDropdown
                                        handleReactSelectClose={this.l4DropdownClick}
                                        selectedDDObj= {this.state.allDataList.filter(e=>this.state.selectedL4Ids==e.userId)}
                                        selectDropdownArr={L4List}
                                        label={"fullName"}
                                        value={"userId"}
                                        placeholder={this.state.selectedL4User}
                                        isClearSelect={true}
                                        disabled ={!this.state.isScheduledEdit}

                                    />
                                </div>
                            </div>
                            <div className="col-md-6 custom-col">
                                <div class="addnew-modal-form-group">
                                    <p>Review L4</p>
                                    <ReactSelectDropdown
                                        handleReactSelectClose={this.l5DropdownClick}
                                        selectedDDObj= {this.state.allDataList.filter(e=>this.state.selectedL5Ids==e.userId)}
                                        selectDropdownArr={L5List}
                                        label={"fullName"}
                                        value={"userId"}
                                        placeholder={this.state.selectedL5User}
                                        isClearSelect={true}
                                        disabled ={!this.state.isScheduledEdit}

                                    />
                                </div>
                            </div>
                        </div>
                        
                        <div className="addnew-modal-form-group">
                            <p>View</p>
                             <ReactSelectDropdown
                                        handleReactSelectClose={this.viewMemberSelection}
                                        selectedDDObj= {this.state.allDataList.filter(e=>this.state.selectedViewIds.includes(e.userId))}
                                        selectDropdownArr={ViewList}
                                        label={"fullName"}
                                        value={"userId"}
                                        isMultiselect = {true}
                                        disabled ={!this.state.isScheduledEdit}

                                    />
                        </div>
                        {
                           this.displayReviewCycleCheckBox() &&
                            <TreeNodeCheckBox
                            style={{marginLeft:0,marginBottom:15}}
                            themeSettings={this.props.themeSettings}>
                                <input 
                                    type="checkbox"
                                    name="isReviewReq"
                                    id="isReviewReq"
                                    onChange={this.handleSelectionReviewCycle}
                                    checked={this.state.reviewCycleReq === "Y"}
                                    disabled = {this.props.selectedAssignmentStatus !== "Scheduled"}
                                />
                                <label for="isReviewReq"
                                style={{marginLeft:6}}>Review Cycle is required for assignment tasks</label>
                            </TreeNodeCheckBox>
                        }

                        <div class="modal-close-save">
                            <div class="close-save-col">
                                <button onClick={this.props.handleAllResourcesDialogClose} type="button" class="modal-close">Cancel</button>
                                <DialogSaveButton themeSettings={this.props.themeSettings} 
                                    onClick={(this.props.selectedAssignmentStatus=="Scheduled"&&!this.state.isScheduledEdit)?this.handleEditOnClick:this.handleCreateMaster } 
                                    type="button">
                                    {(this.props.selectedAssignmentStatus=="Scheduled"&&!this.state.isScheduledEdit)?"Edit":"Save"}
                                </DialogSaveButton>
                            </div>
                        </div>
                    </div>
                }
                <AlertDialog
                    showAlertDialog={this.state.showAlertDeleteDialog1}
                    handleAlertDialogClose={this.handleAlertDialogClose1}
                    type={Constants.ALERT_TYPE_WARNING}
                    // alertDialogHeading={"Remove Resource"}
                    alertDialogMessage={"Any assigned task to the removed resources will get deleted. You have to reassign the task."}
                    proceedBtnClick={this.handleDeleteBox1}
                    proceedBtnLabel={"Yes"}
                    themeSettings={this.props.themeSettings}
                />
                <AlertDialog
                    showAlertDialog={this.state.showAlertDeleteDialog2}
                    handleAlertDialogClose={this.handleAlertDialogClose2}
                    type={Constants.ALERT_TYPE_WARNING}
                    // alertDialogHeading={"Remove Resource"}
                    alertDialogMessage={"Are you sure you want to save the resources?"}
                    proceedBtnClick={this.handleDeleteBox2}
                    proceedBtnLabel={"Yes"}
                    showLoader={this.state.showBtnLoader}
                    themeSettings={this.props.themeSettings}
                />
                 
                <AlertDialog 
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type= {Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                    themeSettings={this.props.themeSettings}
                />

                <CommonAlertMessageDialog
                    showAlertDialog={this.state.showAlertDialog}
                    handleAlertDialogClose={this.handleAlertDialogClose}
                    alertDialogHeadingIcon={'error_outline'}
                    type={Constants.ALERT_TYPE_INFO}
                    alertDialogMessage={"You've chosen just one resource. Do you want to assign all tasks to this resource?"}
                    proceedBtnClick={this.handleAlertDialogYesButtonClick}
                    proceedBtnLabel={"Yes"}
                    loading={this.state.loading}
                    cancelBtnClick={this.handleAlertDialogNoButtonClick}
                    cancelBtnLabel={"No"}
                    showLoader={this.state.showBtnLoader}
                    themeSettings={this.props.themeSettings}
                />
            </div>
        );
    }
}

export default AssignResources;