import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import RegularDropdown from '../../common/DropdownMenus/RegularDropdown';
import AlertDialog from '../../common/AlertDialog';
import {getLocalStorageVariables,isEmptyVariable, ifEmptyReturnEmptyStr,isEmptyArray,
    getThemeSettingsVariables} from '../../common/Global/commonFunctions';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import {PageSaveButton,TreeNodeCheckBox} from '../../common/Global/globalStyles.style';

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();
const companyTypePlaceholder = "Select Engagement Type";
const subscriptionTypePlaceholder = "Select Subscription Type";
const salutationPlaceholderText = "Select";

class EditClient extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            showLoader:false,
            componentDidMountFlag:false,

            clientId:this.props.match.params.id,
            companyId:"",
            unitId:"",
            employee1Id:"",
            employee2Id:"",
            showForm:false,
            companyName:"",
            companyShortName:"",
            clientCode:"",
            address1:"",
            address2:"",
            city:"",
            pinCode:"",
            state:"",
            country:"",
            salutation1:salutationPlaceholderText,
            firstName1:"",
            middleName1:"",
            lastName1:"",
            phoneNo1:"",
            employeeEmail1:"",
            designation1:"",
            salutation2:salutationPlaceholderText,
            firstName2:"",
            middleName2:"",
            lastName2:"",
            phoneNo2:"",
            employeeEmail2:"",
            designation2:"",
            formErrors:{},
            pocId:"",
            pan:"",
            gst:"",

            auditTypes:[],
            selectedAuditTypes:[],
            companyTypeArr:[],
            companyType:companyTypePlaceholder,
            submitClickedAtleastOnce:false,

            subscriptionTypesArr:[],
            subscriptionType:subscriptionTypePlaceholder,

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",
        }
    }

    componentDidMount(){
        this.getInitialData();
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        let FormErrorsTemp = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            if(name === "companyName"){
                FormErrorsTemp['companyName'] = isEmptyVariable(value)?"Please enter company name":"";
            }
            else if(name === "companyShortName"){
                FormErrorsTemp['companyShortName'] = isEmptyVariable(value)?"Please enter company short name":"";
            }
            else if(name === "clientCode"){
                FormErrorsTemp['clientCode'] = isEmptyVariable(value)?"Please enter client code":"";
            }
            else if(name === "address1"){
                FormErrorsTemp['address1'] = isEmptyVariable(value)?"Please enter address":"";
            }
            else if(name === "city"){
                FormErrorsTemp['city'] = isEmptyVariable(value)?"Please enter city":"";
            }
            else if(name === "pinCode"){
                if(isEmptyVariable(value)){
                    FormErrorsTemp['pinCode'] = "Please enter pin code";
                }else{
                    var regexp = new RegExp(/^(?=.*[0-9])[ 0-9]+$/);
                    if(!regexp.test(value)) {
                        FormErrorsTemp['pinCode'] = "Please enter valid pin code";
                    }else{
                        FormErrorsTemp['pinCode'] = "";
                    }
                }
            }
            else if(name === "state"){
                FormErrorsTemp['state'] = isEmptyVariable(value)?"Please enter state":"";
            }
            else if(name === "country"){
                FormErrorsTemp['country'] = isEmptyVariable(value)?"Please enter country":"";
            }
            else if(name === "gst"){
                if(isEmptyVariable(value)){
                    FormErrorsTemp['gst'] = "";
                }else{
                    var regexp = new RegExp(/^[a-zA-Z0-9]*$/);
                    if(!regexp.test(value)) {
                        FormErrorsTemp['gst'] = "Please enter valid GST number";
                    }else{
                        FormErrorsTemp['gst'] = "";
                    }
                }
            }
            else if(name === "pan"){
                if(isEmptyVariable(value)){
                    FormErrorsTemp['pan'] = "";
                }else{
                    var regexp = new RegExp(/^[a-zA-Z0-9]*$/);
                    if(!regexp.test(value)) {
                        FormErrorsTemp['pan'] = "Please enter valid PAN";
                    }else{
                        FormErrorsTemp['pan'] = "";
                    }
                }
            }
            else if(name === "firstName1"){
                FormErrorsTemp['firstName1'] = isEmptyVariable(value)?"Please enter first name":"";
            }
            else if(name === "lastName1"){
                FormErrorsTemp['lastName1'] = isEmptyVariable(value)?"Please enter last name":"";
            }
            else if(name === "phoneNo1"){
                if(isEmptyVariable(value)){
                    FormErrorsTemp['phoneNo1'] = "";
                }else{
                    var regexp = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
                    if(!regexp.test(value)) {
                        FormErrorsTemp['phoneNo1'] = "Please enter valid phone number";
                    }else{
                        FormErrorsTemp['phoneNo1'] = "";
                    }
                }
            }
            else if(name === "mobileNo1"){
                if(isEmptyVariable(value)){
                    FormErrorsTemp['mobileNo1'] = "";
                }else{
                    var regexp = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
                    if(!regexp.test(value)) {
                        FormErrorsTemp['mobileNo1'] = "Please enter valid phone number";
                    }else{
                        FormErrorsTemp['mobileNo1'] = "";
                    }
                }
            }
            else if(name === "employeeEmail1"){
                if(isEmptyVariable(value)){
                    FormErrorsTemp['employeeEmail1'] = "Please enter email";
                }else{
                    var regexp = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
                    if(!regexp.test(value)) {
                        FormErrorsTemp['employeeEmail1'] = "Please enter valid email";
                    }else{
                        FormErrorsTemp['employeeEmail1'] = "";
                    }
                }
            }
            else if(name === "designation1"){
                FormErrorsTemp['designation1'] = isEmptyVariable(value)?"Please enter designation":"";
            }else{
            }
        }

        this.setState({ 
            [name]: value,
            formErrors:FormErrorsTemp
        });
    }

    typeDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['companyType'] = "";
        }

        this.setState({
            companyType:item.companyType,
            formErrors:FormErrorsTemp
        })
    }

    subTypeDropdownClick = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['subscriptionType'] = "";
        }

        this.setState({
            subscriptionType:item.subscriptionType,
            formErrors:FormErrorsTemp
        })
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    onCancel = () => {
        this.props.history.push(Constants.URL_CLIENTS);
    }

    salutionDropdownClick1 = (item) => {
        let FormErrorsTemp  = this.state.formErrors;

        if(this.state.submitClickedAtleastOnce){
            if(isEmptyVariable(item.name) || item.name === salutationPlaceholderText){
                FormErrorsTemp['salutation1'] = "Please select salutation";
            }else{
                FormErrorsTemp['salutation1'] = "";
            }
        }

        this.setState({
            salutation1:item.name,
            formErrors:FormErrorsTemp
        })
    }

    salutionDropdownClick2 = (item) => {
        this.setState({
            salutation2:item.name,
        })
    }

    handleSelectionAuditType = (e) => {
        let isChecked = e.target.checked;
        let auditTypeName = e.target.name;

        if(isChecked){
            let FormErrorsTemp  = this.state.formErrors;
            FormErrorsTemp["auditTypes"] = "";
            let temp = [...this.state.selectedAuditTypes]
            temp.push(auditTypeName);
            this.setState({
                selectedAuditTypes:temp,
                FormErrorsTemp:FormErrorsTemp
            })
        }else{
            let temp = this.state.selectedAuditTypes.filter(item=>item!==auditTypeName);
            this.setState({
                selectedAuditTypes:temp
            })
        }
    }
    /******************API CALLS ********************/
    getInitialData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetClientCreateMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            }),
            fetch(Constants.GetClientDetails,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    clientId:this.state.clientId
                })
            }),
        ])
        .then(([masterRes,clientRes]) => { 
            return Promise.all([masterRes.json(),clientRes.json()]) 
        })
        .then(([masterRes,clientRes]) => {
            let companyTypeArrTemp = [];
            let subscriptionTypesArrTemp = [];
            let auditTypes = [];
            let clientdataTemp = "";

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                    companyTypeArrTemp = masterRes.data.companyType;
                    subscriptionTypesArrTemp = masterRes.data.subscriptionTypes;
                    auditTypes = masterRes.data.auditTypes;
            }else{
                companyTypeArrTemp = [];
                subscriptionTypesArrTemp = [];
                auditTypes = [];
            }

            if(clientRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                clientRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(clientRes.responseCode === Constants.CODE_SUCCESS){
                clientdataTemp = clientRes.data.result;
            }else{
                clientdataTemp = "";
            }

            if(isEmptyVariable(clientdataTemp)){
                this.setState({
                    showForm:false,
                    showLoader:false,
                    componentDidMountFlag:true,
                })
            }else{
                let selectedAuditTypes = [];
                if(!isEmptyVariable(clientdataTemp.auditTypeList)){
                    selectedAuditTypes = clientdataTemp.auditTypeList.split(";;")
                }

                this.setState({
                    showForm:true,
                    showLoader:false,
                    componentDidMountFlag:true,

                    auditTypes:auditTypes,
                    companyTypeArr:companyTypeArrTemp,
                    subscriptionTypesArr:subscriptionTypesArrTemp,

                    companyName:clientdataTemp.companyName,
                    companyId:clientdataTemp.companyId,
                    unitId:clientdataTemp.unitId,
                    companyShortName:clientdataTemp.companyShortName,
                    clientCode:clientdataTemp.clientCode,
                    companyType:clientdataTemp.companyType,
                    address1:clientdataTemp.address1,
                    address2:clientdataTemp.address2,
                    city:clientdataTemp.city,
                    pinCode:clientdataTemp.pinCode,
                    state:clientdataTemp.state,
                    country:clientdataTemp.country,
                    gst:clientdataTemp.gst,
                    pan:clientdataTemp.pan,
                    subscriptionType:ifEmptyReturnEmptyStr(clientdataTemp.subscriptionType,subscriptionTypePlaceholder),
                    selectedAuditTypes:selectedAuditTypes,

                    pocId:clientdataTemp.pocId,
                    salutation1:ifEmptyReturnEmptyStr(clientdataTemp.salutation1,salutationPlaceholderText),
                    firstName1:clientdataTemp.firstName1,
                    middleName1:ifEmptyReturnEmptyStr(clientdataTemp.middleName1,""),
                    lastName1:clientdataTemp.lastName1,
                    phoneNo1:ifEmptyReturnEmptyStr(clientdataTemp.phoneNo1,""),
                    mobileNo1:ifEmptyReturnEmptyStr(clientdataTemp.mobileNo1,""),
                    employeeEmail1:clientdataTemp.email1,
                    designation1:clientdataTemp.designation1,

                    salutation2:ifEmptyReturnEmptyStr(clientdataTemp.salutation2,salutationPlaceholderText),
                    firstName2:ifEmptyReturnEmptyStr(clientdataTemp.firstName2,""),
                    middleName2:ifEmptyReturnEmptyStr(clientdataTemp.middleName2,""),
                    lastName2:ifEmptyReturnEmptyStr(clientdataTemp.lastName2,""),
                    phoneNo2:ifEmptyReturnEmptyStr(clientdataTemp.phoneNo2,""),
                    mobileNo2:ifEmptyReturnEmptyStr(clientdataTemp.mobileNo2,""),
                    employeeEmail2:ifEmptyReturnEmptyStr(clientdataTemp.email2,""),
                    designation2:ifEmptyReturnEmptyStr(clientdataTemp.designation2,""),
                })
            }
        });
    }

    addClient = () => {
        this.setState({
            showLoader:true,
            submitClickedAtleastOnce:true,
        });

        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.companyName)){
            isError = true;
            FormErrorsTemp['companyName'] = "Please enter company name";
        }

        if(isEmptyVariable(this.state.companyShortName)){
            isError = true;
            FormErrorsTemp['companyShortName'] = "Please enter company short name";
        }

        if(isEmptyVariable(this.state.clientCode)){
            isError = true;
            FormErrorsTemp['clientCode'] = "Please enter client code";
        }

        if(isEmptyVariable(this.state.companyType) || this.state.companyType === companyTypePlaceholder){
            isError = true;
            FormErrorsTemp['companyType'] = "Please select engagement type";
        }

        if(isEmptyVariable(this.state.address1)){
            isError = true;
            FormErrorsTemp['address1'] = "Please enter address";
        }

        if(isEmptyVariable(this.state.city)){
            isError = true;
            FormErrorsTemp['city'] = "Please enter city";
        }

        if(isEmptyVariable(this.state.pinCode)){
            isError = true;
            FormErrorsTemp['pinCode'] = "Please enter pin code";
        }

        if(!isEmptyVariable(this.state.pinCode)){
            var regexp = new RegExp(/^(?=.*[0-9])[ 0-9]+$/);
            if(!regexp.test(this.state.pinCode)) {
                isError = true;
                FormErrorsTemp['pinCode'] = "Please enter valid pin code";
            }
        }

        if(isEmptyVariable(this.state.state)){
            isError = true;
            FormErrorsTemp['state'] = "Please enter state";
        }

        if(isEmptyVariable(this.state.country)){
            isError = true;
            FormErrorsTemp['country'] = "Please enter country";
        }

        if(isEmptyVariable(this.state.gst)){
        }else{
            var regexp = new RegExp(/^[a-zA-Z0-9]*$/);
            if(!regexp.test(this.state.gst)) {
                isError = true;
                FormErrorsTemp['gst'] = "Please enter valid GST number";
            }
        }

        if(isEmptyVariable(this.state.pan)){
        }else{
            var regexp = new RegExp(/^[a-zA-Z0-9]*$/);
            if(!regexp.test(this.state.pan)) {
                isError = true;
                FormErrorsTemp['pan'] = "Please enter valid PAN";
            }
        }

        if(isEmptyVariable(this.state.subscriptionType) || this.state.subscriptionType === subscriptionTypePlaceholder){
            isError = true;
            FormErrorsTemp['subscriptionType'] = "Please select subscription type";
        }

        if(isEmptyArray(this.state.selectedAuditTypes)){
            isError = true;
            FormErrorsTemp['auditTypes'] = "Please select audit type";
        }

        if(isEmptyVariable(this.state.salutation1) || this.state.salutation1 === salutationPlaceholderText){
            isError = true;
            FormErrorsTemp['salutation1'] = "Please select salutation";
        }

        if(isEmptyVariable(this.state.firstName1)){
            isError = true;
            FormErrorsTemp['firstName1'] = "Please enter first name";
        }

        if(isEmptyVariable(this.state.lastName1)){
            isError = true;
            FormErrorsTemp['lastName1'] = "Please enter last name";
        }

        if(!isEmptyVariable(this.state.phoneNo1)){
            var regexp = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
            if(!regexp.test(this.state.phoneNo1)) {
                isError = true;
                FormErrorsTemp['phoneNo1'] = "Please enter valid phone number";
            }
        }

        if(!isEmptyVariable(this.state.mobileNo1)){
            var regexp = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
            if(!regexp.test(this.state.mobileNo1)) {
                isError = true;
                FormErrorsTemp['mobileNo1'] = "Please enter valid mobile number";
            }
        }

        if(isEmptyVariable(this.state.employeeEmail1)){
            isError = true;
            FormErrorsTemp['employeeEmail1'] = "Please enter email";
        }

        if(!isEmptyVariable(this.state.employeeEmail1)){
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if(!pattern.test(this.state.employeeEmail1)) {
                isError = true;
                FormErrorsTemp["employeeEmail1"] = "Please enter valid email";
            }
        }

        if(isEmptyVariable(this.state.designation1)){
            isError = true;
            FormErrorsTemp['designation1'] = "Please enter designation";
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });
        }else{
            let postParam = {
                clientId:this.state.clientId,
                companyId:this.state.companyId,
                unitId:this.state.unitId,
                companyName:this.state.companyName,
                companyShortName:this.state.companyShortName,
                clientCode:this.state.clientCode,
                address1:this.state.address1,
                address2:this.state.address2,
                city:this.state.city,
                pinCode:this.state.pinCode,
                state:this.state.state,
                country:this.state.country,
                gst:this.state.gst,
                pan:this.state.pan,
                pocId:this.state.pocId,

                salutation1:this.state.salutation1,
                firstName1:this.state.firstName1,
                middleName1:this.state.middleName1,
                lastName1:this.state.lastName1,
                phoneNo1:this.state.phoneNo1,
                mobileNo1:this.state.mobileNo1,
                email1:this.state.employeeEmail1,
                designation1:this.state.designation1,

                salutation2:this.state.salutation2,
                firstName2:this.state.firstName2,
                middleName2:this.state.middleName2,
                lastName2:this.state.lastName2,
                phoneNo2:this.state.phoneNo2,
                mobileNo2:this.state.mobileNo2,
                email2:this.state.employeeEmail2,
                designation2:this.state.designation2,

                companyType:this.state.companyType,
                subscriptionType:this.state.subscriptionType,
                auditTypes:JSON.stringify(this.state.selectedAuditTypes)
            }

            fetch(Constants.updateClient,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    data:JSON.stringify(postParam)
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                    },()=>{
                        this.props.history.push(Constants.URL_CLIENTS);
                    });
                }else{
                    this.setState({
                        showLoader:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    });
                }
            });
        }
    }

    render() {
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                {
                                    this.state.showLoader &&
                                    <div class="loader"></div>
                                }
                                {
                                this.state.showForm && this.state.componentDidMountFlag &&
                                <div className="clients-module-bg">
                                    <div className="clients-module-first-card card-col">
                                        <h6>Company Details</h6>
                                        <div className="row custom-row">
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>Company Name*</p>
                                                    <input 
                                                        type="text" 
                                                        placeholder="Enter Company Name" 
                                                        name="companyName"
                                                        onChange ={this.handleChange}
                                                        value={this.state.companyName}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['companyName']) &&
                                                        <span className="cm-error">{this.state.formErrors['companyName']}</span>
                                                    }
                                                    
                                                </div>
                                            </div>
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>Company Short Name*</p>
                                                    <input 
                                                        type="text" 
                                                        placeholder="Enter Company Short Name" 
                                                        name="companyShortName"
                                                        onChange ={this.handleChange}
                                                        value={this.state.companyShortName}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['companyShortName']) &&
                                                        <span className="cm-error">{this.state.formErrors['companyShortName']}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row custom-row">
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>Client Code*</p>
                                                    <input 
                                                        type="text" 
                                                        placeholder="Enter Client Code" 
                                                        name="clientCode"
                                                        onChange ={this.handleChange}
                                                        value={this.state.clientCode}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['clientCode']) &&
                                                        <span className="cm-error">{this.state.formErrors['clientCode']}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>Engagement Type*</p>
                                                    <RegularDropdown 
                                                        placeholder={this.state.companyType}
                                                        dropdownArr={this.state.companyTypeArr}
                                                        labelParam="companyType"
                                                        onDropDownItemClick={this.typeDropdownClick}
                                                        defaultPlaceholderDropDown={companyTypePlaceholder}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['companyType']) &&
                                                        <span className="cm-error">{this.state.formErrors['companyType']}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row custom-row">
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>Address Line 1*</p>
                                                    <textarea 
                                                        type="text" 
                                                        placeholder="Enter Address Line 1" 
                                                        name="address1"
                                                        onChange ={this.handleChange}
                                                        value={this.state.address1}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['address1']) &&
                                                        <span className="cm-error">{this.state.formErrors['address1']}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>Address Line 2</p>
                                                    <textarea
                                                        type="text" 
                                                        placeholder="Enter Address Line 2" 
                                                        name="address2"
                                                        onChange ={this.handleChange}
                                                        value={this.state.address2}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row custom-row">
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>City*</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter City" 
                                                        name="city"
                                                        onChange ={this.handleChange}
                                                        value={this.state.city}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['city']) &&
                                                        <span className="cm-error">{this.state.formErrors['city']}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>Pin Code*</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter Pin Code" 
                                                        name="pinCode"
                                                        onChange ={this.handleChange}
                                                        value={this.state.pinCode}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['pinCode']) &&
                                                        <span className="cm-error">{this.state.formErrors['pinCode']}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row custom-row">
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>State*</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter State" 
                                                        name="state"
                                                        onChange ={this.handleChange}
                                                        value={this.state.state}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['state']) &&
                                                        <span className="cm-error">{this.state.formErrors['state']}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>Country*</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter Country" 
                                                        name="country"
                                                        onChange ={this.handleChange}
                                                        value={this.state.country}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['country']) &&
                                                        <span className="cm-error">{this.state.formErrors['country']}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row custom-row">
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>GST</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter GST Number" 
                                                        name="gst"
                                                        onChange ={this.handleChange}
                                                        value={this.state.gst}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['gst']) &&
                                                        <span className="cm-error">{this.state.formErrors['gst']}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>PAN</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter PAN" 
                                                        name="pan"
                                                        onChange ={this.handleChange}
                                                        value={this.state.pan}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['pan']) &&
                                                        <span className="cm-error">{this.state.formErrors['pan']}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group  m-b-0">
                                                    <p>Subscription Type*</p>
                                                    <RegularDropdown 
                                                        placeholder={this.state.subscriptionType}
                                                        dropdownArr={this.state.subscriptionTypesArr}
                                                        labelParam="subscriptionType"
                                                        onDropDownItemClick={this.subTypeDropdownClick}
                                                        defaultPlaceholderDropDown={subscriptionTypePlaceholder}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['subscriptionType']) &&
                                                        <span className="cm-error">{this.state.formErrors['subscriptionType']}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group  m-b-0">
                                                    <p>Audit Type*</p>
                                                    <div className="flex-center-layout">
                                                    {
                                                        this.state.auditTypes.map(type => {
                                                            return <TreeNodeCheckBox
                                                                style={{padding:"7px 0px",marginRight:10,marginLeft:0}}
                                                                themeSettings={themeSettings}>
                                                                <input 
                                                                    type="checkbox"
                                                                    name={type.auditType}
                                                                    id={type.auditType}
                                                                    onChange={this.handleSelectionAuditType}
                                                                    checked={this.state.selectedAuditTypes.some(item=>item === type.auditType)}
                                                                />
                                                                <label for={type.auditType}
                                                                style={{marginLeft:6}}>{type.auditType}</label>
                                                            </TreeNodeCheckBox>
                                                        })
                                                    }
                                                    </div>
                                                    
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['auditTypes']) &&
                                                        <span className="cm-error">{this.state.formErrors['auditTypes']}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="clients-module-second-card card-col">
                                        <h6>Primary Contact</h6>
                                        <div className="row custom-row">
                                            <div className="col-md-3 custom-col">
                                                <div className="addnew-modal-form-group">
                                                    <p>Salutation*</p>
                                                    <RegularDropdown 
                                                        placeholder={this.state.salutation1}
                                                        dropdownArr={Constants.SALUTATION_ARR}
                                                        labelParam="name"
                                                        onDropDownItemClick={this.salutionDropdownClick1}
                                                        defaultPlaceholderDropDown={salutationPlaceholderText}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['salutation1']) &&
                                                        <span className="cm-error">{this.state.formErrors['salutation1']}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-3 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>First Name*</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter First Name" 
                                                        name="firstName1"
                                                        onChange ={this.handleChange}
                                                        value={this.state.firstName1}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['firstName1']) &&
                                                        <span className="cm-error">{this.state.formErrors['firstName1']}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-3 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>Middle Name</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter Middle Name" 
                                                        name="middleName1"
                                                        onChange ={this.handleChange}
                                                        value={this.state.middleName1}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['middleName1']) &&
                                                        <span className="cm-error">{this.state.formErrors['middleName1']}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-3 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>Last Name*</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter Last Name" 
                                                        name="lastName1"
                                                        onChange ={this.handleChange}
                                                        value={this.state.lastName1}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['lastName1']) &&
                                                        <span className="cm-error">{this.state.formErrors['lastName1']}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row custom-row">
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>Phone</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter Phone" 
                                                        name="phoneNo1"
                                                        onChange ={this.handleChange}
                                                        value={this.state.phoneNo1}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['phoneNo1']) &&
                                                        <span className="cm-error">{this.state.formErrors['phoneNo1']}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>Mobile</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter Mobile Number" 
                                                        name="mobileNo1"
                                                        onChange ={this.handleChange}
                                                        value={this.state.mobileNo1}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['mobileNo1']) &&
                                                        <span className="cm-error">{this.state.formErrors['mobileNo1']}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row custom-row">
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group m-b-0">
                                                    <p>Email*</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter Email" 
                                                        name="employeeEmail1"
                                                        onChange ={this.handleChange}
                                                        value={this.state.employeeEmail1}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['employeeEmail1']) &&
                                                        <span className="cm-error">{this.state.formErrors['employeeEmail1']}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group m-b-0">
                                                    <p>Designation*</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter Designation" 
                                                        name="designation1"
                                                        onChange ={this.handleChange}
                                                        value={this.state.designation1}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['designation1']) &&
                                                        <span className="cm-error">{this.state.formErrors['designation1']}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="clients-module-second-card card-col">
                                        <h6>Secondary Contact</h6>
                                        <div className="row custom-row">
                                            <div className="col-md-3 custom-col">
                                                <div className="addnew-modal-form-group">
                                                    <p>Salutation</p>
                                                    <RegularDropdown 
                                                        placeholder={this.state.salutation2}
                                                        dropdownArr={Constants.SALUTATION_ARR}
                                                        labelParam="name"
                                                        onDropDownItemClick={this.salutionDropdownClick2}
                                                        defaultPlaceholderDropDown={salutationPlaceholderText}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['salutation2']) &&
                                                        <span className="cm-error">{this.state.formErrors['salutation2']}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-3 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>First Name*</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter First Name" 
                                                        name="firstName2"
                                                        onChange ={this.handleChange}
                                                        value={this.state.firstName2}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['firstName2']) &&
                                                        <span className="cm-error">{this.state.formErrors['firstName2']}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-3 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>Middle Name</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter Middle Name" 
                                                        name="middleName2"
                                                        onChange ={this.handleChange}
                                                        value={this.state.middleName2}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['middleName2']) &&
                                                        <span className="cm-error">{this.state.formErrors['middleName2']}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-3 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>Last Name</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter Last Name" 
                                                        name="lastName2"
                                                        onChange ={this.handleChange}
                                                        value={this.state.lastName2}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['lastName2']) &&
                                                        <span className="cm-error">{this.state.formErrors['lastName2']}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row custom-row">
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>Phone</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter Phone" 
                                                        name="phoneNo2"
                                                        onChange ={this.handleChange}
                                                        value={this.state.phoneNo2}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['phoneNo2']) &&
                                                        <span className="cm-error">{this.state.formErrors['phoneNo2']}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>Mobile</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter Mobile Number" 
                                                        name="mobileNo2"
                                                        onChange ={this.handleChange}
                                                        value={this.state.mobileNo2}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['mobileNo2']) &&
                                                        <span className="cm-error">{this.state.formErrors['mobileNo2']}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row custom-row">
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group m-b-0">
                                                    <p>Email</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter Email" 
                                                        name="employeeEmail2"
                                                        onChange ={this.handleChange}
                                                        value={this.state.employeeEmail2}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['employeeEmail2']) &&
                                                        <span className="cm-error">{this.state.formErrors['employeeEmail2']}</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group m-b-0">
                                                    <p>Designation</p>
                                                    <input
                                                        type="text" 
                                                        placeholder="Enter Designation" 
                                                        name="designation2"
                                                        onChange ={this.handleChange}
                                                        value={this.state.designation2}
                                                    />
                                                    {
                                                        !isEmptyVariable(this.state.formErrors['designation2']) &&
                                                        <span className="cm-error">{this.state.formErrors['designation2']}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row custom-row">
                                            <div className="col-md-12 custom-col">
                                            <div className="add-new-form-group text-center m-b-0">
                                                <div className="common-close-save">
                                                    <div className="common-close-save-col">
                                                        <button onClick={this.onCancel} type="button" className="common-close">Cancel</button>
                                                        <PageSaveButton onClick={this.addClient} themeSettings={themeSettings} type="button" className="common-save">Save</PageSaveButton>
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }
                            </div>
                        </div>
                        <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings = {themeSettings}
                        />
                    </section>
                </main>
            </Router>
        );
    }
}

export default EditClient;