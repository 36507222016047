import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable, isEmptyArray} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import {DialogSaveButton} from '../../common/Global/globalStyles.style';
import ReactSelectDropdown from '../../common/DropdownMenus/ReactSelectDropdownWithSearch';
import { DatePicker } from 'antd';
import moment from 'moment';
import AlertDialog from '../../common/AlertDialog';

const userDetails  = getLocalStorageVariables();
const projectManagerPlaceholder = "Select Project Manager";
const unitPlaceholder = "Select Unit";
const employeePlaceholder = "Select Employees";

class AddProjectDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            selectedUnitObj:[],
            selectedEmployeeObj:[],
            projectName:"",
            projectCode:"",
            description:"",
            startDate:"",
            endDate:"",
            state:"",
            country:"",
            projectManagerId:"",
            projectManagerPlaceholder:projectManagerPlaceholder,
            unitPlaceholder:unitPlaceholder,
            employeePlaceholder:employeePlaceholder,
            formErrors:{},
            employeesArr:[],
            unitArr:[]
        }
        this.textInput = React.createRef();
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showAddProjectDialog){
            if(this.props.isEdit){
                let editProjectItem = this.props.editProjectItem;
                let startDate = !isEmptyVariable(editProjectItem.startDate)?moment(editProjectItem.startDate).format("YYYY/MM/DD"):"";
                let endDate = !isEmptyVariable(editProjectItem.endDate)?moment(editProjectItem.endDate).format("YYYY/MM/DD"):"";
                
                // Filter selected project manager
                let projectManager = this.props.employeesArr.find(employees => employees.employeeId === editProjectItem.projectManagerId) || [];

                // Create new units and employees arrays with additional properties
                let newUnits =[]
                Object.values(this.props.unitArr).map((item)=>{
                    let  tempItem = item
                    tempItem["unitNameAndCity"] = item.unitName+", "+item.city
                    newUnits.push(tempItem)
                })

                let newEmployees = []
                Object.values(this.props.employeesArr).map((item)=>{
                    let tempItem = item
                    tempItem["empAndDesignation"] = item.salutation+" "+item.firstName+" "+item.lastName+" ("+item.designation+")"
                    newEmployees.push(item)
                })
    
                // Find the selected units and employees
                let selectedUnitObj = [];
                if(!isEmptyVariable(editProjectItem.unitList)){
                    let splitArr = editProjectItem.unitList.split(";;");
                    if(!isEmptyArray(splitArr)){
                        splitArr.map((item)=>{
                            newUnits.map((unit)=>{
                                if(unit.unitId==parseInt(item.split(":-:")[1]))
                                {
                                    selectedUnitObj.push(unit)
                                }
                            })
    
                        })
                    }
                }

                let selectedEmployeeObj = [];
                if(!isEmptyVariable(editProjectItem.employeeList)){
                    let splitArr = editProjectItem.employeeList.split(";;");
                    if(!isEmptyArray(splitArr)){
                        splitArr.map(item => {
                            newEmployees.map(emp => {
                                if(emp.employeeId == parseInt(item.split(":-:")[1])){
                                    selectedEmployeeObj.push(emp)
                                }
                            })
                        })
                    }
                }
                // Update the employees array with the selected employees
                selectedEmployeeObj = selectedEmployeeObj.map(item => {
                    return parseInt(item.employeeId);
                }) 
                
                newEmployees = newEmployees.map(item=>{
                    let itemNew = item;
                    for (const [index, value] of selectedEmployeeObj.entries()) {
                        if(value+"" === item.employeeId+""){
                            itemNew.isSelected = true;
                            itemNew.position = "employee";
                        }
                    }
                    return itemNew;
                });

                // Update the state with the edited project's data
                let finalEmpList = [];
                if(isEmptyVariable(editProjectItem.projectManagerId)){
                    finalEmpList = newEmployees;
                }else{
                    finalEmpList = this.changeEmpArray(newEmployees,true,"projectManager",editProjectItem.projectManagerId)
                }
                this.setState({
                    formErrors:{},
                    unitArr:newUnits,
                    employeesArr:finalEmpList,
                    projectName:editProjectItem.projectName,
                    projectCode:editProjectItem.projectCode,
                    description:editProjectItem.description,
                    projectManagerId:editProjectItem.projectManagerId,
                    projectManagerPlaceholder:projectManager.employeeName,
                    startDate:startDate,
                    endDate:endDate,
                    selectedUnitObj:selectedUnitObj,
                    selectedEmployeeObj:selectedEmployeeObj
                },()=>{
                    this.textInput.current.focus();
                })
            }else{
                let newUnits =[]
                Object.values(this.props.unitArr).map((item)=>{
                    let  tempItem = item
                    tempItem["unitNameAndCity"] = item.unitName+", "+item.city
                    newUnits.push(item)
                })

                let newEmployees = []
                Object.values(this.props.employeesArr).map((item)=>{
                    let tempItem = item
                    tempItem["empAndDesignation"] = item.salutation+" "+item.firstName+" "+item.lastName+" ("+item.designation+")"
                    newEmployees.push(item)
                })
                this.setState({
                    formErrors:{},
                    projectName:"",
                    projectCode:"",
                    description:"",
                    projectManagerId:"",
                    selectedUnitObj:[],
                    selectedEmployeeObj:[],
                    projectManagerPlaceholder:projectManagerPlaceholder,
                    unitPlaceholder:unitPlaceholder,
                    employeePlaceholder:employeePlaceholder,
                    startDate:"",
                    endDate:"",
                    unitArr:newUnits,
                    employeesArr:newEmployees,
                    isEdit:false
                },()=>{
                    this.textInput.current.focus();
                })
            }
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    projectManagerDropdownClick = (item) => {
        let employeesArr = [];
        let projectManagerId = ''
        if(!isEmptyVariable(item)){
            employeesArr=this.changeEmpArray(this.state.employeesArr,true,"projectManager",item.employeeId)
            projectManagerId = item.employeeId
        }else{
            employeesArr=this.changeEmpArray(this.state.employeesArr,true,"projectManager",'')
        }
        this.setState({
            employeesArr:employeesArr,
            projectManagerPlaceholder:item?item.employeeName:'',
            projectManagerId:projectManagerId
        });
    }

    // Handle changes to the start date
    onStartDateChange = (date, dateString) => {
		let startDate = "";
		if (!isEmptyVariable(date)) {
			startDate = date.format("YYYY/MM/DD");
        }
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp['startDate'] = "";

        // Clear end date if start date is later than the current end date
        let endDate = this.state.endDate;
        if (endDate && moment(startDate).isAfter(moment(endDate))) {
            endDate = "";
            FormErrorsTemp['endDate'] = "";
        }

		this.setState({
            startDate:startDate,
            endDate:endDate,
            formErrors:FormErrorsTemp
        })
    };
    // Handle changes to the end date
    onEndDateChange = (date, dateString) => {
		let endDate = "";
		if (!isEmptyVariable(date)) {
			endDate = date.format("YYYY/MM/DD");
        }
        let FormErrorsTemp = this.state.formErrors;
        FormErrorsTemp['endDate'] = "";

		this.setState({
            endDate:endDate,
            formErrors:FormErrorsTemp
        })
    };

    // Disable end dates that are before the selected start date
    disabledEndDate = (current) => {
        const { startDate } = this.state;
        return startDate && current < moment(startDate, "YYYY-MM-DD");
    };

    // Handle the press of the Enter key
    onKeyBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.handleSelectFuncDialogShow();
        }
    }

    // Handle changes to the selected units
    unitDropdownClick = (item) => {
        let selectedUnitObj=[];
        let FormErrorsTemp = this.state.formErrors;
        // If the submit button has been clicked at least once, clear the error message for selected units
        if(this.state.submitClickedAtleastOnce){
            FormErrorsTemp['selectedUnit'] = "";
        }

        for(var i =0;i<item.length;i++){
            selectedUnitObj.push(item[i])
        }

        this.setState({
            selectedUnitObj:selectedUnitObj,
            formErrors:FormErrorsTemp
        })
    }

    // Handle changes to the selected employees
    employeeDropdownClick = (selectedItem) => {
        let selectedEmployeeObj = this.state.selectedEmployeeObj;
        let employeesArr = [];

        // Loop through the current selected employee objects
        for (const item of selectedEmployeeObj){
            // If the selected item does not include the current employee, remove it from the selected employee objects
            if(!selectedItem.some(e=>e.employeeId==item)){
                // Filter out the current employee from the selected employee objects
                selectedEmployeeObj=selectedEmployeeObj.filter(e=>e!==item);
                  // Update the employees array to reflect the change
                employeesArr=this.changeEmpArray(this.state.employeesArr,false,"",item)
            }
        }

        // Loop through the selected items
        for (const item of selectedItem){
            // If the selected employee objects do not include the current item, add it
            if(!selectedEmployeeObj.includes(item.employeeId)){
                // Add the current item to the selected employee objects
                selectedEmployeeObj.push(item.employeeId);
                // Update the employees array to reflect the change
                employeesArr=this.changeEmpArray(this.state.employeesArr,true,"employee",item.employeeId)
            }
        }

        this.setState({
            selectedEmployeeObj:selectedEmployeeObj,
            employeesArr:employeesArr
        })
    }

    // Update the employees array based on the selection of an employee
    changeEmpArray = (arr, flag, position,targetEmpId) => {
        let newArr = arr.map((item)=>{
            if(item.employeeId === targetEmpId){
                item.isSelected = flag;
                item.position = position;
            }else{
                if(item.position !== "" &&
                item.position === position &&
                item.position !== "employee"){
                    item.isSelected = !flag;
                    item.position = "";
                }
            }
            return item;
        })
        return newArr;
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }
    /************************API CALLS **************************/
    addEditProject = () => {
        this.setState({
            showLoader:true,
        });

        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.projectName.trim())){
            isError = true;
            FormErrorsTemp['projectName'] = "Please enter project name";
        }

        if(isEmptyVariable(this.state.projectCode.trim())){
            isError = true;
            FormErrorsTemp['projectCode'] = "Please enter project code";
        }

        let selectedUnitIds =[]
        Object.values(this.state.selectedUnitObj).map((item)=>{
           selectedUnitIds.push(item.unitId)
        })

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

            setTimeout(function(){
                this.setState({
                    formErrors:{},
                });
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }else{
            let url = Constants.CreateProject
            if(this.props.isEdit){
                url = Constants.EditProject
            }
            
            let postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                companyId:this.props.companyId,
                projectName:this.state.projectName,
                projectCode:this.state.projectCode,
                description:this.state.description,
                projectManagerId:this.state.projectManagerId,
                startDate:this.state.startDate,
                endDate:this.state.endDate,
                selectedUnits:JSON.stringify(selectedUnitIds),
                selectedEmployees:JSON.stringify(this.state.selectedEmployeeObj)
            }
            if(this.props.isEdit){
                postParam.projectId = this.props.editProjectItem.projectId
            }
            
            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                    },()=>{
                        this.props.handleAddProjectDialogClose(true)
                    });
                }else{
                    this.setState({
                        showLoader:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    })
                }
            });
        }
    }
    getEmployee = (position) => {
        //filter
        let filteredArr = this.state.employeesArr.filter(item => item.isSelected !== true 
            || (item.isSelected === true && item.position === position));
        return filteredArr;
    }

    render() {
        let PMList  = this.getEmployee("projectManager");
        let EMList  = this.getEmployee("employee");
        return(
            <div>
                <Modal className="add-unit-dialog custom-dialog" 
                show={this.props.showAddProjectDialog} backdrop="static">
                    <Modal.Header>
                        <h5>{this.props.isEdit?"Edit Project":"Add New Project"}</h5>
                        <button 
                            onClick={this.props.handleAddProjectDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        <div className="addnew-modal-form">
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <p>Project Name*</p>
                                        <input 
                                            ref={this.textInput}
                                            type="text" 
                                            placeholder="Enter Project Name" 
                                            name="projectName"
                                            onChange ={this.handleChange}
                                            value={this.state.projectName}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['projectName']) &&
                                            <span className="cm-error">{this.state.formErrors['projectName']}</span>
                                        }
                                        
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <p>Project Code*</p>
                                        <input 
                                            type="text" 
                                            placeholder="Enter Project Code" 
                                            name="projectCode"
                                            onChange ={this.handleChange}
                                            value={this.state.projectCode}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['projectCode']) &&
                                            <span className="cm-error">{this.state.formErrors['projectCode']}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <p>Description</p>
                                        <textarea 
                                            type="text" 
                                            placeholder="Enter Description" 
                                            name="description"
                                            onChange ={this.handleChange}
                                            value={this.state.description}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['description']) &&
                                            <span className="cm-error">{this.state.formErrors['description']}</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <p>Project Manager</p>
                                        {/* <RegularDropdown 
                                            placeholder={this.state.projectManagerPlaceholder}
                                            dropdownArr={PMList}
                                            labelParam="empAndDesignation"
                                            onDropDownItemClick={this.projectManagerDropdownClick}
                                            defaultPlaceholderDropDown={projectManagerPlaceholder}
                                        /> */}
                                        <ReactSelectDropdown
                                            handleReactSelectClose={this.projectManagerDropdownClick}
                                            selectedDDObj= {this.state.employeesArr.filter(e=>this.state.projectManagerId==e.employeeId)}
                                            selectDropdownArr={PMList}
                                            label={"empAndDesignation"}
                                            value={"employeeId"}
                                            placeholder={this.state.projectManagerPlaceholder}
                                            isClearSelect={true}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['projectManager']) &&
                                            <span className="cm-error">{this.state.formErrors['projectManager']}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <p>Start Date</p>
                                        <DatePicker
                                            onChange={this.onStartDateChange}
                                            format={"DD/MM/YYYY"}
                                            value={!isEmptyVariable(this.state.startDate) ? moment(this.state.startDate) : ""}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['startDate']) &&
                                            <span className="cm-error">{this.state.formErrors['startDate']}</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group">
                                        <p>End Date</p>
                                        <DatePicker
                                            onChange={this.onEndDateChange}
                                            format={"DD/MM/YYYY"}
                                            disabledDate={this.disabledEndDate}
                                            value={!isEmptyVariable(this.state.endDate) ? moment(this.state.endDate) : ""}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['endDate']) &&
                                            <span className="cm-error">{this.state.formErrors['endDate']}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="row custom-row">
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group input-dis">
                                        <p>Units</p>
                                        <ReactSelectDropdown
                                            handleReactSelectClose={this.unitDropdownClick}
                                            selectedDDObj= {this.state.selectedUnitObj}
                                            selectDropdownArr={this.state.unitArr}
                                            label={"unitNameAndCity"}
                                            value={"unitId"}
                                            placeholder={this.state.unitPlaceholder}
                                            isMultiselect={true}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['units']) &&
                                            <span className="cm-error">{this.state.formErrors['units']}</span>
                                        }
                                    </div>
                                </div>
                                <div className="col-md-6 custom-col">
                                    <div className="addnew-modal-form-group input-dis">
                                        <p>Employee</p>
                                        <ReactSelectDropdown
                                            handleReactSelectClose={this.employeeDropdownClick}
                                            selectedDDObj= {this.state.employeesArr.filter(e=>this.state.selectedEmployeeObj.includes(e.employeeId))}
                                            selectDropdownArr={EMList}
                                            label={"empAndDesignation"}
                                            value={"employeeId"}
                                            // placeholder={this.state.employeePlaceholder}
                                            isMultiselect={true}
                                        />
                                        {
                                            !isEmptyVariable(this.state.formErrors['employees']) &&
                                            <span className="cm-error">{this.state.formErrors['employees']}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="modal-close-save">
                            <div className="close-save-col">
                                <button onClick={this.props.handleAddProjectDialogClose} type="button" className="modal-close">Cancel</button>
                                <DialogSaveButton themeSettings={this.props.themeSettings} onClick={this.addEditProject} type="button" className="modal-save">
                                    {this.state.showLoader ? <div class="loader-btn mt-2"></div> : 'Save'}
                                </DialogSaveButton>
                            </div>
                        </div>
                    </div>
                </Modal>
                <AlertDialog
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type= {Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                    themeSettings={this.props.themeSettings}
                />
            </div>
        );
    }
}

export default AddProjectDialog;