import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { TableIcon, DialogSaveButton, AddNewButtonLayout,FilterCheckBox } from "../../../common/Global/globalStyles.style";
import { getLocalStorageVariables, isEmptyVariable, isEmptyArray, truncateString,
	getOnlyFileNameFromPath } from "../../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";
import TableDropDown from "../../../common/DropdownMenus/TableDropdown";
import UploadReport from "./uploadReport"
import AlertDialog from '../../../common/AlertDialog';

const userDetails  = getLocalStorageVariables();

class AssertionAssignmentList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			datalist:[],
			showLoader:false,
			// uploadReportErr: "",
			showUploadReportDialog: false,
			
			operationType:"",
			alertDialogHeading:"",
			proceedBtnLabel:"",
			deleteReactivateId:"",
			showAlertDialog:false,
			alertDialogMessage:"",
			showBtnLoader:false,
			showAlertDialogInfo:false,
            alertDialogMessageInfo:"",
		};
	}

	handleShowUploadReport = () => {
		this.setState({
			showUploadReportDialog:true
		});
	}

	handleUploadReportDialogClose = (reloadFlag) => {
		this.setState({
			showUploadReportDialog:false
		},()=>{
			if(reloadFlag === true){
				this.initData();
			}
		});
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showUploadedReportListDialog) {
			this.setState({
				datalist:[],
				showLoader:false,

				operationType:"",
				alertDialogHeading:"",
				proceedBtnLabel:"",
				deleteReactivateId:"",
				showAlertDialog:false,
				alertDialogMessage:"",
				
				showAlertDialogInfo:false,
            	alertDialogMessageInfo:"",
			},()=>{
				this.initData();
			});
		}
	}

	downloadAttachment = (documentPath,e) => {
        e.stopPropagation();
        fetch(Constants.API_SERVER_PATH+documentPath)
        .then(response => { return response.blob(); } )
        .then(blob =>
        {
            let fileName = getOnlyFileNameFromPath(documentPath);
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

	handleDeleteReport = ( dropdownItem) => {
		let arr = dropdownItem.split(":-:");
        
		this.setState({
			operationType:"Delete",
			alertDialogHeading:"Delete Report",
			proceedBtnLabel:"Delete",
			deleteReactivateId:arr[0],
			showAlertDialog:true,
			alertDialogMessage:"Are you sure you want to delete report "+getOnlyFileNameFromPath(arr[1])+"?"
		})
        
    }

	handleAlertDialogClose = () => {
		this.setState({
			operationType:"",
			alertDialogHeading:"",
			proceedBtnLabel:"",
			deleteReactivateId:"",
			showAlertDialog:false,
			alertDialogMessage:"",
			
		});
	}

	handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

	

	// //Callback from upload annexure sample
	// returnUploadedArray = (uploadedFileArray) => {
	// 	let newArray = [...this.state.datalist,...uploadedFileArray]

	// 	this.setState({
	// 		datalist:newArray,
	// 		showUploadReportDialog:false
	// 	})
	// }

	// handleSelectUploadAnnexureDialogSaveClose = () => {
	// 	this.props.handleSelectUploadAnnexureDialogSaveClose(this.state.datalist,this.state.annexure_NR);
	// }

	// handleCheck = (e) =>{
    //     let isChecked = e.target.checked;
    //     let name = e.target.name;

    //     if(isChecked){
    //         this.setState({
    //             [name]:"Y",
	// 			datalist:"",
    //         })
    //     }else{
    //         this.setState({
    //             [name]:"N"
    //         })
    //     }
    // }

	/*****************************API*********************************/
	initData = () => {
		this.setState({
			showLoader:true
		});
		let postParams = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			assignmentId:this.props.assignmentId,
		}
		fetch(Constants.GetReportUploads, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParams),
		})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
				localStorage.clear();
				window.location = "/";
			} else if (data.responseCode === Constants.CODE_SUCCESS) {
				let reportUploads = [];
				reportUploads= data.data.reportUploads;
				
				this.setState({
					showLoader: false,
					datalist: reportUploads,
				});
			} else {
				this.setState({
					datalist: [],
					showLoader: false,
					
				});
			}
		});

	}

	handleRecordOperation = () => {
		if(!isEmptyVariable(this.state.deleteReactivateId)){
			this.setState({
				showBtnLoader:true
			})
			let url = "";
			let postParams = {
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				assignmentId:this.props.assignmentId,
				reportUploadId:this.state.deleteReactivateId,
			};

			if(this.state.operationType === "Delete"){
				url = Constants.DeleteReportUpload;
			}

			fetch(url,{
				method:"POST",
				mode:"cors",
				body: new URLSearchParams(postParams),
			}).then((response)=>{
				return response.json();
			})
			.then((data)=>{
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState({
						operationType:"",
						alertDialogHeading:"",
						proceedBtnLabel:"",
						deleteReactivateId:"",
						showAlertDialog:false,
						alertDialogMessage:"",
						showBtnLoader:false
					},()=>{
						this.initData();
					});
				} else {
					this.setState({
						operationType:"",
						alertDialogHeading:"",
						proceedBtnLabel:"",
						deleteReactivateId:"",
						showAlertDialog:false,
						alertDialogMessage:"",
						showBtnLoader:false,
						showAlertDialogInfo:true,
						alertDialogMessageInfo:data.responseMessage,
						
					});
				}
			});
		}
	}


	render() {
		const delDropdownObj = {
            icon:"delete",
            label:"Delete"
        }
        let actionObjAllowEdit = [delDropdownObj];
        let actionObjDisallowEdit = [];

		return (
			<section>
				<Modal
					className="task-select-dept-dialog custom-dialog"
					show={this.props.showUploadedReportListDialog}
					onHide={this.props.handleUploadedReportListDialogClose}
				>
					<Modal.Header>
						<h5>Uploaded Reports</h5>
						<button onClick={this.props.handleUploadedReportListDialogClose} type="button" data-dismiss="modal">
							<span class="material-icons">close</span>
						</button>
					</Modal.Header>
					<hr />
					<div className="modal-body">
						{
							this.state.showLoader &&
							<div class="loader"></div>
						}
						<div className="body-wrapper">
							<div className="search-addnew-bg m-t-0">
								<div className="search-addnew" style={{alignItems:"center"}}>
									<div className="search-filter-layout" style={{position:"relative"}}>
										<AddNewButtonLayout themeSettings={this.props.themeSettings}>
											<a href="javascript:void(0);" onClick={this.handleShowUploadReport}>
												<span className="material-icons">add_circle</span>
												<p>Add Report</p>
											</a>
										</AddNewButtonLayout>
										{/* <div className="overlay-nr" style={{display:this.state.annexure_NR === "Y"?"block":"none"}}></div> */}
									</div>
								</div>
							</div>

							<div style={{position:"relative"}}>
							{
								!isEmptyArray(this.state.datalist) && 
								<div className="common-tab-bg">
									<div className="common-table" style={{width:"100%"}}>
										<table className="table">
											<thead>
												<tr>
													<th className="text-center" width={"5%"}>No.</th>
													<th>File Name</th>
													{/* <th width={"5%"}>Remarks</th> */}
													<th className="text-center" width={"5%"}></th>
													<th className="text-center"  width={"5%"}></th>
												</tr>
											</thead>
											<tbody>
											{
												!isEmptyArray(this.state.datalist) && 
												this.state.datalist.map((item,idx) => {
													return <tr>
														<td className="text-center">{(idx+1)}</td>
														<td>{getOnlyFileNameFromPath(item.documentPath)}</td>
														{/* <td>{truncateString(item.remark,Constants.REMARKS_STRING_LENGTH)}</td> */}
														<TableIcon themeSettings={this.props.themeSettings} className="text-center">
															<span class="material-icons" onClick={this.downloadAttachment.bind(this, item.documentPath)}>get_app</span>
														</TableIcon>
														<TableIcon themeSettings={this.props.themeSettings} className="text-center">
															<span class="material-icons" onClick={this.handleDeleteReport.bind(this, item.reportUploadId+":-:"+item.documentPath)}>delete</span>
														</TableIcon>
														{/* <td className="text-center">
															{
																<TableDropDown 
																	actionArr={actionObjAllowEdit}
																	onDropDownItemClick = {this.handleDeleteReport}
																	dropDownId = {item.reportUploadId+":-:"+item.documentPath}
																	dropdownWidth={"130px"}
																	themeSettings={this.props.themeSettings}
																/>
															}
														</td> */}
													</tr>
												})
											}
											</tbody>
										</table>
									</div>
								</div>
							}
							{
								isEmptyArray(this.state.datalist) &&
								<div class="no-items-layout">
									<div class="no-items-card">
										<h6>
										{
											isEmptyVariable(this.state.apiSearchKey)?
											Constants.NO_RECORDS_WARNING
											:
											Constants.EMPTY_SEARCH_WARNING
										}
										</h6>
									</div>
								</div>
							}
							{/* <div className="overlay-nr" style={{display:this.state.annexure_NR === "Y"?"block":"none"}}></div> */}
							</div>


							{/* {!isEmptyVariable(this.state.uploadReportErr) && <span className="cm-error">{this.state.uploadReportErr}</span>} */}
							<div className="modal-close-save m-t-15">
								<div className="close-save-col">
									<button onClick={this.props.handleUploadedReportListDialogClose} type="button" className="modal-close m-b-0">
										Close
									</button>
									{/* <DialogSaveButton
										themeSettings={this.props.themeSettings}
										onClick={this.handleSelectUploadAnnexureDialogSaveClose}
										type="button"
										className="modal-save m-b-0"
									>
										Save
									</DialogSaveButton> */}
								</div>
							</div>
						</div>
					</div>
				</Modal>

				<UploadReport
					showUploadReportDialog = {this.state.showUploadReportDialog}
					handleUploadReportDialogClose = {this.handleUploadReportDialogClose}
					themeSettings={this.props.themeSettings}
					returnUploadedArray={this.returnUploadedArray}
					isDraftOnward = {this.props.isDraftOnward}
					assignmentId = {this.props.assignmentId}
				/>

				<AlertDialog
                    showAlertDialog={this.state.showAlertDialog}
                    handleAlertDialogClose={this.handleAlertDialogClose}
                    type={
                        (this.state.operationType === "Delete")
                        ?
                        Constants.ALERT_TYPE_WARNING
                        :
                        Constants.ALERT_TYPE_INFO
                    }
                    alertDialogHeading={this.state.alertDialogHeading}
                    alertDialogMessage={this.state.alertDialogMessage}
                    proceedBtnClick={this.handleRecordOperation}
                    proceedBtnLabel={this.state.proceedBtnLabel}
					showLoader={this.state.showBtnLoader}
                    themeSettings={this.props.themeSettings}
                />
				<AlertDialog 
					showAlertDialog={this.state.showAlertDialogInfo}
					handleAlertDialogClose={this.handleAlertDialogCloseInfo}
					type= {Constants.ALERT_TYPE_ALERT}
					alertDialogMessage={this.state.alertDialogMessageInfo}
					proceedBtnClick={this.handleAlertDialogCloseInfo}
					proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
					themeSettings={this.props.themeSettings}
				/>
			</section>
		);
	}
}

export default AssertionAssignmentList;
