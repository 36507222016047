import React from "react";
import * as Constants from '../Global/constants';
import { getLocalStorageVariables, getThemeSettingsVariables, isEmptyArray, isEmptyVariable, isJsonString, removeHtmlTags } from "../Global/commonFunctions";
import * as TemplateConstants from "../Global/templateConstants";
import { AddNewButtonLayout, PageSaveButton } from "../Global/globalStyles.style";
import RegularDropdown from "../DropdownMenus/RegularDropdown";
import MultiSelectComponentTemplate from "../../pages/Tasks/Common/multiSelectComponent";
import DatePickerComponent from "../../pages/Tasks/Common/dateSelectComponent";
import AlertWithInput from "../AlertDialog/alertwithInput";
import ReactQuill from "react-quill";
import AlertDialog from "../AlertDialog";
import { matchPath } from "react-router-dom";

const themeSettings  = getThemeSettingsVariables();
const userDetails  = getLocalStorageVariables();

export default class OverallAssignment extends React.PureComponent {
    constructor(props) {
		super(props);
		this.state = {
            showLoader: false,
            componentDidMountFlag: false,
            menus: [],
            isEdit: false,
            showTemplateAlertDialog: false,
            showTemplateInfoDialog: false,
            showCreateDialog: false,
            editTemplateItem: {},
            editTemplateItem_prev: {},
            datalist: [],
        }
    }

    componentDidMount() {
		this.getInitData();
	}

    getInitData = () => {
        this.setState({
            showLoader: true,
        });
        Promise.all([
            fetch(this.props.getDatalistApi, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.props.assignmentId,
                }),
            }),
        ])
        .then(([assRes]) => {
            return Promise.all([assRes.json()]);
        })
        .then(([assRes]) => {
            let datalist = [], assignmentLevelData = [];
            if (assRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || assRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            } else if (assRes.responseCode === Constants.CODE_SUCCESS) {
                const assignmentLevelTemplateFields = assRes.data.assignmentLevelTemplateFields;
                assignmentLevelData = assRes.data.assignmentLevelData
                if (assignmentLevelTemplateFields && isJsonString(assignmentLevelTemplateFields)) {
                    datalist = JSON.parse(assignmentLevelTemplateFields);
                    this.setFieldValues(datalist, assignmentLevelData);
                }
            } else if (assRes.responseCode === Constants.CODE_ASSIGNMENT_ACCESS_ERROR) {
                this.setState({
                    showAlertDialogInfo: true,
                    alertDialogMessageInfo: assRes.responseMessage,
                    responseCode: assRes.responseCode
                })
            } else {
                this.setState({
                    showAlertDialogInfo: true,
                    alertDialogMessageInfo: assRes.responseMessage,
                    responseCode: assRes.responseCode
                })
            }
            
            this.setState({
                showLoader: false,
                componentDidMountFlag: true,
                datalist: datalist,
                isEdit: this.props.isEditable && assignmentLevelData.length === 0,
            });
        });
    }

    handleChangeReactQuill = (focusedIndex,value) => {
        let tempArr = [ ...this.state.datalist ];
        tempArr[focusedIndex].fieldValue = value;
        this.setState({
            datalist:tempArr
        });
    }
    modules = {
		toolbar: [
			[{ header: [1, 2, 3, false] }],
			["bold", "italic", "underline", "strike", { color: [] }, { background: [] }],
			[{ list: "ordered" }, { list: "bullet" }, { align: [] }, "link"],
			["clean"],
		],
	};

    handleQuillFocus = (focusIndex) => {
        if (this.state.isEdit) {
            let tempArr = this.state.datalist.map((item,index)=>{
                if(index === focusIndex){
                    item.isFocused = true
                }else{

                    item.isFocused = false
                }
                return item;
            })
            this.setState({
                datalist:tempArr
            })
        }
    }

    onDropDownItemClick = (item,index) => {
        let tempArr = [ ...this.state.datalist ];
        tempArr[index].fieldValue = item.value;
        this.setState({
            datalist: tempArr
        })
    }

    addSelectedValues = (value,index) => {
        let tempArr = [ ...this.state.datalist ];
        if (isEmptyVariable(tempArr[index].fieldValue)) {
            tempArr[index].fieldValue = [];
        }

        tempArr[index].fieldValue.push(value);
        this.setState({
            datalist:tempArr
        })
    }

    removeSelectedValues = (value,index) => {
        let tempArr = [ ...this.state.datalist ];
        let fieldValueArr = tempArr[index].fieldValue;
		let formCustomRisk = this.state.formCustomRisk;

        if(!isEmptyVariable(value)){
            let fieldValueIndex = fieldValueArr.indexOf(value);
            if(fieldValueIndex !== -1){
                fieldValueArr.splice(fieldValueIndex,1)
            }
			if(value === -1){
				formCustomRisk="";
			}
			
        }
        this.setState({
            datalist:tempArr,
			formCustomRisk:formCustomRisk
        })
    }

    handleChangeFormCustomRisk = (value) => {
        this.setState({ 
            formCustomRisk:value
        });
    }

    onCustomRiskFocus = () => {
        this.handleFocus();
        this.setState({
            formCustomRisk_isFocused:true,
        })    
    }
    
    handleFocus = () => {
        if (this.state.isEdit) {
            let tempArr = this.state.datalist.map((item)=>{
                item.isFocused = false
                return item;
            })
            this.setState({
                datalist:tempArr
            });
        }
    }

    onCustomRiskBlur = () => {
        this.setState({
            formCustomRisk_isFocused:false,
        })    
    }

    onDateChange = (dateValue,index) => {
        let tempArr = [ ...this.state.datalist ];
        tempArr[index].fieldValue = dateValue;
        this.setState({
            datalist:tempArr
        })
    };

    onAddTableClick = (index) => {
		this.setState({
			showAlertWithInputDialog: true,
			alertWithInputDialogMessage: "Enter the number of Rows and Columns",
            addToTableIndex:index,
		});
	};

    handleAlertWithInputDialogClose = () => {
		this.setState({
			showAlertWithInputDialog: false,
			alertWithInputDialogMessage: "",
		});
	};

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleDeleteTable = () => {
        let tempArr = this.state.datalist;
        tempArr[this.state.deleteReactivateId].fieldValue = [];
        tempArr[this.state.deleteReactivateId].isTableAdded = false;
        this.setState({
            datalist: tempArr,
            deleteReactivateId:"",
            operationType:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            alertDialogMessage:"",
            showAlertDialog:false,
        })
    }

    handleAddIntanceTable = (rows, cols, excelData) => {
        let tableArray = [];
        for(let i=0;i<rows;i++){
            tableArray[i]=[];
            let j= 0;
            for(j=0;j<cols;j++){
                if(!isEmptyArray(excelData)){
                    tableArray[i][j]= excelData[i][j] ?? "";
                }else{
                    tableArray[i][j] = "";
                }
            }
            tableArray[i][j] = "Action"
        }
        
        let tempArr = [ ...this.state.datalist ];
        tempArr[this.state.addToTableIndex].fieldValue = tableArray;
        tempArr[this.state.addToTableIndex].isTableAdded = true;

		this.setState({
			showAlertWithInputDialog: false,
			alertWithInputDialogMessage: "",
            tableArray:tableArray,
            datalist:tempArr,
            addToTableIndex:""
		});
	};

    addNewColumn = (index,e) => {
        let tempArr = [ ...this.state.datalist ];
        let tableArray = tempArr[index].fieldValue;
        let rowLength = tableArray.length;
        let colLength = tableArray[0].length;
        let newArr = [];

        for(let i=0;i<rowLength;i++){
            let rowArray = tableArray[i];
            rowArray.splice(colLength-1,0,"")
            newArr.push(rowArray);
        }
        tempArr[index].fieldValue = newArr;
        this.setState({
            datalist: tempArr
        })
    }

    addNewRow = (index,e) => {
        let tempArr = [ ...this.state.datalist ];
        let tableArray = tempArr[index].fieldValue;
        let singleRow = [];
        let colLength = tableArray[0].length;
        for(let i=0; i<colLength; i++){
            if(i === colLength-1){
                singleRow.push("Action")
            }else{
                singleRow.push("");
            }
        }

        tableArray.push(singleRow);
        tempArr[index].fieldValue = tableArray;

        this.setState({
            datalist: tempArr
        })
    }

    handleTableInputBlur = (rowIndex, colIndex, index, e) => {
		const value = e.currentTarget.textContent;
        let tempArr = [ ...this.state.datalist ];
        let tableArray = tempArr[index].fieldValue;
		tableArray[rowIndex][colIndex] = value;

		this.setState({
            datalist: tempArr,
        });
	};

    deleteColumn = (colIndex, index,  e) =>{
        let tempArr = [ ...this.state.datalist ];
        let tableArray = tempArr[index].fieldValue;
        let tableArrayTemp = [];

        tableArray.forEach((rowItem,rowIndex)=>{
            rowItem.splice(colIndex,1);
            tableArrayTemp[rowIndex]=[...rowItem];
        });
        tempArr[index].fieldValue = tableArrayTemp;

        this.setState({
            datalist: tempArr
        })
    }

    deleteRow = (rowIndex, index,  e) =>{
        let tempArr = [ ...this.state.datalist ];
        let tableArray = tempArr[index].fieldValue;
        tableArray.splice(rowIndex,1);
        tempArr[index].fieldValue = tableArray;

        this.setState({
            datalist: tempArr
        })
    }

    handleDeleteTableClickAlert = (fieldName,index,e) => {
        e.stopPropagation();
        this.setState({
            operationType:"Delete",
            alertDialogHeading:"Delete Field",
            proceedBtnLabel:"Delete",
            deleteReactivateId:index,
            showAlertDialog:true,
            alertDialogMessage:"Are you sure you want to delete field- "+fieldName+"?"
        })
    }

    editFields = () => {
        this.setState({
            isEdit: true,
        });
    }

    cancelEditFields = () => {
        this.setState({
            isEdit: false,
        });
    }

    saveFields = () => {
        let values = {};
        this.state.datalist.forEach(field => {
            let value = field.fieldValue ?? "";
            if (!isEmptyVariable(value)) {
                if (field.fieldType === TemplateConstants.FIELD_TYPE_TEXT) {
                    value = removeHtmlTags(value);
                }
                else if (field.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI || field.fieldType === TemplateConstants.FIELD_TYPE_TABLE) {
                    value = JSON.stringify(value);
                }
                values[field.fieldId] = value;
            }
        });
        this.setState({
            showLoader: true,
        });

        fetch(this.props.saveDatalistApi, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                assignmentId: this.props.assignmentId,
                ...values,
            }),
        })
        .then(assRes => assRes.json())
        .then(assRes => {
            if (assRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || assRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            } else if (assRes.responseCode === Constants.CODE_SUCCESS) {
                fetch(this.props.getDatalistApi, {
                    method: "POST",
                    mode: "cors",
                    body: new URLSearchParams({
                        email: userDetails.email,
                        accessToken: userDetails.accessToken,
                        assignmentId: this.props.assignmentId,
                    }),
                })
                .then(assRes => assRes.json())
                .then(assRes => {
                    let datalist = [];
                    if (assRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || assRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                        localStorage.clear();
                        window.location = "/";
                    } else if (assRes.responseCode === Constants.CODE_SUCCESS) {
                        const assignmentLevelTemplateFields = assRes.data.assignmentLevelTemplateFields, assignmentLevelData = assRes.data.assignmentLevelData;
                        if (assignmentLevelTemplateFields && isJsonString(assignmentLevelTemplateFields)) {
                            datalist = JSON.parse(assignmentLevelTemplateFields);
                            this.setFieldValues(datalist, assignmentLevelData);
                        }
                    }
        
                    this.setState({
                        showLoader: false,
                        datalist: datalist,
                        isEdit: false,
                    });
                });
            }
        });
    }

    setFieldValues = (datalist, assignmentLevelData) => {
        datalist = datalist.map(field => {
            const fieldData = assignmentLevelData.find(data => data.fieldId.toString() === field.fieldId.toString());
            if (fieldData) {
                let value = fieldData.value;
                if ((field.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI || field.fieldType === TemplateConstants.FIELD_TYPE_TABLE) && isJsonString(value)) {
                    value = JSON.parse(value);
                    field.isTableAdded = true;
                }
                field.fieldValue = value;
            }
            return field;
        });
    }

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
        if(this.state.responseCode === Constants.CODE_ASSIGNMENT_ACCESS_ERROR ||
            this.state.responseCode === Constants.CODE_ASSIGNMENT_SPECIFIC_ACCESS_ERROR){
            if(matchPath(this.props.history.location.pathname, { path: ["/ongoingoverall" ]})){
				this.props.history.push(Constants.URL_ONGOING_REPORTS);
			}else if(matchPath(this.props.history.location.pathname, { path: ["/draftoverall" ]})){
				this.props.history.push(Constants.URL_DRAFT_REPORTS);
			}else{
				this.props.history.push(Constants.URL_COMPLETED_REPORTS);
			}
		}
    }

    render () {
        return (
            <div>
                {
                    this.state.showLoader &&
                    <div class="loader"></div>
                }
                {this.state.componentDidMountFlag &&
                    <div className="m-t-10">
                        <div className="row custom-row">
                            <div className="col-md-8 custom-col add-template">
                                {
                                    this.state.datalist.map((item,index)=>{
                                        let showHeader = true;
                                        if(item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI){
                                            if(item.isFromRiskMaster){
                                                if(item.isRiskMasterEnabled === "Y"){
                                                    showHeader = true;
                                                }else{
                                                    showHeader = false;
                                                }
                                            }else{
                                                showHeader = true;
                                            }
                                        }
                                        return (
                                            <div class="editor-card">
                                                {
                                                    showHeader &&
                                                    <div className="task-header-layout">
                                                        <h6 className="editor-heading">{item.fieldName}{item.isMandatorySelected === "Y"?"*":""}</h6>
                                                    </div>
                                                }
                                                {
                                                    item.fieldType === TemplateConstants.FIELD_TYPE_TEXT &&
                                                    <div className="row custom-row">
                                                        <div className="col-md-12 custom-col">
                                                            <div className={item.isNRChecked === "Y"?"editor-layout editor-disabled":"editor-layout"}>
                                                                <ReactQuill
                                                                    placeholder={item.placeholderValue}
                                                                    value={!isEmptyVariable(item.fieldValue)?item.fieldValue:""} 
                                                                    id = {item.fieldName+":-:"+index}
                                                                    onChange={this.handleChangeReactQuill.bind(this,index)}
                                                                    className={item.isFocused?
                                                                        (item.isNRChecked === "Y"?"two-lines":"two-lines-focus"):"two-lines"}
                                                                    modules={this.modules}
                                                                    onFocus={this.handleQuillFocus.bind(this,index)}
                                                                    readOnly={item.isNRChecked === "Y" || !this.state.isEdit ? true : false}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN &&
                                                <div className="row custom-row">
                                                    <div className="col-md-12 custom-col">
                                                        <div className={item.isNRChecked === "Y"?"editor-layout editor-disabled":"editor-layout"}>
                                                            <RegularDropdown 
                                                                placeholder={isEmptyVariable(item.fieldValue)?item.placeholderValue:item.fieldValue}
                                                                dropdownArr={item.dropdownValues}
                                                                labelParam="value"
                                                                onDropDownItemClick={this.onDropDownItemClick}
                                                                disabled={item.isNRChecked === "Y" || !this.state.isEdit ?true:false}
                                                                defaultPlaceholderDropDown={item.placeholderValue}
                                                                udf = {index}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                {
                                                    item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                                                    item.isRiskMasterEnabled === "Y" &&
                                                    item.isFromRiskMaster &&
                                                    <div className="row custom-row">
                                                        <div className="col-md-12 custom-col">
                                                            <div className={item.isNRChecked === "Y"?"editor-layout editor-disabled":"editor-layout"}>
                                                                {!this.state.isEdit
                                                                    ? <RegularDropdown 
                                                                        placeholder={!isEmptyArray(item.fieldValue) ? item.fieldValue.join(", ") : item.placeholderValue}
                                                                        dropdownArr={[]}
                                                                        disabled={true}
                                                                    />
                                                                    : <MultiSelectComponentTemplate
                                                                            templateItemIndex={index}
                                                                            allowFiltering={true}
                                                                            fields = {{text:"displayName",value:"businessRiskId"}}
                                                                            dropdownValues={this.state.businessRisk}
                                                                            placeholderValue={item.placeholderValue} 
                                                                            addSelectedValues={this.addSelectedValues}
                                                                            removeSelectedValues={this.removeSelectedValues}
                                                                            fieldValue={!isEmptyArray(item.fieldValue)?item.fieldValue:""}
                                                                            isFromRiskMaster={item.isFromRiskMaster}
                                                                            handleFocus = {this.handleFocus}
                                                                            disabled={!this.state.isEdit}
                                                                        />
                                                                }
                                                                {
                                                                    item.fieldValue.some(fieldItem => fieldItem === -1)  &&
                                                                    <div class="editor-layout" style={{marginTop:15}}>
                                                                        <ReactQuill
                                                                            placeholder={item.placeholderValue}
                                                                            value={this.state.formCustomRisk} 
                                                                            onChange={this.handleChangeFormCustomRisk}
                                                                            className={this.state.formCustomRisk_isFocused?
                                                                                (item.isNRChecked === "Y"?"two-lines":"two-lines-focus"):"two-lines"}
                                                                            modules={this.modules}
                                                                            onFocus={this.onCustomRiskFocus}
                                                                            onBlur={this.onCustomRiskBlur}
                                                                            readOnly={item.isNRChecked === "Y" || !this.state.isEdit ? true : false}
                                                                        />
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                                                    !item.isFromRiskMaster &&
                                                    <div className="row custom-row">
                                                        <div className="col-md-12 custom-col">
                                                            <div className={item.isNRChecked === "Y"?"editor-layout editor-disabled":"editor-layout"}>
                                                                {!this.state.isEdit
                                                                    ? <RegularDropdown 
                                                                        placeholder={!isEmptyArray(item.fieldValue) ? item.fieldValue.join(", ") : item.placeholderValue}
                                                                        dropdownArr={[]}
                                                                        disabled={true}
                                                                    />
                                                                    : <MultiSelectComponentTemplate
                                                                        templateItemIndex={index}
                                                                        allowFiltering={true}
                                                                        fields = {{text:"value",value:"value"}}
                                                                        dropdownValues={item.dropdownValues}
                                                                        placeholderValue={item.placeholderValue} 
                                                                        addSelectedValues={this.addSelectedValues}
                                                                        removeSelectedValues={this.removeSelectedValues}
                                                                        fieldValue={!isEmptyArray(item.fieldValue)?item.fieldValue:""}
                                                                        handleFocus = {this.handleFocus}
                                                                    />
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    item.fieldType === TemplateConstants.FIELD_TYPE_DATE &&
                                                    <div className="row custom-row">
                                                        <div className="col-md-12 custom-col">
                                                            <div className={item.isNRChecked === "Y"?"editor-layout editor-disabled":"editor-layout"}>
                                                                <DatePickerComponent
                                                                    templateItemIndex={index}
                                                                    onDateChange={this.onDateChange}
                                                                    format={"DD/MM/YYYY"}
                                                                    placeholderValue={item.placeholderValue}
                                                                    isNRChecked={item.isNRChecked}
                                                                    fieldValue={!isEmptyVariable(item.fieldValue)?item.fieldValue:""}
                                                                    isDisabled={this.state.isEdit ? "N" : "Y"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    item.fieldType === TemplateConstants.FIELD_TYPE_TABLE &&
                                                    <div className="flex-center-layout" style={{justifyContent:"center"}}>
                                                        {
                                                            !item.isTableAdded && this.state.isEdit &&
                                                            <AddNewButtonLayout themeSettings={themeSettings}>
                                                                <a onClick={this.onAddTableClick.bind(this,index)} href="javascript:void(0);"
                                                                    disabled={
                                                                        item.isNRChecked === "Y"
                                                                            ? true
                                                                            : false
                                                                    }
                                                                >
                                                                    <span className="material-icons">add_circle</span>
                                                                    <p>Add Table</p>
                                                                </a>
                                                            </AddNewButtonLayout>
                                                        }
                                                        {
                                                            item.isTableAdded &&
                                                            // !isEmptyArray(this.state.columnArray) && !isEmptyArray(this.state.rowArray) && 
                                                            <div
                                                                style={{ width: "100%", marginTop: 10, boxShadow: "none" }}
                                                                class={
                                                                    item.isNRChecked === "Y"
                                                                        ? "editor-layout editor-disabled common-table"
                                                                        : "common-table editor-layout"
                                                                }
                                                            >
                                                                {this.state.isEdit &&
                                                                    <div className="flex-center-layout m-b-10" style={{justifyContent:"right"}}>
                                                                        <button className="flex-center-layout instances-new-row" onClick={this.addNewColumn.bind(this,index)}>
                                                                            <span className="material-icons">add</span>
                                                                            <p>Add New Column</p>
                                                                        </button>
                                                                        <button className="flex-center-layout instances-new-row" onClick={this.addNewRow.bind(this,index)}>
                                                                            <span className="material-icons">add</span>
                                                                            <p>Add New Row</p>
                                                                        </button>
                                                                    </div>
                                                                }
                                                                <table class="table instances-table">
                                                                    {
                                                                        item.fieldValue.map((rowItem, rowIndex) =>  {
                                                                            if(rowIndex === 0){
                                                                                return <thead className="instances-thead">
                                                                                    <tr>
                                                                                    {
                                                                                        rowItem.map((colItem, colIndex) => {
                                                                                            return <th width={colItem === "Action"?"5%":""} className= {colItem === "Action"?"text-center":""}>
                                                                                                <div className="instances-table-header">
                                                                                                    <p
                                                                                                        contenteditable={colItem === "Action" ? "false" : (this.state.isEdit ? "true" : "false")}
                                                                                                        onBlur={this.handleTableInputBlur.bind(this, rowIndex, colIndex,index)}
                                                                                                    >
                                                                                                        {colItem}
                                                                                                    </p>
                                                                                                    {colItem === "Action" ? null : (
                                                                                                        this.state.isEdit &&
                                                                                                        <div className="instances-table-del-column">
                                                                                                            <span
                                                                                                                onClick={this.deleteColumn.bind(this, colIndex,index)}
                                                                                                                className="material-icons"
                                                                                                                style = {{cursor:'pointer'}}
                                                                                                            >
                                                                                                                delete
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            </th>
                                                                                            
                                                                                        })
                                                                                    }
                                                                                    </tr>
                                                                                </thead>
                                                                                    
                                                                            }else{
                                                                                return <tbody className="instances-thead">
                                                                                    <tr>
                                                                                    {
                                                                                        rowItem.map((colItem, colIndex) => {
                                                                                            return <td
                                                                                                className={colItem === "Action" ? "text-center" : ""}
                                                                                                contenteditable={colItem === "Action" ? "false" : (this.state.isEdit ? "true" : "false")}
                                                                                                onBlur={this.handleTableInputBlur.bind(this, rowIndex, colIndex,index)}
                                                                                            >
                                                                                                {
                                                                                                    colItem === "Action" ? (
                                                                                                        this.state.isEdit && 
                                                                                                        <div className="instances-table-del-cell">
                                                                                                            <span
                                                                                                                onClick={this.deleteRow.bind(this,rowIndex,index)}
                                                                                                                className="material-icons"
                                                                                                                style = {{cursor:'pointer'}}
                                                                                                            >
                                                                                                                delete
                                                                                                            </span>
                                                                                                        </div>
                                                                                                ) : (
                                                                                                    colItem
                                                                                                )}
                                                                                            </td>
                                                                                        })
                                                                                    }
                                                                                    </tr>
                                                                                </tbody>
                                                                            }

                                                                        })
                                                                    }
                                                                    
                                                                </table>
                                                                {this.state.isEdit &&
                                                                    <div className="addnew-row-wrapper">
                                                                        <button className="flex-center-layout instances-new-row" onClick={this.handleDeleteTableClickAlert.bind(this,item.fieldName,index)}>
                                                                            <span className="material-icons">delete</span>
                                                                            <p>Delete Table</p>
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    !isEmptyVariable(item.valueErr) &&
                                                    <span className="cm-error">{item.valueErr}</span>
                                                }
                                            </div>
                                        )
                                    })
                                }
                                {
                                    this.props.isEditable && (
                                        this.state.isEdit 
                                        ? <div className="common-close-save m-b-20">
                                            <div className="common-close-save-col">
                                                <button onClick={this.cancelEditFields} type="button" className="common-close">Cancel</button>
                                                <PageSaveButton
                                                    themeSettings={themeSettings}
                                                    onClick={this.saveFields}
                                                    type="button"
                                                >
                                                    Save
                                                </PageSaveButton>
                                            </div>
                                        </div>
                                        : <div className="text-center">
                                            <PageSaveButton
                                                themeSettings={themeSettings}
                                                onClick={this.editFields}
                                                type="button"
                                            >
                                                Edit
                                            </PageSaveButton>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                }
                <AlertWithInput
                    showAlertDialog={this.state.showAlertWithInputDialog}
                    handleAlertDialogClose={this.handleAlertWithInputDialogClose}
                    alertDialogHeading={"Instances"}
                    alertDialogMessage={this.state.alertWithInputDialogMessage}
                    proceedBtnClick={this.handleAddIntanceTable}
                    proceedBtnLabel={"Create"}
                    themeSettings={themeSettings}
                />
                <AlertDialog 
                    showAlertDialog={this.state.showAlertDialog}
                    handleAlertDialogClose={this.handleAlertDialogClose}
                    type={
                        (this.state.operationType === "Delete")
                        ?
                        Constants.ALERT_TYPE_WARNING
                        :
                        Constants.ALERT_TYPE_INFO
                    }
                    alertDialogMessage={this.state.alertDialogMessage}
                    proceedBtnClick={
                        (this.state.operationType === "Delete")
                        ?
                        this.handleDeleteTable
                        :
                        ""
                    }
                    proceedBtnLabel={this.state.proceedBtnLabel }
                    themeSettings={themeSettings}
                />
                <AlertDialog
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type={Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                    themeSettings={themeSettings}
                />
            </div>
        )
    }
}
