import React, { Component } from 'react';
import * as Constants from '../Global/constants';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import {getLocalStorageVariables,isEmptyVariable,getThemeSettingsVariables,
    GetSidebarMasterLink, GetSidebarTemplateLink,
    GetSidebarSettingLink} from '../Global/commonFunctions';
import {SidebarCollapseWrapper} from './sidebarCollapse.style';
import { matchPath,withRouter } from 'react-router-dom';
import { ProfileIcon } from '../Global/globalStyles.style';

let userDetails  = "";
let themeSettings  = getThemeSettingsVariables();
class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSidebar:false,
            menus:"",
            company:"",
        }
        userDetails  = getLocalStorageVariables();
        themeSettings  = getThemeSettingsVariables();
    }

    componentDidMount(){
        this.getMenu();
    }

    componentDidUpdate(){
        themeSettings  = getThemeSettingsVariables();
    }

    toggleSidebar = () => {
        this.setState({
            showSidebar: !this.state.showSidebar
        })
    }
    /********************** API CALLS **********************/

    getMenu = () => {
        fetch(Constants.GetUserMenu,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    menus:data.data.menus,
                    company:data.data.company
                })
            }else{
                this.setState({
                    menus:""
                })
            }
        });
    }

    render() {
        const userDetails  = getLocalStorageVariables();
        let fullName = userDetails.firstName+" "+userDetails.lastName
        let initialIcon = fullName.split(" ").map(name => name[0]).join('').toUpperCase();
        return(
            <Router>
                <SidebarCollapseWrapper
                    sidebarBg = {themeSettings.sidebarBg}
                    sidebarBorderRight = {themeSettings.sidebarBorderRight}
                    sidebarTextColor={themeSettings.sidebarTextColor}
                    sidebarIconColor={themeSettings.sidebarIconColor}
                    sidebarSelectedItemBg={themeSettings.sidebarSelectedItemBg}
                    sidebarSelectedItemTextColor={themeSettings.sidebarSelectedItemTextColor}
                    sidebarSelectedItemIconColor={themeSettings.sidebarSelectedItemIconColor}
                    sidebarHoverItemBg={themeSettings.sidebarHoverItemBg}
                    sidebarHoverItemTextColor={themeSettings.sidebarHoverItemTextColor}
                    sidebarHoverItemIconColor={themeSettings.sidebarHoverItemIconColor}
                    themeColor={themeSettings.themeColor}
                    themeTextColor={themeSettings.themeTextColor}
                >
                    <div className={this.state.showSidebar?"vertical-menu verticalmenu-collapse":"vertical-menu"}>
                        <button className="toggle-menu-bar" onClick={this.toggleSidebar}>
                            <span className="material-icons">{this.state.showSidebar?"arrow_back_ios_new":"arrow_forward_ios"}</span>
                        </button>

                        <div className="vertical-menu-wrapper">
                            <div className="vertical-menu-content">
                                {/* <div className="verticalmenu-close">
                                    <span className="material-icons">close</span>
                                </div> */}
                                <div className="vertical-menu-col">
                                    <div className="vertical-menu-profile">
                                        <div className="client-profile-bg">
                                            {
                                                isEmptyVariable(this.state.company?.companyLogo) &&
                                                <img className="logo" src="/assets/images/client-logo.png" />
                                            }
                                            {
                                                !isEmptyVariable(this.state.company?.companyLogo) &&
                                                <img className="logo" src={Constants.ImageBaseUrl+this.state.company.companyLogo} />
                                            }
                                            <div className="client-profile">
                                                <a href={Constants.URL_PROFILE}>
                                                    <h6 className="m-b-0">{isEmptyVariable(userDetails)?"":userDetails.firstName+" "+userDetails.lastName}</h6>
                                                </a>
                                            </div>
                                        </div>
                                        <a href={Constants.URL_PROFILE}>
                                            {
                                                isEmptyVariable(userDetails.profilePic)
                                                ?
                                                <div className={this.state.showSidebar?"account_circle_collapse":"account_circle"}>
                                                    <h6>{initialIcon}</h6>
                                                </div>
                                                :
                                                <img className={this.state.showSidebar?"profile_collapse_img":"profile_img"}src={Constants.ImageBaseUrl+userDetails.profilePic} />
                                            }
                                        </a>
                                        <hr 
                                        style={{background:`rgba(${ themeSettings.sidebarDividerColor.r }, ${ themeSettings.sidebarDividerColor.g }, ${ themeSettings.sidebarDividerColor.b }, ${ themeSettings.sidebarDividerColor.a })`}}/>
                                    </div>

                                    <div className="vertical-menu-list-col">
                                        <div className="vertical-menu-list">
                                            <ul>
                                                {
                                                    !isEmptyVariable(this.state.menus['Dashboard']) && this.state.menus['Dashboard'] !== "NONE" &&
                                                    <li className={matchPath(this.props.history.location.pathname, { path: "/dashboard" }) ? "active" : ""}>
                                                        <a href={Constants.URL_DASHBOARD} className="tool" data-title="Dashboard"><span className="material-icons-outlined">dashboard</span> <p>Dashboard</p></a>
                                                    </li>
                                                }
                                                {
                                                    ((!isEmptyVariable(this.state.menus['Plan Assignment(All)']) && this.state.menus['Plan Assignment(All)'] !== "NONE") ||
                                                    (!isEmptyVariable(this.state.menus['Plan Assignment(Manager)']) && this.state.menus['Plan Assignment(Manager)'] !== "NONE") ||
                                                    (!isEmptyVariable(this.state.menus['Schedule Assignment(All)']) && this.state.menus['Schedule Assignment(All)'] !== "NONE") ||
                                                    (!isEmptyVariable(this.state.menus['Schedule Assignment(Manager)']) && this.state.menus['Schedule Assignment(Manager)'] !== "NONE") ||
                                                    (!isEmptyVariable(this.state.menus['Follow Up Assignment']) && this.state.menus['Follow Up Assignment'] !== "NONE")) &&
                                                    <li
                                                    className={matchPath(this.props.history.location.pathname, { path: ["/assignments","/scheduledassignments","/followups"] }) ? "active" : ""}>
                                                    <a href={Constants.URL_SCHEDULED_ASSIGNMENTS} className="tool" data-title="Planning"><span className="material-icons-outlined">emoji_objects</span> <p>Planning</p></a></li>
                                                }
                                                {
                                                    (!isEmptyVariable(this.state.menus['Execute']) !== "NONE" ||
                                                    (!isEmptyVariable(this.state.menus['Follow Up Assignment']) !== "NONE")) &&
                                                    <li 
                                                        className={matchPath(this.props.history.location.pathname, { path: ["/executeassignmentlist","/followups","/executetasklist","/followupstask","/followupsobservation","/addobservation", "/editobservation", "/auditeelist",
                                                            "/observationList", "/samplecheckedlist","/workingpaperslist","/assignmentemployee","/timeline"] }) ? "active" : ""}>
                                                        <a href={Constants.URL_EXECUTEASSIGNMENTS} className="tool" data-title="Execute"><span className="material-icons-outlined">assignment_turned_in</span> <p>Execute</p></a>
                                                    </li>
                                                }
                                                {
                                                    !isEmptyVariable(this.state.menus['Report']) && this.state.menus['Report'] !== "NONE" &&
                                                    <li
                                                    className={matchPath(this.props.history.location.pathname, { path: ["/ongoingassignmentreports","/ongoingobservations","/ongoingsamplechecked","/ongoingworkingpapers","/ongoingtimeline",
                                                        "/draftsamplechecked","/draftworkingpapers","/draftobservations","/drafttimeline","/editdraftobservation","/editongoingobservation","/finalizedobservations","/initialdraftobservations","/completedfollowupobservation","/completedfollowuptask",
                                                        "/ongoingassignmentemployee","/draftassignmentemployee","/finalassignmentemployee","/initdraftassignmentemployee","/finalTimeline","/initdrafttimeline","/finalsamplechecked","/finalworkingpapers"] }) ? "active" : ""}>
                                                        <a href={Constants.URL_ONGOING_REPORTS} className="tool" data-title="Reports"><span className="material-icons-outlined">insert_chart</span> <p>Reports</p></a>
                                                    </li>
                                                }
                                                {
                                                    !isEmptyVariable(this.state.menus['Task']) && this.state.menus['Task'] !== "NONE" &&
                                                    <li
                                                    className={matchPath(this.props.history.location.pathname, { path: ["/tasks","/addtask","/viewtasks","/edittask",
                                                        "/viewfunctiontasks","/drafttasks","/underreviewtasks","/addtaskwithtemplates","/edittaskwithtemplates"] }) ? "active" : ""}>
                                                        <a href={Constants.URL_TASKS} className="tool" data-title="Tasks"><span className="material-icons-outlined">task_alt</span> <p>Tasks</p></a>
                                                    </li>
                                                }
                                                {
                                                    !isEmptyVariable(this.state.menus['Client']) && this.state.menus['Client'] !== "NONE" &&
                                                    <li
                                                        className={matchPath(this.props.history.location.pathname, { path: ["/listclients","/addclient","/editclient"] }) ? "active" : ""}>
                                                        <a href={Constants.URL_CLIENTS} className="tool" data-title="Clients"><span className="material-icons-outlined">supervised_user_circle</span> <p>Clients</p></a>
                                                    </li>
                                                }
                                                {
                                                    !isEmptyVariable(this.state.menus['Company']) && this.state.menus['Company'] !== "NONE" &&
                                                    <li
                                                        className={matchPath(this.props.history.location.pathname, { path: ["/clients"] }) ? "active" : ""}>
                                                        <a href={Constants.URL_COMPANIES} className="tool" data-title="Clients"><span className="material-icons-outlined">supervised_user_circle</span> <p>Clients</p></a>
                                                    </li>
                                                }
                                                {
                                                    !isEmptyVariable(this.state.menus['Units']) && this.state.menus['Units'] !== "NONE" &&
                                                    <li
                                                        className={matchPath(this.props.history.location.pathname, { path: ["/units"] }) ? "active" : ""}>
                                                        <a href={Constants.URL_UNITS} className="tool" data-title="Units"><span className="material-icons-outlined">business</span> <p>Units</p></a>
                                                    </li>
                                                }
                                                {
                                                    !isEmptyVariable(this.state.menus['User Management']) && this.state.menus['User Management'] !== "NONE" &&
                                                    <li
                                                        className={matchPath(this.props.history.location.pathname, { path: ["/users","/userroles","/adduserrole","/edituserrole","/userdepartments"] }) ? "active" : ""}
                                                        ><a href={Constants.URL_USERLIST} className="tool" data-title="Users"><span className="material-icons-outlined">account_box</span> <p>Users</p></a>
                                                    </li>
                                                }
                                                {
                                                    ((!isEmptyVariable(this.state.menus['Masters']) && this.state.menus['Masters'] !== "NONE") ||
                                                    (!isEmptyVariable(this.state.menus['GeneralMasters']) && this.state.menus['GeneralMasters'] !== "NONE")) &&
                                                    <li
                                                    className={matchPath(this.props.history.location.pathname, { path: ["/processes","/functions","/industries","/documents","/risk","/othermasters","/generalmasters"] }) ? "active" : ""}>
                                                        <a href={GetSidebarMasterLink(this.state.menus)} className="tool" data-title="Masters"><span className="material-icons-outlined">description</span>
                                                        <p>Masters</p></a>
                                                    </li>
                                                }
                                                {
                                                    ((!isEmptyVariable(this.state.menus['Task Template']) && this.state.menus['Task Template'] !== "NONE") ||
                                                    (!isEmptyVariable(this.state.menus['Execution Template']) && this.state.menus['Execution Template'] !== "NONE") ||
                                                    (!isEmptyVariable(this.state.menus['Observation Template']) && this.state.menus['Observation Template'] !== "NONE") ||
                                                    (!isEmptyVariable(this.state.menus['Follow Up Template']) && this.state.menus['Follow Up Template'] !== "NONE")) &&
                                                    this.state.users?.role !== "SuperAdmin" &&
                                                    <li
                                                    className={matchPath(this.props.history.location.pathname, { path: ["/tasktemplates","/executetasktemplates",
                                                    "/observationtemplates","/edittasktemplate","/addobservationtemplate","/tasktemplatedetails","/exectasktemplatedetails","/observationtemplatedetails",
                                                    "/editobservationtemplate","/followuptemplates","/editfollowuptemplate","/followuptemplatedetails","/overalltemplates","/overalltemplatesdetails","/auditeecommenttemplatesdetails","/editauditeecommenttemplates" ] }) ? "active" : ""}>
                                                        <a href={GetSidebarTemplateLink(this.state.menus)} className="tool" data-title="Templates"><span className="material-icons-outlined">post_add</span>
                                                        <p>Templates</p></a>
                                                    </li>
                                                }
                                                {
                                                    ((!isEmptyVariable(this.state.menus['Department Settings']) && this.state.menus['Department Settings'] !== "NONE") || 
                                                    (!isEmptyVariable(this.state.menus['Client Settings']) && this.state.menus['Client Settings'] !== "NONE"))  && this.state.users?.role !== "SuperAdmin" &&
                                                    <li
                                                    className={matchPath(this.props.history.location.pathname, { path: ["/clientsettings","/departmentsettings","/assignmenttypesettings"] }) ? "active" : ""}>
                                                    <a href={GetSidebarSettingLink(this.state.menus)} className="tool" data-title="Settings"><span className="material-icons-outlined">settings</span> <p>Settings</p></a></li>
                                                }
                                                {
                                                    this.state.users?.role === "SuperAdmin" &&
                                                    <li
                                                        className={matchPath(this.props.history.location.pathname, { path: ["/adminSettings"] }) ? "active" : ""}>
                                                        <a href={Constants.URL_ADMIN_SETTINGS} className="tool" data-title="Settings"><span className="material-icons-outlined">settings</span> <p>Settings</p></a>
                                                    </li>
                                                }
                                                <li>
                                                    <a href="/logout" className="tool" data-title="Logout"><span className="material-icons-outlined">exit_to_app</span> <p>Logout</p></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SidebarCollapseWrapper>
            </Router>
        );
    }
}
export default withRouter(Sidebar);