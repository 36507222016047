import React, { Component } from "react";
import * as Constants from "../../common/Global/constants";
import { BrowserRouter as Router } from "react-router-dom";
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import TableDropDown from '../../common/DropdownMenus/TableDropdown';
import {getDropdownActionArray} from '../../common/Global/reviewFunctions';
import Pagination from '../../common/Global/pagination';
import AddGroupingDialog from './addrootgroupingdialog';
import GroupingTreeDialog from "./groupingTree";
import AlertDialog from '../../common/AlertDialog';

// import GroupingTreeDialog from './groupingTree';
import {SearchSpan,AddNewButtonLayout} from '../../common/Global/globalStyles.style';
import {
  isEmptyVariable,
  isEmptyArray,
  GetOrderedMasterTabMenu,
  getStatusSpan,
  getThemeSettingsVariables,
  getLocalStorageVariables,
} from "../../common/Global/commonFunctions";
const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();
const activeStatus = "Active";
const underReviewStatus = "Under Review";
const departmentFilterDropdownDefaultValue = "All";

const actionObjectArr = [
   {
        icon:"edit",
        label:"Edit"
    },
   {
        icon:"delete",
        label:"Delete"
    }
]
export default class unitGrouping extends Component {
constructor(props) {
super(props);
this.state = {
    componentDidMountFlag:false,
        apiSearchKey:"",
        searchkey:"",
        currentPageNo: 1,
        showLoader:false,
        resultSize:Constants.RESULT_SIZE_GRID,
        totalCount:0,
        datalist:[],
        deletePermission:"N",

        statusPlaceholder:"Active",
        resultSizePlaceholder:Constants.RESULT_SIZE_GRID,
        departmentArr:[],
        deptOnly:"Y",
        actionType:"",

        showAlertDialog:false,
        operationType:"",
        deleteReactivateId:"",
        reviewLevel:"",
        alertDialogMessage:"",
        alertDialogHeading:"",
        proceedBtnLabel:"",
        showBtnLoader:false,

        showAlertDialogInfo:false,
        alertDialogMessageInfo:"",

        showUpdateReviewDialog:false,
        showReviewHistoryDialog:false,

        status:activeStatus,
        statusArr:[
            {
                label:activeStatus,
                isSelected:true
            },
            {
                label:underReviewStatus,
                isSelected:false
            },
        ],
        resultSizeArr: JSON.parse(JSON.stringify(Constants.RESULT_SIZE_GRID_DD_ARR)),

        showDetailsDialog:false,
        selectedItem:"",

        unitGroupingName:"",
        selectedId:"",
        rootGroupingId:"",
        showGroupingTreeDialog:false,

        idToBeExpandedAfterAddingChild:"",
        functionMastersInfoFlag:userDetails.functionMastersInfoFlag,

        companyType:"",
        isClient:"N",
        companyDetial:[],

};
}
componentDidMount() {
this.getInitData();
}
handleGroupingTreeDialogClose = (reloadFlag) => {
    this.setState({
        showGroupingTreeDialog:false,
        rootGroupingId:""
    },()=>{
        if(reloadFlag === true) this.getGroupingMasterList();
    })
}

/// keep this for grouping
showGroupingTreeDialog = (item) =>{
 
    this.setState({
        showGroupingTreeDialog:true,
        rootGroupingId:item.unitGroupId,
        rootNode:item,
        isClient:item.isClient
    })
}

handleCheck = (e) =>{
    let isChecked = e.target.checked;

    if(isChecked){
        this.setState({
            deptOnly:"Y"
        },()=>{
            this.getGroupingMasterList()
        })
    }else{
        this.setState({
            deptOnly:"N"
        },()=>{
            this.getGroupingMasterList()
        })
    }

}

handleChangeSearch = (e) => {
    const { name, value } = e.target;

    this.setState({ 
        [name]: value
    });
}

/// keep this for grouping
onSearchIconClick = () => {
    this.setState({
        currentPageNo:1
    },()=>{
        this.getGroupingMasterList();
    })
}

/// keep this for grouping
onEnterBtnPress = (e) => {
    var code = e.keyCode || e.which;
    if(code === 13){
        this.setState({
            currentPageNo:1
        },()=>{
            this.getGroupingMasterList();
        })
    }
}

handleAlertDialogClose = () =>{
    this.setState({
        showAlertDialog:false,
        alertDialogMessage:""
    });
}

handleAlertDialogCloseInfo = () =>{
    this.setState({
        showAlertDialogInfo:false,
        alertDialogMessageInfo:""
    });
}

handleUpdateReviewDialogClose = (reloadFlag) =>{
    this.setState({
        showUpdateReviewDialog:false,
        deleteReactivateId:"",
        reviewLevel:"",
    },()=>{
        if(reloadFlag === true){
            this.getGroupingMasterList();
        }
    });
}

handleReviewHistoryDialogClose = () =>{
    this.setState({
        showReviewHistoryDialog:false,
        deleteReactivateId:"",
    });
}

handleCreateDialogClose = () =>{
    this.setState({
        showCreateDialog:false,
        isEdit:false,
        selectedId:"",
        name:"",
        shortName:"",
        description:"",
    });
}

handleDetailsDialogClose = () =>{
    this.setState({
        showDetailsDialog:false,
        selectedItem:"",
    });
}

handleDetailsDialogShow = (item) => {
    this.setState({
        showDetailsDialog:true,
        selectedItem:item,
    });
}
/// keep this for grouping
statusDropdownClick = (item,selectedIndex) => {
    let tempArr = this.state.statusArr;
    let placeholder = "";

    for(const [i,value] of tempArr.entries()){
        if(i === selectedIndex){
            tempArr[i].isSelected = true;
            placeholder = value.label;
        }else{
            tempArr[i].isSelected = false;
        }
    }
    
    this.setState({
        currentPageNo:1,
        statusArr:tempArr,
        status:item.label,
        statusPlaceholder:placeholder
    }, () => {
        this.getGroupingMasterList();
    })
}


/// keep this for grouping
resultSizeDropdownClick = (item,selectedIndex) => {
    let tempArr = this.state.resultSizeArr;
    let placeholder = "";

    for(const [i,value] of tempArr.entries()){
        if(i === selectedIndex){
            tempArr[i].isSelected = true;
            placeholder = value.label ;
        }else{
            tempArr[i].isSelected = false;
        }
    }
    
    this.setState({
        currentPageNo:1,
        resultSizeArr:tempArr,
        resultSize:item.label,
        resultSizePlaceholder:placeholder
    },() => {
        this.getGroupingMasterList();
    })
} 

deptFilterDropdownClick = (item,selectedIndex) => {
    let placeholder = "";
    let id = "";
    let filterDeptArr = this.state.filterDeptArr;

    for(const [i,value] of filterDeptArr.entries()){
        if(i === selectedIndex){
            filterDeptArr[i].isSelected = true;
            placeholder = value.name;
            id = value.departmentId;
        }else{
            filterDeptArr[i].isSelected = false;
        }
    }
    
    this.setState({
        currentPageNo: 1,
        selectedFilterDepartmentName:placeholder,
        selectedFilterDepartmentId:id
    },() => {
        this.getGroupingMasterList();
    })
}

//When user clicks on add new from search and filters layout(Change required in 3 functions)
/// keep this for grouping
handleCreateDialogShow = () => {
    this.setState({
        showCreateDialog:true,

        isEdit:false,
        selectedId:"",
        unitGroupingName:"",
        rootGroupingId:""
    });
}

handleAddEditDialogClose = (reloadFlag) => {
    this.setState({
        isEdit:false,
        showCreateDialog:false,
    },()=>{
        if(reloadFlag === true) this.getGroupingMasterList();
    })
}

/// keep this for grouping
onTableDropDownItemClick = (item, dropdownItem) => {
    if(item.label === "Delete"){
        this.setState({
            operationType:item.label,
            alertDialogHeading:"Delete Grouping",
            proceedBtnLabel:"Delete",
            deleteReactivateId:dropdownItem.unitGroupId,
            showAlertDialog:true,
            alertDialogMessage:"Are you sure you want to delete unitGrouping "+dropdownItem.unitGroupName+"?"
        })
    }else if(item.label === "Edit"){     
        this.setState({
            isEdit:true,
            selectedId:dropdownItem.unitGroupId,
            unitGroupingName:dropdownItem.unitGroupName,
            rootGroupingId:dropdownItem.unitGroupId,
            showCreateDialog:true,
            isClient:dropdownItem.isClient,
            companyDetial:dropdownItem.companyDetial
        });
    }

}
     //copy data dialog
     showCopyFunctionDialog = () => {
        let selectedDepartmentId = this.state.selectedDepartmentId;
        let selectedDepartmentName = this.state.selectedDepartmentName;

        if(this.state.departmentArr.length > 1){
            selectedDepartmentId="";
            selectedDepartmentName="Select Department";
        }

        this.setState({
            showCopyFunctionDialog:true,
           
            masterType:"Select Master Type",
            selectedDepartmentId:selectedDepartmentId,
            selectedDepartmentName:selectedDepartmentName,
            submitClickedAtleastOnce:false,
            // formErrors:{}
        });
    }
    handleCopyFunctionClose = () =>{
        this.setState({
            showCopyFunctionDialog:false,
           
        },()=>{
            this.getGroupingMasterList();
        });
    }

handleCloseFunctionMastersInfo = () =>{

    this.setState({
        operationType:"Close Message",
        alertDialogHeading:"Close Message",
        proceedBtnLabel:"Okay",
        showAlertDialog:true,
        alertDialogMessage:"Are you sure you want to close message?"
    });
}

handleAlertDialogClose = () =>{
    this.setState({
        showAlertDialog:false,
        alertDialogMessage:""
    });
}

  getInitData = () => {
    this.setState({
        showLoader:true,
    });

    Promise.all([
        fetch(Constants.GetGroupingCreateMasterData,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
            })
        }),
        fetch(Constants.GetRootGrouping,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                status_new:this.state.status,
                departmentOnly:this.state.deptOnly,
            })
        }),
        fetch(Constants.GetUserMenu,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
            })
        })
    ])
    .then(([masterRes,generalRes,menuRes]) => { 
        return Promise.all([masterRes.json(),generalRes.json(),menuRes.json()]) 
    })
    .then(([masterRes,generalRes,menuRes]) => {
        let datalistTemp = [];
        let totalCountTemp = 0;
        let menusTemp = {};
        let deptTemp = [];
        let tempCompanyType="";

        if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
            masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
            localStorage.clear();
            window.location="/";
        }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                deptTemp = masterRes.data.departments;
        }else{
            deptTemp = [];
        }

        if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
            generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
            localStorage.clear();
            window.location="/";
        }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
            datalistTemp = generalRes.data.result;
            totalCountTemp = generalRes.data.count;
        }else{
            datalistTemp = [];
        }

        if(menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
            menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
            localStorage.clear();
            window.location="/";
        }else if(menuRes.responseCode === Constants.CODE_SUCCESS){
            menusTemp = menuRes.data.menus;
            tempCompanyType = menuRes.data.company.companyType;

        }else{
            menusTemp = {};
        }

        //Order the menu tabs in a specific way
        let menusTemp2 = GetOrderedMasterTabMenu(menusTemp)
        let deletePermission = "N";
        if(menusTemp["Delete Grouping"] === "ALL"){
            deletePermission = "Y";
        }

        //Filter Dept array
        let filterDeptArr = [];
        let selectedFilterDepartmentId = "-100";
        let selectedFilterDepartmentName = "All";
        if(!isEmptyArray(deptTemp) && deptTemp.length===1){

        }else{
            let obj = {
                departmentId:"-100",
                name:departmentFilterDropdownDefaultValue,
                isSelected:true
            }
            filterDeptArr = [...deptTemp];
            filterDeptArr.splice(0,0,obj)
        }

        this.setState({
            componentDidMountFlag:true,
            showLoader:false,

            datalist:datalistTemp,
            totalCount:totalCountTemp,
            menus:menusTemp2,
            departmentArr:deptTemp,
            deletePermission:deletePermission,

            filterDeptArr:filterDeptArr,
            selectedFilterDepartmentId:selectedFilterDepartmentId,
            selectedFilterDepartmentName:selectedFilterDepartmentName,

            companyType:tempCompanyType

        })
    })
}
getGroupingMasterList = () => {
    this.setState({
        showLoader:true,
    });

    let postParam = {
        email:userDetails.email,
        accessToken:userDetails.accessToken,
        pageNo:this.state.currentPageNo,
        search:this.state.searchkey,
        resultsize:this.state.resultSize,
        status_new:this.state.status,
    }

    if(this.state.filterDeptArr.length > 1 &&
        this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
        postParam.departmentId = this.state.selectedFilterDepartmentId;
    }

    fetch(Constants.GetRootGrouping,
    {
        method: "POST",
        mode:'cors',
        body: new URLSearchParams(postParam)
    })
    .then(response => { return response.json(); } )
    .then(data =>
    {
        if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
            data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
            localStorage.clear();
            window.location="/";
        }else if(data.responseCode === Constants.CODE_SUCCESS){
            this.setState({
                showLoader:false,
                datalist:data.data.result,
                totalCount:data.data.count,
                apiSearchKey:this.state.searchkey,
              
            });
        }else{
            this.setState({
                datalist:[],
                showLoader:false,
                totalCount:0,
                componentDidMountFlag:true,
                apiSearchKey:this.state.searchkey,
            });
        }
    });
}

handleRecordOperation = () => {
    let url = "";
    let postParam = {
        email:userDetails.email,
        accessToken:userDetails.accessToken,
    }
    let callApi = true;
    

    if(this.state.operationType === "Delete"){
        url = Constants.DeleteGrouping;
        postParam.unitGroupId=this.state.deleteReactivateId;
        postParam.rootUnitGroupId=this.state.deleteReactivateId;
        postParam.departmentId = this.state.departmentArr[0].departmentId;
        postParam.deleteChildren = "Y";
    }

    if(callApi){
        this.setState({
            showBtnLoader:true,
        });

        fetch(url,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParam)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    deleteReactivateId:"",
                    operationType:"",
                    actionType:"",
                    alertDialogHeading:"",
                    proceedBtnLabel:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                    showBtnLoader:false
                },() => {
                    this.getGroupingMasterList();
                });
            }else{
                this.setState({
                    showBtnLoader:false,
                    deleteReactivateId:"",
                    operationType:"",
                    actionType:"",
                    alertDialogHeading:"",
                    proceedBtnLabel:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                })
            }
        });
    }
}

onChangePage = (page) => {
    // update state with new page of items
    if(page !== this.state.currentPageNo){
        this.setState({
            showLoader:true,
        });

        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:page,
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            status_new:this.state.status,
        }

        if(this.state.filterDeptArr.length > 1 &&
            this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
            postParam.departmentId = this.state.selectedFilterDepartmentId;
        }


        fetch(Constants.GetRootFunctions,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParam)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                    currentPageNo:page,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }
}



  render() {
    return (
      <Router>
        <section>
        {this.state.showLoader && (
            <div class="loader"></div>
        )}
        {
            // Need to check component did mount flag, other wise until api is called,
            // it shows no items found layout.
            this.state.componentDidMountFlag && (
              <div className="body-wrapper">
                <div className="search-addnew-bg">
                  <div className="search-addnew">
                    <div className="search-filter-layout">
                      <FilterDropDown
                        placeholder={this.state.resultSizePlaceholder}
                        dropdownArr={this.state.resultSizeArr}
                        onDropDownItemClick={this.resultSizeDropdownClick}
                        themeSettings={themeSettings}
                        mleft={0}
                      />
                      <div className="search-col m-l-10">
                        <input
                          name="companySearchKey"
                          type="text"
                          onChange={this.handleChange}
                          placeholder="Search"
                          onKeyPress={this.onEnterBtnPress}
                          value={this.state.companySearchKey}
                        />
                        <SearchSpan
                          themeSettings={themeSettings}
                          onClick={this.onSearchIconClick}
                          className="material-icons"
                        >
                          {" "}
                          search{" "}
                        </SearchSpan>
                      </div>
                    
                    </div>
                    <div className="flex-center-layout" >
                    
                        <AddNewButtonLayout themeSettings={themeSettings}>
                            <a onClick={this.handleCreateDialogShow} href="javascript:void(0);">
                            <span className="material-icons">add_circle</span>
                            <p>Add New</p>
                        </a>
                        </AddNewButtonLayout>
                    </div>
                  </div>

                        <div className="row custom-row-5 my-2">
                        {!isEmptyArray(this.state.datalist) && 
                        this.state.datalist.map((item,idx) => {
                            
                           
                            let marTop = 10;
                            if(idx === 0 || idx === 1 || idx === 2){marTop=0;}
                            return <div className="col-md-4 custom-col-5">
                                        <div onClick={this.showGroupingTreeDialog.bind(this,item)}
                                        className="tree-list-card-layout c-pointer" style={{marginTop:marTop}}>
                                            <div className="tree-card-text-layout">
                                                <div className="col">
                                                <p className="font-bold font-bold-resize">
                                                    {item.unitGroupName}<br/>
                                                </p>
                                               { this.state.companyType!==Constants.USER_ENGAGEMENT_TYPE_SELF && <p style={{fontSize:"13px"}}>{item.isClient==="Y"?"Client":"Unit's"}{item.isClient!=="Y"?" of "+Object.values(item.companyDetial)[0].companyName:""}
                                                </p>}
                                                </div>
                                               
                                                {!isEmptyArray(this.state.departmentArr) && this.state.departmentArr.length > 1 && 
                                                    <span>{item.deptName}</span>
                                                }
                                            </div>
                                            {
                                                // actionObj.actionArr && actionObj.actionArr.length > 0 &&
                                                <div className="action-dd-layout">
                                                    <TableDropDown 
                                                        actionArr={actionObjectArr}
                                                        onDropDownItemClick = {this.onTableDropDownItemClick}
                                                        dropDownId = {item}
                                                        themeSettings={themeSettings}
                                                    />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                        {
                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                            <div class="no-items-layout">
                                <div class="no-items-card">
                                    <h6>
                                    {
                                    isEmptyVariable(this.state.apiSearchKey)?Constants.NO_RECORDS_WARNING:Constants.EMPTY_SEARCH_WARNING
                                    }
                                    </h6>
                                </div>
                            </div>
                        }
                        <div className="pagination-layout">
                            <div className="row custom-row">
                                <div className="col-md-9 custom-col">
                                {
                                    (this.state.totalCount > this.state.datalist.length) && 
                                    <Pagination 
                                        totalLength ={this.state.totalCount} 
                                        items={this.state.datalist} 
                                        onChangePage={this.onChangePage} 
                                        pageSize={this.state.resultSize}
                                        currentPageNo = {this.state.currentPageNo}
                                        initialPage={this.state.currentPageNo} 
                                        themeSettings={themeSettings}/>
                                }
                                </div>
                                <div className="col-md-3 custom-col">
                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                </div>
                            </div>
                        </div>
                    </div>
               
                </div>
            )
          }

            <GroupingTreeDialog
                showGroupingTreeDialog={this.state.showGroupingTreeDialog}
                handleGroupingTreeDialogClose={this.handleGroupingTreeDialogClose}
                rootGroupingId={this.state.rootGroupingId}
                rootNode={this.state.rootNode}
                idToBeExpandedAfterAddingChild={this.state.idToBeExpandedAfterAddingChild}
                themeSettings={themeSettings}
                newFlag = {"Y"}
                companyType ={this.state.companyType}
                isClient ={this.state.isClient}
                departmentArr = {this.state.departmentArr}
            />
              <AddGroupingDialog
                isEdit={this.state.isEdit}
                showCreateDialog={this.state.showCreateDialog}
                handleAddEditDialogClose={this.handleAddEditDialogClose}
                createNodeType={"root"}
                departmentArr={this.state.departmentArr}
                selectedId={this.state.selectedId}
                groupingName={this.state.unitGroupingName}
                rootGroupingId={this.state.rootGroupingId}
                companyType ={this.state.companyType}
                themeSettings={themeSettings}
                isClient={this.state.isClient}
                companyDetial={this.state.companyDetial}
            />

       
            <AlertDialog
                showAlertDialog={this.state.showAlertDialog}
                handleAlertDialogClose={this.handleAlertDialogClose}
                type={
                    (this.state.operationType === "Delete")
                    ?
                    Constants.ALERT_TYPE_WARNING
                    :
                    (this.state.operationType === "Review Remarks")
                    ?
                    Constants.ALERT_TYPE_ALERT
                    :
                    Constants.ALERT_TYPE_INFO
                }
                alertDialogHeading={this.state.alertDialogHeading}
                alertDialogMessage={this.state.alertDialogMessage}
                proceedBtnClick={this.handleRecordOperation}
                proceedBtnLabel={this.state.proceedBtnLabel}
                showLoader={this.state.showBtnLoader}
                themeSettings={themeSettings}

            />



        </section>
      </Router>
    );
  }
}
