import React, { Component } from 'react';
import {isEmptyVariable, SplitJoinString} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import moment from 'moment';

class ProjectDetailsDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    componentDidMount(){
    }

    render() {
        return(
            <Modal className="user-details-dialog custom-dialog" 
            show={this.props.showProjectDetailsDialog} onHide={this.props.handleProjectDetailsDialogClose}>
                <Modal.Header>
                    <h5>{this.props.projectData.projectName+" Details"}</h5>
                    <button 
                        onClick={this.props.handleProjectDetailsDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <Modal.Body>
                    <div className="body-wrapper">
                        {
                            !isEmptyVariable(this.props.projectData.projectId) && 
                            <div className="details-table-layout">
                                <div className="row custom-row">
                                    <div className="col-md-6 custom-col">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td width={"33%"}>Project Name</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{this.props.projectData.projectName}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"33%"}>Project Code</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{this.props.projectData.projectCode}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"33%"}>Description</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{isEmptyVariable(this.props.projectData.description)?"-":this.props.projectData.description}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"33%"}>Project Manager</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{isEmptyVariable(this.props.projectData.projectManagerName)?"-":this.props.projectData.projectManagerName}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-6 custom-col">
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td width={"33%"}>Start Date</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{isEmptyVariable(this.props.projectData.startDate)?"-":moment(this.props.projectData.startDate).format('DD/MM/YYYY')}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"33%"}>End Date</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{isEmptyVariable(this.props.projectData.endDate)?"-":moment(this.props.projectData.endDate).format('DD/MM/YYYY')}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"33%"}>Units</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{isEmptyVariable(this.props.projectData.unitList)?"-":SplitJoinString(this.props.projectData.unitList, ";;", ":-:", ", ")}</td>
                                                </tr>
                                                <tr>
                                                    <td width={"33%"}>Employees</td>
                                                    <td width={"4%"}>:</td>
                                                    <td width={"63%"}>{isEmptyVariable(this.props.projectData.employeeList)?"-":SplitJoinString(this.props.projectData.employeeList, ";;", ":-:", ", ")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ProjectDetailsDialog;