import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Sidebar from "../../common/Sidebar";
import { AddNewButtonLayout, AssigmentDetailsIconLayout, DialogSaveButton } from "../../common/Global/globalStyles.style";
import SettingTopMenu from "./topmenu";
import Topbar from "../../common/Topbar";
import { FilterCheckBox } from "../../common/Global/globalStyles.style";
import { getThemeSettingsVariables, getLocalStorageVariables, isEmptyArray, isEmptyVariable } from "../../common/Global/commonFunctions";
import * as Constants from '../../common/Global/constants';
import AlertDialog from "../../common/AlertDialog";
import RegularDropdown from "../../common/DropdownMenus/RegularDropdown";
import { Modal } from "react-bootstrap";
const themeSettings = getThemeSettingsVariables();
let userDetails = getLocalStorageVariables();
const assignmentTypeDropdownDefaultValue = "Select Assignment Type";
const departmentDropdownDefaultValue = "Select Department";

let assignmentTypeDefaultSettings = {
    disabledRisk:{
        settingColumn: "disabledRisk",
        settingDisplayColumn: "Disable Risk",
        settingColumnEnabled: "N",
        settingValue: "",
        showCheckbox: "Y",
    },
    disabledLikelihood: {
        settingColumn: "disabledLikelihood",
        settingDisplayColumn: "Disable Likelihood",
        settingColumnEnabled: "N",
        settingValue: "",
        showCheckbox:"Y",
    },
    createMultipleRisk: {
        settingColumn: "createMultipleRisk",
        settingDisplayColumn: "Create Multiple Risk",
        settingColumnEnabled: "N",
        settingValue: "",
        showCheckbox: "Y",
    }
}
export default class assignmentTypeSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag: false,
            showLoader: false,
            showBtnLoader: false,
            formErrors: {},
            menus: "",
            showAlertDialogInfo:false,
            showFilter:false,
            alertDialogMessageInfo:"",
            selectedDepartmentId:"",
            selectedassignmentTypeId:"",
            selectedDepartmentName:departmentDropdownDefaultValue,
            selectedassignmentType:assignmentTypeDropdownDefaultValue,
            submitClickedAtleastOnce:false,
            showDialog:false
        }
    }

    componentDidMount() {
        this.getUserMenu();
    }

    // This function to handle checkbox click events
    handleCheckBoxClick = (e) => {
        let isChecked = e.target.checked;
        const { name } = e.target;

        let assignmentTypeSettingsTemp = this.state.assignmentTypeSettings;
        assignmentTypeSettingsTemp[name]["settingColumnEnabled"] = isChecked?"Y":"N";

        // If Risk Disable then Likelihood must be disable
        if(assignmentTypeSettingsTemp[name].settingColumn === Constants.DISABLEDRISK && assignmentTypeSettingsTemp[name]["settingColumnEnabled"] === "Y"){
            assignmentTypeSettingsTemp[Constants.DISABLEDLIKELIHOOD]["settingColumnEnabled"] = "Y"
        }

        // User can't change Likelihood setting while Risk is Disable 
        if(assignmentTypeSettingsTemp[Constants.DISABLEDRISK]["settingColumnEnabled"] === "Y" &&
            assignmentTypeSettingsTemp[name].settingColumn === Constants.DISABLEDLIKELIHOOD && 
            assignmentTypeSettingsTemp[name]["settingColumnEnabled"] === "N"){
            assignmentTypeSettingsTemp[Constants.DISABLEDLIKELIHOOD]["settingColumnEnabled"] = "Y"
        }

        this.setState({
            assignmentTypeSettings: assignmentTypeSettingsTemp,
        });
    }

    // Handle Department Dropdown selection based on that API call for getting Assignment Type
    deptDropdownClick = (item) => {
        let FormErrorsTemp = this.state.formErrors;

        if (this.state.submitClickedAtleastOnce) {
            FormErrorsTemp['department'] = "";
        }

        this.setState({
            selectedDepartmentName: item.name,
            selectedDepartmentId: item.departmentId,
            formErrors: FormErrorsTemp
        }, () => {
            this.getUserAllAssignmentTypes();
        })
    }

    // Handle Assignment Type Dropdown
    assignmentTypeDropdownClick = (item) => {
        let FormErrorsTemp = this.state.formErrors;

        if (this.state.submitClickedAtleastOnce) {
            FormErrorsTemp['assignmentType'] = "";
        }

        this.setState({
            formErrors: FormErrorsTemp,
            selectedassignmentType: item.name,
            selectedassignmentTypeId: item.assignmentTypeId,
        })
    }

    // Handle Info dialog close event
    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
    }

    // Edit Department and Assignment Type selection 
    updateSetting = () => {
        // Set initial Value inside modal if Department not one
        if(this.state.departmentsArr.length !== 1){
            this.setState({
                selectedDepartmentId:"",
                selectedDepartmentName:departmentDropdownDefaultValue,
                assignmentTypeArr:[]
            })
        }
        this.setState({
            showDialog:true, // Show Department and Assignment dropdown in modal
            selectedassignmentTypeId:"",
            selectedassignmentType:assignmentTypeDropdownDefaultValue,
        })
    }

    // Handle modal close event
    handleDialogClose = () => {
        this.setState({
            showDialog:false
        })
    }

    // Save selection of Department and Assignment Type
    handleSave = () => {
        let isError = false;
        let formErrTemp = {};
        this.setState({
            submitClickedAtleastOnce:true
        });

        if(isEmptyVariable(this.state.selectedDepartmentId)){
            isError = true;
            formErrTemp["department"] = "Please select the department";
        }

        if(!isEmptyArray(this.state.assignmentTypeArr) && isEmptyVariable(this.state.selectedassignmentTypeId)){
            isError = true;
            formErrTemp["assignmentType"] = "Please select the assignment type";
        }
        
        if (isError) {
            this.setState({
                formErrors: formErrTemp,
            });
        } else {
            // Assignment Type is not then clear previous selction value and set message
            if(isEmptyArray(this.state.assignmentTypeArr)){
                this.setState({
                    componentDidMountFlag:false,
                    assignmentType:"",
                    selectedassignmentType:assignmentTypeDropdownDefaultValue,
                    selectedassignmentTypeId:"",
                    showMessage:true,
                    responseMessage:"No assignment type created in selected department.",
                })
            }

            // Set the Department value
            this.setState({
                showDialog:false,
                showFilter:true, // Display selected Department and Assignment Type
                departmentName:this.state.selectedDepartmentName, // Used for the display Department name
            })

            // Set selected assignment type value and call API for getting setting
            if(!isEmptyArray(this.state.assignmentTypeArr)){
                this.setState({
                    assignmentType:this.state.selectedassignmentType, // Used for the display assignment Type name
                    showMessage:false,
                    responseMessage:""
                },()=>{
                    this.getAssignmentTypeSettings();
                })
            }
        }
    }
    /*******************API CALLS*******************/
    getUserMenu = () => {
        fetch(Constants.GetUserMenu, {
            method: "POST",
            mode: 'cors',
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
            }),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            let tempMenusData = ""
            let departmentsArr = [];
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location = "/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                tempMenusData = data.data.menus;
                departmentsArr = data.data.departments;
                
                // If department Arr length one then automatic set department name and Id
                if(departmentsArr.length === 1){
                    this.setState({
                        // Used for the Department dropdown selection
                        selectedDepartmentName: departmentsArr[0].name,
                        selectedDepartmentId: departmentsArr[0].departmentId,
                        
                        // Used for the display department name
                        departmentName:departmentsArr[0].name 
                    }, () => {
                        // If department Arr length one then pass true
                        this.getUserAllAssignmentTypes(true);
                    })
                }else{
                    this.setState({
                        showDialog:true
                    })
                }
                this.setState({
                    showLoader: false,
                    menus: tempMenusData,
                    departmentsArr: departmentsArr
                })

            }else{
            }
        })
    }

    getUserAllAssignmentTypes = (reload) => {
        fetch(Constants.GetUserAllAssignmentTypes, {
            method: "POST",
            mode: 'cors',
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                departmentId:this.state.selectedDepartmentId
            })
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            let assignmentTypeArr = [];
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location = "/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                assignmentTypeArr = data.data;

                // Check reload is true and assignmentTypeArr is empty then clear value and set message
                if(reload == true && isEmptyArray(assignmentTypeArr)){
                    this.setState({
                        componentDidMountFlag:false,
                        selectedassignmentType:assignmentTypeDropdownDefaultValue,
                        selectedassignmentTypeId:"",
                        assignmentType:"",
                        showMessage:true,
                        responseMessage:"No assignment type created in selected department."
                    })
                }

                // assignmentTypeArr is one then set AssignmentType and Id
                if(assignmentTypeArr.length === 1){
                    this.setState({
                        // Used for the Assignment Type dropdown selection
                        selectedassignmentType: assignmentTypeArr[0].name,
                        selectedassignmentTypeId: assignmentTypeArr[0].assignmentTypeId,

                        // Used for the display assignment Type name
                        assignmentType:assignmentTypeArr[0].name
                    }, () => {
                        if(!this.state.showDialog){
                            this.getAssignmentTypeSettings();
                        }
                    })
                }else if(assignmentTypeArr.length > 1){
                    this.setState({
                        showDialog:true
                    })
                }
                
                this.setState({
                    assignmentTypeArr: assignmentTypeArr
                })
            }else{
            }
        })
    }

    getAssignmentTypeSettings = () => {
        this.setState({
            showLoader: true
        });

        fetch(Constants.GetAssignmentTypeSettings, {
            method: "POST",
            mode: 'cors',
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                departmentId:this.state.selectedDepartmentId,
                assignmentTypeId:this.state.selectedassignmentTypeId
            })
        })
        .then((response) => {
            return response.json();
        }).then((data) => {
            let tempAssignmentTypeSettings = {};

            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location = "/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                tempAssignmentTypeSettings = data.data.settings;
            
                if (isEmptyArray(Object.keys(tempAssignmentTypeSettings))) {
                    tempAssignmentTypeSettings = assignmentTypeDefaultSettings;
                }
                
            }else{
            }
                
            this.setState({
                componentDidMountFlag: true,
                showLoader: false,
                assignmentTypeSettings: tempAssignmentTypeSettings
            })
        })
    }

    // This function to handle update the settings
    handleSaveFields = () => {
        this.setState({
            showBtnLoader: true,
            submitClickedAtleastOnce:true
        });

        let isError = false;
        let formErrTemp = {};

        if(isEmptyVariable(this.state.selectedDepartmentId)){
            isError = true;
            formErrTemp["department"] = "Please select the department";
        }

        if(isEmptyVariable(this.state.selectedassignmentTypeId)){
            isError = true;
            formErrTemp["assignmentType"] = "Please select the assignment type";
       }

        if (isError) {
            this.setState({
                formErrors: formErrTemp,
                showBtnLoader:false
            });
        } else {
            const updatedSettingsValues = { ...this.state.assignmentTypeSettings };

            Object.keys(updatedSettingsValues).forEach(key => {
                if (updatedSettingsValues[key].hasOwnProperty('showCheckbox')) {
                    delete updatedSettingsValues[key].showCheckbox;
                }
            });

            fetch(Constants.UpdateAssignmentTypeSettings, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    departmentId:this.state.selectedDepartmentId,
                    assignmentTypeId:this.state.selectedassignmentTypeId,
                    updatedValues: JSON.stringify(updatedSettingsValues)
                }),
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    this.setState({
                        showBtnLoader: false,
                        showAlertDialogInfo: true,
                        alertDialogMessageInfo: "Settings updated successfully",
                    }, () => {
                        this.getAssignmentTypeSettings();
                    });
                } else {
                    this.setState({
                        showBtnLoader: false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    })
                }
            });
        }
    };
    /*******************API CALLS END HERE*******************/

    render() {
        return (
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />
                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                {
                                    // this.state.componentDidMountFlag &&
                                    <SettingTopMenu
                                        themeSettings={themeSettings}
                                        menus={this.state.menus}
                                    />
                                }

                                {this.state.showLoader && (
                                    <div class="loader"></div>
                                )}

                                {
                                    // this.state.componentDidMountFlag &&
                                    <div className="body-wrapper">
                                        <div className="row custom-row">
                                            <div className="col-md-12 custom-col">
                                                <div className="card-col"> 
                                                    {this.state.showFilter &&
                                                        <div className="row custom-row">
                                                            <div className="col-md-12 custom-col d-flex align-items-center">
                                                                {!isEmptyVariable(this.state.departmentName) &&
                                                                    <p className="mr-5 mt-2">Department: {this.state.departmentName}</p>
                                                                }
                                                                {!isEmptyVariable(this.state.assignmentType) &&
                                                                    <p className="mr-3 mt-2">Assignment Type: {this.state.assignmentType}</p>
                                                                }
                                                                {((!isEmptyArray(this.state.departmentsArr) && this.state.departmentsArr.length >= 1) || 
                                                                (!isEmptyArray(this.state.assignmentTypeArr) && this.state.assignmentTypeArr.length > 1)) &&
                                                                    <AssigmentDetailsIconLayout themeSettings={themeSettings}>
                                                                        <span className="material-icons" style={{fontSize: 23}} 
                                                                            onClick={() => this.updateSetting()}>edit</span>
                                                                    </AssigmentDetailsIconLayout>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.componentDidMountFlag &&   
                                                        !isEmptyArray(Object.keys(this.state.assignmentTypeSettings)) && 
                                                        Object.values(this.state.assignmentTypeSettings).map((item, index, array) => {
                                                            return (
                                                                <>
                                                                    {index === 0 && <p style={{fontWeight:400, marginBottom: 15, marginTop: 15}}>Use Risk Matrix and likelihood matrix for the assignment type.</p>}
                                                                    {index === 2 && <p style={{fontWeight:400, marginBottom: 15, marginTop:15}}>Create multiple risk matrix for an assignment Type.</p>}
                                                                    <div className="row custom-row" style={{borderBottom: index == 1 ? '1px solid #ccc' : 0}}>
                                                                        <div className="col-md-2 custom-col flex-center-layout">
                                                                            <div className="add-new-form-group">
                                                                                <p>{item["settingDisplayColumn"]}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-8 custom-col">
                                                                            <div className="flex-center-layout">
                                                                                {
                                                                                    item.showCheckbox === "Y" &&
                                                                                    <FilterCheckBox
                                                                                        themeSettings={themeSettings}
                                                                                        style={{ marginLeft: 0, marginBottom: 10 }}
                                                                                    >
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            name={item["settingColumn"]}
                                                                                            id={item["settingColumn"]}
                                                                                            onChange={this.handleCheckBoxClick}
                                                                                            checked={item.settingColumnEnabled === "Y" ? true : false}
                                                                                        />
                                                                                        <label for={item["settingColumn"]}></label>
                                                                                    </FilterCheckBox>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            );
                                                        })
                                                    }
                                                    {!isEmptyVariable(this.state.assignmentType) &&
                                                    <div className="modal-close-save m-t-15">
                                                        <div className="close-save-col">
                                                            <DialogSaveButton
                                                                themeSettings={themeSettings}
                                                                onClick={this.handleSaveFields}
                                                                type="button"
                                                                className="modal-save m-b-0"
                                                            >
                                                                {this.state.showBtnLoader ? <div class="loader-btn mt-2"></div> : 'Save'}
                                                            </DialogSaveButton>
                                                        </div>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    this.state.showMessage && 
                                    <div class="no-items-layout">
                                        <div class="no-items-card">
                                            <h6>
                                            {
                                                this.state.responseMessage
                                            }
                                            </h6>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type={Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                            themeSettings={themeSettings}
                        />
                        
                        <Modal className="custom-dialog" show={this.state.showDialog} 
                            backdrop="static">
                            <Modal.Header>
                                <h5>{"Select Assignment Type"}</h5>
                                <button 
                                    onClick={this.handleDialogClose}
                                    type="button" data-dismiss="modal">
                                    <span class="material-icons">close</span>
                                </button>
                            </Modal.Header>
                            <hr />
                            <div class="modal-body">
                                <div className="row custom-row">
                                {
                                    !isEmptyArray(this.state.departmentsArr) &&
                                    this.state.departmentsArr.length > 1 &&
                                    <div className="col-md-6 custom-col">
                                        <div class="addnew-modal-form-group">
                                            <p>Department*</p>
                                            <RegularDropdown
                                                placeholder={this.state.selectedDepartmentName}
                                                dropdownArr={this.state.departmentsArr}
                                                labelParam="name"
                                                onDropDownItemClick={this.deptDropdownClick}
                                                defaultPlaceholderDropDown={departmentDropdownDefaultValue}
                                            />
                                            {
                                                !isEmptyVariable(this.state.formErrors['department']) &&
                                                <span class="cm-error">{this.state.formErrors['department']}</span>
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    !isEmptyArray(this.state.assignmentTypeArr) &&
                                    this.state.assignmentTypeArr.length > 1 &&
                                    <div className="col-md-6 custom-col">
                                        <div class="addnew-modal-form-group">
                                            <p>Assignment Type*</p>
                                            <RegularDropdown
                                                placeholder={this.state.selectedassignmentType}
                                                dropdownArr={this.state.assignmentTypeArr}
                                                labelParam="name"
                                                onDropDownItemClick={this.assignmentTypeDropdownClick}
                                                defaultPlaceholderDropDown={assignmentTypeDropdownDefaultValue}
                                            />
                                            {
                                                !isEmptyVariable(this.state.formErrors['assignmentType']) &&
                                                <span class="cm-error">{this.state.formErrors['assignmentType']}</span>
                                            }
                                        </div>
                                    </div>
                                }
                                </div>
                                <div class="modal-close-save">
                                    <div class="close-save-col">
                                        <button onClick={this.handleDialogClose} type="button" class="modal-close">Cancel</button>
                                        <DialogSaveButton 
                                            themeSettings={themeSettings}
                                            onClick={this.handleSave} type="button">
                                                Save
                                        </DialogSaveButton>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </section>
                </main>
            </Router>
        );
    }
}
