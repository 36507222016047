import React, { Component } from "react";
import { TaskTagButtonsLayout, PageSaveButton, FilterCheckBox, AddNewButtonLayout } from "../../../common/Global/globalStyles.style";
import Sidebar from "../../../common/SidebarCollapse";
import Topbar from "../../../common/Topbar";
import MasterMenuTabs from "../Common/topmenu";
import { BrowserRouter as Router } from "react-router-dom";
import * as Constants from "../../../common/Global/constants";
import {
	getLocalStorageVariables,
	isEmptyVariable,
	GetOrderedMasterTabMenuExecute,
	getThemeSettingsVariables,
	isEmptyArray,
	ifEmptyReturn,
	isJsonString,
	removeHtmlTags
} from "../../../common/Global/commonFunctions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import RegularDropdown from "../../../common/DropdownMenus/RegularDropdown";
import SelectRiskDialog from "../../Execute/Observation/selectRisk";
import SelectTagTasksDialog from "../../Execute/Observation/selectTagTasks";
import SelectValueAdditionDialog from "../../Execute/Observation/selectValueAddition";
import UploadAnnexureDialog from "../../Execute/Observation/UploadAnnexure";
import UploadBackupDialog from "../../Execute/Observation/UploadBackup";
import AlertWithInput from "../../../common/AlertDialog/alertwithInput";
import AssignmentHeading from "../Common/assignmentHeadingDetails";
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import * as TemplateConstants from "../../../common/Global/templateConstants";
import MultiSelectComponentTemplate from "../../Tasks/Common/multiSelectComponent";
import DatePickerComponent from "../../Tasks/Common/dateSelectComponent";
import AlertDialog from "../../../common/AlertDialog";
import LeftSideSelectDialog from "../../Tasks/Common/selectDropdownValues";

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();
const riskImpactDropdownDefaultValue = "Please select risk/impact";
const mTTagTasks = "Tag Tasks";

class AddEditObservation extends Component {
	constructor(props) {
		super(props);

		let assignmentId = "";
		let observationId = "";
		// let isEditObservation = false;
		
		// if (this.props.location.pathname.includes(Constants.EDITONGOINGOBSERVATION_PATH)) {
			let ids = this.props.match.params.id;

			let arr = ids.split("-");
			// isEditObservation = true;
			assignmentId = arr[1];
			observationId = arr[0];
		// }

		this.state = {
			assignmentId: assignmentId,
			observationId: observationId,
			menus:{},
			user: {},
			businessRisk: [],
			valueAdditions: [],
			deptDefaultRisk: {},
			selectedDepartmentId: "",
			assignmentStatus: "",
			assignmentDetails:"",
			company:{},
			datalist:[],
            datalistLeft:[],
			showLoader: false,
			componentDidMountFlag: false,
			// isEditObservation: isEditObservation,

			showAlertWithInputDialog: false,
			formCustomRisk:"",
            formCustomRisk_isFocused:false,

			//TAGS
			//Tag tasks
			showSelectTagTasksDialog: false,
			selectedTagTasksObjArr: [],
			//Risk
			showSelectRiskDialog: false,
			selectedRiskObj: "",
			//Value Addition
			showSelectVADialog: false,
			selectedVAObj: [],
			//Upload annexure
			showSelectUploadAnnexureDialog: false,
			uploadedAnnexureArr: [],
			annexure_NR:"",
			uploadedBackupArr: [],
			//Upload Backup
			showSelectUploadBackupDialog: false,
			selectedUploadBackupObj: [],
			backup_NR:"",
			showSelectDropdownDialog:false,
            templateFieldItem:{},
			formErrors: {},
			likelihoodRiskMatrix: [],
            selectedLikelihoodRiskId: "",
		};
	}

	componentDidMount() {
		this.initData();
	}

	/************* DIALOGS***************/
	//Tag Tasks
	handleSelectTagTasksDialogClose = () => {
		this.setState({
			showSelectTagTasksDialog: false,
		});
	};
	handleSelectTagTasksDialogSaveClose = (selectedTagTasksObjArr) => {
		this.setState(
			{
				showSelectTagTasksDialog: false,
				selectedTagTasksObjArr: selectedTagTasksObjArr,
			});
	};
	handleSelectTagTasksDialogShow = () => {
		let focusObj = this.handleFocus();
		this.setState({
			showSelectTagTasksDialog: true,

			...focusObj,
		});
	};
	// Risk Impact
	handleSelectRiskDialogClose = () => {
		this.setState({
			showSelectRiskDialog: false,
			selectedLikelihoodRiskId: "",
            isLikelihood:false
		});
	};
	handleSelectRiskDialogSaveClose = (selectedRiskId, likelihoodRiskId) => {
		this.setState({
			showSelectRiskDialog: false,
			// selectedRiskObj: selectedRiskObj,
			selectedRiskId: selectedRiskId,
			selectedLikelihoodRiskId: likelihoodRiskId,
		});
	};
	handleSelectRiskDialogShow = () => {
		let focusObj = this.handleFocus();
		
		let isLikelihood = this.state.allSettings.some(e => e.settingColumn.includes(Constants.DISABLEDLIKELIHOOD) && e.settingColumnEnabled === "N" && 
			e.departmentId === this.state.assignmentDetails.departmentId && e.assignmentTypeId === this.state.assignmentDetails.assignmentTypeId);

		this.setState({
			showSelectRiskDialog: true,
			isLikelihood:isLikelihood,
			...focusObj,
		});
		
	};

	// Value addition
	handleSelectVADialogClose = () => {
		this.setState({
			showSelectVADialog: false,
		});
	};
	handleSelectVADialogSaveClose = (selectedVAObj) => {
		this.setState({
			showSelectVADialog: false,
			selectedVAObj: selectedVAObj,
		});
	};
	handleSelectVADialogShow = () => {
		let focusObj = this.handleFocus();

		this.setState({
			showSelectVADialog: true,

			...focusObj,
		});
	};

	// Upload annexure
	handleSelectUploadAnnexureDialogClose = () => {
		this.setState({
			showSelectUploadAnnexureDialog: false,
		});
	};
	handleSelectUploadAnnexureDialogSaveClose = (uploadedAnnexureArr,annexure_NR) => {
		this.setState({
			showSelectUploadAnnexureDialog: false,
			uploadedAnnexureArr: uploadedAnnexureArr,
			annexure_NR:annexure_NR,
		});
	};
	handleSelectUploadAnnexureDialogShow = () => {
		let focusObj = this.handleFocus();

		this.setState({
			showSelectUploadAnnexureDialog: true,
			...focusObj,
		});
	};

	// Upload backup
	handleSelectUploadBackupDialogClose = () => {
		this.setState({
			showSelectUploadBackupDialog: false,
		});
	};
	handleSelectUploadBackupDialogSaveClose = (uploadedBackupArr,backup_NR) => {
		this.setState({
			showSelectUploadBackupDialog: false,
			uploadedBackupArr: uploadedBackupArr,
			backup_NR:backup_NR,
		});
	};
	handleSelectUploadBackupDialogShow = () => {
		let focusObj = this.handleFocus();

		this.setState({
			showSelectUploadBackupDialog: true,

			...focusObj,
		});
		// }
	};
	//Left Side Dialog Functions
    handleSelectDropdownDialogShow = (item,index) => {
        this.setState({
            showSelectDropdownDialog:true,
            templateFieldItem:item,
            deleteReactivateId:index
        })
    }
    handleSelectDropdownDialogClose = () => {
        this.setState({
            showSelectDropdownDialog:false,
            editTemplateItem:{},
            deleteReactivateId:"",
        })
    }

    dialogSelectedValues = (selectedValObj,arrIndex) => {
        //dialog is for left side options only
        let tempArr = this.state.datalistLeft;
        if(selectedValObj.fieldType === TemplateConstants.FIELD_TYPE_SINGLE_SELECT){
            tempArr[arrIndex].fieldValue = selectedValObj.selectedValues[0];
        }else if(selectedValObj.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT){
            tempArr[arrIndex].fieldValue = selectedValObj.selectedValues;
        }

        this.setState({
            datalistLeft:tempArr,

            showSelectDropdownDialog:false,
            editTemplateItem:{},
            deleteReactivateId:"",
        })
    }

	
	/************* DIALOGS END HERE***************/
	
	handleCheck = (e) => {
		let isChecked = e.target.checked;
        let name = e.target.name;
        let arr = name.split(":-:");
        let tempArr = this.state.datalist;
        let index = parseInt(arr[1]);

        let item = tempArr[index];
        item.isNRChecked = isChecked?"Y":"N";
        tempArr.splice(index,1,item);
        this.setState({
            datalist:tempArr
        })
    }

	handleFocus = () => {
        let tempArr = this.state.datalist.map((item)=>{
            item.isFocused = false
            return item;
        })
        this.setState({
            datalist:tempArr
        })
    }

	handleQuillFocus = (focusIndex) => {
        let tempArr = this.state.datalist.map((item,index)=>{
            if(index === focusIndex){
                item.isFocused = true
            }else{

                item.isFocused = false
            }
            return item;
        })
        this.setState({
            datalist:tempArr
        })
    }
	handleChangeReactQuill = (focusedIndex,value) =>{

        let tempArr = this.state.datalist;
        tempArr[focusedIndex].fieldValue = value;

        this.setState({
            datalist:tempArr
        })
        
    }
	addSelectedValues = (value,index) => {
        
        let tempArr = this.state.datalist;
        tempArr[index].fieldValue.push(value);
        this.setState({
            datalist:tempArr
        })
    }

    removeSelectedValues = (value,index) => {
        let tempArr = this.state.datalist;
        let fieldValueArr = tempArr[index].fieldValue;
		let formCustomRisk = this.state.formCustomRisk;
        if(!isEmptyVariable(value)){
            let fieldValueIndex = fieldValueArr.indexOf(value);
            if(fieldValueIndex !== -1){
                // tempArr[index].fieldValue.splice(fieldValueIndex,1)
                fieldValueArr.splice(fieldValueIndex,1)
            }
			// If "Other" in Risk/Impact is removed, make formCustomRisk empty
			if(value === -1){
				formCustomRisk="";
			}
        }
        this.setState({
            datalist:tempArr,
			formCustomRisk:formCustomRisk

        })
    }
	//Custom Risk React Quill Functions
	handleChangeFormCustomRisk = (value) => {
		this.setState({ 
			formCustomRisk:value
		});
	}
	

	onCustomRiskFocus = () => {
		this.handleFocus();
		this.setState({
			formCustomRisk_isFocused:true,
		})    
	}

	onCustomRiskBlur = () => {
		this.setState({
			formCustomRisk_isFocused:false,
		})    
	}
   
    onDropDownItemClick = (item,index) => {
        this.handleFocus();
        let tempArr = this.state.datalist;
        tempArr[index].fieldValue = item.value;

        this.setState({
            datalist:tempArr
        })
    }
    addSelectedValuesLeft = (value,index) => {
        let tempArr = this.state.datalistLeft;
        tempArr[index].fieldValue.push(value);
        this.setState({
            datalistLeft:tempArr
        })
    }

    removeSelectedValuesLeft = (value,index) => {
        let tempArr = this.state.datalistLeft;
        let fieldValueArr = tempArr[index].fieldValue;
        if(!isEmptyVariable(value)){
            let fieldValueIndex = fieldValueArr.indexOf(value);
            if(fieldValueIndex !== -1){
                // tempArr[index].fieldValue.splice(fieldValueIndex,1)
                fieldValueArr.splice(fieldValueIndex,1)
            }
        }
        this.setState({
            datalistLeft:tempArr
        })
    }
   
    onDropDownItemClickLeft = (item,index) => {
        this.handleFocus();
        let tempArr = this.state.datalistLeft;
        tempArr[index].fieldValue = item.value;

        this.setState({
            datalistLeft:tempArr
        })
    }
	modules = {
		toolbar: [
			[{ header: [1, 2, 3, false] }],
			["bold", "italic", "underline", "strike", { color: [] }, { background: [] }],
			[{ list: "ordered" }, { list: "bullet" }, { align: [] }, "link"],
			["clean"],
		],
	};

	onDateChange = (dateValue,index) => {
        let tempArr = this.state.datalist;
        tempArr[index].fieldValue = dateValue;
        this.setState({
            datalist:tempArr
        })
    };

    onAddTableClick = (index) => {
        
		this.setState({
			showAlertWithInputDialog: true,
			alertWithInputDialogMessage: "Enter the number of Rows and Columns",
            addToTableIndex:index,
		});
	};

	handleAlertWithInputDialogClose = () => {
		this.setState({
			showAlertWithInputDialog: false,
			alertWithInputDialogMessage: "",
		});
	};
    handleAddIntanceTable = (rows, cols, excelData) => {
        let tableArray = [];
        
        for(let i=0;i<rows;i++){
            tableArray[i]=[];
            let j= 0;
            for(j=0;j<cols;j++){
                if(!isEmptyArray(excelData)){
                    tableArray[i][j]= excelData[i][j] ?? "";
                }else{
                    tableArray[i][j] = "";
                }
            }
            tableArray[i][j] = "Action"
        }
        
        let tempArr = this.state.datalist;
        tempArr[this.state.addToTableIndex].fieldValue = tableArray;
        tempArr[this.state.addToTableIndex].isTableAdded = true;

		this.setState({
			showAlertWithInputDialog: false,
			alertWithInputDialogMessage: "",
            tableArray:tableArray,
            datalist:tempArr,
            addToTableIndex:""
			
		});
	};

    handleTableInputBlur = (rowIndex, colIndex, index, e) => {
		const value = e.currentTarget.textContent;

        let tempArr = this.state.datalist;
        let tableArray = tempArr[index].fieldValue;
		tableArray[rowIndex][colIndex] = value;

		this.setState(
			{
				datalist: tempArr,
			});
	};
    
    addNewRow = (index,e) => {
        let tempArr = this.state.datalist;
        let tableArray = tempArr[index].fieldValue;
        let singleRow = [];
        let colLength = tableArray[0].length;
        for(let i=0; i<colLength; i++){
            if(i === colLength-1){
                singleRow.push("Action")
            }else{
                singleRow.push("");
            }
        }

        tableArray.push(singleRow);
        tempArr[index].fieldValue = tableArray;

        this.setState({
            datalist: tempArr
        })
    } 

    addNewColumn = (index,e) => {
        let tempArr = this.state.datalist;
        let tableArray = tempArr[index].fieldValue;
        let rowLength = tableArray.length;
        let colLength = tableArray[0].length;
        let newArr = [];

        for(let i=0;i<rowLength;i++){
            let rowArray = tableArray[i];
            rowArray.splice(colLength-1,0,"")
            newArr.push(rowArray);
        }
        tempArr[index].fieldValue = newArr;
        this.setState({
            datalist: tempArr
        })
    }

    deleteRow = (rowIndex, index,  e) =>{
        let tempArr = this.state.datalist;
        let tableArray = tempArr[index].fieldValue;
        tableArray.splice(rowIndex,1);
        tempArr[index].fieldValue = tableArray;

        this.setState({
            datalist: tempArr
        })
    }

    deleteColumn = (colIndex, index,  e) =>{
        let tempArr = this.state.datalist;
        let tableArray = tempArr[index].fieldValue;
        let tableArrayTemp = [];

        tableArray.forEach((rowItem,rowIndex)=>{
            rowItem.splice(colIndex,1);
            tableArrayTemp[rowIndex]=[...rowItem];
        });
        tempArr[index].fieldValue = tableArrayTemp;

        this.setState({
            datalist: tempArr
        })
    }
   
    handleDeleteTableClickAlert = (fieldName,index,e) => {
        e.stopPropagation();
        this.setState({

            operationType:"Delete",
            alertDialogHeading:"Delete Field",
            proceedBtnLabel:"Delete",
            deleteReactivateId:index,
            showAlertDialog:true,
            alertDialogMessage:"Are you sure you want to delete field- "+fieldName+"?"
        })
    }

    handleDeleteTable = () => {
        let tempArr = this.state.datalist;
        tempArr[this.state.deleteReactivateId].fieldValue = [];
        tempArr[this.state.deleteReactivateId].isTableAdded = false;

        this.setState({
                
            datalist: tempArr,
            deleteReactivateId:"",
            operationType:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            alertDialogMessage:"",
            showAlertDialog:false,
        })
    }

	handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

	onCancelClick = () => {
		this.props.history.push(Constants.URL_ONGOING_OBSERVATIONS + "/" + this.state.assignmentId);
	};

	/************************API CALLS****************************/
	initData = () => {
		this.setState({
			showLoader: true,
		});
		// if (this.state.isEditObservation) {
			Promise.all([
				fetch(Constants.GetObservationCreateMasterData, {
					method: "POST",
					mode: "cors",
					body: new URLSearchParams({
						email: userDetails.email,
						accessToken: userDetails.accessToken,
						assignmentId: this.state.assignmentId,
					}),
				}),
				fetch(Constants.GetUserMenu, {
					method: "POST",
					mode: "cors",
					body: new URLSearchParams({
						email: userDetails.email,
						accessToken: userDetails.accessToken,
					}),
				}),
				fetch(Constants.GetAssignmentsDetails, {
					method: "POST",
					mode: "cors",
					body: new URLSearchParams({
						email: userDetails.email,
						accessToken: userDetails.accessToken,
						assignmentId: this.state.assignmentId,
					}),
				}),
				fetch(Constants.GetObservationDetails, {
					method: "POST",
					mode: "cors",
					body: new URLSearchParams({
						email: userDetails.email,
						accessToken: userDetails.accessToken,
						observationId: this.state.observationId,
					}),
				}),
			])
				.then(([masterRes, menuRes, assignmentRes, obsservationRes]) => {
					return Promise.all([masterRes.json(), menuRes.json(), assignmentRes.json(), obsservationRes.json()]);
				})
				.then(([masterRes, menuRes, assignmentRes, obsservationRes]) => {
					let menusTemp = {};
					let userTemp = {};
					let allSettings = [];
					let companyTemp = {};
					let businessRiskArr = [];
					let businessRisks = [];
					let deptDefaultRisk = [];
					let valueAdditions = [];
					let assignedDeptId = "";
					let assignmentStatus = "";
					let assignmentDetails = {};
					let observationDetails = {};
					let observationTasks = [];
					let observationVAArr = [];
					let observationAnnexures = [];
					let observationBackups = [];
					let datalistTemp = [];
					let datalistLeftTemp = [];
					let datalist = [];
					let datalistLeft = [];
					let templateObj = {};
					let templateName = "";
					let templateId = "";
					let templateFieldValuesMap = {};
					let formCustomRisk = "";
					let likelihoodRiskMatrix = [];
					let isRisk = false;
					if (assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
						assignedDeptId = assignmentRes.data.assignmentDetails.departmentId;
						assignmentStatus = assignmentRes.data.assignmentDetails.assignmentStatus;
						assignmentDetails = assignmentRes.data.assignmentDetails;
						likelihoodRiskMatrix = assignmentRes.data.likelihoodLevels;
					} else {
					}

					if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
						menusTemp = menuRes.data.menus;
						userTemp = menuRes.data.user;
						companyTemp = menuRes.data.company;
						allSettings = menuRes.data.allSettings;
						isRisk = allSettings.some(e => e.settingColumn.includes(Constants.DISABLEDRISK) && e.settingColumnEnabled === "N" && 
							e.departmentId === assignedDeptId && e.assignmentTypeId === assignmentDetails.assignmentTypeId);
					} else {
						menusTemp = {};
					}
					//Order the menu tabs in a specific way
					let menusTemp2 = GetOrderedMasterTabMenuExecute(menusTemp);

					if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
						let businessRiskObj = {
							businessRiskId: -1,
							shortName: "",
							name: "Other",
							description: "",
						};
						businessRisks = masterRes.data.businessRisk;
						businessRisks.push(businessRiskObj);
						//add property "displayname: name (description)" to businessRisk array
						businessRisks.forEach(item=>{
							if(item.name !=="Other"){
								item.displayName = item.name+" ("+item.description+")"
								
							}
							else{
								item.displayName = item.name
							}
							businessRiskArr.push(item);
							
						});
						deptDefaultRisk = masterRes.data.riskLevels;
						valueAdditions = masterRes.data.valueAdditions;
						
						// //user defined fields
						// let observationUserFieldsArrTemp = masterRes.data.observationUserFields;
                    	// if(!isEmptyArray(observationUserFieldsArrTemp)){
                        // 	userDefinedFieldsObj = observationUserFieldsArrTemp[0];
						// }
						//Template Fields
						templateObj = masterRes.data.templateFields[0];
                    
						if(!isEmptyVariable(templateObj.templateFields) && isJsonString(templateObj.templateFields)){
							datalistTemp = JSON.parse(templateObj.templateFields);
						}
						if(!isEmptyVariable(templateObj.templateFieldsLeft) && isJsonString(templateObj.templateFieldsLeft)){
							datalistLeftTemp = JSON.parse(templateObj.templateFieldsLeft);
						}
						
						templateName = templateObj.templateName;
						templateId = templateObj.templateId;
					} else {
						this.setState({
							businessRisk: [],
							valueAdditions: [],
							deptDefaultRisk: [],
							showLoader: false,
							componentDidMountFlag: true,
						});
					}

					if (obsservationRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || obsservationRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (obsservationRes.responseCode === Constants.CODE_SUCCESS) {
						observationDetails = obsservationRes.data.observationDetails;
						observationTasks = obsservationRes.data.observationTasks;
						observationVAArr = obsservationRes.data.valueAdditions;
						observationAnnexures = obsservationRes.data.annexures;
						observationBackups = obsservationRes.data.backups;
						observationDetails.templateFieldValues.forEach(item=>{
							templateFieldValuesMap[JSON.stringify(item.fieldId)] = item;
						});
						datalist = datalistTemp.map(item=>{
							if(item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI ||
								item.fieldType === TemplateConstants.FIELD_TYPE_TABLE ){
	
								if(!isEmptyVariable(templateFieldValuesMap[item.fieldId]?.value) &&
									templateFieldValuesMap[item.fieldId]?.valueNR !== "Y" &&
									isJsonString(templateFieldValuesMap[item.fieldId]?.value)
								){
									item.fieldValue = JSON.parse(templateFieldValuesMap[item.fieldId]?.value);
									if(item.fieldType === TemplateConstants.FIELD_TYPE_TABLE){
										item.isTableAdded = true;
									}
									
								}else{
									item.fieldValue = [];
								}
	
							}else{
								if(templateFieldValuesMap[item.fieldId]?.valueNR !== "Y"){
									item.fieldValue = templateFieldValuesMap[item.fieldId]?.value;
								}
							}
	
							item.isNRChecked = templateFieldValuesMap[item.fieldId]?.valueNR;
							return item;
						});
	
						datalistLeft = datalistLeftTemp.map(item=>{
							if(item.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT){
								if(!isEmptyVariable(templateFieldValuesMap[item.fieldId]?.value) &&
									templateFieldValuesMap[item.fieldId]?.valueNR !== "Y" &&
									isJsonString(templateFieldValuesMap[item.fieldId]?.value)
								){
	
									item.fieldValue = JSON.parse(templateFieldValuesMap[item.fieldId]?.value);
								}else{
									item.fieldValue = [];
								}
							}else{
								if(templateFieldValuesMap[item.fieldId]?.valueNR !== "Y"){
									item.fieldValue = templateFieldValuesMap[item.fieldId]?.value;
								}
							}
							item.isNRChecked = templateFieldValuesMap[item.fieldId]?.valueNR;
							return item;
						});
						//Get Other Risk/Impact Value
						if(!isEmptyVariable(observationDetails.customBusinessRisk)){
							formCustomRisk = observationDetails.customBusinessRisk;
						}

					} else {
					}

					// let selectedRiskImpactName = observationDetails.businessRisk;
					// let selectedRiskImpactId = observationDetails.businessRiskId;
					// let formCustomRisk = "";

					// if (isEmptyVariable(selectedRiskImpactId) && observationDetails.businessRisk_NR !== "Y") {
					// 	if (isEmptyVariable(selectedRiskImpactName)) {
					// 		selectedRiskImpactName = riskImpactDropdownDefaultValue;
					// 		selectedRiskImpactId = "";
					// 	} else {
					// 		selectedRiskImpactName = "Other";
					// 		selectedRiskImpactId = -1;
					// 		formCustomRisk = observationDetails.businessRisk;
					// 	}
					// }

					
					this.setState({
						showLoader: false,
						componentDidMountFlag: true,
						menus: menusTemp2,
						user: userTemp,
						company:companyTemp,

						deptDefaultRisk: deptDefaultRisk,
						businessRisk: businessRiskArr,
						valueAdditions: valueAdditions,

						selectedDepartmentId: assignedDeptId,
						assignmentStatus: assignmentStatus,
						assignmentDetails:assignmentDetails,
						observationStatus: observationDetails.status,
						allowReview: observationDetails.allowReview,
						allowEdit: observationDetails.allowEdit,


						selectedTagTasksObjArr: observationTasks.map((item) => item.assignmentTaskId),
						selectedRiskId: observationDetails.riskLevelId,
						selectedVAObj: observationVAArr.map((item) => {
							return {
								valueAdditionId: item.valueAdditionId,
								valueAdditionValue: item.valueAdditionValue,
								valueAddition_NR: item.valueAddition_NR,
							};
						}),
						uploadedAnnexureArr:observationAnnexures.map(item=>{
							return {
								fileName:item.documentPath,
								remark:item.remark
							}
						}),
						annexure_NR:observationDetails.annexure_NR,
						backup_NR:observationDetails.backup_NR,
						uploadedBackupArr:observationBackups.map(item=>{
							return {
								fileName:item.documentPath,
								remark:item.remark
							}
						}),
						datalist: datalist,
						datalistLeft: datalistLeft,
						templateName:templateName,
						templateId:templateId,
						templateFieldValuesMap:templateFieldValuesMap,
						formCustomRisk:formCustomRisk,
						likelihoodRiskMatrix: likelihoodRiskMatrix,
						allSettings:allSettings,
						isRisk:isRisk
					});
				});
		// }
	};
	
	submitObservation = () => {
		this.saveObservation("N");
	};

	saveObservation = () => {
		this.setState({
			showLoader: true,
		});

		let isError = false;
		let datalist = this.state.datalist;
        let datalistLeft = this.state.datalistLeft;
        this.handleFocus();

		//now validate the mandatory fields
		datalist.forEach((item)=>{
            if(item.fieldType !== TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                item.fieldType !== TemplateConstants.FIELD_TYPE_TABLE &&
                item.isMandatorySelected === "Y" )
            {
                if( isEmptyVariable(item.fieldValue)){
                    isError = true;
                    item.valueErr = "Please enter "+ item.fieldName+" Value";
                }else if( isEmptyVariable(removeHtmlTags(item.fieldValue))){
                    isError = true;
                    item.valueErr = "Please enter "+ item.fieldName+" Value";
                }
            }
            if((item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI ||
                item.fieldType === TemplateConstants.FIELD_TYPE_TABLE ) &&
                isEmptyArray(item.fieldValue) && 
                item.isMandatorySelected === "Y"
                ){
                    isError = true;
                    item.valueErr = "Please enter "+ item.fieldName+" Value";
            }
            
            //Check for empty table header
            if(item.fieldType === TemplateConstants.FIELD_TYPE_TABLE &&
                !isEmptyArray(item.fieldValue) 
                ){

                let rowHeading = item.fieldValue[0];
                isError = rowHeading.some(rowItem =>
                    isEmptyVariable(rowItem)
                )
                if(isError){
                    item.valueErr = "Please enter "+ item.fieldName+" Header Value";
                }

            }
        });

        //now validate the mandatory fields
		datalistLeft.forEach((item)=>{
            if(item.fieldType !== TemplateConstants.FIELD_TYPE_MULTI_SELECT &&
                item.isMandatorySelected === "Y" )
            {
                if( isEmptyVariable(item.fieldValue)){
                    isError = true;
                    item.valueErr = "Please enter Value";
                }else if( isEmptyVariable(removeHtmlTags(item.fieldValue))){
                    isError = true;
                    item.valueErr = "Please enter Value";

                }
            }
            if(item.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT &&
                isEmptyArray(item.fieldValue) && 
                item.isMandatorySelected === "Y"
                ){
                    isError = true;
                    item.valueErr = "Please enter "+ item.fieldName+" Value";
            }
        });


		if (isError) {
			this.setState({
                datalist:datalist,
                datalistLeft:datalistLeft,
                showLoader:false,
            });
			setTimeout(() => {
				datalist.forEach((item)=>{
					item.valueErr = ""
				});
                
                datalistLeft.forEach((item)=>{
					item.valueErr = ""
				});

                this.setState({
					datalist:datalist,
					datalistLeft:datalistLeft
				});
			},Constants.WRNG_MSG_TIMEOUT);
			
		} else {
			
			
			let tmpAnnexureFileArr = [];
			let delAnnexureFileArr = [];

			if(!isEmptyArray(this.state.uploadedAnnexureArr)){
				this.state.uploadedAnnexureArr.forEach(item=>{
					//if any item is deleted and if it is not newly added
					if(item.isDeleted && !item.newlyAdded){
						let obj = {
							fileName:item.fileName,
							remark:item.remark
						}
						delAnnexureFileArr.push(obj)

					}

					if(!item.isDeleted && item.newlyAdded){
						let obj = {
							fileName:item.fileName,
							remark:item.remark
						}
						tmpAnnexureFileArr.push(obj)
					}
				})
			}

			let tmpBackupsFileArr = [];
			let delBackupsFileArr = [];

			if(!isEmptyArray(this.state.uploadedBackupArr)){
				this.state.uploadedBackupArr.forEach(item=>{
					//if any item is deleted and if it is not newly added
					if(item.isDeleted && !item.newlyAdded){
						let obj = {
							fileName:item.fileName,
							remark:item.remark
						}
						delBackupsFileArr.push(obj)

					}

					if(!item.isDeleted && item.newlyAdded){
						let obj = {
							fileName:item.fileName,
							remark:item.remark
						}
						tmpBackupsFileArr.push(obj)
					}
				})
			}

			let postObj = {
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				assignmentId: this.state.assignmentId,
				templateId: this.state.templateId,

				assignmentTaskIds: JSON.stringify(this.state.selectedTagTasksObjArr),
				riskLevelId: isEmptyVariable(this.state.selectedRiskId) ? "" : this.state.selectedRiskId,
				likelihoodLevelId: this.state.selectedLikelihoodRiskId,
				valueAdditions: JSON.stringify(this.state.selectedVAObj),

				annexure_NR: this.state.annexure_NR,
				backup_NR: this.state.backup_NR,
				tmpAnnexureFileArr:JSON.stringify(tmpAnnexureFileArr),
				delAnnexureFileArr:JSON.stringify(delAnnexureFileArr),
				delTmpAnnexureFileArr:JSON.stringify([]),

				tmpBackupFileArr:JSON.stringify(tmpBackupsFileArr),
				delBackupFileArr:JSON.stringify(delBackupsFileArr),
				delTmpBackupFileArr:JSON.stringify([]),
			};

			// if (!isEmptyVariable(this.state.isEditObservation)) {
				postObj.observationId = this.state.observationId;
			// }
			if(!isEmptyVariable(this.state.formCustomRisk)){
                postObj.customBusinessRisk = this.state.formCustomRisk;
            }
			//create post attributes from fields
			this.state.datalist.forEach((item)=>{
                if((item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI ||
                item.fieldType === TemplateConstants.FIELD_TYPE_TABLE ) &&
                !isEmptyArray(item.fieldValue) &&
                item.isNRChecked !== "Y"){
                    postObj[item.fieldId]= JSON.stringify(item.fieldValue);
                }else if(item.fieldType !== TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                item.isNRChecked !== "Y"){
					if(!isEmptyVariable(item.fieldValue)){
						postObj[item.fieldId]= item.fieldValue;
					}

                    if(item.fieldId+"" === "1"){
                        postObj.observationHeading = !isEmptyVariable(item.fieldValue)?item.fieldValue:"";
                    }

					if(item.fieldId+"" === "2"){
                        postObj.observationDetail = item.fieldValue;
                    }
                }

                if(!isEmptyVariable(item.isNRChecked)){
                    postObj[item.fieldId+"_NR"]=item.isNRChecked
                }
				//TODO 
				if(item.isNRChecked === "Y" && item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI && item.isFromRiskMaster){
					postObj.customBusinessRisk = "";
				}
            });

            this.state.datalistLeft.forEach((item)=>{
                if(item.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT &&
                    !isEmptyArray(item.fieldValue)
                    ){
                    postObj[item.fieldId]= JSON.stringify(item.fieldValue);
                }else if(item.fieldType !== TemplateConstants.FIELD_TYPE_MULTI_SELECT &&
                    !isEmptyVariable(item.fieldValue)
                    ){
                    postObj[item.fieldId]= item.fieldValue;
                }
                if(!isEmptyVariable(item.isNRChecked)){
                    postObj[item.fieldId+"_NR"]=item.isNRChecked
                }
            });
			
			fetch(Constants.UpdateOngoingobservation, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(postObj),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (data.responseCode === Constants.CODE_SUCCESS) {
						this.props.history.push(Constants.URL_ONGOING_OBSERVATIONS + "/" + this.state.assignmentId);
					} else {
						this.setState({
							showAlertDialogInfo: true,
							alertDialogMessageInfo: data.responseMessage,
							showLoader: false,
						});
					}
				});
		}
	};

	render() {
		let fields = {text:"displayName",value:"businessRiskId"};
		return (
			<Router>
				<main className="main-dashboard">
					<section className="dashboard-bg">
						<Sidebar />
						<div className="dashboard-right-bg-collpase">
							<div className="dashboard-wrapper">
								<Topbar />
								{
									this.state.componentDidMountFlag &&
									<AssignmentHeading 
										assignmentId={this.state.assignmentId} 
										assignmentDetails={this.state.assignmentDetails} 
										company={this.state.company} 
										isDraftOnward = {false} 
                                        themeSettings={themeSettings}
									/>
								}
								<MasterMenuTabs assignmentId={this.state.assignmentId} masterMenus={this.state.menus} themeSettings={themeSettings} />
								{this.state.showLoader && (
									<div class="loader"></div>
								)}
								{
									// Need to check component did mount flag, other wise until api is called,
									// it shows no items found layout.
									this.state.componentDidMountFlag &&
										!isEmptyVariable(this.state.assignmentStatus) &&
										this.state.assignmentStatus === "Ongoing" && (
											<div className="m-t-10">
												<div className="row custom-row">
													<div className="col-md-3 custom-col">
														<TaskTagButtonsLayout themeSettings={themeSettings}>
															<ul>
																<li>
																	<a href={Constants.URL_ONGOING_OBSERVATIONS+"/"+this.state.assignmentId}>Back</a>
																</li>

																<li>
																	<a href="javascript:void(0)" onClick={this.handleSelectTagTasksDialogShow}>
																		Tag Tasks
																		{!isEmptyArray(this.state.selectedTagTasksObjArr) && (
																			<span class="material-icons">check_circle</span>
																		)}
																	</a>
																</li>
																{
																	this.state.isRisk && !isEmptyVariable(this.state.assignmentDetails.riskId) && 
																	<li>
																		<a href="javascript:void(0)" onClick={this.handleSelectRiskDialogShow}>
																			Assign Risk
																			{!isEmptyVariable(this.state.selectedRiskId) && <span class="material-icons">check_circle</span>}
																		</a>
																	</li>
																}
																{
																	!isEmptyVariable(this.state.assignmentDetails.valueAdditionList) &&
																	<li>
																		<a href="javascript:void(0)" onClick={this.handleSelectVADialogShow}>
																			Value Addition
																			{!isEmptyArray(this.state.selectedVAObj) && <span class="material-icons">check_circle</span>}
																		</a>
																	</li>
																}
																<li>
																	<a href="javascript:void(0)" onClick={this.handleSelectUploadAnnexureDialogShow}>
																		Upload Annexure
																		{(!isEmptyArray(this.state.uploadedAnnexureArr)|| this.state.annexure_NR === "Y") && (
																			<span class="material-icons">check_circle</span>
																		)}
																	</a>
																</li>
																<li>
																	<a href="javascript:void(0)" onClick={this.handleSelectUploadBackupDialogShow}>
																		Upload Backup
																		{(!isEmptyArray(this.state.uploadedBackupArr)|| this.state.backup_NR === "Y") && (
																			<span class="material-icons">check_circle</span>
																		)}
																	</a>
																</li>
																<li>
																{
																	this.state.datalistLeft.map((item,index)=>{
																		return  <li>
																			<div class="template-field-left-layout m-b-10">
																				<p className="m-b-5 flex-center-layout" style={{marginBottom:10}}
																				onClick={this.handleSelectDropdownDialogShow.bind(this,item,index)}>
																					{item.fieldName}{item.isMandatorySelected === "Y"?"*":""}
																					{
																						!isEmptyVariable(item.fieldValue) &&
																						<span className="material-icons template-field-tick">check_circle</span>
																					}
																				</p>
																			</div>
																		</li>
																	})
																}
																</li>
																{/* <li>
																	<a href="javascript:void(0)" onClick={this.handleSelectAssertionDialogShow}>
																		Completed
																		{(!isEmptyArray(this.state.selectedAssertionObjArr) || this.state.assertion_NR === "Y") && (
																			<span class="material-icons">check_circle</span>
																		)}
																	</a>
																</li>
																<li>
																	<a href="javascript:void(0)" onClick={this.handleSelectAssertionDialogShow}>
																		Submit
																		{(!isEmptyArray(this.state.selectedAssertionObjArr) || this.state.assertion_NR === "Y") && (
																			<span class="material-icons">check_circle</span>
																		)}
																	</a>
																</li>
																<li>
																	<a href="javascript:void(0)" onClick={this.handleSelectAssertionDialogShow}>
																		Rework
																		{(!isEmptyArray(this.state.selectedAssertionObjArr) || this.state.assertion_NR === "Y") && (
																			<span class="material-icons">check_circle</span>
																		)}
																	</a>
																</li> */}
															</ul>
														</TaskTagButtonsLayout>
													</div>
													<div className="col-md-9 custom-col">
													{
														this.state.datalist.map((item,index)=>{
															return (
																<div class="editor-card">
																	<div className="task-header-layout">
																		<h6 className="editor-heading">{item.fieldName}{item.isMandatorySelected === "Y"?"*":""}</h6>
																		{
																			item.isNotReqSelected === "Y" &&
																			<FilterCheckBox themeSettings={themeSettings}
																				style={{marginLeft:0}}>
																				<input 
																					type="checkbox"
																					name={item.fieldName+":-:"+index}
																					id={item.fieldName+":-:"+index}
																					onChange={this.handleCheck}
																					checked={item.isNRChecked === "Y"?true:false}
																				/>
																				<label for={item.fieldName+":-:"+index}>Not Required</label>
																			</FilterCheckBox>
																		}
																	</div>
																	{
																		item.fieldType === TemplateConstants.FIELD_TYPE_TEXT &&
																		<div className="row custom-row">
																			<div className="col-md-12 custom-col">
																				<div className={item.isNRChecked === "Y"?"editor-layout editor-disabled":"editor-layout"}>
																					<ReactQuill
																						placeholder={item.placeholderValue}
																						value={!isEmptyVariable(item.fieldValue)?item.fieldValue:""} 
																						id = {item.fieldName+":-:"+index}
																						onChange={this.handleChangeReactQuill.bind(this,index)}
																						className={item.isFocused?
																							(item.isNRChecked === "Y"?"two-lines":"two-lines-focus"):"two-lines"}
																						modules={this.modules}
																						onFocus={this.handleQuillFocus.bind(this,index)}
																						// onBlur={this.handleQuillBlur}
																						readOnly={item.isNRChecked === "Y"?true:false}
																					/>
																				</div>
																			</div>
																		</div>
																	}
																	{
                                                                    item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN &&
                                                                    <div className="row custom-row">
                                                                        <div className="col-md-12 custom-col">
                                                                            <div className={item.isNRChecked === "Y"?"editor-layout editor-disabled":"editor-layout"}>
                                                                                <RegularDropdown 
                                                                                    placeholder={isEmptyVariable(item.fieldValue)?item.placeholderValue:item.fieldValue}
                                                                                    dropdownArr={item.dropdownValues}
                                                                                    labelParam="value"
                                                                                    onDropDownItemClick={this.onDropDownItemClick}
                                                                                    disabled={item.isNRChecked === "Y"?true:false}
                                                                                    defaultPlaceholderDropDown={item.placeholderValue}
                                                                                    udf = {index}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                                                                    item.isFromRiskMaster &&
                                                                    <div className="row custom-row">
                                                                        <div className="col-md-12 custom-col">
                                                                            <div className={item.isNRChecked === "Y"?"editor-layout editor-disabled":"editor-layout"}>
                                                                                <MultiSelectComponentTemplate
                                                                                    templateItemIndex={index}
                                                                                    // multiselectId={item.fieldName+index}
                                                                                        fields = {{text:"displayName",value:"businessRiskId"}}
                                                                                    dropdownValues={this.state.businessRisk}
                                                                                    placeholderValue={item.placeholderValue} 
                                                                                    addSelectedValues={this.addSelectedValues}
                                                                                    removeSelectedValues={this.removeSelectedValues}
                                                                                    fieldValue={!isEmptyArray(item.fieldValue)?item.fieldValue:""}
                                                                                    isFromRiskMaster={item.isFromRiskMaster}
                                                                                    handleFocus = {this.handleFocus}
                                                                                />
                                                                                {
																					item.fieldValue.some(fieldItem => fieldItem === -1)  &&
																					<div class="editor-layout" style={{marginTop:15}}>
																						<ReactQuill
																							placeholder={item.placeholderValue}
																							value={this.state.formCustomRisk} 
																							onChange={this.handleChangeFormCustomRisk}
																							className={this.state.formCustomRisk_isFocused?
																								(item.isNRChecked === "Y"?"two-lines":"two-lines-focus"):"two-lines"}
																							modules={this.modules}
																							onFocus={this.onCustomRiskFocus}
																							onBlur={this.onCustomRiskBlur}
																							readOnly={item.isNRChecked === "Y"?true:false}
																						/>
																					</div>
																				}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
                                                                    !item.isFromRiskMaster &&
                                                                    <div className="row custom-row">
                                                                        <div className="col-md-12 custom-col">
                                                                            <div className={item.isNRChecked === "Y"?"editor-layout editor-disabled":"editor-layout"}>
                                                                                <MultiSelectComponentTemplate
                                                                                    templateItemIndex={index}
                                                                                    // multiselectId={item.fieldName+index}
                                                                                    fields = {{text:"value",value:"value"}}
                                                                                    dropdownValues={item.dropdownValues}
                                                                                    placeholderValue={item.placeholderValue} 
                                                                                    addSelectedValues={this.addSelectedValues}
                                                                                    removeSelectedValues={this.removeSelectedValues}
                                                                                    fieldValue={!isEmptyArray(item.fieldValue)?item.fieldValue:""}
                                                                                    handleFocus = {this.handleFocus}
                                                                                />
                                                                                
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {
                                                                    item.fieldType === TemplateConstants.FIELD_TYPE_DATE &&
                                                                    <div className="row custom-row">
                                                                        <div className="col-md-12 custom-col">
                                                                            <div className={item.isNRChecked === "Y"?"editor-layout editor-disabled":"editor-layout"}>
                                                                                <DatePickerComponent
                                                                                    templateItemIndex={index}
                                                                                    onDateChange={this.onDateChange}
                                                                                    format={"DD/MM/YYYY"}
                                                                                    placeholderValue={item.placeholderValue}
                                                                                    isNRChecked={item.isNRChecked}
                                                                                    fieldValue={!isEmptyVariable(item.fieldValue)?item.fieldValue:""}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
																{
                                                                    item.fieldType === TemplateConstants.FIELD_TYPE_TABLE &&
                                                                    <div className="flex-center-layout" style={{justifyContent:"center"}}>
                                                                        {
                                                                            !item.isTableAdded &&
                                                                            <AddNewButtonLayout themeSettings={themeSettings}
                                                                                
                                                                            >
                                                                                <a onClick={this.onAddTableClick.bind(this,index)} href="javascript:void(0);"
                                                                                    disabled={
                                                                                        item.isNRChecked === "Y"
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                >
                                                                                    <span className="material-icons">add_circle</span>
                                                                                    <p>Add Table</p>
                                                                                </a>
                                                                            </AddNewButtonLayout>
                                                                        }
                                                                        {
                                                                            item.isTableAdded &&
                                                                            // !isEmptyArray(this.state.columnArray) && !isEmptyArray(this.state.rowArray) && 
                                                                            <div
                                                                                style={{ width: "100%", marginTop: 10, boxShadow: "none" }}
                                                                                class={
                                                                                    item.isNRChecked === "Y"
                                                                                        ? "editor-layout editor-disabled common-table"
                                                                                        : "common-table editor-layout"
                                                                                }
                                                                            >
                                                                                <div className="flex-center-layout m-b-10" style={{justifyContent:"right"}}>
                                                                                    <button className="flex-center-layout instances-new-row" onClick={this.addNewColumn.bind(this,index)}>
                                                                                        <span className="material-icons">add</span>
                                                                                        <p>Add New Column</p>
                                                                                    </button>
                                                                                    <button className="flex-center-layout instances-new-row" onClick={this.addNewRow.bind(this,index)}>
                                                                                        <span className="material-icons">add</span>
                                                                                        <p>Add New Row</p>
                                                                                    </button>
                                                                                </div>
                                                                                <table class="table instances-table">
                                                                                    {
                                                                                        item.fieldValue.map((rowItem, rowIndex) =>  {
                                                                                            if(rowIndex === 0){
                                                                                                return <thead className="instances-thead">
                                                                                                    <tr>
                                                                                                    {
                                                                                                        rowItem.map((colItem, colIndex) => {
                                                                                                            return <th width={colItem === "Action"?"5%":""} className= {colItem === "Action"?"text-center":""}>
                                                                                                                <div className="instances-table-header">
                                                                                                                    <p
                                                                                                                        contenteditable={colItem === "Action" ? "false" : "true"}
                                                                                                                        onBlur={this.handleTableInputBlur.bind(this, rowIndex, colIndex,index)}
                                                                                                                    >
                                                                                                                        {colItem}
                                                                                                                    </p>
                                                                                                                    {colItem === "Action" ? null : (
                                                                                                                        <div className="instances-table-del-column">
                                                                                                                            <span
                                                                                                                                onClick={this.deleteColumn.bind(this, colIndex,index)}
                                                                                                                                className="material-icons"
                                                                                                                                style = {{cursor:'pointer'}}
                                                                                                                            >
                                                                                                                                delete
                                                                                                                            </span>
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                            </th>
                                                                                                            
                                                                                                        })
                                                                                                    }
                                                                                                    </tr>
																								</thead>
                                                                                                    
                                                                                            }else{
                                                                                                return <tbody className="instances-thead">
                                                                                                    <tr>
                                                                                                    {
                                                                                                        rowItem.map((colItem, colIndex) => {
                                                                                                            return <td
                                                                                                                className={colItem === "Action" ? "text-center" : ""}
                                                                                                                contenteditable={colItem === "Action" ? "false" : "true"}
                                                                                                                onBlur={this.handleTableInputBlur.bind(this, rowIndex, colIndex,index)}
                                                                                                            >
                                                                                                                {
                                                                                                                    colItem === "Action" ? (
                                                                                                                    <div className="instances-table-del-cell">
                                                                                                                        <span
                                                                                                                            onClick={this.deleteRow.bind(this,rowIndex,index)}
                                                                                                                            className="material-icons"
                                                                                                                            style = {{cursor:'pointer'}}
                                                                                                                        >
                                                                                                                            delete
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    colItem
                                                                                                                )}
                                                                                                            </td>
                                                                                                        })
                                                                                                    }
                                                                                                    </tr>
																								</tbody>
                                                                                            }

                                                                                        })
                                                                                    }
                                                                                    
                                                                                </table>
                                                                                <div className="addnew-row-wrapper">
                                                                                    <button className="flex-center-layout instances-new-row" onClick={this.handleDeleteTableClickAlert.bind(this,item.fieldName,index)}>
                                                                                        <span className="material-icons">delete</span>
                                                                                        <p>Delete Table</p>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                }
																{
                                                                    !isEmptyVariable(item.valueErr) &&
                                                                    <span className="cm-error">{item.valueErr}</span>
                                                                }
																</div>
															)
														})
															
													}
														{/************************ User Defined Fields ENDS********************************/}

													</div>
												</div>
												<div className="row custom-row">
													<div className="col-md-12 custom-col">
														<div className="common-close-save">
															<div className="common-close-save-col">
																<button onClick={this.onCancelClick} type="button" className="common-close">
																	Cancel
																</button>
																{/* {this.state.status === draftStatus && (
																<PageSaveButton themeSettings={themeSettings} onClick={this.saveAsDraft} type="button">
																	Save as draft
																</PageSaveButton>
															)} */}

																<PageSaveButton themeSettings={themeSettings} onClick={this.submitObservation} type="button">
																	Save
																</PageSaveButton>
															</div>
														</div>
													</div>
												</div>
											</div>
										)
								}
							</div>
						</div>
						<SelectTagTasksDialog
							showOtherMasterDialog={this.state.showSelectTagTasksDialog}
							handleSelectOtherMasterDialogClose={this.handleSelectTagTasksDialogClose}
							handleSelectOtherMasterDialogSaveClose={this.handleSelectTagTasksDialogSaveClose}
							selectedTagTasksObjArr={this.state.selectedTagTasksObjArr}
							observationId={this.state.observationId}
							masterType={mTTagTasks}
							themeSettings={themeSettings}
							assignmentId={this.state.assignmentId}
							isDraftOnward = {false}
							forceDisableCheckbox={"Y"}
						/>
						{
							!isEmptyVariable(this.state.assignmentDetails.riskId) &&
							<SelectRiskDialog
								showRiskDialog={this.state.showSelectRiskDialog}
								handleSelectRiskDialogClose={this.handleSelectRiskDialogClose}
								handleSelectRiskDialogSaveClose={this.handleSelectRiskDialogSaveClose}
								selectedRiskObj={this.state.selectedRiskObj}
								selectedRiskId={this.state.selectedRiskId}
								riskArr={isEmptyArray(this.state.deptDefaultRisk) ? [] : this.state.deptDefaultRisk}
								themeSettings={themeSettings}
								selectedLikelihoodRiskId={this.state.selectedLikelihoodRiskId}
                            	likelihoodRiskMatrix={this.state.likelihoodRiskMatrix}
								isLikelihood={this.state.isLikelihood}
							/>
						}
						{
							!isEmptyVariable(this.state.assignmentDetails.valueAdditionList) &&
							<SelectValueAdditionDialog
								showVADialog={this.state.showSelectVADialog}
								handleSelectVADialogClose={this.handleSelectVADialogClose}
								handleSelectVADialogSaveClose={this.handleSelectVADialogSaveClose}
								selectedVAObj={this.state.selectedVAObj}
								valueAdditionArr={this.state.valueAdditions}
								themeSettings={themeSettings}
							/>
						}
						<UploadAnnexureDialog
							showUploadAnnexureDialog={this.state.showSelectUploadAnnexureDialog}
							handleSelectUploadAnnexureDialogClose={this.handleSelectUploadAnnexureDialogClose}
							handleSelectUploadAnnexureDialogSaveClose={this.handleSelectUploadAnnexureDialogSaveClose}
							uploadedAnnexureArr={this.state.uploadedAnnexureArr}
							annexure_NR={this.state.annexure_NR}
							themeSettings={themeSettings}
							isDraftOnward = {false}
						/>
						<UploadBackupDialog
							showUploadBackupDialog={this.state.showSelectUploadBackupDialog}
							handleSelectUploadBackupDialogClose={this.handleSelectUploadBackupDialogClose}
							handleSelectUploadBackupDialogSaveClose={this.handleSelectUploadBackupDialogSaveClose}
							uploadedBackupArr={this.state.uploadedBackupArr}
							backup_NR={this.state.backup_NR}
							themeSettings={themeSettings}
							isDraftOnward = {false}
						/>
						<AlertWithInput
							showAlertDialog={this.state.showAlertWithInputDialog}
							handleAlertDialogClose={this.handleAlertWithInputDialogClose}
							alertDialogHeading={"Instances"}
							alertDialogMessage={this.state.alertWithInputDialogMessage}
							proceedBtnClick={this.handleAddIntanceTable}
							proceedBtnLabel={"Create"}
							themeSettings={themeSettings}
							// showRadio={this.state.showRadio}
						/>
						<AlertDialog 
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={
                                (this.state.operationType === "Delete")
                                ?
                                this.handleDeleteTable
                                :
                                ""
                            }
                            proceedBtnLabel={this.state.proceedBtnLabel }
                            themeSettings={themeSettings}
                        />
						<LeftSideSelectDialog
                            showSelectDropdownDialog = {this.state.showSelectDropdownDialog}
                            handleSelectDropdownDialogClose = {this.handleSelectDropdownDialogClose}
                            fieldObj = {this.state.templateFieldItem}
                            viewOnly = {"N"}
                            themeSettings={themeSettings}
                            dialogSelectedValues={this.dialogSelectedValues}
                            fieldArrIndex = {this.state.deleteReactivateId}
                        />
					</section>
				</main>
			</Router>
		);
	}
}
export default AddEditObservation;
