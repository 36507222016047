import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {DialogSaveButton} from '../../common/Global/globalStyles.style';
import {getLocalStorageVariables,isEmptyVariable, getThemeSettingsVariables} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import AlertDialog from '../../common/AlertDialog';

const themeSettings  = getThemeSettingsVariables();
const userDetails  = getLocalStorageVariables();

class EditCountry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            formErrors:{},
            country:"",
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showCreateDialog){
            if(this.props.isEdit){
                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
                    formErrors:{},
                    country:this.props.selectedCountry
                })
            }
        }
    }

    // Handle Edit Country dialog close event
    handleCreateDialogClose = () => {
        this.setState({
            country:""
        },()=>{
            this.props.handleCreateDialogClose(false);
        });
    }

    // Handle input change event for the Country input field
    handleChange = (e) =>{
       const {name, value} = e.target;
       this.setState({ 
            [name]: value,
        });
    }

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
    }
    /*******************API CALLS*******************/
    
    handleUpdateCountry = () => {
        this.setState({
            showLoader:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.country)){
            isError = true;
            FormErrorsTemp['country'] = "Please enter country name";
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            fetch(Constants.UpdateCountry,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    country:this.state.country,
                    oldCountry:this.props.selectedCountry
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false
                    },() => {
                        this.props.handleCreateDialogClose(true);
                    });
                }else{
                    this.setState({
                        showLoader:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    })
                }
            });
        }
    }

    /*******************API CALLS END HERE*******************/

    render() {
        return(
            <>
            <Modal enforceFocus={false} className="custom-dialog add-city-dialog" show={this.props.showCreateDialog} backdrop="static">
                <Modal.Header>
                    <h5>{"Edit Country"}</h5>
                    <button 
                        onClick={this.handleCreateDialogClose}>
                        <span className="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />

                <div className="modal-body">
                    <div className="addnew-modal-form">
                        
                        <div className="addnew-modal-form-group">
                            <p>Country*</p>
                            <input type="text" placeholder="Enter Country Name"
                            name="country" value={this.state.country} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['country']) &&
                                <span className="cm-error">{this.state.formErrors['country']}</span>
                            }
                        </div>
                    </div>
                    
                    <div className="modal-close-save">
                        <div className="close-save-col">
                            <button onClick={this.handleCreateDialogClose} type="button" className="modal-close">Cancel</button>
                            <DialogSaveButton
                            themeSettings = {themeSettings}
                            onClick={this.handleUpdateCountry} type="button">Save</DialogSaveButton>
                        </div>
                    </div>
                </div>
            </Modal>
            <AlertDialog
                showAlertDialog={this.state.showAlertDialogInfo}
                handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                type={Constants.ALERT_TYPE_ALERT}
                alertDialogMessage={this.state.alertDialogMessageInfo}
                proceedBtnClick={this.handleAlertDialogCloseInfo}
                proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                themeSettings={themeSettings}
            />
            </>
        );
    }
}

export default EditCountry;
