import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { SearchSpan, AddNewButtonLayout, DialogSaveButton } from "../../../common/Global/globalStyles.style";
import Sidebar from "../../../common/SidebarCollapse";
import Topbar from "../../../common/Topbar";
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";
import TableDropDown from "../../../common/DropdownMenus/TableDropdown";
import AlertDialog from "../../../common/AlertDialog";
import Pagination from "../../../common/Global/pagination";
import RegularDropdown from "../../../common/DropdownMenus/RegularDropdown";
// import UpdateReviewStatusDialog from './ObservationListupdatereviewstatusdialog'
// import ReviewHistoryDialog from '../Common/reviewhistorydialog'
import {
	getLocalStorageVariables,
	getThemeSettingsVariables,
	isEmptyVariable,
	sortTable,
	isEmptyArray,
	GetOrderedMasterTabMenuExecute,
	removeHtmlTags,
} from "../../../common/Global/commonFunctions";
import { getDropdownActionArrayDoNotObservationList } from "../../../common/Global/reviewFunctions";
import { Modal } from "react-bootstrap";
import { BrowserRouter as Router } from "react-router-dom";
import ObservationDetailsLayout from "../../Execute/Observation/ObservationDetails/observationDetails";
import AssignmentHeading from "../Common/assignmentHeadingDetails";

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();

const departmentDropdownDefaultValue = "Select Department";
const departmentFilterDropdownDefaultValue = "All";

const sortObservationDetail = "observationDetail";
const sortRisk = "o.riskLevel";
// const sortStatus = "o.status";

const allStatus = "All";
// const reviewedStatus = "Reviewed";
// const inProgressStatus = "In Progress";
// const completedStatus = "Completed";
// const underReviewStatus = "Under Review";
// const inActiveStatus = "Inactive";

class ObservationList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assignmentId: this.props.assignmentId,

			componentDidMountFlag: false,
			apiSearchKey: "",
			searchkey: "",
			currentPageNo: 1,
			showLoader: false,
			resultSize: Constants.RESULT_SIZE,
			totalCount: 0,
			datalist: [],
			// statusPlaceholder: allStatus,
			resultSizePlaceholder: Constants.RESULT_SIZE ,
			sort: "",
			sortDir: "",
			assignmentStatus: "",
			assignmentDetails: "",
			company:{},

			departmentArr: [],
			selectedDepartmentName: departmentDropdownDefaultValue,
			selectedDepartmentId: "",

			deptOnly: "N",

			showAlertDialog: false,
			operationType: "",
			deleteReactivateId: "",
			deleteReactivateMasterType: "",
			reviewLevel: "",
			alertDialogMessage: "",
			alertDialogHeading: "",
			proceedBtnLabel: "",
			showBtnLoader:false,
			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",

			status: "All",
			resultSizeArr: Constants.RESULT_SIZE_DD_ARR,

			showObsDetailsLayout:false,
			selectedObsIdForDetails:"",
			reloadFlag:false,
		};
	}

	componentDidMount() {
		this.getInitData();
	}


	handleChangeSearch = (e) => {
		const { name, value } = e.target;

		this.setState({
			[name]: value,
		});
	};

	onSearchIconClick = () => {
		this.setState(
			{
				currentPageNo: 1,
			},
			() => {
				this.getObservationList();
			}
		);
	};

	onEnterBtnPress = (e) => {
		var code = e.keyCode || e.which;
		if (code === 13) {
			this.setState(
				{
					currentPageNo: 1,
				},
				() => {
					this.getObservationList();
				}
			);
		}
	};

	handleAlertDialogClose = () => {
		this.setState({
			showAlertDialog: false,
			alertDialogMessage: "",
		});
	};

	handleAlertDialogCloseInfo = () => {
		this.setState({
			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",
		});
	};

	resultSizeDropdownClick = (item, selectedIndex) => {
		let tempArr = this.state.resultSizeArr;
		let placeholder = "";

		for (const [i, value] of tempArr.entries()) {
			if (i === selectedIndex) {
				tempArr[i].isSelected = true;
				placeholder = value.label;
			} else {
				tempArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				currentPageNo: 1,
				resultSizeArr: tempArr,
				resultSize: item.label,
				resultSizePlaceholder: placeholder,
			},
			() => {
				this.getObservationList();
			}
		);
	};

	onTableDropDownItemClick = (item, dropdownItem) => {
		if (item.label === "Put Back") {
			this.setState({
				operationType: item.label,
				alertDialogHeading: "Put Back Observation",
				proceedBtnLabel: "Okay",
				deleteReactivateId: dropdownItem.observationId,
				showAlertDialog: true,
				alertDialogMessage: "Are you sure you want to put back observation " +  
					`${!isEmptyVariable(removeHtmlTags(dropdownItem.observationHeading))
					? `${removeHtmlTags(dropdownItem.observationHeading)}`
					: `${removeHtmlTags(dropdownItem.observationDetail)}`
				}`+"?" ,
			});
		} 
    };
    
    // handleUpdateReviewDialogClose = (reloadFlag) =>{
    //     this.setState({
    //         showUpdateReviewDialog:false,
    //         deleteReactivateMasterType:"",
    //         deleteReactivateId:"",
    //         reviewLevel:"",
    //     },()=>{
    //         if(reloadFlag){
    //             this.getObservationList();
    //         }
    //     });
    // }

    // handleReviewHistoryDialogClose = () =>{
    //     this.setState({
    //         showReviewHistoryDialog:false,
    //         deleteReactivateMasterType:"",
    //         deleteReactivateId:"",
    //     });
    // }

	sortTableLocal = (sortColumn) => {
		let sortObj = sortTable(sortColumn, this.state.sort, this.state.sortDir);

		this.setState(
			{
				sort: sortObj.sortTemp,
				sortDir: sortObj.sortDirTemp,
			},
			() => {
				this.getObservationList();
			}
		);
	};

	handleShowObsDetailsLayout = (observationId) => {
        
        this.setState({
            showObsDetailsLayout:true,
            selectedObsIdForDetails:observationId,
           
        })
    }
	handleHideObsDetailsLayout = () => {
        this.setState({
            showObsDetailsLayout:false,
            selectedObsIdForDetails:""
        })
    }

	hideDonotReportObservationsLayout = () =>{
		this.props.hideDonotReportObservationsLayout(this.state.reloadFlag);
	}
	/*******************API CALLS*******************/
	getInitData = () => {
		this.setState({
			showLoader: true,
		});
		let url ="";
		let assignmentUrl ="";
		if(this.props.isDraftOnward === true){
			assignmentUrl = Constants.GetDraftAssignmentsDetails;
			url = Constants.GetDraftDoNotReportObservations;
		}else{
			assignmentUrl = Constants.GetAssignmentsDetails;
			url = Constants.GetDoNotReportObservations;
		}

		if(this.props.isDraftOnward === true && !this.props.isInitDraftReport){
			assignmentUrl = Constants.GetDraftAssignmentsDetails;
			url = Constants.GetDraftDoNotReportObservations;
		}else if(!this.props.isDraftOnward && !this.props.isInitDraftReport){
			assignmentUrl = Constants.GetAssignmentsDetails;
			url = Constants.GetDoNotReportObservations;
		}else if( this.props.isInitDraftReport){
			assignmentUrl = Constants.GetInitDraftReportDetails;
			url = Constants.GetInitDonotReportDraftObservations;
		}

		Promise.all([
			fetch(url, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.state.assignmentId,
					resultsize: this.state.resultSize,
					status: this.state.status,
				}),
			}),
			fetch(Constants.GetUserMenu, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
				}),
			}),
			fetch(assignmentUrl, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.state.assignmentId,
				}),
			}),
		])
			.then(([masterRes, menuRes, assignmentRes]) => {
				return Promise.all([masterRes.json(), menuRes.json(), assignmentRes.json()]);
			})
			.then(([masterRes, menuRes, assignmentRes]) => {
				let datalistTemp = [];
				let totalCountTemp = 0;
				let deptTemp = [];
				let menusTemp = {};
				let companyTemp = {};
				let assignmentStatus = "";
				let assignmentDetails = {};
				let allSettings = [];
				let isRisk = false;
				if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
					datalistTemp = masterRes.data.observationList;
					totalCountTemp = masterRes.data.count;
				} else {
				}

				if (assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
					if(this.props.isDraftOnward === true){
                        assignmentDetails = assignmentRes.data.result;
						assignmentStatus = assignmentRes.data.result.assignmentStatus;
                    }
                    else{
						assignmentStatus = assignmentRes.data.assignmentDetails.assignmentStatus;
						assignmentDetails = assignmentRes.data.assignmentDetails;
					}

				} else {
				}
				if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
					deptTemp = menuRes.data.departments;
					menusTemp = menuRes.data.menus;
					companyTemp = menuRes.data.company;
					allSettings = menuRes.data.allSettings;
					isRisk = allSettings.some(e => e.settingColumn.includes(Constants.DISABLEDRISK) && e.settingColumnEnabled === "N" && 
            			e.departmentId === assignmentDetails.departmentId && e.assignmentTypeId === assignmentDetails.assignmentTypeId);

				} else {
					menusTemp = {};
				}
				let menusTemp2 = GetOrderedMasterTabMenuExecute(menusTemp);
				console.log(menusTemp2);

				let selectedDepartmentId = "";
				let selectedDepartmentName = departmentDropdownDefaultValue;
				if (!isEmptyArray(deptTemp) && deptTemp.length === 1) {
					selectedDepartmentId = deptTemp[0].departmentId;
					selectedDepartmentName = deptTemp[0].name;
				}

				// //Filter Dept array
				// let filterDeptArr = [];
				// let selectedFilterDepartmentId = "-100";
				// // let selectedFilterDepartmentName = "All";
				// if (!isEmptyArray(deptTemp) && deptTemp.length === 1) {
				// } else {
				// 	let obj = {
				// 		departmentId: "-100",
				// 		name: departmentFilterDropdownDefaultValue,
				// 		isSelected: true,
				// 	};
				// 	filterDeptArr = [...deptTemp];
				// 	filterDeptArr.splice(0, 0, obj);
				// }

				this.setState({
					componentDidMountFlag: true,
					showLoader: false,
					datalist: datalistTemp,
					totalCount: totalCountTemp,

					// filterDeptArr: filterDeptArr,
					// selectedFilterDepartmentId: selectedFilterDepartmentId,
					// selectedFilterDepartmentName: selectedFilterDepartmentName,
					menus: menusTemp2,
					company:companyTemp,
					assignmentStatus: assignmentStatus,
					assignmentDetails: assignmentDetails,
					isRisk:isRisk
				});
			});
	};

	getObservationList = () => {
		this.setState({
			showLoader: true,
		});

		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			assignmentId: this.state.assignmentId,
			pageNo: this.state.currentPageNo,
			search: this.state.searchkey,
			resultsize: this.state.resultSize,
			status: this.state.status,
			sort: this.state.sort,
			sortDir: this.state.sortDir,
		};

		let url ="";
		if(this.props.isDraftOnward === true){
			url = Constants.GetDraftDoNotReportObservations;
		}else{
			url = Constants.GetDoNotReportObservations;
		}

		fetch(url, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParam),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState({
						showLoader: false,
						datalist: data.data.observationList,
						totalCount: data.data.count,
						apiSearchKey: this.state.searchkey,
					});
				} else {
					this.setState({
						datalist: [],
						showLoader: false,
						totalCount: 0,
						componentDidMountFlag: true,
						apiSearchKey: this.state.searchkey,
					});
				}
			});
	};

	onChangePage = (page) => {
		// update state with new page of items
		if (page !== this.state.currentPageNo) {
			this.setState({
				showLoader: true,
			});

			let postParam = {
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				assignmentId: this.state.assignmentId,
				pageNo: page,
				search: this.state.searchkey,
				resultsize: this.state.resultSize,
				status: this.state.status,
				sort: this.state.sort,
				sortDir: this.state.sortDir,
			};

			let url ="";
			if(this.props.isDraftOnward === true){
				url = Constants.GetDraftDoNotReportObservations;
			}else{
				url = Constants.GetDoNotReportObservations;
			}
			fetch(url, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(postParam),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (data.responseCode === Constants.CODE_SUCCESS) {
						this.setState({
							showLoader: false,
							datalist: data.data.observationList,
							totalCount: data.data.count,
							apiSearchKey: this.state.searchkey,
							currentPageNo: page,
						});
					} else {
						this.setState({
							datalist: [],
							showLoader: false,
							apiSearchKey: this.state.searchkey,
						});
					}
				});
		}
	};

	handleRecordOperation = () => {
		let url = "";
		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			observationId: this.state.deleteReactivateId,
		};
		let callApi = true;

		if (this.state.operationType === "Put Back") {
			if(this.props.isDraftOnward ){
				url = Constants.RevertDoNotReportDraftObservation;
			}else if(!this.props.isDraftOnward ){
				url = Constants.RevertDoNotReport;
			}
		}

		if (callApi) {
			this.setState({
				showBtnLoader: true,
			});

			fetch(url, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(postParam),
			})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState(
						{
							deleteReactivateId: "",
							operationType: "",
							alertDialogHeading: "",
							proceedBtnLabel: "",
							alertDialogMessage: "",
							showAlertDialog: false,
							reloadFlag:true,
							showBtnLoader:false
						},
						() => {
							this.getObservationList();
						}
					);
				} else {
					this.setState({
						showBtnLoader: false,
						deleteReactivateId: "",
						operationType: "",
						alertDialogHeading: "",
						proceedBtnLabel: "",
						alertDialogMessage: "",
						showAlertDialog: false,
						showAlertDialogInfo: true,
						alertDialogMessageInfo: data.responseMessage,
					});
				}
			});
		}
	};
	/*******************API CALLS END HERE*******************/

	render() {
		return (
			<Router>
				<div className="m-t-10">
					{this.state.showLoader && (
						<div class="loader"></div>
					)}
					{
						!this.state.showObsDetailsLayout &&
						<div className="prev-next-layout">
								<div className="flex-center-layout secondary-top-bar">
									<div className="back-btn-layout" onClick={this.hideDonotReportObservationsLayout}>
										<span class="material-icons-outlined">arrow_back_ios</span>
									</div>
									<h6>{"Do not report Observation"}</h6>
								</div>
								{/* {
									this.props.allowEdit &&
									<AddNewButtonLayout themeSettings={themeSettings}>
										<a href={this.props.editUrl}>
											<span className="material-icons">edit</span>
											<p>Edit</p>
										</a>
									</AddNewButtonLayout>
								} */}
							</div>
						
					}
					{
						// Need to check component did mount flag, other wise until api is called,
						// it shows no items found layout.
						this.state.componentDidMountFlag &&
						!this.state.showObsDetailsLayout &&
						!isEmptyVariable(this.state.assignmentStatus) &&
						(this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_ONGOING ||
						this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_DRAFT ||
						this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_COMPLETED)
						&& (
							<div className="body-wrapper">
								<div className="search-addnew-bg">
									<div className="search-addnew">
										<div className="search-filter-layout">
											<FilterDropDown
												placeholder={this.state.resultSizePlaceholder}
												dropdownArr={this.state.resultSizeArr}
												onDropDownItemClick={this.resultSizeDropdownClick}
												themeSettings={themeSettings}
												mleft={0}
											/>
											<div className="search-col m-l-10">
												<input
													name="searchkey"
													type="text"
													onChange={this.handleChangeSearch}
													placeholder="Search"
													onKeyPress={this.onEnterBtnPress}
													value={this.state.searchkey}
												/>
												<SearchSpan themeSettings={themeSettings} onClick={this.onSearchIconClick} className="material-icons">
													{" "}
													search{" "}
												</SearchSpan>
											</div>

											{/* {this.state.filterDeptArr.length > 0 && (
												<FilterDropDown
													placeholder={this.state.selectedFilterDepartmentName}
													dropdownArr={this.state.filterDeptArr}
													onDropDownItemClick={this.deptFilterDropdownClick}
													name={"name"}
													themeSettings={themeSettings}
													dropdownWidth={"120px"}
												/>
											)} */}
										</div>
										
									</div>
								</div>

								{!isEmptyArray(this.state.datalist) && (
									<div className="common-tab-bg">
										<div className="common-table" style={{ width: "100%" }}>
											<table className="table">
												<thead>
													<tr>
														<th className="text-center" width={"5%"}>
															No.
														</th>
														<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortObservationDetail)}>
															<div className="sort-header">
																Observation
																<span className={
																		this.state.sort === sortObservationDetail ? "material-icons" : "material-icons hide-sort-arrow"
																	}
																>
																	{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																</span>
															</div>
														</th>
														{this.state.isRisk &&
															<th width={"15%"} className="c-pointer" onClick={this.sortTableLocal.bind(this, sortRisk)}>
																<div className="sort-header">Risk
																	<span className={
																			this.state.sort === sortRisk ? "material-icons" : "material-icons hide-sort-arrow"
																		}
																	>
																		{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																	</span>
																</div>
															</th>
														}
														{/* <th width={"15%"} className="c-pointer" onClick={this.sortTableLocal.bind(this, sortStatus)}>
															<div className="sort-header">Status
																<span className={
																		this.state.sort === sortStatus ? "material-icons" : "material-icons hide-sort-arrow"
																	}
																>
																	{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																</span>
															</div>
														</th> */}
														{
															!this.props.isReportCompleted  &&
															<th className="text-center" width={"8%"}>
																<div className="sort-header"  width={"5%"}></div>
															</th>
														}
													</tr>
												</thead>
												<tbody>
													{!isEmptyArray(this.state.datalist) &&
														this.state.datalist.map((item, idx) => {
															let actionObj = getDropdownActionArrayDoNotObservationList(
																// item.allowEdit,
																// item.allowReview,
																// item.status,
																// item.currentReviewStatus,
																// item.taskList
															);
															return (
																<tr>
																	<td className="text-center">
																		{(this.state.currentPageNo - 1) * this.state.resultSize + ++idx}
																	</td>
																	<td onClick={this.handleShowObsDetailsLayout.bind(this, item.observationId)}>
																		{!isEmptyVariable(item.observationDetail)?removeHtmlTags(item.observationDetail):""}
																	</td>
																	<td>{item.riskLevel}</td>
																	{/* <td>{item.status === underReviewStatus?item.editUser:item.status}</td> */}
																	{
																		!this.props.isReportCompleted  &&
																		<td className="text-center">
																			{actionObj.actionArr && actionObj.actionArr.length > 0 && (
																				<TableDropDown
																					actionArr={actionObj.actionArr}
																					onDropDownItemClick={this.onTableDropDownItemClick}
																					dropDownId={item}
																					dropdownWidth={actionObj.dropdownWidth}
																					themeSettings={themeSettings}
																				/>
																			)}
																		</td>
																	}
																</tr>
															);
														})}
												</tbody>
											</table>
										</div>
									</div>
								)}
								{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && 
									<div class="no-items-layout">
										<div class="no-items-card">
											<h6>
												{Constants.NO_RECORDS_WARNING}
											</h6>
										</div>
									</div>
								}
								<div className="pagination-layout">
									<div className="row custom-row">
										<div className="col-md-9 custom-col">
											{!isEmptyArray(this.state.datalist) && this.state.totalCount > this.state.datalist.length && (
												<Pagination
													totalLength={this.state.totalCount}
													items={this.state.datalist}
													onChangePage={this.onChangePage}
													pageSize={this.state.resultSize}
													currentPageNo={this.state.currentPageNo}
													initialPage={this.state.currentPageNo}
													themeSettings={themeSettings}
												/>
											)}
										</div>
										<div className="col-md-3 custom-col">
											<p>{"Total Records: " + this.state.totalCount}</p>
										</div>
									</div>
								</div>
							</div>
						)
					}
					{
						this.state.showObsDetailsLayout &&
						<ObservationDetailsLayout
							observationId = {this.state.selectedObsIdForDetails}
							assignmentId = {this.state.assignmentId}
							handleHideObsDetailsLayout = {this.handleHideObsDetailsLayout}
							// subscriptionType={this.state.user.subscriptionType}
							handleShowObsDetailsLayout={this.handleShowObsDetailsLayout}
							// editUrl ={this.props.editUrl}
							isDraftOnward = {this.props.isDraftOnward}
						/>
					}
					<AlertDialog
						showAlertDialog={this.state.showAlertDialog}
						handleAlertDialogClose={this.handleAlertDialogClose}
						type={
							(this.state.operationType === "Delete")
							?
							Constants.ALERT_TYPE_WARNING
							:
							Constants.ALERT_TYPE_INFO
						}
						alertDialogHeading={this.state.alertDialogHeading}
						alertDialogMessage={this.state.alertDialogMessage}
						proceedBtnClick={this.handleRecordOperation}
						proceedBtnLabel={this.state.proceedBtnLabel}
						showLoader={this.state.showBtnLoader}
						themeSettings={themeSettings}
					/>
					<AlertDialog 
						showAlertDialog={this.state.showAlertDialogInfo}
						handleAlertDialogClose={this.handleAlertDialogCloseInfo}
						type= {Constants.ALERT_TYPE_ALERT}
						alertDialogMessage={this.state.alertDialogMessageInfo}
						proceedBtnClick={this.handleAlertDialogCloseInfo}
						proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
						themeSettings={themeSettings}
					/>
				</div>


			</Router>
		);
	}
}

export default ObservationList;
