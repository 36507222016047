import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Sidebar from "../../common/Sidebar";
import { DialogSaveButton } from "../../common/Global/globalStyles.style";
import SettingTopMenu from "./topmenu";
import Topbar from "../../common/Topbar";
import { FilterCheckBox } from "../../common/Global/globalStyles.style";
import { getThemeSettingsVariables, getLocalStorageVariables, isEmptyVariable, 
    isEmptyArray } from "../../common/Global/commonFunctions";
import * as Constants from '../../common/Global/constants';
import DisplayCompletedReport from "./displayCompletedReport";
import AlertDialog from "../../common/AlertDialog";
const themeSettings = getThemeSettingsVariables();
let userDetails = "";

let clientDefaultSettings = {
    industryFlag: {
        settingColumn: "industryFlag",
        settingDisplayColumn: "Industry",
        settingColumnEnabled: "Y",
        settingValue: ""
    },
    fiscalYearFlag: {
        settingColumn: "fiscalYearFlag",
        settingDisplayColumn: "Fiscal Year Begining Date",
        settingColumnEnabled: "Y",
        settingValue: "1 April"
    },
}

export default class clientSettings extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isShowIndustrySelected: "N",
            menus: "",
            showLoader: false,
            showBtnLoader: false,
            componentDidMountFlag: false,
            formErrors: {},

            /// Display Table Stuff
            isDisplayTable: false,
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""

        }

        userDetails = getLocalStorageVariables();
    }

    componentDidMount() {
        this.getInitData();
    }

    getInitData = () => {
        this.setState({
            showLoader: true,
        });

        Promise.all([
            fetch(Constants.GetUserMenu, {
                method: "POST",
                mode: 'cors',
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken
                }),

            }),
            fetch(Constants.GetClientSetting, {
                method: "POST",
                mode: 'cors',
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken
                })
            })
        ]).then(([menus, clientSettings]) => {
            return Promise.all([
                menus.json(),
                clientSettings.json(),
            ]);
        }).then(([menus, clientSettings]) => {

            let tempMenusData = ""
            let tempClientSettings = {};
            if (
                menus.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menus.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (menus.responseCode === Constants.CODE_SUCCESS) {
                tempMenusData = menus.data.menus;
            }else{
                tempMenusData = ""
            }

            if (
                clientSettings.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                clientSettings.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (clientSettings.responseCode === Constants.CODE_SUCCESS) {
                tempClientSettings = clientSettings.data.settings;
                if (isEmptyArray(Object.keys(tempClientSettings))) {
                    tempClientSettings = clientDefaultSettings;
                }
            }else {
                tempClientSettings = ""
            }

            this.setState({
                componentDidMountFlag: true,
                showLoader: false,
                menus: tempMenusData,
                clientSettings: tempClientSettings
            })
        })
    }

    // This function to handle checkbox click events
    handleCheckBoxClick = (e) => {
        let isChecked = e.target.checked;
        const { name } = e.target;

        let clientSettingsTemp = this.state.clientSettings;
        clientSettingsTemp[name]["settingColumnEnabled"] = isChecked?"Y":"N";

        this.setState({
            clientSettings: clientSettingsTemp,
        });
    }

    // This function to handle input field change events
    handleTaskFieldsChange = (e) => {
        let FormErrorsTemp = this.state.formErrors;
        const { name, value } = e.target;
        let clientSettingsTemp = this.state.clientSettings;
        clientSettingsTemp[name]["settingValue"] = value;

        if (this.state.submitClickedAtleastOnce) {
            FormErrorsTemp[name]["settingColumn"] = "";
        }

        this.setState({
            clientSettings: clientSettingsTemp,
            formErrors: FormErrorsTemp
        });
    };

    // This function to handle update the settings
    handleSaveFields = () => {
        let isError = false;
        let formErrTemp = {};

        Object.values(this.state.clientSettings).forEach((item) => {
            if(item.showTextbox === "Y" && isEmptyVariable(item.settingValue)){
                isError = true;
                formErrTemp[item["settingColumn"]] = "Enter date and month."
            }
        });

        // if (!isNumber(this.state.selectedSettingsDepartment.daysToDelete)) {
        //     isError = true;
        //     formErrTemp["daysToDelete"] = "Please enter numeric values only";
        // }

        // if(!isNumber(this.state.selectedSettingsDepartment.daysToCompleteTask)){
        //     isError = true;
        //     formErrTemp["daysToCompleteTask"] = "Please enter numeric values only";
        // }

        
        if (isError) {
            this.setState({
                formErrors: formErrTemp
            });
        } else {
            this.setState({
                showBtnLoader: true,
            });
            fetch(Constants.SaveClientSetting, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    updatedValues: JSON.stringify(this.state.clientSettings),
                }),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                        localStorage.clear();
                        window.location = "/";
                    } else if (data.responseCode === Constants.CODE_SUCCESS) {
                        this.setState({
                            showBtnLoader: false,
                            showAlertDialogInfo: true,
                            alertDialogMessageInfo: "Settings updated successfully",
                        }, () => {
                            this.getInitData();
                        });
                    } else {
                        this.setState({
                            showBtnLoader: false,
                            showAlertDialogInfo:true,
                            alertDialogMessageInfo:data.responseMessage
                        })
                    }
                });
        }
    };

    displayTable = () => {
        this.setState({
            isDisplayTable: true
        })
    }

    closeReportTable = () => {
        this.setState({
            isDisplayTable: false
        })
    }

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
    }

    render() {
        return (
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                {
                                    this.state.componentDidMountFlag &&
                                    <SettingTopMenu themeSettings={themeSettings} menus={this.state.menus} />
                                }

                                {this.state.showLoader && (
                                    <div class="loader"></div>
                                )}

                                {
                                    this.state.componentDidMountFlag &&
                                    <div className="body-wrapper">
                                        <div className="row custom-row">
                                            <div className="col-md-12 custom-col">
                                                <div className="card-col">
                                                    {
                                                        Object.values(this.state.clientSettings).map((item, index, array) => {
                                                            return (
                                                                <div>
                                                                {index === 0 && <p style={{fontWeight:400, marginBottom: 15}}>Industry can be used to tagging / categorisation of the Task. During assigning the task this tag can be used to shortlist the tasks. Check the box to use the Industry Tag.</p>}
                                                                {index === 1 && <p style={{fontWeight:400, marginTop: 15}}>Choose the start of the fiscal year. Reports may be displayed based on the fiscal year.</p>}
                                                                <div className="row custom-row" style={{borderBottom: index == 0 ? '1px solid #ccc' : 0}}>
                                                                    <div className="col-md-2 custom-col flex-center-layout">
                                                                        <div className="add-new-form-group">
                                                                            <p>{item["settingDisplayColumn"]}</p>
                                                                        </div>
                                                                        {/* </div> */}
                                                                    </div>
                                                                    <div className="col-md-8 custom-col">
                                                                        <div className="flex-center-layout">
                                                                            {
                                                                                item.showCheckbox === "Y" &&
                                                                                <FilterCheckBox
                                                                                    themeSettings={themeSettings}
                                                                                    style={{ marginLeft: 0, marginBottom: 10 }}
                                                                                >
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        name={item["settingColumn"]}
                                                                                        id={item["settingColumn"]}
                                                                                        onChange={this.handleCheckBoxClick}
                                                                                        checked={item.settingColumnEnabled === "Y" ? true : false}
                                                                                    />

                                                                                    <label for={item["settingColumn"]}></label>

                                                                                </FilterCheckBox>
                                                                            }
                                                                            
                                                                            {
                                                                                item.showTextbox === "Y" &&
                                                                                <div className="add-new-form-group">
                                                                                    <input name={item["settingColumn"]} type="text"
                                                                                        value={item["settingValue"]}
                                                                                        onChange={this.handleTaskFieldsChange} />

                                                                                        {
                                                                                            !isEmptyVariable(this.state.formErrors[item["settingColumn"]]) &&
                                                                                            <span className="cm-error">{this.state.formErrors[item["settingColumn"]]}</span>
                                                                                        }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            );
                                                        })
                                                    }
                                                    <div className="modal-close-save m-t-15">
                                                        <div className="close-save-col">
                                                            <DialogSaveButton
                                                                themeSettings={themeSettings}
                                                                onClick={this.handleSaveFields}
                                                                type="button"
                                                                className="modal-save m-b-0"
                                                            >
                                                                {this.state.showBtnLoader ? <div class="loader-btn mt-2"></div> : 'Save'}
                                                            </DialogSaveButton>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                            </div>

                        </div>
                        <DisplayCompletedReport
                            isDisplayTable={this.state.isDisplayTable}
                            closeTable={this.closeReportTable}
                            themeSettings={themeSettings}
                        />
                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type={Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                            themeSettings={themeSettings}
                        />
                    </section>
                </main>
            </Router>
        );
    }
}
