import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import SpinnerLoader from 'react-loader-spinner';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import {isEmptyVariable, validatePassword,getLocalStorageVariables, getThemeSettingsVariables} from '../../common/Global/commonFunctions';
import AlertDialog from '../../common/AlertDialog';

let themeSettings  = getThemeSettingsVariables();

class changePasswordFirstTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password:"",
            confirmPassword:"",
            fromSubmitted:false,
            errors:[],
            isSuccess:false,
            componentDidMount:false,
            heading:"",
            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",
        }

    }

    componentDidMount()
    {
        if(localStorage.getItem('firstLogin')+"" === "1"){
        }else{
            this.props.history.push('/dashboard');
        }

        this.setState({
            isSuccess:true,
            componentDidMount:true,
            heading:"Change Your Password"
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    validateForm()
    {
        let error_flag = false;
        let errors = {};

        let validatePasswordObj = validatePassword(this.state.password);
        if(validatePasswordObj.err === true){
            error_flag = true;
            errors['password'] = validatePasswordObj.responseMsg;
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }

        if(isEmptyVariable(this.state.confirmPassword))
        {
            error_flag = true;
            errors['confirmPassword'] = "Please confirm password";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        if(this.state.password !== this.state.confirmPassword)
        {
            error_flag = true;
            errors['confirmPassword'] = "Please enter confirm password same as above";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }

        this.setState({
            errors: errors
        });
        return error_flag;
    }

    changePasswordFirstLogin = () => {
        this.setState({
            formSubmitted:true,
        });
        const userDetails  = getLocalStorageVariables();
        if(!this.validateForm())
        {
            fetch(Constants.ChangePasswordFirstTime,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    newPassword:this.state.password
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {

                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.props.history.push('/dashboard');
                }else{
                    this.setState({
                        formSubmitted:false,
                        password:"",
                        confirmPassword:"",
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    });
                }
            });
        }else{
            setTimeout(function(){
                this.setState({errors:[]});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);

           this.setState({
                formSubmitted:false,
            });
        }
    }

    render(){
        return(
            <Router>
                <main className="login-bg">
                    <div className="login-wrapper">
                        <div className="login-wrapper-col">

                            <div className="change-password-form">
                                <div className="login-form-logo">
                                    <img src="/assets/images/sameeksha.png" />
                                    <h6>{this.state.heading}</h6>
                                </div>
                                {
                                    this.state.isSuccess &&
                                    <div>
                                        <div className="login-form-col">
                                            <div className="login-input-group">
                                                <div className="login-field">
                                                    <span className="material-icons">lock</span>
                                                    <input type="password" placeholder="New Password" onChange={this.handleChange}
                                                    name="password" value={this.state.password}/>
                                                </div>
                                                <span className="errorspan-login">{this.state.errors.password}</span>
                                            </div>
                                    
                                            <div className="login-input-group">
                                                <div className="login-field">
                                                    <span className="material-icons">lock</span>
                                                    <input type="password" placeholder="Confirm Password" onChange={this.handleChange}
                                                    name="confirmPassword" value={this.state.confirmPassword}/>
                                                </div>

                                                <span className="errorspan-login">{this.state.errors.confirmPassword}</span>
                                            </div>

                                            <div className="login-input-group">
                                                <div className="login-button">
                                                    <button 
                                                        type="button" 
                                                        disabled={this.state.formSubmitted} 
                                                        onClick={this.changePasswordFirstLogin}>
                                                        Change Password
                                                        {
                                                            this.state.formSubmitted &&
                                                            <SpinnerLoader style={{ marginLeft:10 }} 
                                                            type={Constants.LOADER_TYPE} color={Constants.LOADER_COLOR} height={20} width={30} />
                                                        }
                                                    </button>
                                                </div>
                                                {/* <span className="errorspan-login">{this.state.errors.respMsg}</span> */}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <AlertDialog
                        showAlertDialog={this.state.showAlertDialogInfo}
                        handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                        type={Constants.ALERT_TYPE_ALERT}
                        alertDialogMessage={this.state.alertDialogMessageInfo}
                        proceedBtnClick={this.handleAlertDialogCloseInfo}
                        proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                        themeSettings={themeSettings}
                    />
                </main>
            </Router>
        );
    }
}

export default changePasswordFirstTime;