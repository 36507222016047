import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,
    isEmptyVariable} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import {DialogSaveButton} from '../../../common/Global/globalStyles.style';
import AlertDialog from '../../../common/AlertDialog';

const userDetails  = getLocalStorageVariables();

class UpdateReviewStatusDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,

            heading:"",
            formErrors:{},
            submitClickedAtleastOnce:false,
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showCreateTextDialog){
            if(this.props.isEdit){
                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
    
                    heading:this.props.heading,
                    formErrors:{},
                    submitClickedAtleastOnce:false,
                })
            }else{
                this.setState({
                    componentDidMountFlag:false,
                    showLoader:false,
    
                    heading:"",
                    formErrors:{},
                    submitClickedAtleastOnce:false,
                })
            }
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleAddEditTextDialogClose = () =>{
        this.props.handleAddEditTextDialogClose(false);
    }

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
    }

    handleCreateMaster = () => {
        this.setState({
            showLoader:true,
            submitClickedAtleastOnce:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.heading)){
            isError = true;
            FormErrorsTemp['heading'] = "Please enter the text";
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            let url = "";
            let postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
            }

            if(this.props.isEdit){
                if(this.props.isDraftOnward === true){
                    url = Constants.UpdateDrafttext;
                }else{
                    url = Constants.Updatetext;
                }

                postParam.assignmentId = this.props.assignmentId;
                postParam.observationId = this.props.selectedId;
                postParam.text = this.state.heading;
            }else{
                if(this.props.isDraftOnward === true){
                    url = Constants.AddDrafttext;
                }else{
                    url = Constants.Addtext;
                }

                postParam.assignmentId = this.props.assignmentId;
                postParam.reportHeadingId = this.props.reportHeadingId;
                postParam.text = this.state.heading;
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.props.handleAddEditTextDialogClose(true);
                }else{
                    this.setState({
                        showLoader:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    });
                }
            });
        }
    }

    render() {
        return(
            <>
            <Modal className="custom-dialog" show={this.props.showCreateTextDialog} backdrop="static">
                <Modal.Header>
                    <h5>{this.props.isEdit?"Edit Text":"Add New Text"}</h5>
                    <button 
                        onClick={this.handleAddEditTextDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div class="modal-body">
                    <div class="addnew-modal-form">
                        <div class="addnew-modal-form-group">
                            <p>Text*</p>
                            <input type="text" placeholder="Enter Function Name" spellCheck="true" 
                            name="heading" value={this.state.heading} onChange={this.handleChange}/>
                            {
                                !isEmptyVariable(this.state.formErrors['heading']) &&
                                <span class="cm-error">{this.state.formErrors['heading']}</span>
                            }
                            {/* {
                                !isEmptyVariable(this.state.formErrors['apiErr']) &&
                                <span class="cm-error">{this.state.formErrors['apiErr']}</span>
                            } */}
                        </div>
                    </div>
                    <div class="modal-close-save">
                        <div class="close-save-col">
                            <button onClick={this.handleAddEditTextDialogClose} type="button" class="modal-close">Cancel</button>
                            <DialogSaveButton 
                            themeSettings={this.props.themeSettings}
                            onClick={this.handleCreateMaster} type="button">
                                {this.state.showLoader ? <div class="loader-btn mt-2"></div> : 'Save'}
                            </DialogSaveButton>
                        </div>
                    </div>
                </div>
            </Modal>
            <AlertDialog
                showAlertDialog={this.state.showAlertDialogInfo}
                handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                type={Constants.ALERT_TYPE_ALERT}
                alertDialogMessage={this.state.alertDialogMessageInfo}
                proceedBtnClick={this.handleAlertDialogCloseInfo}
                proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                themeSettings={this.props.themeSettings}
            />
        </>
        );
    }
}

export default UpdateReviewStatusDialog;