import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { SearchSpan, AddNewButtonLayout } from "../../../common/Global/globalStyles.style";
import Sidebar from "../../../common/Sidebar";
import Topbar from "../../../common/Topbar";
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";
import Pagination from "../../../common/Global/pagination";
import MasterMenuTabs from "../Common/topmenu";
import {
	getLocalStorageVariables,
	getThemeSettingsVariables,
	isEmptyVariable,
	sortTable,
	isEmptyArray,
	GetTemplateTabMenu,
    getStatusSpan
} from "../../../common/Global/commonFunctions";
import TableDropDown from "../../../common/DropdownMenus/TableDropdown";
import { BrowserRouter as Router } from "react-router-dom";
import * as TemplateConstants from '../../../common/Global/templateConstants';
import {getDropdownActionArrayTemplateList} from '../../../common/Global/reviewFunctions';
import AlertDialog from "../../../common/AlertDialog";
import UpdateReviewStatusDialog from '../Common/updatereviewstatusdialog'
import ReviewHistoryDialog from '../../../common/reviewhistorydialog'
import SelectAssignmentTypeDialog from '../../Tasks/selectAssignmentTypeDialog';
import SelectDepartmentDialog from '../../Tasks/selectDepartment';

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();

const activeStatus="Active";
const underReviewStatus="Under Review";
const sortType = "name";
const sortName = "templateName";
const departmentFilterDropdownDefaultValue = "All";

class ReportAssignmentList extends Component {
	constructor(props) {
		super(props);

        //let check the URL
        let templateType = TemplateConstants.TEMPLATE_TYPE_TASK;
        //this condition should be first since executetasktemplate contains tasktemplates
        if(this.props.location.pathname.includes(Constants.EX_TASK_TEMPLATES_PATH)){ 
            templateType = TemplateConstants.TEMPLATE_TYPE_EX_TASK;
        }else if(this.props.location.pathname.includes(Constants.TASK_TEMPLATES_PATH)){
            templateType = TemplateConstants.TEMPLATE_TYPE_TASK;
        }else if(this.props.location.pathname.includes(Constants.OBSERVATION_TEMPLATES_PATH)){
            templateType = TemplateConstants.TEMPLATE_TYPE_OBSERVATION;
        }else if(this.props.location.pathname.includes(Constants.OVERALL_TEMPLATES_PATH)){
            templateType = TemplateConstants.TEMPLATE_TYPE_OVERALL;
        }else if(this.props.location.pathname.includes(Constants.FOLLOWUP_TEMPLATES_PATH)){
            templateType = TemplateConstants.TEMPLATE_TYPE_FOLLOWUP;
        }else if(this.props.location.pathname.includes(Constants.AUDITEE_COMMENT_TEMPLATES_PATH)){
            templateType = TemplateConstants.TEMPLATE_TYPE_AUDITEE_COMMENT;
        }

		this.state = {
			componentDidMountFlag: false,
			apiSearchKey: "",
			searchkey: "",
			currentPageNo: 1,
			showLoader: false,
			resultSize: Constants.RESULT_SIZE,
			totalCount: 0,
			datalist: [],
			resultSizePlaceholder: Constants.RESULT_SIZE ,
			sort: "",
			sortDir: "",
            deletePermission:"N",
            templateType:templateType,
			
			statusPlaceholder:activeStatus,
			status:activeStatus,
            statusArr:[
                {
                    label:activeStatus,
                    isSelected:true
                },
                {
                    label:underReviewStatus,
                    isSelected:false
                },
                
            ],

			resultSizeArr: Constants.RESULT_SIZE_DD_ARR,
			showAlertDialog: false,
			operationType: "",
			deleteReactivateId: "",
			deleteReactivateMasterType: "",
			reviewLevel: "",
			alertDialogMessage: "",
			alertDialogHeading: "",
			proceedBtnLabel: "",
            actionType:"",
			showBtnLoader:false,
			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",
			showUpdateReviewDialog:false,
            showReviewHistoryDialog:false,

			showSelectAssigmentTypeDialog:false,
			//Change department
            showSelectDeptDialog:false,
            selectedDepartmentId:"",
			departmentArr:[],
		};
	}

	componentDidMount() {
		this.getInitData();
	}

	handleChangeSearch = (e) => {
		const { name, value } = e.target;

		this.setState({
			[name]: value,
		});
	};

	onSearchIconClick = () => {
		this.setState(
			{
				currentPageNo: 1,
			},
			() => {
				this.getTemplateList();
			}
		);
	};

	onEnterBtnPress = (e) => {
		var code = e.keyCode || e.which;
		if (code === 13) {
			this.setState(
				{
					currentPageNo: 1,
				},
				() => {
					this.getTemplateList();
				}
			);
		}
	};

    handleDetailsScreenShow = (item) => {
        if(this.state.templateType === TemplateConstants.TEMPLATE_TYPE_TASK){
            this.props.history.push(Constants.URL_TASK_TEMPLATE_DETAILS + "/" + item.templateId);
        }else if(this.state.templateType === TemplateConstants.TEMPLATE_TYPE_OBSERVATION){
            this.props.history.push(Constants.URL_OBSERVATION_TEMPLATE_DETAILS + "/" + item.templateId);
        }else if(this.state.templateType === TemplateConstants.TEMPLATE_TYPE_EX_TASK){
            this.props.history.push(Constants.URL_EXEC_TASK_TEMPLATE_DETAILS + "/" + item.templateId);
        }else if(this.state.templateType === TemplateConstants.TEMPLATE_TYPE_OVERALL){
            this.props.history.push(Constants.URL_OVERALL_TEMPLATES_DETAILS + "/" + item.templateId);
        }else if(this.state.templateType === TemplateConstants.TEMPLATE_TYPE_FOLLOWUP){
            this.props.history.push(Constants.URL_FOLLOWUP_TEMPLATE_DETAILS+ "/" + item.templateId);
        }else if(this.state.templateType === TemplateConstants.TEMPLATE_TYPE_AUDITEE_COMMENT){
            this.props.history.push(Constants.URL_AUDITEE_COMMENT_TEMPLATES_DETAILS + "/" + item.templateId);
        }
    }

	statusDropdownClick = (item, selectedIndex) => {
		let tempArr = this.state.statusArr;
		let placeholder = "";

		for (const [i, value] of tempArr.entries()) {
			if (i === selectedIndex) {
				tempArr[i].isSelected = true;
				placeholder = value.label;
			} else {
				tempArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				currentPageNo: 1,
				statusArr: tempArr,
				status: item.label,
				statusPlaceholder: placeholder,
			},
			() => {
				this.getTemplateList();
			}
		);
	};

	resultSizeDropdownClick = (item, selectedIndex) => {
		let tempArr = this.state.resultSizeArr;
		let placeholder = "";

		for (const [i, value] of tempArr.entries()) {
			if (i === selectedIndex) {
				tempArr[i].isSelected = true;
				placeholder = value.label;
			} else {
				tempArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				resultSizeArr: tempArr,
				resultSize: item.label,
				resultSizePlaceholder: placeholder,
			},
			() => {
				this.getTemplateList();
			}
		);
	};

	deptFilterDropdownClick = (item,selectedIndex) => {
        let placeholder = "";
        let id = "";
        let filterDeptArr = this.state.filterDeptArr;

        for(const [i,value] of filterDeptArr.entries()){
            if(i === selectedIndex){
                filterDeptArr[i].isSelected = true;
                placeholder = value.name;
                id = value.departmentId;
            }else{
                filterDeptArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo: 1,
            selectedFilterDepartmentName:placeholder,
            selectedFilterDepartmentId:id
        },() => {
            this.getTemplateList();
        })
    }

	onTableDropDownItemClick = (item, dropdownItem) => {
		if (item.label === "Edit") {
			if(this.state.templateType === TemplateConstants.TEMPLATE_TYPE_TASK){
				this.props.history.push(Constants.URL_EDIT_TASK_TEMPLATE + "/" + dropdownItem.templateId);
			}else if(this.state.templateType === TemplateConstants.TEMPLATE_TYPE_OBSERVATION){
				this.props.history.push(Constants.URL_EDIT_OBSERVATION_TEMPLATE + "/" + dropdownItem.templateId);
			}else if(this.state.templateType === TemplateConstants.TEMPLATE_TYPE_EX_TASK){
				this.props.history.push(Constants.URL_EDIT_EXEC_TASK_TEMPLATE + "/" + dropdownItem.templateId);
			}else if(this.state.templateType === TemplateConstants.TEMPLATE_TYPE_OVERALL){
				this.props.history.push(Constants.URL_EDIT_OVERALL_TEMPLATES + "/" + dropdownItem.templateId);
			}else if(this.state.templateType === TemplateConstants.TEMPLATE_TYPE_FOLLOWUP){
				this.props.history.push(Constants.URL_EDIT_FOLLOWUP_TEMPLATE + "/" + dropdownItem.templateId);
			}else if(this.state.templateType === TemplateConstants.TEMPLATE_TYPE_AUDITEE_COMMENT){
				this.props.history.push(Constants.URL_EDIT_AUDITEE_COMMENT_TEMPLATES + "/" + dropdownItem.templateId);
			}
		}else if(item.label === "Delete"){
            this.setState({
				operationType: item.label,
				alertDialogHeading: "Delete Template",
				proceedBtnLabel: "Delete",
				deleteReactivateId: dropdownItem.templateId,
				showAlertDialog: true,
				alertDialogMessage: "Are you sure you want to delete " + dropdownItem.templateName + "?",
			});
		} else if (item.label === "Submit For Review") {
			this.setState({
				operationType: item.label,
				alertDialogHeading: "Submit For Review",
				proceedBtnLabel: "Submit",
				deleteReactivateId: dropdownItem.templateId,
				showAlertDialog: true,
				alertDialogMessage: "Are you sure you want to submit " + dropdownItem.templateName + " for review?",
			});
		}else if(item.label === "Update Review Status"){
            this.setState({
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.templateId,
                showUpdateReviewDialog:true,
            })
        }else if(item.label === "Review History"){
            this.setState({
                deleteReactivateId:dropdownItem.templateId,
                showReviewHistoryDialog:true,
            })
        }else if(item.label === "Review Remarks"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Review Remarks",
                proceedBtnLabel:"Okay",
                showAlertDialog:true,
                alertDialogMessage:'\"'+dropdownItem.lastReviewRemark+'\"'
            })
        }else if(item.label === "Start Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Start Review",
                proceedBtnLabel:"Start",
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.templateId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to start review of "+dropdownItem.templateName
            })
        }else if(item.label === "Discard Changes"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Discard Changes",
                proceedBtnLabel:"Discard",
                deleteReactivateId:dropdownItem.templateId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to discard changes of "+dropdownItem.templateName+"?",
                actionType: "Update"
            })
        }
	};

	sortTableLocal = (sortColumn) => {
		let sortObj = sortTable(sortColumn, this.state.sort, this.state.sortDir);

		this.setState(
			{
				sort: sortObj.sortTemp,
				sortDir: sortObj.sortDirTemp,
			},
			() => {
				this.getTemplateList();
			}
		);
	};

	handleAlertDialogClose = () => {
		this.setState({
			showAlertDialog: false,
			alertDialogMessage: "",
		});
	};

	handleAlertDialogCloseInfo = () => {
		this.setState({
			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",
		});
	};

	handleUpdateReviewDialogClose = (reloadFlag) =>{
        this.setState({
            showUpdateReviewDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
            reviewLevel:"",
        },()=>{
            if(reloadFlag === true){
                this.getTemplateList();
            }
        });
    }

    handleReviewHistoryDialogClose = () =>{
        this.setState({
            showReviewHistoryDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
        });
    }

	//AssignmentType
    handleSelectAssignmentTypeDialogClose = () => {
        this.setState({
            showSelectAssigmentTypeDialog:false,
        });
    }
    handleSelectAssignmentTypeDialogSaveClose = (selectedAssigmentTypeId) => {
		this.props.history.push(Constants.URL_ADD_EXEC_TASK_TEMPLATE+"/"+selectedAssigmentTypeId);
        // this.props.history.push(Constants.URL_ADDTASK_WITH_TEMPLATES+"/"+this.state.selectedProcessId+"-"+selectedAssigmentTypeId);
    }
	
	handleAddTemplate = () => {
		if(this.state.templateType === TemplateConstants.TEMPLATE_TYPE_OBSERVATION){
			// this.props.history.push(Constants.URL_ADD_OBSERVATION_TEMPLATE);
			if(!isEmptyArray(this.state.departmentArr) && this.state.departmentArr.length >1){
				this.setState({
					showSelectDeptDialog:true,
				});
			}else{
				this.props.history.push(Constants.URL_ADD_OBSERVATION_TEMPLATE+"/"+this.state.selectedDepartmentId)

			}
		}else if(this.state.templateType === TemplateConstants.TEMPLATE_TYPE_EX_TASK){
			this.setState({
				showSelectAssigmentTypeDialog:true,
			});
		}
	}

	//Department
    handleSelectDeptDialogClose = () => {
		this.setState({
			showSelectDeptDialog:false,
		})
    }
    handleSelectDeptDialogSaveClose = (selectedDepartmentId) => {
		this.props.history.push(Constants.URL_ADD_OBSERVATION_TEMPLATE+"/"+selectedDepartmentId)

		// this.setState({
		// 	showSelectDeptDialog:false,
		// 	selectedDepartmentId:selectedDepartmentId,
		// },()=>{
		// 	this.props.history.push(Constants.URL_ADD_OBSERVATION_TEMPLATE+"/"+selectedDepartmentId)
		// })
}
    handleSelectDeptDialogShow = () => {
        this.setState({
            showSelectDeptDialog:true,
        })
    }
	/*******************API CALLS*******************/
	getInitData = () => {
		this.setState({
			showLoader: true,
		});

		Promise.all([
			fetch(Constants.GetTemplates, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					templateType:this.state.templateType,
					pageNo: this.state.currentPageNo,
					resultsize: this.state.resultSize,
					status_new: this.state.status,
					sort: this.state.sort,
					sortDir: this.state.sortDir,
				}),
			}),
			fetch(Constants.GetUserMenu, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
				}),
			}),
		])
        .then(([generalRes, menuRes]) => {
            return Promise.all([generalRes.json(), menuRes.json()]);
        })
        .then(([generalRes, menuRes]) => {
            let datalistTemp = [];
            let totalCountTemp = 0;
            let menusTemp = {};
			let deptArrTemp = [];
			let selectedDepartmentId = "";

            if (generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            } else if (generalRes.responseCode === Constants.CODE_SUCCESS) {
                datalistTemp = generalRes.data.result;
                totalCountTemp = generalRes.data.count;
            } else {
            }
            if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                menusTemp = menuRes.data.menus;
				deptArrTemp = menuRes.data.departments;

				if(deptArrTemp.length === 1){
					selectedDepartmentId = menuRes.data.departments[0].departmentId;
				}
            } else {
            }
            
            let menusTemp2 = GetTemplateTabMenu(menusTemp);
            let deletePermission = "N";

            if(this.state.templateType === TemplateConstants.TEMPLATE_TYPE_EX_TASK &&
                menusTemp["Delete Execution Template"] === "ALL"){
                deletePermission = "Y";
            }

            if(this.state.templateType === TemplateConstants.TEMPLATE_TYPE_OBSERVATION &&
                menusTemp["Delete Observation Template"] === "ALL"){
                deletePermission = "Y";
            }
			
			//Filter Dept array
            let filterDeptArr = [];
            let selectedFilterDepartmentId = "-100";
            let selectedFilterDepartmentName = "All";
            if(!isEmptyArray(deptArrTemp) && deptArrTemp.length===1){

            }else{
                let obj = {
                    departmentId:"-100",
                    name:departmentFilterDropdownDefaultValue,
                    isSelected:true
                }
                filterDeptArr = [...deptArrTemp];
                filterDeptArr.splice(0,0,obj)
            }

            this.setState({
                componentDidMountFlag: true,
                showLoader: false,
                datalist: datalistTemp,
                totalCount: totalCountTemp,
                menus: menusTemp2,
                deletePermission:deletePermission,
				departmentArr:deptArrTemp,
				selectedDepartmentId:selectedDepartmentId,

				filterDeptArr:filterDeptArr,
                selectedFilterDepartmentId:selectedFilterDepartmentId,
                selectedFilterDepartmentName:selectedFilterDepartmentName,
            });
        });
	};

	getTemplateList = () => {
		this.setState({
			showLoader: true,
		});

		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			templateType:this.state.templateType,
			pageNo: this.state.currentPageNo,
			search: this.state.searchkey,
			resultsize: this.state.resultSize,
			status_new: this.state.status,
			sort: this.state.sort,
			sortDir: this.state.sortDir,
		};
		if(this.state.filterDeptArr.length > 1 &&
            this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
            postParam.departmentId = this.state.selectedFilterDepartmentId;
        }
		fetch(Constants.GetTemplates, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParam),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState({
						showLoader: false,
						datalist: data.data.result,
						totalCount: data.data.count,
						apiSearchKey: this.state.searchkey,
					});
				} else {
					this.setState({
						datalist: [],
						showLoader: false,
						totalCount: 0,
						componentDidMountFlag: true,
						apiSearchKey: this.state.searchkey,
					});
				}
			});
	};

	onChangePage = (page) => {
		// update state with new page of items
		if (page !== this.state.currentPageNo) {
			this.setState({
				showLoader: true,
			});

			let postParam = {
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				templateType:this.state.templateType,
				pageNo: page,
				search: this.state.searchkey,
				resultsize: this.state.resultSize,
				status_new: this.state.status,
				sort: this.state.sort,
				sortDir: this.state.sortDir,
			};
			if(this.state.filterDeptArr.length > 1 &&
                this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
                postParam.departmentId = this.state.selectedFilterDepartmentId;
            }

			fetch(Constants.GetTemplates, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(postParam),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (data.responseCode === Constants.CODE_SUCCESS) {
						this.setState({
							showLoader: false,
							datalist: data.data.result,
							totalCount: data.data.count,
							apiSearchKey: this.state.searchkey,
							currentPageNo: page,
						});
					} else {
						this.setState({
							datalist: [],
							showLoader: false,
							apiSearchKey: this.state.searchkey,
						});
					}
				});
		}
	};

	handleRecordOperation = () => {

		let url = "";
		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			templateId: this.state.deleteReactivateId,
		};
		let callApi = true;

		if (this.state.operationType === "Delete") {
			url = Constants.DeleteTemplate;
		}else if(this.state.operationType === "Submit For Review"){
            url = Constants.SubmitTemplateForReview;
        }else if(this.state.operationType === "Start Review"){
            url = Constants.UpdateTemplateReviewStatus;
            postParam.reviewLevel = this.state.reviewLevel;
            postParam.reviewStatus = Constants.REVIEW_STATUS_STARTED;
        }else if(this.state.operationType === "Discard Changes"){
            url = Constants.DiscardChangeTemplate;
            postParam.actionType = this.state.actionType;
        }else if(this.state.operationType === "Review Remarks"){
		    callApi = false;
		    this.setState({
		        operationType:"",
		        alertDialogHeading:"",
		        proceedBtnLabel:"",
		        alertDialogMessage:"",
		        showAlertDialog:false,
		    })
		}

		if (callApi) {
			this.setState({
				showBtnLoader: true,
			});

			fetch(url, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(postParam),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (data.responseCode === Constants.CODE_SUCCESS) {
						this.setState(
							{
								deleteReactivateId: "",
								operationType: "",
								alertDialogHeading: "",
								proceedBtnLabel: "",
								alertDialogMessage: "",
								actionType:"",
								showAlertDialog: false,
								showBtnLoader: false
							},
							() => {
								this.getTemplateList();
							}
						);
					} else {
						this.setState({
							showBtnLoader: false,
							deleteReactivateId: "",
							operationType: "",
							alertDialogHeading: "",
							proceedBtnLabel: "",
							alertDialogMessage: "",
							actionType:"",
							showAlertDialog: false,
							showAlertDialogInfo: true,
							alertDialogMessageInfo: data.responseMessage,
						});
					}
				});
		}

	};

	/*******************API CALLS END HERE*******************/

	render() {
		return (
			<Router>
				<main className="main-dashboard">
					<section className="dashboard-bg">
						<Sidebar />

						<div className="dashboard-right-bg position-relative">
							<div className="dashboard-wrapper">
								<Topbar />
								<MasterMenuTabs 
									assignmentId={this.state.assignmentId}
									 masterMenus={this.state.menus} 
									 themeSettings={themeSettings} 
								/>
								{this.state.showLoader && (
									<div class="loader"></div>
								)}
								{
									// Need to check component did mount flag, other wise until api is called,
									// it shows no items found layout.
									this.state.componentDidMountFlag && (
										<div className="body-wrapper">
											<div className="search-addnew-bg">
												<div className="search-addnew">
													<div className="search-filter-layout">
														<FilterDropDown
															placeholder={this.state.resultSizePlaceholder}
															dropdownArr={this.state.resultSizeArr}
															onDropDownItemClick={this.resultSizeDropdownClick}
															themeSettings={themeSettings}
															mleft={0}
														/>
														<div className="search-col m-l-10">
															<input
																name="searchkey"
																type="text"
																onChange={this.handleChangeSearch}
																placeholder="Search"
																onKeyPress={this.onEnterBtnPress}
																value={this.state.searchkey}
															/>
															<SearchSpan themeSettings={themeSettings} onClick={this.onSearchIconClick} className="material-icons">
																{" "}
																search{" "}
															</SearchSpan>
														</div>

														<FilterDropDown
															placeholder={this.state.statusPlaceholder}
															dropdownArr={this.state.statusArr}
															onDropDownItemClick={this.statusDropdownClick}
															themeSettings={themeSettings}
														/>
														{
															this.state.filterDeptArr.length > 0 &&
															<FilterDropDown
																placeholder={this.state.selectedFilterDepartmentName}
																dropdownArr={this.state.filterDeptArr}
																onDropDownItemClick={this.deptFilterDropdownClick}
																name={"name"}
																themeSettings={themeSettings}
																dropdownWidth={"120px"}
															/>
														}
													</div>
													{
														(this.state.templateType === TemplateConstants.TEMPLATE_TYPE_OBSERVATION ||
														this.state.templateType === TemplateConstants.TEMPLATE_TYPE_EX_TASK ) &&
														<div className="flex-center-layout">
															<AddNewButtonLayout themeSettings={themeSettings}>
																{/* <a  href={Constants.URL_ADD_OBSERVATION_TEMPLATE}> */}
																<a  onClick={this.handleAddTemplate}>
																	<span className="material-icons">add_circle</span>
																	<p>Add Template</p>
																</a>
															</AddNewButtonLayout>
														</div>
													}
												</div>
											</div>

											{!isEmptyArray(this.state.datalist) && (
												<div className="common-tab-bg">
													<div className="common-table" style={{ width: "100%" }}>
														<table className="table">
															<thead>
																<tr>
																	<th className="text-center" width={"5%"}>
																		No.
																	</th>
																	<th className="c-pointer" width={"50%"}>
																		<div className="sort-header" onClick={this.sortTableLocal.bind(this, sortName)}>
																			Template Name
																			<span className={
                                                                                this.state.sort === sortName ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
                                                                    {
                                                                        this.state.templateType !== TemplateConstants.TEMPLATE_TYPE_OBSERVATION &&
                                                                        <th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortType)}>
                                                                            <div className="sort-header">
                                                                                Assignment Type
                                                                                <span className={
                                                                                    this.state.sort === sortType ? "material-icons" : "material-icons hide-sort-arrow"
                                                                                    }
                                                                                >
                                                                                    {this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                    }
																	
																	<th className="text-center" width={"5%"}>
																		<div className="sort-header"></div>
																	</th>
																</tr>
															</thead>
															<tbody>
																{!isEmptyArray(this.state.datalist) &&
																	this.state.datalist.map((item, idx) => {
																		let actionObj = getDropdownActionArrayTemplateList(item.allowEdit, item.allowReview, 
																			item.status, item.currentReviewStatus, item.status_new, item.previousStatus, 
                                                                            this.state.templateType,this.state.deletePermission,item.isDefault);
                                                                        let spanObj = getStatusSpan(item.previousStatus,themeSettings);
																		return (
																			<tr onClick={this.handleDetailsScreenShow.bind(this,item)}>
																				<td className="text-center">
																					{(this.state.currentPageNo - 1) * this.state.resultSize + ++idx}
																				</td>
																				<td>
                                                                                    {
                                                                                        item.templateName + 
                                                                                        (this.state.templateType !== TemplateConstants.TEMPLATE_TYPE_TASK
                                                                                            ?" (V"+item.version+")"
                                                                                            :"")
                                                                                    }
                                                                                    {
                                                                                        this.state.status === "Under Review" &&
                                                                                        <span style={spanObj.spanStyle} className="span-tag">
                                                                                            {spanObj.spanText}
                                                                                        </span>
                                                                                    }   
                                                                                </td>
                                                                                {
                                                                                    this.state.templateType !== TemplateConstants.TEMPLATE_TYPE_OBSERVATION &&
                                                                                    <td>{item.name}</td>
                                                                                }
																				<td className="text-center">
																					{actionObj.actionArr && actionObj.actionArr.length > 0 && (
																						<TableDropDown
																							actionArr={actionObj.actionArr}
																							onDropDownItemClick={this.onTableDropDownItemClick}
																							dropDownId={item}
																							dropdownWidth={actionObj.dropdownWidth}
																							themeSettings={themeSettings}
																						/>
																					)}
																				</td>
																			</tr>
																		);
																	})}
															</tbody>
														</table>
													</div>
												</div>
											)}
											{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
												<div class="no-items-layout">
													<div class="no-items-card">
														<h6>
															{isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}
														</h6>
													</div>
												</div>
											)}
											<div className="pagination-layout">
												<div className="row custom-row">
													<div className="col-md-9 custom-col">
														{this.state.totalCount > this.state.datalist.length && (
															<Pagination
																totalLength={this.state.totalCount}
																items={this.state.datalist}
																onChangePage={this.onChangePage}
																pageSize={this.state.resultSize}
																currentPageNo={this.state.currentPageNo}
																initialPage={this.state.currentPageNo}
																themeSettings={themeSettings}
															/>
														)}
													</div>
													<div className="col-md-3 custom-col">
														<p>{"Total Records: " + this.state.totalCount}</p>
													</div>
												</div>
											</div>
										</div>
									)
								}
							</div>
						</div>

						<AlertDialog
							showAlertDialog={this.state.showAlertDialog}
							handleAlertDialogClose={this.handleAlertDialogClose}
							type={
								this.state.operationType === "Delete"
									? Constants.ALERT_TYPE_WARNING
									: this.state.operationType === "Review Remarks"
									? Constants.ALERT_TYPE_ALERT
									: Constants.ALERT_TYPE_INFO
							}
							alertDialogHeading={this.state.alertDialogHeading}
							alertDialogMessage={this.state.alertDialogMessage}
							proceedBtnClick={this.handleRecordOperation}
							proceedBtnLabel={this.state.proceedBtnLabel}
							showLoader={this.state.showBtnLoader}
							themeSettings={themeSettings}
						/>

						<AlertDialog
							showAlertDialog={this.state.showAlertDialogInfo}
							handleAlertDialogClose={this.handleAlertDialogCloseInfo}
							type={Constants.ALERT_TYPE_ALERT}
							alertDialogMessage={this.state.alertDialogMessageInfo}
							proceedBtnClick={this.handleAlertDialogCloseInfo}
							proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
							themeSettings={themeSettings}
						/>

						<UpdateReviewStatusDialog
                            showUpdateReviewDialog={this.state.showUpdateReviewDialog}
                            handleUpdateReviewDialogClose={this.handleUpdateReviewDialogClose}
                            masterType = {"Template"}
                            masterDataId = {this.state.deleteReactivateId}
                            reviewLevel={this.state.reviewLevel}
                            themeSettings={themeSettings}
                        />

						<ReviewHistoryDialog
                            showReviewHistoryDialog={this.state.showReviewHistoryDialog}
                            handleReviewHistoryDialogClose={this.handleReviewHistoryDialogClose}
                            masterType = {
                                this.state.templateType === TemplateConstants.TEMPLATE_TYPE_EX_TASK?
                                "Execution Template":(this.state.templateType === TemplateConstants.TEMPLATE_TYPE_OBSERVATION?
                                "Observation Template":"Task Template")
                                }
                            masterDataId = {this.state.deleteReactivateId}
                        />

						<SelectAssignmentTypeDialog
                            showOtherMasterDialog={this.state.showSelectAssigmentTypeDialog}
                            handleSelectOtherMasterDialogClose={this.handleSelectAssignmentTypeDialogClose}
                            handleSelectOtherMasterDialogSaveClose={this.handleSelectAssignmentTypeDialogSaveClose}
                            selectedAssigmentTypeId={this.state.selectedAssigmentTypeId}
                            masterType={"Assignment Type"}
                            themeSettings = {themeSettings}
                            // departmentId={this.state.selectedDepartmentId}
                            
                        />

						<SelectDepartmentDialog
                            showDeptDialog={this.state.showSelectDeptDialog}
                            handleSelectDeptDialogClose={this.handleSelectDeptDialogClose}
                            handleSelectDeptDialogSaveClose={this.handleSelectDeptDialogSaveClose}
                            selectedDeptId={this.state.selectedDepartmentId}
                            departmentArr={this.state.departmentArr}
                            themeSettings = {themeSettings}
                        />
					</section>
				</main>
			</Router>
		);
	}
}

export default ReportAssignmentList;
