import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import * as TemplateConstants from "../../../common/Global/templateConstants";
import {AddNewButtonLayout, TableCheckBox, DialogSaveButton} from '../../../common/Global/globalStyles.style';
import {Modal} from 'react-bootstrap';
import { getLocalStorageVariables, getThemeSettingsVariables, isEmptyVariable,
     isEmptyArray, removeHtmlTags, isJsonString, } from "../../../common/Global/commonFunctions";
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdownTable';
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";

//******************** FROM EXEC TASKS FOLDER ******************/
import { getCheckListScoresFlag, getAllReportTasks, getMatrixandMyDetails, getRankingArray } from '../../Execute/Tasks/execTaskUtilFunctions';
import SelectValueAdditionDialog from "../../Execute/Tasks/selectValueAddition";
import ExecTableDialog from "../../Execute/Common/execTableDialog";

//****************** FROM TASKS FOLDER ********************/
import DatePickerComponent from "../../Tasks/Common/dateSelectComponent";
import TaskDetailsLayout from '../../Tasks/taskDetailsWithTemplates';
import LeftSideSelectDialog from '../../Tasks/Common/selectDropdownValues';

import { BrowserRouter as Router } from "react-router-dom";
import { DatePicker } from "antd";
import "antd/dist/antd.css";
import moment from "moment";
import AlertDialog from "../../../common/AlertDialog";

const defaultWeightPlaceholder="Rank";
const defaultYNPlaceholder="Rank";
const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();
const resourceDropdownPlaceholder="Select Resource";

class AddTaskAndResource extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assignmentId: this.props.assignmentId,
            departmentId: this.props.departmentId,
			componentDidMountFlag: false,
			apiSearchKey: "",
			searchkey: "",
			currentPageNo: 1,
			showLoader: true,
			resultSize: Constants.RESULT_SIZE,
			totalCount: 0,
			datalist: [],
			allDataList: [],
            datalistArr: [],
			assignmentStatus: "",
			assignmentArea: "",
			assignmentDetails: "",
			company:{},
            selectedTaskTemplateFields:[],
            taskTemplateValuesMap:{},
            execTaskTemplateFields:[],
            executionTemplateValuesMap:{},
            assignmentRiskMatrix: [],
            selectedAssignmentTaskId:"",
            selectedAssignmentTaskRiskLevelId:"",
            assignmentTaskAllowEdit:"N",
            valueAdditions: [],
            executionTemplateFixedFields:{},

            //Value Addition
			showSelectVADialog: false,
			selectedVAObj: [],

			operationType: "",
			deleteReactivateId: "",
			deleteReactivateMasterType: "",
			reviewLevel: "",
			alertDialogMessage: "",
			alertDialogHeading: "",
			proceedBtnLabel: "",

            type:"",

			showTaskDetailsLayout:false,
			selectedTaskIdForDetails:"",
			resourceUser:{},
			
			assignmentMatrix: [],
			selectedResourceUserName:"",
			selectedResourceId:"",
			myPosition:"",

			showAlertWithTextareaDialog: false,
			alertWithTextareaDialogMessage: "",
			remarks:"",

			overallChecklistScoreObj:{},
            checklistMaxRank:0,

            showSelectDropdownDialog:false,
            templateFieldItem:{},

			isBackup: false,
            assignmentTaskUserId:"",

            filterDataList: [],
            filterDropDownArr: [],
            filterPlaceholder: "All",               
            companyDetials:{},
            unitDetials:[],

            alertExecuteDialogMessage: "",
            alertExecuteDialogHeading: "",

            functionMap:[],
            functionId:this.props.functionId,
            followUpAssignTask:[],

            showAssignResourcesDialogFlag:false,
            resourceDropdownPlaceholder:"Select Resource",

            assigneResourcesFilterDropDownArr :[],
            assigneResourcesFilterDataList:[],
            assignResourcesPlaceholder:"All",
            followUpDate:"",
            open:false,
            singleSelectDropDownArr: [],
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
		};
	}

	componentDidMount() {
		this.getInitData();
	}

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props)){
            this.setState({
                componentDidMountFlag:false,
                apiSearchKey:"",
                searchkey:"",
                currentPageNo: 1,
                showLoader:false,
                resultSize:Constants.RESULT_SIZE,
                totalCount:0,
                datalist:[],
                resultSizePlaceholder:Constants.RESULT_SIZE,
                users:[],
                processMap:[],
                functionId:this.props.functionId,
                resourceDropdownPlaceholder:"Select Resource",
            },()=>{
                this.getInitData();
            })
            
        }
    }

    getAllowEdit = (taskItem) => {
        //now check if user can edit report observations
        return !isEmptyVariable(taskItem.observationId)||taskItem.completionStatus==="Completed"||taskItem.allowEdit==="N"?true:false  
    }

	maxrDropdownClick = (selectedItem,udf) => {
        this.setState({
            showLoader:true
        })

        let iapArr = udf.split(":-:")
        let index = iapArr[0];
        let parentId = iapArr[1];
        let list = this.state.datalist;
        list[parentId].taskList[index].rank = selectedItem.id;
        
        this.setState({
            datalist:list,
            showLoader:false
        },()=>{
			this.handleSaveAssignmentTaskProperties(list[parentId].taskList[index].assignmentTaskId, selectedItem.id, "rank");
        });
    }

	handleShowTaskDetailsLayout = (taskId) => {
        this.setState({
            showTaskDetailsLayout:true,
            selectedTaskIdForDetails:taskId,
        })
    }

    handleHideTaskDetailsLayout = () => {
        this.setState({
            showTaskDetailsLayout:false,
            selectedTaskIdForDetails:""
        })
    }

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
    }

	getUserTasksFromUserId = (userId) => {
		let allData = JSON.parse(JSON.stringify(this.state.allDatalist))
        let tempDataList = this.state.datalist
        tempDataList.map((dataItem,index) => {
            dataItem.taskList.map((taskItem,taskIndex) => {
                allData[index].taskList[taskIndex].added = taskItem.added;
                allData[index].taskList[taskIndex].isCheckboxSelected = taskItem.isCheckboxSelected;
                allData[index].taskList[taskIndex].isResourceCheckboxSelected = taskItem.isResourceCheckboxSelected;
            })
        })
		let arr =  allData.map(process=>{
			let taskList = [];
            let maxScore = 0;
            let totalScore = 0;
			process.taskList.forEach(task=>{
				if(task.userId+"" === userId+"" || userId+"" === "-1"){
					taskList.push(task);

                    //Now if it is checklist, then based on the type of checklist we need to assign scores
                    if(this.state.assignmentArea === "CheckList"){
                        let weight = isEmptyVariable(task.weight)?1:task.weight;
                        let rank = isEmptyVariable(task.rank) || task.rank === -1
                                    ?0:task.rank;

                        //if rank is NA then that should be considered for max score also
                        let maxRankNew = task.rank === -1?0:this.state.checklistMaxRank;
                        maxScore += weight*maxRankNew;
                        totalScore += weight*rank;
                    }
				}
			})
			process.taskList = taskList;
            process.maxScore = maxScore;
			process.totalScore = totalScore;
			return process;
		})
		return arr;
	}

    getTaskDisplayValues = (taskId, fieldItem) => {
        if(isEmptyVariable(this.state.taskTemplateValuesMap[taskId])){
            return "-";
        }

        let fieldId = fieldItem.fieldId;
        if(isEmptyVariable(this.state.taskTemplateValuesMap[taskId][fieldId])){
            return "-";
        }

        let displayValue = this.state.taskTemplateValuesMap[taskId][fieldId]["value"];
        if(isEmptyVariable(displayValue)){
            return "-";
        }

        switch(fieldItem.fieldType){
            case TemplateConstants.FIELD_TYPE_TEXT:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_DATE:
                return isEmptyVariable((displayValue))
                ?
                ""
                :
                moment(displayValue).format("DD/MM/YYYY");
            case TemplateConstants.FIELD_TYPE_SINGLE_SELECT:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_DROPDOWN:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_MULTI_SELECT:
                if(!isJsonString(displayValue)){
                    return "-";
                }
                return JSON.parse(displayValue).join(", ");
            case TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI:
                if(!isJsonString(displayValue)){
                    return "-";
                }
                return JSON.parse(displayValue).join(", ");
        }
        return "-";
    }

    resourceDropdownClick = (resource) => {
        let newDataList = [];
        for (const [key, value] of Object.entries(this.state.datalistArr)) {
            let newValue = value;
            let newTaskList = value.taskList.map(item=>{
                if(item.isResourceCheckboxSelected){
                    item.addedResourceToAssignment = true;
                    item.resourceName = resource.userName;
                    item.resourceId = resource.userId;
                    item.resourceDesignation = resource.designation;
                    item.resourcefullName = resource.fullName;
                }
                return item;
            })
            newValue.taskList = newTaskList;
            newDataList[key] = newValue;
        }
        
        this.setState({
            datalistArr:newDataList,
        })
    }

    selectAll = () => {
        let newDataList = [];
        for (const [key, value] of Object.entries(this.state.filterDataList.length>0?this.state.filterDataList:this.state.datalist)) {
            let newValue = value;
            let newTaskList = value.taskList.map(item=>{
                if(isEmptyVariable(item.observationId)){
                    item.isCheckboxSelected = true;
                }
                return item;
            })
            newValue.taskList = newTaskList;
            newDataList[key] = newValue;
        }

        if(this.state.filterDataList.length>0){
            this.setState({
                filterDataList:newDataList,
            })
        }else{
            this.setState({
                datalist:newDataList,
            })
        }
    }

    selectAllTaskResourceDialog = () => {
        let newDataList = [];
        for (const [key, value] of Object.entries(this.state.assigneResourcesFilterDataList.length>0?this.state.assigneResourcesFilterDataList:this.state.datalistArr)) {
            let newValue = value;
            //when user "selects all" in resource dialog, we should select all the tasks
            //that are added to the assignment
            let newTaskList = value.taskList.map(item=>{
                if(isEmptyVariable(item.observationId)){
                    item.isResourceCheckboxSelected = true;
                }
                return item;
            })
            newValue.taskList = newTaskList;
            newDataList[key] = newValue;
        }

        if(this.state.assigneResourcesFilterDataList.length>0){
            this.setState({
                assigneResourcesFilterDataList:newDataList
            })
        }else{
            this.setState({
                datalistArr:newDataList
            })
        }
    }

    unSelectAll = () => {
        let newDataList = [];
        for (const [key, value] of Object.entries(this.state.filterDataList.length>0?this.state.filterDataList:this.state.datalist)) {
            let newValue = value;
            let newTaskList = value.taskList.map(item=>{
                item.isCheckboxSelected = false;
                return item;
            })
            newValue.taskList = newTaskList;
            newDataList[key] = newValue;
        }

        if(this.state.filterDataList.length>0){
            this.setState({
                filterDataList:newDataList,
            })
        }else{
            this.setState({
                datalist:newDataList,
            })
        }
    }

    unSelectAllTaskResourceDialog = () => {
        let newDataList = [];
        for (const [key, value] of Object.entries(this.state.assigneResourcesFilterDataList.length>0?this.state.assigneResourcesFilterDataList:this.state.datalistArr)) {
            let newValue = value;
            let newTaskList = value.taskList.map(item=>{
                item.isResourceCheckboxSelected = false;
                return item;
            })
            newValue.taskList = newTaskList;
            newDataList[key] = newValue;
        }

        if(this.state.assigneResourcesFilterDataList.length>0){
            this.setState({
                assigneResourcesFilterDataList:newDataList
            })
        }else{
            this.setState({
                datalistArr:newDataList
            })
        }
    }

    /*################################## EXEC TASK RELATED #################################*/
    getExecTableDataDisplayValue = (assignmentTaskId,fieldItem) => {
        if(isEmptyVariable(this.state.executionTemplateValuesMap[assignmentTaskId])){
            return "";
        }

        let fieldId = fieldItem.fieldId;
        if(isEmptyVariable(this.state.executionTemplateValuesMap[assignmentTaskId][fieldId])){
            return "";
        }

        let displayValue = this.state.executionTemplateValuesMap[assignmentTaskId][fieldId]["value"];
        if(isEmptyVariable(displayValue)){
            return "";
        }

        switch(fieldItem.fieldType){
            case TemplateConstants.FIELD_TYPE_TEXT:
                return removeHtmlTags(displayValue);
            case TemplateConstants.FIELD_TYPE_DATE:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_SINGLE_SELECT:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_DROPDOWN:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_MULTI_SELECT:
                if(!isJsonString(displayValue)){
                    return "";
                }
                return JSON.parse(displayValue).join(", ");
            case TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI:
                if(!isJsonString(displayValue)){
                    return "";
                }
                return JSON.parse(displayValue).join(", ");
            case TemplateConstants.FIELD_TYPE_TABLE:
                if(!isJsonString(displayValue)){
                    return "";
                }
                return JSON.parse(displayValue);
        }
        return "";
    }

    getExecTableDataDisplayValueNR = (assignmentTaskId, fieldItem) => {
        if (isEmptyVariable(this.state.executionTemplateValuesMap[assignmentTaskId])) {
            return "";
        }

        let fieldId = fieldItem.fieldId;
        if (isEmptyVariable(this.state.executionTemplateValuesMap[assignmentTaskId][fieldId])) {
            return "";
        }

        let displayValue = this.state.executionTemplateValuesMap[assignmentTaskId][fieldId]["valueNR"];
        if (displayValue === "Y") {
            return "Y";
        }else{
            return "N"
        }
    };

    getExecTaskDisplayTableData = (assignmentTaskId, fieldItem, assignmentTaskAllowEdit) => {
        switch(fieldItem.fieldType){
            case TemplateConstants.FIELD_TYPE_TEXT:
                return <td contenteditable={false}>
                            {this.getExecTableDataDisplayValue(assignmentTaskId,fieldItem)}
                        </td>
            case TemplateConstants.FIELD_TYPE_DATE:
                let displayLabelDate = this.getExecTableDataDisplayValue(assignmentTaskId,fieldItem);
                return <td>
                    <DatePickerComponent
                        templateItemIndex={assignmentTaskId}
                        fieldItem={fieldItem}
                        onDateChange={this.onExecuteDateFieldChange}
                        placeholderValue={"Enter "+fieldItem.fieldName}
                        fieldValue={!isEmptyVariable(displayLabelDate)?displayLabelDate:""}
                        isDisabled ={assignmentTaskAllowEdit === "Y"?"N":"Y"}
                    />
                </td>
            case TemplateConstants.FIELD_TYPE_SINGLE_SELECT:
                let displayLabel = this.getExecTableDataDisplayValue(assignmentTaskId,fieldItem);
                let displayLabelNR = this.getExecTableDataDisplayValueNR(
                    assignmentTaskId,
                    fieldItem
                );
                return <td>
                    {displayLabelNR == "Y"?"NA":displayLabel}
                </td>
            case TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI:
            case TemplateConstants.FIELD_TYPE_MULTI_SELECT:
                let displayLabelMulti = this.getExecTableDataDisplayValue(assignmentTaskId,fieldItem);
                let displayLabelMultiNR = this.getExecTableDataDisplayValueNR(
                    assignmentTaskId,
                    fieldItem
                );
                return <td>
                    {displayLabelMultiNR == "Y"?"NA":displayLabelMulti}
                </td>
            case TemplateConstants.FIELD_TYPE_TABLE:
                let parsedJsonTableObj = this.getExecTableDataDisplayValue(
                  assignmentTaskId,
                  fieldItem
                );
                let displayLabelTableNR = this.getExecTableDataDisplayValueNR(
                    assignmentTaskId,
                    fieldItem
                );
            return (
                    <td>
                        {
                            displayLabelTableNR == "Y"
                            ?"NA"
                            :(isEmptyVariable(parsedJsonTableObj) ? "-" : this.getAssignmentTaskExecTable(parsedJsonTableObj,assignmentTaskAllowEdit))
                        }
                    </td>
                );  
        }
    }

    getExecSingleSelectTaskDisplayTableData = (assignmentTaskId, fieldItem, assignmentTaskAllowEdit) => {
        switch(fieldItem.fieldType){
            case TemplateConstants.FIELD_TYPE_SINGLE_SELECT:
                let displayLabel = this.getExecTableDataDisplayValue(assignmentTaskId,fieldItem);
                let displayLabelNR = this.getExecTableDataDisplayValueNR(
                    assignmentTaskId,
                    fieldItem
                );
                return <td  style={{minWidth:"150px"}}>
                    {displayLabelNR == "Y"?"NA":displayLabel}
                </td>
        }
    }

    onExecuteDateFieldChange = (dateValue,assignmentTaskId, fieldItem) => {
        this.saveAssignmentTaskTemplateValues(assignmentTaskId, fieldItem, dateValue, {});
    };

    getFirstRowItemValue = (item,idx) => {
        let tempFirstRowList =["yes / no with disable","yes / no / na with disable","yes / no","yes / no / na","textfield"]
        if(idx==0){
            if(!tempFirstRowList.includes(item.toLocaleLowerCase()) ){
                return item
            }else{
                return ""
            }
        }else if(idx!=0){
            return item
        }
    }

    getAssignmentTaskExecTable =(parsedJsonTableObj,assignmentTaskAllowEdit) => {
        return <table className="my-2">
        {
            Object.values(parsedJsonTableObj.tableArray).map((item,idx)=>{
            return <tr style={{background:assignmentTaskAllowEdit ==="true"? "#fff": "#f5f5f5"}}>
                <td style={{fontSize:10,height:22,minWidth:300}}> {item[0]} </td>
                <td style={{fontSize:10,height:22,minWidth:300}}>{this.getFirstRowItemValue(item[1],idx)} </td>
            </tr>
            })
        }
        </table>
    }

    handleExecuteTextField = (assignmentTaskId, fieldItem, e) => {
		const value = e.currentTarget.textContent;
        let currentRemark = this.getExecTableDataDisplayValue(assignmentTaskId,fieldItem);

		if (currentRemark !== value){
            this.saveAssignmentTaskTemplateValues(assignmentTaskId, fieldItem,value,{});
		}
	};

    handleSelectDropdownDialogShow = (item,index,assignmentTaskAllowEdit) => {
        let templateFieldItem = {...item};
        if(item.isFromRiskMaster){
            templateFieldItem.dropdownValues = this.state.businessRisk;
        }
        this.setState({
            showSelectDropdownDialog:true,
            templateFieldItem:templateFieldItem,
            deleteReactivateId:index,
            assignmentTaskAllowEdit:assignmentTaskAllowEdit
        })
    }

    handleSelectDropdownDialogClose = () => {
        this.setState({
            showSelectDropdownDialog:false,
            templateFieldItem:{},
            deleteReactivateId:"",
            assignmentTaskAllowEdit:"N"
        })
    }

    handleExecTableDialogShow = (item,assignmentTaskId,assignmentTaskAllowEdit) => {
        let templateFieldItem = {...item};
        this.setState({
            showExecTableDialog:true,
            templateFieldItem:templateFieldItem,
            deleteReactivateId:assignmentTaskId,
            assignmentTaskAllowEdit:assignmentTaskAllowEdit
        })
    }

    handleExecTableDialogClose = () => {
        this.setState({
            showExecTableDialog:false,
            templateFieldItem:{},
            deleteReactivateId:"",
            assignmentTaskAllowEdit:"N"
        })
    }

    saveTableValues = (tableObj, valueNR) => {
        const resetStateValues = {
            showExecTableDialog:false,
            templateFieldItem:{},
            deleteReactivateId:"",
            assignmentTaskAllowEdit:"N"
        }
        this.saveAssignmentTaskTemplateValues(this.state.deleteReactivateId, this.state.templateFieldItem, 
            JSON.stringify(tableObj),resetStateValues,
            valueNR);
    }

    dialogSelectedValues = (selectedValObj,assignmentTaskId, valueNR) => {
        let selectedValuesStr = "";
        if(valueNR === "Y"){

        }else{
            if(selectedValObj.fieldType === TemplateConstants.FIELD_TYPE_SINGLE_SELECT){
                selectedValuesStr = selectedValObj.selectedValues[0];
            }else if(selectedValObj.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT){
                if(!isEmptyArray(selectedValObj.selectedValues)){
                    selectedValuesStr = JSON.stringify(selectedValObj.selectedValues);
                }
            }
        }
        let resetStateValues = {
            showSelectDropdownDialog:false,
            templateFieldItem:{},
            deleteReactivateId:"",
            assignmentTaskAllowEdit:"N"
        }
        this.saveAssignmentTaskTemplateValues(assignmentTaskId, this.state.templateFieldItem, 
            selectedValuesStr,resetStateValues,valueNR);
    }

    /*################################## EXEC TASK RELATED ENDS#################################*/

	handleStopPropagation = (e) => {
        e.stopPropagation();
    }

    assignResourcesDialogClose = () => {
        this.unSelectAllTaskResourceDialog();
        this.setState({
            showAssignResourcesDialogFlag:false,
        })
    }

    onClickAddToAssignment = () => {
        let newDataList = [];
        for (const [key, value] of Object.entries(this.state.filterDataList.length>0?this.state.filterDataList:this.state.datalist)) {
            let newValue = value;
            let newTaskList = value.taskList.map(item=>{
                if(item.isCheckboxSelected){
                    item.added = true;
                }
                return item;
            })
            newValue.taskList = newTaskList;
            newDataList[key] = newValue;
        }
        if(this.state.filterDataList.length>0){
            this.setState({
                filterDataList:newDataList,
            })
        }else{
            this.setState({
                datalist:newDataList
            })
        }
    }
    
    onClickRemoveFromAssignment = () => {
        let newDataList = [];
        for (const [key, value] of Object.entries(this.state.filterDataList.length>0?this.state.filterDataList:this.state.datalist)) {
            let newValue = value;
            let newTaskList = value.taskList.map(item=>{
                if(item.isCheckboxSelected){
                    item.added = false;
                    item.isCheckboxSelected = false;
                    item.addedResourceToAssignment = false;
                    item.resourceName = "";
                    item.resourceId = "";
                    item.resourceDesignation = "";
                    item.resourcefullName = "";
                }
                return item;
            }) || [];
            newValue.taskList = newTaskList;
            newDataList[key] = newValue;
        }

        if(this.state.filterDataList.length>0){
            this.setState({
                filterDataList:newDataList,
            })
        }else{
            this.setState({
                datalist:newDataList
            })
        }
    }

    onClickNextButton = () => {
        this.setState({
            showLoader:true
        })

        let isError = true;
        if(this.state.resourceUser.length === 0){
            isError = true;
        }else{
            Object.values(this.state.datalist).map(functionObj=>{
                functionObj.taskList.map(item=>{
                    if(item.added){
                        isError = false;
                    }
                })
            })
        }
        
        let newDataList = [];
        for (const [key, value] of Object.entries(this.state.datalist)) {
            let newValue = value;
            let newTaskList = value.taskList.map(item=>{
                let userDetails = this.state.followUpAssignTask.filter(e => e.assignmentTaskId == item.assignmentTaskId && e.taskId == item.taskId);
                const user = this.state.resourceUser.filter(e => e.userId == userDetails[0]?.userId);
                if(item.added){
                    item.resourceId=isEmptyArray(user)?"":isEmptyVariable(user[0].userId)?"":user[0].userId;
                    item.resourceDesignation=isEmptyArray(user)?"":isEmptyVariable(user[0].designation)?"":user[0].designation;
                    item.resourceName=isEmptyArray(user)?"":isEmptyVariable(user[0].userName)?"":user[0].userName;
                    item.resourcefullName=isEmptyArray(user)?"":isEmptyVariable(user[0].fullName)?"":user[0].fullName;
                    item.followUpDate=isEmptyArray(userDetails)?"":isEmptyVariable(userDetails[0].followUpDate)?"":moment(userDetails[0].followUpDate).format("YYYY-MM-DD");
                }
                return item;
            })
            newValue.taskList = newTaskList;
            newDataList[key] = newValue;
        }
       
        let tempFilterArray = [
            {
              label: "All",
              isSelected: true,
            },
        ];

        let newArray = [];
        //filter newDatalist based on resource added
        if(this.state.assignmentArea==="Function"){
            newArray = newDataList.map(functionObj=>({
                ...functionObj,
                taskList: functionObj.taskList.filter(e => e.added === true),
                processTaskList: functionObj.processTaskList.map(taskItem=>({
                    ...taskItem,
                    taskList: taskItem.taskList.filter(e=>e.added === true)
                })).filter(item => item.taskList.length > 0)
            })).filter(item => item.taskList.length > 0)
        }else{
            newArray = newDataList.map(functionObj=>({
                ...functionObj,
                taskList: functionObj.taskList.filter(e => e.added === true)
            })).filter(item => item.taskList.length > 0)
        }

        //now do the validation
        Object.values(newArray).map((processObj)=>{
            let showTasks=(processObj.taskList).some((item) =>item.added===true)
            if(showTasks){
                tempFilterArray.push({
                    label:this.state.assignmentArea==="Process"?processObj.processName:this.state.assignmentArea==="Function"?processObj.functionName:processObj.heading,
                    isSelected:false
                })
            }
        })
        if(isError){
            if(this.state.resourceUser.length === 0){
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:"Assign atleast one resource"
                })
            }else{
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:"Please add atleast one task to the assignment"
                })
            }
            this.setState({
                showLoader:false
            });
        }else{
            this.setState({
                showAssignResourcesDialogFlag:true,
                showLoader:false,
                datalistArr:newArray,
                assigneResourcesFilterDataList:[],
                assigneResourcesFilterDropDownArr:tempFilterArray,
                assignResourcesPlaceholder:"All"
            })
        }
    }

    handleSelectionTasks = (e,taskId,assignmentTaskId) => {
        e.stopPropagation();
        this.setState({
            showLoader:true
        })
        
        let isChecked = e.target.checked;
        let list = this.state.filterDataList.length>0?this.state.filterDataList:this.state.datalist;

        list.map((task) => {
            task.taskList.map((item) => {
                if(item.taskId == taskId && item.assignmentTaskId == assignmentTaskId){
                    item.isCheckboxSelected = isChecked;
                    return item;
                }
            })
        })
        if(this.state.filterDataList.length>0){
            this.setState({
                filterDataList:list,
                showLoader:false
            })
        }else{
            this.setState({
                datalist:list,
                showLoader:false
            });
        }
    }

    handleSelectionTaskInResourcesDialog = (e,taskItem) => {
        e.stopPropagation();
        let isChecked = e.target.checked;
        let list = this.state.assigneResourcesFilterDataList.length>0?this.state.assigneResourcesFilterDataList:this.state.datalistArr;

        list.map((task) => {
            task.taskList.map((item) => {
                if(item.taskId == taskItem.taskId && item.assignmentTaskId == taskItem.assignmentTaskId){
                    item.isResourceCheckboxSelected = isChecked;
                    return item;
                }
            })
        })

        this.setState({
            datalistArr:list,
            showLoader:false
        });
    }

    singleSelectFilterDropDownOnClick = (fieldId,selectedItem) => {
        let placeholder = "";
        let tempSingleSelectFilterDataArray = this.state.singleSelectDropDownArr;
        
        //Set placeholder based on selection 
        Object.values(tempSingleSelectFilterDataArray).forEach(item => {
            if(item.fieldId === fieldId){
                Object.values(item.dropdownValues).map(dropdownItem => {
                    if (selectedItem.label === dropdownItem.label) {
                        dropdownItem.isSelected = true;
                        if(dropdownItem.label!=="All"){
                            placeholder = dropdownItem.label;
                        }
                        item.placeholderValue = placeholder;
                    }else{
                        dropdownItem.isSelected = false;
                    }
                })
            }
        })

        // Single Select filter datalist based on dropdown item selection
        let datalist = [];
        let tempFilterSingleSelectPlaceHolderDataList = tempSingleSelectFilterDataArray.filter(item => !isEmptyVariable(item.placeholderValue));
        let selectedPlaceHolder = tempFilterSingleSelectPlaceHolderDataList.map(item => item.placeholderValue);
                
        if(tempFilterSingleSelectPlaceHolderDataList.length === 0){
            datalist = this.state.allDatalist;
        }else{
            tempSingleSelectFilterDataArray.map(singleSelectItem => {
                datalist = this.state.allDatalist.map(functionObj=>({
                    ...functionObj,
                    taskList: functionObj.taskList.filter(taskItem => {
                        return selectedPlaceHolder.includes(this.state.executionTemplateValuesMap[taskItem.assignmentTaskId]?.[singleSelectItem.fieldId]?.["valueNR"]==="Y"
                            ?"NA":this.state.executionTemplateValuesMap[taskItem.assignmentTaskId]?.[singleSelectItem.fieldId]?.["value"])
                        })
                })).filter(item => item.taskList.length > 0);
            })
        }

        let tempFilterDataArray = this.state.filterDropDownArr;
        tempFilterDataArray.map((item) => {
            if (item.label === "All") {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        });
        this.setState({
            datalist: datalist,
            singleSelectDropDownArr: tempSingleSelectFilterDataArray,
            filterDataList:[],
            filterDropDownArr: tempFilterDataArray,
            filterPlaceholder:this.state.assignmentArea
        });
    }

    singleSelectAll = () => {
        //Single Select filter selection default All
        let placeholder = "";
        let tempSingleSelectFilterDataArray = this.state.singleSelectDropDownArr;
        Object.values(tempSingleSelectFilterDataArray).forEach(item => {
            Object.values(item.dropdownValues).map(dropdownItem => {
                if (dropdownItem.label === "All") {
                    dropdownItem.isSelected = true;
                    item.placeholderValue = placeholder;
                }else{
                    dropdownItem.isSelected = false;
                }
            })
        })
        this.setState({
            singleSelectDropDownArr: tempSingleSelectFilterDataArray
        })
    }

    filterDropDownOnClick = (selectedItem) => {
        this.singleSelectAll();
        let tempFilterDataList =[];
        let arr = this.getUserTasksFromUserId(this.state.selectedResourceId);  
        let tempFilterDataArray = this.state.filterDropDownArr;
        if(selectedItem.label==='All'){
            this.setState({
                filterDataList:[],
                datalist:this.state.allDatalist
            })
        }else if(selectedItem.label!=="All"){
            let dataListFunctionTaskArray = this.state.allDatalist.filter(item => selectedItem.label=== (this.state.assignmentArea==="Process"?item.processName:this.state.assignmentArea==="Function"?item.functionName:item.heading))
            arr.map((item)=>{
                if(selectedItem.label===(this.state.assignmentArea==="Process"?item.processName:this.state.assignmentArea==="Function"?item.functionName:item.heading)){
                    item.taskList = dataListFunctionTaskArray[0].taskList;
                }
            })
            
            Object.values(arr).map((item)=>{
                let tempDataTaskList =[];
                Object.values(item.taskList).map((task)=>{
                    if(selectedItem.label===(this.state.assignmentArea==="Process"?item.processName:this.state.assignmentArea==="Function"?item.functionName:item.heading))
                    {
                        tempDataTaskList.push(task)
                    }
                })
                item.taskList=tempDataTaskList
                tempFilterDataList.push(item)
            })
        }
        tempFilterDataArray.map((item)=>{
            if (item.label === selectedItem.label){
                item.isSelected = true;
            }else{
                item.isSelected = false;
            }
        });

        this.setState({
            filterPlaceholder:selectedItem.label,
            filterDataList:tempFilterDataList,
            filterDropDownArr:tempFilterDataArray,
        })
    };
    
    assigneResourceFilterDropDownOnClick = (selectedItem) =>{
        let tempFilterDataList =[];
        let arr = this.getUserTasksFromUserId(this.state.selectedResourceId);
        let tempFilterDataArray = this.state.assigneResourcesFilterDropDownArr;

        if(selectedItem.label==="All"){
            this.setState({
                assigneResourcesFilterDataList:[],
            })
        }else if(selectedItem.label!=="All"){
            let dataListFunctionTaskArray = this.state.datalistArr.filter(item => selectedItem.label=== (this.state.assignmentArea==="Process"?item.processName:this.state.assignmentArea==="Function"?item.functionName:item.heading))
            arr.map((item)=>{
                if(selectedItem.label=== (this.state.assignmentArea==="Process"?item.processName:this.state.assignmentArea==="Function"?item.functionName:item.heading)){
                    item.taskList = dataListFunctionTaskArray[0].taskList;
                }
            })
            Object.values(arr).map((item)=>{
                let tempDataTaskList =[]
                Object.values(item.taskList).map((task)=>{
                    if(selectedItem.label===(this.state.assignmentArea==="Process"?item.processName:this.state.assignmentArea==="Function"?item.functionName:item.heading)){
                        tempDataTaskList.push(task)
                    }
                })
                item.taskList=tempDataTaskList
                tempFilterDataList.push(item)
            })
        }

        tempFilterDataArray.map((item)=>{
            if(item.label === selectedItem.label){
                item.isSelected = true;
            }else{
                item.isSelected = false;
            }
        });

        this.setState({
            assigneResourcesFilterDataList:tempFilterDataList,
            assigneResourcesFilterDropDownArr:tempFilterDataArray,
            assignResourcesPlaceholder:selectedItem.label
        })
    }

    onDateChange = (date) => {
        let newDataList = [];   
        for (const [key, value] of Object.entries(this.state.datalistArr)) {
            let newValue = value;
            let newTaskList = value.taskList.map(item=>{
                if(item.isResourceCheckboxSelected){
                    item.followUpDate = isEmptyVariable(date)?"":date.format("YYYY-MM-DD");
                }
                return item;
            }) || [];
            newValue.taskList = newTaskList;
            newDataList[key] = newValue;
        }

        setTimeout(() => {
            this.setState({ 
                datalistArr:newDataList,
                open: false,
                showAlertDialogInfo:true,
                alertDialogMessageInfo:"Date selected for the selected task"
             });
        }, 2000);
    };

    handleOpenChange = (open) => {
        this.setState({ open:true });
    };
    /*******************API CALLS*******************/
	getInitData = () => {
		this.setState({
			showLoader: true,
		});

		Promise.all([
            fetch(Constants.GetFollowUpDraftTaskTabAssignedTasksAll, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.state.assignmentId,
                    empStatus:"Active"
				}),
			}),
			fetch(Constants.GetAssignmentsDetails, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.state.assignmentId,
				}),
			}),
            fetch(Constants.GetFollowUpAssignedResources, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.state.assignmentId,
				}),
            }),
        ])
		.then(([taskRes,assignmentRes,assignTaskRes]) => {
			return Promise.all([taskRes.json(),assignmentRes.json(),assignTaskRes.json()]);
		})
		.then(([taskRes,assignmentRes,assignTaskRes,]) => {
			let allDatalistTemp = [];
			let myDatalistTemp = [];
            let followUpAssignTaskTemp = [];
			let totalCountTemp = 0;
			let assignmentStatus = "";
			let assignmentArea = "";
			let assignmentDetails = {};
            let tempCompanyDetials = taskRes.data.company
            let tempUnitDetials = taskRes.data.units
			let singleSelectFilters = [];
            let singleSelectFiltersList = [];
			let companyTemp = {};

            //Since we need assignment area lets check this first
            let selectedTaskTemplateFields = [];
            let execTaskTemplateFields = [];
            let taskTemplateValuesMap = {};
            let executionTemplateValuesMap = {};
            let executionTemplateFixedFields = {};
            let followUpTemplateFixedFields = {};
            let assignmentRiskMatrix = [];
            let valueAdditions = [];

            if (assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
				localStorage.clear();
				window.location = "/";
			} else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
                assignmentStatus = assignmentRes.data.assignmentDetails.assignmentStatus;
                assignmentArea = assignmentRes.data.assignmentDetails.assignmentArea;
                assignmentDetails = assignmentRes.data.assignmentDetails;
                
                //TASK TEMPLATE RELATED STUFFS
                taskTemplateValuesMap = assignmentRes.data.taskTemplateValuesMap;
                let selectedTaskTemplateFieldsStr = assignmentRes.data.executionTemplateFields?.taskTemplateFields;//Template field IDs	
                if(!isEmptyVariable(selectedTaskTemplateFieldsStr) && isJsonString(selectedTaskTemplateFieldsStr)){
                    selectedTaskTemplateFields = JSON.parse(selectedTaskTemplateFieldsStr);
                }
                    
                //EXEC TASK RELATED STUFFS
                executionTemplateValuesMap = assignmentRes.data.executionTemplateValuesMap;
                let execTaskTemplateFieldsStr = assignmentRes.data.executionTemplateFields?.templateFields;
                if(!isEmptyVariable(execTaskTemplateFieldsStr) && isJsonString(execTaskTemplateFieldsStr)){
                    execTaskTemplateFields = JSON.parse(execTaskTemplateFieldsStr);
                }

                //EXEC TASK FIXED FIELDS
                let fixedTemplateFieldsStr = assignmentRes.data.executionTemplateFields?.fixedTemplateFields;
                if(!isEmptyVariable(fixedTemplateFieldsStr) && isJsonString(fixedTemplateFieldsStr)){
                    executionTemplateFixedFields = JSON.parse(fixedTemplateFieldsStr);
                }

                //FOLLOWUP TASK FIXED FIELDS
                let followUpFixedTemplateFieldsStr = assignmentRes.data.followUpTemplateFields?.fixedTemplateFields;
                if (
                    !isEmptyVariable(followUpFixedTemplateFieldsStr) &&
                    isJsonString(followUpFixedTemplateFieldsStr)
                ) {
                    followUpTemplateFixedFields = JSON.parse(followUpFixedTemplateFieldsStr);
                }
                assignmentRiskMatrix = assignmentRes.data.riskLevels;
                valueAdditions = assignmentRes.data.valueAdditions;
			} else {
			}

            let maxRank = 0;
            let showChecklistScores = false;
            let overallChecklistScoreObj = {};
            let assignmentChecklistObj = {};
            let matrixandMyDetails = {}
            let assignedTasksMap = {};
            let taskMap = {};

			if (taskRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || taskRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
				localStorage.clear();
				window.location = "/";
			} else if (taskRes.responseCode === Constants.CODE_SUCCESS) {
				allDatalistTemp = taskRes.data.assignedTasks;
                taskMap = taskRes.data.taskMap;
				followUpAssignTaskTemp = taskRes.data.followUpAssignTask;
                singleSelectFilters = taskRes.data.singleSelectFilters;
				let allDataList = JSON.parse(JSON.stringify(allDatalistTemp))
                
                //if assignment area is checklist we may need to show scores in certain cases
                if(assignmentArea === "CheckList"){
                    assignmentChecklistObj = taskRes.data.assignmentCheckList;
                    overallChecklistScoreObj = taskRes.data.assignmentScore;
                    showChecklistScores = getCheckListScoresFlag(assignmentChecklistObj.checkListType)
                    if(showChecklistScores){
                        maxRank = assignmentChecklistObj.maxRank;
                    }
                }
				//show only Selected User's/My tasks from the tasks list
				myDatalistTemp = getAllReportTasks(allDataList,assignmentArea,maxRank);
                myDatalistTemp.map((item)=>{
                    let datalistTemp = {};
                    let key="";
                    followUpAssignTaskTemp.forEach(e=>{
                        if(assignmentArea==="Function"){
                            assignedTasksMap[e.taskId+":-:"+e.leafNodeId] = e;
                        }else{
                            assignedTasksMap[e.taskId+":-:"+item.processId] = e;
                        }
                    })
                    //add only leaf level nodes
                    // if(!functionMapTemp[item.functionId].hasChildren){
                        //Now lets add the already assigned tasks
                        let updatedTaskList = [];
                        item.taskList.forEach(task=>{
                            if(assignmentArea==="Function"){
                                key = task.taskId+":-:"+item.functionId;
                            }else{
                                key = task.taskId+":-:"+item.processId;
                            }
                            if(!isEmptyVariable(assignedTasksMap[key])){
                                task.added = true;
                                task.assignmentTaskId = assignedTasksMap[key].assignmentTaskId;
                                task.resourceName = assignedTasksMap[key].userName;
                                task.resourceId = assignedTasksMap[key].userId;
                                task.resourcefullName = assignedTasksMap[key].userName+"("+assignedTasksMap[key].designation+")";
                                task.resourceDesignation = assignedTasksMap[key].designation;
                            }

                            updatedTaskList.push(task);
                        })
                        item.taskList = updatedTaskList;
                        
                        datalistTemp[item.functionId] = item;
                        datalistTemp[item.functionId].currentPageNo = 1; //set the current page no as 1 for all the individual task lists
                    // }
                })

                allDatalistTemp.map((item)=>{
                    let datalistTemp = {};
                    let key="";
                    followUpAssignTaskTemp.forEach(e=>{
                        if(assignmentArea==="Function"){
                            assignedTasksMap[e.taskId+":-:"+e.leafNodeId] = e;
                        }else{
                            assignedTasksMap[e.taskId+":-:"+item.processId] = e;
                        }
                    })
                    //add only leaf level nodes
                    // if(!functionMapTemp[item.functionId].hasChildren){
                        //Now lets add the already assigned tasks
                        let updatedTaskList = [];
                        item.taskList.forEach(task=>{
                            if(assignmentArea==="Function"){
                                key = task.taskId+":-:"+item.functionId;
                            }else{
                                key = task.taskId+":-:"+item.processId;
                            }
                            if(!isEmptyVariable(assignedTasksMap[key])){
                                task.added = true;
                                task.assignmentTaskId = assignedTasksMap[key].assignmentTaskId;
                                task.resourceName = assignedTasksMap[key].userName;
                                task.resourceId = assignedTasksMap[key].userId;
                                task.resourcefullName = assignedTasksMap[key].userName+"("+assignedTasksMap[key].designation+")";
                                task.resourceDesignation = assignedTasksMap[key].designation;
                            }

                            updatedTaskList.push(task);
                        })
                        item.taskList = updatedTaskList;
                        
                        datalistTemp[item.functionId] = item;
                        datalistTemp[item.functionId].currentPageNo = 1; //set the current page no as 1 for all the individual task lists
                    // }
                })
				//need list of users/reviewers
				//Sample:- 31:Suhas L5:Product Manager,39:Sumanth S:Product Manager
				let assignmentMatrixObj = taskRes.data.assignmentMatrix;

				//Get memberslist & Reviewers list for dropdown
				matrixandMyDetails = getMatrixandMyDetails(assignmentMatrixObj);
			} else {
			}

			//Lets check few things that are needed for checklist based assignment
			let showWeight = false;
			let showRanking = false;
			let showYesNoDD = false;
			let minRank = 0;
			let rankingArray = getRankingArray(minRank,maxRank);

			if(!isEmptyArray(allDatalistTemp) && assignmentArea === "CheckList"){
				let firstItem = allDatalistTemp[0];
				minRank = firstItem.taskList[0].minRank;

				if(!isEmptyArray(firstItem.taskList)){
					if(firstItem.taskList[0].checkListType === "WR"){
						showWeight = true;
						showRanking = true;
					}else if(firstItem.taskList[0].checkListType === "R"){
						showRanking = true;
					}else if(firstItem.taskList[0].checkListType === "Y/N"){
						showYesNoDD = true;
					}
				}
			}

            //Add "All" to Assignment matrix array
            let objAll = {
                id:-1,
                label:"All"
            }

            matrixandMyDetails.assignmentMatrix.splice(0,0,objAll)
            matrixandMyDetails.selectedResourceUserName = "All";

            let tempFilterArray = [
                {
                    label: "All",
                    isSelected: true,
                },
            ];
          
            Object.values(myDatalistTemp).map((item) => {
                tempFilterArray.push({
                    label: assignmentArea==="Process"?item.processName:assignmentArea==="Function"?item.functionName:item.heading,
                    isSelected: false,
                });
            });

            let users = [];
            if(assignTaskRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || assignTaskRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            }else if(assignTaskRes.responseCode === Constants.CODE_SUCCESS) {
                let assignmentReviewMatrix = assignTaskRes.data.assignmentReviewMatrix;
                let assignmentMemberMatrix = assignTaskRes.data.assignmentMemberMatrix;
                //need to get only active users
                let activeMembers = assignmentMemberMatrix.filter(item=> item.status === "Active");
                let activeReviewers = assignmentReviewMatrix.filter(item=> item.status === "Active");
                users = [...activeMembers,...activeReviewers]
            }

            //Set filter for single select
            Object.values(singleSelectFilters).forEach(item => {
                let tempSingleSelectItem = {};
                tempSingleSelectItem.fieldId = item.fieldId;
                tempSingleSelectItem.fieldName = item.fieldName;
                let tempSingleSelectArr = [
                    {
                        label: "All",
                        isSelected: true
                    },
                    {
                        label:"NA",
                        isSelected: false
                    }
                ];
                Object.values(item.dropdownValues).map(dropdownItem => {
                    tempSingleSelectArr.push({
                        label: dropdownItem.value,
                        isSelected: false
                    })
                })
                tempSingleSelectItem.dropdownValues = tempSingleSelectArr;
                tempSingleSelectItem.placeholderValue = "";
                singleSelectFiltersList.push(tempSingleSelectItem);
            });
            
			this.setState({
				componentDidMountFlag: true,
				showLoader: false,
				datalist: myDatalistTemp,
				allDatalist: allDatalistTemp,
				totalCount: totalCountTemp,
                followUpAssignTask: followUpAssignTaskTemp,

                selectedTaskTemplateFields:selectedTaskTemplateFields,
                taskTemplateValuesMap:taskTemplateValuesMap,
                execTaskTemplateFields:execTaskTemplateFields,
                executionTemplateValuesMap:executionTemplateValuesMap,
                executionTemplateFixedFields:executionTemplateFixedFields,
                followUpTemplateFixedFields:followUpTemplateFixedFields,
                assignmentRiskMatrix: assignmentRiskMatrix,
                valueAdditions: valueAdditions,
				company:companyTemp,

				assignmentStatus: assignmentStatus,
				assignmentArea: assignmentArea,
				assignmentDetails: assignmentDetails,
				assignmentMatrix: matrixandMyDetails.assignmentMatrix,
				selectedResourceUserName:matrixandMyDetails.selectedResourceUserName,
				selectedResourceId:-1,//all
				myPosition:matrixandMyDetails.myPosition,

				showWeight: showWeight,
				showRanking: showRanking,
				showYesNoDD:showYesNoDD,
                checklistMaxRank:maxRank,
				showChecklistScores: showChecklistScores,
				overallChecklistScoreObj: overallChecklistScoreObj,
				rankingArray: rankingArray,

                singleSelectDropDownArr:singleSelectFiltersList,

				resourceUser:users,
                filterDropDownArr: tempFilterArray,
                companyDetials:tempCompanyDetials,
                unitDetials:tempUnitDetials,
                taskMap:taskMap
			});
		});
	};

    getProcessGroupingTaskList = (Datalist,assignmentArea) => {
        if(assignmentArea==="Function"){
            let tempProcessList = [];
            let processName=[];
            let tempAllDataList = Object.values (Datalist);
            Object.values(Datalist).map((item)=>{
                Object.values(item.taskList).map((task)=>{
                    if(!processName.includes(task.processNameList)){
                        processName.push(task.processNameList)
                    }
                })
            })
    
            tempAllDataList.map((item)=>{
                let tempTaskList=[];
                tempProcessList=[];
                let tempItem = item;
                let tempProcessId="";
                processName.map((process)=>{
                    tempTaskList=[];       
                    Object.values(item.taskList).map((task)=>{
                        tempProcessId=task.processIdList
                        if(task.processNameList===process){
                            tempTaskList.push(task)
                        }
                    })
        
                    tempProcessList.push({
                        processName:process,
                        functionName:item.functionName,
                        processId:tempProcessId,
                        taskList:tempTaskList
                    })
                    tempItem["processTaskList"]=tempProcessList
                })
            })
            return tempAllDataList  
        }else{
            return Datalist
        }
    }

	GetExecuteTasksList = () => {
		this.setState({
			showLoader: true,
		});

		fetch(Constants.GetFollowUpDraftTaskTabAssignedTasksAll, {
            method: "POST",
			mode: "cors",
			body: new URLSearchParams({
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				assignmentId: this.state.assignmentId,
                empStatus:"Active"
			}),
        })
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
				localStorage.clear();
				window.location = "/";
			}else if(data.responseCode === Constants.CODE_SUCCESS) {
                let assignmentChecklistObj = {};
                let overallChecklistScoreObj = {};
                let assignedTasksMap = {};
                let myDatalistTemp = [];
                let taskMap = {};

                //show only Selected User's/My tasks from the tasks list
                let followUpAssignTaskTemp = data.data.followUpAssignTask;
                taskMap = data.data.taskMap;
                
                let assignmentMatrixObj = data.data.assignmentMatrix;
                let matrixandMyDetails = getMatrixandMyDetails(assignmentMatrixObj);

                let allDatalistTemp = data.data.assignedTasks;
				let allDataList = JSON.parse(JSON.stringify(allDatalistTemp))
                let maxRank = 0;
                if(this.state.assignmentArea === "CheckList"){
                    assignmentChecklistObj = data.data.assignmentCheckList;
                    overallChecklistScoreObj = data.data.assignmentScore;
                    let showChecklistScores = getCheckListScoresFlag(assignmentChecklistObj.checkListType)
                    if(showChecklistScores){
                        maxRank = assignmentChecklistObj.maxRank;
                    }
                }
				myDatalistTemp = getAllReportTasks(allDataList,this.state.assignmentArea,maxRank);
                    
                myDatalistTemp.map((item)=>{
                    let datalistTemp = {};
                    let key="";
                    followUpAssignTaskTemp.forEach(e=>{
                        if(this.state.assignmentArea==="Function"){
                            assignedTasksMap[e.taskId+":-:"+e.leafNodeId] = e;
                        }else{
                            assignedTasksMap[e.taskId+":-:"+item.processId] = e;
                        }
                    })
                //add only leaf level nodes
                    // if(!functionMapTemp[item.functionId].hasChildren){
                        //Now lets add the already assigned tasks
                    let updatedTaskList = [];
                    item.taskList.forEach(task=>{
                        if(this.state.assignmentArea==="Function"){
                            key = task.taskId+":-:"+item.functionId;
                        }else{
                            key = task.taskId+":-:"+item.processId;
                        }
                        if(!isEmptyVariable(assignedTasksMap[key])){
                            task.added = true;
                            task.assignmentTaskId = assignedTasksMap[key].assignmentTaskId;
                            task.resourceName = assignedTasksMap[key].userName;
                            task.resourceId = assignedTasksMap[key].userId;
                            task.resourcefullName = assignedTasksMap[key].userName+"("+assignedTasksMap[key].designation+")";
                            task.resourceDesignation = assignedTasksMap[key].designation;
                        }
                        updatedTaskList.push(task);
                    })
                    item.taskList = updatedTaskList;
                        
                    datalistTemp[item.functionId] = item;
                    datalistTemp[item.functionId].currentPageNo = 1; //set the current page no as 1 for all the individual task lists
                })
				
                allDatalistTemp.map((item)=>{
                    let datalistTemp = {};
                    let key="";
                    followUpAssignTaskTemp.forEach(e=>{
                        if(this.state.assignmentArea==="Function"){
                            assignedTasksMap[e.taskId+":-:"+e.leafNodeId] = e;
                        }else{
                            assignedTasksMap[e.taskId+":-:"+item.processId] = e;
                        }
                    })
                    //add only leaf level nodes
                    // if(!functionMapTemp[item.functionId].hasChildren){
                        //Now lets add the already assigned tasks
                        let updatedTaskList = [];
                        item.taskList.forEach(task=>{
                            if(this.state.assignmentArea==="Function"){
                                key = task.taskId+":-:"+item.functionId;
                            }else{
                                key = task.taskId+":-:"+item.processId;
                            }
                            if(!isEmptyVariable(assignedTasksMap[key])){
                                task.added = true;
                                task.assignmentTaskId = assignedTasksMap[key].assignmentTaskId;
                                task.resourceName = assignedTasksMap[key].userName;
                                task.resourceId = assignedTasksMap[key].userId;
                                task.resourcefullName = assignedTasksMap[key].userName+"("+assignedTasksMap[key].designation+")";
                                task.resourceDesignation = assignedTasksMap[key].designation;
                            }

                            updatedTaskList.push(task);
                        })
                        item.taskList = updatedTaskList;
                        
                        datalistTemp[item.functionId] = item;
                        datalistTemp[item.functionId].currentPageNo = 1; //set the current page no as 1 for all the individual task lists
                    // }
                })

                this.setState({
					showLoader: false,
					datalist: myDatalistTemp,
					allDatalist: allDatalistTemp,
					overallChecklistScoreObj: overallChecklistScoreObj,
                    assignmentMatrix: matrixandMyDetails.assignmentMatrix,
				    selectedResourceUserName:matrixandMyDetails.selectedResourceUserName,
                    selectedResourceId:-1,//all
                    myPosition:matrixandMyDetails.myPosition,
                    followUpAssignTask: followUpAssignTaskTemp,
                    taskMap: taskMap
				});
			} else {
				this.setState({
					datalist: [],
					showLoader: false,
				});
			}
		});
	};

	GetAssignmentDetails = (resetStateValues) => {
        if(!this.state.showLoader){
            this.setState({
                showLoader: true,
            });
        }

		fetch(Constants.GetAssignmentsDetails, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				assignmentId: this.state.assignmentId,
			}),
		})
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                let executionTemplateValuesMap = data.data.executionTemplateValuesMap;
                let executionTemplateFixedFields = {};
                let followUpTemplateFixedFields = {};
                let fixedTemplateFieldsStr = data.data.executionTemplateFields?.fixedTemplateFields;
                let assignmentDetails = {};
                if(!isEmptyVariable(fixedTemplateFieldsStr) && isJsonString(fixedTemplateFieldsStr)){
                    executionTemplateFixedFields = JSON.parse(fixedTemplateFieldsStr);
                }
                //FOLLOWUP TASK FIXED FIELDS
                let followUpFixedTemplateFieldsStr = data.data.followUpTemplateFields?.fixedTemplateFields;
                if (
                    !isEmptyVariable(followUpFixedTemplateFieldsStr) &&
                    isJsonString(followUpFixedTemplateFieldsStr)
                ) {
                    followUpTemplateFixedFields = JSON.parse(followUpFixedTemplateFieldsStr);
                }
                if(this.props.isDraftOnward){
                    assignmentDetails = data.data.result;
                }else{
                    assignmentDetails = data.data.assignmentDetails;
                }
                this.setState({
                    executionTemplateValuesMap:executionTemplateValuesMap,
                    executionTemplateFixedFields:executionTemplateFixedFields,
                    followUpTemplateFixedFields:followUpTemplateFixedFields,
                    assignmentDetails: assignmentDetails,
                    showLoader: false,
                    ...resetStateValues
                })
            } else {
            }
        });
	};

	handleSaveAssignmentTaskProperties = (assignmentTaskId, remark, fieldName) => {
        this.setState({
			showLoader: true,
		});
		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			assignmentId: this.state.assignmentId,
			assignmentTaskId: assignmentTaskId,
			[fieldName]: remark,
		};
		if(fieldName === "remark"){
			postParam.type = "Remark";
		}

        let saveTaskPropertiesUrl = Constants.SaveAssignmentTaskProperties;
        if(this.props.isDraftOnward){
            saveTaskPropertiesUrl = Constants.SaveDraftAssignmentTaskProperties;
        }

		fetch(saveTaskPropertiesUrl, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParam),
		})
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                this.setState({}, () => {
                    this.GetExecuteTasksList();
                });
            } else {
            }
        });
	};

    saveAssignmentTaskTemplateValues = (assignmentTaskId,fieldItem,value,resetStateValues,valueNR) => {
        this.setState({
			showLoader: true,
		});

        let postObj = {
            email: userDetails.email,
            accessToken: userDetails.accessToken,
            assignmentTaskId:assignmentTaskId,
            fieldId:fieldItem.fieldId,
            [fieldItem.fieldId]:value
        }

        if(valueNR === "Y"){
            postObj[fieldItem.fieldId+"_NR"] = "Y";
        }

        let saveTemplateValuesUrl = Constants.SaveAssignmentTaskTemplateValues;
        if(this.props.isDraftOnward){
            saveTemplateValuesUrl = Constants.SaveDraftAssignmentTaskTemplateValues;
        }

        fetch(saveTemplateValuesUrl, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postObj),
		})
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                this.GetAssignmentDetails(resetStateValues);
            } else {
            }
		});
    }

    onClickAddResourceToAssignment = () => {   
        let assignmentTasks = []
        Object.values(this.state.assigneResourcesFilterDataList.length>0?this.state.assigneResourcesFilterDataList:this.state.datalistArr).forEach(functionObj=>{
            functionObj.taskList.forEach(item=>{
                if(item.added){
                    let obj = {
                        taskId:item.taskId,
                        userId:item.resourceId,
                        userName:item.resourceName,
                        designation:item.resourceDesignation,
                        followUpDate:item.followUpDate,
                    }
                    if(!isEmptyVariable(item.assignmentTaskId)){
                        obj.assignmentTaskId = item.assignmentTaskId;
                    }
                    assignmentTasks.push(obj);
                }
            })
        })

        //call the fetch function
        fetch(Constants.AddFollowUpTasks, {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                assignmentTasks:JSON.stringify(assignmentTasks),
                assignmentId:this.props.assignmentId
            })
        })
        .then((response) => { 
            return response.json(); 
        })
        .then((data) => {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
            data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.assignResourcesDialogClose();
                this.unSelectAll();
                this.GetExecuteTasksList();
                this.setState({
                    filterDataList:[],
                    filterPlaceholder:"All"
                })
            }else{
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage
                })
            }
        });       
    }
    
	/*******************API CALLS END HERE*******************/

	render() {
        let showEmptyCard = true;
		return (
			<Router>
                {
                    this.state.componentDidMountFlag &&
                    <div>
                        {
                            this.state.showLoader && 
                            <div class="loader"></div>
                        }
                        {
                            !this.state.showTaskDetailsLayout &&
                            <div className="prev-next-layout">
                                <div className="flex-center-layout secondary-top-bar">
                                    {
                                        !this.props.showOnlyTasks &&
                                        <div className="back-btn-layout" onClick={this.props.onClickBackBtnFromAddTasks}>
                                            <span class="material-icons-outlined">arrow_back_ios</span>
                                        </div>
                                    }
                                    <h6>{"Tasks"}</h6>
                                </div>
                                <div className="flex-center-layout">
                                    {!isEmptyVariable(this.props.observationTemplateId) &&
                                        <div style={{ marginRight: 10 }}>
                                            <FilterDropDown
                                                placeholder={this.props.observationTaskFilterPlaceholder}
                                                dropdownArr={this.props.observationTaskFilterDropDownArr}
                                                onDropDownItemClick={this.props.observationTaskFilterDropDownOnClick}
                                                themeSettings={themeSettings}
                                                name={"label"}
                                                dropdownWidth="200px"
                                            />
                                        </div>
                                    }
                                    {!isEmptyArray(this.state.singleSelectDropDownArr) && this.state.singleSelectDropDownArr.map(item => {
                                        return(
                                            <div style={{ marginRight: 10 }}>
                                                <FilterDropDown
                                                    placeholder={isEmptyVariable(item.placeholderValue)?item.fieldName:item.placeholderValue}
                                                    dropdownArr={item.dropdownValues}
                                                    onDropDownItemClick={this.singleSelectFilterDropDownOnClick.bind(this,item.fieldId)}
                                                    themeSettings={themeSettings}
                                                    name={"label"}
                                                    dropdownWidth="200px"
                                                />
                                            </div>
                                        )
                                    })}   
                                    <div style={{ marginRight: 10 }}>
                                        <FilterDropDown
                                            placeholder={this.state.filterPlaceholder=="All"?this.state.assignmentArea:this.state.filterPlaceholder}
                                            dropdownArr={this.state.filterDropDownArr}
                                            onDropDownItemClick={this.filterDropDownOnClick}
                                            themeSettings={themeSettings}
                                            name={"label"}
                                            dropdownWidth="200px"/>
                                    </div>
                                    <div className="bordered-anchor" style={{marginRight:5}}>
                                        <a onClick={this.unSelectAll} href="javascript:void(0);">
                                            <span class="material-icons-outlined">
                                            check_box_outline_blank
                                            </span>
                                            <p>Unselect All</p>
                                        </a>
                                    </div>
                                    <div className="bordered-anchor" style={{marginRight:5}}>
                                        <a onClick={this.selectAll} href="javascript:void(0);">
                                            <span class="material-icons-outlined">
                                            check_box
                                            </span>
                                            <p>Select All</p>
                                        </a>
                                    </div>
                                    
                                    <AddNewButtonLayout themeSettings={themeSettings}
                                    style={{marginRight:5}}>
                                        <a onClick={this.onClickRemoveFromAssignment} href="javascript:void(0);">
                                            <span className="material-icons">remove_circle_outline</span>
                                            <p>Remove</p>
                                        </a>
                                    </AddNewButtonLayout>

                                    <AddNewButtonLayout themeSettings={themeSettings}>
                                        <a onClick={this.onClickAddToAssignment} href="javascript:void(0);">
                                            <span className="material-icons">add_circle</span>
                                            <p>Add to Assignment</p>
                                        </a>
                                    </AddNewButtonLayout>

                                    <AddNewButtonLayout themeSettings={themeSettings} style={{marginLeft:5}}>
                                        <a onClick={this.onClickNextButton} href="javascript:void(0);">
                                            {/* <span className="material-icons">add_circle</span> */}
                                            <p>Next</p>
                                            <span class="material-icons-outlined">arrow_forward_ios</span>
                                        </a>
                                    </AddNewButtonLayout>
                                </div>
                            </div>
                        }
                        
                        {
                            // Need to check component did mount flag, other wise until api is called,
                            // it shows no items found layout.
                            this.state.componentDidMountFlag && 
                            !this.state.showTaskDetailsLayout &&
                            <div className="body-wrapper common-table-scroll">
                                {
                                    !isEmptyArray(this.state.datalist)  && this.state.assignmentArea!=="Function" &&
                                     (
                                       this.state.filterDataList.length > 0
                                       ? this.state.filterDataList
                                       : this.state.datalist
                                     ).map((item, index)=>{
                                        return(
                                            !isEmptyArray(item.taskList) &&
                                            <div>
                                                <h6 style={{marginTop:index===0?15:10}} className="small-h6">
                                                    {
                                                        isEmptyVariable(item.processName)
                                                        ?(isEmptyVariable(item.functionName)
                                                            ?(item.heading)
                                                            :item.functionName)
                                                        :item.processName
                                                    }
                                                </h6>
                                                {
                                                    !isEmptyArray(item.taskList) &&
                                                    <div>
                                                        <div className="common-tab-bg">
                                                            <div className="common-table" style={{ width: "100%" }}>
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th></th>
                                                                            {
                                                                                this.state.selectedTaskTemplateFields.map(item=>{
                                                                                    return <th className={item.fieldType===TemplateConstants.FIELD_TYPE_TEXT
                                                                                        ?"scrollable-table-task-text-th":"scrollable-table-task-others-th"}>
                                                                                        {item.fieldName}
                                                                                    </th>
                                                                                })
                                                                            }
                                                                            <th style={{minWidth:"250px"}}>Resource</th> 
                                                                            <th style={{minWidth:"150px"}}>Follow Up Date</th>      
                                                                            <th>Added</th>
                                                                            {
                                                                                this.state.executionTemplateFixedFields?.assignRisk==="Y" &&
                                                                                <th>Risk</th>
                                                                            }
                                                                            {
                                                                                this.state.showWeight &&
                                                                                <th>Weight</th>
                                                                            }
                                                                            {
                                                                                this.state.showYesNoDD &&
                                                                                <th>Rating</th>
                                                                            }
                                                                            {
                                                                                this.state.showRanking &&
                                                                                <th>Rating</th>
                                                                            }
                                                                            {
                                                                                this.state.execTaskTemplateFields.map(item=>{
                                                                                    let showHeader = true;
                                                                                    if(item.fieldType===TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI){
                                                                                        if(item.isFromRiskMaster){
                                                                                            if(item.isRiskMasterEnabled==="Y"){
                                                                                                showHeader = true;
                                                                                            }else{
                                                                                                showHeader = false;
                                                                                            }
                                                                                        }else{
                                                                                            showHeader = true;
                                                                                        }
                                                                                    }
                                                                                    return showHeader && <th className={item.fieldType===TemplateConstants.FIELD_TYPE_TEXT
                                                                                        ?"scrollable-table-task-text-th":"scrollable-table-task-others-th"}>
                                                                                        {item.fieldName}
                                                                                        {item.isMandatorySelected==="Y"?"*":""}
                                                                                    </th>
                                                                                })
                                                                            }
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {!isEmptyArray(item.taskList) &&
                                                                            item.taskList.map((taskItem,idx)=>{
                                                                                let userDetails = this.state.followUpAssignTask.filter(e => e.assignmentTaskId == taskItem.assignmentTaskId && e.taskId == taskItem.taskId); 
                                                                                showEmptyCard=false;

                                                                                let yNRank=defaultYNPlaceholder;
                                                                                //check allowEdit
                                                                                let assignmentTaskAllowEdit=this.getAllowEdit(taskItem);
                                                                                // For Y/N, get label from yesNoRankArr
                                                                                if(this.state.showYesNoDD && !isEmptyVariable(taskItem.rank)){
                                                                                    Constants.yesNoRankArr.forEach((rankItem)=>{
                                                                                        if(taskItem.rank===rankItem.id){
                                                                                            yNRank=rankItem.label;
                                                                                        }
                                                                                    })
                                                                                }
                                                                                return (
                                                                                    <tr style={{background:assignmentTaskAllowEdit==="Y"?"#fff":"#f5f5f5"}}>
                                                                                        <td className="text-center">
                                                                                            {isEmptyArray(userDetails)||isEmptyVariable(userDetails[0].observationId)?
                                                                                                <TableCheckBox onClick={this.handleStopPropagation}
                                                                                                    themeSettings={themeSettings}>
                                                                                                    <input 
                                                                                                        type="checkbox"
                                                                                                        name={idx+":-:"+item.processId}
                                                                                                        id={idx+":-:"+item.processId+":-:"+taskItem.taskId}
                                                                                                        onChange={(e) => this.handleSelectionTasks(e,taskItem.taskId,taskItem.assignmentTaskId)}
                                                                                                        checked={taskItem.isCheckboxSelected?true:false}
                                                                                                    />
                                                                                                    <label for={idx+":-:"+item.processId+":-:"+taskItem.taskId} 
                                                                                                        style={{marginLeft:6}}></label>
                                                                                                </TableCheckBox>
                                                                                            :""}
                                                                                        </td>
                                                                                        {
                                                                                            this.state.selectedTaskTemplateFields.map((item,taskIdx)=>{
                                                                                                return <td 
                                                                                                style={taskIdx+""==="0"?{borderLeft:"5px solid "+taskItem.colorCode}:{}}>
                                                                                                    <div className={item.fieldId+""==="1"?"dangerouslysethtml-flex":""}>
                                                                                                        <div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{__html: this.getTaskDisplayValues(
                                                                                                            taskItem.taskId,item)}} 
                                                                                                        />
                                                                                                        {
                                                                                                            taskItem.completionStatus==="Completed" && item.fieldId+""==="1" &&
                                                                                                            <span className="material-icons exec-task-done">done_all</span>
                                                                                                        }
                                                                                                    </div>
                                                                                                </td>
                                                                                            })
                                                                                        }
                                                                                        <td>
                                                                                            {
                                                                                                taskItem.added && (isEmptyArray(userDetails)?"":(isEmptyVariable(userDetails[0].userName)+isEmptyVariable(userDetails[0].designation))?"":userDetails[0].userName+"("+userDetails[0].designation+")")
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                taskItem.added && (isEmptyArray(userDetails)?"":isEmptyVariable(userDetails[0].followUpDate)?"":moment(userDetails[0].followUpDate).format("DD/MM/YYYY"))
                                                                                            }
                                                                                        </td>
                                                                                        <td>
                                                                                            {
                                                                                                taskItem.added &&
                                                                                                <span class="material-icons"
                                                                                                style={{color:`rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`}}>check_circle</span>
                                                                                            }
                                                                                        </td>
                                                                                        {
                                                                                            this.state.executionTemplateFixedFields?.assignRisk==="Y" &&
                                                                                            <td
                                                                                            >{isEmptyVariable(taskItem.assignmentTaskRiskLevel)?"-":taskItem.assignmentTaskRiskLevel}</td>
                                                                                        }
                                                                                        {
                                                                                            this.state.showWeight &&
                                                                                            <td className="text-center">{taskItem.weight}</td>
                                                                                        }
                                                                                        {
                                                                                            this.state.showYesNoDD &&
                                                                                            <td>
                                                                                                <RegularDropdown 
                                                                                                    placeholder={
                                                                                                        isEmptyVariable(taskItem.rank)
                                                                                                        ?defaultYNPlaceholder
                                                                                                        :yNRank
                                                                                                    }
                                                                                                    dropdownArr={Constants.yesNoRankArr}
                                                                                                    labelParam="label"
                                                                                                    onDropDownItemClick={this.maxrDropdownClick}
                                                                                                    udf={idx+":-:"+index}
                                                                                                    disabled={assignmentTaskAllowEdit==="Y"?false:true}
                                                                                                />
                                                                                            </td>
                                                                                        }
                                                                                        {
                                                                                            this.state.showRanking &&
                                                                                            <td>
                                                                                                <RegularDropdown 
                                                                                                    placeholder={
                                                                                                        isEmptyVariable(taskItem.rank)
                                                                                                        ?defaultWeightPlaceholder
                                                                                                        :(taskItem.rank===-1?"N/A":taskItem.rank)
                                                                                                    }
                                                                                                    dropdownArr={this.state.rankingArray}
                                                                                                    labelParam="label"
                                                                                                    onDropDownItemClick={this.maxrDropdownClick}
                                                                                                    udf={idx+":-:"+index}
                                                                                                    disabled={assignmentTaskAllowEdit==="Y"?false:true}
                                                                                                />
                                                                                            </td>
                                                                                        }
                                                                                        {
                                                                                            this.state.execTaskTemplateFields.map(item=>{
                                                                                                return this.getExecTaskDisplayTableData(taskItem.assignmentTaskId,item,assignmentTaskAllowEdit)
                                                                                            })
                                                                                        }
                                                                                    </tr>
                                                                                );
                                                                            })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.showChecklistScores &&
                                                            <div className="checklist-scores-layout">
                                                                <p>{"Max Score: "+item.maxScore}</p>
                                                                <p>{"Total Score: "+item.totalScore}</p>
                                                                <p>{"Percentage: "+((item.totalScore/item.maxScore)*100).toFixed(1)+"%"}</p>
                                                            </div>
                                                        }
                                                    </div>
                                                }
                                                {
                                                    isEmptyArray(item.taskList) &&
                                                    <div class="no-items-layout">
                                                        <div class="no-items-card" style={{padding:"10px"}}>
                                                            <h6 style={{margin:0,fontSize:"13px"}}>
                                                            {
                                                                isEmptyVariable(this.state.apiSearchKey)?
                                                                Constants.NO_RECORDS_WARNING
                                                                :
                                                                Constants.EMPTY_SEARCH_WARNING
                                                            }
                                                            </h6>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        );
                                    })
                                }
                                {   
                                    !isEmptyArray(this.state.datalist)  && this.state.assignmentArea==="Function" &&
                                    (
                                        this.state.filterDataList.length > 0
                                        ? this.getProcessGroupingTaskList(this.state.filterDataList,this.state.assignmentArea)
                                        : this.getProcessGroupingTaskList(this.state.datalist,this.state.assignmentArea)
                                    )
                                    .map((functionObj, idx) => {
                                        return !isEmptyArray(functionObj.processTaskList) && Object.values(functionObj.processTaskList).map((item,index)=>{
                                            return (
                                                !isEmptyArray(item.taskList) &&
                                                <div>
                                                    <div className="sticky-exec-header">
                                                        {
                                                            <h6 style={{ marginTop: 5 }} className="small-h6">
                                                                {!isEmptyVariable(functionObj.functionName)?functionObj.functionName:""}
                                                            </h6>
                                                        }
                                                        <center>
                                                            <h6 style={{marginTop:5}} className="small-h6">
                                                                {!isEmptyVariable(item.processName)?item.processName:""}
                                                            </h6>
                                                        </center>
                                                    </div>
                                                    {
                                                        !isEmptyArray(functionObj.taskList) &&
                                                        <div>
                                                            <div className="common-tab-bg">
                                                                <div className="common-table" style={{ width: "100%" }}>
                                                                    <table className="table">
                                                                        <thead className="sticky-exec-header" style={{top:54}}>
                                                                            <tr>
                                                                                <th></th>
                                                                                {
                                                                                    this.state.selectedTaskTemplateFields.map(item=>{
                                                                                        return <th className={item.fieldType === TemplateConstants.FIELD_TYPE_TEXT
                                                                                            ?"scrollable-table-task-text-th":"scrollable-table-task-others-th"}>
                                                                                            {item.fieldName}
                                                                                        </th>
                                                                                    })
                                                                                }
                                                                                <th style={{minWidth:"150px"}}>Resource</th>
                                                                                <th style={{minWidth:"150px"}}>Follow Up Date</th>
                                                                                <th>Added</th>
                                                                                {
                                                                                    this.state.executionTemplateFixedFields?.assignRisk === "Y" &&
                                                                                    <th>Risk</th>
                                                                                }
                                                                                {
                                                                                    this.state.showWeight &&
                                                                                    <th>Weight</th>
                                                                                }
                                                                                {
                                                                                    this.state.showYesNoDD &&
                                                                                    <th>Rank</th>
                                                                                }
                                                                                {
                                                                                    this.state.showRanking &&
                                                                                    <th>Rank</th>
                                                                                }
                                                                                {
                                                                                    this.state.execTaskTemplateFields.map(item=>{
                                                                                        let showHeader=true;
                                                                                        if(item.fieldType===TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI){
                                                                                            if(item.isFromRiskMaster){
                                                                                                if(item.isRiskMasterEnabled==="Y"){
                                                                                                    showHeader=true;
                                                                                                }else{
                                                                                                    showHeader=false;
                                                                                                }
                                                                                            }else{
                                                                                                showHeader=true;
                                                                                            }
                                                                                        }
                                                                                        return showHeader && <th className={item.fieldType === TemplateConstants.FIELD_TYPE_TEXT
                                                                                            ?"scrollable-table-task-text-th":"scrollable-table-task-others-th"}>
                                                                                            {item.fieldName}
                                                                                            {item.isMandatorySelected === "Y"?"*":""}
                                                                                        </th>
                                                                                    })
                                                                                }
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {!isEmptyArray(item.taskList) &&
                                                                                item.taskList.map((taskItem,idx)=>{
                                                                                    let userDetails = this.state.followUpAssignTask.filter(e => e.assignmentTaskId == taskItem.assignmentTaskId && e.taskId == taskItem.taskId);
                                                                                    showEmptyCard=false;
                                                                                    let yNRank=defaultYNPlaceholder;
                                                                                    //check allowEdit
                                                                                    let assignmentTaskAllowEdit=this.getAllowEdit(taskItem);

                                                                                    // For Y/N, get label from yesNoRankArr
                                                                                    if(this.state.showYesNoDD && !isEmptyVariable(taskItem.rank)){
                                                                                        Constants.yesNoRankArr.forEach((rankItem)=>{
                                                                                            if(taskItem.rank===rankItem.id){
                                                                                                yNRank=rankItem.label;
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                    return (
                                                                                        <tr style={{background:assignmentTaskAllowEdit==="Y"?"#fff":"#f5f5f5"}}>
                                                                                            <td className="text-center">
                                                                                                <TableCheckBox onClick={this.handleStopPropagation}
                                                                                                    themeSettings={themeSettings}>
                                                                                                    <input 
                                                                                                        type="checkbox"
                                                                                                        name={idx+":-:"+functionObj.functionId}
                                                                                                        id={idx+":-:"+functionObj.functionId+":-:"+taskItem.taskId}
                                                                                                        onChange={(e) => this.handleSelectionTasks(e,taskItem.taskId,taskItem.assignmentTaskId)}
                                                                                                        checked={taskItem.isCheckboxSelected?true:false}
                                                                                                    />
                                                                                                    <label for={idx+":-:"+functionObj.functionId+":-:"+taskItem.taskId} 
                                                                                                    style={{marginLeft:6}}></label>
                                                                                                </TableCheckBox>
                                                                                            </td>
                                                                                            {
                                                                                                this.state.selectedTaskTemplateFields.map((item,taskIdx)=>{
                                                                                                    return <td 
                                                                                                    style={taskIdx+"" === "0"?{borderLeft:"5px solid "+taskItem.colorCode}:{}}>
                                                                                                        <div className = {item.fieldId + "" === "1" ? "dangerouslysethtml-flex" : ""}>
                                                                                                            <div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{__html: this.getTaskDisplayValues(
                                                                                                            taskItem.taskId, item )}} 
                                                                                                            />
                                                                                                            {
                                                                                                                taskItem.completionStatus==="Completed" && item.fieldId+"" === "1" &&
                                                                                                                <span className="material-icons exec-task-done">done_all</span>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </td>
                                                                                                })
                                                                                            }
                                                                                            <td>
                                                                                                {
                                                                                                    taskItem.added && (isEmptyArray(userDetails)?"":(isEmptyVariable(userDetails[0].userName)+isEmptyVariable(userDetails[0].designation))?"":userDetails[0].userName+"("+userDetails[0].designation+")")
                                                                                                }
                                                                                             </td>
                                                                                             <td>
                                                                                                {
                                                                                                    taskItem.added && (isEmptyArray(userDetails)?"":isEmptyVariable(userDetails[0].followUpDate)?"":moment(userDetails[0].followUpDate).format("DD/MM/YYYY"))
                                                                                                }
                                                                                             </td>
                                                                                             <td>
                                                                                                {
                                                                                                    taskItem.added &&
                                                                                                    <span class="material-icons"
                                                                                                    style={{color:`rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`}}>check_circle</span>
                                                                                                }
                                                                                             </td>
                                                                                                {
                                                                                                    this.state.executionTemplateFixedFields?.assignRisk === "Y" &&
                                                                                                    <td>
                                                                                                        {isEmptyVariable(taskItem.assignmentTaskRiskLevel)?"-":taskItem.assignmentTaskRiskLevel}
                                                                                                    </td>
                                                                                                }
                                                                                                {
                                                                                                    this.state.showWeight &&
                                                                                                    <td className="text-center">{taskItem.weight}</td>
                                                                                                }
                                                                                                {
                                                                                                    this.state.showYesNoDD &&
                                                                                                    <td>
                                                                                                        <RegularDropdown 
                                                                                                            placeholder={
                                                                                                                isEmptyVariable(taskItem.rank)
                                                                                                                ?defaultYNPlaceholder
                                                                                                                :yNRank
                                                                                                            }
                                                                                                            dropdownArr={Constants.yesNoRankArr}
                                                                                                            labelParam="label"
                                                                                                            onDropDownItemClick={this.maxrDropdownClick}
                                                                                                            udf={idx+":-:"+index}
                                                                                                            disabled={assignmentTaskAllowEdit === "Y"?false:true}
                                                                                                        />
                                                                                                    </td>
                                                                                                }
                                                                                                {
                                                                                                    this.state.showRanking &&
                                                                                                    <td>
                                                                                                        <RegularDropdown 
                                                                                                            placeholder={
                                                                                                                isEmptyVariable(taskItem.rank)
                                                                                                                ?defaultWeightPlaceholder
                                                                                                                :(taskItem.rank === -1?"N/A":taskItem.rank)
                                                                                                            }
                                                                                                            dropdownArr={this.state.rankingArray}
                                                                                                            labelParam="label"
                                                                                                            onDropDownItemClick={this.maxrDropdownClick}
                                                                                                            udf={idx+":-:"+index}
                                                                                                            disabled={assignmentTaskAllowEdit === "Y"?false:true}
                                                                                                        />
                                                                                                    </td>
                                                                                                }
                                                                                                {
                                                                                                    this.state.execTaskTemplateFields.map(item=>{
                                                                                                        return this.getExecTaskDisplayTableData(taskItem.assignmentTaskId,item,assignmentTaskAllowEdit)
                                                                                                    })
                                                                                                }
                                                                                        </tr>
                                                                                    );
                                                                                })}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.showChecklistScores &&
                                                                <div className="checklist-scores-layout">
                                                                    <p>{"Max Score: "+item.maxScore}</p>
                                                                    <p>{"Total Score: "+item.totalScore}</p>
                                                                    <p>{"Percentage: "+((item.totalScore/item.maxScore)*100).toFixed(1)+"%"}</p>
                                                                </div>
                                                            }
                                                            
                                                        </div>
                                                    }
                                                    {
                                                        isEmptyArray(item.taskList) &&
                                                        <div class="no-items-layout">
                                                            <div class="no-items-card" style={{padding:"10px"}}>
                                                                <h6 style={{margin:0,fontSize:"13px"}}>
                                                                    {
                                                                        isEmptyVariable(this.state.apiSearchKey)?
                                                                        Constants.NO_RECORDS_WARNING
                                                                        :
                                                                        Constants.EMPTY_SEARCH_WARNING
                                                                    }
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            );
                                        })  
                                    })
                                }

                                {
                                    this.state.componentDidMountFlag && showEmptyCard && (
                                    <div class="no-items-layout m-t-10">
                                        <div class="no-items-card">
                                            <h6>
                                                No Tasks
                                            </h6>
                                        </div>
                                    </div>
                                )}
                                {
                                    this.state.showChecklistScores &&
                                    <div className="checklist-scores-layout">
                                        <p>{"Checklist - Max Score: "+this.state.overallChecklistScoreObj?.maxScore}</p>
                                        <p>{"Total Score: "+this.state.overallChecklistScoreObj?.actualScore}</p>
                                        <p>{"Percentage: "+this.state.overallChecklistScoreObj?.scorePct+"%"}</p>
                                    </div>
                                } 
                            </div>
                        }
                        {
                            this.state.showTaskDetailsLayout && !this.props.isDraftOnward &&
                            <TaskDetailsLayout
                                taskId = {this.state.selectedTaskIdForDetails}
                                handleHideTaskDetailsLayout = {this.handleHideTaskDetailsLayout}
                                subscriptionType={this.state.resourceUser.subscriptionType}
                                handleShowTaskDetailsLayout={this.handleShowTaskDetailsLayout}
                                assignmentId = {this.state.assignmentId}
                                isIndustryFlag={this.props.isIndustryFlag}
                            />
                        }
                        
                        <LeftSideSelectDialog
                            showSelectDropdownDialog = {this.state.showSelectDropdownDialog}
                            handleSelectDropdownDialogClose = {this.handleSelectDropdownDialogClose}
                            fieldObj = {this.state.templateFieldItem}
                            viewOnly = {"N"}
                            themeSettings={themeSettings}
                            dialogSelectedValues={this.dialogSelectedValues}
                            fieldArrIndex = {this.state.deleteReactivateId}
                            assignmentTaskAllowEdit = {this.state.assignmentTaskAllowEdit}
                        />  

                        <ExecTableDialog
                            showExecTableDialog = {this.state.showExecTableDialog}
                            handleExecTableDialogClose={this.handleExecTableDialogClose}
                            assignmentTaskId={this.state.selectedAssignmentTaskId}
                            assignmentTaskAllowEdit = {this.state.assignmentTaskAllowEdit}
                            fieldObj = {this.state.templateFieldItem}
                            themeSettings={themeSettings}
                            saveTableValues = {this.saveTableValues}
                            isDraftOnward = {this.props.isDraftOnward}
                        />

                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type={Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                            themeSettings={themeSettings}
                        />
                        {
                            this.state.executionTemplateFixedFields?.valueAddition === "Y" &&
                            <SelectValueAdditionDialog
                                showVADialog={this.state.showSelectVADialog}
                                handleSelectVADialogClose={this.handleSelectVADialogClose}
                                selectedVAObj={this.state.selectedVAObj}
                                assignmentTaskId={this.state.selectedAssignmentTaskId}
                                valueAdditionArr={this.state.valueAdditions}
                                themeSettings={themeSettings}
                                isDraftOnward = {this.props.isDraftOnward}
                            />
                        }

                        <Modal className="followup-task-select-table-dialog custom-dialog"
                            show={this.state.showAssignResourcesDialogFlag} 
                            backdrop="static">
                            <Modal.Header>
                                <h5>Assign Resources</h5>
                                <button 
                                    onClick={this.assignResourcesDialogClose}
                                    type="button" data-dismiss="modal">
                                    <span class="material-icons">close</span>
                                </button>
                            </Modal.Header>
                            <hr />
                            <div className="modal-body">
                                <div className="body-wrapper">
                                    <div className="search-addnew-bg" style={{marginTop:0}}>
                                        <div className="search-addnew">
                                            <div className="flex-center-layout">
                                                <div style={{ marginRight: 5 }}>
                                                    <FilterDropDown
                                                        placeholder={this.state.assignResourcesPlaceholder=="All"?this.state.assignmentArea:this.state.assignResourcesPlaceholder}
                                                        dropdownArr={this.state.assigneResourcesFilterDropDownArr}
                                                        onDropDownItemClick={this.assigneResourceFilterDropDownOnClick}
                                                        themeSettings={themeSettings}
                                                        name={"label"}
                                                        dropdownWidth="200px"
                                                    />
                                                </div>
                                                <div className="bordered-anchor" style={{marginRight:5, width:"120px"}}>
                                                    <a onClick={this.unSelectAllTaskResourceDialog} href="javascript:void(0);">
                                                        <span class="material-icons-outlined">
                                                            check_box_outline_blank
                                                        </span>
                                                        <p>Unselect All</p>
                                                    </a>
                                                </div>
                                                <div className="bordered-anchor" style={{marginRight:5, width:"120px"}}>
                                                    <a onClick={this.selectAllTaskResourceDialog} href="javascript:void(0);">
                                                        <span class="material-icons-outlined">
                                                            check_box
                                                        </span>
                                                        <p>Select All</p>
                                                    </a>
                                                </div>
                                                <div style={{width:"300px",marginRight:5}}>
                                                    <RegularDropdown 
                                                        placeholder={this.state.resourceDropdownPlaceholder}
                                                        dropdownArr={this.state.resourceUser}
                                                        labelParam="fullName"
                                                        onDropDownItemClick={this.resourceDropdownClick}
                                                        defaultPlaceholderDropDown={resourceDropdownPlaceholder}
                                                    />
                                                </div>
                                                <DatePicker
                                                    onChange={this.onDateChange}
                                                    format={"DD/MM/YYYY"}
                                                    placeholder="Select Date"
                                                    value={""}
                                                    open={this.state.open}
                                                    onOpenChange={this.handleOpenChange}
                                                    onClick={() => this.setState({ open: true })}
                                                    disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
                                                />
                                            </div>
                                            <div>
                                                <DialogSaveButton themeSettings={themeSettings} 
                                                onClick={this.onClickAddResourceToAssignment} type="button" 
                                                className="modal-save m-b-0">Assign</DialogSaveButton>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="assign-taskresource" style={{position:"relative"}}>
                                        {
                                            !isEmptyArray(this.state.datalistArr)  && this.state.assignmentArea!=="Function" &&
                                            (
                                            this.state.assigneResourcesFilterDataList.length > 0
                                            ? this.state.assigneResourcesFilterDataList
                                            : this.state.datalistArr
                                            ).map((item, index)=>{
                                                return(
                                                    !isEmptyArray(item.taskList) &&
                                                    <div>
                                                        <h6 style={{marginTop:index===0?15:10}} className="small-h6">
                                                            {
                                                                isEmptyVariable(item.processName)
                                                                ?(isEmptyVariable(item.functionName)
                                                                    ?(item.heading)
                                                                    :item.functionName)
                                                                :item.processName
                                                            }
                                                        </h6>
                                                        {
                                                            !isEmptyArray(item.taskList) &&
                                                            <div>
                                                                <div className="common-tab-bg">
                                                                    <div className="common-table" style={{ width: "100%" }}>
                                                                        <table className="table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th></th>
                                                                                    <th width={"20%"}>Tasks</th>
                                                                                    <th style={{minWidth:"150px"}}>Resource</th>
                                                                                    <th style={{minWidth:"150px"}}>Follow Up Date</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {!isEmptyArray(item.taskList) &&
                                                                                    item.taskList.map((taskItem,idx)=>{
                                                                                        let userDetails = this.state.followUpAssignTask.filter(e => e.assignmentTaskId == taskItem.assignmentTaskId && e.taskId == taskItem.taskId);
                                                                                        let yNRank=defaultYNPlaceholder;
                                                                                        //check allowEdit
                                                                                        let assignmentTaskAllowEdit=this.getAllowEdit(taskItem);

                                                                                        // For Y/N, get label from yesNoRankArr
                                                                                        if(this.state.showYesNoDD && !isEmptyVariable(taskItem.rank)){
                                                                                            Constants.yesNoRankArr.forEach((rankItem)=>{
                                                                                                if(taskItem.rank===rankItem.id){
                                                                                                    yNRank=rankItem.label;
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                        if(taskItem.added){
                                                                                            return (
                                                                                                <tr style={{background:assignmentTaskAllowEdit==="Y"?"#fff":"#f5f5f5"}}>
                                                                                                    <td className="text-center">
                                                                                                        {isEmptyArray(userDetails)||isEmptyVariable(userDetails[0].observationId)?
                                                                                                            <TableCheckBox onClick={this.handleStopPropagation}
                                                                                                                themeSettings={themeSettings}>
                                                                                                                <input 
                                                                                                                    type="checkbox"
                                                                                                                    name={idx+":-:"+item.processId}
                                                                                                                    id={"RES"+idx+":-:"+item.processId+":-:"+taskItem.taskId}
                                                                                                                    onChange={(e) => this.handleSelectionTaskInResourcesDialog(e,taskItem)}
                                                                                                                    checked={taskItem.isResourceCheckboxSelected?true:false}
                                                                                                                />
                                                                                                                <label for={"RES"+idx+":-:"+item.processId+":-:"+taskItem.taskId} 
                                                                                                                    style={{marginLeft:6}}></label>
                                                                                                            </TableCheckBox>
                                                                                                            :""
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div className="flex-center-layout">
                                                                                                            <div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{__html: this.state.taskMap[taskItem.taskId]}} />
                                                                                                            {
                                                                                                                item.completionStatus==="Completed" &&
                                                                                                                <span style={{marginLeft:6,fontSize:18}}
                                                                                                                className="material-icons">done_all</span>
                                                                                                            }
                                                                                                            </div>
                                                                                                    </td>
                                                                                                    <td>{isEmptyVariable(taskItem.resourcefullName)?"":taskItem.resourcefullName}</td>
                                                                                                    <td>{isEmptyVariable(taskItem.followUpDate)?"":moment(taskItem.followUpDate).format("DD/MM/YYYY")}</td>
                                                                                                </tr>
                                                                                            );
                                                                                        }     
                                                                                    })}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            isEmptyArray(item.taskList) &&
                                                            <div class="no-items-layout">
                                                                <div class="no-items-card" style={{padding:"10px"}}>
                                                                    <h6 style={{margin:0,fontSize:"13px"}}>
                                                                    {
                                                                        isEmptyVariable(this.state.apiSearchKey)?
                                                                        Constants.NO_RECORDS_WARNING
                                                                        :
                                                                        Constants.EMPTY_SEARCH_WARNING
                                                                    }
                                                                    </h6>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                );
                                            })
                                        }
                                        {
                                            !isEmptyArray(this.state.datalistArr) && this.state.assignmentArea==="Function" &&
                                            (
                                                this.state.assigneResourcesFilterDataList.length > 0
                                                ? this.getProcessGroupingTaskList(this.state.assigneResourcesFilterDataList,this.state.assignmentArea)
                                                : this.getProcessGroupingTaskList(this.state.datalistArr,this.state.assignmentArea)
                                            )
                                            .map((functionObj, idx) => {
                                                return !isEmptyArray(functionObj.processTaskList) && Object.values(functionObj.processTaskList).map((item,index)=>{
                                                    let showTasks = false;
                                                    showTasks=functionObj.taskList.some((item) =>item.added===true)
                                                    if(showTasks){
                                                        return (
                                                        !isEmptyArray(item.taskList) &&
                                                        <div>
                                                            <div className="sticky-exec-header">
                                                                {
                                                                    <h6 style={{ marginTop: 5 }} className="small-h6">
                                                                        {!isEmptyVariable(functionObj.functionName)?functionObj.functionName:""}
                                                                    </h6>
                                                                }
                                                                <center>
                                                                    <h6 style={{marginTop:5}} className="small-h6">
                                                                        {!isEmptyVariable(item.processName)?item.processName:""}
                                                                    </h6>
                                                                </center>
                                                            </div>
                                                            {
                                                                !isEmptyArray(functionObj.taskList) &&
                                                                <div>
                                                                    <div className="common-tab-bg">
                                                                        <div className="common-table" style={{width:"100%"}}>
                                                                            <table className="table">
                                                                                <thead className="sticky-exec-header" style={{top:54}}>
                                                                                    <tr>
                                                                                        <th></th>
                                                                                        {
                                                                                            this.state.selectedTaskTemplateFields.map(item=>{
                                                                                                return <th className={item.fieldType === TemplateConstants.FIELD_TYPE_TEXT
                                                                                                    ?"scrollable-table-task-text-th":"scrollable-table-task-others-th"}>
                                                                                                    {item.fieldName}
                                                                                                </th>
                                                                                            })
                                                                                        }
                                                                                        <th style={{minWidth:"150px"}}>Resource</th>
                                                                                        <th style={{minWidth:"150px"}}>Follow Up Date</th>
                                                                                        {
                                                                                            this.state.executionTemplateFixedFields?.assignRisk === "Y" &&
                                                                                            <th>Risk</th>
                                                                                        }
                                                                                        {
                                                                                            this.state.showWeight &&
                                                                                            <th>Weight</th>
                                                                                        }
                                                                                        {
                                                                                            this.state.showYesNoDD &&
                                                                                            <th>Rank</th>
                                                                                        }
                                                                                        {
                                                                                            this.state.showRanking &&
                                                                                            <th>Rank</th>
                                                                                        }
                                                                                        {
                                                                                            this.state.execTaskTemplateFields.map(item=>{ 
                                                                                               return item.fieldType === TemplateConstants.FIELD_TYPE_SINGLE_SELECT &&
                                                                                                     <th>
                                                                                                        {item.fieldName}
                                                                                                        {item.isMandatorySelected === "Y"?"*":""}
                                                                                                    </th>   
                                                                                            })  
                                                                                        }   
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        !isEmptyArray(item.taskList) && 
                                                                                        item.taskList.map((taskItem,idx) => {
                                                                                            let assignmentTaskAllowEdit=this.getAllowEdit(taskItem);
                                                                                            let yNRank=defaultYNPlaceholder;

                                                                                            if(taskItem.added){
                                                                                                return <tr style={{background:assignmentTaskAllowEdit==="Y"?"#fff":"#f5f5f5"}}>
                                                                                                    <td className="text-center">
                                                                                                        <TableCheckBox onClick={this.handleStopPropagation}
                                                                                                            themeSettings={themeSettings}>
                                                                                                            <input 
                                                                                                                type="checkbox"
                                                                                                                name={idx+":-:"+functionObj.functionId}
                                                                                                                id={"RES"+idx+":-:"+functionObj.functionId+":-:"+taskItem.taskId}
                                                                                                                onChange={(e) => this.handleSelectionTaskInResourcesDialog(e,taskItem)}
                                                                                                                checked={taskItem.isResourceCheckboxSelected?true:false}
                                                                                                            />
                                                                                                            <label for={"RES"+idx+":-:"+functionObj.functionId+":-:"+taskItem.taskId}
                                                                                                                style={{marginLeft:6}}></label>
                                                                                                        </TableCheckBox>
                                                                                                    </td>
                                                                                                    {
                                                                                                        this.state.selectedTaskTemplateFields.map((item,taskIdx)=>{
                                                                                                            return <td 
                                                                                                            style={taskIdx+"" === "0"?{borderLeft:"5px solid "+taskItem.colorCode}:{}}>
                                                                                                                <div className = {item.fieldId + "" === "1" ? "dangerouslysethtml-flex" : ""}>
                                                                                                                    <div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{__html: this.getTaskDisplayValues(
                                                                                                                    taskItem.taskId, item )}}/>
                                                                                                                    {
                                                                                                                        taskItem.completionStatus==="Completed" && item.fieldId+"" === "1" &&
                                                                                                                        <span className="material-icons exec-task-done">done_all</span>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </td>
                                                                                                        })
                                                                                                    }
                                                                                                    
                                                                                                    <td>{isEmptyVariable(taskItem.resourcefullName)?"":taskItem.resourcefullName}</td>
                                                                                                    <td>{isEmptyVariable(taskItem.followUpDate)?"":moment(taskItem.followUpDate).format("DD/MM/YYYY")}</td>
                                                                                                    {
                                                                                                        this.state.executionTemplateFixedFields?.assignRisk === "Y" &&
                                                                                                        <td>
                                                                                                            {isEmptyVariable(taskItem.assignmentTaskRiskLevel)?"-":taskItem.assignmentTaskRiskLevel}
                                                                                                        </td>
                                                                                                    }
                                                                                                    {
                                                                                                        this.state.showWeight &&
                                                                                                        <td className="text-center">{taskItem.weight}</td>
                                                                                                    }
                                                                                                    {
                                                                                                        this.state.showYesNoDD &&
                                                                                                        <td>
                                                                                                            <RegularDropdown 
                                                                                                                placeholder={
                                                                                                                    isEmptyVariable(taskItem.rank)
                                                                                                                    ?defaultYNPlaceholder
                                                                                                                    :yNRank
                                                                                                                }
                                                                                                                dropdownArr={Constants.yesNoRankArr}
                                                                                                                labelParam="label"
                                                                                                                onDropDownItemClick={this.maxrDropdownClick}
                                                                                                                udf={idx+":-:"+index}
                                                                                                                disabled={assignmentTaskAllowEdit === "Y"?false:true}
                                                                                                            />
                                                                                                        </td>
                                                                                                    }
                                                                                                    {
                                                                                                        this.state.showRanking &&
                                                                                                        <td>
                                                                                                            <RegularDropdown 
                                                                                                                placeholder={
                                                                                                                    isEmptyVariable(taskItem.rank)
                                                                                                                    ?defaultWeightPlaceholder
                                                                                                                    :(taskItem.rank === -1?"N/A":taskItem.rank)
                                                                                                                }
                                                                                                                dropdownArr={this.state.rankingArray}
                                                                                                                labelParam="label"
                                                                                                                onDropDownItemClick={this.maxrDropdownClick}
                                                                                                                udf={idx+":-:"+index}
                                                                                                                disabled={assignmentTaskAllowEdit === "Y"?false:true}
                                                                                                            />
                                                                                                        </td>
                                                                                                    }
                                                                                                    {
                                                                                                        this.state.execTaskTemplateFields.map(item=>{
                                                                                                            return this.getExecSingleSelectTaskDisplayTableData(taskItem.assignmentTaskId,item,assignmentTaskAllowEdit)
                                                                                                        })
                                                                                                    }
                                                                                                </tr>
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                isEmptyArray(item.taskList) &&
                                                                <div class="no-items-layout">
                                                                    <div class="no-items-card" style={{padding:"10px"}}>
                                                                        <h6 style={{margin:0,fontSize:"13px"}}>
                                                                            {
                                                                                isEmptyVariable(this.state.apiSearchKey)?
                                                                                Constants.NO_RECORDS_WARNING
                                                                                :
                                                                                Constants.EMPTY_SEARCH_WARNING
                                                                            }
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                        )
                                                    }
                                                })
                                            })
                                        }
                                    </div>
                                </div>
                                {/* {
                                    !isEmptyVariable(this.state.otherMasterFormErr) &&
                                    <span className="cm-error">{this.state.otherMasterFormErr}</span>
                                } */}
                            </div>
                        </Modal>
                    </div>
                }
			</Router>
		);
	}
}

export default AddTaskAndResource;
