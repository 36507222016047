import React, { Component } from 'react';
import {getLocalStorageVariables,getThemeSettingsVariables,
    GetOrderedMasterTabMenu} from '../../../common/Global/commonFunctions';
import * as Constants from '../../../common/Global/constants';
import DocumentList from './listdocumentmaster';
import DocumentSampleList from './listDocumentSample';
import DocumentERPList from './listDocumentERPMaster';
import MasterMenuTabs from '../Common/topmenu';
import Sidebar from '../../../common/Sidebar';
import Topbar from '../../../common/Topbar';
import {
    BrowserRouter as Router,
} from 'react-router-dom';

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();
const activeStatus="Active";

class DocumentMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menus:{},
            user:{},
            showDocumentList:true,
            showDocumentSampleList:false,
            showDocumentErpList:false,

            documentItem:"",
            deletePermission:"N",
            
            currentPageNo: 1,
            searchkey:"",
            resultSize:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"",
            deptOnly:"N",
            status:activeStatus,
        }
    }

    componentDidMount(){
        this.getInitData();
    }

    //Save filters before showing document samples listing
    //when user presses back reapply the filters
    handleActionDropdownItemClick = (documentItem,currentPageNo,searchkey,resultSize,
        sort,sortDir,deptOnly,status,target) => {
        
        this.setState({
            showDocumentList:false,
            showDocumentSampleList:target==="documentSampleList"?true:false,
            showDocumentErpList:target==="documentERPList"?true:false,

            documentItem:documentItem,
            currentPageNo:currentPageNo,
            searchkey:searchkey,
            resultSize:resultSize,
            sort:sort,
            sortDir:sortDir,
            deptOnly:deptOnly,
            status:status,
        })
    }

    handleBackBtnClickFromSamplesandERP = () => {
        this.setState({
            showDocumentList:true,
            showDocumentSampleList:false,
            showDocumentErpList:false,

            documentItem:"",
        })
    }

    /***************************API CALLS******************************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetUserMenu,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
            })
        })
        .then(menuRes => { 
            return menuRes.json();
        })
        .then(menuRes => {
            let menusTemp = {};
            let userTemp = {};
            let allSettings = [];
            let subscriptionTemp = {};

            if(menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(menuRes.responseCode === Constants.CODE_SUCCESS){
                menusTemp = menuRes.data.menus;
                userTemp = menuRes.data.user;
                allSettings = menuRes.data.allSettings;
                subscriptionTemp = menuRes.data.subscription;
            }else{
                menusTemp = {};
                userTemp = {};
            }

            //Order the menu tabs in a specific way
            let menusTemp2 = GetOrderedMasterTabMenu(menusTemp)
            if(allSettings.find(item => item.settingDisplayColumn === Constants.SCREEN_INDUSTRY).settingColumnEnabled === "N"){
                menusTemp2 = Object.fromEntries(Object.entries(menusTemp2).filter(([key, value]) => key !== Constants.SCREEN_INDUSTRY))
            }
            let deletePermission = "N";

            if(menusTemp["Delete Document"] === "ALL"){
                deletePermission = "Y";
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,
                deletePermission:deletePermission,

                menus:menusTemp2,
                user:userTemp,
                subscription:subscriptionTemp,
            })
        })
    }

    render(){
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                <MasterMenuTabs 
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}
                                />
                                {
                                    this.state.showDocumentList &&
                                    <DocumentList 
                                        handleActionDropdownItemClick={this.handleActionDropdownItemClick}
                                        currentPageNo = {this.state.currentPageNo}
                                        searchkey={this.state.searchkey}
                                        resultSize={this.state.resultSize}
                                        status={this.state.status}
                                        sort={this.state.sort}
                                        sortDir={this.state.sortDir}
                                        deptOnly={this.state.deptOnly}
                                        subscription={this.state.subscription}
                                        themeSettings={themeSettings}
                                        masterMenus={this.state.menus}
                                        deletePermission={this.state.deletePermission}
                                    />
                                }
                                {
                                    this.state.showDocumentSampleList &&
                                    <DocumentSampleList 
                                        documentId={this.state.documentItem.documentId}
                                        documentName={this.state.documentItem.name}
                                        allowEdit={this.state.documentItem.allowEdit}
                                        handleBackBtnClickFromSamplesandERP={this.handleBackBtnClickFromSamplesandERP}
                                        themeSettings={themeSettings}
                                        masterMenus={this.state.menus}
                                    />
                                }
                                {
                                    this.state.showDocumentErpList &&
                                    <DocumentERPList 
                                        documentId={this.state.documentItem.documentId}
                                        documentName={this.state.documentItem.name}
                                        allowEdit={this.state.documentItem.allowEdit}
                                        handleBackBtnClickFromSamplesandERP={this.handleBackBtnClickFromSamplesandERP}
                                        themeSettings={themeSettings}
                                        masterMenus={this.state.menus}
                                    />
                                }
                            </div>
                        </div>
                    </section>
                </main>
            </Router>
        )
    }

}

export default DocumentMain;