import React from 'react';
import {Modal} from 'react-bootstrap';
import {isEmptyVariable} from '../Global/commonFunctions';

class AlertWithRadioDialog extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            deleteChildren:"",
            errorMsg:""
        }
    };

    handleCheck = (e) =>{
        let name = e.target.name;

        if(name === "deleteChildrenYes"){
            this.setState({
                deleteChildren:"Y"
            })
        }else if(name === "deleteChildrenNo"){
            this.setState({
                deleteChildren:"N"
            })
        }else{
            this.setState({
                deleteChildren:""
            })
        }
    }

    handleProceedButtonClick = () => {
        if(this.props.showRadio){
            let isError = false;
            let errorMessage = "";

            if(isEmptyVariable(this.state.deleteChildren)){
                isError = true;
                errorMessage="Please select the delete option"
            }

            if(isError){
                this.setState({
                    errorMsg:errorMessage
                })
            }else{
                this.props.proceedBtnClick(this.state.deleteChildren,
                "N");
            }
        }else{
            this.props.proceedBtnClick("Y","Y");
        }
    }

    render() {
        return (
            <Modal show={this.props.showAlertDialog} backdrop="static">
                <Modal.Body>
                    <div class="dialog-layout">
                        <button 
                            onClick={this.props.handleAlertDialogClose}>
                            <span className="material-icons">close</span>
                        </button>
                        <span className="material-icons">warning</span>
                        {
                            // !this.props.type === Constants.ALERT_TYPE_ALERT &&
                            !isEmptyVariable(this.props.alertDialogHeading) &&
                            <h5>
                                {this.props.alertDialogHeading}
                            </h5>
                        }
                        <p style={{marginBottom:this.props.showRadio?10:15}}>{this.props.alertDialogMessage}</p>

                        {
                            this.props.showRadio &&
                            <div className="radio-group radio-group-block">
                                <div className="radio-btn">
                                    <input 
                                        type="radio"
                                        name="deleteChildrenYes"
                                        id="deleteChildrenYes"
                                        onChange={this.handleCheck}
                                        checked={this.state.deleteChildren === "Y"?true:false}
                                    />
                                    <label for="deleteChildrenYes">Delete selected node along with its children</label>
                                </div>
                                <div className="radio-btn">
                                    <input 
                                        type="radio"
                                        name="deleteChildrenNo"
                                        id="deleteChildrenNo"
                                        onChange={this.handleCheck}
                                        checked={this.state.deleteChildren === "N"?true:false}
                                    />
                                    <label for="deleteChildrenNo">Delete selected node and move children to parent</label>
                                </div>
                            </div>
                        }

                        {
                            this.state.errorMsg &&
                            <span className="cm-error dialog-error">{this.state.errorMsg}</span>
                        }

                        <div class="dialog-btn-layout">
                            <a class="alert-cancel" href="javascript:void(0);"
                                onClick={this.props.handleAlertDialogClose}
                            >Cancel</a>
                            
                            <a 
                                class="alert-proceed alert-dialog-warning" href="javascript:void(0);"
                                onClick = {this.handleProceedButtonClick}
                                style={{
                                    marginLeft:"10px"
                                }}
                            >
                            {this.props.showLoader ? <div class="loader-btn mt-2"></div> : this.props.proceedBtnLabel}
                            </a>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

export default AlertWithRadioDialog;