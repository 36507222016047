import React from "react";
import { Modal } from "react-bootstrap";
import { isEmptyVariable } from "../Global/commonFunctions";
import { AlertDialogSpan,DialogProceedButton} from "../Global/globalStyles.style";
import * as Constants from "../Global/constants";
class AlertWithRadioDialog extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			remarks: "",
			formErrors: {},
		};
	}

	componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showAlertDialog){
            this.setState({
                remarks: "",
				formErrors: {},
            })
        }
    }

	handleChange = (e) => {
		let name = e.target.name;
		this.setState({ [name]: e.target.value });
	};

	handleProceedButtonClick = () => {
		let formErrors = {};
		let isError = false;

		if(isEmptyVariable(this.state.remarks)){
			formErrors.remarks = "Please enter Remarks";
			isError = true;
		}

		if (isError) {
			this.setState({
				formErrors: formErrors,
				showLoader: false,
			});

			setTimeout(
				function () {
					this.setState({
						formErrors: {},
					});
				}.bind(this),
				Constants.WRNG_MSG_TIMEOUT
			);
		} else {
			this.props.proceedBtnClick(this.state.remarks);
		}
	};

	render() {
		return (
			<Modal show={this.props.showAlertDialog} backdrop="static">
				<Modal.Body>
					<div class="dialog-layout">
						<button onClick={this.props.handleAlertDialogClose}>
							<span className="material-icons">close</span>
						</button>
						<AlertDialogSpan 
                            themeSettings={this.props.themeSettings}
                            className="material-icons">error_outline</AlertDialogSpan>
						{
							// !this.props.type === Constants.ALERT_TYPE_ALERT &&
							!isEmptyVariable(this.props.alertDialogHeading) && <h5>{this.props.alertDialogHeading}</h5>
						}
						<p style={{ marginBottom: this.props.showRadio ? 10 : 15 }}>{this.props.alertDialogMessage}</p>
						<div class="addnew-modal-form">
							<div class="addnew-modal-form-group">
								<p style={{textAlign:"left"}}>Remarks*</p>
								<textarea 
									type="text" 
									placeholder="Enter Remarks" 
									name="remarks"
									onChange ={this.handleChange}
									value={this.state.remarks}
								/>
								{!isEmptyVariable(this.state.formErrors["remarks"]) && <p className="cm-error" style={{textAlign:"left"}}>{this.state.formErrors["remarks"]}</p>}
							</div>
						</div>

						<div class="dialog-btn-layout">
							<a class="alert-cancel" href="javascript:void(0);" onClick={this.props.handleAlertDialogClose}>
								Cancel
							</a>

							<DialogProceedButton
								themeSettings={this.props.themeSettings}
								class="alert-proceed"
								href="javascript:void(0);"
								onClick = {this.handleProceedButtonClick}
								style={{
									marginLeft:(this.props.type === Constants.ALERT_TYPE_ALERT)?"0px":"10px"
								}}
							>
								{this.props.showLoader ? <div class="loader-btn mt-2"></div> : this.props.proceedBtnLabel}
							</DialogProceedButton>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		);
	}
}

export default AlertWithRadioDialog;
