import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {PageSaveButton,UserRoleCheckBoxLayout} from '../../common/Global/globalStyles.style';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import AlertDialog from '../../common/AlertDialog';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import UserManagementTopMenu from './topmenu';
import {getLocalStorageVariables,isEmptyVariable, ifEmptyReturn,getThemeSettingsVariables,
    isEmptyArray} from '../../common/Global/commonFunctions';

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();
class AddUserRole extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            roleUserCount:props.location.state.roleUserCount,
            screens:[],
            reviewRoleId:this.props.match.params.id,
            roleArr:{},
            roleErrors:{},
            userRoleName:"",
            description:"",

            singleLevelRoleArr :{},

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            screensObj:{}
        }
        this.inputText = React.createRef();
        this.screenNameFlags = {};
    }

    componentDidMount(){
        this.getUserRoleDetails();
    }

    getUserRoleDetails = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.MasterScreens,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            }),
            fetch(Constants.GetRoleMatrix,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    reviewRoleId:this.state.reviewRoleId
                })
            }),
            fetch(Constants.GetUserMenu,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            })
        ])
        .then(([masterRes,roleMatrixRes,menuRes]) => { 
            return Promise.all([masterRes.json(),roleMatrixRes.json(),menuRes.json()]) 
        })
        .then(([masterRes,roleMatrixRes,menuRes]) => {
            let masterScreens = [];
            let matrix = [];
            let reviewRoleName = "";
            let descriptionTemp = "";
            let screensObj = {};
            let newScreensObj = {};
            let menusTemp = {};
            let allSettings = [];

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                masterScreens = masterRes.data.screens;
                
                //map screens based on screenType
                //SAMPLE OUTPUT: {"Tasks":[ {"screenName":"Task", "isMatrix":"Y","screenType":"Tasks" } "Masters":[ { "screenName":"Other Masters", "isMatrix":"Y", "screenType":"Masters"} ]
                masterScreens.forEach((item)=>{
                    if(item.isMatrix==="Y"){
                        if(isEmptyArray(screensObj[item.screenType] )){
                            screensObj[item.screenType]=[];
                        }
                        screensObj[item.screenType].push(item);
                    }    
                })
                newScreensObj = {
                    "Assignment":screensObj["Assignment"],
                    "Tasks":screensObj["Tasks"],
                    "Masters":screensObj["Masters"],
                    "Settings":screensObj["Settings"],
                }
            }else{
            }

            if(roleMatrixRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                roleMatrixRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(roleMatrixRes.responseCode === Constants.CODE_SUCCESS){
                matrix = roleMatrixRes.data.matrix;
                reviewRoleName = roleMatrixRes.data.reviewRole;
                descriptionTemp = ifEmptyReturn(roleMatrixRes.data.description);
            }else{
            }

            for(const [index, value] of masterScreens.entries()){
                this.screenNameFlags[value.screenName] = false;
            }

            let roleArr = {};
            let singleLevelRoleArr = {};
            for(const [idx,value] of matrix.entries()){
                let roleObjTemp = value.screenName + "_";
                let temp = "";

                if(value.ViewOnly === "Y"){
                    temp = roleObjTemp + "ViewOnly";
                    roleArr[temp] = "Y";
                    this.screenNameFlags[value.screenName] = true;
                }
                if (value.L0 === "Y") {
                    if (
                        value.screenName === Constants.USER_ROLE_TASK_SEQUENCING ||
                        value.screenName === Constants.USER_ROLE_DASHBOARD_CURRENT ||
                        value.screenName === Constants.USER_ROLE_DASHBOARD_SCHEDULED ||
                        value.screenName === Constants.USER_ROLE_DASHBOARD_PLANNED ||
                        value.screenName === Constants.USER_ROLE_DASHBOARD_DRAFT ||
                        value.screenName === Constants.USER_ROLE_DASHBOARD_COMPLETED ||
                        value.screenName === Constants.USER_ROLE_DASHBOARD_NEW_TASKS ||
                        value.screenName === Constants.USER_ROLE_DASHBOARD_FIGURE_AND_CHART ||
                        value.screenName === Constants.USER_ROLE_DASHBOARD_ASSIGNMENT_SUMMARY ||
                        value.screenName === Constants.USER_ROLE_DEPARTMENT_SETTINGS
                    ) {
                        singleLevelRoleArr[value.screenName] = "Y";
                    }
                    else{
                        temp = roleObjTemp + "0";
                        roleArr[temp] = "Y";
                        this.screenNameFlags[value.screenName] = true;
                    }
                }
                if(value.L1 === "Y"){
                    temp = roleObjTemp + "1";
                    roleArr[temp] = "Y";
                    this.screenNameFlags[value.screenName] = true;
                }
                if(value.L2 === "Y"){
                    temp = roleObjTemp + "2";
                    roleArr[temp] = "Y";
                    this.screenNameFlags[value.screenName] = true;
                }
                if(value.L3 === "Y"){
                    temp = roleObjTemp + "3";
                    roleArr[temp] = "Y";
                    this.screenNameFlags[value.screenName] = true;
                }
                if(value.L4 === "Y"){
                    temp = roleObjTemp + "4";
                    roleArr[temp] = "Y";
                    this.screenNameFlags[value.screenName] = true;
                }
                if(value.L5 === "Y"){
                    temp = roleObjTemp + "5";
                    roleArr[temp] = "Y";
                    this.screenNameFlags[value.screenName] = true;
                }
                if(value.L6 === "Y"){
                    temp = roleObjTemp + "6";
                    roleArr[temp] = "Y";
                    this.screenNameFlags[value.screenName] = true;
                }
                if(value.L7 === "Y"){
                    temp = roleObjTemp + "7";
                    roleArr[temp] = "Y";
                    this.screenNameFlags[value.screenName] = true;
                }
            }

            if(menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(menuRes.responseCode === Constants.CODE_SUCCESS){
                menusTemp = menuRes.data.menus;
                allSettings = menuRes.data.allSettings;
                if(allSettings.find(item => item.settingDisplayColumn === Constants.SCREEN_INDUSTRY).settingColumnEnabled === "N"){
                    newScreensObj.Masters = newScreensObj.Masters.filter(item => 
                        item.screenName !== Constants.SCREEN_INDUSTRY && item.screenName !== "Delete Industry")
                }
            }else{
                menusTemp = {};
            }

            this.setState({
                userRoleName:reviewRoleName,
                description:descriptionTemp,
                screens:masterScreens,
                roleArr:roleArr,
                singleLevelRoleArr:singleLevelRoleArr,
                showLoader:false,
                screensObj:newScreensObj,
                matrix:matrix
            },()=>{
                this.inputText.current.focus();
            })
        });
    }

    onCancelClick = () => {
        this.props.history.push(Constants.URL_USERROLES);
    }

    saveUserRole = () => {
        this.setState({
            showLoader:true,
        });
        //Add Validation for name and levels
        let roleErrTemp = {};
        let isError = false;
        if(isEmptyVariable(this.state.userRoleName)){
            isError = true;
            roleErrTemp['userRoleNameErr'] = "Please enter user role";
        }

        if(!isEmptyVariable(this.state.description)){
            if(this.state.description.length > Constants.DESC_STRING_LENGTH){
                isError = true;
                roleErrTemp['description'] = "Description must be less then 500 characters";
            }
        }

        let atleastOneValue = false;
        let roleArrTemp = this.state.roleArr;
        let postRoleArr = {};
        const keys = Object.keys(roleArrTemp);
        for(const key of keys){
            if(roleArrTemp[key] === "Y"){
                postRoleArr[key] = "Y";
                atleastOneValue = true;
            }
        }

        if(!atleastOneValue){
            isError = true;
            // roleErrTemp['userRoleMatrixErr'] = "Please select atleast one review role";
            // toast.warning("Please select atleast one review role");
        }
        if(this.screenNameFlags["Schedule Assignment(All)"] && this.screenNameFlags["Schedule Assignment(Manager)"]){
            isError = true;
            // roleErrTemp['userRoleMatrixErr'] = "Please select either Schedule Assignment(All) or Schedule Assignment(Manager)";
            // toast.warning("Please select either Schedule Assignment(All) or Schedule Assignment(Manager)")
        }
        if(this.screenNameFlags["Plan Assignment(All)"] && this.screenNameFlags["Plan Assignment(Manager)"]){
            isError = true;
            // roleErrTemp['userRoleMatrixErr'] = "Please select either Plan Assignment(All) or Plan Assignment(Manager)";
            // toast.warning("Please select either Plan Assignment(All) or Plan Assignment(Manager)");
        }

        let singleLevelRoleArrTemp = this.state.singleLevelRoleArr;
        let postSingleLevelRoleArr = {};
        const singleLevelKeys = Object.keys(singleLevelRoleArrTemp);
        for(const key of singleLevelKeys){
            if(singleLevelRoleArrTemp[key] === "Y"){
                postSingleLevelRoleArr[key] = "Y";
            }
        }

        if(isError){
            if(!atleastOneValue){
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:"Please select atleast one review role"
                })
            }
            if(this.screenNameFlags["Schedule Assignment(All)"] && this.screenNameFlags["Schedule Assignment(Manager)"]){
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:"Please select either Schedule Assignment(All) or Schedule Assignment(Manager)"
                })
            }
            if(this.screenNameFlags["Plan Assignment(All)"] && this.screenNameFlags["Plan Assignment(Manager)"]){
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:"Please select either Plan Assignment(All) or Plan Assignment(Manager)"
                })
            }
            this.setState({
                roleErrors:roleErrTemp,
                showLoader:false
            });

        }else{
            fetch(Constants.UpdateRoles,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    reviewRole:this.state.userRoleName,
                    description:this.state.description,
                    reviewRoleId:this.state.reviewRoleId,
                    roleMatrix:JSON.stringify(postRoleArr),
                    ...postSingleLevelRoleArr
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                    })
                    this.props.history.push(Constants.URL_USERROLES);
                }else{
                    this.setState({
                        showLoader:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    })
                }
            });
        }
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        let FormErrorsTemp = this.state.roleErrors;
        
        if(name === "userRoleName"){
            if(isEmptyVariable(value)){
                FormErrorsTemp['userRoleNameErr'] = "Please enter user role";
            }else{
                FormErrorsTemp['userRoleNameErr'] = "";
            }
        }else if(name === "description"){
            if(!isEmptyVariable(value) && value.length > Constants.DESC_STRING_LENGTH){
                FormErrorsTemp['description'] = "Description must be less then 500 characters";
            }else{
                FormErrorsTemp['description'] = "";
            }
        }

        this.setState({ 
            [name]: value,
            roleErrors:FormErrorsTemp
        });
    }

    handleCheck = (e) =>{
        let isChecked = e.target.checked;
        let name = e.target.name;
        let roleArrTemp = this.state.roleArr;

        var splitArr = name.split("_");

        if(this.screenNameFlags[splitArr[0]]){
            if(isChecked){
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:"Cannot select multiple levels for same screen"
                })
            }else{
                this.screenNameFlags[splitArr[0]] = false;
                if(isChecked)
                {
                    roleArrTemp[name] = "Y";
                }
                else
                {
                    roleArrTemp[name] = "N";
                }
            }
        }else{
            this.screenNameFlags[splitArr[0]] = true;
            if(isChecked)
            {
                roleArrTemp[name] = "Y";
            }
            else
            {
                roleArrTemp[name] = "N";
            }
        }

        let atleastOne = false;

        for (const [key, item] of Object.entries(roleArrTemp)) {
            if(item === "Y"){
                atleastOne = true;
                break;
            }
        }

        let roleErrTemp = this.state.roleErrors
        if(atleastOne){
            roleErrTemp['userRoleMatrixErr'] = "";
        }else{
            this.setState({
                showAlertDialogInfo:true,
                alertDialogMessageInfo:"Please select atleast one review role"
            })
        }

        this.setState({
            roleArr:roleArrTemp,
            roleErrors:roleErrTemp
        })
    }

    handleCheckSingleLevelRole = (e) =>{
        let isChecked = e.target.checked;
        let name = e.target.name;
        let roleArrTemp = this.state.singleLevelRoleArr;

        if(isChecked)
            {
                roleArrTemp[name] = "Y";
            }
            else
            {
                roleArrTemp[name] = "N";
            }
        this.setState({
            singleLevelRoleArr:roleArrTemp,
        })
    }

    // Checks if a screen is disabled based on the user count and matrix settings.
    isDisabled = (screenName) => {
        if (this.state.roleUserCount > 0) {
            const hasYValue = this.state.matrix.some(item => {
                return item.screenName === screenName &&
                    (item.ViewOnly === 'Y' || item.L0 === 'Y' || item.L1 === 'Y' ||
                     item.L2 === 'Y' || item.L3 === 'Y' || item.L4 === 'Y' || item.L5 === 'Y');
            });
            return hasYValue;
        } else {
          return false;
        }
    }
    
    render() {
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                {
                                    this.state.showLoader &&
                                    <div class="loader"></div>
                                }

                                <UserManagementTopMenu 
                                themeSettings = {themeSettings}
                                hideInfo={true}/>


                                <div className="body-wrapper">

                                    <div className="card-col">
                                        <div className="row custom-row">
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group">
                                                    <p>User Role*</p>
                                                    <input 
                                                        name="userRoleName" 
                                                        type="text" 
                                                        value={this.state.userRoleName}
                                                        onChange ={this.handleChange}
                                                        ref={this.inputText}
                                                        placeholder="Type the role name here" 
                                                        spellCheck="true" />
                                                        {
                                                            !isEmptyVariable(this.state.roleErrors['userRoleNameErr']) &&
                                                            <span class="cm-error">{this.state.roleErrors['userRoleNameErr']}</span>
                                                        }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row custom-row">
                                            <div className="col-md-6 custom-col">
                                                <div className="add-new-form-group m-b-0">
                                                    <p>Description</p>
                                                    <textarea 
                                                        name="description" 
                                                        type="text" 
                                                        value={this.state.description}
                                                        onChange ={this.handleChange}
                                                        placeholder="Enter description" 
                                                        spellCheck="true" />
                                                    {
                                                        !isEmptyVariable(this.state.roleErrors['description']) &&
                                                        <span class="cm-error">{this.state.roleErrors['description']}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-col">
                                        <div className="review-roles-col">
                                            <h6>Review Roles*</h6>

                                            <div className="common-table user-role-table" style={{width: "100%"}}>
                                            {
                                                Object.entries(this.state.screensObj).map(([key,value])=>{
                                                  return <div class="m-b-10">
                                                    <p className="m-t-15">{key}</p>
                                                    <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th width={"30%"}>
                                                                Screens
                                                            </th>
                                                            <th>
                                                                View Only
                                                            </th>
                                                            <th>
                                                                Level 0
                                                            </th>
                                                            <th>
                                                                Level 1
                                                            </th>
                                                            <th>
                                                                Level 2
                                                            </th>
                                                            <th>
                                                                Level 3
                                                            </th>
                                                            <th>
                                                                Level 4
                                                            </th>
                                                            <th>
                                                                Level 5
                                                            </th>
                                                            {/* <th>
                                                                Level 6
                                                            </th>
                                                            <th>
                                                                Level 7
                                                            </th> */}
                                                        </tr>
                                                    </thead>
                                                    {
                                                        value.map((item)=>{
                                                            const isDisabled = this.isDisabled(item.screenName);
                                                            return <tbody>
                                                                {
                                                                    (item.isMatrix === "Y" ) &&
                                                                    <tr style={{background: isDisabled ? "#fafafa" : "#ffffff"}}>
                                                                    <td>
                                                                        {item.screenName}
                                                                    </td>
                                                                    <td>
                                                                        <UserRoleCheckBoxLayout
                                                                        themeSettings = {themeSettings}
                                                                        
                                                                        >
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={item.screenName+"_ViewOnly"}
                                                                            id={item.screenName+"_ViewOnly"}
                                                                            onChange={this.handleCheck}
                                                                            checked={this.state.roleArr[item.screenName+"_ViewOnly"] === "Y"?true:false}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        <label for={item.screenName+"_ViewOnly"}></label>
                                                                        </UserRoleCheckBoxLayout>
                                                                    </td>
                                                                    <td>
                                                                        <UserRoleCheckBoxLayout
                                                                            themeSettings = {themeSettings}
                                                                        
                                                                        >
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={item.screenName+"_0"}
                                                                            id={item.screenName+"_0"}
                                                                            onChange={this.handleCheck}
                                                                            checked={this.state.roleArr[item.screenName+"_0"] === "Y"?true:false}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        <label for={item.screenName+"_0"}></label>
                                                                        </UserRoleCheckBoxLayout>
                                                                    </td>
                                                                    <td>
                                                                        <UserRoleCheckBoxLayout
                                                                        themeSettings = {themeSettings}
                                                                        
                                                                        >
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={item.screenName+"_1"}
                                                                            id={item.screenName+"_1"}
                                                                            onChange={this.handleCheck}
                                                                            checked={this.state.roleArr[item.screenName+"_1"] === "Y"?true:false}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        <label for={item.screenName+"_1"}></label>
                                                                        </UserRoleCheckBoxLayout>
                                                                    </td>
                                                                    <td>
                                                                        <UserRoleCheckBoxLayout
                                                                        themeSettings = {themeSettings}
                                                                        
                                                                        >
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={item.screenName+"_2"}
                                                                            id={item.screenName+"_2"}
                                                                            onChange={this.handleCheck}
                                                                            checked={this.state.roleArr[item.screenName+"_2"] === "Y"?true:false}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        <label for={item.screenName+"_2"}></label>
                                                                        </UserRoleCheckBoxLayout>
                                                                    </td>
                                                                    <td>
                                                                        <UserRoleCheckBoxLayout
                                                                        themeSettings = {themeSettings}
                                                                        
                                                                        >
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={item.screenName+"_3"}
                                                                            id={item.screenName+"_3"}
                                                                            onChange={this.handleCheck}
                                                                            checked={this.state.roleArr[item.screenName+"_3"] === "Y"?true:false}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        <label for={item.screenName+"_3"}></label>
                                                                        </UserRoleCheckBoxLayout>
                                                                    </td>
                                                                    <td>
                                                                        <UserRoleCheckBoxLayout
                                                                        themeSettings = {themeSettings}
                                                                        
                                                                        >
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={item.screenName+"_4"}
                                                                            id={item.screenName+"_4"}
                                                                            onChange={this.handleCheck}
                                                                            checked={this.state.roleArr[item.screenName+"_4"] === "Y"?true:false}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        <label for={item.screenName+"_4"}></label>
                                                                        </UserRoleCheckBoxLayout>
                                                                    </td>
                                                                    <td>
                                                                        <UserRoleCheckBoxLayout
                                                                        themeSettings = {themeSettings}
                                                                        
                                                                        >
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={item.screenName+"_5"}
                                                                            id={item.screenName+"_5"}
                                                                            onChange={this.handleCheck}
                                                                            checked={this.state.roleArr[item.screenName+"_5"] === "Y"?true:false}
                                                                            disabled={isDisabled}
                                                                        />
                                                                        <label for={item.screenName+"_5"}></label>
                                                                        </UserRoleCheckBoxLayout>
                                                                    </td>
                                                                    {/* <td>
                                                                        <UserRoleCheckBoxLayout
                                                                        themeSettings = {themeSettings}
                                                                        
                                                                        >
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={item.screenName+"_6"}
                                                                            id={item.screenName+"_6"}
                                                                            onChange={this.handleCheck}
                                                                            checked={this.state.roleArr[item.screenName+"_6"] === "Y"?true:false}
                                                                        />
                                                                        <label for={item.screenName+"_6"}></label>
                                                                        </UserRoleCheckBoxLayout>
                                                                    </td>
                                                                    <td>
                                                                        <UserRoleCheckBoxLayout
                                                                        themeSettings = {themeSettings}
                                                                        
                                                                        >
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={item.screenName+"_7"}
                                                                            id={item.screenName+"_7"}
                                                                            onChange={this.handleCheck}
                                                                            checked={this.state.roleArr[item.screenName+"_7"] === "Y"?true:false}
                                                                        />
                                                                        <label for={item.screenName+"_7"}></label>
                                                                        </UserRoleCheckBoxLayout>
                                                                    </td> */}
                                                                </tr>
                                                            }
                                                            </tbody>
                                                                })
                                                            }
                                                        </table>
                                                   </div>
                                                })
                                            }
                                                {
                                                    !isEmptyVariable(this.state.roleErrors['userRoleMatrixErr']) &&
                                                    <span style={{marginLeft:10}} class="cm-error">{this.state.roleErrors['userRoleMatrixErr']}</span>
                                                }
                                                <div className="non-matrix-role m-t-20">
                                                    <table>
                                                    {
                                                        !isEmptyArray(this.state.screens) && 
                                                        this.state.screens.map((item,idx) => {
                                                            if(item.isMatrix === "N"){
                                                                return <tr>
                                                                    <td width="40%">
                                                                        {item.screenName}
                                                                    </td>
                                                                    <td>
                                                                        <UserRoleCheckBoxLayout
                                                                        themeSettings = {themeSettings}
                                                                        >
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={item.screenName}
                                                                            id={item.screenName}
                                                                            onChange={this.handleCheckSingleLevelRole}
                                                                            checked={this.state.singleLevelRoleArr[item.screenName] === "Y"?true:false}
                                                                        />
                                                                        <label for={item.screenName}></label>
                                                                        </UserRoleCheckBoxLayout>
                                                                    </td>
                                                                </tr>
                                                            }
                                                        })
                                                    }
                                                    </table>
                                                </div>
                                            </div>
                                            <div className="common-close-save">
                                                <div className="common-close-save-col">
                                                    <button onClick={this.onCancelClick} type="button" className="common-close">Cancel</button>
                                                    <PageSaveButton 
                                                        themeSettings = {themeSettings}
                                                        onClick={this.saveUserRole} type="button" className="common-save">Save</PageSaveButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings = {themeSettings}
                        />
                    </section>
                </main>
            </Router>
        );
    }
}

export default AddUserRole;