import Item from "antd/lib/list/Item";
import { AlignmentType, Table, TableRow, TableCell, Paragraph, WidthType,
       BorderStyle, TextRun, VerticalAlign, ImageRun } from "docx";
import {
  isEmptyVariable,
  removeHtmlTags,
} from "../../../common/Global/commonFunctions";
let fontName = "Times New Roman";
let commonSize = 17;
let commonMargin = {
  top: 50,
  bottom: 50,
  left: 50,
};

let shrotNameArr = [
  {
    "fullName": "Administration",
    "shortName": "AD"
  },
  {
    "fullName": "Asphalt",
    "shortName": "ASP"
  },
  {
    "fullName": "BU Support Services",
    "shortName": "SS"
  },
  {
    "fullName": "Building",
    "shortName": "BUILD"
  },
  {
    "fullName": "Business Development - MI",
    "shortName": "BD_MI"
  },
  {
    "fullName": "Business Development - ST",
    "shortName": "BD_ST"
  },
  {
    "fullName": "Business Development - UH",
    "shortName": "BD_UH"
  },
  {
    "fullName": "Business Development - UI",
    "shortName": "BD_UI"
  },
  {
    "fullName": "Business Development - WS",
    "shortName": "BD_WS"
  },
  {
    "fullName": "Construction Plant and Equipment (CPE)",
    "shortName": "CP"
  },
  {
    "fullName": "CPE - SS",
    "shortName": "CP"
  },
  {
    "fullName": "Design",
    "shortName": "DE"
  },
  {
    "fullName": "Design -SS",
    "shortName": "DE"
  },
  {
    "fullName": "Document Control - OG",
    "shortName": "DC"
  },
  {
    "fullName": "Earthwork",
    "shortName": "ERW"
  },
  {
    "fullName": "Electrical & Instrumentation ",
    "shortName": "ELEINS"
  },
  {
    "fullName": "Engineering - OG",
    "shortName": "ENG_OG"
  },
  {
    "fullName": "Execution",
    "shortName": "SE"
  },
  {
    "fullName": "HR - SS",
    "shortName": "HR"
  },
  {
    "fullName": "ISO Cell - SS",
    "shortName": "ISOCELL"
  },
  {
    "fullName": "Library - SS",
    "shortName": "LIBRARY"
  },
  {
    "fullName": "Logistics - OG",
    "shortName": "LOG"
  },
  {
    "fullName": "Marine & Industrial",
    "shortName": "M&I"
  },
  {
    "fullName": "MIS",
    "shortName": "MI"
  },
  {
    "fullName": "MIS Cell - SS",
    "shortName": "MI"
  },
  {
    "fullName": "Oil & Gas",
    "shortName": "OG"
  },
  {
    "fullName": "Piping Works (Mechanical)",
    "shortName": "PW"
  },
  {
    "fullName": "Piping Works (Water Supply)",
    "shortName": "PW"
  },
  {
    "fullName": "Planning & Document Controller",
    "shortName": "PM"
  },
  {
    "fullName": "Planning - OG",
    "shortName": "PM_OG"
  },
  {
    "fullName": "PQC",
    "shortName": "PQC"
  },
  {
    "fullName": "Precast",
    "shortName": "PC"
  },
  {
    "fullName": "Procurement - OG",
    "shortName": "PRO"
  },
  {
    "fullName": "Project Planning & Co-ordination - MI",
    "shortName": "PPC_MI"
  },
  {
    "fullName": "Project Planning & Co-ordination - SP",
    "shortName": "PPC_SP"
  },
  {
    "fullName": "Project Planning & Co-ordination - UH",
    "shortName": "PPC_UH"
  },
  {
    "fullName": "Project Planning & Co-ordination - UI",
    "shortName": "PPC_UI"
  },
  {
    "fullName": "Project Planning & Co-ordination -WS",
    "shortName": "PPC_WS"
  },
  {
    "fullName": "Project Planning - Co-ordination - ST",
    "shortName": "PPC_ST"
  },
  {
    "fullName": "Projects",
    "shortName": "PROJECTS"
  },
  {
    "fullName": "Purchase - SS",
    "shortName": "PU"
  },
  {
    "fullName": "QAQC - OG",
    "shortName": "QAQC"
  },
  {
    "fullName": "QAQC / Execution (Fabrication)",
    "shortName": "FAB"
  },
  {
    "fullName": "QS_Subcontracts",
    "shortName": "QS_SC"
  },
  {
    "fullName": "Quality Excellence Cell (QxL)",
    "shortName": "QE"
  },
  {
    "fullName": "Special Projects",
    "shortName": "SP"
  },
  {
    "fullName": "Stores & Purchase",
    "shortName": "ST_PU"
  },
  {
    "fullName": "Structure",
    "shortName": "STR"
  },
  {
    "fullName": "Subcontracts - SS",
    "shortName": "SC"
  },
  {
    "fullName": "Surface Transport",
    "shortName": "ST"
  },
  {
    "fullName": "Survey",
    "shortName": "SR"
  },
  {
    "fullName": "Tendering & BD - OG",
    "shortName": "TDBD"
  },
  {
    "fullName": "Tendering - MI",
    "shortName": "TD_MI"
  },
  {
    "fullName": "Tendering - ST",
    "shortName": "TD_ST"
  },
  {
    "fullName": "Tendering - UH",
    "shortName": "TD_UH"
  },
  {
    "fullName": "Tendering - WS",
    "shortName": "TD_WS"
  },
  {
    "fullName": "Tendering -UI",
    "shortName": "TD_UI"
  },
  {
    "fullName": "Tunneling",
    "shortName": "TNL"
  },
  {
    "fullName": "Underground & Hydro",
    "shortName": "UGHY"
  },
  {
    "fullName": "Urban Infrastructure",
    "shortName": "UI"
  },
  {
    "fullName": "Water Segment",
    "shortName": "WS"
  },
  {
    "fullName": "Workshop",
    "shortName": "WS"
  }
]


export const getNonconformityReportPageContent = (
  assignmentDetails,
  clausesRefrence,
  qmsRefrence,
  requirement,
  ncrCount,
  companyLogo,isLogoAvailable,description,allAuditorList,functionName,functionId,finalEmployeeList
) => {

  let nonconformityReportContent = [];
  let unitName = String(assignmentDetails.unitAddressList).split(":-:")[0]+" ("+ JSON.stringify(assignmentDetails.unitNameCityList).split("(")[0].replace('"', "").trim()+") & "+functionName;
  let docRefNo = generatDocRefNo(qmsRefrence,clausesRefrence);
  allAuditorList = allAuditorList.filter(e=>e.level!=0).sort((a, b) => b.level - a.level);
  let auditorName = allAuditorList[0]["userName"];
  let blankSpace = 1200;
  let commonBorderStyle = { style: BorderStyle.SINGLE, color: "000000", size: 3, };
  let depShortName ="";
  let fromDate = !isEmptyVariable(assignmentDetails.startDate)?localDateExtractor(assignmentDetails.startDate):"";
  let toDate =!isEmptyVariable(assignmentDetails.endDate)?localDateExtractor(assignmentDetails.endDate):"";
  let auditNo = !isEmptyVariable(assignmentDetails.referenceNo)?assignmentDetails.referenceNo:""

  shrotNameArr.map((item)=>{
    if(item.fullName==functionName)
    {
      depShortName=item.shortName
    }
  })

  let tempEmployeeList =[];
  Object.values(finalEmployeeList).map((item)=>{
    if(item.functionId==functionId) {
      tempEmployeeList.push(item.employeeName)
    }
    // if(functionName.toLocaleLowerCase()=="qs_subcontracts")
    // {
    //   if((String(item.functionName).toLocaleLowerCase().trim()).includes(functionName.replace("_", " & ").toLocaleLowerCase().trim()) )
    //   {
    //     tempEmployeeList.push(item.employeeName)
    //   }
    // }
    // if(functionName.toLocaleLowerCase()=="construction plant and equipment (cpe)")
    // {
    //   if((String(item.functionName).trim())=="Construction Plants & Equipment (CPE)")
    //   {
    //     tempEmployeeList.push(item.employeeName)
    //   }
    // }
    // if(functionName.toLocaleLowerCase()=="quality excellence cell (qxl)")
    // {
    //   if((String(item.functionName).trim())=="Quality Excellance Cell (QxL)")
    //   {
    //     tempEmployeeList.push(item.employeeName)
    //   }
    // }
  })
  let ncrNo=`IA01/${JSON.stringify(assignmentDetails.unitNameCityList).split("(")[0].replace('"', "").trim()}/${depShortName}/${ncrCount}` 



  nonconformityReportContent.push(
    new Table({
      margins: {
        bottom: 50,
      },
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
      borders: {
        top: { style: BorderStyle.NONE},
        bottom: { style: BorderStyle.NONE},
        left: { style: BorderStyle.NONE},
        right: { style: BorderStyle.NONE},
        insideHorizontal: {
          style: BorderStyle.NONE,
        },
        insideVertical: { style: BorderStyle.NONE },
      },
      rows: [
        /// Logo, NCR, Date etc..
        new TableRow({
          children: [
            new TableCell({
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
                right: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    !isEmptyVariable(isLogoAvailable) &&
                new ImageRun({
                  data: companyLogo,
                  transformation: {
                    width: 100,
                    height: 30,
                  },
                }),
                  ],
                }),
              ],
            }),
            new TableCell({
              verticalAlign: VerticalAlign.CENTER,
              borders: {
                bottom: commonBorderStyle,
                right: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Nonconformity report (NCR)",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              margins: {
                top: 50,
                bottom: 50,
                left: 50,
              },
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  spacing: {
                    after: 50,
                  },
                  children: [
                    new TextRun({
                      text: "Doc No. ",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: 20,
                    }),
                    new TextRun({
                      text: "QF 9001 09",
                      font: fontName,
                      size: 20,
                    }),
                  ],
                }),
                new Paragraph({
                  spacing: {
                    after: 50,
                  },

                  children: [
                    new TextRun({
                      text: "Rev No. ",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: 20,
                    }),
                    new TextRun({
                      text: "07",
                      font: fontName,
                      size: 20,
                    }),
                  ],
                }),
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Date: ",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: 20,
                    }),
                    new TextRun({
                      text: "01/02/2022",
                      font: fontName,
                      size: 20,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// Section 1, reporting ...
        new TableRow({
          children: [
            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
                right: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Section 1",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              borders: {
                bottom: commonBorderStyle,
                right: commonBorderStyle,
              },
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 10,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "reporting nonconformity",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: {
                top: 50,
                bottom: 50,
                left: 50,
              },
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "to be filled by the audit / inspection / testing / checking team leader",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

         /// Site
         new TableRow({
          children: [
            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 5,
              children: [
                new Paragraph({
                  spacing:{
                    after:10
                  },
                  children: [
                    new TextRun({
                      text: "Site & Department: ",
                      font: fontName,
                      bold: true,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 15,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: unitName,
                      font: fontName,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 5,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Audit No: ",
                      font: fontName,
                      bold: true,
                      size: commonSize+3,
                    }),
                    new TextRun({
                      text: auditNo,
                      font: fontName,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 5,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "NCR No: ",
                      font: fontName,
                      bold: true,
                      size: commonSize+3,
                    }),
                    new TextRun({
                      text: ncrNo,
                      font: fontName,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
           /// non conformative description
           new TableRow({
            children: [
              new TableCell({
                margins: commonMargin,
                verticalAlign: AlignmentType.CENTER,
                borders: {
                  bottom: commonBorderStyle,
                },
                columnSpan: 20,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Nonconformity Description & Location Of work: (with objective evidence):",
                        font: fontName,
                        bold: true,
                        size: commonSize+3,
                      }),
                      new TextRun({
                        text: "",
                        font: fontName,
                        size: commonSize+3,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                margins: commonMargin,
                verticalAlign: AlignmentType.CENTER,
                borders: {
                  bottom: commonBorderStyle,
                },
                columnSpan: 10,
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "Period: ",
                        font: fontName,
                        bold: true,
                        size: commonSize+3,
                      }),
                      new TextRun({
                        text: fromDate+" - "+toDate,
                        font: fontName,
                        size: commonSize+3,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),

        /// Description
        new TableRow({
          children: [
            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              columnSpan: 30,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Description: ",
                      font: fontName,
                      bold: true,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        new TableRow({
          children: [
            new TableCell({
              margins: commonMargin,
              // borders: {
              //   bottom: commonBorderStyle,
              // },
              verticalAlign: AlignmentType.CENTER,
              columnSpan: 30,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: description,
                      font: fontName,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),


        // requirement
        new TableRow({
          children: [
            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              columnSpan: 30,
              children: [
                new Paragraph({
                  spacing:{
                    before:70
                  },
                  children: [
                    new TextRun({
                      text: "Requirement: ",
                      font: fontName,
                      bold: true,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 30,
              children: [
                new Paragraph({
                  spacing:{
                    after:100
                  },
                  children: [
                    new TextRun({
                      text: removeHtmlTags(requirement).replace(/&amp;/g, '&').replace(/&gt;/g, '>').replace(/&lt;/g, '<').replace(/&nbsp;/g, ''),
                      font: fontName,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// Document Ref No
        new TableRow({
          children: [
            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 30,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Document Reference No (Specific requirement): ",
                      font: fontName,
                      bold: true,
                      size: commonSize+3,
                    }),
                    new TextRun({
                      text: docRefNo,
                      font: fontName,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        ///  name of team leader,
        new TableRow({
          children: [
            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 5,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Name of Team Leader: ",
                      font: fontName,
                      bold: true,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 5,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: auditorName,
                      font: fontName,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              margins: {
                top: 50,
                bottom: 50,
              },
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 5,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Proposed completion Date: ",
                      bold: true,
                      font: fontName,
                      size: commonSize+3,
                    }),
                  ],
                }),
               
              ],
            }),
            new TableCell({
              margins: {
                top: 50,
                bottom: 50,
              },
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 5,
              children: [
               
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "",
                      font: fontName,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              margins: {
                top: 50,
                bottom: 50,
                left: 50,
              },
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 5,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Name of End User/ Process Owner: ",
                      bold: true,
                      font: fontName,
                      size: commonSize+3,
                    }),
                  ],
                }),
                
              ],
            }),
            new TableCell({
              margins: {
                top: 50,
                bottom: 50,
                left: 50,
              },
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 5,
              children: [
                
                new Paragraph({
                  spacing: {
                    after: 50,
                  },
                  children: [
                    new TextRun({
                      text: tempEmployeeList.join(", "),
                      font: fontName,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// Section 2-4 clouser of nonconformaty, etc...
        new TableRow({
          children: [
            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: {
                top: 50,
                bottom: 50,
              },
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
                right: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Section 2-4",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: {
                top: 50,
                bottom: 50,
              },
              borders: {
                bottom: commonBorderStyle,
                right: commonBorderStyle,
              },
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 10,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "closure of nonconformity",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: {
                top: 50,
                bottom: 50,
                left: 50,
              },
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "to be filled by end user / process owner",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// section 2, correction
        new TableRow({
          children: [
            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: {
                top: 50,
                bottom: 50,
              },
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
                right: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Section 2",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: {
                top: 50,
                bottom: 50,
              },
              borders: {
                bottom: commonBorderStyle,
                right: commonBorderStyle,
              },
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 10,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "correction",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: {
                top: 50,
                bottom: 50,
                left: 50,
              },
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "without approval of designated authority wherever required",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// space
        new TableRow({
          children: [
            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 30,
              children: [
                new Paragraph({
                  spacing: {
                    after: blankSpace,
                  },
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: " ",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// Type of correction check box
        new TableRow({
          children: [
            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 30,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "Type of Correction:",
                      bold: true,
                      font: fontName,
                      size: commonSize+3,
                    }),

                    new TextRun({
                      text: "\t\t☐ Accepted with deviation\t",
                      bold: true,
                      font: fontName,
                      size: commonSize+3,
                    }),
                    new TextRun({
                      text: "☐ Rework\t\t",
                      bold: true,
                      font: fontName,
                      size: commonSize+3,
                    }),

                    new TextRun({
                      text: "☐ Reject",
                      bold: true,
                      font: fontName,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
           
          ],
        }),

        /// section 3, Root Cause
        new TableRow({
          children: [
            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
                right: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Section 3",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: {
                top: 50,
                bottom: 50,
              },
              borders: {
                bottom: commonBorderStyle,
                right: commonBorderStyle,
              },
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 10,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Root Cause of Nonconformity",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: {
                top: 50,
                bottom: 50,
                left: 50,
              },
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  spacing: {
                    after: 20,
                  },
                  children: [
                    new TextRun({
                      text: "5Y, Fish Bone, Brain Storming",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// space
        new TableRow({
          children: [
            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 30,
              children: [
                new Paragraph({
                  spacing: {
                    after: blankSpace,
                  },
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: " ",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// section 4, Corrective Action
        new TableRow({
          children: [
            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
                right: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Section 4",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: {
                top: 50,
                bottom: 50,
              },
              borders: {
                bottom: commonBorderStyle,
                right: commonBorderStyle,
              },
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 10,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Corrective action",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: {
                top: 50,
                bottom: 50,
                left: 50,
              },
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  spacing: {
                    after: 20,
                  },
                  children: [
                    new TextRun({
                      text: "Should Eliminate Root Cause",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// space
        new TableRow({
          children: [
            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 30,
              children: [
                new Paragraph({
                  spacing: {
                    after: blankSpace,
                  },
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: " ",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// section 5, Verification of corrective
        new TableRow({
          children: [
            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
                right: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Section 5",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: {
                top: 50,
                bottom: 50,
              },
              borders: {
                bottom: commonBorderStyle,
                right: commonBorderStyle,
              },
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 10,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Verification of corrective action",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: {
                top: 50,
                bottom: 50,
                left: 50,
              },
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  spacing: {
                    after: 20,
                  },
                  children: [
                    new TextRun({
                      text: "to be done by hod / pm",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// signature, name, date
        new TableRow({
          children: [
            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  spacing:{
                    after:50,before:50
                  },
                  children: [
                    new TextRun({
                      text: "Signature: ",
                      bold: true,
                      font: fontName,
                     size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              margins: {
                top: 50,
                bottom: 50,
              },
              borders: {
                bottom: commonBorderStyle,
              },
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 10,
              children: [
                new Paragraph({
                  spacing:{
                    after:50,before:50
                  },
                  children: [
                    new TextRun({
                      text: "Name: ",

                      bold: true,
                      font: fontName,
                     size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              margins: {
                top: 50,
                bottom: 50,
                left: 50,
              },
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  spacing:{
                    after:50,before:50
                  },
                  children: [
                    new TextRun({
                      text: "Date:",
                      bold: true,
                      font: fontName,
                     size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// section 6, clause of nonconformity
        new TableRow({
          children: [
            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
                right: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Section 6",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: {
                top: 50,
                bottom: 50,
              },
              borders: {
                bottom: commonBorderStyle,
                right: commonBorderStyle,
              },
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 10,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "clouser of nonconformity",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              shading:{
                fill:"#eeece1"
              },
              margins: {
                top: 50,
                bottom: 50,
                left: 50,
              },
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  spacing: {
                    after: 20,
                  },
                  children: [
                    new TextRun({
                      text: "to be filled by the audit / inspection / testing / checking team leader",
                      allCaps: true,
                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// question A, yes / Not applicable
        new TableRow({
          children: [
            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
             
              columnSpan: 10,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "A. Is approval of designated authority is taken:",

                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              margins: {
                top: 50,
                bottom: 50,
              },
             
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 10,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "☐ Yes",

                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              margins: {
                top: 50,
                bottom: 50,
                left: 50,
              },
             
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 10,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "☐ No",
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// question B, yes / Not
        new TableRow({
          children: [
            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
             
              columnSpan: 10,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "B. Is Closure action accepted:",

                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              margins: {
                top: 50,
                bottom: 50,
              },
             
              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 10,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "☐ Yes",

                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              margins: {
                top: 50,
                bottom: 50,
                left: 50,
              },
             
              columnSpan: 10,
              children: [
                new Paragraph({
                  // alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "☐ No",

                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        /// question c, closed
        new TableRow({
          children: [
            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders: {
                bottom: commonBorderStyle,
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "C. This NCR is considered as:",

                      bold: true,
                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              margins: {
                top: 50,
                bottom: 50,
              },
              borders: {
                bottom: commonBorderStyle,
              },
              verticalAlign: AlignmentType.CENTER,
              columnSpan: 10,
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "   ☐ Closed",

                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              margins: {
                top: 50,
                bottom: 50,
              },
              borders: {
                bottom: commonBorderStyle,
              },
              verticalAlign: AlignmentType.CENTER,
              columnSpan: 10,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      text: "",

                      font: fontName,
                      size: commonSize,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),

        /// Signature, Name of TL, Date:
        new TableRow({
          children: [
            new TableCell({
              margins: commonMargin,
              verticalAlign: AlignmentType.CENTER,
              borders:{
                bottom:commonBorderStyle
              },
              columnSpan: 10,
              children: [
                new Paragraph({
                  spacing:{
                    after:50,before:50
                  },
                  children: [
                    new TextRun({
                      text: "Signature:",
                      bold: true,
                      font: fontName,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              
              margins: {
                top: 50,
                bottom: 50,
              },
              borders:{
                bottom:commonBorderStyle
              },

              verticalAlign: VerticalAlign.CENTER,
              columnSpan: 10,
              children: [
                new Paragraph({
                 
                  spacing:{
                    after:50,before:50
                  },
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Name of Team Lead:",
                      bold: true,
                      font: fontName,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),

            new TableCell({
              margins: {
                top: 50,
                bottom: 50,
                left: 50,
              },
              borders:{
                bottom:commonBorderStyle
              },

              columnSpan: 10,
              children: [
                new Paragraph({
                  spacing:{
                    after:50,before:50
                  },
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: "Date:",
                      bold: true,
                      font: fontName,
                      size: commonSize+3,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
      ],
    })
  );

  return [...nonconformityReportContent];
};

export const  generatDocRefNo = (qmsRefrence,clausesRefrence) => {
  if(Object.values(qmsRefrence).length>1)
  {
    if(Object.values(qmsRefrence)[0].includes("PR"))
    {
      return (Object.values(qmsRefrence).slice(1)).join(", ") + " of "+ Object.values(qmsRefrence)[0] + " & " + (Object.values(clausesRefrence)).join(", ") + " of ISO 9001:2015"
    }
    else {
      return (Object.values(clausesRefrence)).join(", ") + " of ISO 9001:2015"
    }
  }

  else{
    return (Object.values(qmsRefrence)).join(", ") + " of " + (Object.values(clausesRefrence)).join(", ") + " of ISO 9001:2015"
  }
}

export const localDateExtractor=(date)=>{
  var date = new Date(date);
  return date.toLocaleDateString('en-GB');
}