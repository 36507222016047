import React, { Component } from "react";
import { DialogSaveButton, FilterCheckBox } from "../../../common/Global/globalStyles.style";
import { isEmptyArray, isEmptyVariable } from "../../../common/Global/commonFunctions";
import * as Constants from '../../../common/Global/constants';


class TemplateFieldsFilter extends Component {
    render(){
        const isDisabled = ((!this.props.filterDataList.some(filter =>
            filter.dropdownValues.some(value => value.isSelected))) && 
            (!this.props.riskArr.some(item => item.isSelected)) &&
            (!this.props.weightArr.some(item => item.isSelected)) &&
            (!this.props.yesNoRankArr.some(item => item.isSelected)) &&
            (!this.props.rankingArr.some(item => item.isSelected)));
        return(
            <div className="overlay" style={{zIndex: this.props.screenName == Constants.SCREEN_EXECUTE ? 0 : 999}}>
                <div className={`popup-container ${this.props.showFilter ? 'show' : ''}`}>
                    <div>
                        <h5 className="small-h5">Filter</h5>
                    </div>
                    <div style={{marginTop:10}} className="d-flex flex-wrap content">
                    {!isEmptyArray(this.props.filterDataList) && this.props.filterDataList.map((item,index) => {
                        return(
                            <div className="d-flex flex-column mr-5 mb-1">
                            <h6 className="small-h6 border-top-bottom">{item.fieldName}</h6>
                            <div className="scrollable-container">
                                {item.dropdownValues.map((it,i) => {
                                    return(
                                        <FilterCheckBox themeSettings={this.props.themeSettings}
                                        style={{marginLeft:0, marginTop:10}}>
                                            <input 
                                                type="checkbox"
                                                name={index+":-:"+it.value}
                                                id={index+":-:"+it.value}
                                                onChange={this.props.handleCheck}
                                                checked={it.isSelected?true:false}
                                            />
                                            <label for={index+":-:"+it.value}>{isEmptyVariable(it.value) ? "Blank" : it.value}</label>
                                        </FilterCheckBox>
                                    )
                                })}
                                </div>
                            </div>
                        )
                    })}
                    {this.props.assignRisk === "Y" && 
                        <div className="d-flex flex-column mr-5 mb-1">
                            <h6 className="small-h6 border-top-bottom">Risk</h6>
                            <div className="scrollable-container">
                                {!isEmptyArray(this.props.riskArr) && this.props.riskArr.map((item,index) => {
                                    return(
                                        <FilterCheckBox themeSettings={this.props.themeSettings}
                                        style={{marginLeft:0, marginTop:10}}>
                                            <input
                                                type="checkbox"
                                                name={index+":-:"+item.riskLevelId}
                                                id={index+":-:"+item.riskLevelId}
                                                onChange={this.props.handleRiskCheck}
                                                checked={item.isSelected?true:false}
                                            />
                                            <label for={index+":-:"+item.riskLevelId}>{item.riskLevel}</label>
                                        </FilterCheckBox>
                                    )
                                })}
                                {isEmptyArray(this.props.riskArr) &&
                                    <div class="no-items-layout m-t-10">
                                        <div class="no-items-cards">
                                            <h6>No data to filter</h6>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    }
                    {!isEmptyArray(this.props.weightArr) &&
                        <div className="d-flex flex-column mr-5 mb-1">
                            <h6 className="small-h6 border-top-bottom">Weight</h6>
                            <div className="scrollable-container">
                                {!isEmptyArray(this.props.weightArr) && this.props.weightArr.map((item,index) => {
                                    return(
                                        <FilterCheckBox themeSettings={this.props.themeSettings}
                                        style={{marginLeft:0, marginTop:10}}>
                                            <input
                                                type="checkbox"
                                                name={index+":-:"+item.value}
                                                id={index+":-:"+item.value}
                                                onChange={this.props.handleWeightCheck}
                                                checked={item.isSelected?true:false}
                                            />
                                            <label for={index+":-:"+item.value}>{item.value}</label>
                                        </FilterCheckBox>
                                    )
                                })}
                            </div>
                        </div>
                    }
                    {!isEmptyArray(this.props.yesNoRankArr) &&
                        <div className="d-flex flex-column mr-5 mb-1">
                            <h6 className="small-h6 border-top-bottom">Rate</h6>
                            <div className="scrollable-container">
                                {!isEmptyArray(this.props.yesNoRankArr) && this.props.yesNoRankArr.map((item,index) => {
                                    return(
                                        <FilterCheckBox themeSettings={this.props.themeSettings}
                                        style={{marginLeft:0, marginTop:10}}>
                                            <input
                                                type="checkbox"
                                                name={index+":-:"+item.label}
                                                id={index+":-:"+item.label}
                                                onChange={this.props.handleYesNoRankArr}
                                                checked={item.isSelected?true:false}
                                            />
                                            <label for={index+":-:"+item.label}>{item.label}</label>
                                        </FilterCheckBox>
                                    )
                                })}
                            </div>
                        </div>
                    }
                    {!isEmptyArray(this.props.rankingArr) &&
                        <div className="d-flex flex-column mr-5 mb-1">
                            <h6 className="small-h6 border-top-bottom">Rate</h6>
                            <div className="scrollable-container">
                                {!isEmptyArray(this.props.rankingArr) && this.props.rankingArr.map((item,index) => {
                                    return(
                                        <FilterCheckBox themeSettings={this.props.themeSettings}
                                        style={{marginLeft:0, marginTop:10}}>
                                            <input
                                                type="checkbox"
                                                name={index+":-:"+item.id}
                                                id={index+":-:"+item.id}
                                                onChange={this.props.handleRankingArr}
                                                checked={item.isSelected?true:false}
                                            />
                                            <label for={index+":-:"+item.id}>{item.label}</label>
                                        </FilterCheckBox>
                                    )
                                })}
                            </div>
                        </div>
                    }
                    </div>
                    <div className="fixed-bottom mb-3 ml-3">
                        <button onClick={this.props.handleCancelFilter} type="button" className="modal-close m-b-0 mr-2">
                            Cancel
                        </button>
                        <DialogSaveButton
                            themeSettings={this.props.themeSettings}
                            type="button"
                            style={{cursor:isDisabled?"no-drop":"auto"}}
                            disabled={isDisabled}
                            onClick={this.props.handleResetFilter}
                            className="modal-save m-b-0 mr-2"
                        >
                            Reset
                        </DialogSaveButton>
                        <DialogSaveButton
                            themeSettings={this.props.themeSettings}
                            type="button"
                            style={{cursor:isDisabled?"no-drop":"auto"}}
                            disabled={isDisabled}
                            onClick={this.props.handleSaveFilter}
                            className="modal-save m-b-0"
                        >
                            Apply
                        </DialogSaveButton>
                    </div>
                </div>
            </div>
        )
    }
}
export default TemplateFieldsFilter;