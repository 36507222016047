import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import {ChangeProfilePicLayout} from '../../common/Global/globalStyles.style';
import AlertDialog from '../../common/AlertDialog';

const userDetails  = getLocalStorageVariables();
const unitTypePlaceholder = "Select Unit Type";
class AddClientAdminDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",
            companyLogo:"",
           
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showUploadLogoDialog){
            this.setState({
                companyLogo:this.props.companyLogo,
            })
                
        }
    }
    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }
    fileValidation(id){
        var fileInput = document.getElementById(id);
        var filePath = fileInput.value;

        var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;

        if(!allowedExtensions.exec(filePath)){
            fileInput.value = '';
            return false;
        }else{
            return true;
        }
    }
    
    /************************API CALLS **************************/

    uploadCompanyLogo = (e) => {
        e.preventDefault();

        this.setState({
            showLoader:true
        });
       
        var sizeInMB    =   (e.target.files[0].size / (1024*1024)).toFixed(2);
        if(!this.fileValidation("uploadCompanyLogoImage"))
        {
            this.setState(
                {
                    showLoader:false,
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:'Please upload file having extensions jpeg,jpg and png only.'
                }
            );
        }
        else if(sizeInMB > Constants.VALID_PROFILE_IMAGE_SIZE)
        {
            this.setState(
                {
                    showLoader:false,
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:'Please upload profile image size less than '+Constants.VALID_PROFILE_IMAGE_SIZE+' MB'
                }
            );
        }
        else
        {
            const formData = new FormData();
            formData.append('companyLogo',e.target.files[0]);
            formData.append('email',userDetails.email);
            formData.append('accessToken',userDetails.accessToken);
            formData.append('companyId',this.props.companyId);

            fetch(Constants.UpdateCompanyLogo,
            {
                method: "POST",
                mode: 'cors',
                body: formData
            })
            .then(response => { return response.json(); })
            .then(data => {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        companyLogo:data.data.companyLogo
                    });

                }else{
                    this.setState({
                        showLoader:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    });
                }
            });
        }
    }


    render() {
        return(
            <Modal className="add-company-dialog custom-dialog" 
            show={this.props.showUploadLogoDialog} backdrop="static">
                <Modal.Header>
                    <h5>Upload Company Logo</h5>
                    <button 
                        onClick={this.props.handleUploadLogoDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div className="modal-body">
                    {
                        this.state.showLoader &&
                        <div class="loader"></div>
                    }
                    <div className="addnew-modal-form">
                        <div className="row custom-row just-content">
                            {/* <div className="col-md-6 custom-col"> */}
                                <div className="img-layout ">
                                    <img src={isEmptyVariable(this.state.companyLogo)?"/assets/images/default-propic.png":Constants.ImageBaseUrl+this.state.companyLogo}/>
                                </div>
                            {/* </div> */}
                            
                        </div>
                     </div>
                    
                        <div className="close-save-col">
                            <ChangeProfilePicLayout themeSettings={this.props.themeSettings}>
                                <input name="file" 
                                    type="file"
                                    id="uploadCompanyLogoImage"
                                    onChange={this.uploadCompanyLogo}
                                    disabled={this.state.showLoader}
                                />
                                <label disabled={this.state.showLoader} for="uploadCompanyLogoImage">Upload Company Logo</label>
                            </ChangeProfilePicLayout>
                            
                        </div>
                    </div>
                    <AlertDialog 
                        showAlertDialog={this.state.showAlertDialogInfo}
                        handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                        type= {Constants.ALERT_TYPE_ALERT}
                        alertDialogMessage={this.state.alertDialogMessageInfo}
                        proceedBtnClick={this.handleAlertDialogCloseInfo}
                        proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                        themeSettings={this.props.themeSettings}
                    />
            </Modal>
        );
    }
}

export default AddClientAdminDialog;