import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout} from '../../common/Global/globalStyles.style';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import AlertDialog from '../../common/AlertDialog';
import TableDropDown from '../../common/DropdownMenus/TableDropdown';
import Pagination from '../../common/Global/pagination';
import AddUnitDialog from './addunitdialog';
import UnitDetailsDialog from './unitdetailsdialog';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import {getLocalStorageVariables,isEmptyVariable,sortTableTwoStates,
    isEmptyArray} from '../../common/Global/commonFunctions';
import { getUnitActionArray } from '../../common/Global/reviewFunctions';

const userDetails  = getLocalStorageVariables();

const editUnit = "Edit";
const deleteUnit = "Delete";
const reactivateUnit = "Reactivate";

const sortShortName = "unitShortName";
const sortUnitName = "unitName";
const sortUnitType = "unitType";
const sortCity = "u.city";
const sortState = "u.state";
const sortFunctionList = "functionList";

class ListUnits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyId:this.props.companyId,
            componentDidMountFlag:false,
            apiSearchKey:"",
            companySearchKey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:sortUnitType,
            sortDir:"asc",
            status:"Active",
            statusArr:[
                {
                    label:"Active",
                    isSelected:true
                },
                {
                    label:"Inactive",
                    isSelected:false
                }
            ],
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            unitTypeArr:[],
            companyInfo:"",
            functionsArr:"",
            
            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            alertDialogMessage:"",
            showBtnLoader:false,

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            //Add Unit
            showAddUnitDialog:false,
            isEdit:false,
            editUnitItem:"",

            //Add Employee
            showUnitDetailsDialog:false,
            selectedItem:""
        }
    }

    componentDidMount(){
        this.getInitData();
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === deleteUnit){
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.unitId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete unit "+dropdownItem.unitName+"?"
            })
        }else if(item.label === reactivateUnit){
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.unitId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate unit "+dropdownItem.unitName+"?"
            })
        }else if(item.label === editUnit){
            this.setState({
                showAddUnitDialog:true,
                isEdit:true,
                editUnitItem:dropdownItem
            })
        }
    }

    onAddUnitClick = () => {
        this.setState({
            showAddUnitDialog:true,
            isEdit:false,
            editUnitItem:""
        })
    }

    onUserCountClick = (selectedCompanyObj) => {
        this.setState({
            selectedCompanyId:selectedCompanyObj.clientId,
            showUsersListDialog:true,
            selectedClientName:selectedCompanyObj.companyName
        })
    }

    onCompanyNameClick = (selectedCompanyObj) => {
        this.setState({
            selectedCompanyId:selectedCompanyObj.clientId,
            showcompanyDetailsDialog:true,
            selectedClientName:selectedCompanyObj.companyName
        })
    }


    handleUnitDetailsDialogShow = (item) => {
        this.setState({
            showUnitDetailsDialog:true,
            selectedItem:item
        })
    }

    handleUnitDetailsDialogClose = () => {
        this.setState({
            showUnitDetailsDialog:false,
            selectedItem:""
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    onSearchIconClick = () => {
        this.getUnitsList();
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.getUnitsList();
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getUnitsList();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getUnitsList();
        })
    }

    handleAddUnitDialogClose = (reloadFlag) => {
        this.setState({
            selectedClientId:"",
            showAddUnitDialog:false,
            selectedClientName:""
        },()=>{
            if(reloadFlag === true){
                this.getUnitsList();
            }
        })
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTableTwoStates(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getUnitsList();
        })
    }
    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetUnitCreateMasterData,
            {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams(
                {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            }),
            fetch(Constants.GetUnits,
            {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams(
                {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.companySearchKey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    companyId:this.props.onlyParent?"":this.state.companyId
                })
            }),
        ])
        .then(([masterRes,unitsRes]) => {
            return Promise.all([masterRes.json(),unitsRes.json()]) 
        })
        .then(([masterRes,unitsRes]) => {
            let datalistTemp = [];
            let totalCountTemp = 0;

            let unitTypeArrTemp = [];
            let companyInfoTemp = "";
            let functionsArr = "";

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                unitTypeArrTemp = masterRes.data.unitType;
                companyInfoTemp = masterRes.data.company[0];
                functionsArr = masterRes.data.functions;

            }else{
                unitTypeArrTemp = [];
                companyInfoTemp = "";
                functionsArr = "";
            }

            if(unitsRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                unitsRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(unitsRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = unitsRes.data.result;
                totalCountTemp = unitsRes.data.count;
            }else{
                datalistTemp = [];
                totalCountTemp = 0;
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                unitTypeArr:unitTypeArrTemp,
                companyInfo:companyInfoTemp,
                functionsArr:functionsArr,
                datalist:datalistTemp,
                totalCount:totalCountTemp,
            })
        });
    }

    getUnitsList = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetUnits,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.companySearchKey,
                resultsize:this.state.resultSize,
                status:this.state.status,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
                companyId:this.props.onlyParent?"":this.state.companyId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.companySearchKey,
                })
            }else{
                this.setState({
                    datalist:[],
                    totalCount:0,
                    showLoader:false,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.companySearchKey,
                })
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });
            
            fetch(Constants.GetUnits,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.companySearchKey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    companyId:this.props.onlyParent?"":this.state.companyId
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        componentDidMountFlag:true,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.companySearchKey,
                        currentPageNo:page,
                    })
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        componentDidMountFlag:true,
                        apiSearchKey:this.state.companySearchKey,
                    })
                }
            });
        }
    }

    deleteActivateClient = () => {
        let url = "";

        if(this.state.operationType === deleteUnit){
            url = Constants.DeleteUnits;
        }else if(this.state.operationType === reactivateUnit){
            url = Constants.ReactivateUnits;
        }

        this.setState({
            showBtnLoader:true,
        });

        fetch(url,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                unitId:this.state.deleteReactivateId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showBtnLoader:false,
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                },() =>{
                    this.getUnitsList();
                })
            }else{
                this.setState({
                    showBtnLoader:false,
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,

                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                });
            }
        });
    }

    render() {
        return(
            <Router>
                <section>
                    {
                        this.state.showLoader &&
                        <div class="loader"></div>
                    }

                    {
                        // Need to check component did mount flag, other wise until api is called,
                        // it shows no items found layout.
                        this.state.componentDidMountFlag &&
                        <div className="body-wrapper">
                            <div className="search-addnew-bg">
                                <div className="search-addnew">
                                    <div className="search-filter-layout">
                                        <FilterDropDown
                                            placeholder={this.state.resultSizePlaceholder}
                                            dropdownArr={this.state.resultSizeArr}
                                            onDropDownItemClick={this.resultSizeDropdownClick}
                                            themeSettings={this.props.themeSettings}
                                            mleft={0}
                                        />
                                        <div className="search-col m-l-10">
                                            <input 
                                                name="companySearchKey" 
                                                type="text"
                                                onChange={this.handleChange}
                                                placeholder="Search" 
                                                onKeyPress={this.onEnterBtnPress}
                                                value={this.state.companySearchKey} />
                                            <SearchSpan themeSettings={this.props.themeSettings} onClick = {this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                                        </div>
                                        <FilterDropDown
                                            placeholder={this.state.statusPlaceholder}
                                            dropdownArr={this.state.statusArr}
                                            onDropDownItemClick={this.statusDropdownClick}
                                            themeSettings={this.props.themeSettings}
                                        />
                                        
                                    </div>
                                    <div className="search-addnew-shw-employees-btns">
                                        <AddNewButtonLayout themeSettings={this.props.themeSettings}>
                                            <a  href="javascript:void(0);" onClick={() => this.props.viewProjectsDropDownItemClick(this.state.companyId)}>
                                               <span className="material-icons">edit_document</span>
                                               <p>Show Projects</p>
                                            </a>
                                       </AddNewButtonLayout>
                                       <AddNewButtonLayout themeSettings={this.props.themeSettings} style={{marginLeft:15}}>
                                            <a  href="javascript:void(0);" onClick={this.props.viewEmployeesDropDownItemClick}>
                                               <span className="material-icons">group</span>
                                               <p>Show Employees</p>
                                            </a>
                                       </AddNewButtonLayout>
                                       <AddNewButtonLayout themeSettings={this.props.themeSettings} style={{marginLeft:15}}>
                                            <a  href="javascript:void(0);" onClick={this.onAddUnitClick}>
                                                <span className="material-icons">add_circle</span>
                                                <p>Add Unit</p>
                                            </a>
                                        </AddNewButtonLayout>
                                    </div>
                                </div>
                            </div>
                            {
                                !(isEmptyArray(this.state.datalist)) &&
                                <div className="common-tab-bg">
                                    <div className="common-table" style={{width:"100%"}}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                            <th className="text-center" width={"5%"}>No.</th>
                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortUnitType)}>
                                                <div className="sort-header">Unit Type
                                                    <span className={(this.state.sort === sortUnitType)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortShortName)}>
                                                <div className="sort-header">Short Name
                                                    <span className={(this.state.sort === sortShortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortUnitName)}>
                                                <div className="sort-header">Unit Name
                                                    <span className={(this.state.sort === sortUnitName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortCity)}>
                                                <div className="sort-header">City
                                                    <span className={(this.state.sort === sortCity)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortState)}>
                                                <div className="sort-header">State
                                                    <span className={(this.state.sort === sortState)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            {/* <th width={"35%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortFunctionList)}>
                                                <div className="sort-header">Functions
                                                    <span className={(this.state.sort === sortFunctionList)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th> */}
                                            <th className="text-center" width={"5%"}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                !isEmptyArray(this.state.datalist) && 
                                                this.state.datalist.map((item,idx) => {
                                                    var resFunctionArr = [];
                                                    if(!isEmptyVariable(item.functionList)){
                                                        var funcWithIdArr = item.functionList.split(",");

                                                        funcWithIdArr.map(funcItem => {
                                                            resFunctionArr.push(funcItem.split(":-:")[0])
                                                        })
                                                    }
                                                    let actionObj = getUnitActionArray(item.unitStatus, item.parentUnit, this.props.companyType);

                                                    return <tr onClick={this.handleUnitDetailsDialogShow.bind(this,item)}>
                                                        <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                        <td>{item.unitTypeName}</td>
                                                        <td>{item.unitShortName}</td>
                                                        <td>{item.unitName}</td>
                                                        <td>{item.city}</td>
                                                        <td>{item.state}</td>
                                                        {/* <td>{resFunctionArr.join().length > Constants.STRING_LENGTH?resFunctionArr.join().substring(0,Constants.STRING_LENGTH)+"...":resFunctionArr.join()} </td> */}

                                                        <td className="text-center">
                                                            {!isEmptyArray(actionObj.actionArr) &&
                                                                <TableDropDown 
                                                                    actionArr={actionObj.actionArr}
                                                                    onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                    dropDownId = {item}
                                                                    dropdownWidth = {actionObj.dropdownWidth}
                                                                    themeSettings={this.props.themeSettings}
                                                                />
                                                            }
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                
                                    </div>
                                </div>
                            }
                            {
                                this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && 
                                <div class="no-items-layout">
                                    <div class="no-items-card">
                                        <h6>
                                        {
                                            isEmptyVariable(this.state.apiSearchKey)?
                                            Constants.NO_RECORDS_WARNING
                                            :
                                            Constants.EMPTY_SEARCH_WARNING
                                        }
                                        </h6>
                                    </div>
                                </div>
                            }
                            <div className="pagination-layout">
                                <div className="row custom-row">
                                    <div className="col-md-9 custom-col">
                                    {
                                        (this.state.totalCount > this.state.datalist.length) && 
                                        <Pagination 
                                            totalLength ={this.state.totalCount} 
                                            items={this.state.datalist} 
                                            onChangePage={this.onChangePage} 
                                            pageSize={this.state.resultSize}
                                            currentPageNo = {this.state.currentPageNo}
                                            initialPage={this.state.currentPageNo} 
                                            themeSettings={this.props.themeSettings}/>
                                    }
                                    </div>
                                    <div className="col-md-3 custom-col">
                                        <p>{"Total Records: "+this.state.totalCount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <AlertDialog
                        showAlertDialog={this.state.showAlertDialog}
                        handleAlertDialogClose={this.handleAlertDialogClose}
                        type={
                            (this.state.operationType === deleteUnit)
                            ?
                            Constants.ALERT_TYPE_WARNING
                            :
                            Constants.ALERT_TYPE_INFO
                        }
                        alertDialogHeading={
                            (this.state.operationType === deleteUnit)
                            ?
                            "Delete Unit"
                            :
                            "Reactivate Unit"
                        }
                        alertDialogMessage={this.state.alertDialogMessage}
                        proceedBtnClick={this.deleteActivateClient}
                        proceedBtnLabel={
                            (this.state.operationType === deleteUnit)
                            ?
                            "Delete"
                            :
                            "Reactivate"
                        }
                        showLoader={this.state.showBtnLoader}
                        themeSettings={this.props.themeSettings}
                    />

                    <AlertDialog 
                        showAlertDialog={this.state.showAlertDialogInfo}
                        handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                        type= {Constants.ALERT_TYPE_ALERT}
                        alertDialogMessage={this.state.alertDialogMessageInfo}
                        proceedBtnClick={this.handleAlertDialogCloseInfo}
                        proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                        themeSettings={this.props.themeSettings}
                    />
                    <AddUnitDialog 
                        showAddUnitDialog = {this.state.showAddUnitDialog}
                        handleAddUnitDialogClose = {this.handleAddUnitDialogClose}
                        isEdit={this.state.isEdit}
                        editUnitItem = {this.state.editUnitItem}
                        unitTypeArr = {this.state.unitTypeArr}
                        companyId = {this.props.onlyParent?this.state.companyInfo?.companyId:this.state.companyId}
                        companyType={this.props.companyType}
                        functionsArr = {this.state.functionsArr}
                        themeSettings={this.props.themeSettings}
                    />

                    <UnitDetailsDialog
                        unitData = {this.state.selectedItem}
                        showUnitDetailsDialog = {this.state.showUnitDetailsDialog}
                        handleUnitDetailsDialogClose = {this.handleUnitDetailsDialogClose}
                    />
                </section>
            </Router>
        );
    }
}

export default ListUnits;