import React, { Component } from 'react'
import { matchPath, withRouter } from 'react-router-dom';
import * as Constants from '../../common/Global/constants';
import { isEmptyVariable, getLocalStorageVariables,getThemeSettingsVariables } from '../../common/Global/commonFunctions';
import { TopTabsLayout } from '../../common/Global/globalStyles.style';

let isClient ='';
let isDepartment ='';
let isAssignmentType ='';
let themeColor = ``;
class SettingTopMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader: false,
        }
        isClient = this.checkActivePath("/clientSettings");
        isDepartment = this.checkActivePath("/departmentsettings");
        isAssignmentType = this.checkActivePath("/assignmenttypesettings");
    }
    checkActivePath = (path) =>{
        const themeSettings = getThemeSettingsVariables();
        themeColor = `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`;
       return matchPath(this.props.history.location.pathname, { path });
    }
  render() {

    let width = 40;
    // if (!isEmptyVariable(this.props.masterMenus)) {
    //     width = 12.5 * Object.keys(this.props.masterMenus).length
    // }
    return (
        <TopTabsLayout themeSettings={this.props.themeSettings}>
        {this.state.showLoader && (
            <div class="loader"></div>
        )}
        <div className="question-tabs" style={{ marginRight: 0 }}>
            
                <ul style={{ width: width + "%" }}>
                  {(!isEmptyVariable(this.props.menus['Client Settings']) && this.props.menus['Client Settings'] !== "NONE")&& 
                       <li
                            className={
                                matchPath(this.props.history.location.pathname, { path: "/clientSettings" })
                                    ? "questiontab-active"
                                    : ""
                            }
                            >
                            <a href={Constants.URL_CLIENT_SETTINGS}
                                style={{color: !isClient ? themeColor : '', fontWeight: !isClient ? "bold" : "100px"}}>Client</a>
                        </li>
                    }
                       
                    {  (!isEmptyVariable(this.props.menus['Department Settings']) && this.props.menus['Department Settings'] !== "NONE") &&
                        <li
                            className={
                                matchPath(this.props.history.location.pathname, { path:"/departmentsettings"})
                                    ? "questiontab-active"
                                    : ""
                            }
                        >
                            <a href={Constants.URL_DEPARTMENT_SETTINGS}
                                style={{color: !isDepartment ? themeColor : '', fontWeight: !isDepartment ? "bold" : "100px"}}>Department</a>
                        </li>
                    }
                    <li
                        className={
                            matchPath(this.props.history.location.pathname, { path:"/assignmenttypesettings"})
                                ? "questiontab-active"
                                : ""
                        }
                    >
                        <a href={Constants.URL_ASSIGNMENT_TYPE_SETTINGS}
                            style={{color: !isAssignmentType ? themeColor : '', fontWeight: !isAssignmentType ? "bold" : "100px"}}>Assignment Type</a>
                    </li>
                </ul>
            
        </div>

    </TopTabsLayout>
    )
  }
}

export default withRouter(SettingTopMenu);
