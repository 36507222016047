import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,
    isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import {DialogSaveButton} from '../../../common/Global/globalStyles.style';
import {Modal} from 'react-bootstrap';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import AlertDialog from '../../../common/AlertDialog';
const userDetails  = getLocalStorageVariables();
const selectClientPlaceholderDefault = "Select Client";

class AssignToClientDialog extends Component{
    constructor(props){
        super(props);
        this.state = {
            clientList:[],
            selectedClients:[],
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
            // clientslistErr:""
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showAssignToClientDialog){
            this.getClientList();
        }
    }

    addSelection = (args) => {
        if(!isEmptyVariable(args.itemData.companyId))
        {
            this.state.selectedClients.push(args.itemData.companyId)
        }
        this.setState({
            selectedClients:this.state.selectedClients,
            // clientslistErr:""
        })

        console.log(this.state.selectedClients);
    }

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
    }

    removeSelection = (args) => {
        if(!isEmptyVariable(args.itemData.companyId)){
            let idx = this.state.selectedClients.indexOf(args.itemData.companyId);
            if(idx !== -1){
                this.state.selectedClients.splice(idx,1);
            }
        }
        this.setState({
            selectedClients:this.state.selectedClients
        })
    }

    getClientList = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetAssignRiskMasterData,
        {
            method:"POST",
            mode:"cors",
            body: new URLSearchParams(
            {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                riskId:this.props.riskId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let assingedArr = data.data.assignedCompanies;
                let selectedClients = [];

                assingedArr.map(item=>{
                    selectedClients.push(parseInt(item.id))
                })

                this.setState({
                    showLoader:false,
                    clientList:data.data.clients,
                    selectedClients:selectedClients
                })
            }else{
                this.setState({
                    showLoader:false,
                    clientList:[]
                })
            }
        });
    }

    handleAssignToClientApi = () => {
        this.setState({
            showLoader:true,
        });

        let isError = false;
        // let clientslistErr = "";

        if(isEmptyArray(this.state.selectedClients)){
            isError = true;
            // clientslistErr = "Please select atleast one client"
        }

        if(isError){
            this.setState({
                showLoader:false,
                showAlertDialogInfo:true,
                alertDialogMessageInfo:"Please select atleast one client"
            })
        }else{
            fetch(Constants.AssingRiskToClient,
            {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams(
                {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    riskId:this.props.riskId,
                    companyIds:JSON.stringify(this.state.selectedClients)
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        clientList:[],
                        selectedClients:[],
                        // clientslistErr:""
                    },()=>{
                        this.props.handleAssignToClientDialogClose(true);
                    })
                }else{
                    this.setState({
                        showLoader:false,
                        clientList:[],
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    })
                }
            });

        }
    }

    render(){
        let fields = {text:"companyShortName",value:"companyId"}
        return(
            <>
            <Modal enforceFocus={false} className="update-review-status-dialog custom-dialog" 
            show={this.props.showAssignToClientDialog} backdrop="static">
                <Modal.Header>
                    <h5>Assign To Client</h5>
                    <button 
                        onClick={this.props.handleAssignToClientDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div className="modal-body">
                    {
                        this.state.showLoader &&
                        <div class="loader"></div>
                    }
                    <div className="row custom-row">
                        <div className="col-md-12 custom-col">
                            <div className="addnew-modal-form-group">
                                <p>Clients*</p>
                                <MultiSelectComponent 
                                    id="clientsCheckbox"
                                    dataSource={this.state.clientList}
                                    fields={fields}
                                    placeholder={selectClientPlaceholderDefault} 
                                    mode="CheckBox"
                                    closePopupOnSelect={false}
                                    allowFiltering={false}
                                    select={this.addSelection}
                                    removed={this.removeSelection}
                                    cssClass="clientsChecklist"
                                    value={this.state.selectedClients}
                                    showDropDownIcon={true}
                                    showClearButton={false}
                                >
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                                {/* {
                                    !isEmptyVariable(this.state.clientslistErr) &&
                                    <span class="cm-error">{this.state.clientslistErr}</span>
                                } */}
                            </div>
                        </div>
                    </div>
                    <div class="modal-close-save">
                        <div class="close-save-col">
                            <button onClick={this.props.handleAssignToClientDialogClose} type="button" class="modal-close">Cancel</button>
                            <DialogSaveButton 
                            themeSettings={this.props.themeSettings}
                            onClick={this.handleAssignToClientApi} type="button">Save</DialogSaveButton>
                        </div>
                    </div>
                </div>
            </Modal>
            <AlertDialog
                showAlertDialog={this.state.showAlertDialogInfo}
                handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                type={Constants.ALERT_TYPE_ALERT}
                alertDialogMessage={this.state.alertDialogMessageInfo}
                proceedBtnClick={this.handleAlertDialogCloseInfo}
                proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                themeSettings={this.props.themeSettings}
            />
            </>
        )
    }
}

export default AssignToClientDialog;