import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout,InfoCardSpan} from '../../common/Global/globalStyles.style';
import {getLocalStorageVariables,ifEmptyReturnEmptyStr,ifEmptyReturn,sortTable,isEmptyVariable,
    isEmptyArray} from '../../common/Global/commonFunctions';
import Pagination from '../../common/Global/pagination';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import AnimateHeight from 'react-animate-height';
import AddClientAdminDialog from './addclientadmin';

let userDetails  = getLocalStorageVariables();

const sortDisplayRoleName = "r1.displayRoleName";
const sortFirstName = "firstName";
const sortEmail = "email";
const sortStartDate = "createDate";
const sortEndDate = "inactiveDate";

class ClientUsersDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            status:"Active",
            statusPlaceholder:"Active status",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"",
            infoCard:"",

            statusArr:[
                {
                    label:"Active",
                    isSelected:true
                },
                {
                    label:"Inactive",
                    isSelected:false
                }
            ],
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,

            showAddClientAdminDialog:false,
        }
    }

    componentDidMount(){
        this.getClientUsersList();
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getClientUsersList();
            })
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label + " status";
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getClientUsersList();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getClientUsersList();
        })
    }

    handleAddClientAdminDialogClose = (reloadFlag) => {
        this.setState({
            showAddClientAdminDialog:false,
        },()=>{
            if(reloadFlag === true){
                this.getClientUsersList();
            }
        })
    }

    handleAddClientAdminDialogShow = () => {
        this.setState({
            showAddClientAdminDialog:true,
        })
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getClientUsersList();
        })
    }

    getClientUsersList = () => {
        userDetails  = getLocalStorageVariables();
        this.setState({
            showLoader:true
        })

        fetch(Constants.GetClientUsers,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                resultsize:this.state.resultSize,
                status:this.state.status,
                search:this.state.searchkey,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
                clientId:this.props.clientId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    componentDidMountFlag:true,
                    infoCard:data.data.infoCard,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    infoCard:"",
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        userDetails  = getLocalStorageVariables();
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            fetch(Constants.GetClientUsers,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    search:this.state.searchkey,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    clientId:this.props.clientId
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        currentPageNo:page,
                        apiSearchKey:this.state.searchkey,
                    });
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    render() {
        return(
            <section>
            {
                this.state.showLoader &&
                <div class="loader"></div>
            }

            {
                this.state.componentDidMountFlag &&
                <AnimateHeight duration={ 300 } height={ this.props.displayCards?"auto":0 } >
                <div className="master-card-bg">
                    <div className="master-card-col">
                        <InfoCardSpan themeSettings={this.props.themeSettings} className="material-icons">supervised_user_circle</InfoCardSpan>
                        <div className="mcc-text">
                        <h5>{ifEmptyReturnEmptyStr(this.state.infoCard.maxUsersCountThisMonth,"0")}</h5>
                        <p>Current Month Active Users</p>
                        </div>
                    </div>
                    <div className="master-card-col">
                        <InfoCardSpan themeSettings={this.props.themeSettings} className="material-icons">people</InfoCardSpan>
                        <div className="mcc-text">
                        <h5>{ifEmptyReturnEmptyStr(this.state.infoCard.maxUsersCountLastMonth,"0")}</h5>
                        <p>Prev Month Active Users</p>
                        </div>
                    </div>
                    <div className="master-card-col">
                        <InfoCardSpan themeSettings={this.props.themeSettings} className="material-icons">people</InfoCardSpan>
                        <div className="mcc-text">
                        <h5>{ifEmptyReturnEmptyStr(this.state.infoCard.maxUsersCountLastLastMonth,"0")}</h5>
                        <p>Prev Month Active Users</p>
                        </div>
                    </div>
                </div>
                </AnimateHeight>
            }

            {
                // Need to check component did mount flag, other wise until api is called,
                // it shows no items found layout.
                this.state.componentDidMountFlag &&
                <div className="body-wrapper">
                    <div className="search-addnew-bg">
                        <div className="search-addnew">
                            <div className="search-filter-layout">
                                <FilterDropDown
                                    placeholder={this.state.resultSizePlaceholder}
                                    dropdownArr={this.state.resultSizeArr}
                                    onDropDownItemClick={this.resultSizeDropdownClick}
                                    themeSettings={this.props.themeSettings}
                                    mleft={0}
                                />
                                <div className="search-col  m-l-10">
                                    <input 
                                        name="searchkey" 
                                        type="text"
                                        onChange={this.handleChange}
                                        placeholder="Search" 
                                        onKeyPress={this.onEnterBtnPress}
                                        value={this.state.searchkey} />
                                    <SearchSpan 
                                    themeSettings={this.props.themeSettings}
                                    onClick = {this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                                </div>
                                <FilterDropDown
                                    placeholder={this.state.statusPlaceholder}
                                    dropdownArr={this.state.statusArr}
                                    onDropDownItemClick={this.statusDropdownClick}
                                    themeSettings={this.props.themeSettings}
                                />
                            </div>
                            <AddNewButtonLayout 
                            themeSettings={this.props.themeSettings}>
                                <a href="javascript:void(0);" onClick={this.handleAddClientAdminDialogShow}>
                                    <span className="material-icons">add_circle</span>
                                    <p>Add Client Admin</p>
                                </a>
                            </AddNewButtonLayout>
                        </div>
                    </div>

                    {
                        !isEmptyArray(this.state.datalist) && 
                        <div className="common-tab-bg">
                            <div className="common-table table-bb">
                                <table className="table">
                                    <thead style={{background:"#f5f5f5"}}>
                                        <tr>
                                            <th className="text-center" width={"5%"}>No</th>
                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortFirstName)}>
                                                <div className="sort-header">Name
                                                    <span className={(this.state.sort === sortFirstName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDisplayRoleName)}>
                                                <div className="sort-header">Type
                                                    <span className={(this.state.sort === sortDisplayRoleName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortEmail)}>
                                                <div className="sort-header">Email
                                                    <span className={(this.state.sort === sortEmail)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortStartDate)}>
                                                <div className="sort-header">Create Date
                                                    <span className={(this.state.sort === sortStartDate)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortEndDate)}>
                                                <div className="sort-header">End Date
                                                    <span className={(this.state.sort === sortEndDate)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        !isEmptyArray(this.state.datalist) && 
                                        this.state.datalist.map((item,idx) => {
                                            return <tr>
                                                <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                <td>
                                                {ifEmptyReturn(item.salutation)+" "+item.firstName+" "+
                                                ifEmptyReturn(item.middleName)+" "+item.lastName}
                                                </td>
                                                <td>{item.displayRoleName}</td>
                                                <td>{item.email}</td>
                                                <td>{item.startDate}</td>
                                                <td>{ifEmptyReturnEmptyStr(item.endDate,"-")}</td>
                                            </tr>
                                        })
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {
                        this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                        <div class="no-items-layout">
                            <div class="no-items-card">
                                <h6>
                                {
                                    isEmptyVariable(this.state.apiSearchKey)?
                                    Constants.NO_RECORDS_WARNING
                                    :
                                    Constants.EMPTY_SEARCH_WARNING
                                }
                                </h6>
                            </div>
                        </div>
                    }
                    <div className="pagination-layout">
                        <div className="row custom-row">
                            <div className="col-md-9 custom-col">
                            {
                                (this.state.totalCount > this.state.datalist.length) && 
                                <Pagination 
                                    totalLength ={this.state.totalCount} 
                                    items={this.state.datalist} 
                                    onChangePage={this.onChangePage} 
                                    pageSize={this.state.resultSize}
                                    currentPageNo = {this.state.currentPageNo}
                                    initialPage={this.state.currentPageNo} 
                                    themeSettings={this.props.themeSettings}/>
                            }
                            </div>
                            <div className="col-md-3 custom-col">
                                <p>{"Total Records: "+this.state.totalCount}</p>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <AddClientAdminDialog 
                clientId = {this.props.clientId}
                showAddClientAdminDialog = {this.state.showAddClientAdminDialog}
                handleAddClientAdminDialogClose = {this.handleAddClientAdminDialogClose}
                selectedClientName = {this.props.clientName}
                themeSettings={this.props.themeSettings}
            />
            </section>
        );
    }
}

export default ClientUsersDialog;