import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import {
    isEmptyVariable,

    getThemeSettingsVariables,
    isEmptyArray,
} from "../../../common/Global/commonFunctions";
import {
    TableRadioButton,
    DialogSaveButton,
} from "../../../common/Global/globalStyles.style";
import { Modal } from "react-bootstrap";
import moment from "moment";

const themeSettings = getThemeSettingsVariables();
class SelectDropdownValues extends Component {
    constructor(props) {
        super(props);

        this.state = {
            datalist: [],
            selectedAssignmentID: "",
            selctedItem: {},
        };
    }

    componentDidMount() {
        this.setState({
            datalist: Object.values(this.state.datalist),
            selectedAssignmentID: this.props.selectedPlannedAssignmentId
        });
    }

    componentDidUpdate(prevProps) {
        if (
            JSON.stringify(prevProps) !== JSON.stringify(this.props) &&
            this.props.selectedScheduleAssignment
        ) {
            this.setState({
                datalist: Object.values(this.state.datalist),
                selectedAssignmentID: this.props.selectedPlannedAssignmentId
            });
        }

    }

    handleSelectionRadio = (item, e) => {
        let checkBoxIndex = e.target.name;
        this.setState({
            selectedAssignmentID: checkBoxIndex,
            selctedItem: item,
        });
    };

    returnSelectedValues = () => {
        if (this.state.selectedAssignmentID !== "") {
            this.props.onSaveButtonClick(this.state.selctedItem);
        }
    };

    render() {
        return (
            <Modal
                className="assignment-select-dialog custom-dialog"
                show={this.props.selectedScheduleAssignment}
                backdrop="static"
            >
                <Modal.Header>
                    <h5>Select Assignment</h5>
                    <button
                        onClick={this.props.onCancleButtonClick}
                        type="button"
                        data-dismiss="modal"
                    >
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div className="modal-body">
                    <div className="body-wrapper">
                        {
                            // !isEmptyArray(this.state.datalist) &&
                            <div className="common-tab-bg">
                                <div
                                    className="common-table"
                                    style={{ border: "1px solid #e5e5e5" }}
                                >
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th style={{width:"8%"}}>Sr No.</th>
                                                {this.props.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF ? <th>Unit</th> : <th>Company Name</th>}
                                                <th>Name</th>
                                                <th>City</th>
                                                <th style={{width:"16%"}}>Assignment Type</th>
                                                <th style={{width:"12%"}}>Plan Date</th>
                                                <th>Scope</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                // !isEmptyArray(this.state.dropdownValues) &&
                                                Object.values(this.props.datalist).map((item, idx) => {
                                                    let tempScop = [];
                                                    if(!isEmptyVariable(item.functionList)){
                                                        tempScop = item.functionList?.split(",").map((func) => func.split(":-:")[0]);
                                                    }else if(!isEmptyVariable(item.processList)){
                                                        tempScop = item.processList.split(",").map((func) => func.split(":-:")[0]);
                                                    }else if(!isEmptyVariable(item.checkList)){
                                                        tempScop = item.checkList.split(",").map((func) => func.split(":-:")[0]);
                                                    }	
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <TableRadioButton themeSettings={themeSettings}>
                                                                    <input
                                                                        type="checkbox"
                                                                        name={item.assignmentId}
                                                                        id={item.assignmentId}
                                                                        onChange={this.handleSelectionRadio.bind(
                                                                            this,
                                                                            item
                                                                        )}
                                                                        checked={
                                                                            item.assignmentId ==
                                                                            this.state.selectedAssignmentID
                                                                        }
                                                                    // disabled={this.props.viewOnly === "Y"?true:false}
                                                                    />

                                                                    <label
                                                                        for={item.assignmentId}
                                                                        style={{ marginLeft: 2 }}
                                                                    ></label>
                                                                </TableRadioButton>
                                                            </td>
                                                            <td className="text-center">{idx + 1}</td>
                                                            {
                                                                this.props.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF ? 
                                                                <td>
                                                                {
                                                                    isEmptyVariable(item.unitList)
                                                                    ? "-"
                                                                    : String(item.unitList).split(":-:")[0]
                                                                }
                                                                </td> 
                                                                : 
                                                                <td>
                                                                    {
                                                                        isEmptyVariable(item.companyName)
                                                                        ? "-"
                                                                        : item.companyName
                                                                    }
                                                                </td>}
                                                            <td>
                                                                {
                                                                    isEmptyVariable(item.unitAddressList)
                                                                    ? "-"
                                                                    : String(item.unitAddressList).split(":-:")[0]
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    isEmptyVariable(item.unitCityList)
                                                                    ? "-"
                                                                    : item.unitCityList
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    isEmptyVariable(item.assignmentTypeShortName)
                                                                    ? "-"
                                                                    : item.assignmentTypeShortName
                                                                }
                                                            </td>
                                                            <td>{moment(item.planDate).format('MMM - YYYY')}</td>
                                                            <td>{item.assignmentArea == "CheckList" ?
															    isEmptyArray(tempScop)?"-":(`CheckList: ` + tempScop[0]) :
															    isEmptyArray(tempScop)?"-":tempScop.length==1?tempScop[0]:`${tempScop[0]} + ${tempScop.length-1}`}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        <div className="modal-close-save m-t-15">
                            <div className="close-save-col">
                                <button
                                    onClick={this.props.onCancleButtonClick}
                                    type="button"
                                    className="modal-close m-b-0"
                                >
                                    Cancel
                                </button>

                                {
                                    // this.props.assignmentTaskAllowEdit !== "N" &&
                                    <DialogSaveButton
                                        themeSettings={themeSettings}
                                        onClick={this.returnSelectedValues}
                                        type="button"
                                        className="modal-save m-b-0"
                                        disabled={
                                            this.state.selectedAssignmentID !== "" ? false : true
                                        }
                                    >
                                        Save
                                    </DialogSaveButton>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default SelectDropdownValues;
