import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getThemeSettingsVariables, isEmptyVariable } from '../../common/Global/commonFunctions';
import {TopTabsLayout} from '../../common/Global/globalStyles.style';


let themeColor = ``;
const themeSettings = getThemeSettingsVariables();
class CompaniesTopMenu extends Component{
    constructor(props) {
        super(props);
        themeColor = `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`;
    }

    // This function to handle click events on Clients tab
    companiesOnClick = () => {
        this.props.companyTabItemOnClick();
    }

    // This function to handle click events on Units tab
    unitsOnClick = () => {
        this.props.unitTabItemOnClick(this.props.companyId)
    }

    // This function to handle click events on Employees tab
    employeesOnClick = () => {
        this.props.employeesTabItemOnClick(this.props.unitId)
    }

    // This function to handle click events on Project tab
    projectOnClick = () => {
        this.props.projectTabItemOnClick();
    }

    // This function to handle click events on Grouping tab
    groupingOnClick = () =>{
        this.props.gropingTabItemOnClick();
    }

    // This function to handle click events on City tab
    cityOnClick = () =>{
        this.props.cityTabItemOnClick();
    }

    // This function handle click events on State/Province tab
    stateOnClick = () => {
        this.props.stateTabItemOnClick();
    }
    // This function handle click events on Country tab
    countryOnClick = () => {
        this.props.countryTabItemOnClick();
    }

    render(){
        const {companyId,showUnit,unitId,displayingCompanies,displayingUnits,
            showEmployee, displayingEmployees,showProject,displayingProjects,displayingGrouping,
            displayingCities,displayingStates,displayingCountries,showCityMaster} = this.props;

        let width = showProject?"61.5%":"51%";
        if(!isEmptyVariable(companyId) && !isEmptyVariable(unitId) && showUnit){
            width = showProject?"81.5%":"71%";
        }else if(!isEmptyVariable(companyId) && showUnit){
            width = showProject?"69%":"59.5%";
        }
        
        return(
            <TopTabsLayout
            themeSettings={this.props.themeSettings}
            >
            
                <div className="question-tabs"  style={{marginRight:0}}>
                    <ul style={{width:width}}>

                    {/* By default Clients must be displayed. if users tab is there then we need to
                    display both the tabls */}
                    <li className={displayingCompanies?"questiontab-active":""}>
                        <a onClick={this.companiesOnClick} href="javascript:void(0)"
                        style={{color: !displayingCompanies ? themeColor : '', fontWeight: !displayingCompanies ? "bold" : "100px"}}>Clients</a>
                    </li>
                    {
                        !isEmptyVariable(companyId) && showUnit &&
                        <li className={displayingUnits?"questiontab-active":""}>
                            <a onClick={this.unitsOnClick} href="javascript:void(0)" 
                            style={{color: !displayingUnits ? themeColor : '', fontWeight: !displayingUnits ? "bold" : "100px"}}>Units</a>
                        </li>
                    }
                    {
                        !isEmptyVariable(unitId) && showEmployee &&
                        <li className={displayingEmployees?"questiontab-active":""}>
                            <a onClick={this.employeesOnClick} href="javascript:void(0)" 
                            style={{color: !displayingEmployees ? themeColor : '', fontWeight: !displayingEmployees ? "bold" : "100px"}}>Employees</a>
                        </li>
                    }
                    {
                        !isEmptyVariable(companyId) && showProject && 
                        <li className={displayingProjects?"questiontab-active":""}>
                            <a onClick={this.projectOnClick} href="javascript:void(0)" 
                            style={{color: !displayingProjects ? themeColor : '', fontWeight: !displayingProjects ? "bold" : "100px"}}>Projects</a>
                        </li>
                    }
                    <li className={displayingGrouping?"questiontab-active":""}>
                        <a onClick={this.groupingOnClick} href="javascript:void(0)"
                        style={{color: !displayingGrouping ? themeColor : '', fontWeight: !displayingGrouping ? "bold" : "100px"}}>Grouping</a>
                    </li>
                    {
                        showCityMaster &&
                        <>
                            <li className={displayingCities?"questiontab-active":""}>
                                <a onClick={this.cityOnClick} href="javascript:void(0)"
                                style={{color: !displayingCities ? themeColor : '', fontWeight: !displayingCities ? "bold" : "100px"}}>City</a>
                            </li>
                            <li className={displayingStates?"questiontab-active":""}>
                                <a onClick={this.stateOnClick} href="javascript:void(0)"
                                style={{color: !displayingStates ? themeColor : '', fontWeight: !displayingStates ? "bold" : "100px"}}>State/Province</a>
                            </li>
                            <li className={displayingCountries?"questiontab-active":""}>
                                <a onClick={this.countryOnClick} href="javascript:void(0)"
                                style={{color: !displayingCountries ? themeColor : '', fontWeight: !displayingCountries ? "bold" : "100px"}}>Country</a>
                            </li>
                        </>
                    }
                    </ul>
                </div>

                <div onClick={this.props.toggleCardsDisplay}
                style={{opacity:this.props.hideInfo?"0":"1"}} className="infocard-showhide-col">
                    {/* <div className="infocard-icon-col">
                        <img src="/assets/images/topbar-info.svg" />
                    </div> */}
                </div>
            </TopTabsLayout>
        )
    }
}

export default withRouter(CompaniesTopMenu);