import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout} from '../../common/Global/globalStyles.style';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import AlertDialog from '../../common/AlertDialog';
import TableDropDown from '../../common/DropdownMenus/TableDropdown';
import Pagination from '../../common/Global/pagination';
import ProjectDetailsDialog from './projectdetailsdialog';
import { BrowserRouter as Router,} from 'react-router-dom';
import {getLocalStorageVariables,isEmptyVariable,sortTableTwoStates, isEmptyArray} from '../../common/Global/commonFunctions';
import AddProjectDialog from './addprojectdialog';
import moment from 'moment';

const userDetails  = getLocalStorageVariables();

const editProject = "Edit";
const deleteProject = "Delete";
const reactivateProject = "Reactivate";

const sortProjectName = "projectName";
const sortProjectCode = "projectCode";
const sortDescription = "description";
const sortStartDate = "startDate";
const sortEndDate = "endDate";

class ListProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyId:this.props.companyId,
            componentDidMountFlag:false,
            apiSearchKey:"",
            projectSearchKey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"",
            status:"Active",
            statusArr:[
                {
                    label:"Active",
                    isSelected:true
                },
                {
                    label:"Inactive",
                    isSelected:false
                }
            ],
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            unitArr:[],
            employeesArr:"",
            
            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            alertDialogMessage:"",
            showBtnLoader:false,

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            //Add Project
            showAddProjectDialog:false,
            isEdit:false,
            editProjectItem:"",

            showProjectDetailsDialog:false,
            selectedItem:""
        }
    }

    componentDidMount(){
        this.getInitData();
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === deleteProject){
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.projectId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete project "+dropdownItem.projectName+"?"
            })
        }else if(item.label === reactivateProject){
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.projectId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate project "+dropdownItem.projectName+"?"
            })
        }else if(item.label === editProject){
            this.setState({
                showAddProjectDialog:true,
                isEdit:true,
                editProjectItem:dropdownItem
            })
        }
    }

    onAddProjectClick = () => {
        this.setState({
            showAddProjectDialog:true,
            isEdit:false
        })
    }

    handleAddProjectDialogClose = (reloadFlag) => {
        this.setState({
            showAddProjectDialog:false
        },()=>{
            if(reloadFlag === true){
                this.getProjectList();
            }
        })
    }

    handleProjectDetailsDialogShow = (item) => {
        this.setState({
            showProjectDetailsDialog:true,
            selectedItem:item
        })
    }

    handleProjectDetailsDialogClose = () => {
        this.setState({
            showProjectDetailsDialog:false,
            selectedItem:""
        })
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    onSearchIconClick = () => {
        this.getProjectList();
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.getProjectList();
        }
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTableTwoStates(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getProjectList();
        })
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getProjectList();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getProjectList();
        })
    }

    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetProjectCreateMasterData,
            {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams(
                {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            }),
            fetch(Constants.GetProjects,
            {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams(
                {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.projectSearchKey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    companyId:this.state.companyId
                })
            }),
        ])
        .then(([masterRes,projectRes]) => {
            return Promise.all([masterRes.json(),projectRes.json()]) 
        })
        .then(([masterRes,projectRes]) => {
            let datalistTemp = [];
            let totalCountTemp = 0;
            let unitsTemp = [];
            let employeesTemp = [];
            let employees = [];

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                unitsTemp = masterRes.data.units;
                employeesTemp = masterRes.data.employees;
            }else{
                unitsTemp = [];
                employeesTemp = [];
            }

            employees = employeesTemp.map(item => {
                item.employeeName = item.salutation+" "+item.firstName+" "+item.lastName+" ("+item.designation+")";
                return item;
            })

            if(projectRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                projectRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(projectRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = projectRes.data.projectList;
                totalCountTemp = projectRes.data.count;
            }else{
                datalistTemp = [];
                totalCountTemp = 0;
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                unitArr:unitsTemp,
                employeesArr:employees,
                datalist:datalistTemp,
                totalCount:totalCountTemp,
            })
        });
    }

    getProjectList = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetProjects,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.projectSearchKey,
                resultsize:this.state.resultSize,
                status:this.state.status,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
                companyId:this.state.companyId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:data.data.projectList,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.projectSearchKey,
                })
            }else{
                this.setState({
                    datalist:[],
                    totalCount:0,
                    showLoader:false,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.projectSearchKey,
                })
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });
            
            fetch(Constants.GetProjects,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.projectSearchKey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    companyId:this.state.companyId
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        componentDidMountFlag:true,
                        datalist:data.data.projectList,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.projectSearchKey,
                        currentPageNo:page,
                    })
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        componentDidMountFlag:true,
                        apiSearchKey:this.state.projectSearchKey,
                    })
                }
            });
        }
    }

    deleteActivateProject = () => {
        let url = "";

        if(this.state.operationType === deleteProject){
            url = Constants.DeleteProject;
        }else if(this.state.operationType === reactivateProject){
            url = Constants.ReactivateProject;
        }

        this.setState({
            showBtnLoader:true,
        });

        fetch(url,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                projectId:this.state.deleteReactivateId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showBtnLoader:false,
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                },() =>{
                    this.getProjectList();
                })
            }else{
                this.setState({
                    showBtnLoader:false,
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,

                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                });
            }
        });
    }
    /*******************API CALLS END HERE*******************/

    render() {
        const actionArr = [
            {
                icon:"edit",
                label:editProject
            },
            {
                icon:"delete",
                label:deleteProject
            },
        ]
        const actionArrReactivate = [
            {
                icon:"check_circle",
                label:reactivateProject
            },
        ]
        return(
            <Router>
                <section>
                    {
                        this.state.showLoader &&
                        <div class="loader"></div>
                    }
                    {
                        // Need to check component did mount flag, other wise until api is called,
                        // it shows no items found layout.
                        this.state.componentDidMountFlag &&
                        <div className="body-wrapper">
                            <div className="search-addnew-bg">
                                <div className="search-addnew">
                                    <div className="search-filter-layout">
                                        <FilterDropDown
                                            placeholder={this.state.resultSizePlaceholder}
                                            dropdownArr={this.state.resultSizeArr}
                                            onDropDownItemClick={this.resultSizeDropdownClick}
                                            themeSettings={this.props.themeSettings}
                                            mleft={0}
                                        />
                                        <div className="search-col m-l-10">
                                            <input 
                                                name="projectSearchKey" 
                                                type="text"
                                                onChange={this.handleChange}
                                                placeholder="Search" 
                                                onKeyPress={this.onEnterBtnPress}
                                                value={this.state.projectSearchKey} />
                                            <SearchSpan themeSettings={this.props.themeSettings} onClick = {this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                                        </div>
                                        <FilterDropDown
                                            placeholder={this.state.statusPlaceholder}
                                            dropdownArr={this.state.statusArr}
                                            onDropDownItemClick={this.statusDropdownClick}
                                            themeSettings={this.props.themeSettings}
                                        />
                                    </div>
                                    <div className="search-addnew-shw-employees-btns">
                                       <AddNewButtonLayout themeSettings={this.props.themeSettings} style={{marginLeft:15}}>
                                            <a  href="javascript:void(0);" onClick={this.onAddProjectClick}>
                                                <span className="material-icons">add_circle</span>
                                                <p>Add Project</p>
                                            </a>
                                        </AddNewButtonLayout>
                                    </div>
                                </div>
                            </div>
                            {
                                !(isEmptyArray(this.state.datalist)) &&
                                <div className="common-tab-bg">
                                    <div className="common-table" style={{width:"100%"}}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="text-center" width={"5%"}>No.</th>
                                                    <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortProjectName)}>
                                                        <div className="sort-header">Project Name
                                                            <span className={(this.state.sort === sortProjectName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                        </div>
                                                    </th>
                                                    <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortProjectCode)}>
                                                        <div className="sort-header">Project Code
                                                            <span className={(this.state.sort === sortProjectCode)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                        </div>
                                                    </th>
                                                    <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDescription)}>
                                                        <div className="sort-header">Description
                                                            <span className={(this.state.sort === sortDescription)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                        </div>
                                                    </th>
                                                    <th className="c-pointer">Project Manager</th>
                                                    <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortStartDate)}>
                                                        <div className="sort-header">Start Date
                                                            <span className={(this.state.sort === sortStartDate)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                        </div>
                                                    </th>
                                                    <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortEndDate)}>
                                                        <div className="sort-header">End Date
                                                            <span className={(this.state.sort === sortEndDate)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                        </div>
                                                    </th>
                                                    <th className="text-center" width={"5%"}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !isEmptyArray(this.state.datalist) && 
                                                    this.state.datalist.map((item,idx) => {
                                                        return <tr onClick={this.handleProjectDetailsDialogShow.bind(this,item)}>
                                                            <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                            <td>{item.projectName}</td>
                                                            <td>{item.projectCode}</td>
                                                            <td>{isEmptyVariable(item.description)?"-":item.description}</td>
                                                            <td>{isEmptyVariable(item.projectManagerName)?"-":item.projectManagerName}</td>
                                                            <td>{isEmptyVariable(item.startDate)?"-":moment(item.startDate).format('DD/MM/YYYY')}</td>
                                                            <td>{isEmptyVariable(item.endDate)?"-":moment(item.endDate).format('DD/MM/YYYY')}</td>
                                                            <td className="text-center">
                                                                <TableDropDown 
                                                                    actionArr={item.status === "Active"?actionArr:actionArrReactivate}
                                                                    onDropDownItemClick={this.onTableDropDownItemClick}
                                                                    dropDownId={item}
                                                                    dropdownWidth={"180px"}
                                                                    themeSettings={this.props.themeSettings}
                                                                />
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            {
                                this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && 
                                <div class="no-items-layout">
                                    <div class="no-items-card">
                                        <h6>
                                        {
                                            isEmptyVariable(this.state.apiSearchKey)?
                                            Constants.NO_RECORDS_WARNING
                                            :
                                            Constants.EMPTY_SEARCH_WARNING
                                        }
                                        </h6>
                                    </div>
                                </div>
                            }
                            <div className="pagination-layout">
                                <div className="row custom-row">
                                    <div className="col-md-9 custom-col">
                                    {
                                        (this.state.totalCount > this.state.datalist.length) && 
                                        <Pagination 
                                            totalLength ={this.state.totalCount} 
                                            items={this.state.datalist} 
                                            onChangePage={this.onChangePage} 
                                            pageSize={this.state.resultSize}
                                            currentPageNo = {this.state.currentPageNo}
                                            initialPage={this.state.currentPageNo} 
                                            themeSettings={this.props.themeSettings}/>
                                    }
                                    </div>
                                    <div className="col-md-3 custom-col">
                                        <p>{"Total Records: "+this.state.totalCount}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <AlertDialog
                        showAlertDialog={this.state.showAlertDialog}
                        handleAlertDialogClose={this.handleAlertDialogClose}
                        type={
                            (this.state.operationType === deleteProject)
                            ?
                            Constants.ALERT_TYPE_WARNING
                            :
                            Constants.ALERT_TYPE_INFO
                        }
                        alertDialogHeading={
                            (this.state.operationType === deleteProject)
                            ?
                            "Delete Project"
                            :
                            "Reactivate Project"
                        }
                        alertDialogMessage={this.state.alertDialogMessage}
                        proceedBtnClick={this.deleteActivateProject}
                        proceedBtnLabel={
                            (this.state.operationType === deleteProject)
                            ?
                            "Delete"
                            :
                            "Reactivate"
                        }
                        showLoader={this.state.showBtnLoader}
                        themeSettings={this.props.themeSettings}
                    />

                    <AlertDialog 
                        showAlertDialog={this.state.showAlertDialogInfo}
                        handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                        type= {Constants.ALERT_TYPE_ALERT}
                        alertDialogMessage={this.state.alertDialogMessageInfo}
                        proceedBtnClick={this.handleAlertDialogCloseInfo}
                        proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                        themeSettings={this.props.themeSettings}
                    />

                    <AddProjectDialog 
                        showAddProjectDialog={this.state.showAddProjectDialog}
                        handleAddProjectDialogClose={this.handleAddProjectDialogClose}
                        isEdit={this.state.isEdit}
                        companyId={this.state.companyId}
                        editProjectItem={this.state.editProjectItem}
                        unitArr={this.state.unitArr}
                        employeesArr={this.state.employeesArr}
                        themeSettings={this.props.themeSettings}
                    />

                    <ProjectDetailsDialog
                        projectData={this.state.selectedItem}
                        showProjectDetailsDialog={this.state.showProjectDetailsDialog}
                        handleProjectDetailsDialogClose={this.handleProjectDetailsDialogClose}
                    />
                </section>
            </Router>
        );
    }
}

export default ListProjects;