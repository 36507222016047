import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { getThemeSettingsVariables } from '../../common/Global/commonFunctions';
import {TopTabsLayout} from '../../common/Global/globalStyles.style';

let themeSettings  = getThemeSettingsVariables();
let themeColor='';

class CityMasterTopMenu extends Component{
    constructor(props) {
        super(props);

        themeSettings  = getThemeSettingsVariables();
        themeColor = `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`;
        
    }

    // This function to handle click events on City tab
    cityOnClick = () =>{
        this.props.cityTabItemOnClick();
    }

    // This function handle click events on State/Province tab
    stateOnClick = () => {
        this.props.stateTabItemOnClick();
    }
    // This function handle click events on Country tab
    countryOnClick = () => {
        this.props.countryTabItemOnClick();
    }

    render(){
        let width="40%";
        const {displayingCities,displayingStates,displayingCountries} = this.props;
    
        return(
            <TopTabsLayout themeSettings={this.props.themeSettings}>
                <div className="question-tabs"  style={{marginRight:0}}>
                    <ul style={{width:width}}>
                    {/* By default Cities must be displayed.*/}
                        <li className={displayingCities?"questiontab-active":""}>
                            <a onClick={this.cityOnClick} href="javascript:void(0)"
                            style={{color: !displayingCities ? themeColor : '', fontWeight: !displayingCities ? "bold" : "100px"}}>City</a>
                        </li>
                        <li className={displayingStates?"questiontab-active":""}>
                            <a onClick={this.stateOnClick} href="javascript:void(0)"
                            style={{color: !displayingStates ? themeColor : '', fontWeight: !displayingStates ? "bold" : "100px"}}>State/Province</a>
                        </li>
                        <li className={displayingCountries?"questiontab-active":""}>
                            <a onClick={this.countryOnClick} href="javascript:void(0)"
                            style={{color: !displayingCountries ? themeColor : '', fontWeight: !displayingCountries ? "bold" : "100px"}}>Country</a>
                        </li>
                    </ul>
                </div>
            </TopTabsLayout>
        )
    }
}

export default withRouter(CityMasterTopMenu);