import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {
    isEmptyVariable, isEmptyArray, getThemeSettingsVariables,
    getLocalStorageVariables} from '../../../common/Global/commonFunctions';
import {Accordion, Button, Card, Modal} from 'react-bootstrap';
import {  DialogSaveButton, TableCheckBox} from '../../../common/Global/globalStyles.style';
import * as TemplateConstants from "../../../common/Global/templateConstants";
import SelectFunctionDialog from '../auditee/selectFunction';
import AlertDialog from '../../../common/AlertDialog';

const defaultYNPlaceholder = "Rank";
const functionPlaceholder="Select Function";
const themeSettings = getThemeSettingsVariables();
const userDetails  = getLocalStorageVariables();

class AssignFunctionDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader:false,
            dataList:[],
            oldDatalist:[],
            showSelectFunctionDialog:false,
            allFunctionArr:[],
            allTaskFuncArr:[],
            selectedTaskId:[],
            selectedTaskFuncIds:[],
            activeKey: null,
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showAssignFuncDialog){
            this.setState({
                dataList:this.props.dataList,
                oldDatalist:this.props.dataList,
                allFunctionArr:[],
                allTaskFuncArr:[],
                selectedTaskId:[],
                selectedTaskFuncIds:[],
                activeKey: null
            },() => {
                this.getInitData();
            })
        }
    }

    handleStopPropagation = (e) => {
        e.stopPropagation();
    }

    // This function shows the 'Function' dialog box
    handleSelectFunctionDialogShow = () => {
        this.setState({
            showSelectFunctionDialog:true,
        })
    }

    // This function hides the 'Function' dialog box
    handleSelectFunctionDialogClose = () =>{
        this.setState({
            showSelectFunctionDialog:false,
        })
    }

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
    }

    // This function handle the 'Function' dialog box save button click event
    handleSelectFunctionDialogSaveClose = (selectedFunctionObjArr) =>{
        let tmpSelectedTaskIds = this.state.selectedTaskId;
        let tmpAllTaskFuncArr = [];
        // Extract function IDs from the selected function object array
        let selectedFuncIds = selectedFunctionObjArr.map(e=>e.functionId)||[];

        if(this.state.allTaskFuncArr.length>0){
            // Filter out the task function array that is not in the selected task ID array
            tmpAllTaskFuncArr = this.state.allTaskFuncArr.filter(e=>
                !tmpSelectedTaskIds.some(item => item.assignmentTaskId === e.assignmentTaskId &&
                    item.taskId === e.taskId)) || []
        }

        // Iterate through selected task ID array to add new task function array
        tmpSelectedTaskIds.map(e=>{
            selectedFuncIds.map(func =>{
                tmpAllTaskFuncArr.push({
                    assignmentTaskId:e.assignmentTaskId,
                    taskId:e.taskId,
                    functionId:func
                })
            })
        })
        
        let tmpAllFuncArr = this.state.allFunctionArr;
        
        // Iterate through selected function object array to add new functions to the all function array
        selectedFunctionObjArr.map(e=>{
            let selectedTaskFucIds = tmpAllFuncArr.map(e=>e.functionId) ||[];
            // If the function ID is not in the all function array, add it to the all function array
            if(!selectedTaskFucIds.includes(e.functionId)){
                tmpAllFuncArr.push({
                    functionId:e.functionId,
                    functionName:e.functionName
                })
            }
        });
        
        this.setState({
            allTaskFuncArr:tmpAllTaskFuncArr,
            allFunctionArr:tmpAllFuncArr,
            showSelectFunctionDialog:false,
        },()=>{
            // Update task function arrays for each selected task
            this.state.selectedTaskId.map(e=>this.updateTaskFunctionArr(e, true))
        })
    }

    // Function to find the difference between two arrays
    // by removing elements of arr2 from arr1
    getArrDiff = (arr1, arr2) => {
        const set1 = new Set(arr1);
        // Iterate through arr2 and delete its elements from set1
        for (const value of arr2) {
          set1.delete(value);
        }
        // Convert the remaining Set object back to an array
        return Array.from(set1);
    }
    
    // Helper function to get the identifier based on process name or checklist heading
    getIdentifier = (item) => {
        return !isEmptyVariable(item.processName) ? item.processId : item.checkListHeadingId;
    };

    // Check if the task has associated functions in tmpAllTaskFuncArr
    tasksWithFunctionsExist = (taskItem, allTaskFuncArr) => {
        return allTaskFuncArr.some(func =>
            func.assignmentTaskId === taskItem.assignmentTaskId &&
            func.taskId === taskItem.taskId &&
            func.functionId
        );
    };

    // Get selected task function IDs
    getSelectedTaskFuncIds = (selectedAssignmentTaskId) => {
        return this.state.allTaskFuncArr
            .filter(item => selectedAssignmentTaskId
            .some(e => item.assignmentTaskId === e.assignmentTaskId && item.taskId === e.taskId))
            .map(func => func.functionId) || [];
    }

    // Helper function to filter data list based on selected tasks and function IDs
    getFilteredDataList = (tempOldDataList, selectedTaskFuncIds) => {
        let newDataList= [];
        tempOldDataList.forEach(item => {
            const taskList = item.taskList.filter(taskItem => {
                let taskFuncIds = this.state.allTaskFuncArr
                    .filter(item => item.assignmentTaskId === taskItem.assignmentTaskId && item.taskId === taskItem.taskId)
                    .map(func => func.functionId) || [];
    
                let getArrDiff = this.getArrDiff(selectedTaskFuncIds, taskFuncIds);
                let getArrDiff1 = this.getArrDiff(taskFuncIds, selectedTaskFuncIds);
    
                return getArrDiff.length == 0 && getArrDiff1.length == 0;
            });
            newDataList.push({...item, taskList });
        });
        return newDataList;
    }

    // Helper function to update task checkboxes
    updateTaskCheckboxes = (tempOldDataList, selectedAssignmentTaskId) => {
        tempOldDataList.forEach(e => {
            e.taskList.forEach(item => {
                item.isTaskCheckboxSelected = selectedAssignmentTaskId.some(
                    taskItem => taskItem.assignmentTaskId === item.assignmentTaskId && taskItem.taskId === item.taskId
                );
            });
            e.isSelected = e.taskList.every(taskItem => taskItem.isTaskCheckboxSelected);
        });
        return tempOldDataList;
    }

    // Helper function to check if all tasks in taskList have the same functionId
    allTasksHaveSameFunctionId = (taskList) => {
        if (taskList.length === 0) {
            return false;
        }

        // Extract the list of functionIds associated with the tasks in taskList
        const taskFunctionIds = taskList.map(task => {
            const funcObj = this.state.allTaskFuncArr.find(func =>
                func.assignmentTaskId === task.assignmentTaskId && func.taskId === task.taskId
            );
            return funcObj ? funcObj.functionId : null;
        }).filter(id => id !== null);

        if (taskFunctionIds.length === 0) {
            return false;
        }

        // Get the functionId of the first task
        const firstFunctionId = taskFunctionIds[0];

        // Check if all functionIds are the same
        return taskFunctionIds.every(id => id === firstFunctionId);
    };

    // Handle selection of all tasks
    handleSelectionAllTasks = (e) => {
        e.stopPropagation();
        let isChecked = e.target.checked;
        let idAndIndex = e.target.name;
        let id = parseInt(idAndIndex.split(":-:")[0]);

        let tempOldDataList = [...this.state.oldDatalist];
        let tmpSelectedAssignmentTaskId = [...this.state.selectedTaskId];
        
        tempOldDataList.forEach(item => {
            if(this.getIdentifier(item) === id){
                item.isSelected = isChecked;

                // Check if all tasks in taskList have the same functionId
                let allSameFunctionId = this.allTasksHaveSameFunctionId(item.taskList);

                item.taskList.forEach(taskItem => {
                    const isAlreadySelected = tmpSelectedAssignmentTaskId.some(
                        e => e.assignmentTaskId === taskItem.assignmentTaskId && e.taskId === taskItem.taskId
                    );
                    if (isChecked && !isAlreadySelected) {
                        tmpSelectedAssignmentTaskId.push({
                            assignmentTaskId: taskItem.assignmentTaskId,
                            taskId: taskItem.taskId
                        });
                    }else if (!isChecked && isAlreadySelected){
                        tmpSelectedAssignmentTaskId = tmpSelectedAssignmentTaskId.filter(
                            e => e.assignmentTaskId !== taskItem.assignmentTaskId || e.taskId !== taskItem.taskId
                        );
                    }

                    if (allSameFunctionId) {
                        taskItem.isTaskCheckboxSelected = isChecked;
                    } else {
                        const taskHasFunctions = this.tasksWithFunctionsExist(taskItem, this.state.allTaskFuncArr);
                        taskItem.isTaskCheckboxSelected = !taskHasFunctions ? isChecked : false;
                    }
                });
            }
        });

        let newDataList = tmpSelectedAssignmentTaskId.length > 0
            ? this.getFilteredDataList(tempOldDataList, this.getSelectedTaskFuncIds(tmpSelectedAssignmentTaskId))
            : tempOldDataList;

        this.setState({
            oldDatalist: tempOldDataList,
            dataList:newDataList,
            selectedTaskId:tmpSelectedAssignmentTaskId,
            selectedTaskFuncIds:Array.from(new Set(this.getSelectedTaskFuncIds(tmpSelectedAssignmentTaskId)))
        })
    }

    // Handle selection of individual tasks
    handleSelectionTasks = (e,taskItem) => {
        let isChecked = e.target.checked;
        let tmpSelectedAssignmentTaskId = [...this.state.selectedTaskId];

        // If the checkbox is checked, add the selected task to the tmpSelectedAssignmentTaskId array
        if(isChecked){
            tmpSelectedAssignmentTaskId.push({
                assignmentTaskId: taskItem.assignmentTaskId,
                taskId: taskItem.taskId
            })
        }
        // Otherwise, filter out the selected task from the tmpSelectedAssignmentTaskId array
        else{
            tmpSelectedAssignmentTaskId = tmpSelectedAssignmentTaskId.filter(e=>
                e.assignmentTaskId!=taskItem.assignmentTaskId && e.taskId!=taskItem.taskId)
        }
    
        // Update the isTaskCheckboxSelected for each task based on the tmpSelectedAssignmentTaskId array
        let tempOldDataList = this.updateTaskCheckboxes([...this.state.oldDatalist], tmpSelectedAssignmentTaskId);

        let newDataList = tmpSelectedAssignmentTaskId.length > 0
            ? this.getFilteredDataList(tempOldDataList, this.getSelectedTaskFuncIds(tmpSelectedAssignmentTaskId))
            : tempOldDataList;

        this.setState({
            dataList:newDataList,
            selectedTaskId:tmpSelectedAssignmentTaskId,
            selectedTaskFuncIds:Array.from(new Set(this.getSelectedTaskFuncIds(tmpSelectedAssignmentTaskId)))
        })
    }

    // Function to update the task list based on the checked status of a task item
    updateTaskFunctionArr = (taskItem, isChecked) => {
        let tmpSelectedAssignmentTaskId = [...this.state.selectedTaskId];

        // If the checkbox is checked, add the selected task to the tmpSelectedAssignmentTaskId array
        if(isChecked){
            tmpSelectedAssignmentTaskId.push({
                assignmentTaskId: taskItem.assignmentTaskId,
                taskId: taskItem.taskId
            })
        }
        // Otherwise, filter out the selected task from the tmpSelectedAssignmentTaskId array
        else{
            tmpSelectedAssignmentTaskId = tmpSelectedAssignmentTaskId.filter(e=>
                e.assignmentTaskId!=taskItem.assignmentTaskId && e.taskId!=taskItem.taskId)
        }
        
        // Update the isTaskCheckboxSelected for each task based on the tmpSelectedAssignmentTaskId array
        let tempOldDataList = this.updateTaskCheckboxes([...this.state.oldDatalist], tmpSelectedAssignmentTaskId);

        let newDataList = tmpSelectedAssignmentTaskId.length > 0
            ? this.getFilteredDataList(tempOldDataList, this.getSelectedTaskFuncIds(tmpSelectedAssignmentTaskId))
            : tempOldDataList;

        // Set the state with the new data list, selected task IDs, and selected task function IDs
        this.setState({
            dataList:newDataList,
            selectedTaskId:tmpSelectedAssignmentTaskId,
            selectedTaskFuncIds:Array.from(new Set(this.getSelectedTaskFuncIds(tmpSelectedAssignmentTaskId)))
        })
    }

    handleAccordionToggle = (eventKey) => {
        if (this.state.activeKey === eventKey) {
            this.setState({ activeKey: null });
        } else {
            this.setState({ activeKey: eventKey });
        }
    }

    handleAssignFuncDialogClose = () => {
        this.setState({
            activeKey: null
        });
        this.props.handleAssignFuncDialogClose();
    }
    /*******************API CALLS*******************/
    getInitData(){
        this.setState({
            showLoader:true
        })
        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            assignmentId: this.props.assignmentId,
        }

        if(this.props.company?.companyType!== Constants.USER_ENGAGEMENT_TYPE_SELF){
            postParam.companyId = this.props.company?.companyId;
        }

        // if(this.props.isDraftOnward){
        //     postParam.isInitDraftOnward = "Y";
        // }

        fetch(Constants.GetAssignmentTaskFunctionLinking, {
            method:"POST",
            mode:"cors",
            body: new URLSearchParams(postParam)
        })
        .then((response)=> { return response.json(); }) 
        .then((data)=> {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let functionLinkingData = data.data;
                let tmpAllTaskFuncArr = [];
                let tmpAllFuncArr = [];
                let tempOldDataList = [...this.state.oldDatalist];

                functionLinkingData.map(e => {
                    tmpAllTaskFuncArr.push({
                        assignmentTaskId:e.assignmentTaskId,
                        taskId:e.taskId,
                        functionId:e.functionId
                    })
                    let selectedTaskFucIds = tmpAllFuncArr.map(e=>e.functionId) ||[];
                    if(!selectedTaskFucIds.includes(e.functionId)){
                        tmpAllFuncArr.push({
                            functionId:e.functionId,
                            functionName:e.functionName
                        })
                    }
                })

                tempOldDataList.forEach(process => {
                    // Determine the visibility of the main checkbox
                    let functionIds = process.taskList.flatMap(task => {
                        let taskFuncIds = tmpAllTaskFuncArr.filter(item => 
                            item.assignmentTaskId === task.assignmentTaskId && item.taskId === task.taskId)
                            .map(e => e.functionId);
                            return taskFuncIds.length > 0 ? taskFuncIds : [null];
                        });
                        
                    let uniqueFunctionIds = [...new Set(functionIds)];
                    process.isCheckboxVisible = uniqueFunctionIds.length === 1 || (uniqueFunctionIds.length === 2 && uniqueFunctionIds.includes(null) && uniqueFunctionIds.includes(undefined));
                });

                this.setState({
                    dataList: tempOldDataList,
                    allTaskFuncArr:tmpAllTaskFuncArr,
                    allFunctionArr:tmpAllFuncArr,
                    showLoader:false
                })
            }else{
                this.setState({
                    showLoader:false
                })
            }
        })
    }

    saveAssignmentTaskFunctionLinking = () => {
        let tmpAllTaskArr = this.state.allTaskFuncArr;

        if(isEmptyArray(this.state.allTaskFuncArr)){
            this.setState({
                showAlertDialogInfo:true,
                alertDialogMessageInfo:"Assign atleast one function to any task"
            })
        }else {
            let assignmentTaskFunctionLinkingUrl = Constants.AddAssignmentTaskFunctionLinking;
            if(this.props.isDraftOnward){
                assignmentTaskFunctionLinkingUrl = Constants.AddDraftAssignmentTaskFunctionLinking;
            }
            let postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                assignmentId: this.props.assignmentId,
                assignmentTaskFunctionLinkData:JSON.stringify(Array.from(new Set(tmpAllTaskArr.map(JSON.stringify))).map(JSON.parse)),
            }
            if(this.props.company?.companyType!== Constants.USER_ENGAGEMENT_TYPE_SELF){
                postParam.companyId = this.props.company?.companyId;
            }
            if(this.props.isDraftOnward){
                postParam.isInitDraftOnward = "Y";
            }
            fetch(assignmentTaskFunctionLinkingUrl,{
                method: "post",
                mode: "cors",
                body: new URLSearchParams(postParam)
            })
            .then((response)=>{
                return response.json()
            })
            .then(async(data)=>{
                if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                }else if (data.responseCode === Constants.CODE_SUCCESS) {
                    this.setState({
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    })
                    this.handleAssignFuncDialogClose();
                }else{
                    this.setState({
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    })
                }
            })
        }
    }
    /*******************API CALLS END HERE*******************/
    
    render() {
        let showEmptyCard = true;
        return(
            <section>
            <Modal className="followup-task-select-table-dialog custom-dialog"
                show={this.props.showAssignFuncDialog} 
                backdrop="static">
                <Modal.Header>
                    <h5>Assign Function</h5>
                    <button 
                        onClick={this.handleAssignFuncDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div className="modal-body">
                { 
                            this.state.showLoader &&
                            <div className="modal-loading-text">
                                <p
                                style={{
                                    background:`rgba(${this.props.themeSettings.themeColor.r},${this.props.themeSettings.themeColor.g},${this.props.themeSettings.themeColor.b},${this.props.themeSettings.themeColor.a})`,
                                    color:`rgba(${this.props.themeSettings.themeTextColor.r},${this.props.themeSettings.themeTextColor.g},${this.props.themeSettings.themeTextColor.b},${this.props.themeSettings.themeTextColor.a})`
                                }}
                                >Loading....</p>
                            </div>
                        }
                    <div className="body-wrapper accordion-layout">
                        {this.state.selectedTaskId.length>0 && (['Process','CheckList'].includes(this.props.assignmentDetails.assignmentArea)) && 
                            <div style={{width:"250px"}}>
                                <div className="dummy-dropdown mt-1 ml-2" tabIndex={0} onKeyPress={this.onKeyBtnPress}  
                                    onClick={this.handleSelectFunctionDialogShow}>
                                    <p class="placeholder-text">
                                        {isEmptyArray(this.state.selectedTaskFuncIds)
                                        ?functionPlaceholder
                                        :this.state.selectedTaskFuncIds.length === 1
                                        ?"1 Function Selected"
                                        :this.state.selectedTaskFuncIds.length+" Functions Selected"}
                                    </p>
                                    <span class="material-icons">keyboard_arrow_right</span>
                                </div>
                            </div>
                        }
                        <div style={{position:"relative", overflow:"auto"}}>
                            {!isEmptyArray(this.state.dataList) && 
                                this.state.dataList.filter(item => !isEmptyArray(item.taskList)).map((item,index) => {
                                    let className;
                                    if(this.state.dataList.filter(item => !isEmptyArray(item.taskList)).length === 1){
                                        className = 'single-card';
                                    } else if (index === 0) {
                                        className = 'card-radius'; // CSS for the first item
                                    } else if (index === (this.state.dataList.filter(item => !isEmptyArray(item.taskList)).length - 1)) {
                                        className = 'card-last'; // CSS for the last item
                                    } else {
                                        className = 'card-middle'; // CSS for all other items
                                    }
                                    return (
                                        !isEmptyArray(item.taskList) && (
                                            <div>
                                                <Accordion style={{marginTop:index === 0?15:0}} activeKey={this.state.activeKey}>
                                                    <Card className={className}> 
                                                        <Card.Header>
                                                            <Accordion.Toggle
                                                                as={Button}
                                                                variant="link"
                                                                eventKey={!isEmptyVariable(item.processName) ? item.processId : item.checkListHeadingId }
                                                                onClick={() => this.handleAccordionToggle(!isEmptyVariable(item.processName) ?  item.processId : item.checkListHeadingId)}
                                                            >
                                                                <div className='row align-items-center'>
                                                                {item.isCheckboxVisible && (
                                                                    <TableCheckBox onClick={this.handleStopPropagation}
                                                                        themeSettings={themeSettings}>
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={(!isEmptyVariable(item.processName) ? item.processId : item.checkListHeadingId)+":-:"+index}
                                                                            id={(!isEmptyVariable(item.processName) ? item.processId : item.checkListHeadingId)+":-:"+index}
                                                                            onChange={this.handleSelectionAllTasks}
                                                                            checked={item.isSelected?true:false}
                                                                        />
                                                                        <label for={(!isEmptyVariable(item.processName) ? item.processId : item.checkListHeadingId)+":-:"+index} 
                                                                        style={{marginLeft:-3}}></label>
                                                                    </TableCheckBox>
                                                                )}
                                                                    <h6 className='col-md-6 text-left small-h6'>
                                                                        {!isEmptyVariable(item.processName) ? item.processName : item.heading}
                                                                    </h6>
                                                                </div>
                                                        </Accordion.Toggle>
                                                    </Card.Header> 
                                                    <Accordion.Collapse eventKey={!isEmptyVariable(item.processName) ? item.processId : item.checkListHeadingId}>
                                                        <Card.Body>
                                                            {
                                                                !isEmptyArray(item.taskList) && (
                                                                <div>
                                                                    <div className="common-tab-bg">
                                                                        <div className="common-table" style={{ width: "100%" }} >
                                                                            <table className="table">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th></th>
                                                                                        {
                                                                                            this.props.selectedTaskTemplateFields.map((item) => {
                                                                                                return (
                                                                                                    <th className={item.fieldType === TemplateConstants.FIELD_TYPE_TEXT ? "scrollable-table-task-text-th" : "scrollable-table-task-others-th"}>
                                                                                                        {item.fieldName}
                                                                                                    </th>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                        <th>Function</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        !isEmptyArray(item.taskList) &&
                                                                                        item.taskList.map((taskItem, idx) => {
                                                                                            showEmptyCard = false;
                                                                                            let taskFuncIds = this.state.allTaskFuncArr.filter(e=> 
                                                                                                e.assignmentTaskId==taskItem.assignmentTaskId && e.taskId == taskItem.taskId)
                                                                                                .map(e=>e.functionId)||[];
                                                                                            
                                                                                            return (
                                                                                                <tr key={idx + "_" + item.assignmentTaskId} style={{ background: "#f5f5f5" }}>
                                                                                                    <td className="text-center">
                                                                                                        <TableCheckBox onClick={this.handleStopPropagation}
                                                                                                            themeSettings={themeSettings}>
                                                                                                            <input 
                                                                                                                type="checkbox"
                                                                                                                name={idx}
                                                                                                                id={idx+":-:"+(!isEmptyVariable(item.processName) ? item.processId : item.checkListHeadingId)+":-:"+taskItem.taskId}
                                                                                                                onChange={(e) => this.handleSelectionTasks(e,taskItem)}
                                                                                                                checked={taskItem.isTaskCheckboxSelected?true:false}
                                                                                                            />
                                                                                                            <label for={idx+":-:"+(!isEmptyVariable(item.processName) ? item.processId : item.checkListHeadingId)+":-:"+taskItem.taskId} 
                                                                                                                style={{marginLeft:6}}></label>
                                                                                                        </TableCheckBox>
                                                                                                    </td>
                                                                                                    {
                                                                                                        this.props.selectedTaskTemplateFields.map((item, taskIdx) => {
                                                                                                            return (
                                                                                                                <td style={taskIdx+"" === "0"?{borderLeft:"5px solid "+taskItem.colorCode}:{}}>
                                                                                                                    <div className={item.fieldId + "" === "1" ? "dangerouslysethtml-flex" : ""}>
                                                                                                                        <div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{ __html: this.props.getTaskDisplayValues(taskItem.taskId, item) }} />
                                                                                                                        {
                                                                                                                            taskItem.completionStatus === "Completed" && item.fieldId + "" === "1" && (
                                                                                                                                <span className="material-icons exec-task-done" >
                                                                                                                                    done_all
                                                                                                                                </span>
                                                                                                                            )
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            );
                                                                                                        }
                                                                                                    )}
                                                                                                    <td style={{whiteSpace:'normal',minWidth:'150px'}}>{this.state.allFunctionArr.filter(e=>taskFuncIds.includes(e.functionId)).map(e=>e.functionName).join(", ") || ''}</td>
                                                                                                </tr>
                                                                                            );
                                                                                        }
                                                                                    )}
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        this.props.showChecklistScores &&
                                                                        <div className="checklist-scores-layout">
                                                                            <p>{"Max Score: " + item.maxScore}</p>
                                                                            <p> {"Total Score: " + item.totalScore} </p>
                                                                            <p> {"Percentage: " + ((item.totalScore / item.maxScore) * 100).toFixed(1) + "%"} </p>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )}
                                                        </Card.Body>
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                            </div>
                                        )
                                    );
                                })
                            }
                        </div>
                        <div className="modal-close-save m-t-15">
                            <div className="close-save-col">
                                <button onClick={this.handleAssignFuncDialogClose} type="button" 
                                className="modal-close m-b-0">Cancel</button>
                                <DialogSaveButton  themeSettings={this.props.themeSettings} 
                                onClick={ this.saveAssignmentTaskFunctionLinking} 
                                type="button" 
                                className="modal-save m-b-0">Save</DialogSaveButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <SelectFunctionDialog
                showFunctionsDialog={this.state.showSelectFunctionDialog}
                handleSelectFuncDialogClose={this.handleSelectFunctionDialogClose}
                handleSelectFunctionDialogSaveClose={this.handleSelectFunctionDialogSaveClose}
                selectedEmpFuncIds={this.state.selectedTaskFuncIds}
                departmentId={this.props.assignmentDetails.departmentId}
                themeSettings = {this.props.themeSettings}
            />
            <AlertDialog
                showAlertDialog={this.state.showAlertDialogInfo}
                handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                type={Constants.ALERT_TYPE_ALERT}
                alertDialogMessage={this.state.alertDialogMessageInfo}
                proceedBtnClick={this.handleAlertDialogCloseInfo}
                proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                themeSettings={this.props.themeSettings}
            />
            </section>
        );
    }
}

export default AssignFunctionDialog;