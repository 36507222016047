import React, { Component } from 'react';
import jszip from 'jszip'
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,getThemeSettingsVariables,GetOrderedMasterTabMenuReports,
    isEmptyVariable, isEmptyArray,removeHtmlTags,isJsonString} from '../../../common/Global/commonFunctions';
import TreeNodeDropdown from '../../../common/DropdownMenus/TreeNodeDropdownObservations';
import AddHeadingDialog from './addHeadingdialog'
import AddTextDialog from './addTextdialog'
import AlertDialog from '../../../common/AlertDialog';
import {ExpandCollapseWrapper,TaskTagButtonsLayout} from '../../../common/Global/globalStyles.style';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-react-navigations/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import Sidebar from "../../../common/SidebarCollapse";
import Topbar from "../../../common/Topbar";
import MasterMenuTabs from "../Common/topmenu";
import AddNewResponseDialog from "../Ongoing/addNewResponseDialog"
import ViewResponseCommentDialog from "../Ongoing/viewResponseCommentsdialog";
import ObservationDetailsLayout from "../../Execute/Observation/ObservationDetails/observationDetails";
import { Document, Packer,PageOrientation,BorderStyle } from "docx";
import { saveAs } from "file-saver";
import {
	getFirstPageContent, 
	getHeaderContent, 
	getFooterContent,
	getParagraphStyles, 
	getTableContent,
    getAssignementTableHeadingContent,
	getExecutiveSummaryContent,
    getValueAdditionContent,
	getObservationContentWithTemplates,
    getSecondFormatObservationsContentWithTemplates,
} from "../Common/createDownloadReportFunctions"
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdownReports';
import AssignmentTaskLayout from "../Common/assignmentTaskList";
import UploadReportDialog from "../Completed/uploadReport";
import ListUploadedReportDialog from "../Completed/uploadedReportListDialog";
import DonotReportObservationsLayout from "../Common/DonotReportObservationList";
import TopmenuReports from '../../../common/TopmenuReports';

const userDetails  = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();

const downloadReportPlaceholder = "Download Report";

const AddChildDropdownObj = {
    icon:"add_circle",
    label:"Add Child"
}
const AddTextDropdownObj = {
    icon:"add_circle",
    label:"Add Text"
}
// const AddResponseDropdownObj = {
//     icon:"add_circle",
//     label:"Add Response"
// }
const ViewResponsesDropdownObj = {
    icon:"visibility",
    label:"View Responses"
}
const AddSiblingDropdownObj = {
    icon:"add_box",
    label:"Add Sibling"
}
const editDropdownObj = {
    icon:"edit",
    label:"Edit"
}
const editObsDropdownObj = {
    icon:"edit",
    label:"Edit Observation"
}
const editTextDropdownObj = {
    icon:"edit",
    label:"Edit Text"
}
const delDropdownObj = {
    icon:"delete",
    label:"Delete"
}
const ObsDetailsDropdownObj = {
    icon:"description",
    label:"Observation Details"
}

const resetLayoutFlags = {
    showObservationTree:false,
    showObservationDetails:false,
    showDonotReportObservations:false,
    showTaskList:false,
}

const initFormatStandard = "Initial Standard format";
const initFormatTabular = "Initial Tabular format";

class HeadingTreeDialog extends Component {
    constructor(props) {
        super(props);
        let isInitDraftReport = false;
        if(this.props.location.pathname.includes(Constants.FINALIZEDOBSERVATIONS_PATH)){
            isInitDraftReport = false;
        }

        if(this.props.location.pathname.includes(Constants.INITIALDRAFTOBSERVATIONS_PATH)){
            isInitDraftReport = true;
        }

        this.state = {
            showLoader:false,

            assignmentId: this.props.match.params.id,
            headingMap:{},
            assignmentObj:{},
            actionArr:[],
            allowEdit:"Y",
            syncFusionData:[],
            rootReportHeadingId:"",

            showCreateDialog:false,
            showCreateTextDialog:false,
            isEdit:false,
            createNodeType:"",
            selectedId:"",
            heading:"",
            relativeFunctionId:"",
            clearAndResetFunctionTree:true,
            idToBeExpandedAfterAddingChild:"",
            company:{},

            operationType:"",
            deleteReactivateId:"",
            showAlertWithRadioDialog:false,
            alertWithRadioDialogMessage:"",
            showBtnLoader:false,
            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showAddResponseDialog:false,
            showViewResponseDialog:false,
            
            showObservationTree:false,
            showObservationDetails:false,
            showDonotReportObservations:false,
            showTaskList:false,

            //download report
			companyDownloadReport:"",
			observationsDownloadReport:"",
            downloadReportArr:Constants.REPORT_DOWNLOAD_ARR_FINALIZED,
            downloadReportPlaceholder:downloadReportPlaceholder,

            showUploadReportDialog:false,
            showUploadedReportListDialog:false,
            
            isInitDraftReport:isInitDraftReport,
            filterPlaceholder: "Tasks",
        }
        this.fields = {};
        this.headingObj = {};
        this.newObservationsMap = {};
        this.newObservationsArr = [];
    }

    componentDidMount(){
        this.fields = {
            dataSource:[],
            id:"nodeId",
            text:"reportHeadingText",
            parentID:"parentId",
            child:"newChildren",
            expanded:"isExpanded",
        };
        this.getFunctionTree();
    }

    handleShowCreateDraftDialog = () => {
        this.setState({
            operationType:"Finalize Report",
            showAlertWithRadioDialog:true,
            alertWithRadioDialogMessage:"Are you sure you want to Finalize this report?"
        })
    }

    handleAlertWithDialogDialogClose = () =>{
        this.setState({
            showAlertWithRadioDialog:false,
            alertWithRadioDialogMessage:"",
            operationType:"",
        });
    }

    handleAddResponseDialogClose = (reloadFlag) =>{
        this.setState({
            showAddResponseDialog:false,
            selectedId:""
        },()=>{
            if(reloadFlag === true) this.getFunctionTree();
        });
    }

    handleViewResponseDialogClose = (reloadFlag) =>{
        this.setState({
            showViewResponseDialog:false,
            selectedId:""
        },()=>{
            if(reloadFlag === true) this.getFunctionTree();
        });
    }

    handleAddEditDialogClose = (reloadFlag) =>{
        let id = "";
        if(this.state.createNodeType === "child") id = this.state.relativeFunctionId;
        this.setState({
            showCreateDialog:false,
            clearAndResetFunctionTree:false,
            isEdit:false,
            createNodeType:"",
            selectedId:"",
            heading:"",
            relativeFunctionId:"",
            idToBeExpandedAfterAddingChild:id,
        },()=>{
            if(reloadFlag === true) this.getFunctionTree();
        })
    }

    handleAddEditTextDialogClose = (reloadFlag) =>{
        let id = "";
        id = this.state.relativeFunctionId;
        this.setState({
            showCreateTextDialog:false,
            clearAndResetFunctionTree:false,
            isEdit:false,
            createNodeType:"",
            selectedId:"",
            heading:"",
            relativeFunctionId:"",
            idToBeExpandedAfterAddingChild:id,
        },()=>{
            if(reloadFlag === true) this.getFunctionTree();
        })
    }

    onDropDownItemClick = (item,headingObj) => {
        if(item.label === "Add Child"){
            this.setState({
                showCreateDialog:true,
                createNodeType:"child",
                relativeFunctionId:headingObj.nodeId,
                
                isEdit:false,
            })
        }else if(item.label === "Add Sibling"){
            this.setState({
                showCreateDialog:true,
                createNodeType:"sibling",
                relativeFunctionId:headingObj.parentId,
                
                isEdit:false,
            })
        }else if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                deleteReactivateId:headingObj.nodeId,
                showAlertWithRadioDialog:true,
                alertWithRadioDialogMessage:"Are you sure you want to delete "+headingObj.heading+"?"
            })
        }else if(item.label === "Edit"){
            this.setState({
                showCreateDialog:true,
                createNodeType:"sibling",
                relativeFunctionId:headingObj.nodeId,
                
                isEdit:true,
                selectedId:headingObj.nodeId,
                heading:headingObj.heading,
            })
        }else if(item.label === "Add Text"){
            this.setState({
                showCreateTextDialog:true,
                relativeFunctionId:headingObj.nodeId,
                isEdit:false,
            })
        }else if(item.label === "Edit Text"){
            this.setState({
                showCreateTextDialog:true,
                relativeFunctionId:headingObj.nodeId,

                isEdit:true,
                selectedId:headingObj.nodeId,
                heading:headingObj.observationHeading,
            })
        }else if(item.label === "Add Response"){
            this.setState({
                showAddResponseDialog:true,
                selectedId:headingObj.nodeId,
            });
        }else if(item.label === "View Responses"){
            this.setState({
                showViewResponseDialog:true,
                selectedId:headingObj.nodeId,
            });
        }else if(item.label === "Edit Observation"){
            this.props.history.push(Constants.URL_EDIT_DRAFT_OBSERVATIONS + "/" + headingObj.nodeId+"-"+this.state.assignmentObj?.assignmentId);
        }else if(item.label === "Observation Details"){
            this.setState({
                ...resetLayoutFlags,
                showObservationDetails:true,
                selectedId:headingObj.nodeId,
            })
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    getRootLevelFunctions = (functionMapTemp) => {
        let rootFunctionArr = [];
        Object.values(functionMapTemp).map((item)=>{
            if(item.level === 0){
                rootFunctionArr.push(item);
            }
        })
        return rootFunctionArr;
    }

    //No need of return value since the array itself is passed and updated.
    //Javascript maintains single array with pointers. it will not deep copy the array
    addParentIdandHasChildrenFlag = (tempArr, nodeId, level,parentHeadingId,prefix) => {
        tempArr[nodeId].level = level;
        tempArr[nodeId].parentHeadingId = parentHeadingId;
        if(prefix !== "0"){
            tempArr[nodeId].reportHeadingText = prefix+".\xa0\xa0"+tempArr[nodeId].reportHeadingText;
        }

        // add parent Id to all the observations
        let ObsList = tempArr[nodeId].obsList.map((item,index)=>{
            item.parentId = parentHeadingId;
            if(prefix === "0"){
                item.reportHeadingText = (index+1)+".\xa0\xa0\xa0\xa0"+item.reportHeadingText;
            }else{
                item.reportHeadingText = prefix+"."+(index+1)+".\xa0\xa0\xa0\xa0"+item.reportHeadingText;
            }
            item.level = level+1;
            return item;
        })
        tempArr[nodeId].obsList = ObsList;

        if(!isEmptyArray(tempArr[nodeId].children)){
            tempArr[nodeId].children.map((childNode,index) => {
                let newPrefix = prefix === "0"?(index+1):(prefix+"."+(index+1));
                this.addParentIdandHasChildrenFlag(tempArr,childNode.nodeId,level+1,nodeId,newPrefix);
            });
        }else{
            tempArr[nodeId].hasChildren = false;
        }
    }

    createSyncHRFusionData = (parentId,nodeId,headingMap,isExpanded) => {
        //Now create an object
        let headingObj = headingMap[nodeId];
        if(headingObj){
            headingObj.newChildren = [...headingObj.obsList];
            headingObj.isExpanded = isExpanded;
            headingObj.fontbold = true;
            if(parentId !== nodeId){
                headingObj.parentId = parentId;
            }
            //now add children recursively
            headingObj.children.map((item)=>{
                headingObj.newChildren.push(this.createSyncHRFusionData(nodeId,item.nodeId,headingMap,true));
            });
            return headingObj;
        }
    }

    updateSyncHRFusionData = (parentId,nodeId,headingMap) => {
        //Now create an object
        let headingObj = headingMap[nodeId];
        headingObj.newChildren = [...headingObj.obsList];
        headingObj.fontbold = true;
        //Check the old function map
        if(!isEmptyVariable(this.state.headingMap[nodeId])){
            headingObj.isExpanded = this.state.headingMap[nodeId].isExpanded;
            headingMap[nodeId].isExpanded = this.state.headingMap[nodeId].isExpanded;
        }

        if(parentId !== nodeId){
            headingObj.parentId = parentId;
        }
        //now add children recursively
        headingObj.children.map((item)=>{
            headingObj.newChildren.push(this.updateSyncHRFusionData(nodeId,item.nodeId,headingMap));
        });
        return headingObj;
    }

    expandAll = () => {
        this.reference.expandAll();
    }

    collapseAll = () => {
        this.reference.collapseAll();
    }

    nodeExpanded = (args) =>{
        this.state.headingMap[args.nodeData.id].isExpanded = true;
    }

    nodeCollapsed = (args) =>{
        this.state.headingMap[args.nodeData.id].isExpanded = false;
    }

    getNodeType = (text) => {
        let type = "heading";

        let textArr = text.split("$_$");
        if(!isEmptyVariable(textArr[1])){
            type = textArr[1]
        }
        return type;
    }

    dragStop = (args)=>{
        console.log("Drag STOP Node: "+JSON.stringify(args.draggedNodeData))
        console.log("Drop STOP Node: "+JSON.stringify(args.droppedNodeData))
        console.log("Drop STOP Level: "+args.dropLevel)

        if(isEmptyVariable(args.droppedNodeData)){
            console.log("Drop node is empty. Operation not allowed");
            args.cancel = true;
            return;
        }

        if(isEmptyVariable(args.dropLevel)){
            console.log("Drop level is empty. Operation not allowed");
            args.cancel = true;
            return;
        }

        let draggedType = this.getNodeType(args.draggedNodeData?.text);

        let droppedType = this.getNodeType(args.droppedNodeData?.text);
        let droppedParentId = args.droppedNodeData.parentID;
        let droppedNodeId = args.droppedNodeData.id;

        let dropLevel = args.dropLevel;

        //Observation cannot have child
        if(droppedType==="observation"){
            let droppedNodeLevel = this.state.headingMap[droppedParentId].level+1;

            if(dropLevel > droppedNodeLevel){
                console.log("Child node of observation. Operation not allowed");
                args.cancel = true;
                return;
            }
        }

        //if the node is dropped above the parent node then cancel the drag
        if(args.dropLevel === 1){
            console.log("Multiple root level headings. Operation not allowed")
            args.cancel = true;
            return;
        }

        if(draggedType !== droppedType){
            //TODOSUHAS
            //we need to check if dropped type is not equal to dragged type,
            //and dropped type is observation, and if it is not last observation then disable.
            //same goes for heading
            
            //Check if observation is moved inside the heading
            if(droppedType==="heading"){
                let droppedNodeLevel = this.state.headingMap[droppedNodeId].level;
                if(dropLevel > droppedNodeLevel){
                    console.log("Child node of heading. Operation is allowed")
                    return;
                }
            }
            args.cancel = true;
        }

        
    }

    nodeDrop = (args) => {
        //when it comes to this both function drag type and drop type will be same.

        console.log("Drag Node: "+JSON.stringify(args.draggedNodeData))
        console.log("Drop Node: "+JSON.stringify(args.droppedNodeData))
        console.log("Drop Index: "+args.dropIndex);
        console.log("Drop Level: "+args.dropLevel)

        let draggedNodeId = "";
        let draggedNodePid = "";
        let droppedNodeType = "";

        let droppedNodeid = "";
        let droppedNodePid = "";
        let draggedNodeType = "";

        let droppedPos = "";
        let dropLevel = "";

        if(!isEmptyVariable(args.draggedNodeData) && !isEmptyVariable(args.droppedNodeData)){
            draggedNodeId = args.draggedNodeData.id;
            draggedNodePid = args.draggedNodeData.parentID;
            draggedNodeType = this.getNodeType(args.draggedNodeData.text);

            droppedNodeid = args.droppedNodeData.id;
            droppedNodePid = args.droppedNodeData.parentID;
            droppedNodeType = this.getNodeType(args.droppedNodeData.text);

            droppedPos = args.dropIndex + 1;
            dropLevel = args.dropLevel;

            if(draggedNodeType !== droppedNodeType){
                //then it is child node of heading. 
                //All other operations are stopped in dragStop method

                //call move observations
                this.moveObservation(draggedNodeId,droppedNodeid,droppedPos)
            }else{
                // based on the type call either move heading or move observation function

                if(draggedNodeType === "heading"){

                    //headings are displayed at last always, top nodes will be observations
                    //so we need to deduct the observation count in dropped pos
                    let parentHeadingObsCount = this.state.headingMap[droppedNodePid].obsList.length;
                    let newDroppedPos = droppedPos - parentHeadingObsCount;

                    let dropRelativeNodeLevel = this.state.headingMap[droppedNodeid].level;
                    //dropped level is greater than relative node level, 
                    //then it is a child of that relative node
                    if(dropLevel > dropRelativeNodeLevel){
                        droppedNodePid = droppedNodeid;
                        newDroppedPos = droppedPos;
                    }
                    
                    this.moveHeading(draggedNodeId,droppedNodePid,newDroppedPos)

                }else if(draggedNodeType === "observation"){
                    //if the dragged and dropped nodes are of the type observation
                    //then we need the heading Id, hence we are passing observation 
                    //parent Id which is heading
                    this.moveObservation(draggedNodeId,droppedNodePid,droppedPos)
                }else{
                    this.getFunctionTree();
                }
            }
        }
    }

    downloadReportDropdownClick = (item) => {
        if(item.format === Constants.REPORT_FORMAT_UPLOADED){
            this.setState({
                showUploadedReportListDialog:true,
                downloadReportPlaceholder:item.label,
            });

        }else{
            this.setState({
                downloadReportPlaceholder:item.label
            },()=>{
                this.handleDownloadReport(item);
            });
        }
    }

    generateBlobFromUrl = () => { 
        if(isEmptyVariable(this.state.companyDownloadReport.companyLogo)){
            return null;
        }
        return fetch(Constants.ImageBaseUrl+this.state.companyDownloadReport.companyLogo)
            .then(resp => resp.blob())
            .then(data=> data);
    }

    downloadFormatOneReportContent = async (
        observationTemplateFieldsAll,
        businessRiskMap
        ) => {
        //first lets initialise the styles
        let paraStyles = getParagraphStyles();

        let imgblob = this.generateBlobFromUrl();

        let firstPage = getFirstPageContent(
            this.state.companyDownloadReport?.clientName,
            this.state.companyDownloadReport?.unitNameCityList,
            this.state.companyDownloadReport?.unitAddressList,
            this.state.companyDownloadReport?.companyName,
            this.state.companyDownloadReport?.address1,
            this.state.companyDownloadReport?.address2,
            this.state.companyDownloadReport?.city,
            this.state.companyDownloadReport?.state,
            this.state.companyDownloadReport?.pinCode,
            imgblob
        );

        let headersContent = getHeaderContent(
            this.state.companyDownloadReport?.clientName,
            this.state.companyDownloadReport?.assignmentTypeName
        );
        let footersContent = getFooterContent(
            this.state.companyDownloadReport?.companyName
        );
        let assignementTableHeadingContent = getAssignementTableHeadingContent();

        let tableContent = getTableContent(
            this.state.companyDownloadReport?.assignmentTypeName,
            this.state.companyDownloadReport?.unitList,
            this.state.companyDownloadReport?.unitAddressList,
            this.state.companyDownloadReport?.assignmentArea,
            this.state.companyDownloadReport?.processList,
            this.state.companyDownloadReport?.functionList,
            this.state.companyDownloadReport?.checkList,
            this.state.companyDownloadReport?.memberList,
            this.state.companyDownloadReport?.L1UserName,
            this.state.companyDownloadReport?.startDate,
            this.state.companyDownloadReport?.endDate,
            this.state.companyDownloadReport?.reportDate,
            this.state.companyDownloadReport?.periodFromDate,
            this.state.companyDownloadReport?.periodToDate,
            this.state.companyDownloadReport?.reportRiskLevel
        );
        let executiveSummaryContent = getExecutiveSummaryContent(
            this.newObservationsArr
        );
        let ValueAdditiontemp = [];
        this.newObservationsArr.map((val) => {
            if (val.valueAdditions.length > 0) {
            ValueAdditiontemp.push(val.valueAdditions);
            }
        });
        let ValueAdditionContent = getValueAdditionContent(this.newObservationsArr);

        let observationsContent = getObservationContentWithTemplates(
            this.newObservationsArr,
            observationTemplateFieldsAll,
            businessRiskMap
        );

        let sectionTemp = [];
        sectionTemp.push({
            properties: {
            page: {
                borders: {
                pageBorderBottom: {
                    style: BorderStyle.SINGLE,
                    size: 3,
                    color: "auto",
                    space: 1,
                },
                pageBorderLeft: {
                    style: BorderStyle.SINGLE,
                    size: 3,
                    color: "auto",
                    space: 1,
                },
                pageBorderRight: {
                    style: BorderStyle.SINGLE,
                    size: 3,
                    color: "auto",
                    space: 1,
                },
                pageBorderTop: {
                    style: BorderStyle.SINGLE,
                    size: 3,
                    color: "auto",
                    space: 1,
                },
                },
            },
            },
            children: firstPage,
        });
        sectionTemp.push({
            headers: headersContent,
            footers: footersContent,
            children: [assignementTableHeadingContent, tableContent],
        });
        sectionTemp.push({
            children: executiveSummaryContent,
        });
        if (ValueAdditiontemp.length > 0) {
            sectionTemp.push({
            children: ValueAdditionContent,
            });
        }
        sectionTemp.push({
            children: observationsContent,
        });

        const doc = new Document({
            styles: {
            paragraphStyles: paraStyles,
            },
            sections: sectionTemp,
        });

        const tempAnnexturelist = this.getAllAnnexturesList();

        Packer.toBlob(doc).then((blob) => {
            var fZip = new jszip();
            Promise.all(
            tempAnnexturelist.map((item) => {
        
                return fetch(Constants.API_SERVER_PATH + item.path, {
                method: "GET",
                })
                .then((response) => response.blob())
                .then((blob) => {
                    fZip.file(item.name, blob);
                });
            })
            ).then(() => {
            fZip.file("report.doc", blob);
            fZip.generateAsync({ type: "blob" }).then((content) => {
                saveAs(content, "report.zip");
            });
            });
        });
    };
    
    getAllAnnexturesList = () => {
    let tempAnnexturelist = [];
    this.newObservationsArr.map((item) => {
        if (item.annexures.length > 0) {
        Object.values(item.annexures).map((annex) => {
            tempAnnexturelist.push({
            name: JSON.stringify(annex.documentPath)
                .split("_-_")[1]
                .replace('"', ""),
            path: annex.documentPath,
            });
        });
        }
    });

    return tempAnnexturelist;
    };

    downloadFormatTwoReportContent = (observationTemplateFieldsAll, businessRiskMap) =>{
		//first lets initialise the styles
		let paraStyles = getParagraphStyles();
        let imgblob = this.generateBlobFromUrl();
		
        let firstPage = getFirstPageContent(
			// this.state.companyDownloadReport?.clientName, 
			this.state.companyDownloadReport?.unitNameCityList                                                                                                                          , 
			this.state.companyDownloadReport?.unitAddressList,
			this.state.companyDownloadReport?.companyName,
			this.state.companyDownloadReport?.address1,
			this.state.companyDownloadReport?.address2,
			this.state.companyDownloadReport?.city,
			this.state.companyDownloadReport?.state,
			this.state.companyDownloadReport?.pinCode,
            imgblob
		);
		// let headersPageOneContent=  getSecondFormatHeaderContent(
		// 	this.state.companyDownloadReport?.clientName,
		// 	this.state.companyDownloadReport?.assignmentTypeName,
		// );
        let headersContent=  getHeaderContent(
			this.state.companyDownloadReport?.clientName,
			this.state.companyDownloadReport?.assignmentTypeName,
		);
		let footersContent=  getFooterContent(
			this.state.companyDownloadReport?.companyName
		);
        let assignementTableHeadingContent = getAssignementTableHeadingContent();


		let tableContent=  getTableContent(
			this.state.companyDownloadReport?.assignmentTypeName,
			this.state.companyDownloadReport?.unitList,
			this.state.companyDownloadReport?.unitAddressList,
			this.state.companyDownloadReport?.assignmentArea,
			this.state.companyDownloadReport?.processList,
			this.state.companyDownloadReport?.functionList,
			this.state.companyDownloadReport?.checkList,
			this.state.companyDownloadReport?.memberList,
			this.state.companyDownloadReport?.L1UserName,
			this.state.companyDownloadReport?.startDate,
			this.state.companyDownloadReport?.endDate,
			this.state.companyDownloadReport?.reportDate,
			this.state.companyDownloadReport?.periodFromDate,
			this.state.companyDownloadReport?.periodToDate,
			this.state.companyDownloadReport?.reportRiskLevel
		);
		
		let executiveSummaryContent = getExecutiveSummaryContent(this.newObservationsArr);
		
		let ValueAdditionContent = getValueAdditionContent(this.newObservationsArr);

        let observationsContent = getSecondFormatObservationsContentWithTemplates(this.newObservationsArr,
            observationTemplateFieldsAll,businessRiskMap);

		const doc = new Document({
			styles: {
				paragraphStyles: paraStyles
			},

			sections: [
				{
					headers:headersContent,
					footers:footersContent,		
					children:firstPage,
				},
				{
					children:[assignementTableHeadingContent,tableContent],
				},
				{
					children:executiveSummaryContent,
				},
				{
					children:ValueAdditionContent,
				},
				{
                    properties: {
                        page: {
                            size: {
                                orientation: PageOrientation.LANDSCAPE,
                            },
                        },
                    },
					children:observationsContent,
				}
			],
		});
		

		Packer.toBlob(doc).then((blob) => {
			saveAs(blob, "Report.docx");
		});
	}

    handleShowUploadReport = () =>{
        this.setState({
            showUploadReportDialog:true,
        })
    }

    handleUploadReportDialogClose = () =>{
        this.setState({
            showUploadReportDialog:false,
        })
    }
    
    handleUploadedReportListDialogClose = () =>{
        this.setState({
            showUploadedReportListDialog:false,
        })
    }

    handleShowDonotReportObservations = () =>{
        this.setState({
            ...resetLayoutFlags,
            showDonotReportObservations:true,
        })
    }

    hideDonotReportObservations = (reloadFlag) =>{
        this.setState({
            ...resetLayoutFlags,
            showObservationTree:true,
        },()=>{
            if(reloadFlag === true) this.getFunctionTree();
        })
    }

    showAssignmentTaskListLayout = () => {
        this.setState({
            ...resetLayoutFlags,
            showTaskList:true,
        })
    }

    hideAssignmentTaskListLayout = () => {
        this.setState({
            ...resetLayoutFlags,
            showObservationTree:true
        })
    }

    hideObservationDetails = () => {
        this.setState({
            ...resetLayoutFlags,
            showObservationTree:true,
            selectedId:"",
        })
    }
    /************************API CALLS **************************/
    HeadingTree = (headingObj) => {

        return <div className={headingObj.fontbold?"dialog-tree-structure-sync":"dialog-tree-structure-sync border-bottom"}>
        {
            !isEmptyVariable(headingObj) &&
                <div className="tree-node-sync">
                    {/* {
                        (isEmptyVariable(this.state.allowEdit) ||
                        this.state.allowEdit === "N") &&
                        <div className="tree-text-layout">
                            <p className={headingObj.fontbold?"font-bold":"m-t-0"}>
                                {removeHtmlTags(headingObj.reportHeadingText.split("$_$")[0])}
                            </p>
                        </div>
                    } */}
                    {
                        // this.state.allowEdit === "Y" &&
                        <div className="tree-dropdown-layout-sync">
                            <TreeNodeDropdown
                                // placeholder={headingObj.reportHeadingText.split("$_$")[0]}
                                placeholder={headingObj.reportHeadingText}
                                showResponseIcon={headingObj.responseCount > 0?true:false}
                                dropdownArr={
                                    headingObj.level === 1
                                    ?
                                    this.state.actionArrRoot
                                    :
                                    headingObj.type==="heading"?this.state.actionArr:(headingObj.observationType === "comment"?this.state.actionArrFreeText:this.state.actionArrObs)
                                }
                                labelParam={"label"}
                                onDropDownItemClick={this.onDropDownItemClick}
                                dropdownId={headingObj}
                                fontbold={headingObj.fontbold}
                                paraMT={"1.5px"}
                                themeSettings={themeSettings}
                            />
                        </div>
                    }
                    
                </div>
            }
        </div>
    }

    deleteActivateFunctionNode = () => {
        let url = "";
        let postParams = {};

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteDraftReportHeading;
            postParams = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                reportHeadingId:this.state.deleteReactivateId,
            }
        }else if(this.state.operationType === "Finalize Report"){
            url = Constants.FinalizeReport;
            postParams = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                assignmentId:this.state.assignmentObj?.assignmentId,
            }
        }

        this.setState({
            showBtnLoader:true,
        });

        fetch(url,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParams)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    deleteReactivateId:"",
                    operationType:"",
                    alertWithRadioDialogMessage:"",
                    showAlertWithRadioDialog:false,
                    clearAndResetFunctionTree:false,
                    showBtnLoader:false,
                },() => {
                    this.getFunctionTree();
                });
            }else{
                this.setState({
                    showBtnLoader:false,
                    deleteReactivateId:"",
                    operationType:"",
                    alertWithRadioDialogMessage:"",
                    showAlertWithRadioDialog:false,
                    clearAndResetFunctionTree:false,

                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                })
            }
        });
    }

    getFunctionTree = () => {
        this.setState({
            showLoader:true,
        });
        let url = "";
        let assignmentUrl = "";
        if(this.state.isInitDraftReport){
            url = Constants.GetInitDraftReportHeadings
            assignmentUrl = Constants.GetInitDraftReportDetails
        }else{
            url = Constants.GetDraftReportHeadings
            assignmentUrl = Constants.GetDraftAssignmentsDetails
        }

        Promise.all([
            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId:this.state.assignmentId
                })
            }),
            fetch(assignmentUrl,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId:this.state.assignmentId
                })
            }),
            fetch(Constants.GetUserMenu, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                }),
            }),
        ])
        .then(([response,assignmentRes,menuRes]) => { 
            return Promise.all([response.json(),assignmentRes.json(), menuRes.json()]);
        })
        .then(([data,assignmentRes, menuRes]) => {
            let companyTemp = {};
            let menusTemp = {};
            let assignmentObj = {};
            if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                menusTemp = menuRes.data.menus;
                companyTemp = menuRes.data.company;

            } else {
            }
            let menusTemp2 = GetOrderedMasterTabMenuReports(menusTemp);

            if (assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            } else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
                assignmentObj = assignmentRes.data.result;
            } else {
            }


            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let temp = data.result.headingMap;
                let rootFunctionArr = this.getRootLevelFunctions(temp);
                let rootNodeId = "";

                rootFunctionArr.map((rootNode)=>{
                    rootNodeId = rootNode.nodeId;
                    this.addParentIdandHasChildrenFlag(temp,rootNodeId,1,rootNodeId,"0")
                })

                let syncFusionData = [];
                //clearAndResetFunctionTree - if it is false, then copy the isShowingChildren param 
                //from old state array, this is required because whenever the user adds a new node at the nth level
                //all the nodes are collapsed and shows only first level nodes since the API is called again
                if(!isEmptyArray(Object.keys(this.state.headingMap)) && 
                !this.state.clearAndResetFunctionTree){
                    let headingObj = this.updateSyncHRFusionData(rootNodeId,rootNodeId,temp);
                    headingObj.fontbold = true;
                    syncFusionData.push(headingObj);
                }else{
                    //create datastructure and Expand root level node's children by default
                    let headingObj = this.createSyncHRFusionData(rootNodeId,rootNodeId,temp,true);
                    if(!isEmptyArray(Object.keys(temp))){
                        temp[rootNodeId].isExpanded = true;
                        headingObj.fontbold = true;
                        syncFusionData.push(headingObj);
                    }
                }
                //Action item arrays based on allowEdit flag
                let actionArrTemp = [];
                let actionArrRootTemp = [];
                let actionArrFreeTextTemp = [];
                let actionArrObsTemp = [];
                let allowEditFlag = (!isEmptyVariable(assignmentObj?.allowEdit) && assignmentObj.allowEdit === "Y")?"Y":"N";
                actionArrObsTemp = [ObsDetailsDropdownObj,ViewResponsesDropdownObj]

                if(allowEditFlag === "Y"){
                    actionArrTemp = [AddChildDropdownObj,AddSiblingDropdownObj,AddTextDropdownObj,editDropdownObj,delDropdownObj]
                    actionArrRootTemp = [AddChildDropdownObj,AddTextDropdownObj]
                    actionArrFreeTextTemp = [editTextDropdownObj]
                    actionArrObsTemp = [ObsDetailsDropdownObj,ViewResponsesDropdownObj]
                }

                //set the tree fields
                this.fields = {
                    dataSource:syncFusionData,
                    id:"nodeId",
                    text:"reportHeadingText",
                    parentID:"parentId",
                    child:"newChildren",
                    expanded:"isExpanded"
                };

                //check if observation template id is there or not
                let showOnlyTasks = false;
                if(isEmptyVariable(assignmentObj.observationTemplateId)){
                    showOnlyTasks = true;
                }


                this.setState({
                    headingMap:temp,
                    actionArr:actionArrTemp,
                    actionArrRoot:actionArrRootTemp,
                    actionArrFreeText:actionArrFreeTextTemp,
                    actionArrObs:actionArrObsTemp,
                    idToBeExpandedAfterAddingChild:"",
                    syncFusionData:syncFusionData,
                    rootNodeId:rootNodeId,
                    showLoader:false,
                    clearAndResetFunctionTree:false,

                    showTaskList:showOnlyTasks,
                    showObservationTree:!showOnlyTasks,
                    showOnlyTasks:showOnlyTasks,

                    menus: menusTemp2,
                    company:companyTemp,
                    assignmentObj:assignmentObj,
                    allowEdit:allowEditFlag,
                    userMenus: menusTemp,
                },()=>{
                    console.log("assiObj",assignmentObj)
                    if(!showOnlyTasks) this.reference.refresh();
                });
            }else{
                this.setState({
                    headingMap:{},
                    showLoader:false,
                    syncFusionData:[],
                    clearAndResetFunctionTree:false
                });
            }
        });
    }

    moveObservation = (draggedNodeId,droppedNodePid,droppedPos) => {
        fetch(Constants.MoveDraftObservation,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                rootHeadingId:this.state.rootHeadingId,
                observationId:draggedNodeId,
                
                toHeadingId:droppedNodePid,
                toChildOrder:droppedPos,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            } else {
                this.getFunctionTree();
            }
        });
    }

    moveHeading = (draggedNodeId,droppedNodePid,droppedPos) => {
        fetch(Constants.MoveDraftReportHeading,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                rootHeadingId:this.state.rootHeadingId,
                reportHeadingId:draggedNodeId,
                
                toHeadingId:droppedNodePid,
                toChildOrder:droppedPos,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            } else {
                this.getFunctionTree();
            }
        });
    }

    parseObservations = (nodeId, prefix, headingListPrev) => { //11,0 //14,1 ,15,2 //13,3, // 140,1.1 , 141,1.2
        let headingNode = this.newObservationsMap[nodeId];

        let isHeadingUsed = false;
        this.headingList = [...headingListPrev];

        if(prefix+"" !== "0"){
            this.headingList.push(prefix+". "+headingNode.heading);
        }

        //Add Index to each observation
        let newObsList = [];
        headingNode.obsList.forEach((item,idx)=>{
            if(prefix+"" === "0"){
                //root level
                item.observationHeading = (idx+1)+". "+item.observationHeading;
            }else{
                //heading is required only for first observation
                if(idx === 0){
                    isHeadingUsed = true;
                    item.reportCustomHeading = this.headingList.join(":--:");
                }
                item.observationHeading = prefix+"."+(idx+1)+". "+item.observationHeading;
            }
            newObsList.push(item);
        });
        headingNode.obsList = newObsList;
        this.newObservationsArr = [...this.newObservationsArr,...newObsList];

        if(isHeadingUsed){
            this.headingList = [];
        }

        //14.15.13 //14th children - 140,141
        headingNode.children.map((childNode,index)=>{
            let newPrefix = prefix+"" === "0"?(index+1):(prefix+"."+(index+1));
            this.parseObservations(childNode.nodeId,newPrefix,this.headingList)
        })
    }

    handleDownloadReport = (item) => {
		this.setState({
			showLoader: true,
			
		});
		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			assignmentId:this.props.match.params.id,
		};
        let url = "";
        if(item.label.includes(initFormatStandard) || item.label.includes(initFormatTabular) ){
            url = Constants.DownloadInitDraftReport;
        }else{
            url = Constants.DownloadDraftReport;
        }
		fetch(url, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParam),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
                    this.newObservationsArr = [];
                    this.newObservationsMap = data.data.observations;
                    let businessRiskMap = data.data.businessRiskMap;
                    let rootHeadings = this.getRootLevelFunctions(this.newObservationsMap);

                    //Template Fields
                    let observationTemplateFieldsStr = data.data.observationTemplateFields;
                    let observationTemplateFieldsLeftStr = data.data.observationTemplateFieldsLeft;

                    let observationTemplateFields = [];
                    if(!isEmptyVariable(observationTemplateFieldsStr) && isJsonString(observationTemplateFieldsStr)){
                        observationTemplateFields = JSON.parse(observationTemplateFieldsStr)
                    }
                    let observationTemplateFieldsLeft = [];
                    if(!isEmptyVariable(observationTemplateFieldsLeftStr) && isJsonString(observationTemplateFieldsLeftStr)){
                        observationTemplateFieldsLeft = JSON.parse(observationTemplateFieldsLeftStr)
                    }

                    let observationTemplateFieldsAll = [...observationTemplateFields,...observationTemplateFieldsLeft]

                    if(!isEmptyArray(rootHeadings)){                                                                                                                                                                        
                        this.parseObservations(rootHeadings[0].nodeId,0,[]);                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
                    }
					this.setState({
						showLoader: false,
						companyDownloadReport: data.data.company,
						observationsDownloadReport: this.newObservationsMap,
					},()=>{
                        if(item.format === Constants.REPORT_FORMAT_STANDARD){
                            this.downloadFormatOneReportContent(observationTemplateFieldsAll,businessRiskMap);
                        }else if(item.format === Constants.REPORT_FORMAT_TABULAR){
                            this.downloadFormatTwoReportContent(observationTemplateFieldsAll,businessRiskMap);
                        }
					});
				} else {
					this.setState({
						companyDownloadReport: "",
						observationsDownloadReport: "",
						showLoader: false,
						
					});
				}
			});

	};

    handleFilterDropdownClick = (selectedItem)=>{
        let tempShowObservationTree = false
        let tempShowTaskList = false
        let tempShowSelectRiskDialog= false
    
        if(selectedItem.label==="Tasks"){
            tempShowTaskList=true
            this.setState({
                filterPlaceholder:selectedItem.label,
                showTaskList:tempShowTaskList,
                showObservationTree:tempShowObservationTree,
                showSelectRiskDialog: tempShowSelectRiskDialog
            })
        }
        else if(selectedItem.label==="Working Papers"){
            window.location= Constants.URL_FINAL_WORKING_PAPERS +
                                  "/" +
                                  this.state.assignmentId
        }
        else if(selectedItem.label==="Timeline"){
            if(this.state.isInitDraftReport) {
                window.location= Constants.URL_INIT_DRAFT_TIMELINE + "/" + this.state.assignmentId


            }else{
                window.location= Constants.URL_FINAL_TIMELINE + "/" + this.state.assignmentId

            }
           
        }
        else if(selectedItem.label==="Auditee") {

            if(this.state.isInitDraftReport) {
                window.location= Constants.URL_INIT_DRAFT_ASSIGNMENTEMPLOYEE + "/" + this.state.assignmentId


            }else{
                window.location=Constants.URL_FINAL_ASSIGNMENTEMPLOYEE + "/" + this.state.assignmentId

            }

           
        }
        else if(selectedItem.label==="Sample Checked"){
            window.location=Constants.URL_FINAL_SAMPLE_CHECKED +
            "/" +
            this.state.assignmentId
        }
        else if(selectedItem.label==="Assign Overall Risk"){
            tempShowSelectRiskDialog=true
            tempShowTaskList=true
            this.setState({
                filterPlaceholder:selectedItem.label,
                showTaskList:tempShowTaskList,
                showObservationTree:tempShowObservationTree,
                showSelectRiskDialog: tempShowSelectRiskDialog
            })
        }else if(selectedItem.label==="Upload Report"){
            this.handleShowUploadReport();
        }else if(selectedItem.label==="Uploaded Report"){
            this.setState({
                showUploadedReportListDialog:true,
            });
        }
    }

    handleShowOverallAssignment = (event) => {
        event.preventDefault();
        let url = this.state.isInitDraftReport ? Constants.URL_INIT_DRAFT_OVERALL : Constants.URL_FINAL_OVERALL;
        window.location = url + "/" + this.state.assignmentId;
    }

    render() {
        //this allowEdit State variable must be set to Y by default or else the tree
        //will not work properly
        
        let dnd = false;
        return(
            <main className="main-dashboard">
                <section className="dashboard-bg">
                    <Sidebar />

                    <div className="dashboard-right-bg-collpase">
                        <div className="dashboard-wrapper">
                            <Topbar />
                            <div className="d-flex align-items-center">
                                <div className="flex-fill">
                                    <MasterMenuTabs
                                        assignmentId={this.state.assignmentId}
                                        masterMenus={this.state.menus}
                                        themeSettings={themeSettings}
                                        filterPlaceholder = {this.state.filterPlaceholder}
                                        dropdownOnClick = {this.handleFilterDropdownClick}
                                        observationTemplatedId ={(this.state.assignmentObj).observationTemplateId}
                                        filterDisplayFlag={true}
                                        userMenus={this.state.userMenus}
                                    />
                                </div>
                                <div>
                                    <TopmenuReports
                                        assignmentId={this.state.assignmentId}
                                        userDetails ={userDetails}
                                        themeSettings={themeSettings}
                                        company={this.state.company}
                                        isDraftOnward={true}
                                        isInitDraftOnward={this.state.isInitDraftReport}
                                    />
                                </div>
                            </div>

                            {
                                this.state.showObservationTree &&
                                <ExpandCollapseWrapper themeSettings={themeSettings}>
                                    <div className="expand-collapse-layout"
                                        onClick={this.expandAll}
                                        style={{marginRight:10}}
                                        type="button">
                                        <span class="material-icons">unfold_more</span>
                                        <p>Expand All</p>
                                    </div>
                                    <div
                                        className="expand-collapse-layout"
                                        onClick={this.collapseAll}
                                        style={{marginLeft:10}}
                                        type="button">
                                        <span class="material-icons">unfold_less</span>
                                        <p>Collapse All</p>
                                    </div>
                                </ExpandCollapseWrapper>
                            }
                            {
                                this.state.showLoader &&
                                <div class="loader"></div>
                            }
                            {
                                this.state.showObservationTree &&
                                <div className="row custom-row">
                                    <div className="col-md-3 custom-col m-t-10">
                                        <TaskTagButtonsLayout themeSettings={themeSettings}>
                                            <ul>
                                                <li>
                                                    <a href={this.state.isInitDraftReport
                                                        ?"/"+Constants.FINALIZEDOBSERVATIONS_PATH + "/" + this.state.assignmentId
                                                        :Constants.URL_COMPLETED_REPORTS + "/" + this.state.assignmentId}
                                                    >Back
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick ={this.showAssignmentTaskListLayout}>
                                                        Tasks
                                                    </a>
                                                </li>
                                                {
                                                    this.state.userMenus["Assignment Level Template"] && this.state.userMenus["Assignment Level Template"] !== "NONE" &&
                                                    !isEmptyVariable(this.state.assignmentObj?.assignmentLevelTemplateId) &&
                                                    <li>
                                                        <a href="#" onClick={this.handleShowOverallAssignment}>
                                                            Overall
                                                        </a>
                                                    </li>
                                                }
                                                <li>
                                                    <a href={(this.state.isInitDraftReport? Constants.URL_INIT_DRAFT_ASSIGNMENTEMPLOYEE :Constants.URL_FINAL_ASSIGNMENTEMPLOYEE) + "/" + this.state.assignmentId}>
                                                        Auditee
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={Constants.URL_FINAL_SAMPLE_CHECKED + "/" + this.state.assignmentId}>
                                                        Sample Checked
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={Constants.URL_FINAL_WORKING_PAPERS + "/" + this.state.assignmentId}>
                                                        Working Papers
                                                    </a>
                                                </li>
                                                <li>
                                                    <a href={
                                                        this.state.isInitDraftReport ? Constants.URL_INIT_DRAFT_TIMELINE+ "/" + this.state.assignmentId
                                                        : Constants.URL_FINAL_TIMELINE + "/" + this.state.assignmentId}>
                                                        Timeline
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={this.handleShowDonotReportObservations}>
                                                    Do not report Observations
                                                    </a>
                                                </li>
                                                <li>
                                                    <RegularDropdown 
                                                        placeholder={downloadReportPlaceholder}
                                                        dropdownArr={this.state.downloadReportArr}
                                                        labelParam="label"
                                                        onDropDownItemClick={this.downloadReportDropdownClick}
                                                        defaultPlaceholderDropDown={downloadReportPlaceholder}
                                                    />
                                                    {/* <a onClick={this.handleDownloadReport.bind(this,"Report Format Standard")} href="javascript:void(0)">
                                                        View Report 1
                                                    </a>
                                                </li>
                                                <li>
                                                    <a onClick={this.handleDownloadReport.bind(this,"Report Format Tabular")} href="javascript:void(0)">
                                                        View Report 2
                                                    </a> */}
                                                </li>
                                                <li>
                                                    <a onClick={this.handleShowUploadReport}>
                                                        Upload Report
                                                    </a>
                                                </li>
                                                {
                                                    !this.state.isInitDraftReport &&
                                                    <li>
                                                        <a  href={Constants.URL_INITIAL_DRAFT_REPORT + "/" + this.state.assignmentId}>
                                                            Draft Report
                                                        </a>
                                                    </li>
                                                }
                                            </ul>
                                        </TaskTagButtonsLayout>
                                    </div>
                                    <div className="col-md-9 custom-col">
                                        {
                                            this.state.isInitDraftReport &&
                                            <h5 className="init-draft-heading">Draft Report</h5>
                                        }
                                        <div style={{padding:"0px 20px 20px"}}
                                        className="card-col tree-node-bottom-border">
                                            <TreeViewComponent 
                                                fields={this.fields} 
                                                allowDragAndDrop={dnd}
                                                nodeTemplate={this.HeadingTree}
                                                ref = {(treeNode) => {this.reference = treeNode}}
                                                nodeDragStop={this.dragStop}
                                                nodeDropped = {this.nodeDrop}
                                                nodeExpanded = {this.nodeExpanded}
                                                nodeCollapsed = {this.nodeCollapsed}
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.showObservationDetails &&
                                <ObservationDetailsLayout
                                    observationId = {this.state.selectedId}
                                    assignmentId = {this.state.assignmentObj?.assignmentId}
                                    handleHideObsDetailsLayout = {this.hideObservationDetails}
                                    isDraftOnward = {true}
                                    isInitDraftReport = {this.state.isInitDraftReport}
                                />
                            }
                            {
                                this.state.showTaskList &&
                                <AssignmentTaskLayout
                                    assignmentId = {this.state.assignmentObj?.assignmentId}
                                    hideAssignmentTaskListLayout = {this.hideAssignmentTaskListLayout}
                                    themeSettings={themeSettings}
                                    isDraftOnward = {true}
                                    showOnlyTasks = {this.state.showOnlyTasks}
                                    allowEdit = {"N"}
                                    isInitDraftReport = {this.state.isInitDraftReport}
                                    location={this.props.location}
                                />
                            }
                            {
                                this.state.showDonotReportObservations &&
                                <DonotReportObservationsLayout
                                    assignmentId = {this.state.assignmentObj?.assignmentId}
                                    hideDonotReportObservationsLayout = {this.hideDonotReportObservations}
                                    isDraftOnward = {true}
                                    isInitDraftReport = {this.state.isInitDraftReport}
                                    isReportCompleted = {true}
                                />
                            }
                        </div>
                    </div>
                </section>

                <AddHeadingDialog
                    isEdit={this.state.isEdit}
                    showCreateDialog={this.state.showCreateDialog}
                    handleAddEditDialogClose={this.handleAddEditDialogClose}
                    createNodeType={this.state.createNodeType}
                    parentHeadingId={this.state.relativeFunctionId}
                    assignmentId={this.state.assignmentId}

                    selectedId={this.state.selectedId}
                    heading={this.state.heading}
                    themeSettings={themeSettings}
                    isDraftOnward = {true}
                />

                <AddTextDialog
                    isEdit={this.state.isEdit}
                    showCreateTextDialog={this.state.showCreateTextDialog}
                    handleAddEditTextDialogClose={this.handleAddEditTextDialogClose}
                    reportHeadingId={this.state.relativeFunctionId}
                    assignmentId={this.state.assignmentId}

                    selectedId={this.state.selectedId}
                    heading={this.state.heading}
                    themeSettings={themeSettings}
                    isDraftOnward = {true}
                />

                <AlertDialog
                    showAlertDialog={this.state.showAlertWithRadioDialog}
                    handleAlertDialogClose={this.handleAlertWithDialogDialogClose}
                    type= {
                        this.state.operationType === "Delete"
                        ?Constants.ALERT_TYPE_WARNING
                        :Constants.ALERT_TYPE_INFO
                    }
                    alertDialogHeading={
                        this.state.operationType === "Delete"
                        ?"Delete"
                        :"Create Draft"
                    }
                    alertDialogMessage={this.state.alertWithRadioDialogMessage}
                    proceedBtnClick={this.deleteActivateFunctionNode}
                    proceedBtnLabel={
                        this.state.operationType === "Delete"
                        ?"Delete"
                        :"Yes"
                    }
                    showLoader={this.state.showBtnLoader}
                    themeSettings={themeSettings}
                />

                <AlertDialog 
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type= {Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                    themeSettings={themeSettings}
                />

                <AddNewResponseDialog
                    showAddResponseDialog={this.state.showAddResponseDialog}
                    handleAddResponseDialogClose={this.handleAddResponseDialogClose}
                    observationId = {this.state.selectedId}
                    themeSettings={themeSettings}
                    isDraftOnward = {true}
                />

                <ViewResponseCommentDialog
                    showViewResponseDialog={this.state.showViewResponseDialog}
                    handleViewResponseDialogClose={this.handleViewResponseDialogClose}
                    observationId = {this.state.selectedId}
                    assignmentId = {this.state.assignmentId}
                    themeSettings={themeSettings}
                    location={this.props.location}
                    isDraftOnward = {true}
                    isReportCompleted = {true}
                />
                
                <UploadReportDialog
					showUploadReportDialog = {this.state.showUploadReportDialog}
					handleUploadReportDialogClose = {this.handleUploadReportDialogClose}
					themeSettings={themeSettings}
					// returnUploadedArray={this.returnUploadedArray}
					// type={"Annexure"}
					isDraftOnward = {this.props.isDraftOnward}
                    assignmentId = {this.state.assignmentId}
				/>

                <ListUploadedReportDialog
					showUploadedReportListDialog = {this.state.showUploadedReportListDialog}
					handleUploadedReportListDialogClose = {this.handleUploadedReportListDialogClose}
					themeSettings={themeSettings}
					// returnUploadedArray={this.returnUploadedArray}
					isDraftOnward = {this.props.isDraftOnward}
                    assignmentId = {this.state.assignmentId}
				/>


            </main>
        );
    }
}

export default HeadingTreeDialog;