import React, { Component } from 'react';
import * as Constants from '../../../../common/Global/constants';
import {SearchSpan,TableCheckBox,DialogSaveButton,FilterCheckBox,TableIcon} from '../../../../common/Global/globalStyles.style';
import FilterDropDown from '../../../../common/DropdownMenus/FilterDropdown';
import Pagination from '../../../../common/Global/pagination';
import {getLocalStorageVariables,isEmptyVariable,sortTable,truncateString,
    isEmptyArray,ifEmptyReturnEmptyStr,removeHtmlTags} from '../../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';

const sortObservationDetail = "observationDetail";
const sortRisk = "o.riskLevel";

const userDetails  = getLocalStorageVariables();
const inProgressStatus = "In Progress";

class SampleCheckedList extends Component{
    constructor(props){
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            resultSizePlaceholder:Constants.RESULT_SIZE,
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            sort:"",
            sortDir:"",

            selectedObservationMap:{},
            document_NR:"",

            showDetailsDialog:false,
            selectedItem:"",

            showObservationListing:true,
            selectedDocumentId:"",
            selectedDocumentName:"",
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showObservationDialog){
            // let obsMap = {};
            // this.props.selectedObservationIdArr.map((item)=>{
            //     obsMap[item.observationId] = item;
            // })
            this.setState({
                componentDidMountFlag:false,
                apiSearchKey:"",
                searchkey:"",
                currentPageNo: 1,
                showLoader:false,
                resultSize:Constants.RESULT_SIZE,
                totalCount:0,
                datalist:[],
                resultSizePlaceholder:Constants.RESULT_SIZE,
                resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
                sort:"",
                sortDir:"",
                selectedObservationMap:"",
                // document_NR:this.props.document_NR,

                showDetailsDialog:false,
                selectedItem:"",

                showObservationListing:true,
                
                selectedDocumentId:"",
                selectedDocumentName:"",
            },()=>{
                this.getInitData();
            })
            
        }
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getObservationList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getObservationList();
            })
        }
    }
    
    // handleCheck = (e) =>{
    //     let isChecked = e.target.checked;
    //     let name = e.target.name;

    //     if(isChecked){
    //         let datalist = this.state.datalist;
    //         datalist.map((item,idx)=>{
    //             datalist[idx].isSelected=false;
    //         });

    //         this.setState({
    //             [name]:"Y",
    //             selectedObservationMap:{},
    //             datalist:datalist
    //         })
    //     }else{
    //         this.setState({
    //             [name]:"N"
    //         })
    //     }
    // }

    handleDetailsDialogClose = () =>{
        this.setState({
            showDetailsDialog:false,
            selectedItem:"",
        });
    }

    handleDetailsDialogShow = (item) => {
        this.setState({
            showDetailsDialog:true,
            selectedItem:item,
        });
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getObservationList();
        })
    }

    // handleSelection = (e) => {
    //     e.stopPropagation();
    //     let isChecked = e.target.checked;
    //     let index = e.target.name;
    //     let selectedObsArrTemp = this.state.selectedObservationMap;
    //     let newDataList = this.state.datalist;

    //     if(isChecked){
    //         selectedObsArrTemp[this.state.datalist[index].observationId] = this.state.datalist[index];
    //     }else{
    //         delete selectedObsArrTemp[this.state.datalist[index].observationId]
    //     }
    //     newDataList[index].isSelected = isChecked;

    //     this.setState({
    //         datalist:newDataList,
    //         selectedObservationMap:selectedObsArrTemp
    //     },()=>{
    //         console.log("Selected  ",selectedObsArrTemp);
    //     });
    // }

    handleStopPropagation = (e) => {
        e.stopPropagation();
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getObservationList();
        })
    }

    // returnSelectedObservationId = () => {
    //     if(this.state.document_NR === "Y"){
    //         this.props.handleSelectObsDialogSaveClose([],this.state.document_NR);
    //     }else{
    //         let selectedObservationIdArr= [];
    //         let observationFormErrTemp = "";
    
    //         for (const [key, item] of Object.entries(this.state.selectedObservationMap)) {
    //             // if(item.isSelected){
    //                 selectedObservationIdArr.push(item.observationId);
    //             // }else{
    //             // }
    //         }
    
    //         if(isEmptyArray(selectedObservationIdArr)){
    //             observationFormErrTemp =  "Select atleast one observation";
    //             this.setState({
    //                 observationFormErr:observationFormErrTemp,
    //             })
    
    //             setTimeout(function(){
    //                 this.setState({
    //                     observationFormErr:"",
    //                 });
    //             }.bind(this),Constants.WRNG_MSG_TIMEOUT);
    //         }else{
    //             this.handleTagObservation(selectedObservationIdArr);

    //             // this.props.handleSelectObsDialogSaveClose(selectedObservationIdArr);
    //             // console.log("obs arr: ",JSON.stringify(selectedObservationIdArr))
    //         }
    //     }
        
    // }
    /*****************************API*********************************/
    getInitData = () => {
		this.setState({
			showLoader: true,
		});
        let masterUrl = "";
        let url = "";
        let params ={
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            // status:inProgressStatus,
            sort:this.state.sort,
            sortDir:this.state.sortDir,
            // departmentId:this.props.departmentId
            assignmentId:this.props.assignmentId
        }

        if(this.props.isDraftOnward === true){
            masterUrl = Constants.GetDraftObservations;
            url = Constants.GetDraftTaggedObservations;
        }else{
            masterUrl = Constants.GetObservations;
            url = Constants.GetTaggedObservations;
            params.status = inProgressStatus
        }

		Promise.all([
			fetch(masterUrl,
        {
            method:"POST",
            mode:"cors",
            body: new URLSearchParams(params)
        }),
			fetch(url, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentWorkingPaperId: this.props.assignmentWorkingPaperId,
				}),
			}),
		])
			.then(([masterRes,  taggedObsRes]) => {
				return Promise.all([masterRes.json(),  taggedObsRes.json()]);
			})
			.then(([masterRes, taggedObsRes]) => {
				let datalistTemp = [];
				let taggedObsTemp = [];
				let totalCountTemp = 0;
                let selectedObservationMap = {};

				if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
					datalistTemp = masterRes.data.observationList;
					totalCountTemp = masterRes.data.count;
                
				} else {
				}

				if (taggedObsRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || taggedObsRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (taggedObsRes.responseCode === Constants.CODE_SUCCESS) {
					taggedObsTemp = taggedObsRes.data.observations;
                   
                    taggedObsTemp.map((item)=>{
                        selectedObservationMap[item.observationId] = item;
                    })
                    datalistTemp.map((item,idx)=>{
                        if(!isEmptyVariable(selectedObservationMap[item.observationId])){
                            datalistTemp[idx].isSelected=true;
                        }
                    })
				} else {
				}
				

				this.setState({
					componentDidMountFlag: true,
					showLoader: false,
					datalist: datalistTemp,
					totalCount: totalCountTemp,
					apiSearchKey:this.state.searchkey,
                    
					selectedObservationMap:selectedObservationMap,
					
				});
			});
	};
    
    getObservationList = () => {
        this.setState({
            showLoader:true,
        });

        let url = "";
        let params ={
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            // status:inProgressStatus,
            sort:this.state.sort,
            sortDir:this.state.sortDir,
            // departmentId:this.props.departmentId
            assignmentId:this.props.assignmentId
        }
        if(this.props.isDraftOnward === true){
            url = Constants.GetDraftObservations;
        }else{
            url = Constants.GetObservations;
            params.status = inProgressStatus
        }
        
        fetch(url,
        {
            method:"POST",
            mode:"cors",
            body: new URLSearchParams(params)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let dataList = data.data.observationList;
                dataList.map((item,idx)=>{
                    if(!isEmptyVariable(this.state.selectedObservationMap[item.observationId])){
                        dataList[idx].isSelected=true;
                    }
                })

                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:dataList,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:[],
                    totalCount:0,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    // handleTagObservation = (selectedObservationIdArr) => {
    //     this.setState({
    //         showLoader:true
    //     })

    //     fetch(Constants.TagObservation, {
    //         method: "POST",
    //         mode: "cors",
    //         body: new URLSearchParams({
    //             email: userDetails.email,
    //             accessToken: userDetails.accessToken,
                
    //             observationIds:JSON.stringify(selectedObservationIdArr),
    //             assignmentWorkingPaperId:this.props.assignmentWorkingPaperId,
    //         }),
    //     })
    //     .then((response) => {
    //         return response.json();
    //     })
    //     .then((data) => {
    //         if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
    //             localStorage.clear();
    //             window.location = "/";
    //         } else if (data.responseCode === Constants.CODE_SUCCESS) {
    //             this.setState({
    //                 showLoader:false
    //             })
    //             this.props.handleSelectObsDialogSaveClose(true);
    //         } else {
    //             this.setState({
    //                 showLoader:false
    //             })
    //         }
    //     });
        
    // }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            let url = "";
            let params ={
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                // status:inProgressStatus,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
                // departmentId:this.props.departmentId
                assignmentId:this.props.assignmentId
            }

            if(this.props.isDraftOnward === true){
                url = Constants.GetDraftObservations;
            }else{
                url = Constants.GetObservations;
                params.status = inProgressStatus
            }

            fetch(url,
            {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams(params)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    let dataList = data.data.observationList
                    dataList.map((item,idx)=>{
                        if(!isEmptyVariable(this.state.selectedObservationMap[item.observationId])){
                            dataList[idx].isSelected=true;
                        }
                    })

                    this.setState({
                        showLoader:false,
                        componentDidMountFlag:true,
                        datalist:dataList,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        showLoader:false,
                        componentDidMountFlag:true,
                        datalist:[],
                        totalCount:0,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    render(){
        return(
            <section>
                <Modal className="task-select-table-dialog custom-dialog"
                    show={this.props.showObservationDialog} 
                    onHide={this.props.handleSelectObsDialogClose}>
                    <Modal.Header>
                        <h5>Selected Observations</h5>
                        <button 
                            onClick={this.props.handleSelectObsDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        {
                            this.state.showLoader &&
                            <div class="loader"></div>
                        }
                        {
                            // Need to check component did mount flag, other wise until api is called,
                            // it shows no items found layout.
                            this.state.showObservationListing &&
                            this.state.componentDidMountFlag &&
                            <div className="body-wrapper">
                                <div className="search-addnew-bg" style={{marginTop:0}}>
                                    <div className="search-addnew">
                                        
                                        <div className="search-filter-layout" style={{position:"relative"}}>
                                            <FilterDropDown
                                                placeholder={this.state.resultSizePlaceholder}
                                                dropdownArr={this.state.resultSizeArr}
                                                onDropDownItemClick={this.resultSizeDropdownClick}
                                                addBorder={true}
                                                themeSettings={this.props.themeSettings}
                                                mleft={0}
                                            />
                                            <div className="search-col m-l-10" style={{border:"1px solid #e5e5e5"}}>
                                                <input 
                                                    name="searchkey" 
                                                    type="text"
                                                    onChange={this.handleChangeSearch}
                                                    placeholder="Search" 
                                                    onKeyPress={this.onEnterBtnPress}
                                                    value={this.state.searchkey}
                                                />
                                                <SearchSpan 
                                                themeSettings={this.props.themeSettings}
                                                onClick = {this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                                            </div>

                                            
                                            {/* <div className="overlay-nr" style={{display:this.state.document_NR === "Y"?"block":"none"}}></div> */}
                                        </div>
                                        {/* <FilterCheckBox themeSettings={this.props.themeSettings}
                                        style={{marginLeft:0}}>
                                            <input 
                                                type="checkbox"
                                                name="document_NR"
                                                id="document_NR"
                                                onChange={this.handleCheck}
                                                checked={this.state.document_NR === "Y"?true:false}
                                            />
                                            <label for="document_NR">Not Required</label>
                                        </FilterCheckBox> */}
                                    </div>
                                </div>
                                
                                <div style={{position:"relative"}}>
                                    {
                                        !isEmptyArray(this.state.datalist) && 
                                        <div className="common-tab-bg">
                                            <div className="common-table" style={{width:"100%",border:"1px solid #e5e5e5"}}>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center" width={"8%"}></th>
                                                            <th className="text-center" width={"5%"}>No.</th>
                                                            <th  className="c-pointer" onClick={this.sortTableLocal.bind(this, sortObservationDetail)}>
                                                                <div className="sort-header">
                                                                    Observation
                                                                    <span className={
                                                                        this.state.sort === sortObservationDetail ? "material-icons" : "material-icons hide-sort-arrow"
                                                                    }
                                                                    >
                                                                        {this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
                                                                    </span>
                                                                </div>
                                                            </th>
                                                            {this.props.isRisk &&
                                                                <th width={"25%"} className="c-pointer" onClick={this.sortTableLocal.bind(this, sortRisk)}>
                                                                    <div className="sort-header">Risk
                                                                        <span className={
                                                                                this.state.sort === sortRisk ? "material-icons" : "material-icons hide-sort-arrow"
                                                                            }
                                                                        >
                                                                            {this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
                                                                        </span>
                                                                    </div>
                                                                </th>
                                                            }
                                                            {/* <th className="text-center"  width={"10%"}>Type</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        !isEmptyArray(this.state.datalist) && 
                                                        this.state.datalist.map((item,idx) => {

                                                            return <tr onClick={this.handleDetailsDialogShow.bind(this,item)}>
                                                                <td>
                                                                    <TableCheckBox onClick={this.handleStopPropagation}
                                                                        themeSettings={this.props.themeSettings}>
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={idx}
                                                                            id={item.observationId}
                                                                            // onChange={this.handleSelection}
                                                                            checked={item.isSelected?true:false}
                                                                        />
                                                                        <label for={item.observationId} 
                                                                        style={{marginLeft:6}}></label>
                                                                    </TableCheckBox>
                                                                </td>
                                                                <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                                <td>{removeHtmlTags(item.observationDetail)}</td>
                                                                <td>{item.riskLevel}</td>
                                                            </tr>
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                        <div class="no-items-layout">
                                            <div class="no-items-card">
                                                <h6>
                                                {
                                                    isEmptyVariable(this.state.apiSearchKey)?
                                                    Constants.NO_RECORDS_WARNING
                                                    :
                                                    Constants.EMPTY_SEARCH_WARNING
                                                }
                                                </h6>
                                            </div>
                                        </div>
                                    }
                                    <div className="pagination-layout">
                                        <div className="row custom-row">
                                            <div className="col-md-9 custom-col">
                                            {
                                                (this.state.totalCount > this.state.datalist.length) && 
                                                <Pagination 
                                                    totalLength ={this.state.totalCount} 
                                                    items={this.state.datalist} 
                                                    onChangePage={this.onChangePage} 
                                                    pageSize={this.state.resultSize}
                                                    currentPageNo = {this.state.currentPageNo}
                                                    initialPage={this.state.currentPageNo}
                                                    themeSettings={this.props.themeSettings} />
                                            }
                                            </div>
                                            <div className="col-md-3 custom-col">
                                                <p>{"Total Records: "+this.state.totalCount}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="overlay-nr" style={{display:this.state.document_NR === "Y"?"block":"none"}}>
                                    </div> */}
                                </div>

                                {/* {
                                    !isEmptyVariable(this.state.observationFormErr) &&
                                    <span className="cm-error">{this.state.observationFormErr}</span>
                                } */}
                                <div className="modal-close-save m-t-15">
                                    <div className="close-save-col">
                                        <button onClick={this.props.handleSelectObsDialogClose} type="button" 
                                        className="modal-close m-b-0">Close</button>
                                        {/* <DialogSaveButton  themeSettings={this.props.themeSettings} 
                                        onClick={this.returnSelectedObservationId} type="button" 
                                        className="modal-save m-b-0">Save</DialogSaveButton> */}
                                    </div>
                                </div>
                            </div>
                        }
                        
                    </div>
                </Modal>
                <Modal className="custom-dialog" show={this.state.showDetailsDialog} onHide={this.handleDetailsDialogClose}>
                    <Modal.Header>
                        <h5>{this.state.selectedItem.shortName}</h5>
                        <button 
                            onClick={this.handleDetailsDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div class="modal-body">
                        <div className="details-table-layout">
                            <div className="row custom-row">
                                <div className="col-md-12 custom-col">
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td width={"24%"}>Short Name</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.shortName}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Name</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.name}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Description</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{ifEmptyReturnEmptyStr(this.state.selectedItem.description,"-")}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Type</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.documentType}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Sample List</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.sampleList}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Total Size Value</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.totalSizeValue}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Total Size Number</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.totalSizeNumber}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Sample Checked Value</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.sampleCheckedValue}</td>
                                            </tr>
                                            <tr>
                                                <td width={"24%"}>Sample Checked Number</td>
                                                <td width={"4%"}>:</td>
                                                <td width={"72%"}>{this.state.selectedItem.sampleCheckedNumber}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
            </section>
        )
    }
}

export default SampleCheckedList;