import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import SpinnerLoader from 'react-loader-spinner';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import {getThemeSettingsVariables, isEmptyVariable} from '../../common/Global/commonFunctions';
import AlertDialog from '../../common/AlertDialog';
let themeSettings  = getThemeSettingsVariables();

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password:"",
            confirmPassword:"",
            fromSubmitted:false,
            errors:[],
            isSuccess:false,
            componentDidMount:false,
            heading:"",
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        }

    }

    componentDidMount()
    {
        let code = window.location.href.split("/").pop();
        fetch(Constants.Checkforgotpasswordcode,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                code:code
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === 0){
                this.setState({
                    isSuccess:true,
                    componentDidMount:true,
                    heading:"Reset Your Password"
                })
            }else{
                this.setState({
                    isSuccess:false,
                    componentDidMount:true,
                    heading:"Invalid password reset code or link expired"
                })
            }
        });
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    validateForm()
    {
        let error_flag = false;
        let errors = {};
        if(isEmptyVariable(this.state.password))
        {
            error_flag = true;
            errors['password'] = "Please enter password!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        if(isEmptyVariable(this.state.confirmPassword))
        {
            error_flag = true;
            errors['confirmPassword'] = "Please confirm password!";
            setTimeout(function(){
                this.setState({errors:{}});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }
        if(this.state.password !== this.state.confirmPassword)
        {
            error_flag = true;
            errors['confirmPassword'] = "Please enter confirm password same as above!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }

        this.setState({
            errors: errors
        });
        return error_flag;
    }

    resetPassword = () => {
        this.setState({
            formSubmitted:true,
        });

        if(!this.validateForm())
        {
            let code = window.location.href.split("/").pop();
            fetch(Constants.Resetpassword,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    code:code,
                    password:this.state.password
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {

                if(data.responseCode === 0){
                    this.setState({
                        formSubmitted:false,
                        password:"",
                        confirmPassword:"",
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage + ". Redirecting to login page."
                    });
    
                    setTimeout(function(){
                        this.setState({errors:[]});
                        window.location = "/";
                    }.bind(this),Constants.WRNG_MSG_TIMEOUT);
                }else{
                    this.setState({
                        formSubmitted:false,
                        password:"",
                        confirmPassword:"",
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    });
                }
            });
        }else{
            setTimeout(function(){
                this.setState({errors:[]});
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);

           this.setState({
                formSubmitted:false,
            });
        }
    }

    render(){
        return(
            <Router>
                <main className="login-bg">
                    <div className="login-wrapper">
                        <div className="login-wrapper-col">

                            {/* <div className="login-logo">
                                <img src="/assets/images/sameeksha.png" />
                            </div> */}

                                <div className="login-form">
                                    <div className="login-form-logo">
                                        <img src="/assets/images/sameeksha.png" />
                                        <h6>{this.state.heading}</h6>
                                    </div>
                                    {
                                        this.state.isSuccess &&
                                        <div>
                                            <div className="login-form-col">
                                                <div className="login-input-group">
                                                    <div className="login-field">
                                                        <span className="material-icons">person</span>
                                                        <input type="password" placeholder="Password" onChange={this.handleChange}
                                                        name="password" value={this.state.password}/>
                                                    </div>
                                                    <span className="errorspan-login">{this.state.errors.password}</span>
                                                </div>
                                        
                                                <div className="login-input-group">
                                                    <div className="login-field">
                                                        <span className="material-icons">lock</span>
                                                        <input type="password" placeholder="Confirm Password" onChange={this.handleChange}
                                                        name="confirmPassword" value={this.state.confirmPassword}/>
                                                    </div>

                                                    <span className="errorspan-login">{this.state.errors.confirmPassword}</span>
                                                </div>

                                                <div className="login-input-group">
                                                    <div className="login-button">
                                                        <button 
                                                            type="button" 
                                                            disabled={this.state.formSubmitted} 
                                                            onClick={this.resetPassword}>
                                                            Change Password
                                                            {
                                                                this.state.formSubmitted &&
                                                                <SpinnerLoader style={{ marginLeft:10 }} 
                                                                type={Constants.LOADER_TYPE} color={Constants.LOADER_COLOR} height={20} width={30} />
                                                            }
                                                        </button>
                                                    </div>
                                                    {/* <span className="errorspan-login">{this.state.errors.respMsg}</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    }
                            </div>
                        </div>
                    </div>
                    <AlertDialog
                        showAlertDialog={this.state.showAlertDialogInfo}
                        handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                        type={Constants.ALERT_TYPE_ALERT}
                        alertDialogMessage={this.state.alertDialogMessageInfo}
                        proceedBtnClick={this.handleAlertDialogCloseInfo}
                        proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                        themeSettings={themeSettings}
                    />
                </main>
            </Router>
        );
    }
}

export default ResetPassword;