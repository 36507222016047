import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable, isEmptyArray} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import RegularDropdown from '../../common/DropdownMenus/RegularDropdown';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import "@syncfusion/ej2-base/styles/material.css";
import "@syncfusion/ej2-buttons/styles/material.css";
import "@syncfusion/ej2-inputs/styles/material.css";
import "@syncfusion/ej2-react-dropdowns/styles/material.css";
import {DialogSaveButton} from '../../common/Global/globalStyles.style';
import ReactSelectDropdown from '../../common/DropdownMenus/ReactSelectDropdownWithSearch';
import AlertDialog from '../../common/AlertDialog';
const userDetails  = getLocalStorageVariables();
const salutationPlaceholderText = "Select";
const unitPlaceholderText = "Select Unit";

const designationPlaceholderText = "Select Designation";

class AddEmployeeDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,

            salutation:salutationPlaceholderText,
            firstName:"",
            middleName:"",
            lastName:"",
            email:"",
            phone:"",
            designation:designationPlaceholderText,
            designationArr:[],
            isPOC:"",
            mobileNo:"",
            unitPlaceholder:unitPlaceholderText,
            unitArr:[],
            selectedUnitIds:[],
            formErrors:{},
            selectedDesignationObj:{},
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        }
    }



    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showAddEmployeeDialog){

            if(this.props.isEdit){
                let unitIds = isEmptyArray(this.props.selectedItem.units)?[]:Object.values(this.props.selectedItem.units)
                let designationObj = isEmptyArray(this.props.designationArr)?[]:Object.values(this.props.designationArr).filter(e=>e.name==this.props.selectedItem.designation)||{}
                this.setState({
                    selectedUnitIds:unitIds.map(e=>e.unitId) ||[],
                    unitArr:this.props.unitArr,
                    salutation:this.props.selectedItem.salutation,
                    firstName:this.props.selectedItem.firstName,
                    lastName:this.props.selectedItem.lastName,
                    middleName:this.props.selectedItem.middleName,
                    email:this.props.selectedItem.employeeEmail,
                    selectedDesignationObj:designationObj,
                    designationArr:this.props.designationArr,
                    designation:this.props.selectedItem.designation,
                    mobileNo:this.props.selectedItem.mobileNo,
                    phone:this.props.selectedItem.phoneNo,
                    componentDidMountFlag:true
                })

            }else{
                this.setState({
                    unitArr:this.props.unitArr,
                    designationArr:this.props.designationArr,
                    componentDidMountFlag:true,
                    salutation:"",
                    firstName:"",
                    middleName:"",
                    lastName:"",
                    email:"",
                    phone:"",
                    mobileNo:"",
                    designation:"",
                    selectedDesignationObj:{},
                    formErrors:{},
                    selectedUnitIds:[],
                })
    
            }

        }
    }

    salutionDropdownClick = (item) => {
        this.setState({
            salutation:item.name,
        })
    }

    designationDropdownClick = (item) => {
        this.setState({
            designation:item.name,
            selectedDesignationObj:item
        })
    }

    addSelection = (args) => {
        if(!isEmptyVariable(args.itemData.unitId))
        {
            this.state.selectedUnitIds.push(args.itemData.unitId)
        }

        let formErr = this.state.formErrors;
        formErr.unitPlaceholder = "";
        this.setState({
            selectedUnitIds:this.state.selectedUnitIds,
            formErrors:formErr
        })
    }


    removeSelection = (args) => {
        if(!isEmptyVariable(args.itemData.unitId)){
            let idx = this.state.selectedUnitIds.indexOf(args.itemData.unitId);
            if(idx !== -1){
                this.state.selectedUnitIds.splice(idx,1);
            }
        }
        this.setState({
            selectedUnitIds:this.state.selectedUnitIds
        })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleCheck = (e) =>{
        let name = e.target.name;

        if(name === "isPOCY"){
            this.setState({
                isPOC:"Y"
            })
        }else if(name === "isPOCN"){
            this.setState({
                isPOC:"N"
            })
        }else{
            this.setState({
                isPOC:""
            })
        }
    }

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
    }

    createEmployee = () => {
        this.setState({
            showLoader:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.salutation) || this.state.salutation === salutationPlaceholderText){
            isError = true;
            FormErrorsTemp['salutation'] = "Please select salutation";
        }

        if(isEmptyVariable(this.state.firstName)){
            isError = true;
            FormErrorsTemp['firstName'] = "Please enter first name";
        }

        if(isEmptyVariable(this.state.lastName)){
            isError = true;
            FormErrorsTemp['lastName'] = "Please enter last name";
        }

        if(isEmptyVariable(this.state.email)){
            isError = true;
            FormErrorsTemp['email'] = "Please enter email";
        }

        if(isEmptyVariable(this.state.designation) || this.state.designation === designationPlaceholderText){
            isError = true;
            FormErrorsTemp['designation'] = "Please select designation";
        }

        if(!isEmptyVariable(this.state.email))
        {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if(!pattern.test(this.state.email)) {
                isError = true;
                FormErrorsTemp["email"] = "Please enter valid email";
            }
        }
        
        if(!isEmptyVariable(this.state.phone)){
            var regexp = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
            if(!regexp.test(this.state.phone)) {
                isError = true;
                FormErrorsTemp['phone'] = "Please enter valid phone number";
            }
        }

        if(!isEmptyVariable(this.state.mobileNo)){
            var regexp = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/);
            // var regexp = new RegExp(/^(?=.*[0-9])[+ 0-9]+$/);
            if(!regexp.test(this.state.mobileNo)) {
                isError = true;
                FormErrorsTemp['mobileNo'] = "Please enter valid mobile number";
            }
        }

        if(isEmptyArray(this.state.selectedUnitIds) && this.props.isShowUnit =="Y"){
            isError = true;
            FormErrorsTemp['unitPlaceholder'] = "Please select unit";
        }
    

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

            setTimeout(function(){
                this.setState({
                    formErrors:{},
                });
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }else{
           

            let postParam = {
                salutation:this.state.salutation,
                firstName:this.state.firstName,
                middleName:this.state.middleName,
                lastName:this.state.lastName,
                phoneNo:this.state.phone,
                mobileNo:this.state.mobileNo,
                designation:this.state.designation,
                isHOD: "N",
                employeeEmail:this.state.email,
            }

            if(this.props.isShowUnit=="N"){
                postParam.assignmentId = this.props.assignmentId
            }

            let url = '';
            if(this.props?.isDraftOnward && this.props.isDraftOnward.url == '/draftassignmentemployee'){
                postParam.assignmentUnitId = this.props.assignmentUnitId[0]
                url = Constants.CreateDraftEmployee
            }else if(this.props.isEdit){
                postParam.employeeId = this.props.selectedItem.employeeId
                url = Constants.EditEmployee;
            }else{
                url = Constants.CreateEmployee
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    data:JSON.stringify(postParam),
                    units:JSON.stringify(this.props.isShowUnit=="Y"?this.state.selectedUnitIds:this.props.assignmentUnitId),
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        salutation:"",
                        firstName:"",
                        middleName:"",
                        lastName:"",
                        email:"",
                        phone:"",
                        mobileNo:"",
                        designation:"",
                        selectedDesignationObj:"",
                        formErrors:{},
                        selectedUnitIds:[]
                    },() => {
                        this.props.handleAddEmployeeDialogClose(true);
                    });
                }else{
                    this.setState({
                        showLoader:false,
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    })
                }
            });
        }
    }

    handleAddEmployeeDialogClose = () =>{

        this.setState({
            salutation:"",
            firstName:"",
            middleName:"",
            lastName:"",
            email:"",
            phone:"",
            mobileNo:"",
            designation:"",
            selectedDesignationObj:{},
            formErrors:{},
            selectedUnitIds:[],

        },()=>{
            this.props.handleAddEmployeeDialogClose()
        })

    }

    render() {
        let fields = {text:"unitName",value:"unitId"}
        return(
            <>
            <Modal enforceFocus={false} className="add-employee-dialog custom-dialog" 
            show={this.props.showAddEmployeeDialog} onHide={this.handleAddEmployeeDialogClose}>
                <Modal.Header>
                    <h5>{this.props.isEdit?"Edit Employee":"Add Employee"}</h5>
                    <button 
                        onClick={this.handleAddEmployeeDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>
                <hr />
                <div className="modal-body">
                    { this.state.componentDidMountFlag &&
                        <>
                            <div className="addnew-modal-form">
                                <div className="row custom-row">
                                    <div className="col-md-2 custom-col">
                                        <div className="addnew-modal-form-group">
                                            <p>Salutation*</p>
                                            <RegularDropdown 
                                                placeholder={this.state.salutation}
                                                dropdownArr={Constants.SALUTATION_ARR}
                                                labelParam="name"
                                                onDropDownItemClick={this.salutionDropdownClick}
                                                isFocusRequired={true}
                                                defaultPlaceholderDropDown={salutationPlaceholderText}
                                            />
                                            {
                                                !isEmptyVariable(this.state.formErrors['salutation']) &&
                                                <span className="cm-error">{this.state.formErrors['salutation']}</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-4 custom-col">
                                        <div className="addnew-modal-form-group">
                                            <p>First Name*</p>
                                            <input type="text" placeholder="Enter First Name" 
                                            name="firstName" value={this.state.firstName} onChange={this.handleChange}/>
                                            {
                                                !isEmptyVariable(this.state.formErrors['firstName']) &&
                                                <span className="cm-error">{this.state.formErrors['firstName']}</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-3 custom-col">
                                        <div className="addnew-modal-form-group">
                                            <p>Middle Name</p>
                                            <input type="text" placeholder="Enter First Name" 
                                            name="middleName" value={this.state.middleName} onChange={this.handleChange}/>
                                            {
                                                !isEmptyVariable(this.state.formErrors['middleName']) &&
                                                <span className="cm-error">{this.state.formErrors['middleName']}</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-3 custom-col">
                                        <div className="addnew-modal-form-group">
                                            <p>Last Name*</p>
                                            <input type="text" placeholder="Enter Last Name" 
                                            name="lastName" value={this.state.lastName} onChange={this.handleChange}/>
                                            {
                                                !isEmptyVariable(this.state.formErrors['lastName']) &&
                                                <span className="cm-error">{this.state.formErrors['lastName']}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row custom-row">
                                    <div className="col-md-6 custom-col">
                                        <div className="addnew-modal-form-group">
                                            <p>Phone Number</p>
                                            <input type="text" placeholder="Enter Phone Number"
                                            name="phone" value={this.state.phone} onChange={this.handleChange}/>
                                            {
                                                !isEmptyVariable(this.state.formErrors['phone']) &&
                                                <span className="cm-error">{this.state.formErrors['phone']}</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-6 custom-col">
                                        <div className="addnew-modal-form-group">
                                            <p>Mobile Number</p>
                                            <input type="text" placeholder="Enter Mobile Number"
                                            name="mobileNo" value={this.state.mobileNo} onChange={this.handleChange}/>
                                            {
                                                !isEmptyVariable(this.state.formErrors['mobileNo']) &&
                                                <span className="cm-error">{this.state.formErrors['mobileNo']}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row custom-row">
                                    <div className="col-md-6 custom-col">
                                        <div className="addnew-modal-form-group">
                                            <p>Email*</p>
                                            <input type="text" placeholder="Enter Email"
                                            name="email" value={this.state.email} onChange={this.handleChange}/>
                                            {
                                                !isEmptyVariable(this.state.formErrors['email']) &&
                                                <span className="cm-error">{this.state.formErrors['email']}</span>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-6 custom-col">
                                        <div className="addnew-modal-form-group">
                                            <p>Designation*</p>
                                        { !this.state.showLoader && 
                                            <ReactSelectDropdown
                                                selectedDDObj= {this.state.selectedDesignationObj}
                                                handleReactSelectClose={this.designationDropdownClick}
                                                selectDropdownArr={this.state.designationArr}
                                                label={"name"}
                                                value={"designationId"}
                                            />
                                            }
                                            {
                                                !isEmptyVariable(this.state.formErrors['designation']) &&
                                                <span className="cm-error">{this.state.formErrors['designation']}</span>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row custom-row">
                                    { this.props.isShowUnit =="Y" &&
                                        <div className="col-md-6 custom-col">
                                            <div className="addnew-modal-form-group">
                                                <p>Unit*</p>
                                                <MultiSelectComponent 
                                                    id="functionCheckbox"
                                                    dataSource={this.state.unitArr}
                                                    fields={fields}
                                                    placeholder={unitPlaceholderText} 
                                                    mode="CheckBox"
                                                    closePopupOnSelect={false}
                                                    allowFiltering={true}
                                                    select={this.addSelection}
                                                    removed={this.removeSelection}
                                                    cssClass="clientsChecklist"
                                                    value={this.state.selectedUnitIds}
                                                    showDropDownIcon={true}
                                                    showClearButton={false}
                                                >
                                                    <Inject services={[CheckBoxSelection]} />
                                                </MultiSelectComponent>
                                                {
                                                    !isEmptyVariable(this.state.formErrors['unitPlaceholder']) &&
                                                    <span className="cm-error">{this.state.formErrors['unitPlaceholder']}</span>
                                                }
                                            </div>
                                        </div>
                                        }


                                    
                                    {/* <div className="col-md-6 custom-col">
                                        <div className="addnew-modal-form-group">
                                            <p>Is POC*</p>
                                            <div className="radio-group">
                                                <div className="radio-btn">
                                                    <input 
                                                        type="radio"
                                                        name="isPOCY"
                                                        id="isPOCY"
                                                        onChange={this.handleCheck}
                                                        checked={this.state.isPOC === "Y"?true:false}
                                                    />
                                                    <label for="isPOCY">Yes</label>
                                                </div>
                                                <div className="radio-btn" style={{marginLeft:"10px"}}>
                                                    <input 
                                                        type="radio"
                                                        name="isPOCN"
                                                        id="isPOCN"
                                                        onChange={this.handleCheck}
                                                        checked={this.state.isPOC === "N"?true:false}
                                                    />
                                                    <label for="isPOCN">No</label>
                                                </div>
                                            </div>
                                            {
                                                !isEmptyVariable(this.state.formErrors['isPOC']) &&
                                                <span className="cm-error">{this.state.formErrors['isPOC']}</span>
                                            }
                                        </div>
                                    </div> */}
                                </div>
                                {/* {
                                    !isEmptyVariable(this.state.formErrors['apiErr']) &&
                                    <span className="cm-error">{this.state.formErrors['apiErr']}</span>
                                } */}
                            </div>
                            
                            <div className="modal-close-save">
                                <div className="close-save-col">
                                    <button onClick={this.handleAddEmployeeDialogClose} type="button" className="modal-close">Cancel</button>
                                    <DialogSaveButton themeSettings={this.props.themeSettings}  onClick={this.createEmployee} type="button" className="modal-save">
                                        {this.state.showLoader ? <div class="loader-btn mt-2"></div> : 'Save'}
                                    </DialogSaveButton>
                                </div>
                            </div>
                        </>
                    
                    }
                </div>
            </Modal>
            <AlertDialog
                showAlertDialog={this.state.showAlertDialogInfo}
                handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                type={Constants.ALERT_TYPE_ALERT}
                alertDialogMessage={this.state.alertDialogMessageInfo}
                proceedBtnClick={this.handleAlertDialogCloseInfo}
                proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                themeSettings={this.props.themeSettings}
            />
            </>
        );
    }
}

export default AddEmployeeDialog;