import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import {
    getLocalStorageVariables,
    getThemeSettingsVariables,
    isEmptyVariable,
    isEmptyArray,
    sortTable,
} from "../../../common/Global/commonFunctions";
import {
    SearchSpan,
} from "../../../common/Global/globalStyles.style";
import { Modal } from "react-bootstrap";
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";
import * as TemplateConstants from "../../../common/Global/templateConstants";
import AlertDialog from '../../../common/AlertDialog';

const userDetails = getLocalStorageVariables();
const sortName = "assignmentGroup";
const themeSettings = getThemeSettingsVariables();

class AddDropdownValuesDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            showBtnLoader:false,
            resultSize:Constants.RESULT_SIZE,
            resultSizePlaceholder:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            sort:"",
            sortDir:"",
            resultSizeArr:JSON.parse(JSON.stringify(Constants.RESULT_SIZE_DD_ARR)),
            isDataEdit: false,
            assignmentGroupName: "",
            formErrors: {},
            searchkey: "",

        };
    }

    componentDidMount() { }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(prevProps) !== JSON.stringify(this.props) &&
        this.props.showGroupSetDialogFlag) {
            this.setState({
                componentDidMountFlag:false,
                apiSearchKey:"",
                searchkey:"",
                currentPageNo: 1,
                showLoader:false,
                showBtnLoader:false,
                resultSize:Constants.RESULT_SIZE,
                resultSizePlaceholder:Constants.RESULT_SIZE,
                totalCount:0,
                datalist:[],
                sort:"",
                sortDir:"",
                resultSizeArr:JSON.parse(JSON.stringify(Constants.RESULT_SIZE_DD_ARR)),
                isDataEdit: false,
                assignmentGroupName: "",
                formErrors: {},
                searchkey: "",
            },()=>{
                this.getAssignmentGroupList();
            })
            
        }
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getAssignmentGroupList();
        })
    }

    handleChange = (e) => {
        this.setState({ assignmentGroupName: e.target.value });
    };

    editDropDownValue = (item) => {
        this.setState({
            assignmentGroupName: item.assignmentGroup,
            isDataEdit: true,
            selectedAssignmentGroupId: item.assignmentGroupId,
        });
    };

    showDeleteAssignmentGroupDialog = (item) => {
        this.setState({
            selectedAssignmentGroupId:item.assignmentGroupId,
            selectedAssignmentGroup:item.assignmentGroup,

            showAlertDialog:true,
            alertDialogHeading:"Delete Assignment Group",
            alertDialogMessage:"Are you sure you want to delete "+item.assignmentGroup+"?",
            proceedBtnLabel:"Delete"
        })
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    };

    handleAlertDialogClose = () => {
        this.setState({
            showAlertDialog: false
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getAssignmentGroupList();
        })
    }

    /************** API CALLS ***************/
    getAssignmentGroupList = () => {
        this.setState({
            showLoader:true,
        });

        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            sort:this.state.sort,
            sortDir:this.state.sortDir,
            departmentId:this.props.selectedDepartmentId,
            settingColumn:this.props.selectedSettingColumn,
        }

        fetch(Constants.GetAssignmentGroups,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParam)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            let postParam = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:page,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
                departmentId:this.props.selectedDepartmentId,
                settingColumn:this.props.selectedSettingColumn,
            }

            fetch(Constants.GetAssignmentGroups,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    handleAddNewValueClick = () => {
        let formErrors = {};
        let isError = false;
        if (this.state.assignmentGroupName === "") {
            formErrors.valueErr = "Please Enter Assignment Group Name";
            isError = true;
        }


        if (isError) {
            this.setState({
                formErrors: formErrors,
            });

            setTimeout(() => {
                this.setState({
                    formErrors: {},
                });
                }, Constants.WRNG_MSG_TIMEOUT
            );
        } else {
            let url = "";

            if(this.state.isDataEdit){
                url = Constants.UpdateAssignmentGroup;
            }else{
                url = Constants.AddAssignmentGroup;
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentGroup:this.state.assignmentGroupName,
                    settingColumn:this.props.selectedSettingColumn,
                    departmentId:this.props.selectedDepartmentId,
                    assignmentGroupId:this.state.isDataEdit?this.state.selectedAssignmentGroupId:0
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        isEdit:false,
                        assignmentGroupName:"",
                        selectedAssignmentGroupId:"",
                    },() => {
                        this.getAssignmentGroupList();
                    });
                }else{
                    formErrors['valueErr'] = data.responseMessage;
                    this.setState({
                        formErrors:formErrors,
                        showLoader:false
                    });

                    setTimeout(() => {
                        this.setState({
                            formErrors: {},
                        });
                        }, Constants.WRNG_MSG_TIMEOUT
                    );
                }
            });
        }
    };

    deleteAssignmentGroup = () => {
        this.setState({
            showBtnLoader:true
        })
        fetch(Constants.DeleteAssignmentGroup,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                assignmentGroupId:this.state.selectedAssignmentGroupId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    isEdit:false,
                    assignmentGroupName:"",
                    selectedAssignmentGroupId:"",

                    showAlertDialog:false,
                    alertDialogHeading:"",
                    alertDialogMessage:"",
                    proceedBtnLabel:"",
                    showBtnLoader:false
                },() => {
                    this.getAssignmentGroupList();
                });
            }else{
                let formErrors = {};
                formErrors['valueErr'] = data.responseMessage;
                this.setState({
                    formErrors:formErrors,
                    showBtnLoader:false
                });

                setTimeout(() => {
                    this.setState({
                        formErrors: {},
                    });
                    }, Constants.WRNG_MSG_TIMEOUT
                );
            }
        });
    }

    render() {
        return (
            <Modal className="custom-dialog value-desc-dialog" show={this.props.showGroupSetDialogFlag}
                backdrop="static">
                <Modal.Header>
                    <h5>
                        Assignment Groups
                    </h5>
                    <button
                        onClick={this.props.hideGroupSetDialog}
                        type="button"
                        data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>

                <hr />
                <div class="modal-body">
                    <div class="addnew-modal-form body-wrapper">
                        <div className="row custom-row-5">
                            <div className="col-md-4 custom-col-5 flex-center-layout">
                                <div class="addnew-modal-form-group">
                                    <p>Assignment Group Name*</p>
                                    <input
                                        type="text"
                                        placeholder="Enter Value"
                                        value={this.state.assignmentGroupName}
                                        onChange={this.handleChange}
                                    />
                                    {
                                        !isEmptyVariable(this.state.formErrors.valueErr) &&
                                        <span class="cm-error">
                                            {this.state.formErrors.valueErr}
                                        </span>
                                    }
                                </div>
                                <div
                                    className="add-more-btn-layout mx-2"
                                    onClick={this.handleAddNewValueClick}
                                >
                                    <span className="material-icons">add_circle</span>
                                </div>
                            </div>
                        </div>

                        <div className="row custom-row-5">
                            <div className="col-md-12 custom-col-5">
                            {
                                this.state.datalist.length > 0 &&
                                <>
                                    <div className="search-filter-layout">
                                        <div className="search-col m-l-10">
                                            <input
                                                name="searchkey"
                                                type="text"
                                                onChange={this.handleChangeSearch}
                                                placeholder="Search"
                                                value={this.state.searchkey}
                                            />
                                            <SearchSpan
                                                themeSettings={themeSettings}
                                                onClick={this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                                        </div>

                                        <FilterDropDown
                                            placeholder={this.state.resultSizePlaceholder}
                                            dropdownArr={this.state.resultSizeArr}
                                            onDropDownItemClick={this.resultSizeDropdownClick}
                                            themeSettings={themeSettings}
                                            mleft={2}
                                        />
                                    </div>
                                </>
                            }

                            {       
                                !isEmptyArray(this.state.datalist) && 
                                <div className="common-tab-bg">
                                    <div className="common-table" style={{width:"100%"}}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th className="text-center" width={"5%"}>No.</th>
                                                    <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortName)}>
                                                        <div className="sort-header">Assignment Group Name
                                                        <span className={(this.state.sort === sortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                        </div>
                                                    </th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                !isEmptyArray(this.state.datalist) && 
                                                this.state.datalist.map((item,idx) => {
                                                    return <tr>
                                                        <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                        <td>{item.assignmentGroup}</td>
                                                        <td className="flex-center-layout">
                                                            <span
                                                                onClick={this.editDropDownValue.bind(this, item, idx)}
                                                                className="material-icons mx-2"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    fontSize: 18,
                                                                }}
                                                            >
                                                            edit
                                                            </span>
                                                            <span
                                                                onClick={this.showDeleteAssignmentGroupDialog.bind(this,item)}
                                                                className="material-icons mx-2"
                                                                style={{
                                                                    cursor: "pointer",
                                                                    alignItems: "center",
                                                                    justifyContent: "center",
                                                                    fontSize: 18,
                                                                }}
                                                            >
                                                            delete
                                                            </span>
                                                        </td>
                                                    </tr>
                                                })
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </div>

                    <div class="modal-close-save">
                        <div class="close-save-col">
                            <button
                                onClick={this.props.hideGroupSetDialog}
                                type="button"
                                class="modal-close"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>

                <AlertDialog
                    showAlertDialog={this.state.showAlertDialog}
                    handleAlertDialogClose={this.handleAlertDialogClose}
                    type={Constants.ALERT_TYPE_WARNING}
                    alertDialogHeading={this.state.alertDialogHeading}
                    alertDialogMessage={this.state.alertDialogMessage}
                    proceedBtnClick={this.deleteAssignmentGroup}
                    proceedBtnLabel={this.state.proceedBtnLabel}
                    showLoader={this.state.showBtnLoader}
                    themeSettings={themeSettings}
                />
            </Modal>
        );
    }
}

export default AddDropdownValuesDialog;
