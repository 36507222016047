import React, { Component } from "react";
import * as Constants from "../../common/Global/constants";
import { TableIconsLayout, TableIconsLayoutTeam, AddNewButtonLayout } from "../../common/Global/globalStyles.style";
import Sidebar from "../../common/Sidebar";
import Topbar from "../../common/Topbar";
import { BrowserRouter as Router } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { getThemeSettingsVariables, getLocalStorageVariables, SplitJoinString, isEmptyArray, 
	ifEmptyReturnEmptyStr, isEmptyVariable, removeHtmlTags } from "../../common/Global/commonFunctions";
import moment from "moment";
import Donutchart from "./donutChart";
import DonutchartObs from "./donutChartObs";
import RegularDD from '../../common/DropdownMenus/RegularDropdown';
import FigureAndTable from "./displayComponents/figureAndTable"
import SummaryContent from "./summery/summary";
import ApprovedUser from './approvedUser.js'
import DashboardHeader from "./displayComponents/dashboardHeader";
// import {AddNewButtonLayout} from '../../common/Global/globalStyles.style';
let themeSettings = getThemeSettingsVariables();
let userDetails = {};
// const userDetails = getLocalStorageVariables();

const ongoingAssignment = "Ongoing Assignment";
const scheduleAssignment = "Scheduled Assignmnet";
const reportFinalized = "Report Finalized";
export const figureAndTable = "Management Report";
const newlyAddedTask = "Newly Added Task";
const draftreport = "Draft Report";
const plannedAssignment = "Planned Assignment";
const summary = "Summary";

export const dashboardDisplayArr =[
	{
		label:ongoingAssignment,
	},
	{
		label:scheduleAssignment,
	},
	{
		label:reportFinalized
	},
	{
		label:newlyAddedTask
	},
	{
		label:draftreport
	},
	{
		label:plannedAssignment
	},
]
class Dashboard extends Component {
	constructor(props) {
		super(props);
		this.state = {
			userAssignments: [],
			// overallAssignments: [],
			plannedAssignments: [],
			scheduledAssignments: [],
			draftReports: [],
			completedReports: [],
			newTasks: [],
			showLoader: false,
			componentDidMountFlag: false,
			company:{},
			currentUserAssignmentsCount:1,
			currentPlannedAssignmentsSlideCount:1,
			currentScheduledAssignmentsSlideCount:1,
			currentDraftReportsSlideCount:1,
			currentCompletedReportsSlideCount:1,
			departmentId: [],
			approvalUserCount : 0,

			dashboardDisplayArr:dashboardDisplayArr,
			dashboardDisplayPlaceholder:ongoingAssignment,

			criteria:[],
			editCriteriaName:"",
			criteriaPlaceholder:"",
			selectedCriteria:{},
			approvalUsers: [],
			selectedUsersApprovalFlag:false,

		};
		themeSettings = getThemeSettingsVariables();
	}

	componentDidMount() {
		themeSettings = getThemeSettingsVariables();
		userDetails = getLocalStorageVariables();
		this.getInitData();
	}

	next = () => {
		this.slider.slickNext();
	};
	previous = () => {
		this.slider.slickPrev();
	};

	nextSA = () => {
		this.sliderSA.slickNext();
	};
	previousSA = () => {
		this.sliderSA.slickPrev();
	};
	nextPA = () => {
		this.sliderPA.slickNext();
	};
	previousPA = () => {
		this.sliderPA.slickPrev();
	};

	nextDR = () => {
		this.sliderDR.slickNext();
	};
	previousDR = () => {
		this.sliderDR.slickPrev();
	};
	nextRF = () => {
		this.sliderRF.slickNext();
	};
	previousRF = () => {
		this.sliderRF.slickPrev();
	};
	// nextNAT = () => {
	// 	this.sliderNAT.slickNext();
	// };
	// previousNAT = () => {
	// 	this.sliderNAT.slickPrev();
	// };
	handleChangeUserAssignmentsSlide =(currentSlide) => {
		this.setState({
			currentUserAssignmentsCount:currentSlide+1,
		})
	}
	handleChangePlannedAssignmentsSlide =(currentSlide) => {
		this.setState({
			currentPlannedAssignmentsSlideCount:currentSlide+1,
		})
	}
	handleChangeScheduledAssignmentsSlide =(currentSlide) => {
		this.setState({
			currentScheduledAssignmentsSlideCount:currentSlide+1,
		})
	}
	handleChangeDraftReportsSlide =(currentSlide) => {
		this.setState({
			currentDraftReportsSlideCount:currentSlide+1,
		})
	}
	handleChangeCompletedReportsSlide =(currentSlide) => {
		this.setState({
			currentCompletedReportsSlideCount:currentSlide+1,
		})
	}
	selectedUsersApproval = ()=>{

        this.setState({
            selectedUsersApprovalFlag:true
        })
        

    }
	selectedUsersApprovalClose=(approvalUserCount)=>{
        this.setState({
            selectedUsersApprovalFlag:false,
			approvalUserCount:approvalUserCount
        })
    }

	getTaskCount = (taskCountList) =>{
		let taskCount = 0;

		if(taskCountList === "0"){
			return 0;
		}

		let splitArr = taskCountList.split(",");
		if(!isEmptyArray(splitArr)){
			taskCount=splitArr.reduce((totalCount,item)=>{
				return totalCount+= +item.split(":")[1];
			},0)
		}
		return taskCount;
	}

	getTaskCountArray = (taskCountList) =>{
		let taskCountArr = [];

		if(taskCountList === "0"){
			return [];
		}

		let splitArr = taskCountList.split(",");
		if(!isEmptyArray(splitArr)){
			taskCountArr=splitArr.map((item)=>{
				return item.split(":")[1];
			})
		}
		return taskCountArr;
	}

	getTaskRiskArray = (taskCountList) =>{
		let taskCountArr = [];

		if(taskCountList === "0"){
			return [];
		}

		let splitArr = taskCountList.split(",");
		if(!isEmptyArray(splitArr)){
			taskCountArr=splitArr.map((item)=>{
				return item.split(":")[0];
			})
		}
		return taskCountArr;
	}

	getTaskRiskColorArray = (taskCountList) =>{
		let taskCountArr = [];

		if(taskCountList === "0"){
			return [];
		}

		let splitArr = taskCountList.split(",");
		if(!isEmptyArray(splitArr)){
			taskCountArr=splitArr.map((item)=>{
				return item.split(":")[2];
			})
		}
		return taskCountArr;
	}

	getObsCount = (observationCountList) =>{
		let obsCount = 0;

		if(observationCountList === "0"){
			return 0;
		}

		let splitArr = observationCountList.split(",");
		if(!isEmptyArray(splitArr)){
			obsCount=splitArr.reduce((totalCount,item)=>{
				return obsCount+= +item.split(":")[1];
			},0)
		}
		return obsCount;
	}

	/*******************API CALLS*******************/
	getInitData = () => {
		this.setState({
			showLoader: true,
		});

		Promise.all([
			fetch(Constants.GetDashboard, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					
				}),
			}),
			fetch(Constants.GetUserMenu, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
				}),
			}),
			fetch(Constants.GetAllCriteria, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
				}),
			}),	
		])
			.then(([masterRes, menuRes,criteriaRes]) => {
				return Promise.all([masterRes.json(), menuRes.json(),criteriaRes.json(),
				]);
			})
			.then(([masterRes, menuRes,criteriaRes
			]) => {
				let userAssignments = [];
				let plannedAssignments = [];
				let scheduledAssignments = [];
				let draftReports = [];
				let completedReports = [];
				let newTasks = [];
				let companyTemp = {};
				let criteria =[];	
				let approvalUserCountTemp=0;

				let reportUnitsArr=[];
			    let reportTypeArr=[];

				if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
					userAssignments = masterRes.data.userAssignments;
					// overallAssignments = masterRes.data.overallAssignments;
					plannedAssignments = masterRes.data.plannedAssignments;
					scheduledAssignments = masterRes.data.scheduledAssignments;
					draftReports = masterRes.data.draftReports;
					completedReports = masterRes.data.completedReports;
					newTasks = masterRes.data.newTasks;
					approvalUserCountTemp = masterRes.data.approvalUserCount
				} else {
				}

				if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
					companyTemp = menuRes.data.company;
					Object.entries(menuRes.data.menus).map(([key,value])=>{
						if(key===Constants.USER_ROLE_DASHBOARD_FIGURE_AND_CHART|| value==="All")
						{
							this.state.dashboardDisplayArr.push(
								{
									label:figureAndTable,
								}
							)
						}
						if(key===Constants.USER_ROLE_DASHBOARD_ASSIGNMENT_SUMMARY|| value==="All")
						{
							this.state.dashboardDisplayArr.push(
								{
									label:summary,
								}
							)
							this.state.dashboardDisplayPlaceholder =summary
						}
					})
				} else {
				}
				if (criteriaRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || criteriaRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (criteriaRes.responseCode === Constants.CODE_SUCCESS) {
					criteria = criteriaRes.data
										
				} else {
				}

				


				this.setState({
					componentDidMountFlag: true,
					showLoader: false,
					userAssignments: userAssignments,
					// overallAssignments: overallAssignments,
					plannedAssignments: plannedAssignments,
					scheduledAssignments: scheduledAssignments,
					draftReports: draftReports,
					completedReports: completedReports,
					newTasks: newTasks,
					company:companyTemp,
					criteria:criteria,
					selectedCriteria:criteria[0],
					criteriaPlaceholder:criteria[0]?.criteriaName,
					editCriteriaName:criteria[0]?.criteriaName,
					approvalUserCount: approvalUserCountTemp

				});
			});
	};

	getDashboardList = () => {
		this.setState({
			showLoader: true,
		});

		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
		};

		fetch(Constants.GetDashboard, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParam),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState({
						showLoader: false,
						userAssignments: data.data.userAssignments,
						// overallAssignments: data.data.overallAssignments,
						plannedAssignments: data.data.plannedAssignments,
						scheduledAssignments: data.data.scheduledAssignments,
						draftReports: data.data.draftReports,
						completedReports: data.data.completedReports,
						newTasks: data.data.newTasks,
						apiSearchKey: this.state.searchkey,
						componentDidMountFlag: true,
						criteria:data.data.criteria,
						approvalUsers: data.data.approvalUserList,
					});
				} else {
					this.setState({
						datalist: [],
						showLoader: false,
						totalCount: 0,
						componentDidMountFlag: true,
						apiSearchKey: this.state.searchkey,
					});
				}
			});
	};
	/*******************API CALLS END HERE*******************/


	displayDropdownItemClick = (selectedItem) =>{
		
		
		this.setState({
			dashboardDisplayPlaceholder:selectedItem.label,
		})
	}

	ShowingLoader = (bool) =>{
		this.setState({
			showLoader:bool
		})
		
	}

	criteriaDropdownItemClick = (selectedItem)=>{
		this.setState({
			selectedCriteria:selectedItem,
			criteriaPlaceholder:selectedItem.criteriaName,
			editCriteriaName:selectedItem.criteriaName,
			dashboardDisplayPlaceholder: figureAndTable,
		})
	}

	handleChange = (e) => {
		this.setState({editCriteriaName:e.target.value});
	  }

	handleUpdateClick = (newCriteria,criteriaName) =>{
		this.setState({
		criteria:newCriteria,
		criteriaPlaceholder:criteriaName,
		editCriteriaName:criteriaName,
		})
  	}

	updateCriteria = (criteria) => {
		this.setState({ criteria });
	}

	render() {
		var settings = {
			dots: false,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows:false,
			// afterChange: (currentSlide) => this.handleChangeSlide(currentSlide)
		};

		const getHeaderName = (text) => {
			return <strong className="ml-1" style={{ fontSize: 16 }}>({text})</strong>;
		}

		const headerRightContent = {
			[ongoingAssignment]: !isEmptyArray(this.state.userAssignments) &&
									<div className="arrows-col">
										<button onClick={this.previous}>
											<span class="material-icons-round">
											arrow_back_ios
											</span>
										</button>
										<button onClick={this.next}>
											<span class="material-icons-round">
											arrow_forward_ios
											</span>
											{/* <img src="/assets/images/right-slider-icon.svg" /> */}
										</button>
									</div>,
			[scheduleAssignment]: !isEmptyArray(this.state.scheduledAssignments) && <div className="arrows-col">
									<button onClick={this.previousSA}>
										<span class="material-icons-round">
										arrow_back_ios
										</span>
									</button>
									<button onClick={this.nextSA}>
										<span class="material-icons-round">
										arrow_forward_ios
										</span>
									</button>
								</div>,
			[reportFinalized]: !isEmptyArray(this.state.completedReports) && <div className="arrows-col">
									<button onClick={this.previousRF}>
										<span class="material-icons-round">
										arrow_back_ios
										</span>
									</button>
									<button onClick={this.nextRF}>
										<span class="material-icons-round">
										arrow_forward_ios
										</span>
									</button>
								</div>,
			[plannedAssignment]: !isEmptyArray(this.state.plannedAssignments) && <div className="arrows-col">
									<button onClick={this.previousPA}>
										<span class="material-icons-round">
										arrow_back_ios
										</span>
									</button>
									<button onClick={this.nextPA}>
										<span class="material-icons-round">
										arrow_forward_ios
										</span>
									</button>
								</div>,
			[draftreport]: !isEmptyArray(this.state.draftReports) && <div className="arrows-col">
									<button onClick={this.previousDR}>
										<span class="material-icons-round">
										arrow_back_ios
										</span>
									</button>
									<button onClick={this.nextDR}>
										<span class="material-icons-round">
										arrow_forward_ios
										</span>
									</button>
								</div>,
			[newlyAddedTask]: "",
		};

		const headerNameContent = {
			[ongoingAssignment]: !isEmptyArray(this.state.userAssignments)
									? getHeaderName(`${this.state.currentUserAssignmentsCount}/${this.state.userAssignments.length}`)
									: "",
			[scheduleAssignment]: !isEmptyArray(this.state.scheduledAssignments)
									? getHeaderName(`${this.state.currentScheduledAssignmentsSlideCount}/${this.state.scheduledAssignments.length}`)
									: "",
			[reportFinalized]: !isEmptyArray(this.state.completedReports)
									? getHeaderName(`${this.state.currentCompletedReportsSlideCount}/${this.state.completedReports.length}`)
									: "",
			[plannedAssignment]: !isEmptyArray(this.state.plannedAssignments)
									? getHeaderName(`${this.state.currentPlannedAssignmentsSlideCount}/${this.state.plannedAssignments.length}`)
									: "",
			[draftreport]: !isEmptyArray(this.state.draftReports)
									? getHeaderName(`${this.state.currentDraftReportsSlideCount}/${this.state.draftReports.length}`)
									: "",
			[newlyAddedTask]: "",
		};

		return (
			<Router>
				<main className="main-dashboard">
					<section className="dashboard-bg">
						<Sidebar />

						<div className="dashboard-right-bg position-relative">
							<div className="dashboard-wrapper">
								<Topbar />
								{this.state.showLoader && (
									<div class="loader"></div>
								)}

								{
									this.state.dashboardDisplayPlaceholder !== figureAndTable &&
									<DashboardHeader
										showBody={false}
										criteriaPlaceholder={this.state.dashboardDisplayPlaceholder}
										selectedUsersApproval={this.selectedUsersApproval}
										showSelectedUsersApproval={this.state.approvalUserCount > 0}
										criteriaList={this.state.criteria}
										displayDropdownItemClick={this.displayDropdownItemClick}
										criteriaDropdownItemClick={this.criteriaDropdownItemClick}
										updateCriteria={this.updateCriteria}
										rightContent={headerRightContent[this.state.dashboardDisplayPlaceholder]}
										nameContent={headerNameContent[this.state.dashboardDisplayPlaceholder]}
									/>
								}

								{/************Progress of current assignment**************/}
								{
									this.state.componentDidMountFlag && this.state.dashboardDisplayPlaceholder===ongoingAssignment &&
										<div className="card-container">
											<div className="card-body">
												<Slider ref={(c) => (this.slider = c)} {...settings} afterChange={this.handleChangeUserAssignmentsSlide}>
													{ !isEmptyArray(this.state.userAssignments) &&
														this.state.userAssignments.map((item) => {
														return (
															<div className="slide-col">
																<div className="row">
																	<div className="col-lg-6">
																		<div className="leftslide-col">
																			<div className="client-details">
																				<table>
																					{this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
																						<tr>
																							<td>
																								<TableIconsLayout themeSettings={themeSettings}>
																									<span class="material-icons-outlined">
																									person_outline
																									</span>
																								</TableIconsLayout>

																								{/* <img src="/assets/images/client-icon.svg" /> */}
																								<p>Client</p>
																							</td>
																							<td style={{verticalAlign:"middle"}}>
																								<p>:</p>
																							</td>
																							<td>
																								<p>{item.companyName}</p>
																							</td>
																						</tr>
																					}
																					<tr>
																						<td>
                                                                                            <TableIconsLayout themeSettings={themeSettings}>
                                                                                                <span class="material-icons-outlined">
                                                                                                layers
                                                                                                </span>
                                                                                            </TableIconsLayout>
																							{/* <img src="/assets/images/area-icon.svg" /> */}
																							<p>Area</p>
																						</td>
																						<td  style={{verticalAlign:"middle"}}>
																							<p>:</p>
																						</td>
																						<td>
																							{item.assignmentArea === "Process" && (
																								<p>{SplitJoinString(item.rootProcesses, ",", ":-:", ", ")}</p>
																							)}
																							{item.assignmentArea === "Function" && (
																								<p>{SplitJoinString(item.rootFunctions, ",", ":-:", ", ")}</p>
																							)}
																							{item.assignmentArea === "CheckList" && !isEmptyVariable(item.checkList) && (
																								<p>{SplitJoinString(item.checkList, ",", ":-:", ", ")}</p>
																							)}
																							{item.assignmentArea === "CheckList" && isEmptyVariable(item.checkList) && (
																								<p>Checklist</p>
																							)}
																						</td>
																					</tr>
																					<tr>
																						<td>
                                                                                            <TableIconsLayout themeSettings={themeSettings}>
                                                                                                <span class="material-icons-outlined">
                                                                                                location_on
                                                                                                </span>
                                                                                            </TableIconsLayout>
																							{/* <img src="/assets/images/location-icon.svg" /> */}
																							<p>Location</p>
																						</td>
																						<td style={{verticalAlign:"middle"}}>
																							<p>:</p>
																						</td>
																						<td>
																							<p>{item.unitCityList}</p>
																						</td>
																					</tr>
																				</table>
																			</div>
																			<div className="client-date">
																				<table className="tla">
																					<tr>
																						<th>Start Date</th>
																						<th>End Date</th>
																						<th>Report Date</th>
																					</tr>
																					<tr>
																						<td>{moment(item.startDate).format("DD/MM/YYYY")}</td>
																						<td>{moment(item.endDate).format("DD/MM/YYYY")}</td>
																						<td>{moment(item.reportDate).format("DD/MM/YYYY")}</td>
																					</tr>
																				</table>
																			</div>

																			<div className="client-resources">
																				<h5 style={{fontSize: "14px"}}>Resources</h5>
																				<table>
																					<tr>
																						<td>
                                                                                            <TableIconsLayoutTeam themeSettings={themeSettings}>
                                                                                                <span class="material-icons-outlined">
                                                                                                trip_origin
                                                                                                </span>
                                                                                            </TableIconsLayoutTeam>
																							{/* <img src="/assets/images/team-icon.svg" /> */}
																							<p>Team Members</p>
																						</td>
																						<td style={{verticalAlign:"middle"}}>
																							<p>:</p>
																						</td>
																						<td>
																							{
																								isEmptyArray(item.teamMembers)
																								? "-"
																								: item.teamMembers.map((teamItem, index) => {
																									return <span>{(index ? ", " : "") + teamItem}</span>;
																								})
																							}
																						</td>
																					</tr>
																					<tr>
																						<td>

                                                                                            <TableIconsLayoutTeam themeSettings={themeSettings}>
                                                                                                <span class="material-icons-outlined">
                                                                                                trip_origin
                                                                                                </span>
                                                                                            </TableIconsLayoutTeam>
																							{/* <img src="/assets/images/team-icon.svg" /> */}
																							<p>Team Leader</p>
																						</td>
																						<td style={{verticalAlign:"middle"}}>
																							<p>:</p>
																						</td>
																						<td>
																							<p>{ifEmptyReturnEmptyStr(item.teamLeader, "-")}</p>
																						</td>
																					</tr>
																				</table>
																			</div>

																			<div className="client-observations">
                                                                                <div className="co-left">
																					<h6 style={{fontSize: "14px"}}>Your Observations</h6>
                                                                                    <span className="countspan">
																						{/* <p>Count of Observations</p> */}
																						<i
                                                                                            style={{
																								fontSize: "14px",
                                                                                                background: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`,
                                                                                                color: `rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})`
                                                                                            }}
                                                                                        >{this.getObsCount(item.totalObservationCountUserList)}</i>
																					</span>
                                                                                    <DonutchartObs
                                                                                        chartData={
                                                                                            [
                                                                                                {data:this.getTaskCountArray(item.totalObservationCountUserList)},
                                                                                            ]
                                                                                        }
                                                                                        id={"obstest"}
                                                                                        labels={this.getTaskRiskArray(item.totalObservationCountUserList)}
                                                                                        colors={this.getTaskRiskColorArray(item.totalObservationCountUserList)}
                                                                                    ></DonutchartObs>
																				</div>
																				<div className="co-right">
																					<h6 style={{fontSize: "14px"}}>Overall Observations</h6>
                                                                                    <span className="countspan">
																						{/* <p>Count of Observations</p> */}
																						<i
                                                                                            style={{
																								fontSize: "14px",
                                                                                                background: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.7)`,
                                                                                                color: `rgba(${themeSettings.themeTextColor.r},${themeSettings.themeTextColor.g},${themeSettings.themeTextColor.b},${themeSettings.themeTextColor.a})`
                                                                                            }}
                                                                                        >{this.getObsCount(item.observationRiskCountOverallList)}</i>
																					</span>
																					<DonutchartObs
                                                                                        chartData={
                                                                                            [
                                                                                                {data:this.getTaskCountArray(item.observationRiskCountOverallList)},
                                                                                            ]
                                                                                        }
                                                                                        id={"obstest"}
                                                                                        labels={this.getTaskRiskArray(item.observationRiskCountOverallList)}
                                                                                        colors={this.getTaskRiskColorArray(item.observationRiskCountOverallList)}
                                                                                    ></DonutchartObs>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div className="col-lg-6 bl-1px">
																		<div className="rightslide-col">
																			<div className="your-progress-wrap">
																				<h5 style={{fontSize: '14px'}}>Your Progress</h5>
																				<div class="prog-wrap">
																					<div class="opc-col">
																						<div class="overall-progress-col overall-progress-col-resized">
																							<i
                                                                                                style={{background: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`}}
                                                                                            >
																								<img src="/assets/images/total-tasks.svg" />
																							</i>
																							<p>Total Tasks</p>
																							<span>{this.getTaskCount(item.totalTaskCountUserList)}</span>
																						</div>
																						<div class="overall-progress-col-bd overall-progress-col-bd-resized">
                                                                                        <p style={{fontWeight:"bold",color: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b})`}}>
                                                                                                {"Completed Tasks: "+this.getTaskCount(item.completedTaskCountUserList)}</p>
																						</div>
																						<div class="overall-progress-col-bd overall-progress-col-bd-resized">
																							<p style={{fontWeight:"bold",color: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.4)`}}>
                                                                                                {"Pending Tasks: "+this.getTaskCount(item.incompleteTaskCountUserList)}</p>
																						</div>
																					</div>
																					<div class="overall-prog-text">
																						<Donutchart
																							chartData={
                                                                                                [
                                                                                                    {data:this.getTaskCountArray(item.totalTaskCountUserList)},
                                                                                                    {data:this.getTaskCountArray(item.completedTaskCountUserList)},
                                                                                                ]
                                                                                            }
																							id={"test"}
																							labels={this.getTaskRiskArray(item.totalTaskCountUserList)}
																							colors={this.getTaskRiskColorArray(item.totalTaskCountUserList)}
																						></Donutchart>
																					</div>
																				</div>
																			</div>
																			<div className="overall-progress-wrap">
																				<h5 style={{fontSize: "14px"}}>Overall Progress</h5>

																				<div class="prog-wrap">
																					<div class="opc-col">
																						<div class="overall-progress-col overall-progress-col-resized">
																							<i
                                                                                            style={{background: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`}}
                                                                                            >
																								<img src="/assets/images/total-tasks.svg" />
																							</i>
																							<p>Total Tasks</p>
																							<span>{this.getTaskCount(item.totalTaskCountOverallList)}</span>
																						</div>
																						<div class="overall-progress-col-bd overall-progress-col-bd-resized">
																							<p style={{fontWeight:"bold",color: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b})`}}>
                                                                                                {"Completed Tasks: "+this.getTaskCount(item.completedTaskCountOverallList)}</p>
																						</div>
																						<div class="overall-progress-col-bd overall-progress-col-bd-resized">
                                                                                        <p style={{fontWeight:"bold",color: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.4)`}}>
                                                                                                {"Pending Tasks: "+this.getTaskCount(item.incompleteTaskCountOverallList)}</p>
																						</div>
																					</div>
																					<div class="overall-prog-text">
																						<Donutchart
                                                                                            chartData={
                                                                                                [
                                                                                                    {data:this.getTaskCountArray(item.totalTaskCountOverallList)},
                                                                                                    {data:this.getTaskCountArray(item.completedTaskCountOverallList)},
                                                                                                ]
                                                                                            }
                                                                                            id={"test"}
                                                                                            labels={this.getTaskRiskArray(item.totalTaskCountOverallList)}
                                                                                            colors={this.getTaskRiskColorArray(item.totalTaskCountOverallList)}
																						></Donutchart>
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														);
													})}
												</Slider>
												{
													isEmptyArray(this.state.userAssignments) &&
														<div className="flex-center-layout" style={{justifyContent:"center"}}>
															<h6 style={{marginTop:"0.5em"}}>No Ongoing Assignments found</h6>
														</div>
												}
											</div>
										</div>
								}
								{
									this.state.componentDidMountFlag && 
										<div className="card-slide-wrapper">
											<div className="row custom-row">
												<div class="col-md-6 custom-col">
													{!isEmptyArray(this.state.scheduledAssignments) && this.state.dashboardDisplayPlaceholder===scheduleAssignment && (
														<div className="card-container">
															<div className="card-body">
																<Slider ref={(c) => (this.sliderSA = c)} {...settings} afterChange={this.handleChangeScheduledAssignmentsSlide}>
																	{this.state.scheduledAssignments.map((item) => {
																		return (
																			<div className="slide-col">
																				<div className="row">
																					<div className="col-lg-12">
																						<div className="leftslide-col">
																							<div className="client-details">
																								{/* <p>
																									<strong>Assignment which are scheduled but not started</strong>
																								</p> */}
																								<table>
																									{this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
																										<tr>
																											<td>
																												<TableIconsLayout themeSettings={themeSettings}>
																													<span class="material-icons-outlined">
																													person_outline
																													</span>
																												</TableIconsLayout>
																												{/* <img src="/assets/images/client-icon.svg" /> */}
																												<p>Client</p>
																											</td>
																											<td  style={{verticalAlign:"middle"}}>
																												<p>:</p>
																											</td>
																											<td>
																												<p>{item.companyName}</p>
																											</td>
																										</tr>
																									}
																									<tr>
																										<td>
                                                                                                            <TableIconsLayout themeSettings={themeSettings}>
                                                                                                                <span class="material-icons-outlined">
                                                                                                                layers
                                                                                                                </span>
                                                                                                            </TableIconsLayout>
																											{/* <img src="/assets/images/area-icon.svg" /> */}
																											<p>Area</p>
																										</td>
																										<td  style={{verticalAlign:"middle"}}>
																											<p>:</p>
																										</td>
																										<td>
																											{item.assignmentArea === "Process" && (
																												<p>{SplitJoinString(item.rootProcesses, ",", ":-:", ", ")}</p>
																											)}
																											{item.assignmentArea === "Function" && (
																												<p>{SplitJoinString(item.rootFunctions, ",", ":-:", ", ")}</p>
																											)}
																											{item.assignmentArea === "CheckList" && !isEmptyVariable(item.checkList) && (
																												<p>{SplitJoinString(item.checkList, ",", ":-:", ", ")}</p>
																											)}
																											{item.assignmentArea === "CheckList" && isEmptyVariable(item.checkList) && (
																												<p>Checklist</p>
																											)}
																										</td>
																									</tr>
																									<tr>
																										<td>
                                                                                                            <TableIconsLayout themeSettings={themeSettings}>
                                                                                                                <span class="material-icons-outlined">
                                                                                                                location_on
                                                                                                                </span>
                                                                                                            </TableIconsLayout>
																											{/* <img src="/assets/images/location-icon.svg" /> */}
																											<p>Location</p>
																										</td>
																										<td  style={{verticalAlign:"middle"}}>
																											<p>:</p>
																										</td>
																										<td>
																											<p>{item.unitCityList}</p>
																										</td>
																									</tr>
																								</table>
																							</div>
																							<div className="client-date">
																								<table className="tla">
																									<tr>
																										<th>Start Date</th>
																										<th>End Date</th>
																										<th>Report Date</th>
																									</tr>
																									<tr>
																										<td>{moment(item.startDate).format("DD/MM/YYYY")}</td>
																										<td>{moment(item.endDate).format("DD/MM/YYYY")}</td>
																										<td>{moment(item.reportDate).format("DD/MM/YYYY")}</td>
																									</tr>
																								</table>
																							</div>

																							<div className="client-resources">
																								<h5>Resources</h5>
																								<table>
																									<tr>
																										<td>
                                                                                                            <TableIconsLayoutTeam themeSettings={themeSettings}>
                                                                                                                <span class="material-icons-outlined">
                                                                                                                trip_origin
                                                                                                                </span>
                                                                                                            </TableIconsLayoutTeam>
																											{/* <img src="/assets/images/team-icon.svg" /> */}
																											<p>Team Members</p>
																										</td>
																										<td  style={{verticalAlign:"middle"}}>
																											<p>:</p>
																										</td>
																										<td>
																											<p>
																												{isEmptyArray(item.teamMembers)
																													? "-"
																													: item.teamMembers.map((teamItem, index) => {
																															return <span>{(index ? ", " : "") + teamItem}</span>;
																													})}
																											</p>
																										</td>
																									</tr>
																									<tr>
																										<td>
                                                                                                            <TableIconsLayoutTeam themeSettings={themeSettings}>
                                                                                                                <span class="material-icons-outlined">
                                                                                                                trip_origin
                                                                                                                </span>
                                                                                                            </TableIconsLayoutTeam>
																											{/* <img src="/assets/images/team-icon.svg" /> */}
																											<p>Team Leader</p>
																										</td>
																										<td  style={{verticalAlign:"middle"}}>
																											<p>:</p>
																										</td>
																										<td>
																											<p>{ifEmptyReturnEmptyStr(item.teamLeader, "-")}</p>
																										</td>
																									</tr>
																								</table>
																							</div>

																							<div className="tasks-allot">
																								<h5>Tasks Alloted : {item.taskAssigned === "T" ? "Yes" : "No"}</h5>
																								{/* <h5>Tasks Alloted : {item.taskAssigned ==="T"?"Yes":item.taskAssigned ==="X"?"No":"No"}</h5> */}
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		);
																	})}
																</Slider>
															</div>
														</div>
													)}
													{!isEmptyArray(this.state.completedReports) && this.state.dashboardDisplayPlaceholder===reportFinalized && (
														<div className="card-container">
															<div className="card-body">
																<Slider ref={(c) => (this.sliderRF = c)} {...settings} afterChange={this.handleChangeCompletedReportsSlide}>
																	{this.state.completedReports.map((item) => {
																		return (
																			<div className="slide-col">
																				<div className="row">
																					<div className="col-lg-12">
																						<div className="leftslide-col">
																							<div className="client-details">
																								{/* <p>
																									<strong>Recently Finalized Reports</strong>
																								</p> */}
																								{
																									!isEmptyVariable(item.reportRiskLevel) &&
																									<span 
                                                                                                    style={{
                                                                                                        background: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.2)`,
                                                                                                        color: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`,
                                                                                                    }}
                                                                                                    className="med-btn">
                                                                                                        {item.reportRiskLevel}
                                                                                                    </span>
																								}
																								<table>
																									{	this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
																										<tr>
																											<td>
																												<TableIconsLayout themeSettings={themeSettings}>
																													<span class="material-icons-outlined">
																													person_outline
																													</span>
																												</TableIconsLayout>
																												{/* <img src="/assets/images/client-icon.svg" /> */}
																												<p>Client</p>
																											</td>
																											<td style={{verticalAlign:"middle"}}>
																												<p>:</p>
																											</td>
																											<td>
																												<p>{item.companyName}</p>
																											</td>
																										</tr>
																									}
																									<tr>
																										<td>
                                                                                                            <TableIconsLayout themeSettings={themeSettings}>
                                                                                                                <span class="material-icons-outlined">
                                                                                                                layers
                                                                                                                </span>
                                                                                                            </TableIconsLayout>
																											{/* <img src="/assets/images/area-icon.svg" /> */}
																											<p>Area</p>
																										</td>
																										<td  style={{verticalAlign:"middle"}}>
																											<p>:</p>
																										</td>
																										<td>
																											{item.assignmentArea === "Process" && (
																												<p>{SplitJoinString(item.rootProcesses, ",", ":-:", ", ")}</p>
																											)}
																											{item.assignmentArea === "Function" && (
																												<p>{SplitJoinString(item.rootFunctions, ",", ":-:", ", ")}</p>
																											)}
																											{item.assignmentArea === "CheckList" && !isEmptyVariable(item.checkList) && (
																												<p>{SplitJoinString(item.checkList, ",", ":-:", ", ")}</p>
																											)}
																											{item.assignmentArea === "CheckList" && isEmptyVariable(item.checkList) && (
																												<p>Checklist</p>
																											)}
																										</td>
																									</tr>
																									<tr>
																										<td>
                                                                                                            <TableIconsLayout themeSettings={themeSettings}>
                                                                                                                <span class="material-icons-outlined">
                                                                                                                location_on
                                                                                                                </span>
                                                                                                            </TableIconsLayout>
																											{/* <img src="/assets/images/location-icon.svg" /> */}
																											<p>Location</p>
																										</td>
																										<td style={{verticalAlign:"middle"}}>
																											<p>:</p>
																										</td>
																										<td>
																											<p>{item.unitCityList}</p>
																										</td>
																									</tr>
																								</table>
																							</div>
																							<div className="client-date">
																								<table className="tla">
																									<tr>
																										<th>Start Date</th>
																										<th>End Date</th>
																										<th>Report Date</th>
																									</tr>
																									<tr>
																										<td>{moment(item.startDate).format("DD/MM/YYYY")}</td>
																										<td>{moment(item.endDate).format("DD/MM/YYYY")}</td>
																										<td>{moment(item.reportDate).format("DD/MM/YYYY")}</td>
																									</tr>
																								</table>
																							</div>

																							<div className="client-resources">
																								<h5>Resources</h5>
																								<table className="dr-table">
																									<tr>
																										<td>
                                                                                                            <TableIconsLayoutTeam themeSettings={themeSettings}>
                                                                                                                <span class="material-icons-outlined">
                                                                                                                trip_origin
                                                                                                                </span>
                                                                                                            </TableIconsLayoutTeam>
																											{/* <img src="/assets/images/team-icon.svg" /> */}
																											<p>Team Members</p>
																										</td>
																										<td style={{verticalAlign:"middle"}}>
																											<p>:</p>
																										</td>
																										<td>
																											<p>
																												{isEmptyArray(item.teamMembers)
																													? "-"
																													: item.teamMembers.map((teamItem, index) => {
																															return <span>{(index ? ", " : "") + teamItem}</span>;
																													})}
																											</p>
																										</td>
																									</tr>
																									<tr>
																										<td>
                                                                                                            <TableIconsLayoutTeam themeSettings={themeSettings}>
                                                                                                                <span class="material-icons-outlined">
                                                                                                                trip_origin
                                                                                                                </span>
                                                                                                            </TableIconsLayoutTeam>
																											{/* <img src="/assets/images/team-icon.svg" /> */}
																											<p>Team Leader</p>
																										</td>
																										<td  style={{verticalAlign:"middle"}}>
																											<p>:</p>
																										</td>
																										<td>
																											<p>{ifEmptyReturnEmptyStr(item.teamLeader, "-")}</p>
																										</td>
																									</tr>
																								</table>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		);
																	})}
																</Slider>
															</div>
														</div>
													)}
												{/* </div> */}

												{/* <div className="col-md-6 custom-col"> */}
													{!isEmptyArray(this.state.plannedAssignments) && this.state.dashboardDisplayPlaceholder=== plannedAssignment &&  (
														<div className="card-container">
															<div className="card-body">
																<Slider ref={(c) => (this.sliderPA = c)} {...settings} afterChange={this.handleChangePlannedAssignmentsSlide}>
																	{this.state.plannedAssignments.map((item) => {
																		return (
																			<div className="slide-col">
																				<div className="row">
																					<div className="col-lg-12">
																						<div className="leftslide-col">
																							<div className="client-details">
																								<p>
																									<strong>Upcoming Planned Assignment (for next two months)</strong>
																								</p>
																								<table>
																									{this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
																										<tr>
																											<td>
																												<TableIconsLayout themeSettings={themeSettings}>
																													<span class="material-icons-outlined">
																													person_outline
																													</span>
																												</TableIconsLayout>
																												{/* <img src="/assets/images/client-icon.svg" /> */}
																												<p>Client</p>
																											</td>
																											<td style={{verticalAlign:"middle"}}>
																												<p>:</p>
																											</td>
																											<td>
																												<p>{item.companyName}</p>
																											</td>
																										</tr>
																									}
																									<tr>
																										<td>
                                                                                                            <TableIconsLayout themeSettings={themeSettings}>
                                                                                                                <span class="material-icons-outlined">
                                                                                                                layers
                                                                                                                </span>
                                                                                                            </TableIconsLayout>
																											{/* <img src="/assets/images/area-icon.svg" /> */}
																											<p>Area</p>
																										</td>
																										<td style={{verticalAlign:"middle"}}>
																											<p>:</p>
																										</td>
																										<td>
																											{!isEmptyVariable( item.processList) && (
																												<p>{SplitJoinString(item.rootProcesses, ",", ":-:", ", ")}</p>
																											)}
																											{!isEmptyVariable( item.functionList) && (
																												<p>{SplitJoinString(item.rootFunctions, ",", ":-:", ", ")}</p>
																											)}
																											{!isEmptyVariable( item.checkList) && (
																												<p>{SplitJoinString(item.checkList, ",", ":-:", ", ")}</p>
																											)}
																											{	isEmptyVariable( item.processList) &&
																												isEmptyVariable( item.functionList) &&
																												isEmptyVariable( item.checkList) && (
																												<p>Checklist</p>
																											)}
																										</td>
																									</tr>
																									<tr>
																										<td>
                                                                                                            <TableIconsLayout themeSettings={themeSettings}>
                                                                                                                <span class="material-icons-outlined">
                                                                                                                location_on
                                                                                                                </span>
                                                                                                            </TableIconsLayout>
																											{/* <img src="/assets/images/location-icon.svg" /> */}
																											<p>Location</p>
																										</td>
																										<td style={{verticalAlign:"middle"}}>
																											<p>:</p>
																										</td>
																										<td>
																											<p>{item.unitCityList}</p>
																										</td>
																									</tr>
																								</table>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		);
																	})}
																</Slider>
															</div>
														</div>
													)}
													{!isEmptyArray(this.state.draftReports) && this.state.dashboardDisplayPlaceholder===draftreport && (
														<div className="card-container">
															<div className="card-body">
																<Slider ref={(c) => (this.sliderDR = c)} {...settings} afterChange={this.handleChangeDraftReportsSlide}>
																	{this.state.draftReports.map((item) => {
																		return (
																			<div className="slide-col">
																				<div className="row">
																					<div className="col-lg-12">
																						<div className="leftslide-col">
																							<div className="client-details">
																								{/* <p>
																									<strong>Report which are marked as draft</strong>
																								</p> */}
																								{
																									!isEmptyVariable(item.reportRiskLevel) &&
																									<span 
                                                                                                    style={{
                                                                                                        background: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.2)`,
                                                                                                        color: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`,
                                                                                                    }}
                                                                                                    className="high-btn">{item.reportRiskLevel}</span>
																								}
																								<table>
																									{this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
																										<tr>
																											<td>
																												<TableIconsLayout themeSettings={themeSettings}>
																													<span class="material-icons-outlined">
																													person_outline
																													</span>
																												</TableIconsLayout>
																												{/* <img src="/assets/images/client-icon.svg" /> */}
																												<p>Client</p>
																											</td>
																											<td style={{verticalAlign:"middle"}}>
																												<p>:</p>
																											</td>
																											<td>
																												<p>{item.companyName}</p>
																											</td>
																										</tr>
																									}
																									<tr>
																										<td>
                                                                                                            <TableIconsLayout themeSettings={themeSettings}>
                                                                                                                <span class="material-icons-outlined">
                                                                                                                layers
                                                                                                                </span>
                                                                                                            </TableIconsLayout>
																											{/* <img src="/assets/images/area-icon.svg" /> */}
																											<p>Area</p>
																										</td>
																										<td style={{verticalAlign:"middle"}}>
																											<p>:</p>
																										</td>
																										<td>
																											{item.assignmentArea === "Process" && (
																												<p>{SplitJoinString(item.rootProcesses, ",", ":-:", ", ")}</p>
																											)}
																											{item.assignmentArea === "Function" && (
																												<p>{SplitJoinString(item.rootFunctions, ",", ":-:", ", ")}</p>
																											)}
																											{item.assignmentArea === "CheckList" && !isEmptyVariable(item.checkList) && (
																												<p>{SplitJoinString(item.checkList, ",", ":-:", ", ")}</p>
																											)}
																											{item.assignmentArea === "CheckList" && isEmptyVariable(item.checkList) && (
																												<p>Checklist</p>
																											)}
																										</td>
																									</tr>
																									<tr>
																										<td>
                                                                                                            <TableIconsLayout themeSettings={themeSettings}>
                                                                                                                <span class="material-icons-outlined">
                                                                                                                location_on
                                                                                                                </span>
                                                                                                            </TableIconsLayout>
																											{/* <img src="/assets/images/location-icon.svg" /> */}
																											<p>Location</p>
																										</td>
																										<td style={{verticalAlign:"middle"}}>
																											<p>:</p>
																										</td>
																										<td>
																											<p>{item.unitCityList}</p>
																										</td>
																									</tr>
																								</table>
																							</div>
																							<div className="client-date">
																								<table className="tla">
																									<tr>
																										<th>Start Date</th>
																										<th>End Date</th>
																										<th>Report Date</th>
																									</tr>
																									<tr>
																										<td>{moment(item.startDate).format("DD/MM/YYYY")}</td>
																										<td>{moment(item.endDate).format("DD/MM/YYYY")}</td>
																										<td>{moment(item.reportDate).format("DD/MM/YYYY")}</td>
																									</tr>
																								</table>
																							</div>

																							<div className="client-resources">
																								<h5>Resources</h5>
																								<table className="dr-table">
																									<tr>
																										<td>
                                                                                                            <TableIconsLayoutTeam themeSettings={themeSettings}>
                                                                                                                <span class="material-icons-outlined">
                                                                                                                trip_origin
                                                                                                                </span>
                                                                                                            </TableIconsLayoutTeam>
																											{/* <img src="/assets/images/team-icon.svg" /> */}
																											<p>Team Members</p>
																										</td>
																										<td  style={{verticalAlign:"middle"}}>
																											<p>:</p>
																										</td>
																										<td>
																											<p>
																												{isEmptyArray(item.teamMembers)
																													? "-"
																													: item.teamMembers.map((teamItem, index) => {
																															return <span>{(index ? ", " : "") + teamItem}</span>;
																													})}
																											</p>
																										</td>
																									</tr>
																									<tr>
																										<td>
                                                                                                            <TableIconsLayoutTeam themeSettings={themeSettings}>
                                                                                                                <span class="material-icons-outlined">
                                                                                                                trip_origin
                                                                                                                </span>
                                                                                                            </TableIconsLayoutTeam>
																											{/* <img src="/assets/images/team-icon.svg" /> */}
																											<p>Team Leader</p>
																										</td>
																										<td style={{verticalAlign:"middle"}}>
																											<p>:</p>
																										</td>
																										<td>
																											<p>{ifEmptyReturnEmptyStr(item.teamLeader, "-")}</p>
																										</td>
																									</tr>
																								</table>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		);
																	})}
																</Slider>
															</div>
														</div>
													)}
													{!isEmptyArray(this.state.newTasks) && this.state.dashboardDisplayPlaceholder===newlyAddedTask && (
													<div className="card-container">
														<div className="card-body">
															<Slider ref={(c) => (this.sliderNAT = c)} {...settings}>
																<div className="slide-col">
																	<div className="row">
																		<div className="col-lg-12">
																			<div className="leftslide-col">
																				<div className="client-details m-b-0">
																					{/* <p className="m-b-0">
																						<strong>Newly Added Task (In last two week)</strong>
																					</p> */}
																				</div>
																				{this.state.newTasks.map((item)=>{
																					return (
																						<div className="nat-col">
																							<p className="m-b-10" >{removeHtmlTags(item.task)}</p>
																							<div className="btns-col">
																								{
																									!isEmptyVariable(item.processNameList) &&
																									<span 
                                                                                                    style={{
                                                                                                        color: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`,
                                                                                                        border: `1px solid rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`
                                                                                                    }}
                                                                                                    class="blue-btn">
																										<i>{item.processNameList}</i>
																									</span>
																								}
																								{
																									!isEmptyVariable(item.riskLevel) &&
																									<span 
                                                                                                    style={{
                                                                                                        border: `1px solid rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.15)`,
                                                                                                        background: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},0.15)`,
                                                                                                        color: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`,
                                                                                                    }}
                                                                                                    class="orange-btn">
																										<i>{item.riskLevel}</i>
																									</span>
																								}
																							</div>
																						</div>
																					)
																				})}
																			</div>
																		</div>
																	</div>
																</div>
															</Slider>
														</div>
													</div>
													)}
												</div>
											</div>
										</div>
								}
								{/************Progress of current assignment**************/}

								{/********************Figure And Table********************/}
								{
									(this.state.componentDidMountFlag && this.state.dashboardDisplayPlaceholder===figureAndTable) &&
									(
										<FigureAndTable
										criteriaList = {this.state.criteria}
										handleUpdateClick ={this.handleUpdateClick}
										selectedCriteria = {this.state.selectedCriteria}
										editCriteriaName={this.state.editCriteriaName}
										criteriaPlaceholder={this.state.criteriaPlaceholder}
										showLoader ={this.ShowingLoader}
										selectedUsersApproval={this.selectedUsersApproval}
										showSelectedUsersApproval={this.state.approvalUserCount > 0}
										displayDropdownItemClick={this.displayDropdownItemClick}
										criteriaDropdownItemClick={this.criteriaDropdownItemClick}
										updateCriteria={this.updateCriteria}
										/>
									)
								}	

								{
									this.state.componentDidMountFlag && this.state.dashboardDisplayPlaceholder===summary &&
									(
										<SummaryContent 
										showLoader ={this.ShowingLoader}
										/>
										
									)
								}
								{this.state.selectedUsersApprovalFlag && 
                                <ApprovedUser 
                                    selectedUsersApprovalFlag={this.state.selectedUsersApprovalFlag} 
                                    onCancleButtonClick={this.selectedUsersApprovalClose}
				                />}
                                
							</div>
						</div>
					</section>
				</main>
			</Router>
		);
	}
}

export default Dashboard;
