import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { SearchSpan, DialogSaveButton, TableCheckBox } from "../../../common/Global/globalStyles.style";
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";
import Pagination from "../../../common/Global/pagination";
import {
	getLocalStorageVariables,
	ifEmptyReturnEmptyStr,
	isEmptyVariable,
	sortTable,
	truncateString,
	isEmptyArray,
	SplitJoinString,
} from "../../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";
import RegularDropdown from "../../../common/DropdownMenus/RegularDropdown";
import AlertDialog from "../../../common/AlertDialog";

const userDetails = getLocalStorageVariables();

const sortShortName = "shortName";
const sortName = "name";
const sortDescription = "description";
const sortDeptName = "deptName";
const sortLevel = "levels";

const departmentDropdownDefaultValue = "Select Department";
const departmentFilterDropdownDefaultValue = "All";
const activeStatus = "Active";
const inActiveStatus = "Inactive";
const requestChangeCheckText = "Request Change";

class DocumentSampleList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,
			apiSearchKey: "",
			searchkey: "",
			currentPageNo: 1,
			showLoader: false,
			resultSize: Constants.RESULT_SIZE,
			totalCount: 0,
			datalist: [],
			resultSizePlaceholder: Constants.RESULT_SIZE ,
			resultSizeArr: JSON.parse(JSON.stringify(Constants.RESULT_SIZE_DD_ARR)),
			sort: "",
			sortDir: "",

			selectedId: "",
			selectedRiskId: "",

			departmentArr: [],
			selectedDepartmentName: departmentDropdownDefaultValue,
			selectedDepartmentId: "",
			formErrors: {},

			showTableFlag: false,
			showDetailsDialog: false,
			selectedItem: "",
			selectedRiskLevels: [],
			showDialogLoader: false,
			showAlertDialogInfo:false,
            alertDialogMessageInfo:""
		};
	}
	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showCopyRiskMasterDialog) {
			this.setState(
				{
					showLoader: true,
					showTableFlag: false,
					searchkey: "",
					currentPageNo: 1,
					resultSize: JSON.parse(JSON.stringify(Constants.RESULT_SIZE)),
					resultSizeArr: JSON.parse(JSON.stringify(Constants.RESULT_SIZE_DD_ARR)),
					sort: "",
					sortDir: "",
					resultSizePlaceholder: Constants.RESULT_SIZE ,
				},
				() => {
					this.getInitData();
				}
			);
		}
	}

	handleChangeSearch = (e) => {
		const { name, value } = e.target;

		this.setState({
			[name]: value,
		});
	};

	onSearchIconClick = () => {
		this.setState(
			{
				currentPageNo: 1,
			},
			() => {
				this.getRiskMastersList();
			}
		);
	};

	onEnterBtnPress = (e) => {
		var code = e.keyCode || e.which;
		if (code === 13) {
			this.setState(
				{
					currentPageNo: 1,
				},
				() => {
					this.getRiskMastersList();
				}
			);
		}
	};
	sortTableLocal = (sortColumn) => {
		let sortObj = sortTable(sortColumn, this.state.sort, this.state.sortDir);

		this.setState(
			{
				sort: sortObj.sortTemp,
				sortDir: sortObj.sortDirTemp,
			},
			() => {
				this.getRiskMastersList();
			}
		);
	};

	handleStopPropagation = (e) => {
		e.stopPropagation();
	};
	handleDetailsDialogClose = () => {
		this.setState({
			showDetailsDialog: false,
			selectedItem: "",
			selectedRiskLevels: [],
			selectedRiskId: "",
		});
	};

	handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
    }

	handleDetailsDialogShow = (item) => {
		this.setState(
			{
				showDetailsDialog: true,
				showDialogLoader: true,
				selectedRiskId: item.riskId,
			},
			() => {
				this.getRiskDetails(false);
			}
		);
	};

	resultSizeDropdownClick = (item, selectedIndex) => {
		let tempArr = this.state.resultSizeArr;
		let placeholder = "";

		for (const [i, value] of tempArr.entries()) {
			if (i === selectedIndex) {
				tempArr[i].isSelected = true;
				placeholder = value.label ;
			} else {
				tempArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				resultSizeArr: tempArr,
				resultSize: item.label,
				resultSizePlaceholder: placeholder,
			},
			() => {
				this.getRiskMastersList();
			}
		);
	};

	deptFilterDropdownClick = (item, selectedIndex) => {
		let placeholder = "";
		let id = "";
		let filterDeptArr = this.state.filterDeptArr;

		for (const [i, value] of filterDeptArr.entries()) {
			if (i === selectedIndex) {
				filterDeptArr[i].isSelected = true;
				placeholder = value.deptName;
				id = value.departmentId;
			} else {
				filterDeptArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				currentPageNo: 1,
				selectedFilterDepartmentName: placeholder,
				selectedFilterDepartmentId: id,
			},
			() => {
				this.getRiskMastersList();
			}
		);
	};

	handleSelection = (e) => {
		e.stopPropagation();
		let selectedId = "";
		let index = e.target.name;

		if (this.state.selectedId !== this.state.datalist[index].riskId) {
			selectedId = this.state.datalist[index].riskId;
		}
		this.setState({
			selectedId: selectedId,
		});
	};

	deptDropdownClick = (item) => {
		let FormErrorsTemp = this.state.formErrors;

		if (this.state.submitClickedAtleastOnce) {
			FormErrorsTemp["deparment"] = "";
		}

		this.setState(
			{
				currentPageNo: 1,
				selectedDepartmentName: item.name,
				selectedDepartmentId: item.departmentId,
				formErrors: FormErrorsTemp,
			},
			() => {
				this.getInitDataMultiple();
			}
		);
	};
	/*****************************API*********************************/

	getInitData = () => {
		Promise.all([
			fetch(Constants.GetRiskMastersCreateMasterData, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
				}),
			}),
			fetch(Constants.GetCopyRiskMasterData, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					pageNo: this.state.currentPageNo,
					search: this.state.searchkey,
					resultsize: this.state.resultSize,
					sort: this.state.sort,
					sortDir: this.state.sortDir,
					// departmentOnly: this.state.deptOnly,
				}),
			}),
		])
			.then(([masterRes, generalRes]) => {
				return Promise.all([masterRes.json(), generalRes.json()]);
			})
			.then(([masterRes, generalRes]) => {
				let datalistTemp = [];
				let totalCountTemp = 0;

				let deptTemp = [];

				if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
					deptTemp = masterRes.data.departments;
				} else {
					deptTemp = [];
				}

				if (generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (generalRes.responseCode === Constants.CODE_SUCCESS) {
					datalistTemp = generalRes.data.result;
					totalCountTemp = generalRes.data.count;
				} else {
					datalistTemp = [];
				}

				//if user belongs to single dept then dont show the dropdown
				let selectedDepartmentId = "";
				let selectedDepartmentName = departmentDropdownDefaultValue;
				let showTableFlag = false;
				if (!isEmptyArray(deptTemp) && deptTemp.length === 1) {
					selectedDepartmentId = deptTemp[0].departmentId;
					selectedDepartmentName = deptTemp[0].name;

					showTableFlag = true;
				}

				//Filter Dept array
				let filterDeptArr = [];
				let selectedFilterDepartmentId = "-100";
				let selectedFilterDepartmentName = "All";
				if (!isEmptyArray(deptTemp) && deptTemp.length === 1) {
				} else {
					let obj = {
						departmentId: "-100",
						deptName: departmentFilterDropdownDefaultValue,
						isSelected: true,
					};
					filterDeptArr = [...deptTemp];
					filterDeptArr.splice(0, 0, obj);
				}

				this.setState({
					componentDidMountFlag: true,
					showLoader: false,

					datalist: datalistTemp,
					totalCount: totalCountTemp,

					departmentArr: deptTemp,

					filterDeptArr: filterDeptArr,
					selectedFilterDepartmentId: selectedFilterDepartmentId,
					selectedFilterDepartmentName: selectedFilterDepartmentName,

					selectedDepartmentId: selectedDepartmentId,
					selectedDepartmentName: selectedDepartmentName,

					formErrors: {},
					selectedId: "",
					selectedRiskId: "",

					showTableFlag: showTableFlag,
				});
			});
	};

	getInitDataMultiple = () => {
		this.setState({
			showLoader: true,
			showTableFlag: false,
		});

		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			pageNo: this.state.currentPageNo,
			search: this.state.searchkey,
			resultsize: this.state.resultSize,
			sortDir: this.state.sortDir,
			sort: this.state.sort,

			//TODO
			status: "Active",
		};

		if (this.state.departmentArr.length > 1 && this.state.selectedDepartmentName !== departmentFilterDropdownDefaultValue) {
			// exclude this department
			postParam.departmentId = this.state.selectedDepartmentId;
		}

		if (this.state.filterDeptArr.length > 1 && this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue) {
			postParam.copyFromDepartmentId = this.state.selectedFilterDepartmentId;
		}

		fetch(Constants.GetCopyRiskMasterData, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParam),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					let targetFilterDeptTemp = [];
					targetFilterDeptTemp = data.data.departments;
					//Filter Dept array
					let filterDeptArr = [];
					let selectedFilterDepartmentId = "-100";
					let selectedFilterDepartmentName = "All";
					if (!isEmptyArray(targetFilterDeptTemp) && targetFilterDeptTemp.length === 1) {
					} else {
						let obj = {
							departmentId: "-100",
							deptName: departmentFilterDropdownDefaultValue,
							isSelected: true,
						};
						filterDeptArr = [...targetFilterDeptTemp];
						filterDeptArr.splice(0, 0, obj);
					}
					this.setState({
						showLoader: false,
						datalist: data.data.result,
						totalCount: data.data.count,
						apiSearchKey: this.state.searchkey,
						showTableFlag: true,

						filterDeptArr: filterDeptArr,
						selectedFilterDepartmentId: selectedFilterDepartmentId,
						selectedFilterDepartmentName: selectedFilterDepartmentName,
					});
				} else {
					this.setState({
						datalist: [],
						showLoader: false,
						totalCount: 0,
						componentDidMountFlag: true,
						apiSearchKey: this.state.searchkey,
					});
				}
			});
	};

	getRiskMastersList = () => {
		this.setState({
			showLoader: true,
			showTableFlag: false,
		});

		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			pageNo: this.state.currentPageNo,
			search: this.state.searchkey,
			resultsize: this.state.resultSize,
			// status: this.state.status,
			sort: this.state.sort,
			sortDir: this.state.sortDir,
		};

		if (this.state.departmentArr.length > 1 && this.state.selectedDepartmentName !== departmentFilterDropdownDefaultValue) {
			// exclude this department
			postParam.departmentId = this.state.selectedDepartmentId;
		}

		if (this.state.filterDeptArr.length > 1 && this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue) {
			postParam.copyFromDepartmentId = this.state.selectedFilterDepartmentId;
		}

		fetch(Constants.GetCopyRiskMasterData, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParam),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState({
						showLoader: false,
						datalist: data.data.result,
						totalCount: data.data.count,
						apiSearchKey: this.state.searchkey,
						showTableFlag: true,
					});
				} else {
					this.setState({
						datalist: [],
						showLoader: false,
						totalCount: 0,
						componentDidMountFlag: true,
						apiSearchKey: this.state.searchkey,
					});
				}
			});
	};

	onChangePage = (page) => {
		// update state with new page of items
		if (page !== this.state.currentPageNo) {
			this.setState({
				showLoader: true,
			});

			let postParam = {
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				pageNo: page,
				search: this.state.searchkey,
				resultsize: this.state.resultSize,
				// status: this.state.status,
				// departmentOnly: this.state.deptOnly,
				sort: this.state.sort,
				sortDir: this.state.sortDir,

				status: "Active",
			};

			if (this.state.departmentArr.length > 1 && this.state.selectedDepartmentName !== departmentFilterDropdownDefaultValue) {
				postParam.departmentId = this.state.selectedDepartmentId;
			}
			if (this.state.filterDeptArr.length > 1 && this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue) {
				postParam.copyFromDepartmentId = this.state.selectedFilterDepartmentId;
			}

			fetch(Constants.GetCopyRiskMasterData, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(postParam),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (data.responseCode === Constants.CODE_SUCCESS) {
						this.setState({
							showLoader: false,
							datalist: data.data.result,
							totalCount: data.data.count,
							apiSearchKey: this.state.searchkey,
							currentPageNo: page,
						});
					} else {
						this.setState({
							datalist: [],
							showLoader: false,
							apiSearchKey: this.state.searchkey,
						});
					}
				});
		}
	};

	getRiskDetails = (forEdit) => {
		fetch(Constants.GetRiskDetails, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams({
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				riskId: this.state.selectedRiskId,
			}),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					let selectedItem = data.data.risk;
					let selectedRiskLevels = data.data.riskLevels;
					this.setState({
						selectedItem: selectedItem,
						selectedRiskLevels: selectedRiskLevels,
						showDialogLoader: false,
					});
				} else {
					this.setState({
						showDialogLoader: false,
					});
				}
			});
	};

	handleCopyRiskMaster = () => {
		this.setState({
			showLoader: true,
		});

		let isError = false;
		let FormErrorsTemp = {};

		if (isEmptyVariable(this.state.selectedDepartmentName) || this.state.selectedDepartmentName === departmentDropdownDefaultValue) {
			isError = true;
			FormErrorsTemp["deparment"] = "Please select the department";
		}

		if (isEmptyVariable(this.state.selectedId)) {
			isError = true;
			FormErrorsTemp["selectId"] = "Please select the Risk";
		}

		if (isError) {
			this.setState({
				formErrors: FormErrorsTemp,
				showLoader: false,
			});
		} else {
			fetch(Constants.CopyRisk, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					copyFromId: this.state.selectedId,
					departmentId: this.state.selectedDepartmentId,
				}),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (data.responseCode === Constants.CODE_SUCCESS) {
						this.setState(
							{
								showLoader: false,
							},
							() => {
								this.props.handleCopyRiskMasterClose();
							}
						);
					} else {
						this.setState({
							showLoader: false,
							showAlertDialogInfo:true,
            				alertDialogMessageInfo:data.responseMessage
						});
					}
				});
		}
	};

	/**************************************************************/

	render() {
		return (
			<section>
				<Modal className="task-select-table-dialog custom-dialog" show={this.props.showCopyRiskMasterDialog} backdrop="static">
					<Modal.Header>
						<h5>Copy Risk</h5>
						<button onClick={this.props.handleCopyRiskMasterClose} type="button" data-dismiss="modal">
							<span class="material-icons">close</span>
						</button>
					</Modal.Header>
					<hr />
					<div className="modal-body">
						{this.state.showLoader && (
							<div class="loader"></div>
						)}

						{
							// Need to check component did mount flag, other wise until api is called,
							// it shows no items found layout.
							this.state.componentDidMountFlag && (
								<div className="body-wrapper">
									{!isEmptyArray(this.state.departmentArr) && this.state.departmentArr.length > 1 && (
										<div class="addnew-modal-form-group">
											<p>Choose Your Department*</p>
											<RegularDropdown
												placeholder={this.state.selectedDepartmentName}
												dropdownArr={this.state.departmentArr}
												labelParam="name"
												onDropDownItemClick={this.deptDropdownClick}
												defaultPlaceholderDropDown = {departmentDropdownDefaultValue}
											/>
											{!isEmptyVariable(this.state.formErrors["deparment"]) && <span class="cm-error">{this.state.formErrors["deparment"]}</span>}
										</div>
									)}
									{this.state.showTableFlag && (
										<div>
											<div className="search-addnew-bg">
												<div className="search-addnew">
													<div className="search-filter-layout">
														<FilterDropDown
															placeholder={this.state.resultSizePlaceholder}
															dropdownArr={this.state.resultSizeArr}
															onDropDownItemClick={this.resultSizeDropdownClick}
															themeSettings={this.props.themeSettings}
															mleft={0}
														/>
														<div className="search-col m-l-10">
															<input
																name="searchkey"
																type="text"
																onChange={this.handleChangeSearch}
																placeholder="Search"
																onKeyPress={this.onEnterBtnPress}
																value={this.state.searchkey}
															/>
															<SearchSpan
																themeSettings={this.props.themeSettings}
																onClick={this.onSearchIconClick}
																className="material-icons"
															>
																{" "}
																search{" "}
															</SearchSpan>
														</div>

														

														{this.state.filterDeptArr.length > 0 && (
															<FilterDropDown
																placeholder={this.state.selectedFilterDepartmentName}
																dropdownArr={this.state.filterDeptArr}
																onDropDownItemClick={this.deptFilterDropdownClick}
																name={"deptName"}
																themeSettings={this.props.themeSettings}
																dropdownWidth={"120px"}
															/>
														)}
													</div>
												</div>
											</div>
											{!isEmptyArray(this.state.datalist) && (
												<div className="common-tab-bg">
													<div className="common-table" style={{ width: "100%" }}>
														<table className="table">
															<thead>
																<tr>
																	<th className="text-center" width={"8%"}></th>
																	<th className="text-center" width={"5%"}>
																		No
																	</th>
																	<th width={"12%"} className="c-pointer" onClick={this.sortTableLocal.bind(this, sortShortName)}>
																		<div className="sort-header">
																			Short Name
																			<span
																				className={
																					this.state.sort === sortShortName
																						? "material-icons"
																						: "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortName)}>
																		<div className="sort-header">
																			Name
																			<span
																				className={
																					this.state.sort === sortName ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	<th width={"10%"} className="c-pointer" onClick={this.sortTableLocal.bind(this, sortLevel)}>
																		<div className="sort-header">
																			Levels
																			<span
																				className={
																					this.state.sort === sortLevel ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortDescription)}>
																		<div className="sort-header">
																			Description
																			<span
																				className={
																					this.state.sort === sortDescription
																						? "material-icons"
																						: "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>

																	<th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortDeptName)}>
																		<div className="sort-header">
																			Department
																			<span
																				className={
																					this.state.sort === sortDeptName ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																</tr>
															</thead>
															<tbody>
																{!isEmptyArray(this.state.datalist) &&
																	this.state.datalist.map((item, idx) => {
																		return (
																			<tr onClick={this.handleDetailsDialogShow.bind(this, item)}>
																				<td>
																					<TableCheckBox
																						onClick={this.handleStopPropagation}
																						themeSettings={this.props.themeSettings}
																					>
																						<input
																							type="checkbox"
																							name={idx}
																							id={item.riskId}
																							onChange={this.handleSelection}
																							checked={this.state.selectedId === item.riskId ? true : false}
																						/>
																						<label for={item.riskId} style={{ marginLeft: 6 }}></label>
																					</TableCheckBox>
																				</td>
																				<td className="text-center">
																					{(this.state.currentPageNo - 1) * this.state.resultSize + ++idx}
																				</td>
																				<td>{item.shortName}</td>
																				<td>{item.name}</td>
																				<td>{item.levels}</td>
																				<td>{truncateString(item.description, Constants.OTHERMASTERS_STRING_LENGTH)}</td>
																				<td>{item.deptName}</td>
																			</tr>
																		);
																	})}
															</tbody>
														</table>
													</div>
												</div>
											)}
											{!isEmptyVariable(this.state.formErrors["selectId"]) && <span class="cm-error">{this.state.formErrors["selectId"]}</span>}

											{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
												<div class="no-items-layout">
													<div class="no-items-card">
														<h6>
															{isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}
														</h6>
													</div>
												</div>
											)}
											<div className="pagination-layout">
												<div className="row custom-row">
													<div className="col-md-9 custom-col">
														{this.state.totalCount > this.state.datalist.length && (
															<Pagination
																totalLength={this.state.totalCount}
																items={this.state.datalist}
																onChangePage={this.onChangePage}
																pageSize={this.state.resultSize}
																currentPageNo={this.state.currentPageNo}
																initialPage={this.state.currentPageNo}
																themeSettings={this.props.themeSettings}
															/>
														)}
													</div>
													<div className="col-md-3 custom-col">
														<p>{"Total Records: " + this.state.totalCount}</p>
													</div>
												</div>
											</div>
										</div>
									)}
									{/* <div class="addnew-modal-form-group">
										{!isEmptyVariable(this.state.formErrors["apiErr"]) && <span class="cm-error">{this.state.formErrors["apiErr"]}</span>}
									</div> */}
									<div class="modal-close-save">
										<div class="close-save-col">
											<button onClick={this.props.handleCopyRiskMasterClose} type="button" class="modal-close">
												Cancel
											</button>
											<DialogSaveButton themeSettings={this.props.themeSettings} onClick={this.handleCopyRiskMaster} type="button">
												Save
											</DialogSaveButton>
										</div>
									</div>
								</div>
							)
						}
					</div>
					<Modal className="custom-dialog risk-details" show={this.state.showDetailsDialog} onHide={this.handleDetailsDialogClose}>
						<Modal.Header>
							<h5>{this.state.selectedItem.shortName}</h5>
							<button onClick={this.handleDetailsDialogClose} type="button" data-dismiss="modal">
								<span class="material-icons">close</span>
							</button>
						</Modal.Header>
						<hr />
						<div class="modal-body">
							{this.state.showDialogLoader && (
								<div class="loader"></div>
							)}
							<div className="details-table-layout">
								<div className="row custom-row">
									<div className="col-md-6 custom-col">
										<table>
											<tbody>
												<tr>
													<td width={"24%"}>Short Name</td>
													<td width={"4%"}>:</td>
													<td width={"72%"}>{this.state.selectedItem.shortName}</td>
												</tr>
												<tr>
													<td width={"24%"}>Name</td>
													<td width={"4%"}>:</td>
													<td width={"72%"}>{this.state.selectedItem.name}</td>
												</tr>
												<tr>
													<td width={"24%"}>Description</td>
													<td width={"4%"}>:</td>
													<td width={"72%"}>{ifEmptyReturnEmptyStr(this.state.selectedItem.description, "-")}</td>
												</tr>
												<tr>
													<td width={"24%"}>Levels</td>
													<td width={"4%"}>:</td>
													<td width={"72%"}>{this.state.selectedItem.levels}</td>
												</tr>
												<tr>
													<td width={"24%"}>Clients</td>
													<td width={"4%"}>:</td>
													<td width={"72%"}>
														{ifEmptyReturnEmptyStr(SplitJoinString(this.state.selectedItem.companyList, ",", ":-:", ","), "-")}
													</td>
												</tr>
												<tr>
													<td width={"24%"}>Departments</td>
													<td width={"4%"}>:</td>
													<td width={"72%"}>{this.state.selectedItem.deptName}</td>
												</tr>
												{this.state.selectedItem.status !== activeStatus && this.state.selectedItem.status !== inActiveStatus && (
													<tr>
														<td width={"24%"}>Review Status</td>
														<td width={"4%"}>:</td>
														<td width={"72%"}>{this.state.selectedItem.currentReviewStatus}</td>
													</tr>
												)}
												{this.state.selectedItem.allowReview === Constants.ALLOW_REVIEW_SUBMITREVIEW &&
													this.state.selectedItem.currentReviewStatus.includes(requestChangeCheckText) && (
														<tr>
															<td width={"24%"}>Review Remarks</td>
															<td width={"4%"}>:</td>
															<td width={"72%"}>{this.state.selectedItem.lastReviewRemark}</td>
														</tr>
													)}
											</tbody>
										</table>
									</div>
									<div className="col-md-6 custom-col">
										<table>
											<tbody>
												{this.state.selectedRiskLevels.map((item, idx) => {
													return (
														<tr>
															<td width={"24%"}>Risk Level</td>
															<td width={"4%"}>:</td>
															<td width={"72%"}>
																<div className="flex-center-layout">
																	{item.riskLevel}
																	<div className="risk-level-color-wrapper">
																		<div className="risk-level-color" style={{ background: item.colorCode }} />
																	</div>
																</div>
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</Modal>
				</Modal>
				<AlertDialog
					showAlertDialog={this.state.showAlertDialogInfo}
					handleAlertDialogClose={this.handleAlertDialogCloseInfo}
					type={Constants.ALERT_TYPE_ALERT}
					alertDialogMessage={this.state.alertDialogMessageInfo}
					proceedBtnClick={this.handleAlertDialogCloseInfo}
					proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
					themeSettings={this.props.themeSettings}
				/>
			</section>
		);
	}
}
export default DocumentSampleList;
