import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {PageSaveButton,TableCheckBox,CustomTableRow,DialogSaveButton} from '../../../common/Global/globalStyles.style';
// import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
// import AlertDialog from '../../../common/AlertDialog';
import {getLocalStorageVariables,getThemeSettingsVariables,isEmptyVariable,truncateString,
    getStatusSpan,removeHtmlTags,isEmptyArray,ifEmptyReturnEmptyStr} from '../../../common/Global/commonFunctions';
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdownTable';
import {Modal} from 'react-bootstrap';
import ProcessFunctionListDialog from "../ProcessFunctionsDialog/processFunctionsList"
import AlertDialog from '../../../common/AlertDialog';

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();

const activeStatus="Active";
// const inActiveStatus="Inactive";
// const underReviewStatus="Under Review";
const wrankingChecklist = "WR";
const defaultWeightPlaceholder="Select Weight";
const weightedRankingArr = [
    {label:1,id:1},
    {label:2,id:2},
    {label:3,id:3},
    {label:4,id:4},
    {label:5,id:5},
    {label:6,id:6},
    {label:7,id:7},
    {label:8,id:8},
    {label:9,id:9},
    {label:10,id:10}
];

class OtherMasters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            resultSizePlaceholder:Constants.RESULT_SIZE,
            status:activeStatus,

            processMap:[],
            processId:this.props.processId,

            checkListTasks:[],
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            allTaskIds:[],
            nextTaskId:"",
            prevTaskId:"",

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showTaskDetailsLayout:false,
            selectedTaskIdForDetails:"",
            user:{},

            guidanceDialogFlag:false,
            isEdit:false,
            selectedRankingGuidance:"",
            selectedRankingGuidanceIndex:"",
            // formErrors:{},
            // addErrors:{},

            showProcessFunctionsDialog:false,
        }
    }

    componentDidMount(){
        this.getInitData();
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props)){
            this.setState({
                componentDidMountFlag:false,
                apiSearchKey:"",
                searchkey:"",
                currentPageNo: 1,
                showLoader:false,
                resultSize:Constants.RESULT_SIZE,
                totalCount:0,
                datalist:[],
                resultSizePlaceholder:Constants.RESULT_SIZE,
                status:activeStatus,

                processMap:[],
                processId:this.props.processId,

                checkListTasks:[],
                resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
                allTaskIds:[],
                nextTaskId:"",
                prevTaskId:"",

                showAlertDialogInfo:false,
                alertDialogMessageInfo:"",

                showTaskDetailsLayout:false,
                selectedTaskIdForDetails:"",
                user:{},

                guidanceDialogFlag:false,
                isEdit:false,
                selectedRankingGuidance:"",
                selectedRankingGuidanceIndex:"",
                // formErrors:{},
                // addErrors:{},

                showProcessFunctionsDialog:false,
            },()=>{
                this.getInitData();
            })
            
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleGuidanceDialogClose = () => {
        this.setState({
            guidanceDialogFlag:false,
            isEdit:false,
            selectedRankingGuidance:"",
            selectedRankingGuidanceIndex:"",
        })
    }

    handleAddGuidanceDialogShow = (processId, index) => {
        this.setState({
            guidanceDialogFlag:true,
            isEdit:false,
            selectedRankingGuidance:"",
            selectedRankingGuidanceIndex:index+":-:"+processId,
        })
    }

    handleEditGuidanceDialogShow = (processId, index) => {
        let list = this.state.datalist;
        //if not disabled then show the edit dialog
        if(!list[processId].taskList[index].disabled){
            this.setState({
                guidanceDialogFlag:true,
                isEdit:true,
                selectedRankingGuidance:list[processId].taskList[index].rankingGuidance,
                selectedRankingGuidanceIndex:index+":-:"+processId,
            })
        }
    }

    handleAddRankingGuidance = () => {
        let iapArr = this.state.selectedRankingGuidanceIndex.split(":-:");
        let index = iapArr[0];
        let processId = iapArr[1];
        let list = this.state.datalist;

        list[processId].taskList[index].rankingGuidance = this.state.selectedRankingGuidance;
        let taskObj = list[processId].taskList[index];
        this.setState({
            datalist:list,

            guidanceDialogFlag:false,
            isEdit:false,
            selectedRankingGuidance:"",
            selectedRankingGuidanceIndex:"",
        },()=> {
            if(taskObj.isAlreadySelected){
                this.updateChecklistTaskProperties(taskObj.checkListTaskId,taskObj.weight,taskObj.rankingGuidance);
            }
            
        });
    }

    onCancelClick = () => {
        this.props.handleBackBtnClick();
    }

    handleProcessFuncDialogClose = () => {
        this.setState({
            showProcessFunctionsDialog:false,
        })
    }

    handleProcessFuncDialogShow = () => {
        this.setState({
            showProcessFunctionsDialog:true,
        })
    }

    //No need of return value since the array itself is passed and updated.
    addParentIdandHasChildrenFlag = (tempArr, processId, parentProcessId, parentProcessBreadCrumb) => {
        tempArr[processId].parentProcessId = parentProcessId;
        let breadCrumb = "";
        if(isEmptyVariable(parentProcessBreadCrumb)){
            breadCrumb = tempArr[processId].processName;
            tempArr[processId].breadCrumb = breadCrumb;
        }else{
            breadCrumb = parentProcessBreadCrumb + " > " + tempArr[processId].processName;
            tempArr[processId].breadCrumb = breadCrumb;
        }

        if(!isEmptyArray(tempArr[processId].children)){
            tempArr[processId].hasChildren = true;

            tempArr[processId].children.map(childNode => {
                this.addParentIdandHasChildrenFlag(tempArr,childNode.processId,processId,breadCrumb);
            });
        }else{
            tempArr[processId].hasChildren = false;
        }
    }

    handleShowTaskDetailsLayout = (taskId) => {
        let taskIdIndex = this.state.allTaskIds.indexOf(taskId);
        let prevIndex = -1;
        let nextIndex = -1;

        if(taskIdIndex > -1){
            prevIndex = taskIdIndex-1;
            nextIndex = taskIdIndex+1;

            if(nextIndex >= this.state.allTaskIds.length){
                nextIndex = -1;
            }
        }

        this.setState({
            showTaskDetailsLayout:true,
            selectedTaskIdForDetails:taskId,
            prevTaskId:prevIndex > -1?this.state.allTaskIds[prevIndex]:"",
            nextTaskId:nextIndex > -1?this.state.allTaskIds[nextIndex]:"",
        })
    }

    handleHideTaskDetailsLayout = () => {
        this.setState({
            showTaskDetailsLayout:false,
            selectedTaskIdForDetails:""
        })
    }

    handleStopPropagation = (e) => {
        e.stopPropagation();
    }

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
    }

    handleSelection = (e) => {
        e.stopPropagation();
        let isChecked = e.target.checked;
        let indexAndProcessId = e.target.name;
        let iapArr = indexAndProcessId.split(":-:")
        let index = iapArr[0];
        let processId = iapArr[1];
        let list = this.state.datalist;

        list[processId].taskList[index].isSelected = isChecked;

        //check whether all the tasks with in proc are selected
        let atleastOneNotSelected = list[processId].taskList.some(item => !item.isSelected)
        list[processId].isProcSelected = !atleastOneNotSelected;

        this.setState({
            datalist:list
        });
    }

    handleSelectionProcessTasks = (e) => {
        e.stopPropagation();
        let isChecked = e.target.checked;
        let processId = e.target.name;
        let list = this.state.datalist;

        list[processId].isProcSelected = isChecked;

        // also select all the tasks
        let newTasklist = list[processId].taskList.map(item=>{
            if(!item.disabled){ //if item is not disabled then only change it
                item.isSelected = isChecked
            }
            return item;
        })
        list[processId].taskList = newTasklist;
        this.setState({
            datalist:list
        });
    }
    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetProcessesSubtree,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    rootProcessId:this.state.processId,
                    status:"ALL",
                    activeFlag:"Y"//get values from active tables
                })
            }),
            fetch(Constants.GetAddTaskChecklistMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    processId:this.state.processId,
                    checkListVersionId:this.props.checklistItem.checkListVersionId
                })
            }),
            fetch(Constants.GetCheckListTasks,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    checkListVersionId:this.props.checklistItem.checkListVersionId
                })
            })
        ])
        .then(([masterRes,generalRes,clTasksRes]) => { 
            return Promise.all([masterRes.json(),generalRes.json(),clTasksRes.json()]) 
        })
        .then(([masterRes,generalRes,clTasksRes]) => {
            let processMapTemp = {};
            let datalistTemp = {};
            let allTaskIds = [];
            let checkListTasks = [];

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                processMapTemp = masterRes.result.processMap;
                this.addParentIdandHasChildrenFlag(processMapTemp,this.state.processId,this.state.processId,"");
            }else{
                processMapTemp = [];
            }

            if(clTasksRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                clTasksRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(clTasksRes.responseCode === Constants.CODE_SUCCESS){
                checkListTasks = clTasksRes.data.tasks;
            }else{
                checkListTasks = [];
            }

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                let datalistTempArr = generalRes.data.processList;//list of process and task
                allTaskIds = generalRes.data.allTaskIds;
                datalistTemp = {};

                datalistTempArr.map((item)=>{
                    console.log("ProcessID: "+item.processId);
                    if(!processMapTemp[item.processId].hasChildren){
                        datalistTemp[item.processId] = item;
                        datalistTemp[item.processId].currentPageNo = 1; //set the current page no as 1 for all the individual task lists
                    }

                    item.taskList.map((task,idx)=>{
                        //if it is already added to the list then make it checked
                        //if it is added but added to different heading then disable it

                        let checkedTask = checkListTasks.filter(checkedTask => checkedTask.taskId === task.taskId)

                        if(isEmptyArray(checkedTask)){
                            datalistTemp[item.processId].taskList[idx].isSelected = false;
                            datalistTemp[item.processId].taskList[idx].disabled = false;
                        }else{
                            datalistTemp[item.processId].taskList[idx].isSelected = true;
                            datalistTemp[item.processId].taskList[idx].isAlreadySelected = true;
                            datalistTemp[item.processId].taskList[idx].checklistTaskStatus = checkedTask[0].status;
                            // add weight and ranking guidance
                            datalistTemp[item.processId].taskList[idx].weight=checkedTask[0].weight;
                            datalistTemp[item.processId].taskList[idx].rankingGuidance=checkedTask[0].rankingGuidance;
                            datalistTemp[item.processId].taskList[idx].checkListTaskId=checkedTask[0].checkListTaskId;

                            //if heading id is same then dont disable the task
                            if(this.props.checkListHeadingId === checkedTask[0].checkListHeadingId){
                                datalistTemp[item.processId].taskList[idx].disabled = false;
                            }else{
                                datalistTemp[item.processId].taskList[idx].disabled = true;
                            }
                        }
                    })
                })
            }else{
                datalistTemp = [];
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                processMap:processMapTemp,
                checkListTasks:checkListTasks,
                allTaskIds:allTaskIds,
                datalist:datalistTemp,
            })
        })
    }

    submitChecklist = () => {

        this.setState({
            showLoader:true
        })

        let addTaskList = [];
        let removeTaskList = [];
        let isError = false;
        let formErr = {};
        
        Object.values(this.state.datalist).map(processObj=>{
            processObj.taskList.map(item=>{
                if(item.isSelected){
                    //now we need to check if the task is already added
                    let checkedTask = this.state.checkListTasks.filter(checkedTask => checkedTask.taskId === item.taskId)

                    if(isEmptyArray(checkedTask)){
                        //item is not there in already
                        let obj = {
                            taskId:item.taskId,
                            weight:isEmptyVariable(item.weight)?"":item.weight,
                            rankingGuidance:isEmptyVariable(item.rankingGuidance)?"":item.rankingGuidance,
                        }
                        addTaskList.push(obj)

                        //Weight is mandatory for WR checklist type
                        if(this.props.checklistItem.checkListType === wrankingChecklist){
                            if(isEmptyVariable(item.weight)){
                                isError = true;
                                // formErr["weightErr"] = "Please select weight for all the checked tasks"
                            }
                        }
                    }
                }else{
                    //if the item is selected before and now it is removed
                    //then we have add it to the removed task
                    if(item.isAlreadySelected){
                        let obj = {
                            taskId:item.taskId,
                        }
                        removeTaskList.push(obj)
                    }
                }
            })
        })

        //now do the validation
        if(isError){
            this.setState({
                showAlertDialogInfo:true,
                alertDialogMessageInfo:"Please select weight for all the checked tasks"
            })
        }else{
            fetch(Constants.AddRemoveCheckListTasks,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    checkListVersionId:this.props.checklistItem.checkListVersionId,
                    checkListHeadingId:this.props.checkListHeadingId,
                    addTaskList:JSON.stringify(addTaskList),
                    removeTaskList:JSON.stringify(removeTaskList)
                })
            })
            .then(response => { return response.json(); } )
            .then(data => {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.props.handleBackBtnClick();
                }else{
                    this.setState({
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    })
                }
            })
        }
        

        
    }

    maxrDropdownClick = (item,udf) => {
        let iapArr = udf.split(":-:")
        let index = iapArr[0];
        let processId = iapArr[1];
        let list = this.state.datalist;

        list[processId].taskList[index].weight = item.id;
        let taskObj = list[processId].taskList[index];
        this.setState({
            datalist:list
        },()=>{
            if(taskObj.isAlreadySelected){
                this.updateChecklistTaskProperties(taskObj.checkListTaskId,taskObj.weight,taskObj.rankingGuidance);
            }
        });
    }


    updateChecklistTaskProperties = (checkListTaskId,weight,rankingGuidance) => {
        fetch(Constants.UpdateChecklistTaskProperties,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                checkListTaskId:checkListTaskId,
                weight:isEmptyVariable(weight)?"":weight,
                rankingGuidance:isEmptyVariable(rankingGuidance)?"":rankingGuidance,
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                
            }else{
                //TODO go back to the previous value and show error dialog
            }
        });
    }
    /*******************API CALLS END HERE*******************/

    render() {
        return(
            <div>
                {
                    this.state.showLoader &&
                    <div class="loader"></div>
                }
                {
                    // Need to check component did mount flag, other wise until api is called,
                    // it shows no items found layout.
                    this.state.componentDidMountFlag &&
                    !this.state.showTaskDetailsLayout &&
                    <div className="body-wrapper">
                        <div className="flex-center-layout secondary-top-bar">
                            <div className="back-btn-layout" onClick={this.props.handleBackBtnClick}>
                                <span class="material-icons-outlined">arrow_back_ios</span>
                            </div>
                            <h6>{this.props.checklistItem.checkListName+" > "+this.props.checkListHeading}</h6>
                        </div>
                        {/* <div className="search-addnew-bg">
                            <div className="search-addnew">
                                
                                <div className="search-filter-layout">
                                    <div className="search-col">
                                        <input 
                                            name="searchkey" 
                                            type="text"
                                            onChange={this.handleChangeSearch}
                                            placeholder="Search" 
                                            onKeyPress={this.onEnterBtnPress}
                                            value={this.state.searchkey}
                                        />
                                        <SearchSpan 
                                        themeSettings={themeSettings}
                                        onClick = {this.onSearchIconClick} 
                                        className="material-icons"> search </SearchSpan>
                                    </div>
                                    <FilterDropDown
                                        placeholder={this.state.resultSizePlaceholder}
                                        dropdownArr={this.state.resultSizeArr}
                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                        themeSettings={themeSettings}
                                    />
                                </div>
                            </div>
                        </div> */}

                        {
                            Object.values(this.state.datalist).map((processObj,index)=>{
                                return <div className="process-task-list">
                                    <h6 style={{marginTop:index === 0?15:10}}>{this.state.processMap[processObj.processId].breadCrumb}</h6>
                                    {
                                        !isEmptyArray(processObj.taskList) && 
                                        <div className="common-tab-bg">
                                            <div class="common-table" style={{width: "100%", borderRadius: "4px"}}>
                                                <div className="table-custom-layout">
                                                    <div className="table-custom-header">
                                                        <div className="table-custom-header-row">
                                                            <p style={{width:"5%"}}>
                                                                <TableCheckBox onClick={this.handleStopPropagation}
                                                                    themeSettings={themeSettings}>
                                                                    <input 
                                                                        type="checkbox"
                                                                        name={processObj.processId}
                                                                        id={processObj.processId}
                                                                        onChange={this.handleSelectionProcessTasks}
                                                                        checked={processObj.isProcSelected?true:false}
                                                                    />
                                                                    <label for={processObj.processId} 
                                                                    style={{marginLeft:-3}}></label>
                                                                </TableCheckBox>
                                                            </p>
                                                            <p style={{width:"5%",justifyContent:"center"}}>No</p>
                                                            {
                                                                this.props.checklistItem.checkListType === wrankingChecklist &&
                                                                <p style={{width:"50%"}}>Task</p>
                                                            }
                                                            {
                                                                this.props.checklistItem.checkListType !== wrankingChecklist &&
                                                                <p style={{width:"65%"}}>Task</p>
                                                            }
                                                            
                                                            {
                                                                this.props.checklistItem.checkListType === wrankingChecklist &&
                                                                <p style={{width:"15%"}}>Weight</p>
                                                            }
                                                            <p style={{width:"25%"}}>Ranking Guidance</p>
                                                        </div>
                                                    </div>
                                                    
                                                    <div className="table-custom-body">
                                                    {
                                                        !isEmptyArray(processObj.taskList) && 
                                                        processObj.taskList.map((item,idx) => {
                                                            let weightedRankingArrObj = [];
                                                            if(this.props.checklistItem.checkListType === wrankingChecklist){
                                                                weightedRankingArrObj = weightedRankingArr.filter((item)=>
                                                                    item.id >= this.props.checklistItem.minWeight && item.id <= this.props.checklistItem.maxWeight
                                                                );
                                                                // weightedRankingArrObj.push({
                                                                //     label:"N/A",
                                                                //     id:-1,
                                                                // })
                                                            }
                                                            let spanObj = getStatusSpan(item.checklistTaskStatus,themeSettings);
                                                            return <CustomTableRow themeSettings={themeSettings}
                                                            className={item.disabled?"row-disable":""}>
                                                                {/* // onClick={this.handleShowTaskDetailsLayout.bind(this,item.taskId)}> */}
                                                                <div className="table-cell" style={{width:"5%"}}>
                                                                    <TableCheckBox onClick={this.handleStopPropagation}
                                                                        themeSettings={themeSettings}>
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={idx+":-:"+processObj.processId}
                                                                            id={item.taskId}
                                                                            onChange={this.handleSelection}
                                                                            disabled={item.disabled}
                                                                            checked={item.isSelected?true:false}
                                                                        />
                                                                        <label for={item.taskId} 
                                                                        style={{marginLeft:6}}></label>
                                                                    </TableCheckBox>
                                                                </div>
                                                                <p style={{width:"5%",justifyContent:"center"}}>{(idx+1)}</p>
                                                                {
                                                                    this.props.checklistItem.checkListType === wrankingChecklist &&
                                                                    <p style={{width:"50%"}}>
                                                                        {/* {removeHtmlTags(item.task)} */}
                                                                        <div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{__html: item.task}} />
                                                                        {
                                                                            !isEmptyVariable(spanObj.spanText) &&
                                                                            <span style={spanObj.spanStyle} className="span-tag">
                                                                                {spanObj.spanText}
                                                                            </span>
                                                                        }
                                                                    </p>
                                                                }
                                                                {
                                                                    this.props.checklistItem.checkListType !== wrankingChecklist &&
                                                                    <p style={{width:"65%"}}>
                                                                        {/* {removeHtmlTags(item.task)} */}
                                                                        <div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{__html: item.task}} />
                                                                        {
                                                                            !isEmptyVariable(spanObj.spanText) &&
                                                                            <span style={spanObj.spanStyle} className="span-tag">
                                                                                {spanObj.spanText}
                                                                            </span>
                                                                        }
                                                                    </p>
                                                                }
                                                                {
                                                                    this.props.checklistItem.checkListType === wrankingChecklist &&
                                                                    <p style={{width:"15%"}}>
                                                                        <RegularDropdown 
                                                                            placeholder={isEmptyVariable(item.weight)?defaultWeightPlaceholder:item.weight}
                                                                            dropdownArr={weightedRankingArrObj}
                                                                            labelParam="label"
                                                                            onDropDownItemClick={this.maxrDropdownClick}
                                                                            udf={idx+":-:"+processObj.processId}
                                                                            disabled={item.disabled}
                                                                            defaultPlaceholderDropDown={defaultWeightPlaceholder}
                                                                        />
                                                                    </p>
                                                                }
                                                                <p style={{width:"25%"}}>

                                                                    {
                                                                        isEmptyVariable(item.rankingGuidance)
                                                                        ?
                                                                            <button className="add-guidance-btn"
                                                                            disabled={item.disabled}
                                                                            onClick={this.handleAddGuidanceDialogShow.bind(this,processObj.processId,idx)}>Add</button>
                                                                        :
                                                                            <div className="guidance-layout">
                                                                                <p>{truncateString(item.rankingGuidance,Constants.DEPT_STRING_LENGTH)}</p>
                                                                                <span className="material-icons" 
                                                                                disabled={item.disabled}
                                                                                onClick={this.handleEditGuidanceDialogShow.bind(this,processObj.processId,idx)}>edit</span>
                                                                            </div>
                                                                    }

                                                                </p>
                                                            </CustomTableRow>
                                                        })
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="loadmore-layout">
                                        <div>
                                        {/* {
                                            processObj.currentPageNo*this.state.resultSize < processObj.count &&
                                            <LoadMoreButton 
                                            themeSettings = {themeSettings}
                                            onClick={this.onChangePage.bind(this,processObj.processId)} type="button">Load More</LoadMoreButton>
                                        } */}
                                        </div>
                                        {
                                            processObj.count > 0 &&
                                            <p>{"Total Records: "+processObj.count}</p>
                                        }
                                    </div>
                                    {
                                        this.state.componentDidMountFlag && isEmptyArray(processObj.taskList) &&
                                        <div class="no-items-layout">
                                            <div class="no-items-card" style={{padding:"10px"}}>
                                                <h6 style={{margin:0}}>
                                                {
                                                    isEmptyVariable(this.state.apiSearchKey)?
                                                    Constants.NO_RECORDS_WARNING
                                                    :
                                                    Constants.EMPTY_SEARCH_WARNING
                                                }
                                                </h6>
                                            </div>
                                        </div>
                                    }
                                </div>
                            })
                        }
                        {/* {
                            !isEmptyVariable(this.state.addErrors['weightErr']) &&
                            <span class="cm-error">{this.state.addErrors['weightErr']}</span>
                        } */}
                        <div className="row custom-row">
                            <div className="col-md-12 custom-col">
                                <div className="common-close-save">
                                    <div className="common-close-save-col">
                                        <button onClick={this.onCancelClick} type="button" className="common-close">Cancel</button>
                                        <PageSaveButton 
                                        themeSettings = {themeSettings}
                                        onClick={this.handleProcessFuncDialogShow} type="button">Select Another Process</PageSaveButton>
                                        <PageSaveButton 
                                        themeSettings = {themeSettings}
                                        onClick={this.submitChecklist} type="button">Add to Checklist</PageSaveButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <ProcessFunctionListDialog
                    handleProcessFuncDialogClose = {this.handleProcessFuncDialogClose}
                    showProcessFunctionsDialog = {this.state.showProcessFunctionsDialog}
                    themeSettings={themeSettings}
                    currentPageNo={this.props.currentPageNo}
                    searchkey={this.props.searchkey}
                    resultSize={this.props.resultSize}
                    sort={this.props.sort}
                    sortDir={this.props.sortDir}
                    status={this.props.status}
                    handleActionDropdownItemClick={this.props.handleActionDropdownItemClick}
                    checkListItem = {this.props.checklistItem}
                    checkListHeadingId = {this.props.checkListHeadingId}
                    checkListHeading = {this.props.checkListHeading}
                    departmentId={this.props.processDeptId}
                />

                {
                    // this.state.showTaskDetailsLayout &&
                    // <TaskDetailsLayout
                    //     taskId = {this.state.selectedTaskIdForDetails}
                    //     handleHideTaskDetailsLayout = {this.handleHideTaskDetailsLayout}
                    //     subscriptionType={this.state.user.subscriptionType}
                    //     prevTaskId={this.state.prevTaskId}
                    //     nextTaskId={this.state.nextTaskId}
                    //     handleShowTaskDetailsLayout={this.handleShowTaskDetailsLayout}
                    // />
                }

                <AlertDialog
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type={Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                    themeSettings={themeSettings}
                />

                <Modal className="custom-dialog" show={this.state.guidanceDialogFlag} 
                backdrop="static">
                    <Modal.Header>
                        <h5>{this.state.isEdit?"Edit Ranking Guidance":"Add Ranking Guidance"}</h5>
                        <button 
                            onClick={this.handleGuidanceDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div class="modal-body">
                        <div class="addnew-modal-form">
                            {/* <div class="addnew-modal-form-group">
                                <p>Ranking Guidance*</p>
                                <textarea type="text" placeholder="Enter Ranking Guidance"
                                name="selectedRankingGuidance" value={this.state?.selectedRankingGuidance} 
                                onChange={this.handleChange}/>
                                {
                                    !isEmptyVariable(this.state?.formErrors['selectedRankingGuidance']) &&
                                    <span class="cm-error">{this.state?.formErrors['selectedRankingGuidance']}</span>
                                }
                            </div> */}
                        </div>
                        <div class="modal-close-save">
                            <div class="close-save-col">
                                <button onClick={this.handleGuidanceDialogClose} 
                                    type="button" class="modal-close">Cancel</button>
                                <DialogSaveButton 
                                    themeSettings={themeSettings}
                                    onClick={this.handleAddRankingGuidance} type="button">Save</DialogSaveButton>
                            </div>
                        </div>
                    </div>

                </Modal>
            </div>
        );
    }
}

export default OtherMasters;