import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan,LoadMoreButton} from '../../common/Global/globalStyles.style';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import MasterMenuTabs from './topmenu';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import TableDropDown from '../../common/DropdownMenus/TableDropdown';
import AlertDialog from '../../common/AlertDialog';
import TaskDetailsLayout from './taskDetailsWithTemplates';
import ReviewHistoryDialog from '../../common/reviewhistorydialog'
import UpdateReviewStatusDialog from './taskupdatereviewstatusdialog'
import {getLocalStorageVariables,getThemeSettingsVariables,isEmptyVariable,sortTable,truncateString,
    GetOrderedMasterTabMenuTask,removeHtmlTags,
    isEmptyArray,getStatusSpan} from '../../common/Global/commonFunctions';
import {getDropdownActionArrayProcessTasks} from '../../common/Global/reviewFunctions';
import {
    BrowserRouter as Router,
} from 'react-router-dom';

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();

const activeStatus="Active";
const inActiveStatus="Inactive";
const underReviewStatus="Under Review";

class OtherMasters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"",
            deletePermission:"N",
            actionType:"",

            functionMap:[],
            functionId:this.props.match.params.id,

            status:activeStatus,
            statusArr:[
                {
                    label:activeStatus,
                    isSelected:true
                },
                {
                    label:underReviewStatus,
                    isSelected:false
                },
                {
                    label:inActiveStatus,
                    isSelected:false
                },
            ],
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,

            departmentArr:[],
            selectedDeptId:"",
            deptPlaceholder:"",

            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            deleteReactivateMasterType:"",
            reviewLevel:"",
            alertDialogMessage:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            showBtnLoader:false,
            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showUpdateReviewDialog:false,
            showReviewHistoryDialog:false,
            showDetailsDialog:false,
            selectedItem:"",

            showTaskDetailsLayout:false,
            selectedTaskIdForDetails:"",
            user:{},
            isIndustryFlag:false
        }
    }

    componentDidMount(){
        this.getInitData();
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getTaskForAllFunction();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getTaskForAllFunction();
            })
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleUpdateReviewDialogClose = (reloadFlag) =>{
        this.setState({
            showUpdateReviewDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
            reviewLevel:"",
        },()=>{
            if(reloadFlag === true){
                this.getTaskForAllFunction();
            }
        });
    }

    handleReviewHistoryDialogClose = () =>{
        this.setState({
            showReviewHistoryDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
        });
    }

    handleCreateDialogClose = () =>{
        this.setState({
            showCreateDialog:false,
            isEdit:false,
            selectedId:"",
            name:"",
            shortName:"",
            description:"",
        });
    }

    handleDetailsDialogClose = () =>{
        this.setState({
            showDetailsDialog:false,
            selectedItem:"",
        });
    }

    handleDetailsDialogShow = (item) => {
        this.setState({
            showDetailsDialog:true,
            selectedItem:item,
        });
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo:1,
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getTaskForAllFunction();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getTaskForAllFunction();
        })
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Delete Task",
                proceedBtnLabel:"Delete",
                deleteReactivateId:dropdownItem.taskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete task "+removeHtmlTags(dropdownItem.task)+"?"
            })
        }else if(item.label === "Reactivate"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Activate Task",
                proceedBtnLabel:"Reactivate",
                deleteReactivateId:dropdownItem.taskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate task "+removeHtmlTags(dropdownItem.task)+"?"
            })
        }else if(item.label === "Edit"){
            this.props.history.push(Constants.URL_EDITTASK_WITH_TEMPLATES+"/"+dropdownItem.taskId+"-"+dropdownItem.assignmentTypeId);
        }else if(item.label === "Submit For Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Submit For Review",
                proceedBtnLabel:"Submit",
                deleteReactivateId:dropdownItem.taskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to submit "+removeHtmlTags(dropdownItem.task)+" for review?"
            })
        }else if(item.label === "Start Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Start Review",
                proceedBtnLabel:"Start",
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.taskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to start review of "+removeHtmlTags(dropdownItem.task)
            })
        }else if(item.label === "Update Review Status"){
            this.setState({
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.taskId,
                showUpdateReviewDialog:true,
            })
        }else if(item.label === "Review History"){
            this.setState({
                deleteReactivateId:dropdownItem.taskId,
                showReviewHistoryDialog:true,
            })
        }else if(item.label === "Review Remarks"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Review Remarks",
                proceedBtnLabel:"Okay",
                showAlertDialog:true,
                alertDialogMessage:'\"'+dropdownItem.lastReviewRemark+'\"'
            })
        }else if(item.label === "Discard Changes"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Discard Changes",
                proceedBtnLabel:"Discard",
                deleteReactivateId:dropdownItem.taskId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to discard changes of "+removeHtmlTags(dropdownItem.task)+"?",
                actionType: dropdownItem.previousStatus === "Updated"?"Update":(dropdownItem.previousStatus === "Deleted"?"Delete":"")
            })
        }
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getTaskForAllFunction();
        })
    }
    
    //No need of return value since the array itself is passed and updated.
    addParentIdandHasChildrenFlag = (tempArr, functionId, parentFunctionId, parentProcessBreadCrumb) => {
        tempArr[functionId].parentFunctionId = parentFunctionId;
        let breadCrumb = "";
        if(isEmptyVariable(parentProcessBreadCrumb)){
            breadCrumb = tempArr[functionId].functionName;
            tempArr[functionId].breadCrumb = breadCrumb;
        }else{
            breadCrumb = parentProcessBreadCrumb + " > " + tempArr[functionId].functionName;
            tempArr[functionId].breadCrumb = breadCrumb;
        }

        if(!isEmptyArray(tempArr[functionId].children)){
            tempArr[functionId].hasChildren = true;

            tempArr[functionId].children.map(childNode => {
                this.addParentIdandHasChildrenFlag(tempArr,childNode.functionId,functionId,breadCrumb);
            });
        }else{
            tempArr[functionId].hasChildren = false;
        }
    }

    deptFilterDropdownClick = (item,selectedIndex) => {
        let placeholder = "";
        let {departmentArr} = this.state;

        for(const [i,value] of this.state.departmentArr.entries()){
            if(i === selectedIndex){
                departmentArr[i].isSelected = true;
                placeholder = value.name;
            }else{
                departmentArr[i].isSelected = false;
            }
        }
        
        this.setState({
            selectedDeptId:item.departmentId,
            deptPlaceholder:placeholder
        },() => {
            this.getTaskForAllFunction();
        })
    }

    handleShowTaskDetailsLayout = (taskId) => {
        this.setState({
            showTaskDetailsLayout:true,
            selectedTaskIdForDetails:taskId
        })
    }

    handleHideTaskDetailsLayout = (taskId) => {
        this.setState({
            showTaskDetailsLayout:false,
            selectedTaskIdForDetails:""
        })
    }
    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetFunctionsSubtree,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    rootFunctionId:this.state.functionId
                })
            }),
            fetch(Constants.GetTaskForFunction,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    functionId:this.state.functionId,
                    filterTags:JSON.stringify([]),

                    pageNo:1,//initial page no of all the process taks
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                })
            }),
            fetch(Constants.GetUserMenu,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            })
        ])
        .then(([masterRes,generalRes,menuRes]) => { 
            return Promise.all([masterRes.json(),generalRes.json(),menuRes.json()]) 
        })
        .then(([masterRes,generalRes,menuRes]) => {
            let functionMapTemp = {};
            let datalistTemp = {};
            let menusTemp = {};
            let departmentsTemp = [];
            let userTemp = {};
            let placeholder = "";
            let selectedDeptId = "";
            let isIndustryFlag = false;
            let allSettings = [];
            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                    functionMapTemp = masterRes.result.functionMap;
                    this.addParentIdandHasChildrenFlag(functionMapTemp,this.state.functionId,this.state.functionId,"");
            }else{
                functionMapTemp = [];
            }

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                let datalistTempArr = generalRes.data.functionList;//list of process and task
                datalistTemp = {};

                datalistTempArr.map((item)=>{
                    // if(!functionMapTemp[item.functionId].hasChildren){
                        datalistTemp[item.functionId] = item;
                        datalistTemp[item.functionId].currentPageNo = 1; //set the current page no as 1 for all the individual task lists
                    // }
                })
            }else{
                datalistTemp = [];
            }

            if(menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(menuRes.responseCode === Constants.CODE_SUCCESS){
                menusTemp = menuRes.data.menus;
                userTemp = menuRes.data.user;
                departmentsTemp = menuRes.data.departments;
                allSettings = menuRes.data.allSettings;
                if(allSettings.find(item => item.settingDisplayColumn === Constants.SCREEN_INDUSTRY).settingColumnEnabled === "Y"){
                    isIndustryFlag = true;
                }
                if(!isEmptyArray(departmentsTemp)){
                    departmentsTemp[0].isSelected = true;
                    placeholder = departmentsTemp[0].name;
                    selectedDeptId = departmentsTemp[0].departmentId;
                }
            }else{
                menusTemp = {};
            }

            //Order the menu tabs in a specific way
            let menusTemp2 = GetOrderedMasterTabMenuTask(menusTemp)
            let deletePermission = "N";
            if(menusTemp["Delete Task"] === "ALL"){
                deletePermission = "Y";
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,
                deletePermission:deletePermission,

                functionMap:functionMapTemp,
                datalist:datalistTemp,
                menus:menusTemp2,
                departmentArr:departmentsTemp,
                deptPlaceholder:placeholder,
                selectedDeptId:selectedDeptId,
                user:userTemp,
                isIndustryFlag:isIndustryFlag
            })
        })
    }

    //result size change, status change, department change and search
    getTaskForAllFunction = () => {
        this.setState({
            showLoader:true,
        });
        // selectedDeptId
        let postParams = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            functionId:this.state.functionId,
            filterTags:JSON.stringify([]),
            
            pageNo:1,//get the initial page
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            status:this.state.status,
        }
        if(!isEmptyVariable(this.state.selectedDeptId)){
            postParams.departmentId = this.state.selectedDeptId;
        }

        fetch(Constants.GetTaskForFunction,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParams)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let datalistTempArr = data.data.functionList;//list of process and task
                let datalistTemp = {};

                datalistTempArr.map((item)=>{
                    if(!this.state.functionMap[item.functionId].hasChildren){
                        datalistTemp[item.functionId] = item;
                        datalistTemp[item.functionId].currentPageNo = 1; //set the current page no as 1 for all the individual task lists
                    }
                })

                this.setState({
                    showLoader:false,
                    datalist:datalistTemp,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:{},
                    showLoader:false,
                    totalCount:0,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (functionId) => {
        // update state with new page of items
        this.setState({
            showLoader:true,
        });
        let pageNo = this.state.datalist[functionId].currentPageNo+1;

        let postParams = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            functionId:functionId,
            filterTags:JSON.stringify([]),
            
            pageNo:pageNo,//get the initial page
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            status:this.state.status,
        }
        if(!isEmptyVariable(this.state.selectedDeptId)){
            postParams.departmentId = this.state.selectedDeptId;
        }

        fetch(Constants.GetTaskForFunction,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParams)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let datalistTempArr = data.data.functionList;//list of process and task
                let dataListTemp = this.state.datalist;
                let selectedfunctionObj = dataListTemp[functionId];
                selectedfunctionObj.currentPageNo = pageNo;
                let newTaskList = datalistTempArr[0].taskList;

                selectedfunctionObj.taskList = [
                    ...selectedfunctionObj.taskList,
                    ...newTaskList
                ]

                console.log(JSON.stringify(selectedfunctionObj.taskList))

                dataListTemp[functionId] = selectedfunctionObj

                this.setState({
                    showLoader:false,
                    datalist:dataListTemp,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:{},
                    showLoader:false,
                    totalCount:0,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    handleRecordOperation = () => {
        let url = "";
        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            taskId:this.state.deleteReactivateId
        }
        let callApi = true;

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteTask;
        }else if(this.state.operationType === "Reactivate"){
            url = Constants.ActivateTask;
        }else if(this.state.operationType === "Submit For Review"){
            url = Constants.SubmitTaskForReview;
        }else if(this.state.operationType === "Start Review"){
            url = Constants.UpdateTaskReviewStatus;
            postParam.reviewLevel = this.state.reviewLevel;
            postParam.reviewStatus = Constants.REVIEW_STATUS_STARTED;
        }else if(this.state.operationType === "Discard Changes"){
            url = Constants.DiscardChangesTask;
            postParam.actionType = this.state.actionType;
        }else if(this.state.operationType === "Review Remarks"){
            callApi = false;
            this.setState({
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
            })
        }

        if(callApi){
            this.setState({
                showBtnLoader:true,
            });

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        deleteReactivateId:"",
                        deleteReactivateMasterType:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        actionType:"",
                        showBtnLoader:false
                    },() => {
                        this.getTaskForAllFunction();
                    });
                }else{
                    this.setState({
                        showBtnLoader:false,
                        deleteReactivateId:"",
                        deleteReactivateMasterType:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        actionType:"",
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage,
                    })
                }
            });
        }
    }

    /*******************API CALLS END HERE*******************/

    render() {
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar  />
                                <MasterMenuTabs 
                                    masterMenus={this.state.menus}
                                    themeSettings={themeSettings}
                                />
                                {
                                    this.state.showLoader &&
                                    <div class="loader"></div>
                                }
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    !this.state.showTaskDetailsLayout &&
                                    <div className="body-wrapper">
                                        <div className="search-addnew-bg">
                                            <div className="search-addnew">
                                                
                                                <div className="search-filter-layout">
                                                    {/* <FilterDropDown
                                                        placeholder={this.state.resultSizePlaceholder}
                                                        dropdownArr={this.state.resultSizeArr}
                                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                                        themeSettings={themeSettings}
                                                        mleft={0}
                                                    /> */}
                                                    <div className="search-col m-l-10">
                                                        <input 
                                                            name="searchkey" 
                                                            type="text"
                                                            onChange={this.handleChangeSearch}
                                                            placeholder="Search" 
                                                            onKeyPress={this.onEnterBtnPress}
                                                            value={this.state.searchkey}
                                                        />
                                                        <SearchSpan 
                                                        themeSettings={themeSettings}
                                                        onClick = {this.onSearchIconClick} 
                                                        className="material-icons"> search </SearchSpan>
                                                    </div>

                                                    <FilterDropDown
                                                        placeholder={this.state.statusPlaceholder}
                                                        dropdownArr={this.state.statusArr}
                                                        onDropDownItemClick={this.statusDropdownClick}
                                                        themeSettings={themeSettings}
                                                    />
                                                    
                                                    {
                                                        this.state.departmentArr.length > 1 &&
                                                        <FilterDropDown
                                                            placeholder={this.state.deptPlaceholder}
                                                            dropdownArr={this.state.departmentArr}
                                                            onDropDownItemClick={this.deptFilterDropdownClick}
                                                            name={"name"}
                                                            themeSettings={themeSettings}
                                                            dropdownWidth={"120px"}
                                                        />
                                                    }
                                                </div>

                                            </div>
                                        </div>

                                        {
                                            Object.values(this.state.datalist).map((functionObj,index)=>{
                                                return <div className="process-task-list">
                                                    <h6 style={{marginTop:index === 0?15:10}}>{this.state.functionMap[functionObj.functionId].breadCrumb}</h6>
                                                    {
                                                        !isEmptyArray(functionObj.taskList) && 
                                                        <div className="common-tab-bg">
                                                            <div className="common-table" style={{width:"100%"}}>
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                        <th className="text-center" width={"5%"}>No.</th>
                                                                        <th width={"5%"}></th>
                                                                        <th width={"57%"}>Task</th>
                                                                        <th width={"20%"}>Assignment Type</th>
                                                                        <th width={"8%"}>Type</th>
                                                                        {/* <th width={"10%"}>Users Count</th> */}
                                                                        {/* <th className="text-center"  width={"5%"}></th> */}
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {
                                                                        !isEmptyArray(functionObj.taskList) && 
                                                                        functionObj.taskList.map((item,idx) => {

                                                                            // let actionObj = getDropdownActionArrayProcessTasks(item.allowEdit, item.allowReview, 
                                                                            //     item.status, item.currentReviewStatus, this.state.deletePermission, item.previousStatus);
                                                                            let actionObj = getDropdownActionArrayProcessTasks("N", item.allowReview, 
                                                                                item.status, item.currentReviewStatus, "N", item.previousStatus);
                                                                            let spanObj = getStatusSpan(item.previousStatus,themeSettings);

                                                                            {console.log("actionObj>>>",actionObj)}

                                                                            return <tr onClick={this.handleShowTaskDetailsLayout.bind(this,item.taskId)}>
                                                                                <td className="text-center">{(++idx)}</td>
                                                                                <td>
                                                                                    {
                                                                                        isEmptyVariable(item.riskLevel) ? "-" : (
                                                                                        <div className="flex-center-layout">
                                                                                            {isEmptyVariable(item.colorCode) ? item.riskLevel:""}
                                                                                            
                                                                                            {
                                                                                                !isEmptyVariable(item.colorCode) &&
                                                                                                <div
                                                                                                    className="risk-level-color-wrapper-round"
                                                                                                    style={{ width: "38px",marginLeft: (!isEmptyVariable(item.colorCode) ?0:8)}}
                                                                                                >
                                                                                                    <div
                                                                                                        className="risk-level-color-round"
                                                                                                        style={{ background: item.colorCode }}
                                                                                                    />
                                                                                                </div>
                                                                                            }
                                                                                        </div>
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    {/* {removeHtmlTags(item.task)} */}
                                                                                    <div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{__html: item.task}} />
                                                                                    {
                                                                                        this.state.status === "Under Review" &&
                                                                                        <span style={spanObj.spanStyle} className="span-tag">
                                                                                            {spanObj.spanText}
                                                                                        </span>
                                                                                    }
                                                                                </td>
                                                                                <td>{item.assignmentTypeName}</td>
                                                                                <td>{item.inherited === "Y"?"Inherited":"-"}</td>

                                                                                {/* <td className="text-center">
                                                                                    {
                                                                                        actionObj.actionArr && actionObj.actionArr.length > 0 &&
                                                                                        <TableDropDown 
                                                                                            actionArr={actionObj.actionArr}
                                                                                            onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                                            dropDownId = {item}
                                                                                            dropdownWidth={actionObj.dropdownWidth}
                                                                                            themeSettings={themeSettings}
                                                                                        />
                                                                                    }
                                                                                </td> */}
                                                                            </tr>
                                                                        })
                                                                    }
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="loadmore-layout">
                                                        <div>
                                                        {/* {
                                                            functionObj.currentPageNo*this.state.resultSize < functionObj.count &&
                                                            <LoadMoreButton 
                                                            themeSettings = {themeSettings}
                                                            onClick={this.onChangePage.bind(this,functionObj.functionId)} type="button">Load More</LoadMoreButton>
                                                        } */}
                                                        </div>
                                                        {
                                                            functionObj.count > 0 &&
                                                            <p>{"Total Records: "+functionObj.count}</p>
                                                        }
                                                        
                                                    </div>
                                                

                                                    {
                                                        this.state.componentDidMountFlag && isEmptyArray(functionObj.taskList) &&
                                                        <div class="no-items-layout">
                                                            <div class="no-items-card" style={{padding:"10px"}}>
                                                                <h6 style={{margin:0}}>
                                                                {
                                                                    isEmptyVariable(this.state.apiSearchKey)?
                                                                    Constants.NO_RECORDS_WARNING
                                                                    :
                                                                    Constants.EMPTY_SEARCH_WARNING
                                                                }
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            })
                                            
                                        }
                                        {/* TODO - Pagination should be implemented */}
                                        {/* <div className="pagination-layout">
                                            <div className="row custom-row">
                                                <div className="col-md-9 custom-col">
                                                {
                                                    (this.state.totalCount > this.state.datalist.length) && 
                                                    <Pagination 
                                                        totalLength ={this.state.totalCount} 
                                                        items={this.state.datalist} 
                                                        onChangePage={this.onChangePage} 
                                                        pageSize={this.state.resultSize}
                                                        currentPageNo = {this.state.currentPageNo}
                                                        initialPage={this.state.currentPageNo} 
                                                        themeSettings={themeSettings}/>
                                                }
                                                </div>
                                                <div className="col-md-3 custom-col">
                                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                }
                                {
                                    this.state.showTaskDetailsLayout &&
                                    <TaskDetailsLayout
                                        taskId = {this.state.selectedTaskIdForDetails}
                                        handleHideTaskDetailsLayout = {this.handleHideTaskDetailsLayout}
                                        subscriptionType={this.state.user.subscriptionType}
                                        isIndustryFlag={this.state.isIndustryFlag}
                                    />
                                }
                            </div>
                        </div>

                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                (this.state.operationType === "Review Remarks")
                                ?
                                Constants.ALERT_TYPE_ALERT
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogHeading={this.state.alertDialogHeading}
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.handleRecordOperation}
                            proceedBtnLabel={this.state.proceedBtnLabel}
                            showLoader={this.state.showBtnLoader}
                            themeSettings={themeSettings}
                        />

                         <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings={themeSettings}
                        />

                        <UpdateReviewStatusDialog
                            showUpdateReviewDialog={this.state.showUpdateReviewDialog}
                            handleUpdateReviewDialogClose={this.handleUpdateReviewDialogClose}
                            masterType = {this.state.deleteReactivateMasterType}
                            masterDataId = {this.state.deleteReactivateId}
                            reviewLevel={this.state.reviewLevel}
                            themeSettings={themeSettings}
                        />

                        <ReviewHistoryDialog
                            showReviewHistoryDialog={this.state.showReviewHistoryDialog}
                            handleReviewHistoryDialogClose={this.handleReviewHistoryDialogClose}
                            masterType = {"Task"}
                            masterDataId = {this.state.deleteReactivateId}
                        />

                    </section>
                </main>
            </Router>
        );
    }
}

export default OtherMasters;