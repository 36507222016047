import React, { Component, useEffect, useState } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,removeHtmlTags,getStatusSpan,
    isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import {  DialogSaveButton } from "../../../common/Global/globalStyles.style";
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdown';
import {Modal} from 'react-bootstrap';

const defaultWeightPlaceholder="Select Weight";
const userDetails  = getLocalStorageVariables();

const GuidanceDialog = (props) => {
    const [weight,setWeight] = useState("");
    const [showLoader,setShowLoader] = useState(false);
    const [formErrors,setFormErrors] = useState({});
    const [weightArr,setWeightArr] = useState([]);

    useEffect(()=>{
        if(props.guidanceDialogFlag){
            let weightArrTemp = [];
            
            for(let x=props.selectedChecklistTaskObj.minWeight;x<= props.selectedChecklistTaskObj.maxWeight;x++){
                weightArrTemp.push({label:x,id:x})
            }
            setWeightArr(weightArrTemp);
            setShowLoader(false);
            if(!isEmptyVariable(props.selectedChecklistTaskObj?.headingObj?.weight)){
                setWeight(props.selectedChecklistTaskObj.headingObj?.weight)
            }else{
                setWeight("");
            }
        }
    },[props.guidanceDialogFlag])

    const maxrDropdownClick = (item) => {
        setWeight(item.id);
    }

    const handleAddRankingGuidance = () => {
        setShowLoader(true);

        fetch(Constants.UpdateChecklistTaskProperties,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                checkListTaskId:props.selectedChecklistTaskObj.headingObj.checkListTaskId,
                rankingGuidance:isEmptyVariable(props.selectedChecklistTaskObj?.headingObj?.rankingGuidance)?"":props.selectedChecklistTaskObj.headingObj.rankingGuidance,
                weight:isEmptyVariable(weight)?"":weight,
            })
        })
        .then(response => { return response.json(); } )
        .then(data => {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                props.handleWeightDialogClose(true);
            }else{
                setFormErrors({
                    ...formErrors,
                    weight:data.responseMessage
                })
            }
        });
    }


    return (
        <Modal className="custom-dialog" show={props.guidanceDialogFlag} 
        backdrop="static">
            <Modal.Header>
                <h5>Weight</h5>
                <button 
                    onClick={props.handleWeightDialogClose}
                    type="button" data-dismiss="modal">
                    <span class="material-icons">close</span>
                </button>
            </Modal.Header>
            <hr />
            <div class="modal-body">
                <div class="addnew-modal-form">
                    <div class="addnew-modal-form-group">
                        <p>Weight</p>
                        <RegularDropdown 
                            placeholder={weight}
                            dropdownArr={weightArr}
                            labelParam="label"
                            onDropDownItemClick={maxrDropdownClick}
                            udf={""}
                            defaultPlaceholderDropDown={defaultWeightPlaceholder}
                        />
                        {
                            !isEmptyVariable(formErrors['weight']) &&
                            <span class="cm-error">{formErrors['weight']}</span>
                        }
                    </div>
                </div>
                <div class="modal-close-save">
                    <div class="close-save-col">
                        <button onClick={props.handleWeightDialogClose} 
                            type="button" class="modal-close">Cancel</button>
                        <DialogSaveButton 
                            themeSettings={props.themeSettings}
                            onClick={handleAddRankingGuidance} type="button">
                                {showLoader ? <div class="loader-btn mt-2"></div> : 'Save'}
                        </DialogSaveButton>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default GuidanceDialog;