import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { AddNewButtonLayout, TableRadioButton } from "../../../common/Global/globalStyles.style";
import Sidebar from "../../../common/SidebarCollapse";
import Topbar from "../../../common/Topbar";
import MasterMenuTabs from "../Common/topmenu";
import TableDropDown from "../../../common/DropdownMenus/TableDropdown";
import AlertDialog from "../../../common/AlertDialog";
import Pagination from "../../../common/Global/pagination";
import {
	getLocalStorageVariables,
	getThemeSettingsVariables,
	isEmptyVariable,
	sortTable,
	isEmptyArray,
	GetOrderedMasterTabMenuExecute,
} from "../../../common/Global/commonFunctions";
import { BrowserRouter as Router } from "react-router-dom";
import SelectDocumentDialog from "./selectDocument"; 
import DocumentSamplesListLayout from "./modalListDocumentSample";
import ReviewCommentsDialog from "./reviewCommentsdialog";
import ReviewHistoryDialog from './reviewhistorydialog';
import TopmenuReports from "../../../common/TopmenuReports";
import { matchPath } from "react-router-dom";


const sortDocumentName = "d.name";
const sortDocumentType = "d.documentType";

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();

class SampleCheckedList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			assignmentId: this.props.match.params.id,
			user: {},
			componentDidMountFlag: false,
			apiSearchKey: "",
			searchkey: "",
			currentPageNo: 1,
			showLoader: false,
			resultSize: Constants.RESULT_SIZE,
			totalCount: 0,
			datalist: [],
			statusPlaceholder: "Active",
			resultSizePlaceholder: Constants.RESULT_SIZE ,
			sort: "",
			sortDir: "",
			assignmentStatus: "",
			assignmentDetails: "",
			departmentId: "",
			company:{},

			//new Document
			showSelectDocumentDialog: false,
			selectedDocumentObjArr: [],
			document_NR: "",

			showAlertDialog: false,
			operationType: "",
			deleteReactivateId: "",
			deleteReactivateMasterType: "",
			reviewLevel: "",
			alertDialogMessage: "",
			alertDialogHeading: "",
			proceedBtnLabel: "",

			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",
			
			//sample document
			// showDocumentSamplesListing: false,
			selectedDocumentId: "",
			selectedDocumentName: "",
			showSampleDocumentDialog:false,

			showReviewCommentsDialog: false,

			resultSizeArr: Constants.RESULT_SIZE_DD_ARR,
		};
	}

	componentDidMount() {
		this.getInitData();
	}

	handleChange = (e) => {
		const { name, value } = e.target;

		let FormErrorsTemp = this.state.formErrors;

		this.setState({
			[name]: value,
			formErrors: FormErrorsTemp,
		});
	};

	handleSelection = (e) => {
		// e.stopPropagation();

		let isChecked = e.target.checked;
		// Sample: 1:-:notReceived
		let name = e.target.name;
		let arr = name.split(":-:");
		let datalistTemp = this.state.datalist;
		let index = arr[0];
		let attrName = arr[1];
		datalistTemp[index].notReceived = "N";
		datalistTemp[index].partReceived = "N";
		datalistTemp[index].received = "N";
		datalistTemp[index].verified = "N";

		console.log(arr[1]);
		console.log("index: " + index);

		if (isChecked) {
			datalistTemp[index][attrName] = "Y";

			this.setState(
				{
					datalist: datalistTemp,
				},
				() => {
					this.handleSaveProperties(this.state.datalist[index]);
				}
			);
		}
	};

	handleBlurSampleList = (index, e) => {
		const value = e.currentTarget.textContent;
		let datalistTemp = this.state.datalist;

		if (datalistTemp[index].sampleList + "" !== value) {
			datalistTemp[index].sampleList = value;
			this.setState(
				{
					datalist: datalistTemp,
				},
				() => {
					this.handleSaveProperties(this.state.datalist[index]);
				}
			);
		}
	};

	handleBlurSizeNumber = (index, e) => {
		const value = e.currentTarget.textContent;
		let datalistTemp = this.state.datalist;
		if (datalistTemp[index].totalSizeNumber + "" !== value) {
			datalistTemp[index].totalSizeNumber = value;
			this.setState(
				{
					datalist: datalistTemp,
				},
				() => {
					this.handleSaveProperties(this.state.datalist[index]);
				}
			);
		}
	};

	handleBlurSizeValue = (index, e) => {
		const value = e.currentTarget.textContent;
		let datalistTemp = this.state.datalist;
		if (datalistTemp[index].totalSizeValue + "" !== value) {
			datalistTemp[index].totalSizeValue = value;
			this.setState(
				{
					datalist: datalistTemp,
				},
				() => {
					this.handleSaveProperties(this.state.datalist[index]);
				}
			);
		}
	};

	handleBlurCheckedNumber = (index, e) => {
		const value = e.currentTarget.textContent;
		let datalistTemp = this.state.datalist;

		if (datalistTemp[index].sampleCheckedNumber + "" !== value) {
				datalistTemp[index].sampleCheckedNumber = value;
				this.setState(
					{
						datalist: datalistTemp,
					},
					() => {
						this.handleSaveProperties(this.state.datalist[index]);
					}
				);
		}
	};
	handleBlurCheckedValue = (index, e) => {
		const value = e.currentTarget.textContent;
		let datalistTemp = this.state.datalist;

		if (datalistTemp[index].sampleCheckedValue + "" !== value) {
			datalistTemp[index].sampleCheckedValue = value;
			this.setState(
				{
					datalist: datalistTemp,
				},
				() => {
					this.handleSaveProperties(this.state.datalist[index]);
				}
			);
		}
	};

	handleAlertDialogClose = () => {
		this.setState({
			showAlertDialog: false,
			alertDialogMessage: "",
		});
	};

	handleAlertDialogCloseInfo = () => {
		this.setState({
			showAlertDialogInfo: false,
			alertDialogMessageInfo: "",
		});
		if(this.state.responseCode === Constants.CODE_ASSIGNMENT_ACCESS_ERROR ||
            this.state.responseCode === Constants.CODE_ASSIGNMENT_SPECIFIC_ACCESS_ERROR){
			this.props.history.push(Constants.URL_EXECUTEASSIGNMENTS);
		}
	};

	// Add new document
	handleSelectDocumentDialogShow = () => {
		// let focusObj = this.handleFocus()
		this.setState({
			showSelectDocumentDialog: true,
		});
	};

	handleSelectDocumentDialogClose = () => {
		this.setState({
			showSelectDocumentDialog: false,
		});
	};
	handleSelectDocumentDialogSaveClose = (selectedDocumentObjArr, document_NR) => {
		this.setState(
			{
				showSelectDocumentDialog: false,
				selectedDocumentObjArr: selectedDocumentObjArr,
				document_NR: document_NR,
			},
			() => {
				let selectedDocumentIds = [];
				selectedDocumentIds = selectedDocumentObjArr.map((item) => item.documentId);
				this.handleAddNewSampleChecked(selectedDocumentIds);
			}
		);
	};

	sortTableLocal = (sortColumn) => {
		let sortObj = sortTable(sortColumn, this.state.sort, this.state.sortDir);

		this.setState(
			{
				sort: sortObj.sortTemp,
				sortDir: sortObj.sortDirTemp,
			},
			() => {
				this.getSampleCheckedList();
			}
		);
	};

	onTableDropDownItemClick = (item, dropdownItem) => {
		if (item.label === "Review Comments") {
			this.setState({
				deleteReactivateId:dropdownItem.assignmentSampleCheckedId,
				showReviewCommentsDialog:true,
				// operationType: item.label,
				// alertDialogHeading: "",
				// proceedBtnLabel: "",
				// deleteReactivateId: dropdownItem.,
				// showAlertDialog: true,
				// alertDialogMessage: ,
			});
		} 
		else if (item.label === "Review History") {
			this.setState({
			deleteReactivateId:dropdownItem.assignmentSampleCheckedId,
			showReviewHistoryDialog:true,
			});

		}
		else if (item.label === "Check Format") {
			this.setState({
				selectedDocumentId: dropdownItem.documentId,
				selectedDocumentName: dropdownItem.name,
				showSampleDocumentDialog: true,
			});
		}
	};

	// show sample document format
	handleSampleDocumentDialogShow = () => {
		// let focusObj = this.handleFocus()
		this.setState({
			showSampleDocumentDialog: true,
		});
	};

	handleSampleDocumentDialogClose = () => {
		this.setState({
			showSampleDocumentDialog: false,
			selectedDocumentId: "",
			selectedDocumentName: "",
		});
	};

	handleReviewCommentsDialogClose = () =>{
        this.setState({
            showReviewCommentsDialog:false,
            deleteReactivateId:"",
        });
    }

	handleReviewHistoryDialogClose = () =>{
        this.setState({
            showReviewHistoryDialog:false,
            deleteReactivateMasterType:"",
            deleteReactivateId:"",
        });
    }
	

	/*******************API CALLS*******************/
	getInitData = () => {
		this.setState({
			showLoader: true,
		});

		Promise.all([
			fetch(Constants.GetSampleChecked, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.state.assignmentId,
				}),
			}),
			fetch(Constants.GetUserMenu, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
				}),
			}),
			fetch(Constants.GetAssignmentsDetails, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.state.assignmentId,
				}),
			}),
		])
			.then(([masterRes, menuRes, assignmentRes]) => {
				return Promise.all([masterRes.json(), menuRes.json(), assignmentRes.json()]);
			})
			.then(([masterRes, menuRes, assignmentRes]) => {
				let datalistTemp = [];
				let totalCountTemp = 0;
				let menusTemp = {};
				let userTemp = {};
				let assignmentStatus = "";
				let assignmentDetails = {};
				let companyTemp = {};
				let departmentId = "";

				if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
					datalistTemp = masterRes.data.samples;
					totalCountTemp = masterRes.data.count;
				} else {
					this.setState({
						showAlertDialogInfo: true,
						alertDialogMessageInfo: masterRes.responseMessage,
						responseCode: masterRes.responseCode
					})
				}

				if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
					menusTemp = menuRes.data.menus;
					userTemp = menuRes.data.user;
					companyTemp = menuRes.data.company;
				} else {
					menusTemp = {};
					userTemp = {};
				}

				if (assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
					assignmentStatus = assignmentRes.data.assignmentDetails.assignmentStatus;
					departmentId = assignmentRes.data.assignmentDetails.departmentId;
					assignmentDetails = assignmentRes.data.assignmentDetails;

				} else {
				}
                let menusTemp2 = GetOrderedMasterTabMenuExecute(menusTemp,assignmentDetails.observationTemplateId);
				this.setState({
					componentDidMountFlag: true,
					showLoader: false,
					datalist: datalistTemp,
					totalCount: totalCountTemp,
					menus: menusTemp2,
					user: userTemp,
					company:companyTemp,
					assignmentStatus: assignmentStatus,
					departmentId: departmentId,
					assignmentDetails: assignmentDetails,

				});
			});
	};

	getSampleCheckedList = () => {
		this.setState({
			showLoader: true,
		});

		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			assignmentId: this.state.assignmentId,
			pageNo: this.state.currentPageNo,
			resultsize: this.state.resultSize,
			// status: this.state.status,
			sort: this.state.sort,
			sortDir: this.state.sortDir,
		};

		fetch(Constants.GetSampleChecked, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParam),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState({
						showLoader: false,
						datalist: data.data.samples,
						totalCount: data.data.count,
					});
				} else {
					this.setState({
						datalist: [],
						showLoader: false,
						totalCount: 0,
						showAlertDialogInfo: true,
						alertDialogMessageInfo: data.responseMessage,
						responseCode: data.responseCode
					});
				}
			});
	};

	onChangePage = (page) => {
		// update state with new page of items
		if (page !== this.state.currentPageNo) {
			this.setState({
				showLoader: true,
			});

			let postParam = {
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				pageNo: page,
				search: this.state.searchkey,
				resultsize: this.state.resultSize,
				// status:this.state.status,
				sort: this.state.sort,
				sortDir: this.state.sortDir,
			};

			fetch(Constants.GetSampleChecked, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(postParam),
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
						localStorage.clear();
						window.location = "/";
					} else if (data.responseCode === Constants.CODE_SUCCESS) {
						this.setState({
							showLoader: false,
							datalist: data.data.samples,
							totalCount: data.data.count,
							apiSearchKey: this.state.searchkey,
							currentPageNo: page,
						});
					} else {
						this.setState({
							datalist: [],
							showLoader: false,
							apiSearchKey: this.state.searchkey,
							showAlertDialogInfo: true,
							alertDialogMessageInfo: data.responseMessage,
							responseCode: data.responseCode
						});
					}
				});
		}
	};

	handleSaveProperties = (dataListObj) => {
		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			assignmentId: this.state.assignmentId,
			samples: JSON.stringify([dataListObj]),
		};

		fetch(Constants.SaveSampleCheckedProperties, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParam),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState({}, () => {
						this.getSampleCheckedList();
					});
				} else {
				}
			});
	};

	handleAddNewSampleChecked = (selectedDocumentIds) => {
		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			assignmentId: this.state.assignmentId,
			documentIds: JSON.stringify(selectedDocumentIds),
		};

		fetch(Constants.AddNewSampleChecked, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParam),
		})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.getSampleCheckedList();
				} else {
				}
			});
	};

	/*******************API CALLS END HERE*******************/

	render() {
		const actionArr = [
			{
				icon: "chat",
				label: "Review Comments",
			},
			{
				icon: "history",
				label: "Review History",
			},
			{
				icon: "check_circle",
				label: "Check Format",
			},
		];
		return (
			<Router>
				<main className="main-dashboard">
					<section className="dashboard-bg">
						<Sidebar />

						<div className="dashboard-right-bg-collpase">
							<div className="dashboard-wrapper">
								<Topbar />
								{
                                    this.state.componentDidMountFlag && (
                                    <div className="d-flex align-items-center">
                                        <div className="flex-fill">
                                            <MasterMenuTabs
                                                assignmentId={this.state.assignmentId}
                                                masterMenus={this.state.menus}
                                                themeSettings={themeSettings}
                                            />
                                        </div>
                                        <div>
                                            <TopmenuReports
                                                assignmentId={this.state.assignmentId}
                                                userDetails ={userDetails}
                                                themeSettings={themeSettings}
                                                company={this.state.company}
                                            />
                                        </div>
                                    </div>
                                )}
								{this.state.showLoader && (
									<div class="loader"></div>
								)}
								<div className="search-addnew-bg">
									<div className="search-addnew">
										<div></div>
										{!isEmptyVariable(this.state.assignmentStatus) && this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_ONGOING && (
											<AddNewButtonLayout themeSettings={themeSettings}>
												<a onClick={this.handleSelectDocumentDialogShow} href="javascript:void(0);">
													<span className="material-icons">add_circle</span>
													<p>Add New</p>
												</a>
											</AddNewButtonLayout>
										)}
									</div>
								</div>
								{
									// Need to check component did mount flag, other wise until api is called,
									// it shows no items found layout.
									this.state.componentDidMountFlag && (
										<div className="body-wrapper">
											{!isEmptyArray(this.state.datalist) && (
												<div className="common-tab-bg">
													<div className="common-table" style={{ width: "100%" }}>
														<table className="table height-samplechecked-th">
															<thead>
																<tr className="sample-checked-thead">
																	<th rowSpan="2" className="text-center" width={"5%"}>
																		No.
																	</th>
																	<th rowSpan="2" className="c-pointer" onClick={this.sortTableLocal.bind(this, sortDocumentName)}>
																		<div className="sort-header">
																			Documents
																			<span
																				className={
																					this.state.sort === sortDocumentName ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>	
																		</div>
																	</th>
																	<th rowSpan="2" className="c-pointer" onClick={this.sortTableLocal.bind(this, sortDocumentType)}>
																		<div className="sort-header">
																			Type
																			<span
																				className={
																					this.state.sort === sortDocumentType ? "material-icons" : "material-icons hide-sort-arrow"
																				}
																			>
																				{this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
																			</span>
																		</div>
																	</th>
																	{/* <th rowSpan="2" className="c-pointer">
																		<div>Sample List</div>
																	</th> */}
																	<th rowSpan="2" className="text-center">
																		<div>Not Received</div>
																	</th>
																	<th rowSpan="2" className="text-center">
																		<div>Part Received</div>
																	</th>
																	<th rowSpan="2" className="text-center">
																		<div>Received</div>
																	</th>
																	<th rowSpan="2" className="text-center">
																		<div>Verified</div>
																	</th>
																	<th colSpan="2" className="text-center">
																	{
																		this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_ONGOING 
																		?
																		<div className="flex-center-layout table-cell-edit just-content"><p>Total Size</p><span className="material-icons">edit</span></div>
																		:
																		<div>Total Size</div>

																	}
																	</th>
																	<th colspan="2" className="text-center">
																	{
																		this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_ONGOING 
																		?
																		<div className="flex-center-layout table-cell-edit just-content"><p>Sample Checked</p><span className="material-icons">edit</span></div>
																		:
																		<div>Sample Checked</div>

																	}
																	</th>
																	<th rowSpan="2" >
																		<div></div>
																	</th>
																</tr>
																<tr className="sample-checked-thead">
																	<th>
																		<div>Value</div>
																	</th>
																	<th>
																		<div>Number</div>
																	</th>

																	<th>
																		<div>Value</div>
																	</th>
																	<th>
																		<div>Number</div>
																	</th>
																</tr>
															</thead>
															<tbody>
																{!isEmptyArray(this.state.datalist) &&
																	this.state.datalist.map((item, idx) => {
																		let isEditable = false;
																		if(!isEmptyVariable(this.state.assignmentStatus) && 
																		this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_ONGOING){
																			isEditable = true;
																		}
																		return (
																			<tr>
																				<td className="text-center">
																					{(this.state.currentPageNo - 1) * this.state.resultSize + ++idx}
																				</td>
																				<td>{item.name}</td>
																				<td>{item.documentType}</td>
																				{/* <td
																					contenteditable={isEditable?"true":"false"}
																					onBlur={this.handleBlurSampleList.bind(this, idx - 1)}
																					// onKeyPress={this.onEnterBtnPressSampleList.bind(this, idx - 1)}
																				>
																					{item.sampleList}
																				</td> */}
																				<td className="text-center">
																					<TableRadioButton ml={"0px"} themeSettings={themeSettings}>
																						<input
																							type="checkbox"
																							name={idx - 1 + ":-:notReceived"}
																							id={idx + "notReceived"}
																							onChange={this.handleSelection}
																							checked={
																								item.notReceived + "" === "Y"
																									? true
																									: isEmptyVariable(item.partReceived) &&
																									  isEmptyVariable(item.received) &&
																									  isEmptyVariable(item.verified)
																									? true
																									: false
																							}
																							disabled={isEditable?false:true}
																						/>
																						<label for={idx + "notReceived"} style={{ marginLeft: 6 }}></label>
																					</TableRadioButton>
																				</td>
																				<td className="text-center">
																					<TableRadioButton ml={"0px"} themeSettings={themeSettings}>
																						<input
																							type="checkbox"
																							name={idx - 1 + ":-:partReceived"}
																							id={idx + "partReceived"}
																							onChange={this.handleSelection}
																							checked={item.partReceived + "" === "Y" ? true : false}
																							disabled={isEditable?false:true}
																						/>
																						<label for={idx + "partReceived"} style={{ marginLeft: 6 }}></label>
																					</TableRadioButton>
																				</td>
																				<td className="text-center">
																					<TableRadioButton ml={"0px"} themeSettings={themeSettings}>
																						<input
																							type="checkbox"
																							name={idx - 1 + ":-:received"}
																							id={idx + "received"}
																							onChange={this.handleSelection}
																							checked={item.received + "" === "Y" ? true : false}
																							disabled={isEditable?false:true}
																						/>
																						<label for={idx + "received"} style={{ marginLeft: 6 }}></label>
																					</TableRadioButton>
																				</td>
																				<td className="text-center">
																					<TableRadioButton ml={"0px"} themeSettings={themeSettings}>
																						<input
																							type="checkbox"
																							name={idx - 1 + ":-:verified"}
																							id={idx + "verified"}
																							onChange={this.handleSelection}
																							checked={item.verified + "" === "Y" ? true : false}
																							disabled={isEditable?false:true}
																						/>
																						<label for={idx + "verified"} style={{ marginLeft: 6 }}></label>
																					</TableRadioButton>
																				</td>
																				<td contenteditable={isEditable?"true":"false"} onBlur={this.handleBlurSizeValue.bind(this, idx - 1)}>
																					{item.totalSizeValue}
																				</td>
																				<td contenteditable={isEditable?"true":"false"} onBlur={this.handleBlurSizeNumber.bind(this, idx - 1)}>
																					{item.totalSizeNumber}
																				</td>
																				<td contenteditable={isEditable?"true":"false"} onBlur={this.handleBlurCheckedValue.bind(this, idx - 1)}>
																					{item.sampleCheckedValue}
																				</td>
																				<td contenteditable={isEditable?"true":"false"} onBlur={this.handleBlurCheckedNumber.bind(this, idx - 1)}>
																					{item.sampleCheckedNumber}
																				</td>
																				<td className="text-center">
																					<TableDropDown
																						actionArr={actionArr}
																						onDropDownItemClick={this.onTableDropDownItemClick}
																						dropDownId={item}
																						dropdownWidth={"150px"}
																						themeSettings={themeSettings}
																					/>
																				</td>
																			</tr>
																		);
																	})}
															</tbody>
														</table>
													</div>
												</div>
											)}
											{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
												<div class="no-items-layout">
													<div class="no-items-card">
														<h6>
															{isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}
														</h6>
													</div>
												</div>
											)}
											<div className="pagination-layout">
												<div className="row custom-row">
													<div className="col-md-9 custom-col">
														{!isEmptyArray(this.state.datalist) && this.state.totalCount > this.state.datalist.length && (
															<Pagination
																totalLength={this.state.totalCount}
																items={this.state.datalist}
																onChangePage={this.onChangePage}
																pageSize={this.state.resultSize}
																currentPageNo={this.state.currentPageNo}
																initialPage={this.state.currentPageNo}
																themeSettings={themeSettings}
															/>
														)}
													</div>
													{/* <div className="col-md-3 custom-col">
														<p>{"Total Records: " + this.state.totalCount}</p>
													</div> */}
												</div>
											</div>
										</div>
									)
								}
							</div>
						</div>
						<SelectDocumentDialog
							showDocumentDialog={this.state.showSelectDocumentDialog}
							handleSelectDocDialogClose={this.handleSelectDocumentDialogClose}
							handleSelectDocDialogSaveClose={this.handleSelectDocumentDialogSaveClose}
							// selectedDocumentObjArr={this.state.selectedDocumentObjArr}
							// document_NR={this.state.document_NR}
							subscriptionType={this.state.user.subscriptionType}
							departmentId={this.state.departmentId}
							themeSettings={themeSettings}
						/>
						{/* {this.state.showDocumentSamplesListing && ( */}
							<DocumentSamplesListLayout
								showSampleDocumentDialog={this.state.showSampleDocumentDialog}
								handleSampleDocDialogClose={this.handleSampleDocumentDialogClose}
								documentId={this.state.selectedDocumentId}
								documentName={this.state.selectedDocumentName}
								handleBackBtnClickFromSamplesandERP={this.handleBackBtnClickFromSamplesandERP}
								themeSettings={themeSettings}
							/>
						{/* )} */}

						<AlertDialog
							showAlertDialog={this.state.showAlertDialog}
							handleAlertDialogClose={this.handleAlertDialogClose}
							type={
								this.state.operationType === "Delete"
									? Constants.ALERT_TYPE_WARNING
									: this.state.operationType === "Review Remarks"
									? Constants.ALERT_TYPE_ALERT
									: Constants.ALERT_TYPE_INFO
							}
							alertDialogHeading={this.state.alertDialogHeading}
							alertDialogMessage={this.state.alertDialogMessage}
							proceedBtnClick={this.handleRecordOperation}
							proceedBtnLabel={this.state.proceedBtnLabel}
							themeSettings={themeSettings}
						/>

						<AlertDialog
							showAlertDialog={this.state.showAlertDialogInfo}
							handleAlertDialogClose={this.handleAlertDialogCloseInfo}
							type={Constants.ALERT_TYPE_ALERT}
							alertDialogMessage={this.state.alertDialogMessageInfo}
							proceedBtnClick={this.handleAlertDialogCloseInfo}
							proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
							themeSettings={themeSettings}
						/>

						<ReviewCommentsDialog
							showReviewCommentsDialog={this.state.showReviewCommentsDialog}
							handleReviewCommentsDialogClose={this.handleReviewCommentsDialogClose}
							masterDataId = {this.state.deleteReactivateId}
							assignmentId = {this.state.assignmentId}
							themeSettings={themeSettings} 
						/>

						<ReviewHistoryDialog
                            showReviewHistoryDialog={this.state.showReviewHistoryDialog}
                            handleReviewHistoryDialogClose={this.handleReviewHistoryDialogClose}
                            masterType = {"Sample Checked"}
                            masterDataId = {this.state.deleteReactivateId}
                        />
					</section>
				</main>
			</Router>
		);
	}
}

export default SampleCheckedList;
