import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { 
    getThemeSettingsVariables, isEmptyVariable, isEmptyArray, getLocalStorageVariables, sortTable
} from "../../../common/Global/commonFunctions";
import { BrowserRouter as Router } from "react-router-dom";
import { AddNewButtonLayout, DialogSaveButton, TableCheckBox } from "../../../common/Global/globalStyles.style";
import ObservationDetailsLayout from "../../Execute/Observation/ObservationDetails/observationDetails";
import RegularDropdown from '../../../common/DropdownMenus/RegularDropdownTable';
import { Modal } from "react-bootstrap";
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";
import moment from "moment";
import { DatePicker } from "antd";
import AlertDialog from "../../../common/AlertDialog";

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();
const sortObservationDetail = "observationDetail";
const sortRisk = "o.riskLevel";
const sortStatus = "o.status";
const resourceDropdownPlaceholder="Select Resource";

class FollowUpObservation extends Component {
    constructor(props) {
        super(props);
		this.state = {
			componentDidMountFlag: false,
			showLoader: false,
			datalist: [],
            datalistArr: [],
			sort: "",
			sortDir: "",
            assignmentArea:props.assignmentArea,
            showAssignResourcesDialogFlag:false,
            resourceDropdownPlaceholder:"Select Resource",
            assignResourcesPlaceholder:"All",
			resourceUser:{},
            followUpDate:"",
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        }
    }

    componentDidMount() {
		this.getInitData();
	}

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props)){
            this.setState({
                componentDidMountFlag:false,
                showLoader: false,
                datalist: [],
                datalistArr: [],
			    resourceUser:{},
            },()=>{
                this.getInitData();
            })
            
        }
    }

    getRootLevelFunctions = (functionMapTemp) => {
        let rootFunctionArr = [];
        Object.values(functionMapTemp).map((item) => {
            if (item.level === 0) {
                rootFunctionArr.push(item);
            }
        });
        return rootFunctionArr;
    };

    sortTableLocal = (sortColumn) => {
		let sortObj = sortTable(sortColumn, this.state.sort, this.state.sortDir);
		this.setState(
			{
				sort: sortObj.sortTemp,
				sortDir: sortObj.sortDirTemp,
			},
			() => {
				this.getObservationList();
			}
		);
	};

    handleStopPropagation = (e) => {
        e.stopPropagation();
    }

    assignResourcesDialogClose = () => {
        this.unSelectAllTaskResourceDialog();
        this.setState({
            showAssignResourcesDialogFlag:false
        })
    }

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
    }

    handleSelectionTasks = (e,observationId) => {
        e.stopPropagation();
        this.setState({
            showLoader:true
        })
        
        let isChecked = e.target.checked;
        let list = this.state.datalist;

        list.map((observation) => {
            observation.obsList.map((item) => {
                if(item.observationId == observationId){
                    item.isCheckboxSelected = isChecked;
                    return item;
                }
            })
        })
        this.setState({
            datalist:list,
            showLoader:false
        });
    }

    handleSelectionTaskInResourcesDialog = (e,observationItem) => {
        e.stopPropagation();
        let isChecked = e.target.checked;
        let list = this.state.datalistArr;

        list.map((observation) => {
            observation.obsList.map((item) => {
                if(item.observationId == observationItem.observationId){
                    item.isResourceCheckboxSelected = isChecked;
                }
            })
        })

        this.setState({
            datalistArr:list,
            showLoader:false
        });
    }

    selectAll = () => {
        let newDataList = [];
        for (const [key, value] of Object.entries(this.state.datalist)) {
            let newValue = value;
            let newTaskList = value.obsList.map(item=>{
                item.isCheckboxSelected = true;
                return item;
            })
            newValue.obsList = newTaskList;
            newDataList[key] = newValue;
        }

        this.setState({
            datalist:newDataList,
        })
    }

    selectAllTaskResourceDialog = () => {
        let newDataList = [];
        for (const [key, value] of Object.entries(this.state.datalistArr)) {
            let newValue = value;
            //when user "selects all" in resource dialog, we should select all the tasks
            //that are added to the assignment
            let newTaskList = value.obsList.map(item=>{
                item.isResourceCheckboxSelected = true;
                return item;
            })
            newValue.obsList = newTaskList;
            newDataList[key] = newValue;
        }

        this.setState({
            datalistArr:newDataList
        })
    }

    unSelectAll = () => {
        let newDataList = [];
        for (const [key, value] of Object.entries(this.state.datalist)) {
            let newValue = value;
            let newTaskList = value.obsList.map(item=>{
                item.isCheckboxSelected = false;
                return item;
            })
            newValue.obsList = newTaskList;
            newDataList[key] = newValue;
        }

        this.setState({
            datalist:newDataList,
        })
    }

    unSelectAllTaskResourceDialog = () => {
        let newDataList = [];
        for (const [key, value] of Object.entries(this.state.datalistArr)) {
            let newValue = value;
            let newTaskList = value.obsList.map(item=>{
                item.isResourceCheckboxSelected = false;
                return item;
            })
            newValue.obsList = newTaskList;
            newDataList[key] = newValue;
        }

        this.setState({
            datalistArr:newDataList
        })
    }

    onClickAddToAssignment = () => {
        let newDataList = [];
        for (const [key, value] of Object.entries(this.state.datalist)) {
            let newValue = value;
            let newTaskList = value.obsList.map(item=>{
                if(item.isCheckboxSelected){
                    item.added = true;
                }
                return item;
            })
            newValue.obsList = newTaskList;
            newDataList[key] = newValue;
        }
        
        this.setState({
            datalist:newDataList
        })
    }

    onClickRemoveFromAssignment = () => {
        let newDataList = [];
        for (const [key, value] of Object.entries(this.state.datalist)) {
            let newValue = value;
            let newTaskList = value.obsList.map(item=>{
                if(item.isCheckboxSelected){
                    item.added = false;
                    item.isCheckboxSelected = false;
                    item.addedResourceToAssignment = false;
                    item.resourceName = "";
                    item.resourceId = "";
                    item.resourceDesignation = "";
                    item.resourcefullName = "";
                    item.followUpDate = "";
                }
                return item;
            }) || [];
            newValue.obsList = newTaskList;
            newDataList[key] = newValue;
        }

        this.setState({
            datalist:newDataList
        })
    }

    onClickNextButton = () => {
        this.setState({
            showLoader:true
        })

        let isError = true;
        if(this.state.resourceUser.length === 0){
            isError = true;
        }else{
            Object.values(this.state.datalist).map(observationObj=>{
                observationObj.obsList.map(item=>{
                    if(item.added){
                        isError = false;
                    }
                })
            })
        }
        //filter newDatalist based on resource added
        let newArray = this.state.datalist.map(item=>({
            ...item,
            obsList: item.obsList.filter(e => e.added === true)
        })).filter(item => item.obsList.length > 0)
        if(isError){
            if(this.state.resourceUser.length === 0){
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:"Assign atleast one resource"
                })
            }else{
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:"Please add atleast one observation to the assignment"
                })
            }
            this.setState({
                showLoader:false
            });
        }else{
            this.setState({
                showAssignResourcesDialogFlag:true,
                showLoader:false,
                datalistArr:newArray,
                assignResourcesPlaceholder:"All"
            })
        }
    }

    resourceDropdownClick = (resource) => {                                                                
        let newDataList = [];
        for (const [key, value] of Object.entries(this.state.datalistArr)) {
            let newValue = value;
            let newTaskList = value.obsList.map(item=>{
                if(item.isResourceCheckboxSelected){
                    item.addedResourceToAssignment = true;
                    item.resourceName = resource.userName;
                    item.resourceId = resource.userId;
                    item.resourceDesignation = resource.designation;
                    item.resourcefullName = resource.fullName;
                }
                return item;
            })
            newValue.obsList = newTaskList;
            newDataList[key] = newValue;
        }
        this.setState({
            datalistArr:newDataList,
        })
    }

    onDateChange = (date) => {
        let newDataList = [];   
        for (const [key, value] of Object.entries(this.state.datalistArr)) {
            let newValue = value;
            let newTaskList = value.obsList.map(item=>{
                if(item.isResourceCheckboxSelected){
                    item.followUpDate = isEmptyVariable(date)?"":date.format("YYYY-MM-DD");
                }
                return item;
            }) || [];
            newValue.obsList = newTaskList;
            newDataList[key] = newValue;
        }

        this.setState({
            datalistArr:newDataList
        })
    };

    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader: true,
        });
        Promise.all([
            fetch(Constants.GetDraftReportHeadings, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.props.assignmentId,
                    donotReport: "N",
                }),
            }),
            fetch(Constants.GetFollowUpAssignedResources, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.props.assignmentId,
				}),
            }),
            fetch(Constants.GetAssignmentFollowUpObservations, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.props.assignmentId,
				}),
            }),
        ])
        .then(([observationRes,assignTaskRes, assignFollowUpObservation]) => {
            return Promise.all([observationRes.json(),assignTaskRes.json(),assignFollowUpObservation.json()]);
        })
        .then(([observationRes,assignTaskRes,assignFollowUpObservation]) => {
            let headingMap = [];
            let rootFunctionArr = [];
            let rootNodeId = ""; 
            let assignObservation = {};   

            if (
                observationRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                observationRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (observationRes.responseCode === Constants.CODE_SUCCESS) {
                headingMap = observationRes.result.headingMap;
                rootFunctionArr = this.getRootLevelFunctions(headingMap);
            } else {
            }

            let users = [];
            if(assignTaskRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || assignTaskRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            }else if(assignTaskRes.responseCode === Constants.CODE_SUCCESS) {
                let assignmentReviewMatrix = assignTaskRes.data.assignmentReviewMatrix;
                let assignmentMemberMatrix = assignTaskRes.data.assignmentMemberMatrix;
                //need to get only active users
                let activeMembers = assignmentMemberMatrix.filter(item=> item.status === "Active");
                let activeReviewers = assignmentReviewMatrix.filter(item=> item.status === "Active");
     
                users = [...activeMembers,...activeReviewers]
            }

            let followUpObservationTemp = [];
            if(
                assignFollowUpObservation.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignFollowUpObservation.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ){
                localStorage.clear();
                window.location = "/";
            } else if(assignFollowUpObservation.responseCode === Constants.CODE_SUCCESS) {
                followUpObservationTemp = assignFollowUpObservation.data.followUpObservations;
                followUpObservationTemp.forEach(item=>{
                    assignObservation[item.observationId] = item;
                })

                let datalistTemp = {};
                rootFunctionArr.map(item => {
                    let updatedTaskList = [];
                    item.obsList.forEach(observation=>{
                        if(!isEmptyVariable(assignObservation[observation.observationId])){
                            const key = observation.observationId;
                            observation.added = true;
                            observation.resourceName = isEmptyVariable(assignObservation[key].userName)?"":assignObservation[key].userName;
                            observation.resourceId = isEmptyVariable(assignObservation[key].userId)?"":assignObservation[key].userId;
                            observation.resourcefullName = (isEmptyVariable(assignObservation[key].userName)?"":assignObservation[key].userName)+"("+(isEmptyVariable(assignObservation[key].designation)?"":assignObservation[key].designation)+")";
                            observation.resourceDesignation = isEmptyVariable(assignObservation[key].designation)?"":assignObservation[key].designation;
                            observation.followUpDate = isEmptyVariable(assignObservation[key].followUpDate)?"":moment(assignObservation[key].followUpDate).format("YYYY-MM-DD");
                        }
                        updatedTaskList.push(observation);
                    });
                    item.obsList = updatedTaskList;
                    datalistTemp[item.nodeId] = item;
                })
            }

            this.setState({
				componentDidMountFlag: true,
                datalist: rootFunctionArr,
                rootNodeId: rootNodeId,
                showLoader: false,
                resourceUser:users,
                followUpObservation:followUpObservationTemp
            });
        });
    }

    onClickAddResourceToAssignment = () => {  
        let observations = [];
        Object.values(this.state.datalistArr).forEach(observationObj=>{
            observationObj.obsList.forEach(item=>{
                if(item.added){
                    let obj = {
                        observationId:item.observationId,
                        userId:item.resourceId,
                        userName:item.resourceName,
                        designation:item.resourceDesignation,
                        followUpDate:item.followUpDate,
                    }
                    observations.push(obj);
                }
            })
        })

        // call the fetch function
        fetch(Constants.AddFollowUpObservations, {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                observations:JSON.stringify(observations),
                assignmentId:this.props.assignmentId
            })
        })
        .then((response) => { 
            return response.json(); 
        })
        .then((data) => {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
            data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.assignResourcesDialogClose();
                this.unSelectAll();
                this.getObservationList();
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage
                })
            }else{
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage
                })
            }
        });       
    }

    getObservationList = () => {
        this.setState({
			showLoader: true,
		});

        Promise.all([
            fetch(Constants.GetDraftReportHeadings, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.props.assignmentId,
                    donotReport: "N",
                }),
            }),
            fetch(Constants.GetAssignmentFollowUpObservations, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.props.assignmentId,
				}),
            }),
        ])
        .then(([observationRes,assignFollowUpObservation]) => {
            return Promise.all([observationRes.json(),assignFollowUpObservation.json()]);
        })
        .then(([observationRes,assignFollowUpObservation]) => {
            let headingMap = [];
            let rootFunctionArr = [];
            let rootNodeId = "";
            let assignObservation = {};

            if (
                observationRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                observationRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (observationRes.responseCode === Constants.CODE_SUCCESS) {
                headingMap = observationRes.result.headingMap;
                rootFunctionArr = this.getRootLevelFunctions(headingMap);
            } else {
            }

            let followUpObservationTemp = [];
            if(
                assignFollowUpObservation.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignFollowUpObservation.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ){
                localStorage.clear();
                window.location = "/";
            } else if(assignFollowUpObservation.responseCode === Constants.CODE_SUCCESS) {
                followUpObservationTemp = assignFollowUpObservation.data.followUpObservations;
                followUpObservationTemp.forEach(item=>{
                    assignObservation[item.observationId] = item;
                })
                            
                let datalistTemp = {};
                rootFunctionArr.map(item => {
                    let updatedTaskList = [];
                    item.obsList.forEach(observation=>{
                        if(!isEmptyVariable(assignObservation[observation.observationId])){
                            const key = observation.observationId;
                            observation.added = true;
                            observation.resourceName = isEmptyVariable(assignObservation[key].userName)?"":assignObservation[key].userName;
                            observation.resourceId = isEmptyVariable(assignObservation[key].userId)?"":assignObservation[key].userId;
                            observation.resourcefullName = (isEmptyVariable(assignObservation[key].userName)?"":assignObservation[key].userName)+"("+(isEmptyVariable(assignObservation[key].designation)?"":assignObservation[key].designation)+")";
                            observation.resourceDesignation = isEmptyVariable(assignObservation[key].designation)?"":assignObservation[key].designation;
                            observation.followUpDate = isEmptyVariable(assignObservation[key].followUpDate)?"":moment(assignObservation[key].followUpDate).format("YYYY-MM-DD");
                        }
                        updatedTaskList.push(observation);
                    });
                    item.obsList = updatedTaskList;
                    datalistTemp[item.nodeId] = item;
                })
            }

            this.setState({
                componentDidMountFlag: true,
                datalist: rootFunctionArr,
                rootNodeId: rootNodeId,
                showLoader: false,
                followUpObservation:followUpObservationTemp
            });
        })
    }
    /*******************API CALLS END HERE*******************/

    render() {
        return (
            <Router>
                {
                    this.state.componentDidMountFlag &&
                    <div>
                        {
                            this.state.showLoader && 
                            <div class="loader"></div>
                        }
                        {
                            <div className="prev-next-layout">
                                <div className="flex-center-layout secondary-top-bar">
                                    {
                                        !this.props.showOnlyTasks &&
                                        <div className="back-btn-layout" onClick={this.props.onClickBackBtnFromAddTasks}>
                                            <span class="material-icons-outlined">arrow_back_ios</span>
                                        </div>
                                    }
                                    <h6>Observation</h6>
                                </div>
                                <div className="flex-center-layout">
                                    {!isEmptyVariable(this.props.observationTemplateId) &&
                                        <div style={{ marginRight: 10 }}>
                                            <FilterDropDown
                                                placeholder={this.props.observationTaskFilterPlaceholder}
                                                dropdownArr={this.props.observationTaskFilterDropDownArr}
                                                onDropDownItemClick={this.props.observationTaskFilterDropDownOnClick}
                                                themeSettings={themeSettings}
                                                name={"label"}
                                                dropdownWidth="200px"
                                            />
                                        </div>
                                    }   
                                    <div className="bordered-anchor" style={{marginRight:5}}>
                                        <a onClick={this.unSelectAll} href="javascript:void(0);">
                                            <span class="material-icons-outlined">
                                            check_box_outline_blank
                                            </span>
                                            <p>Unselect All</p>
                                        </a>
                                    </div>

                                    <div className="bordered-anchor" style={{marginRight:5}}>
                                        <a onClick={this.selectAll} href="javascript:void(0);">
                                            <span class="material-icons-outlined">
                                            check_box
                                            </span>
                                            <p>Select All</p>
                                        </a>
                                    </div>
                                    
                                    <AddNewButtonLayout themeSettings={themeSettings}
                                    style={{marginRight:5}}>
                                        <a onClick={this.onClickRemoveFromAssignment} href="javascript:void(0);">
                                            <span className="material-icons">remove_circle_outline</span>
                                            <p>Remove</p>
                                        </a>
                                    </AddNewButtonLayout>

                                    <AddNewButtonLayout themeSettings={themeSettings}>
                                        <a onClick={this.onClickAddToAssignment} href="javascript:void(0);">
                                            <span className="material-icons">add_circle</span>
                                            <p>Add to Assignment</p>
                                        </a>
                                    </AddNewButtonLayout>

                                    <AddNewButtonLayout themeSettings={themeSettings} style={{marginLeft:5}}>
                                        <a onClick={this.onClickNextButton} href="javascript:void(0);">
                                            {/* <span className="material-icons">add_circle</span> */}
                                            <p>Next</p>
                                            <span class="material-icons-outlined">arrow_forward_ios</span>
                                        </a>
                                    </AddNewButtonLayout>
                                </div>
                            </div>
                        }
                        {
						    // Need to check component did mount flag, other wise until api is called,
							// it shows no items found layout.
							this.state.componentDidMountFlag && (
								<div className="body-wrapper">
									{!isEmptyArray(this.state.datalist) && 
                                    this.state.datalist.map(item=>{
                                        return(
                                            <>
                                                {!isEmptyArray(item.obsList) &&
                                                    <div className="common-tab-bg">
                                                        <div className="common-table" style={{ width: "100%" }}>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th></th>
                                                                        <th className="c-pointer">
                                                                            <div className="sort-header">
                                                                                Observation
                                                                                <span className={
                                                                                    this.state.sort === sortObservationDetail ? "material-icons" : "material-icons hide-sort-arrow"
                                                                                }>
                                                                                    {this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th style={{minWidth:"150px"}}>Resource</th>
                                                                        <th style={{minWidth:"150px"}}>Follow Up Date</th> 
                                                                        <th>Added</th>
                                                                        <th width={"15%"} className="c-pointer" onClick={this.sortTableLocal.bind(this, sortRisk)}>
                                                                            <div className="sort-header">Risk
                                                                                <span className={
                                                                                    this.state.sort === sortRisk ? "material-icons" : "material-icons hide-sort-arrow"
                                                                                }>
                                                                                    {this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {!isEmptyArray(item.obsList) &&
                                                                        item.obsList.map((observationItem, idx) => {
                                                                        let userDetails = this.state.followUpObservation.filter(e => e.observationId == observationItem.observationId); 
                                                                        return (
                                                                                <tr style={{ background: "#f5f5f5", height:"40px"}}>
                                                                                    <td className="text-center">
                                                                                        <TableCheckBox onClick={this.handleStopPropagation} 
                                                                                        themeSettings={themeSettings}>
                                                                                            <input 
                                                                                                type="checkbox"
                                                                                                name={idx+":-:"+observationItem.observationId}
                                                                                                id={idx+":-:"+observationItem.observationId}
                                                                                                onChange={(e) => this.handleSelectionTasks(e,observationItem.observationId)}
                                                                                                checked={observationItem.isCheckboxSelected?true:false}
                                                                                            />
                                                                                            <label for={idx+":-:"+observationItem.observationId} 
                                                                                            style={{marginLeft:6}}></label>
                                                                                        </TableCheckBox>
                                                                                    </td>
                                                                                    <td>
                                                                                        {!isEmptyVariable(observationItem.observationDetail)?<div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{__html: observationItem.observationDetail}} />:""}
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            observationItem.added && (isEmptyArray(userDetails)?"":(isEmptyVariable(userDetails[0].userName)+isEmptyVariable(userDetails[0].designation))?"":userDetails[0].userName+"("+userDetails[0].designation+")")
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            observationItem.added && (isEmptyArray(userDetails)?"":isEmptyVariable(userDetails[0].followUpDate)?"":moment(userDetails[0].followUpDate).format("DD/MM/YYYY"))
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            observationItem.added &&
                                                                                            <span class="material-icons"
                                                                                            style={{color:`rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},${themeSettings.themeColor.a})`}}>check_circle</span>
                                                                                        }
                                                                                    </td>
                                                                                    <td>{observationItem.riskLevel}</td>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                }
                                                {isEmptyArray(item.obsList) &&
                                                    <div class="no-items-layout m-t-10">
                                                        <div class="no-items-card">
                                                            <h6>
                                                                {Constants.NO_RECORDS_WARNING}
                                                            </h6>
                                                        </div>
                                                    </div>
                                                }
                                            </>
									    )
                                    })}
								</div>
							)}
							{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
								<div class="no-items-layout m-t-10">
									<div class="no-items-card">
										<h6>
											{Constants.NO_RECORDS_WARNING}
										</h6>
									</div>
								</div>
							)}
							{
                                this.state.showObsDetailsLayout &&
                                <ObservationDetailsLayout
                                    observationId = {this.state.selectedObsIdForDetails}
									assignmentId = {this.state.assignmentId}
                                    handleHideObsDetailsLayout = {this.handleHideObsDetailsLayout}
                                    handleShowObsDetailsLayout={this.handleShowObsDetailsLayout}
                                />
                            }
                            <AlertDialog
                                showAlertDialog={this.state.showAlertDialogInfo}
                                handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                                type={Constants.ALERT_TYPE_ALERT}
                                alertDialogMessage={this.state.alertDialogMessageInfo}
                                proceedBtnClick={this.handleAlertDialogCloseInfo}
                                proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                                themeSettings={themeSettings}
                            />
                            <Modal className="followup-task-select-table-dialog custom-dialog"
                            show={this.state.showAssignResourcesDialogFlag} 
                            backdrop="static">
                                <Modal.Header>
                                    <h5>Assign Resources</h5>
                                    <button 
                                        onClick={this.assignResourcesDialogClose}
                                        type="button" data-dismiss="modal">
                                        <span class="material-icons">close</span>
                                    </button>
                                </Modal.Header>
                                <hr />
                                <div className="modal-body">
                                    <div className="body-wrapper">
                                        <div className="search-addnew-bg" style={{marginTop:0}}>
                                            <div className="search-addnew">
                                                <div className="flex-center-layout">
                                                    <div className="bordered-anchor" style={{marginRight:5, width:"120px"}}>
                                                        <a onClick={this.unSelectAllTaskResourceDialog} href="javascript:void(0);">
                                                            <span class="material-icons-outlined">
                                                                check_box_outline_blank
                                                            </span>
                                                            <p>Unselect All</p>
                                                        </a>
                                                    </div>
                                                    <div className="bordered-anchor" style={{marginRight:5, width:"120px"}}>
                                                        <a onClick={this.selectAllTaskResourceDialog} href="javascript:void(0);">
                                                            <span class="material-icons-outlined">
                                                                check_box
                                                            </span>
                                                            <p>Select All</p>
                                                        </a>
                                                    </div>
                                                    <div style={{width:"300px",marginRight:5}}>
                                                        <RegularDropdown 
                                                            placeholder={this.state.resourceDropdownPlaceholder}
                                                            dropdownArr={this.state.resourceUser}
                                                            labelParam="fullName"
                                                            onDropDownItemClick={this.resourceDropdownClick}
                                                            defaultPlaceholderDropDown={resourceDropdownPlaceholder}
                                                        />
                                                    </div>
                                                    <DatePicker
                                                        onChange={this.onDateChange}
                                                        format={"DD/MM/YYYY"}
                                                        placeholder="Select Date"
                                                        value={""}
                                                        disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
                                                    />
                                                </div>
                                                <div>
                                                    <DialogSaveButton themeSettings={themeSettings} 
                                                    onClick={this.onClickAddResourceToAssignment} type="button" 
                                                    className="modal-save m-b-0">Assign</DialogSaveButton>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="assign-taskresource" style={{position:"relative"}}>
                                            {
                                                !isEmptyArray(this.state.datalistArr) &&
                                                this.state.datalistArr.map(item => {
                                                    return (
                                                        !isEmptyArray(item.obsList) &&
                                                        <div>
                                                            <div>
                                                                <div className="common-tab-bg">
                                                                    <div className="common-table" style={{width:"100%"}}>
                                                                        <table className="table">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th></th>
                                                                                    <th className="c-pointer">
                                                                                        <div className="sort-header">
                                                                                            Observation
                                                                                            <span className={
                                                                                                this.state.sort === sortObservationDetail ? "material-icons" : "material-icons hide-sort-arrow"
                                                                                            }>
                                                                                                {this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
                                                                                            </span>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th style={{minWidth:"150px"}}>Resource</th> 
                                                                                    <th style={{minWidth:"150px"}}>Follow Up Date</th> 
                                                                                    <th width={"15%"} className="c-pointer" onClick={this.sortTableLocal.bind(this, sortRisk)}>
                                                                                        <div className="sort-header">Risk
                                                                                            <span className={
                                                                                                this.state.sort === sortRisk ? "material-icons" : "material-icons hide-sort-arrow"
                                                                                            }>
                                                                                                {this.state.sortDir === "asc" ? "arrow_upward" : "arrow_downward"}
                                                                                            </span>
                                                                                        </div>
                                                                                    </th> 
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    !isEmptyArray(item.obsList) && 
                                                                                    item.obsList.map((observationItem,idx) => {
                                                                                        if(observationItem.added){
                                                                                            return (
                                                                                                <tr>
                                                                                                    <td className="text-center">
                                                                                                        <TableCheckBox onClick={this.handleStopPropagation}
                                                                                                        themeSettings={themeSettings}>
                                                                                                            <input 
                                                                                                                type="checkbox"
                                                                                                                name={idx+":-:"+observationItem.observationId}
                                                                                                                id={"RES"+idx+":-:"+observationItem.observationId}
                                                                                                                onChange={(e) => this.handleSelectionTaskInResourcesDialog(e,observationItem)}
                                                                                                                checked={observationItem.isResourceCheckboxSelected?true:false}
                                                                                                            />
                                                                                                            <label for={"RES"+idx+":-:"+observationItem.observationId}
                                                                                                                style={{marginLeft:6}}></label>
                                                                                                        </TableCheckBox>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {!isEmptyVariable(observationItem.observationDetail)?<div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{__html: observationItem.observationDetail}} />:""}
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        {isEmptyVariable(observationItem.resourcefullName)?"":observationItem.resourcefullName}
                                                                                                    </td>
                                                                                                    <td>{isEmptyVariable(observationItem.followUpDate)?"":moment(observationItem.followUpDate).format("DD/MM/YYYY")}</td>
                                                                                                    <td>{observationItem.riskLevel}</td>
                                                                                                </tr>
                                                                                            )
                                                                                        }
                                                                                    })
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                isEmptyArray(item.obsList) &&
                                                                <div class="no-items-layout">
                                                                    <div class="no-items-card" style={{padding:"10px"}}>
                                                                        <h6 style={{margin:0,fontSize:"13px"}}>
                                                                            {Constants.NO_RECORDS_WARNING}
                                                                        </h6>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Modal>
                    </div>
                }
            </Router>
        )
    }
}
export default FollowUpObservation;