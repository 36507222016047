import React, { Component } from 'react';
import {Modal} from 'react-bootstrap';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables, getThemeSettingsVariables, isEmptyArray, isEmptyVariable} from '../../../common/Global/commonFunctions';
import * as TemplateConstants from "../../../common/Global/templateConstants";
import moment from 'moment';
import AddFollowUpTemplateValueDialog from "../Common/addFollowUpTemplateValueDialog";

const themeSettings  = getThemeSettingsVariables();
const userDetails = getLocalStorageVariables();

class FollowUpDetailsDialog extends Component {  
    constructor(props){
        super(props);
        this.state= {
            nextFollowUpDate : "",
            followUpTaskTemplateList:[],
            showFollowUpTemplateValueDialog:false,
            isEdit:false,
            followUpDetailsItem:this.props.followUpDetailsItem
        }
    }
    
    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showFollowUpDetailsDialog){
            this.setState({
                followUpDetailsItem:this.props.followUpDetailsItem
            })
        }
    }

    editFollowUpDetails = (value,version) => {
        this.getFollowupTaskTemplatevalues();
        this.setState({
            showFollowUpTemplateValueDialog:true,
            isEdit:true,
            value:value,
            version:version
        })
    }

    handleFollowUpTemplateValueDialogClose = (reloadFlag) => {
        this.setState({
            showFollowUpTemplateValueDialog:false,
            isEdit:false
        },() => {
            if(reloadFlag === true){
                this.getPreviousFollowUpTaskTemplateValues(this.props.assignmentTaskId,this.props.observationId)
            }
        })
    }
    
    taskEditable = () => {
        let taskItem = this.props.item;
        let endReviewUserId = "";
        for(var i=0;i<7;i++){
            if(taskItem.endReviewLevel===i){
                endReviewUserId=taskItem[`L${i}UserId`]
            }
        }
        if(!isEmptyVariable(endReviewUserId) && taskItem.currentReviewUserId==userDetails.userId && endReviewUserId==userDetails.userId){
            return false;
        }else{
            return !isEmptyVariable(taskItem.observationId)||taskItem.allowEdit==="N"?true:false  
        }
    }

    getFollowupTaskTemplatevalues = () => {
        this.setState({
			showLoader: true,
		});

        let postParam = {
            email: userDetails.email,
            accessToken: userDetails.accessToken
        };

        if(!isEmptyVariable(this.props.observationId)){
            postParam.observationId = this.props.observationId
        }else{
            postParam.assignmentTaskId = this.props.assignmentTaskId
        }

        fetch(Constants.GetFollowUpTaskTemplateValues, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams(postParam),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                let followUpTaskTemplateLists = data.data;
                if(!isEmptyVariable(this.props.observationId)){
                    this.setState({
                        observationId: this.props.observationId,
                    })
                }else{
                    this.setState({
                        assignmentTaskId: this.props.assignmentTaskId
                    })
                }
                this.setState({
                    followUpTaskTemplateList: followUpTaskTemplateLists,
                    showLoader: false,
                })
            } else {
                this.setState({
                    showLoader: false,
                });
            }
        });
    }

    getPreviousFollowUpTaskTemplateValues = (assignmentTaskId,observationId) => {
        let postParam = {
            email: userDetails.email,
            accessToken: userDetails.accessToken,
        }
        if(isEmptyVariable(observationId)){
            postParam.assignmentTaskId = assignmentTaskId;
        }else{
            postParam.observationId = observationId;
        }
        fetch(Constants.GetPreviousFollowUpTaskTemplateValues, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams(postParam),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                const previousCheckAndOpen = data.data.previousCheckAndOpen;
                this.setState({
                    followUpDetailsItem:previousCheckAndOpen
                })
            } else {
                this.setState({
                    showFollowUpDetailsDialog:false
                });
            }
        });
    }

    render() {
        return(
            <div>
                <Modal className="custom-dialog followUp-dialog" show={this.props.showFollowUpDetailsDialog} onHide={this.props.handleFollowUpDetailsDialogClose}>
                    <Modal.Header>
                        <h5>Follow Up Details</h5>
                        <button 
                            onClick={this.props.handleFollowUpDetailsDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div class="modal-body">
                        <div className="details-table-layout">
                            <div className="row custom-row">
                                <div className="col-md-12 custom-col">
                                    <table>
                                        <tbody> 
                                            {
                                                Object.keys(this.state.followUpDetailsItem).length !== 0 ?
                                                Object.entries(this.state.followUpDetailsItem).map(([key, value]) => {
                                                    return(
                                                        <div className="m-t-15">
                                                            <div className="followup-heading-container">
                                                                <p className="followup-heading">Follow Up Details {key}</p>
                                                                <p className="followup-heading-name">Name: {value[0].userName}</p>
                                                                <p className="followup-heading">Date: {moment(value[0].Date).format("DD/MM/YYYY")}</p>
                                                                {   
                                                                    !this.props.item &&
                                                                    <a onClick={()=>this.editFollowUpDetails(value,value[0].version)} href="javascript:void(0);">
                                                                        <span class="material-icons-outlined" 
                                                                        style={{
                                                                            color: `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`,
                                                                            opacity: "1",
                                                                            marginRight:22
                                                                        }}>edit</span>
                                                                    </a>
                                                                }
                                                            </div>
                                                            <div className="row custom-row">
                                                                <div className="col-md-12 custom-col">
                                                                    {value.map(item => {
                                                                        return(
                                                                            <tr>
                                                                                <td width={"38%"}>{item.fieldName}</td>
                                                                                <td width={"4%"}>:</td>
                                                                                <td width={"72%"}>
                                                                                    {
                                                                                        item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI?
                                                                                        !isEmptyArray(JSON.parse(item.value))?JSON.parse(item.value).join(", "):""
                                                                                        :item.value
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                    {!isEmptyVariable(value[0].nextFollowUpDate)&&
                                                                    <>
                                                                        <td  width={"38%"}>Next Follow Up Date</td>
                                                                        <td  width={"4%"}>:</td>
                                                                        <td  width={"72%"}>{!isEmptyVariable(value[0].nextFollowUpDate)?moment(value[0].nextFollowUpDate).format("DD/MM/YYYY"):""}</td>
                                                                    </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className="dialog-no-records">
                                                    <p>{Constants.NO_RECORDS_WARNING}</p>
                                                </div>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                {
                    <AddFollowUpTemplateValueDialog
                        showFollowUpTemplateValueDialog={this.state.showFollowUpTemplateValueDialog}
                        handleFollowUpTemplateValueDialogClose={this.handleFollowUpTemplateValueDialogClose}
                        isEdit={this.state.isEdit}
                        themeSettings={themeSettings}
                        followUpTaskTemplateList={this.state.followUpTaskTemplateList}
                        assignmentTaskId={this.props.assignmentTaskId}
                        observationId={this.props.observationId}
                        assignmentId={this.props.assignmentId}
                        title={"Follow Up Details "+this.state.version}
                        value={this.state.value}
                    />
                }
            </div>
        );
    }
}

export default FollowUpDetailsDialog;