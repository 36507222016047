import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {SearchSpan,TableCheckBox,DialogSaveButton,FilterCheckBox,TableIcon, AlertDialogSpan, DialogProceedButton} from '../../../common/Global/globalStyles.style';
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
import Pagination from '../../../common/Global/pagination';
import {getLocalStorageVariables,isEmptyVariable,sortTable,truncateString,
    isEmptyArray,ifEmptyReturnEmptyStr} from '../../../common/Global/commonFunctions.js';
import {Modal} from 'react-bootstrap';
import AlertDialog from '../../../common/AlertDialog/index.js';

const userDetails  = getLocalStorageVariables();
const sortName = "e.firstName";
const sortDesignation = "e.designation";
const ActiveStatus = "Active";

class SelectAuditeeDialog extends Component{
    constructor(props){
        super(props);
        this.state = {
            isComponentDidMountFlag:false,
            isDraftOnward:false,
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            resultSizePlaceholder:Constants.RESULT_SIZE,
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            sort:"",
            sortDir:"",

            selectedEmployeeIds:[],

            unitList: [],
            selectedUnit: {},
            selectedUnitIds:[],

            showEmpUnitChangeDialog:false,
            diffUnitEmpIds:[],
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        }
    }

    componentDidMount(){
        if(!isEmptyVariable(this.props.isDraftOnward)){
            this.state.isDraftOnward= this.props.isDraftOnward
        }
        this.getInitData()
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props)){
            this.getAuditeeList();
        }
    }

    getInitData = () => {
        let  getAssignmentEmployeesUrl = ''
        if(this.state?.isDraftOnward && this.state.isDraftOnward.url == '/draftassignmentemployee'){
            getAssignmentEmployeesUrl = Constants.GetDraftAssignmentEmployees
        }else{
            getAssignmentEmployeesUrl = Constants.GetAssignmentEmployees
        }
        Promise.all([
            fetch(Constants.GetEmployeeCreateMasterData,
            {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams(
                {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId:this.props.assignmentId

                })
            }),
            fetch(Constants.GetAllEmployees, {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams(
                {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    assignmentId:this.props.assignmentId,
                    status:ActiveStatus,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    activeFlag:"Y",
                    unitIds: JSON.stringify([this.props?.assignmentDetails?.unitId])??[]
                })
            }),
            fetch(getAssignmentEmployeesUrl, {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams(
                {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    assignmentId: this.props.assignmentId,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:ActiveStatus,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                    isAllEmployee:"Y"
                })
            }),
        ])
        .then(([masterRes, employeeRes, assignmentEmpRes]) => {
            return Promise.all([masterRes.json(), employeeRes.json(), assignmentEmpRes.json()]) 
        })
        .then(([masterRes, employeeRes, assignmentEmpRes]) => {
            let unitList = [];

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                unitList = masterRes.data.unit;
                if(unitList.length>0){
                    unitList.map(e=>e.isSelected=false)
                }
            }else{
            }

            let newDataList = []
            let totalCountTemp = 0;

            if(employeeRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                employeeRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(employeeRes.responseCode === Constants.CODE_SUCCESS){
                newDataList = employeeRes.data.result;
                totalCountTemp = employeeRes.data.count;
            }else{
                totalCountTemp = 0;
            }

            let assignmentEmpDataList = []
            let selectedEmployeeIds = [];

            if(assignmentEmpRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignmentEmpRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(assignmentEmpRes.responseCode === Constants.CODE_SUCCESS){
                assignmentEmpDataList = assignmentEmpRes.data.result;
                selectedEmployeeIds = assignmentEmpDataList?.map(data => data.employeeId) ?? [];
            }else{
            }
            unitList.sort((a, b) => a.unitName < b.unitName ? -1 : 1);
            unitList.unshift({
                unitName: "All",
                isSelected:true
            });

            let tmpSelctedUnitItem ='';
            if(unitList.length>0){
                unitList.map(e =>{
                    if(e.unitName==this.props?.assignmentDetails?.unitList.split(":-:")[0]){
                        e['isSelected']=true
                        tmpSelctedUnitItem = e;

                    }else{
                        e['isSelected']=false
                    }
                })
            }

            this.setState({
                isComponentDidMountFlag: true,
                totalCount:totalCountTemp,
                datalist: newDataList,
                unitList: unitList,
                selectedUnit:tmpSelctedUnitItem,
                selectedEmployeeIds: selectedEmployeeIds
            });
        });
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getAuditeeList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getAuditeeList();
            })
        }
    }

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getAuditeeList();
        })
    }


    handleSelection = (employeeId, e) => {
        e.stopPropagation();
        let selectedEmployeeIds = this.state.selectedEmployeeIds;
        let isChecked = e.target.checked;
        let newDataList = this.state.datalist;

        newDataList.map((item)=>{
            if(item.employeeId == employeeId) {
                item.isChecked = isChecked;
            }
        })

        

        if(isChecked){
            selectedEmployeeIds.push(employeeId)
        } else {
            selectedEmployeeIds = selectedEmployeeIds.filter(item=>item != employeeId);
        }

        this.setState({
            selectedEmployeeIds: selectedEmployeeIds,
            datalist: newDataList,
        });
    }

    handleStopPropagation = (e) => {
        e.stopPropagation();
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getAuditeeList();
        })
    }

    saveAssignmentEmployee = () => {
        let selectedEmployeeIds = this.state.selectedEmployeeIds;

            if(isEmptyArray(selectedEmployeeIds)){
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:"Select atleast one auditee"
                })
            }else{

            let assignemntUnitIds = [];

            if(!isEmptyVariable(this.props.assignmentDetails?.unitList)){
                let unitIds = this.props.assignmentDetails?.unitList.split(",") ||[];
                assignemntUnitIds = unitIds.map(e=>parseInt(e.split(':-:')[1]))
            }

            let diffUnitEmpIds = [];

            this.state.datalist.map(e=> {
                if(this.state.selectedEmployeeIds.includes(e.employeeId)){
                    if(e.units.length>0){
                            if(!assignemntUnitIds.some(itm=> e.units.map(e=>e.unitId).includes(itm))){
                                if(!diffUnitEmpIds.includes(e.employeeId)){
                                    diffUnitEmpIds.push(e.employeeId)
                                }
                            }
                    } else{
                        if(!diffUnitEmpIds.includes(e.employeeId)){
                            diffUnitEmpIds.push(e.employeeId) 
                        }
                    }
                }
            });
            let showEmpUnitChangeDialog = this.state.showEmpUnitChangeDialog
            if(diffUnitEmpIds.length>0 && (!this.state.isDraftOnward || this.state.isDraftOnward.url == '/draftassignmentemployee')){
                showEmpUnitChangeDialog = true;
                this.setState({
                    showEmpUnitChangeDialog:showEmpUnitChangeDialog,
                    diffUnitEmpIds:diffUnitEmpIds
                })
            } else {
                let addAssEmpUrl ='';
                if(this.state.isDraftOnward){
                    addAssEmpUrl = Constants.AddDraftAssignmentEmployee
                }else{
                    addAssEmpUrl = Constants.AddAssignmentEmployee
                }
                fetch(addAssEmpUrl,{
                    method: "post",
                    mode: "cors",
                    body: new URLSearchParams({
                        email: userDetails.email,
                        accessToken: userDetails.accessToken,
                        assignmentId: this.props.assignmentId,
                        employeeIds: JSON.stringify(this.state.selectedEmployeeIds)
                    })
                })
                .then((response)=>{
                    return response.json()
                })
                .then((data)=>{
                    if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                            localStorage.clear();
                            window.location = "/";
                    }else if (data.responseCode === Constants.CODE_SUCCESS) {
                            this.setState({
                                employeeIds: selectedEmployeeIds 
                        },()=>{
                            this.props.handleSelectDocDialogClose(true);
                        })
                    }else{
                        this.setState({
                            showAlertDialogInfo:true,
                            alertDialogMessageInfo:data.responseMessage
                        })
                    }
                })
            }
        }
    }

    /*****************************API*********************************/
    getAuditeeList = (closeDialog) => {
        // let companyId = this.props.assignmentDetails.companyId
        this.setState({
            showLoader:true,
        });
        
        let params = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            // companyId:companyId,
            assignmentId:this.props.assignmentId,
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            status:ActiveStatus,
            sort:this.state.sort,
            sortDir:this.state.sortDir,
        }

        if(!isEmptyVariable(this.state.selectedUnit) && this.state.selectedUnit.unitName!="All" ){

            let selectedUnitId = this.state.unitList.filter(e=>e.unitName==this.state.selectedUnit.unitName).map(e=>e.unitId)|| [];
            params.unitIds = JSON.stringify(selectedUnitId)
            
        }
        
        fetch(Constants.GetAllEmployees,
        {
            method:"POST",
            mode:"cors",
            body: new URLSearchParams(params)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let dataList = data.data.result;
                if(closeDialog=="y"){
                    this.props.handleSelectDocDialogClose(this.state.datalist)
                }
                this.setState({
                    showLoader:false,
                    isComponentDidMountFlag:true,
                    datalist:dataList,
                    totalCount:data.data.count,
                },()=>{
                    // this.props.getEmployeesList()
                });
            }else{
                this.setState({
                    showLoader:false,
                    isComponentDidMountFlag:true,
                    datalist:[],
                    totalCount:0,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            let unitIds = [];

            if(!isEmptyVariable(this.state.selectedUnit.unitId)){
                unitIds.push(this.state.selectedUnit.unitId)
            } 
            else if(isEmptyVariable(this.state.selectedUnit.unitId) && this.state.selectedUnit.unitName == "All"){
                unitIds = [];
            }
            else{
                unitIds.push(this.props?.assignmentDetails?.unitId)
            }

            let params = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:page,
                assignmentId:this.props.assignmentId,
                search:this.state.searchkey,
                status:ActiveStatus,
                resultsize:this.state.resultSize,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
                activeFlag:"Y",
            }

            if(unitIds.length>0){
                params.unitIds =JSON.stringify(unitIds)
            }

            fetch(Constants.GetAllEmployees,
            {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams(params)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    let dataList = data.data.result

                    this.setState({
                        showLoader:false,
                        isComponentDidMountFlag:true,
                        datalist:dataList,
                        totalCount:data.data.count,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        showLoader:false,
                        isComponentDidMountFlag:true,
                        datalist:[],
                        totalCount:0,
                    });
                }
            });
        }
    }

    filterUnit = (item, selectedIndex) => {
        let tempUnitList = this.state.unitList;
        if(tempUnitList.length>0){
            tempUnitList.map(e =>{
                if(e.unitName==item.unitName){
                    e['isSelected']=true
                }else{
                    e['isSelected']=false
                }
            })
        }
        this.setState({
            unitList:tempUnitList,
            selectedUnit: item,
        }, () => this.getAuditeeList());
    }
    closeEmpUnitChangeDialog = () =>{
        this.setState({
            showEmpUnitChangeDialog:false
        })
    }
    handleSelectedEmpUnitChange = (type,e) =>{
        let selectedEmployeeIds = this.state.selectedEmployeeIds;
        let apiCall = false;
        let action ='';
        if(type=="Cancle"){
            selectedEmployeeIds = selectedEmployeeIds.filter(e=>!this.state.diffUnitEmpIds.includes(e)) || selectedEmployeeIds;
            if(selectedEmployeeIds.length>0){
                apiCall = true;
            }
            action = 'Cancle';
        }
        else if(type=="Add"){
            action = 'Add';
            apiCall = true;
        }
        else if(type=="Change"){
            action = 'Change';
            apiCall = true;
        }

        if(apiCall){

            let params = {
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                assignmentId: this.props.assignmentId,
                employeeIds: JSON.stringify(selectedEmployeeIds),
                action:action
            }

            fetch(Constants.AddAssignmentEmployee,{
                method: "post",
                mode: "cors",
                body: new URLSearchParams(params)
            })
            .then((response)=>{
                return response.json()
            })
            .then((data)=>{
                if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                        localStorage.clear();
                        window.location = "/";
                }else if (data.responseCode === Constants.CODE_SUCCESS) {
                        this.setState({
                            employeeIds: selectedEmployeeIds,
                            showEmpUnitChangeDialog:false,
                    })
                }
                this.props.handleSelectDocDialogClose(true);
            })
        }else{
            this.setState({
                showEmpUnitChangeDialog:false,
        },()=>{this.props.handleSelectDocDialogClose(true);})
        }

    }

    render(){
        return(
            <section>
                <Modal className="task-select-table-dialog custom-dialog"
                    show={this.props.showDocumentDialog} 
                    onHide={this.props.handleSelectDocDialogClose}
                    size='xl'>
                    <Modal.Header>
                        <h5>Select Assignment Auditee</h5>
                        <button 
                            onClick={this.props.handleSelectDocDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        {
                            this.state.showLoader &&
                            <div class="loader"></div>
                        }
                        {
                            this.state.isComponentDidMountFlag &&
                            <div className="body-wrapper">
                                <div className="search-addnew-bg" style={{marginTop:0}}>
                                    <div className="search-addnew">
                                        
                                        <div className="search-filter-layout" style={{position:"relative"}}>
                                            <FilterDropDown
                                                placeholder={this.state.resultSizePlaceholder}
                                                dropdownArr={this.state.resultSizeArr}
                                                onDropDownItemClick={this.resultSizeDropdownClick}
                                                addBorder={true}
                                                themeSettings={this.props.themeSettings}
                                                mleft={0}
                                            />
                                            <div className="search-col m-l-10" style={{border:"1px solid #e5e5e5"}}>
                                                <input 
                                                    name="searchkey" 
                                                    type="text"
                                                    onChange={this.handleChangeSearch}
                                                    placeholder="Search" 
                                                    onKeyPress={this.onEnterBtnPress}
                                                    value={this.state.searchkey}
                                                />
                                                <SearchSpan 
                                                themeSettings={this.props.themeSettings}
                                                onClick = {this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                                            </div>
                                            <FilterDropDown
                                                placeholder={this.state.selectedUnit?.unitName && this.state.selectedUnit.unitName !== "All" ? this.state.selectedUnit.unitName : "Unit"}
                                                dropdownArr={this.state.unitList}
                                                onDropDownItemClick={this.filterUnit}
                                                themeSettings={this.props.themeSettings}
                                                name={"unitName"}
                                                addBorder={true}
                                                dropdownWidth="200px"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div style={{position:"relative"}}>
                                    {
                                        !isEmptyArray(this.state.datalist) && 
                                        <div className="common-tab-bg">
                                            <div className="common-table" style={{width:"100%",border:"1px solid #e5e5e5"}}>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-center" width={60}></th>
                                                            <th className="text-center" width={60}>No</th>
                                                            <th width={"30%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortName)}>
                                                                <div className="sort-header">Name
                                                                    <span className={(this.state.sort === sortName)?"material-icons":"material-icons hide-sort-arrow"}>
                                                                        {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                    </span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDesignation)}>
                                                                <div className="sort-header">Designation
                                                                    <span className={(this.state.sort === sortDesignation)?"material-icons":"material-icons hide-sort-arrow"}>
                                                                        {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                    </span>
                                                                </div>
                                                            </th>
                                                            <th width={"30%"} className="c-pointer">
                                                                <div className="sort-header">Unit
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        !isEmptyArray(this.state.datalist) && 
                                                        this.state.datalist.map((item,idx) => {
                                                            let employeeName = `${isEmptyVariable(item.salutation)?"":item.salutation} ${isEmptyVariable(item.firstName)?"":item.firstName} ${isEmptyVariable(item.lastName)?"":item.lastName}`

                                                            return <tr key={idx + "_" + item.employeeId}>
                                                                <td>
                                                                    <TableCheckBox onClick={this.handleStopPropagation}
                                                                        themeSettings={this.props.themeSettings}>
                                                                        <input 
                                                                            type="checkbox"
                                                                            name={idx}
                                                                            id={item.employeeId}
                                                                            onChange={this.handleSelection.bind(this, item.employeeId)}
                                                                            checked={this.state.selectedEmployeeIds.includes(item.employeeId)}
                                                                        />
                                                                        <label htmlFor={item.employeeId} 
                                                                        style={{marginLeft:6}}></label>
                                                                    </TableCheckBox>
                                                                </td>
                                                                <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                                <td>{employeeName}</td>
                                                                <td>{item.designation}</td>
                                                                <td>{isEmptyArray(item.units)?"":item.units.map(e=>e.unitName).join(", ") ||''}</td>
                                                            </tr>
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.isComponentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                        <div class="no-items-layout">
                                            <div class="no-items-card">
                                                <h6>
                                                {
                                                    isEmptyVariable(this.state.searchkey)?
                                                    Constants.NO_RECORDS_WARNING
                                                    :
                                                    Constants.EMPTY_SEARCH_WARNING
                                                }
                                                </h6>
                                            </div>
                                        </div>
                                    }
                                    <div className="pagination-layout">
                                        <div className="row custom-row">
                                            <div className="col-md-9 custom-col">
                                            {
                                                (this.state.totalCount > this.state.datalist.length) && 
                                                <Pagination 
                                                    totalLength ={this.state.totalCount} 
                                                    items={this.state.datalist} 
                                                    onChangePage={this.onChangePage} 
                                                    pageSize={this.state.resultSize}
                                                    currentPageNo = {this.state.currentPageNo}
                                                    initialPage={this.state.currentPageNo}
                                                    themeSettings={this.props.themeSettings} />
                                            }
                                            </div>
                                            <div className="col-md-3 custom-col">
                                                <p>{"Total Records: "+this.state.totalCount}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* {
                                    !isEmptyVariable(this.state.documentFormErr) &&
                                    <span className="cm-error">{this.state.documentFormErr}</span>
                                } */}
                                <div className="modal-close-save m-t-15">
                                    <div className="close-save-col">
                                        <button onClick={this.props.handleSelectDocDialogClose} type="button" 
                                        className="modal-close m-b-0">Cancel</button>
                                        <DialogSaveButton  themeSettings={this.props.themeSettings} 
                                        onClick={this.saveAssignmentEmployee} type="button" 
                                        className="modal-save m-b-0">Save</DialogSaveButton>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Modal>
                
                <Modal className="task-select-table-dialog custom-dialog"
                    show={this.state.showEmpUnitChangeDialog} 
                    onHide={this.closeEmpUnitChangeDialog}
                    size='xl'>
                    <Modal.Header>
                        <h5>Change Auditee's Unit</h5>
                        <button 
                            onClick={this.closeEmpUnitChangeDialog}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        {
                            <div className="body-wrapper">
                                <div style={{position:"relative"}}>
                                    <div className="col-md-12">
                                        <p style={{fontSize: '12px'}}><strong>*Note:</strong> Following employees belong to units different than the assignment unit. Employee must be moved to assignment unit.</p>
                                    </div>
                                    {
                                        !isEmptyArray(this.state.datalist) && 
                                        <div className="common-tab-bg">
                                            <div className="common-table" style={{width:"100%",border:"1px solid #e5e5e5"}}>
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th width={"30%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortName)}>
                                                                <div className="sort-header">Name
                                                                    <span className={(this.state.sort === sortName)?"material-icons":"material-icons hide-sort-arrow"}>
                                                                        {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                    </span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDesignation)}>
                                                                <div className="sort-header">Designation
                                                                    <span className={(this.state.sort === sortDesignation)?"material-icons":"material-icons hide-sort-arrow"}>
                                                                        {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                    </span>
                                                                </div>
                                                            </th>
                                                            <th width={"30%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortName)}>
                                                                <div className="sort-header">Unit
                                                                    <span className={(this.state.sort === sortName)?"material-icons":"material-icons hide-sort-arrow"}>
                                                                        {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                    </span>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {
                                                        !isEmptyArray(this.state.datalist) && 
                                                        this.state.datalist.filter(e=>this.state.diffUnitEmpIds.includes(e.employeeId)).map((item,idx) => {
                                                            let employeeName = `${isEmptyVariable(item.salutation)?"":item.salutation} ${isEmptyVariable(item.firstName)?"":item.firstName} ${isEmptyVariable(item.lastName)?"":item.lastName}`
                                                            return <tr key={idx + "_" + item.employeeId}>
                                                                <td>{employeeName}</td>
                                                                <td>{item.designation}</td>
                                                                <td>{isEmptyArray(item.units)?"":item.units.map(e=>e.unitName).join(", ") ||''}</td>
                                                            </tr>
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }
                                </div>

                                <div className="modal-close-save m-t-15">
                                    <div className="close-save-col">
                                        <button onClick={this.handleSelectedEmpUnitChange.bind(this,"Cancle")} type="button" 
                                        className="modal-close m-b-0">Cancel</button>
                                        <DialogSaveButton  themeSettings={this.props.themeSettings} 
                                        onClick={this.handleSelectedEmpUnitChange.bind(this,"Add")} type="button" 
                                        className="modal-save m-b-0">Add Unit</DialogSaveButton>
                                        <DialogSaveButton  themeSettings={this.props.themeSettings} 
                                        onClick={this.handleSelectedEmpUnitChange.bind(this,"Change")} type="button" 
                                        className="modal-save m-b-0">Change Unit</DialogSaveButton>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Modal>

                <AlertDialog
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type={Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                    themeSettings={this.props.themeSettings}
                />
            </section>
        )
    }
}

export default SelectAuditeeDialog;