import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import * as TemplateConstants from "../../../common/Global/templateConstants";
import { getLocalStorageVariables, isEmptyVariable, ifEmptyReturn, isEmptyArray, getOnlyFileNameFromPath, truncateString, isJsonString } from "../../../common/Global/commonFunctions";
import { AddNewButtonLayout, TableIcon } from "../../../common/Global/globalStyles.style";
import AddNewResponseDialog from "./addNewResponseDialog";
import { Modal } from "react-bootstrap";

const userDetails = getLocalStorageVariables();

class ReviewHistoryDialog extends Component {
	constructor(props) {
		super(props);
		
		let isDraftObservation = false;
		if (this.props.location.pathname.includes(Constants.DRAFTOBSERVATIONS_PATH)) {
			isDraftObservation= true;
		}
		this.state = {
			componentDidMountFlag: false,
			showLoader: false,
			employeesList: [],
			showCreateDialog: false,
			target:[],
			isEdit:false,
			isDraftObservation:isDraftObservation,
			templateValues:[],
			responseTemplateFields:[],
			supportings:[],
			responseTemplateValues:[],
			observationResponses:[]
		};
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showViewResponseDialog) {
			this.setState({
				componentDidMountFlag: false,
				showLoader: false,
				employeesList: [],
				showCreateDialog: false,
				target:[],
				isEdit:false,
				responseTemplateValues:[]
			},() => {
				this.getInitData();
			});
		}
	}
	handleCreateDialogShow = () => {
		this.setState({
			showCreateDialog: true,
			selectedId: "",
			isEdit: false,
		});
	};
	handleEditDialogShow = () => {
		this.setState({
			showCreateDialog: true,
			selectedId: "",
			isEdit: true,
		});
	};

	handleAddDialogClose = (reloadFlag) => {
		this.setState({
			showCreateDialog: false,
		},() => {
			if (reloadFlag == true) this.getInitData();
		}
		);
	};

	downloadAttachment = (documentPath,e) => {
        e.stopPropagation();
        fetch(Constants.API_SERVER_PATH+documentPath)
        .then(response => { return response.blob(); } )
        .then(blob => {
            let fileName = getOnlyFileNameFromPath(documentPath);
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

	getInitData = () => {
		this.setState({
			showLoader: true,
		});

		let url = "";
		if(this.props.isDraftOnward === true){
			url = Constants.GetDraftObservationResponse;
		}else{
			url = Constants.GetObservationResponses;
		}

		let params = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
            assignmentId: this.props.assignmentId
		}

		if(!isEmptyVariable(this.props.observationId)){
			params.observationId = this.props.observationId
		}else if(!isEmptyVariable(this.props.assignmentTaskId)){
			params.assignmentTaskId = this.props.assignmentTaskId
		}

		fetch(url, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(params),
		})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
				localStorage.clear();
				window.location = "/";
			} else if (data.responseCode === Constants.CODE_SUCCESS) {
				let targetArr = [];
				let target = "";
				let responseTemplateFields = [];
				let supportings = [];
				let employees = data.data.employees;
				let observationResponse = data.data.observationResponses;
				supportings = data.data.supportings;
				if(!isEmptyVariable(data.data.responseTemplateFields)){
					try{
						responseTemplateFields = JSON.parse(data.data.responseTemplateFields)
					}catch(e){}
				}
				if(!isEmptyArray(observationResponse)){
					if(!isEmptyVariable(observationResponse[0].immediate) && observationResponse[0].immediate === "Y" ){
						targetArr.push("Immediate");
					}
					if(!isEmptyVariable(observationResponse[0].continuous) && observationResponse[0].continuous === "Y" ){
						targetArr.push("Continuous");
					}
					if(!isEmptyVariable(observationResponse[0].customTarget)){
						targetArr.push(observationResponse[0].customTarget);
					}
					target = targetArr.join(", ")
				}
				let tmpTemplaveValues = {};
				if(!isEmptyArray(observationResponse)){
					try{
						let tmpValues = observationResponse;
						tmpValues.map(e=>{
							const key = e["fieldId"]
							tmpTemplaveValues[key] = e["value"]
						})
					}catch(e){}
				}
				this.setState({
					employeesList: employees,
					observationResponses:observationResponse,
					showLoader: false,
					supportings:supportings,
					responseTemplateFields:responseTemplateFields,
                    responseTemplateValues:tmpTemplaveValues,
					componentDidMountFlag: true,
					target:target,
				});
			} else {
				this.setState({
					employeesList: [],
					showLoader: false,
					componentDidMountFlag: true,
				});
			}
		});
	};

	render() {
		return (
			<Modal className="review-history-dialog custom-dialog" show={this.props.showViewResponseDialog} onHide={this.props.handleViewResponseDialogClose}>
				<Modal.Header>
					<h5>Response</h5>
					<button onClick={this.props.handleViewResponseDialogClose} type="button" data-dismiss="modal">
						<span class="material-icons">close</span>
					</button>
				</Modal.Header>
				<hr />
				<div className="modal-body">
					{this.state.showLoader && (
						<div class="loader"></div>
					)}
					<div className="review-history-wrapper">
						{
							this.state.isDraftObservation &&
							!this.props.isReportCompleted &&
							<div className="search-addnew-bg">
								<div className="search-addnew">
									<div></div>
									<AddNewButtonLayout themeSettings={this.props.themeSettings}>
										{
											isEmptyVariable(this.state.observationResponses) && this.state.isDraftObservation 
											?
											<a onClick={this.handleCreateDialogShow} href="javascript:void(0);">
												<span className="material-icons">add_circle</span>
												<p>Add New</p>
											</a>
											:
											<a onClick={this.handleEditDialogShow} href="javascript:void(0);">
												<span className="material-icons">edit</span>
												<p>Edit Response</p>
											</a>
										}
										
									</AddNewButtonLayout>
								</div>
							</div>
						}
						
						{this.state.componentDidMountFlag && (
							<div className="review-history-list">
								<div className="history-item-layout">
								{this.state.responseTemplateFields.map((item,index)=>{
									let isFieldIdCheck = isEmptyVariable(this.state.responseTemplateValues[item.fieldId]);
									let fieldIdValue = this.state.responseTemplateValues[item.fieldId];
									let employeeName = [];
									if(item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
										item.isResponsibilityField == "Y"){
										this.state.employeesList.map(e => {
											!isFieldIdCheck && JSON.parse(fieldIdValue).map(id => {
												if(id == e.employeeId){
													employeeName.push(e)
												}
											})
										})
									}
									return (
										<>
											{
												index == 1 && !isEmptyVariable(this.state.target) &&
												<span>Target : {" "+this.state.target}</span>
											}
											{(item.fieldType === TemplateConstants.FIELD_TYPE_TEXT ||
												item.fieldType === TemplateConstants.FIELD_TYPE_DATE ||
												item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN) &&
												!isFieldIdCheck && <span>{item.fieldName} : {" "}{fieldIdValue}</span>
											}
											{
												item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
												item.isResponsibilityField == "Y" &&  !isFieldIdCheck && 
												<span>{item.fieldName} : {" "}
												{employeeName.map((item, index) => {
													return <>{(index ? ", " : "") + ifEmptyReturn(item.salutation) + " " + item.firstName + " " + ifEmptyReturn(item.middleName) + " " + item.lastName}</>
												})}</span>
											}
											{
												item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI &&
												isEmptyVariable(item.isResponsibilityField) && !isFieldIdCheck && 
												<span>{item.fieldName} : {" "}
													{isJsonString(fieldIdValue) ? JSON.parse(fieldIdValue).join(", ") : fieldIdValue}
												</span>
											}
											{
												item.fieldType === TemplateConstants.FIELD_TYPE_TABLE && !isFieldIdCheck && 
												<>
													{(item.fieldName + " : ")}
													<table class="table instances-table custom-table">
														{
															!isFieldIdCheck && JSON.parse(fieldIdValue).map((rowItem, rowIndex) =>  {
																if(rowIndex === 0){
																	return <thead className="instances-thead display-table">
																		<tr>
																		{
																			rowItem.map((colItem, colIndex) => {
																				return <th width={colItem === "Action"?"5%":""} className= {colItem === "Action"?"text-center":""}>
																					<div className="instances-table-header">
																						<p>
																							{colItem === "Action" ? "" : colItem}
																						</p>
																					</div>
																				</th>
																			})
																		}
																		</tr>
																	</thead>
																}else{
																	return <tbody className="instances-thead display-table">
																		<tr>
																		{
																			rowItem.map((colItem, colIndex) => {
																				return <td
																					className={colItem === "Action" ? "text-center" : ""}
																				>
																					{colItem === "Action" ? "" : colItem}
																				</td>
																			})
																		}
																		</tr>
																	</tbody>
																}
															})
														}
													</table>
												</>
											}
										</>
									)
								})}
								</div>
								{!isEmptyArray(this.state.supportings) &&
									<div className="common-tab-bg">
										<div className="common-table" style={{width:"100%"}}>
											<table className="table">
												<thead>
													<tr>
														<th className="text-center" width={"5%"}>No.</th>
														<th width={"5%"}>File Name</th>
														<th width={"5%"}>Remarks</th>
														<th className="text-center" width={"5%"}>Download</th>
													</tr>
												</thead>
												<tbody>
													{this.state.supportings.map((item,index) => {
														return(
															<tr>
																<td className="text-center">{(index+1)}</td>
																<td>{getOnlyFileNameFromPath(item.filePath)}</td>
																<td>{truncateString(item.remarks,Constants.STRING_LENGTH)}</td>
																<TableIcon themeSettings={this.props.themeSettings} className="text-center">
																	<span class="material-icons" onClick={this.downloadAttachment.bind(this, item.filePath)}>get_app</span>
																</TableIcon>
															</tr>
														)
													})}
												</tbody>
											</table>
										</div>
									</div>
								}
							</div>
						)}
						{this.state.componentDidMountFlag && isEmptyArray(this.state.observationResponses) && (
							<div className="dialog-no-records">
								<p>{Constants.NO_RECORDS_WARNING}</p>
							</div>
						)}
					</div>
				</div>

				<AddNewResponseDialog
					showAddResponseDialog={this.state.showCreateDialog}
					handleAddResponseDialogClose={this.handleAddDialogClose}
					observationId= {this.props.observationId}
					themeSettings={this.props.themeSettings}
                    assignmentId = {this.props.assignmentId}
					assignmentTaskId={this.props.assignmentTaskId}
					isEdit={this.state.isEdit}
					responseObj={this.state.observationResponses}
					supportings={this.state.supportings}
					isDraftOnward = {this.props.isDraftOnward}
					responseTemplateFields={this.state.responseTemplateFields}
					employees={this.state.employeesList}
				/>
				
			</Modal>
		);
	}
}

export default ReviewHistoryDialog;
