import React from "react";
import * as Constants from "../Global/constants";
import { AssigmentDetailsIconLayout } from "../Global/globalStyles.style";
import { getLocalStorageVariables, isEmptyVariable, isJsonString } from "../Global/commonFunctions";
import ReportDetails from "../../pages/Reports/Common/reportDetails";
import InfoCardComponent from "../../pages/Execute/infoCard/infoCardComponent";
import { getAllReportTasks } from "../../pages/Execute/Tasks/execTaskUtilFunctions";
const userDetails = getLocalStorageVariables();
const projectAuditType = "Project";
const employeeAuditType = "Employee";

export default class TopmenuReports extends React.PureComponent {
    constructor(props) {
		super(props);
        this.state = {
            assignmentDetails: {},
            showDetailsDialog: false,
            selectedItem: "",
            showInfoCard:false,
            totalTaksCount:0,
        };
	}

    handleDetailsDialogShow = (item, unitList) => {
        this.setState({
            showDetailsDialog: true,
            selectedItem: item,
            unitList: unitList
        });
    };

    handleGetAssignmentDetails = () => {
        fetch(Constants.GetAssignmentsDetails, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                assignmentId: this.props.assignmentId,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let assignmentDetails = {};
                let unitList = [];
                if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    assignmentDetails = data.data.assignmentDetails;
                    if(assignmentDetails.auditType === projectAuditType){
                        unitList = data.data.projectUnitList;
                    }else if(assignmentDetails.auditType === employeeAuditType){
                        unitList = data.data.defaultEmployeeUnitList;
                    }
                } else {
                }
                this.setState({
                    assignmentDetails: assignmentDetails,
                    unitList:unitList
                }, () => {
                    this.handleDetailsDialogShow(this.state.assignmentDetails, this.state.unitList);
                });
            });
    };

    handleGetInfoCardDetials = () => {

        let getTaskListUrl = Constants.GetReportsTasksAll;
        if(this.props.isDraftOnward === true){
            getTaskListUrl = Constants.GetDraftReportsTasksAll
        }
        let getAssignmentDetailsUrl = Constants.GetAssignmentsDetails;
        if(this.props.isDraftOnward === true){
            getAssignmentDetailsUrl = Constants.Getdraftreportdetails
        }
        let params ={
            email: userDetails.email,
            accessToken: userDetails.accessToken,
            assignmentId: this.props.assignmentId,
        }
        if(this.props.isInitDraftOnward){
            params.initFlag="Y"
        }
        Promise.all([
            fetch(getTaskListUrl, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams(params),
            }),
            fetch(getAssignmentDetailsUrl, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.props.assignmentId,
                }),
            }),
        ])
            .then(([taskRes, assignmentRes]) => {
                return Promise.all([taskRes.json(), assignmentRes.json()]);
            })
            .then(([taskRes, assignmentRes]) => {
                let allDatalistTemp = [];
                let myDatalistTemp = [];
                let assignmentArea = "";

                //Since we need assignment area lets check this first
                let selectedTaskTemplateFields = [];
                let execTaskTemplateFields = [];
                let taskTemplateValuesMap = {};
                let executionTemplateValuesMap = {};
                let executionTemplateFixedFields = {};
                let assignmentRiskMatrix = [];
                let valueAdditions = [];
                if (assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
                    if(this.props.isDraftOnward === true){
                        assignmentArea = assignmentRes.data.result.assignmentArea;
                    }else{
                        assignmentArea = assignmentRes.data.assignmentDetails.assignmentArea;
                    }
                    //TASK TEMPLATE RELATED STUFFS
                    taskTemplateValuesMap = assignmentRes.data.taskTemplateValuesMap;
                    let selectedTaskTemplateFieldsStr = assignmentRes.data.executionTemplateFields?.taskTemplateFields;//Template field IDs	
                    if (!isEmptyVariable(selectedTaskTemplateFieldsStr) && isJsonString(selectedTaskTemplateFieldsStr)) {
                        selectedTaskTemplateFields = JSON.parse(selectedTaskTemplateFieldsStr);
                    }

                    //EXEC TASK RELATED STUFFS
                    executionTemplateValuesMap = assignmentRes.data.executionTemplateValuesMap;
                    let execTaskTemplateFieldsStr = assignmentRes.data.executionTemplateFields?.templateFields;
                    if (!isEmptyVariable(execTaskTemplateFieldsStr) && isJsonString(execTaskTemplateFieldsStr)) {
                        execTaskTemplateFields = JSON.parse(execTaskTemplateFieldsStr);
                    }

                    //EXEC TASK FIXED FIELDS
                    let fixedTemplateFieldsStr = assignmentRes.data.executionTemplateFields?.fixedTemplateFields;
                    if (!isEmptyVariable(fixedTemplateFieldsStr) && isJsonString(fixedTemplateFieldsStr)) {
                        executionTemplateFixedFields = JSON.parse(fixedTemplateFieldsStr);
                    }

                    assignmentRiskMatrix = assignmentRes.data.riskLevels;
                    valueAdditions = assignmentRes.data.valueAdditions;

                } else {
                }

                let maxRank = 0;
                let temptotalTaksCount = 0;

                if (taskRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || taskRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (taskRes.responseCode === Constants.CODE_SUCCESS) {
                    allDatalistTemp = taskRes.data.assignedTasks;
                    let allDataList = JSON.parse(JSON.stringify(allDatalistTemp))
                    myDatalistTemp = getAllReportTasks(allDataList, assignmentArea, maxRank);
                    // let userId = "";
                    // if (!isEmptyVariable(this.props.selectedResourceId)) {
                    //     userId = this.props.selectedResourceId
                    // }
                    // else {
                    //     userId = this.props.userDetails.userId;
                    // }
                    // arr = this.getUserTasksFromUserId(userId, allDatalistTemp);

                    Object.values(myDatalistTemp).map((item) => {
                        temptotalTaksCount += Object.values(item.taskList).length
                    })
                }

                this.setState({
                    datalist: myDatalistTemp,
                    selectedTaskTemplateFields: selectedTaskTemplateFields,
                    taskTemplateValuesMap: taskTemplateValuesMap,
                    execTaskTemplateFields: execTaskTemplateFields,
                    executionTemplateValuesMap: executionTemplateValuesMap,
                    executionTemplateFixedFields: executionTemplateFixedFields,
                    showInfoCard: true,
                    totalTaksCount: temptotalTaksCount,
                    assignmentArea: assignmentArea
                });
            });
    }

    getUserTasksFromUserId = (userId,allData) => {
        let arr = allData.map((process) => {
            let taskList = [];
            let maxScore = 0;
            let totalScore = 0;
            process.taskList.forEach((task) => {
                if (task.userId + "" === userId + "") {
                    taskList.push(task);

                    //Now if it is checklist, then based on the type of checklist we need to assign scores
                    if (this.state.assignmentArea === "CheckList") {
                        let weight = isEmptyVariable(task.weight) ? 1 : task.weight;
                        let rank =
                            isEmptyVariable(task.rank) || task.rank === -1 ? 0 : task.rank;

                        //if rank is NA then that should be considered for max score also
                        let maxRankNew = task.rank === -1 ? 0 : this.state.checklistMaxRank;
                        maxScore += weight * maxRankNew;
                        totalScore += weight * rank;
                    }
                }
            });
            process.taskList = taskList;
            process.maxScore = maxScore;
            process.totalScore = totalScore;

            return process;
        });
        return arr;
    };

    handleDetailsDialogClose = () => {
		this.setState({
			showDetailsDialog: false,
			selectedItem: "",
		});
	};

    closeInfoCard = () =>{
        this.setState({
            showInfoCard:false,
        })
    }

    render () {
        return (
            <React.Fragment>
                <AssigmentDetailsIconLayout themeSettings={this.props.themeSettings}>
                    <span class="material-icons" onClick={this.handleGetInfoCardDetials}>analytics</span>
                    <span className="material-icons" onClick={this.handleGetAssignmentDetails}>info</span>
                </AssigmentDetailsIconLayout>
                <ReportDetails
                    showDetailsDialog={this.state.showDetailsDialog}
                    handleDetailsDialogClose={this.handleDetailsDialogClose}
                    selectedItem={this.state.selectedItem}
                    unitList={this.state.unitList}
                    themeSettings={this.props.themeSettings}
                    company={this.props.company}
                    isDraftOnward={this.props.isDraftOnward}
                />
                {
                    this.state.showInfoCard &&
                    <InfoCardComponent
                        execTaskTemplateFields={this.state.execTaskTemplateFields}
                        taskTemplateFields={this.state.selectedTaskTemplateFields}
                        datalist={this.state.datalist}
                        showUploadAnnexureDialog={this.state.showInfoCard}
                        themeSettings={this.props.themeSettings}
                        executionTemplateValuesMap={this.state.executionTemplateValuesMap}
                        handleSelectUploadAnnexureDialogClose={this.closeInfoCard}
                        totalTaskCount={this.state.totalTaksCount}
                        taskTemplateValuesMap={this.state.taskTemplateValuesMap}
                        assignmentArea={this.state.assignmentArea}
                    />
                }
            </React.Fragment>
        )
    }
}
