import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { SearchSpan } from "../../../common/Global/globalStyles.style";
import Sidebar from "../../../common/Sidebar";
import Topbar from "../../../common/Topbar";
import FilterDropDown from "../../../common/DropdownMenus/FilterDropdown";
import MasterMenuTabs from "../Common/topmenu";
import { 
    getLocalStorageVariables, 
    getThemeSettingsVariables, 
    isEmptyVariable, 
    sortTable,
    isEmptyArray,
    truncateString,
    getStringWithSpaceAfterComma,
    GetOrderedMasterTabMenuExecutes,
} from "../../../common/Global/commonFunctions";
import { BrowserRouter as Router } from "react-router-dom";
import moment from "moment";
import Pagination from "../../../common/Global/pagination";
import TableDropDown from "../../../common/DropdownMenus/TableDropdown";
import AlertDialog from "../../../common/AlertDialog";

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();
const sortName = "c.companyName";
const sortReferenceNo = "a.referenceNo";
const sortDescription = "a.assignmentTypeName";
const sortReportDate = "a.reportDate";
const sortArea = "a.assignmentArea";
const sortLocation = "unitNameCityList";
const sortunitAddressList = "unitAddressList";
const sortunitCityList ="unitCityList";
const activeStatus="Reviewed";
const departmentDropdownDefaultValue = "Select Department";
const departmentFilterDropdownDefaultValue = "All";

class ExecuteFollowUpAssignment extends Component {
    constructor(props) {
        super(props);
		this.state = {
			componentDidMountFlag: false,
            apiSearchKey: "",
            searchkey: "",
            currentPageNo: 1,
			showLoader: false,
			resultSize: Constants.RESULT_SIZE,
			totalCount: 0,
			datalist: [],
            resultSizePlaceholder: Constants.RESULT_SIZE,
            resultSizeArr: Constants.RESULT_SIZE_DD_ARR,
            sort: "",
			sortDir: "",
            status:activeStatus,
			company:{},
            observationFilterDropDownArr: [
                {
                    label:"Observation",
                    isSelected:true
                },
                {
                    label:"Task",
                    isSelected:false
                }
            ],
            taskFilterDropDownArr: [
                {
                    label:"Observation",
                    isSelected:false
                },
                {
                    label:"Task",
                    isSelected:true
                }
            ],
            observationFilterPlaceholder:"Observation",
            taskFilterPlaceholder:"task",
            showAlertDialog:false,
            showAlertDialogInfo:false,
            showBtnLoader:false,
            alertDialogHeading:"",
            alertDialogMessage:"",
            alertDialogMessageInfo:"",
            deleteReactivateId:"",
            showErrorDialog:"",
            alertDialogErrorMessage:""
        }
    }

    componentDidMount() {
		this.getInitData();
	}


    onSearchIconClick = () => {
		this.setState({
            currentPageNo: 1,
        },() => {
            this.getAssignmentList();
        });
	};

    onEnterBtnPress = (e) => {
		var code = e.keyCode || e.which;
		if (code === 13) {
            this.setState({
                currentPageNo: 1,
            },() => {
                this.getAssignmentList();
            });
        }
	};

    resultSizeDropdownClick = (item, selectedIndex) => {
		let tempArr = this.state.resultSizeArr;
		let placeholder = "";

		for (const [i, value] of tempArr.entries()) {
			if (i === selectedIndex) {
				tempArr[i].isSelected = true;
				placeholder = value.label ;
			} else {
				tempArr[i].isSelected = false;
			}
		}

		this.setState({
            resultSizeArr: tempArr,
			resultSize: item.label,
			resultSizePlaceholder: placeholder,
        },() => {
            this.getAssignmentList();
        });
	};

    deptFilterDropdownClick = (item, selectedIndex) => {
		let placeholder = "";
		let id = "";
		let filterDeptArr = this.state.filterDeptArr;

		for (const [i, value] of filterDeptArr.entries()) {
			if (i === selectedIndex) {
				filterDeptArr[i].isSelected = true;
				placeholder = value.name;
				id = value.departmentId;
			} else {
				filterDeptArr[i].isSelected = false;
			}
		}

		this.setState(
			{
				currentPageNo: 1,
				selectedFilterDepartmentName: placeholder,
				selectedFilterDepartmentId: id,
			},
			() => {
				this.getAssignmentList();
			}
		);
	};

    sortTableLocal = (sortColumn) => {
		let sortObj = sortTable(sortColumn, this.state.sort, this.state.sortDir);

		this.setState({
            sort: sortObj.sortTemp,
			sortDir: sortObj.sortDirTemp,
        },() => {
            this.getAssignmentList();
        });
    };

    handleExecuteTaskList = (item) => {
        if(isEmptyVariable(item.observationTemplateId)){
            this.props.history.push(Constants.URL_FOLLOWUPTASK + "/" + item.assignmentId,{
                departmentId:item.departmentId,
                observationTemplateId:item.observationTemplateId,
                observationTaskFilterDropDownArr:this.state.taskFilterDropDownArr,
                observationTaskFilterPlaceholder:this.state.observationFilterPlaceholder,
            });
        }else{
            this.props.history.push(Constants.URL_FOLLOWUPOBSERVATION + "/" + item.assignmentId,{
                departmentId:item.departmentId,
                assignmentArea:item.assignmentArea,
                observationTemplateId:item.observationTemplateId,
                observationTaskFilterDropDownArr:this.state.observationFilterDropDownArr,
                observationTaskFilterPlaceholder:this.state.observationFilterPlaceholder,
            });
        }
	};

    /*******************API CALLS*******************/

	getInitData = () => {
		this.setState({
            showLoader: true,
        });

		Promise.all([
			fetch(Constants.GetExecFollowUp, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
				    accessToken: userDetails.accessToken,
					pageNo: this.state.currentPageNo,
					resultsize: this.state.resultSize,
                    status: this.state.status,
					sort: this.state.sort,
					sortDir: this.state.sortDir,
				}),
			}),
			fetch(Constants.GetUserMenu, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
			    }),
			}),
		])
		.then(([masterRes, menuRes]) => {
			return Promise.all([masterRes.json(), menuRes.json()]);
		})
		.then(([masterRes, menuRes]) => {
			let datalistTemp = [];
			let totalCountTemp = 0;
            let deptTemp = [];
			let menusTemp = {};
			let companyTemp = {};
			if (masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
				localStorage.clear();
				window.location = "/";
			} else if (masterRes.responseCode === Constants.CODE_SUCCESS) {
				datalistTemp = masterRes.data.result;
				totalCountTemp = masterRes.data.count;
			} else {
			}
			if (menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
				localStorage.clear();
				window.location = "/";
			} else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                deptTemp = menuRes.data.departments;
				menusTemp = menuRes.data.menus;
				companyTemp = menuRes.data.company;
			} else {
			}

			let menusTemp2 = GetOrderedMasterTabMenuExecutes(menusTemp);
            
            let selectedDepartmentId = "";
			let selectedDepartmentName = departmentDropdownDefaultValue;
			if (!isEmptyArray(deptTemp) && deptTemp.length === 1) {
				selectedDepartmentId = deptTemp[0].departmentId;
				selectedDepartmentName = deptTemp[0].name;
			}

			//Filter Dept array
			let filterDeptArr = [];
			let selectedFilterDepartmentId = "-100";
			let selectedFilterDepartmentName = "All";
			if (!isEmptyArray(deptTemp) && deptTemp.length === 1) {
			} else {
				let obj = {
					departmentId: "-100",
					name: departmentFilterDropdownDefaultValue,
					isSelected: true,
				};
				filterDeptArr = [...deptTemp];
				filterDeptArr.splice(0, 0, obj);
			}

			this.setState({
				componentDidMountFlag: true,
				showLoader: false,
				datalist: datalistTemp,
				totalCount: totalCountTemp,
				menus: menusTemp2,
				company:companyTemp,

                filterDeptArr: filterDeptArr,
				selectedFilterDepartmentId: selectedFilterDepartmentId,
				selectedFilterDepartmentName: selectedFilterDepartmentName,
			});
		});
	};

    getAssignmentList = () => {
		this.setState({
			showLoader: true,
		});

		let postParam = {
			email: userDetails.email,
			accessToken: userDetails.accessToken,
			pageNo: this.state.currentPageNo,
			search: this.state.searchkey,
			resultsize: this.state.resultSize,
            status: this.state.status,
			sort: this.state.sort,
			sortDir: this.state.sortDir,
		};

        if (this.state.filterDeptArr.length > 1 && this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue) {
			postParam.departmentId = this.state.selectedFilterDepartmentId;
		}

		fetch(Constants.GetFollowUp, {
			method: "POST",
			mode: "cors",
			body: new URLSearchParams(postParam),
		})
		.then((response) => {
			return response.json();
		})
		.then((data) => {
			if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
				localStorage.clear();
				window.location = "/";
			} else if (data.responseCode === Constants.CODE_SUCCESS) {
				this.setState({
					showLoader: false,
					datalist: data.data.result,
					totalCount: data.data.count,
					apiSearchKey: this.state.searchkey,
				});
			} else {
				this.setState({
					datalist: [],
					showLoader: false,
					totalCount: 0,
					componentDidMountFlag: true,
					apiSearchKey: this.state.searchkey,
				});
			}
		});
	};

    onChangePage = (page) => {
		// update state with new page of items
		if (page !== this.state.currentPageNo) {
			this.setState({
				showLoader: true,
			});

			let postParam = {
				email: userDetails.email,
				accessToken: userDetails.accessToken,
				pageNo: page,
				search: this.state.searchkey,
				resultsize: this.state.resultSize,
                status: this.state.status,
				sort: this.state.sort,
				sortDir: this.state.sortDir,
			};

            if (this.state.filterDeptArr.length > 1 && this.state.selectedFilterDepartmentName !== "All") {
				postParam.departmentId = this.state.selectedFilterDepartmentId;
			}

			fetch(Constants.GetFollowUp, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams(postParam),
			})
			.then((response) => {
				return response.json();
			})
			.then((data) => {
				if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
					localStorage.clear();
					window.location = "/";
				} else if (data.responseCode === Constants.CODE_SUCCESS) {
					this.setState({
						showLoader: false,
						datalist: data.data.result,
						totalCount: data.data.count,
						apiSearchKey: this.state.searchkey,
						currentPageNo: page,
					});
				} else {
					this.setState({
						datalist: [],
						showLoader: false,
						apiSearchKey: this.state.searchkey,
					});
				}
			});
		}
	};

    onTableDropDownItemClick = (item,dropdownItem)=>{
        this.setState({
            alertDialogHeading:"",
            proceedBtnLabel:"Freeze",
            deleteReactivateId:dropdownItem.assignmentId,
            showAlertDialog:true,
            alertDialogMessage:"The Follow Up will be frozen. You will not be able to edit it.",
        })
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:"",
            alertDialogHeading:"",
        });
    }

    handleFreezeDialog = () => {
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:"",
            alertDialogHeading:"",
            showAlertDialogInfo:true,
            alertDialogMessageInfo:"Are you sure you want to freeze this Follow Up?"
        });
    }

    handleAlertDialog = () => {
        this.setState({
            showErrorDialog:false,
            alertDialogErrorMessage:""
        });
    }

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
    }

    handleRecordOperation = () => {
        let url = "";
        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            assignmentId:this.state.deleteReactivateId
        }
        let callApi = true;

        url = Constants.FreezeFollowUpAssignment

        if(callApi){
            this.setState({
                showBtnLoader:true,
            });

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        deleteReactivateId:"",
                        showAlertDialogInfo:false,
                        alertDialogMessageInfo:"",
                        showBtnLoader:false
                    },() => {
                        this.getAssignmentList();
                    });
                }else{
                    this.setState({
                        showBtnLoader:false,
                        deleteReactivateId:"",
                        showAlertDialogInfo:false,
                        alertDialogMessageInfo:"",
                        showErrorDialog:true,
                        alertDialogErrorMessage:data.responseMessage
                    })
                }
            });
        }
    }

    /*******************API CALLS END HERE*******************/

    render() {
        return (
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                <MasterMenuTabs masterExecuteMenus={this.state.menus} themeSettings={themeSettings}/>
                                {this.state.showLoader && (
                                    <div class="loader"></div>
                                )}
                                {
                                    // Need to check component did mount flag, other wise until api is called,
									// it shows no items found layout.
                                    this.state.componentDidMountFlag && (
                                        <div className="body-wrapper">
                                            <div className="search-addnew-bg">
                                                <div className="search-addnew">
                                                    <div className="search-filter-layout">
                                                        <FilterDropDown
                                                            placeholder={this.state.resultSizePlaceholder}
                                                            dropdownArr={this.state.resultSizeArr}
                                                            onDropDownItemClick={this.resultSizeDropdownClick}
                                                            themeSettings={themeSettings}
                                                            mleft={0}
														/>
                                                        <div className="search-col m-l-10">
                                                            <input
                                                                name="searchkey"
                                                                type="text"
                                                                placeholder="Search"
                                                                onKeyPress={this.onEnterBtnPress}
                                                                value={this.state.searchkey}
                                                            />
                                                            <SearchSpan themeSettings={themeSettings} onClick={this.onSearchIconClick} className="material-icons">
                                                                {" "}
                                                                search{" "}
                                                            </SearchSpan>
														</div>
                                                        {this.state.filterDeptArr.length > 0 && (
                                                            <FilterDropDown
                                                                placeholder={this.state.selectedFilterDepartmentName}
                                                                dropdownArr={this.state.filterDeptArr}
                                                                onDropDownItemClick={this.deptFilterDropdownClick}
                                                                name={"name"}
                                                                themeSettings={themeSettings}
                                                                dropdownWidth={"120px"}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {!isEmptyArray(this.state.datalist) && (
                                                <div className="common-tab-bg">
                                                    <div className="common-table" style={{ width: "100%" }}>
                                                        <table className="table">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-center" width={"5%"}>No.</th>
                                                                    <th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortReferenceNo)}>
                                                                        <div className="sort-header">Ref No.
                                                                        <span className={(this.state.sort === sortReferenceNo)?
                                                                            "material-icons":"material-icons hide-sort-arrow"}>
                                                                            {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                        </span>
                                                                        </div>
                                                                    </th>
                                                                    {
                                                                        this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                        <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortLocation)}>
                                                                            <div className="sort-header">Unit
                                                                                <span className={(this.state.sort === sortLocation)?
                                                                                    "material-icons":"material-icons hide-sort-arrow"}>
                                                                                    {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                    }
                                                                    {
                                                                        this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                        <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortunitAddressList)}>
                                                                            <div className="sort-header">Name
                                                                                <span className={(this.state.sort === sortunitAddressList)?
                                                                                    "material-icons":"material-icons hide-sort-arrow"}>
                                                                                    {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                    }
                                                                    {
                                                                        this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                        <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortunitCityList)}>
                                                                            <div className="sort-header">City
                                                                                <span className={(this.state.sort === sortunitCityList)?
                                                                                    "material-icons":"material-icons hide-sort-arrow"}>
                                                                                    {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                    }
                                                                    {
                                                                        this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                        <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortName)}>
                                                                            <div className="sort-header">Client
                                                                                <span className={(this.state.sort === sortName)?
                                                                                    "material-icons":"material-icons hide-sort-arrow"}>
                                                                                    {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                    }
                                                                    <th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortDescription)}>
                                                                        <div className="sort-header">Type
                                                                            <span className={(this.state.sort === sortDescription)?
                                                                                "material-icons":"material-icons hide-sort-arrow"}>
                                                                                {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                            </span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortArea)}>
                                                                        <div className="sort-header">Scope
                                                                            <span className={(this.state.sort === sortArea)?
                                                                                "material-icons":"material-icons hide-sort-arrow"}>
                                                                                {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                            </span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="c-pointer" onClick={this.sortTableLocal.bind(this, sortReportDate)}>
                                                                        <div className="sort-header">Report Date
                                                                            <span className={(this.state.sort === sortReportDate)?
                                                                                "material-icons":"material-icons hide-sort-arrow"}>
                                                                                {this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}
                                                                            </span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="text-center" width={"5%"}>
                                                                        <div className="sort-header"></div>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    !isEmptyArray(this.state.datalist) &&
                                                                    this.state.datalist.map((item, idx) => {
                                                                    let  actionArr = [];

                                                                    if(item.finalizeFollowUp=="Y" && item.status === activeStatus){
                                                                        actionArr.push({icon: "gpp_good",label: "Freeze Follow Up"})
                                                                    }
                                                                    let tempScop = [];
                                                                    if(!isEmptyVariable(item.functionList)){
                                                                        tempScop = item.functionList?.split(",").map((func) => func.split(":-:")[0]);
                                                                    }else if(!isEmptyVariable(item.processList)){
                                                                        tempScop = item.processList.split(",").map((func) => func.split(":-:")[0]);
                                                                    }else if(!isEmptyVariable(item.checkList)){
                                                                        tempScop = item.checkList.split(",").map((func) => func.split(":-:")[0]);
                                                                    }    
                                                                        return (
                                                                            <tr onClick={this.handleExecuteTaskList.bind(this, item)}>
                                                                                <td className="text-center">{(this.state.currentPageNo - 1) * this.state.resultSize + ++idx}</td>
                                                                                <td>{item.referenceNo}</td>
                                                                                {
                                                                                    this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                                    <td>
                                                                                        {truncateString(String(getStringWithSpaceAfterComma(item.unitNameCityList)).split("(")[0],100)}
                                                                                    </td>
																				}
                                                                                {
                                                                                    this.state.company?.companyType !== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                                                    <td>{item.companyName}</td>
                                                                                }
                                                                                {   
                                                                                    this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF && 
                                                                                    <td>
                                                                                        {isEmptyVariable(item.unitAddressList)? "-": String(item.unitAddressList).split(":-:")[0]}
                                                                                    </td>
                                                                                }
                                                                                {   
                                                                                    this.state.company?.companyType === Constants.USER_ENGAGEMENT_TYPE_SELF && 
                                                                                    <td>{isEmptyVariable(item.unitCityList)? "-": item.unitCityList}</td>
                                                                                }
                                                                                <td>{item.assignmentTypeName}</td>
                                                                                <td>{item.assignmentArea == "CheckList" ?
                                                                                    isEmptyArray(tempScop)?"-":(`CheckList: ` + tempScop[0]) :
                                                                                    isEmptyArray(tempScop)?"-":tempScop.length==1?tempScop[0]:`${tempScop[0]} + ${tempScop.length-1}`}
                                                                                </td>
                                                                                <td>{isEmptyVariable(item.reportDate)?"": moment(item.reportDate).format("DD/MM/YYYY")}</td>
                                                                                <td className="text-center">
                                                                                    {
                                                                                        actionArr.length > 0 && (
                                                                                        <TableDropDown
                                                                                            actionArr={actionArr}
                                                                                            onDropDownItemClick={this.onTableDropDownItemClick}
                                                                                            dropDownId={item}
                                                                                            dropdownWidth={'160px'}
                                                                                            themeSettings={themeSettings}
                                                                                        />
                                                                                    )}
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            )}
                                            {this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
												<div class="no-items-layout">
													<div class="no-items-card">
														<h6>
															{isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}
														</h6>
													</div>
												</div>
											)}
											<div className="pagination-layout">
												<div className="row custom-row">
													<div className="col-md-9 custom-col">
														{this.state.totalCount > this.state.datalist.length && (
															<Pagination
																totalLength={this.state.totalCount}
																items={this.state.datalist}
																onChangePage={this.onChangePage}
																pageSize={this.state.resultSize}
																currentPageNo={this.state.currentPageNo}
																initialPage={this.state.currentPageNo}
																themeSettings={themeSettings}
															/>
														)}
													</div>
													<div className="col-md-3 custom-col">
														<p>{"Total Records: " + this.state.totalCount}</p>
													</div>
												</div>
											</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>

                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={Constants.ALERT_TYPE_INFO}
                            alertDialogHeading={this.state.alertDialogHeading}
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.handleFreezeDialog}
                            proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                            themeSettings={themeSettings}
                        />
                        <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_INFO}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleRecordOperation}
                            proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                            showLoader={this.state.showBtnLoader}
                            themeSettings={themeSettings}
                        />
                        <AlertDialog 
                            showAlertDialog={this.state.showErrorDialog}
                            handleAlertDialogClose={this.handleAlertDialog}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogErrorMessage}
                            proceedBtnClick={this.handleAlertDialog}
                            proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                            themeSettings={themeSettings}
                        />
                    </section>
                </main>
            </Router>
        )
    }
}
export default ExecuteFollowUpAssignment;