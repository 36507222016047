import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import * as Constants from "../../common/Global/constants";
import FilterDropDown from "../../common/DropdownMenus/FilterDropdown";
import {
  SearchSpan,
  FilterCheckBox,
  DialogSaveButton,
} from "../../common/Global/globalStyles.style";
import Pagination from "../../common/Global/pagination";
import {
  getLocalStorageVariables,
  getThemeSettingsVariables,
  GetOrderedMasterTabMenuReports,
  isEmptyVariable,
  sortTable,
  truncateString,
  isEmptyArray,
  getStringWithSpaceAfterComma,
} from "../../common/Global/commonFunctions";
import moment from "moment";
const sortName = "companyName";
const sortReferenceNo = "referenceNo";
const sortDescription = "a.assignmentTypeName";
const sortArea = "a.assignmentArea";
const sortFinalizeDate = "finalizeDate";
const sortLocation = "unitCityList";

const activeStatus = "Active";
const inActiveStatus = "Inactive";
const underReviewStatus = "Under Review";

const userDetails = getLocalStorageVariables();

const masterTypeDefaultPlaceholder = "Select Master Type";
const masterTypeFilterDefaultPlaceholder = "All Master Types";
const departmentDropdownDefaultValue = "Select Department";
const departmentFilterDropdownDefaultValue = "All";

export default class DisplayCompletedReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentDidMountFlag: false,
      apiSearchKey: "",
      searchkey: "",
      currentPageNo: 1,
      showLoader: false,
      resultSize: Constants.RESULT_SIZE,
      totalCount: 0,
      datalist: [],
      resultSizePlaceholder: Constants.RESULT_SIZE,
      sort: "",
      sortDir: "",
      company: {},
      masterTypeArr: [],
      masterTypeSelected: "All",
      masterFilterPlaceholder: masterTypeFilterDefaultPlaceholder,
      resultSizePlaceholder: Constants.RESULT_SIZE,
      resultSizeArr: JSON.parse(JSON.stringify(Constants.RESULT_SIZE_DD_ARR)),
      statusPlaceholder: activeStatus,
      status: activeStatus,
      statusArr: [
        {
          label: activeStatus,
          isSelected: true,
        },
        {
          label: underReviewStatus,
          isSelected: false,
        },
        {
          label: inActiveStatus,
          isSelected: false,
        },
      ],
      selectedAssignmentList: [],
    };
  }
  componentDidMount() {
    this.getInitData();
  }

  getInitData = () => {
    this.setState({
      showLoader: true,
      // showDetailsDialog: false,
      // selectedItem: "",
    });

    Promise.all([
      fetch(Constants.GetCompletedReports, {
        method: "POST",
        mode: "cors",
        body: new URLSearchParams({
          email: userDetails.email,
          accessToken: userDetails.accessToken,
          pageNo: this.state.currentPageNo,
          resultsize: this.state.resultSize,
          // status: this.state.status,
          sort: this.state.sort,
          sortDir: this.state.sortDir,
        }),
      }),
      fetch(Constants.GetUserMenu, {
        method: "POST",
        mode: "cors",
        body: new URLSearchParams({
          email: userDetails.email,
          accessToken: userDetails.accessToken,
        }),
      }),
    ])
      .then(([generalRes, menuRes]) => {
        return Promise.all([generalRes.json(), menuRes.json()]);
      })
      .then(([generalRes, menuRes]) => {
        let datalistTemp = [];
        let totalCountTemp = 0;
        let deptTemp = [];
        let menusTemp = {};
        let companyTemp = {};

        if (
          generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
          generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
        ) {
          localStorage.clear();
          window.location = "/";
        } else if (generalRes.responseCode === Constants.CODE_SUCCESS) {
          datalistTemp = generalRes.data.result;
          totalCountTemp = generalRes.data.count;
        } else {
        }
        if (
          menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
          menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
        ) {
          localStorage.clear();
          window.location = "/";
        } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
          deptTemp = menuRes.data.departments;
          menusTemp = menuRes.data.menus;
          companyTemp = menuRes.data.company;
        } else {
        }

        let menusTemp2 = GetOrderedMasterTabMenuReports(menusTemp);

        //Filter Dept array
        let filterDeptArr = [];
        let selectedFilterDepartmentId = "-100";
        let selectedFilterDepartmentName = "All";
        if (!isEmptyArray(deptTemp) && deptTemp.length === 1) {
        } else {
          let obj = {
            departmentId: "-100",
            name: departmentFilterDropdownDefaultValue,
            isSelected: true,
          };
          filterDeptArr = [...deptTemp];
          filterDeptArr.splice(0, 0, obj);
        }

        this.setState({
          componentDidMountFlag: true,
          showLoader: false,
          datalist: datalistTemp,
          totalCount: totalCountTemp,
          menus: menusTemp2,
          company: companyTemp,

          filterDeptArr: filterDeptArr,
          selectedFilterDepartmentId: selectedFilterDepartmentId,
          selectedFilterDepartmentName: selectedFilterDepartmentName,
        });
      });
  };

  getAssignmentList = () => {
    this.setState({
      showLoader: true,
    });

    let postParam = {
      email: userDetails.email,
      accessToken: userDetails.accessToken,
      pageNo: this.state.currentPageNo,
      search: this.state.searchkey,
      resultsize: this.state.resultSize,
      // status: this.state.status,
      sort: this.state.sort,
      sortDir: this.state.sortDir,
    };

    if (
      this.state.filterDeptArr.length > 1 &&
      this.state.selectedFilterDepartmentName !==
        departmentFilterDropdownDefaultValue
    ) {
      postParam.departmentId = this.state.selectedFilterDepartmentId;
    }

    fetch(Constants.GetCompletedReports, {
      method: "POST",
      mode: "cors",
      body: new URLSearchParams(postParam),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        if (
          data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
          data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
        ) {
          localStorage.clear();
          window.location = "/";
        } else if (data.responseCode === Constants.CODE_SUCCESS) {
          this.setState({
            showLoader: false,
            datalist: data.data.result,
            totalCount: data.data.count,
            apiSearchKey: this.state.searchkey,
          });
        } else {
          this.setState({
            datalist: [],
            showLoader: false,
            totalCount: 0,
            componentDidMountFlag: true,
            apiSearchKey: this.state.searchkey,
          });
        }
      });
  };

  resultSizeDropdownClick = (item, selectedIndex) => {
    let tempArr = this.state.resultSizeArr;
    let placeholder = "";

    for (const [i, value] of tempArr.entries()) {
      if (i === selectedIndex) {
        tempArr[i].isSelected = true;
        placeholder = value.label;
      } else {
        tempArr[i].isSelected = false;
      }
    }

    this.setState(
      {
        resultSizeArr: tempArr,
        resultSize: item.label,
        resultSizePlaceholder: placeholder,
      },
      () => {
        // this.getIndustryList();
        this.getInitData();
      }
    );
  };

  onChangePage = (page) => {
    // update state with new page of items
    if (page !== this.state.currentPageNo) {
      this.setState({
        showLoader: true,
      });

      let postParam = {
        email: userDetails.email,
        accessToken: userDetails.accessToken,
        pageNo: page,
        search: this.state.searchkey,
        resultsize: this.state.resultSize,
        // status:this.state.status,
        sort: this.state.sort,
        sortDir: this.state.sortDir,
      };

      if (
        this.state.filterDeptArr.length > 1 &&
        this.state.selectedFilterDepartmentName !== "All"
      ) {
        postParam.departmentId = this.state.selectedFilterDepartmentId;
      }

      fetch(Constants.GetCompletedReports, {
        method: "POST",
        mode: "cors",
        body: new URLSearchParams(postParam),
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (
            data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
            data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
          ) {
            localStorage.clear();
            window.location = "/";
          } else if (data.responseCode === Constants.CODE_SUCCESS) {
            this.setState({
              showLoader: false,
              datalist: data.data.result,
              totalCount: data.data.count,
              apiSearchKey: this.state.searchkey,
              currentPageNo: page,
            });
          } else {
            this.setState({
              datalist: [],
              showLoader: false,
              apiSearchKey: this.state.searchkey,
            });
          }
        });
    }
  };
  statusDropdownClick = (item, selectedIndex) => {
    let tempArr = this.state.statusArr;
    let placeholder = "";

    for (const [i, value] of tempArr.entries()) {
      if (i === selectedIndex) {
        tempArr[i].isSelected = true;
        placeholder = value.label;
      } else {
        tempArr[i].isSelected = false;
      }
    }

    this.setState(
      {
        currentPageNo: 1,
        statusArr: tempArr,
        status: item.label,
        statusPlaceholder: placeholder,
      },
      () => {
        this.getTasksForAllProcess(true);
      }
    );
  };

  sortTableLocal = (sortColumn) => {
    let sortObj = sortTable(sortColumn, this.state.sort, this.state.sortDir);

    this.setState(
      {
        sort: sortObj.sortTemp,
        sortDir: sortObj.sortDirTemp,
      },
      () => {
        this.getAssignmentList();
      }
    );
  };
  handleChangeSearch = (e) => {
    const { name, value } = e.target;

    this.setState({
      [name]: value,
    });
  };

  onSearchIconClick = () => {
    this.setState(
      {
        currentPageNo: 1,
      },
      () => {
        this.getAssignmentList();
      }
    );
  };

  onEnterBtnPress = (e) => {
    var code = e.keyCode || e.which;
    if (code === 13) {
      this.setState(
        {
          currentPageNo: 1,
        },
        () => {
          this.getAssignmentList();
        }
      );
    }
  };
  handleSaveClick = () =>{
    console.log("Selected assignment::",this.state.selectedAssignmentList)
    this.props.closeTable()
  }

  handleCheck = (id, e) => {
    let selectedAssignment = this.state.selectedAssignmentList;
    if (e.target.checked) {
      selectedAssignment.push(id);
    } else if (!e.target.checked) {
      const tempid = selectedAssignment.indexOf(id);
      selectedAssignment.splice(tempid, 1);
    }
    this.setState({
      selectedAssignmentList: selectedAssignment,
    });
  };
  render() {
    return (
      <section>
        <Modal
          className="task-select-table-dialog custom-dialog"
          show={this.props.isDisplayTable}
          backdrop="static"
        >
          <Modal.Header>
            <h5>Completed Report</h5>
            <button
              onClick={this.props.closeTable}
              type="button"
              data-dismiss="modal"
            >
              <span class="material-icons">close</span>
            </button>
          </Modal.Header>
          <hr />
          <div className="modal-body">
            {this.state.showLoader && (
              <div class="loader"></div>
            )}
            {this.state.componentDidMountFlag && (
              <div className="body-wrapper">
                <div>
                  <div className="search-addnew-bg">
                    <div className="search-addnew">
                      <div className="search-filter-layout">
                        <FilterDropDown
                          placeholder={this.state.resultSizePlaceholder}
                          dropdownArr={this.state.resultSizeArr}
                          onDropDownItemClick={this.resultSizeDropdownClick}
                          themeSettings={this.props.themeSettings}
                          mleft={0}
                        />
                        <div className="search-col m-l-10">
                          <input
                            name="searchkey"
                            type="text"
                            onChange={this.handleChangeSearch}
                            placeholder="Search"
                            onKeyPress={this.onEnterBtnPress}
                            value={this.state.searchkey}
                          />
                          <SearchSpan
                            themeSettings={this.props.themeSettings}
                            onClick={this.onSearchIconClick}
                            className="material-icons"
                          >
                            {" "}
                            search{" "}
                          </SearchSpan>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="common-tab-bg">
                    <div
                      className="common-table"
                      style={{ width: "100%", borderRadius: 4 }}
                    >
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-center" width={"5%"}></th>

                            <th className="text-center" width={"5%"}>
                              No.
                            </th>
                            <th
                              className="c-pointer"
                              onClick={this.sortTableLocal.bind(
                                this,
                                sortReferenceNo
                              )}
                            >
                              <div className="sort-header">
                                Ref No.
                                <span
                                  className={
                                    this.state.sort === sortReferenceNo
                                      ? "material-icons"
                                      : "material-icons hide-sort-arrow"
                                  }
                                >
                                  {this.state.sortDir === "asc"
                                    ? "arrow_upward"
                                    : "arrow_downward"}
                                </span>
                              </div>
                            </th>
                            {this.state.company?.companyType ===
                              Constants.USER_ENGAGEMENT_TYPE_SELF && (
                              <th
                                className="c-pointer"
                                onClick={this.sortTableLocal.bind(
                                  this,
                                  sortLocation
                                )}
                              >
                                <div className="sort-header">
                                  Unit
                                  <span
                                    className={
                                      this.state.sort === sortLocation
                                        ? "material-icons"
                                        : "material-icons hide-sort-arrow"
                                    }
                                  >
                                    {this.state.sortDir === "asc"
                                      ? "arrow_upward"
                                      : "arrow_downward"}
                                  </span>
                                </div>
                              </th>
                            )}
                            {this.state.company?.companyType !==
                              Constants.USER_ENGAGEMENT_TYPE_SELF && (
                              <th
                                className="c-pointer"
                                onClick={this.sortTableLocal.bind(
                                  this,
                                  sortName
                                )}
                              >
                                <div className="sort-header">
                                  Client
                                  <span
                                    className={
                                      this.state.sort === sortName
                                        ? "material-icons"
                                        : "material-icons hide-sort-arrow"
                                    }
                                  >
                                    {this.state.sortDir === "asc"
                                      ? "arrow_upward"
                                      : "arrow_downward"}
                                  </span>
                                </div>
                              </th>
                            )}
                            <th
                              className="c-pointer"
                              onClick={this.sortTableLocal.bind(
                                this,
                                sortDescription
                              )}
                            >
                              <div className="sort-header">
                                Type
                                <span
                                  className={
                                    this.state.sort === sortDescription
                                      ? "material-icons"
                                      : "material-icons hide-sort-arrow"
                                  }
                                >
                                  {this.state.sortDir === "asc"
                                    ? "arrow_upward"
                                    : "arrow_downward"}
                                </span>
                              </div>
                            </th>
                            <th
                              className="c-pointer"
                              onClick={this.sortTableLocal.bind(
                                this,
                                sortLocation
                              )}
                            >
                              <div className="sort-header">
                                Location
                                <span
                                  className={
                                    this.state.sort === sortLocation
                                      ? "material-icons"
                                      : "material-icons hide-sort-arrow"
                                  }
                                >
                                  {this.state.sortDir === "asc"
                                    ? "arrow_upward"
                                    : "arrow_downward"}
                                </span>
                              </div>
                            </th>
                            <th
                              className="c-pointer"
                              onClick={this.sortTableLocal.bind(this, sortArea)}
                            >
                              <div className="sort-header">
                                Scope
                                <span
                                  className={
                                    this.state.sort === sortArea
                                      ? "material-icons"
                                      : "material-icons hide-sort-arrow"
                                  }
                                >
                                  {this.state.sortDir === "asc"
                                    ? "arrow_upward"
                                    : "arrow_downward"}
                                </span>
                              </div>
                            </th>
                            <th
                              className="c-pointer"
                              onClick={this.sortTableLocal.bind(
                                this,
                                sortFinalizeDate
                              )}
                            >
                              <div className="sort-header">
                                Finalize Date
                                <span
                                  className={
                                    this.state.sort === sortFinalizeDate
                                      ? "material-icons"
                                      : "material-icons hide-sort-arrow"
                                  }
                                >
                                  {this.state.sortDir === "asc"
                                    ? "arrow_upward"
                                    : "arrow_downward"}
                                </span>
                              </div>
                            </th>
                            <th className="text-center" width={"5%"}></th>
                          </tr>
                        </thead>
                        <tbody>
                          {!isEmptyArray(this.state.datalist) &&
                            this.state.datalist.map((item, idx) => {
                              return (
                                <tr>
                                  <td className="text-center">
                                    <FilterCheckBox
                                      themeSettings={this.props.themeSettings}
                                      style={{ marginLeft: 0 }}
                                    >
                                      <input
                                        type="checkbox"
                                        name="assignment"
                                        id="assignment"
                                        onChange={this.handleCheck.bind(
                                          this,
                                          item.assignmentId
                                        )}
                                        checked={Object.values(this.state.selectedAssignmentList).includes(item.assignmentId)? true : false}
                                      />
                                      <label for="assignment"></label>
                                    </FilterCheckBox>
                                  </td>
                                  <td className="text-center">
                                    {(this.state.currentPageNo - 1) *
                                      this.state.resultSize +
                                      ++idx}
                                  </td>
                                  <td>{item.referenceNo}</td>
                                  {this.state.company?.companyType ===
                                    Constants.USER_ENGAGEMENT_TYPE_SELF && (
                                    <td>
                                      {truncateString(
                                        getStringWithSpaceAfterComma(
                                          item.unitNameCityList
                                        ),
                                        100
                                      )}
                                    </td>
                                  )}
                                  {this.state.company?.companyType !==
                                    Constants.USER_ENGAGEMENT_TYPE_SELF && (
                                    <td>{item.companyName}</td>
                                  )}
                                  <td>{item.assignmentTypeName}</td>
                                  <td>{item.unitCityList}</td>
                                  <td>{item.assignmentArea}</td>
                                  <td>
                                    {moment(item.finalizeDate).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </td>

                                  <td className="text-center"></td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {this.state.componentDidMountFlag &&
                    isEmptyArray(this.state.datalist) && (
                      <div class="no-items-layout">
                        <div class="no-items-card">
                          <h6>
                            {isEmptyVariable(this.state.apiSearchKey)
                              ? Constants.NO_RECORDS_WARNING
                              : Constants.EMPTY_SEARCH_WARNING}
                          </h6>
                        </div>
                      </div>
                    )}
                  <div className="pagination-layout">
                    <div className="row custom-row">
                      <div className="col-md-9 custom-col">
                        {this.state.totalCount > this.state.datalist.length && (
                          <Pagination
                            totalLength={this.state.totalCount}
                            items={this.state.datalist}
                            onChangePage={this.onChangePage}
                            pageSize={this.state.resultSize}
                            currentPageNo={this.state.currentPageNo}
                            initialPage={this.state.currentPageNo}
                            themeSettings={this.props.themeSettings}
                          />
                        )}
                      </div>
                      <div className="col-md-3 custom-col">
                        <p>{"Total Records: " + this.state.totalCount}</p>
                      </div>
                    </div>
                   
                  </div>
                </div>
              </div>
            )}
        
            <div class="modal-close-save">
              <div class="close-save-col">
                <button
                  onClick={this.props.closeTable}
                  type="button"
                  class="modal-close"
                >
                  Cancel
                </button>
                <DialogSaveButton
                  themeSettings={this.props.themeSettings}
                  onClick={this.handleSaveClick}
                  type="button"
                >
                  Save
                </DialogSaveButton>
              </div>
            </div>
      
          </div>
        </Modal>
      </section>
    );
  }
}
