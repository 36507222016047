import { saveAs } from "file-saver";
import { getFirstPageContent } from "./firstPageContent";
import { getIndexPageContent } from "./indexPageContent";
import { getgeneralInformationPageContent } from "./generalInformationPageContent";
import { getInternalAuditPlanPageContent } from "./internalAuditPlanPageContent";
import { getAuditFindingPlanPageContent } from "./auditFindingPlanPageContent";
import { getNonconformityReportPageContent } from "./nonconformityReportPageContent";
import { Document, Packer, PageOrientation, BorderStyle,Table,TextRun,ImageRun,Paragraph, TableRow, TableCell } from "docx";
import { isEmptyArray, isEmptyVariable,getLocalStorageVariables, } from "../../../common/Global/commonFunctions";

import * as Constants from "../../../common/Global/constants";
import { getAuditConclusionPageContent } from "./auditConclusionPageContent";
import { getInternalAuditSummaryReportPageContent } from "./internalAuditSummaryPageContent";
import { getObservationCompilanceContnet } from "./observationCompilanceContnet";
let commonPageBorder = {
  pageBorderBottom: { style: BorderStyle.SINGLE, size: 3, color: "auto", },
  pageBorderLeft: { style: BorderStyle.SINGLE, size: 3, color: "auto", },
  pageBorderRight: { style: BorderStyle.SINGLE, size: 3, color: "auto", },
  pageBorderTop: { style: BorderStyle.SINGLE, size: 3, color: "auto", },
};

let tempqmsRefrenceId=9 
let temprequirementId=1 
let tempclausesRefrenceId=8 

let reportName = "afcons";

export const createDownloadReport =  async(
obj
) => {
  // let sectionTemp = [];
  let tempFunctionNameList=[]
  let finalEmployeeList=[]
  let imgblob = generateBlobFromUrl(obj.companyDetials.companyLogo);

  // Object.values(obj.unitDetials).map((item)=>{
  //   Object.values(item.employees).map((emp)=>{
  //     let tempFuncList = emp.functionList
  //     tempFuncList.map((func)=>{
  //       if(!tempFunctionNameList.includes(func)) {
  //         tempFunctionNameList.push(func)
  //       }
  //     })
  //   })
  // })

  tempFunctionNameList = obj.dataList.map(data=>({
    functionName:data.functionName,
    functionId:data.functionId
  }))


  // tempFunctionNameList = tempFunctionNameList.filter(item => {
  //   return obj.dataList.some(data => item.includes(data.functionName))
  // })

  tempFunctionNameList.map((item)=>{
  
    Object.values(obj.unitDetials).map((unit)=>{
      Object.values(unit.employees).map((emp)=>{
        let tempFuncList = emp.functionList
      tempFuncList.map((func)=>{
        
        if(item.functionId==func.functionId) {
          
          let salutation = !isEmptyVariable(emp.salutation)?emp.salutation:""
          let firstName =  !isEmptyVariable(emp.firstName)?emp.firstName:""
          let lastName =  !isEmptyVariable(emp.lastName)?emp.lastName:""

          finalEmployeeList.push({
            employeeName: " "+salutation+" "+firstName+" "+lastName,
            functionName: func.functionName,
            functionId:func.functionId
          })
        }
      })
      })
    })
  })



  
 if(obj.downloadPlaceholder==="Full Report"){
  let sectionTemp = [];
  
  const firstPageContent =  getFirstPageContent(obj.assignmentDetails,imgblob,obj.companyDetials.companyLogo,obj.allAuditorList);
  const indexPageContent = getIndexPageContent(obj.dataList,imgblob,obj.companyDetials.companyLogo);
  let generalInformationPageContent = getgeneralInformationPageContent(
    obj.assignmentDetails, obj.unitDetials, imgblob,obj.companyDetials.companyLogo,finalEmployeeList,tempFunctionNameList,obj.allAuditorList,obj.allviwerList
  );


  let internalAuditPlanPageContent = getInternalAuditPlanPageContent(
    obj.assignmentDetails, obj.dataList, imgblob,obj.companyDetials.companyLogo,obj.allAuditorList,obj.allviwerList,obj.assignmentTimeline
  );
  let auditConclusionPageContent = getAuditConclusionPageContent(
    obj.assignmentDetails, obj.processGroupDataList, obj.executionTemplateValuesMap, imgblob,obj.companyDetials.companyLogo,obj.allAuditorList
  );

  let internalAuditSummaryReportContent = getInternalAuditSummaryReportPageContent(
      obj.assignmentDetails, obj.dataList, obj.taskTamplateValueMap, obj.executionTemplateValuesMap, obj.unitDetials, imgblob,obj.companyDetials.companyLogo
    );
  let observationCompilanceContent = getObservationCompilanceContnet(obj.assignmentDetails,imgblob,obj.companyDetials.companyLogo);
  
  /// First page content
  sectionTemp.push({
    properties: {
      page: { borders: commonPageBorder, margin:getMargins(500) , },
    },
    children: firstPageContent,
  });

  /// Index page content
  sectionTemp.push({
    properties: {
      page: { borders: commonPageBorder,margin:getMargins(500) },
      
    },
    children: indexPageContent,
  });

  /// General information page content
  sectionTemp.push({
    properties: {
      page: { borders: commonPageBorder,margin:getMargins(500) },
      
    },
    children: generalInformationPageContent,
  });


  if(!obj.assignmentDetails.unitTypeList.includes("HO")){

  /// Internal Audit Plan page Content
  sectionTemp.push({
    properties: {
      page: {
        borders: commonPageBorder,
        margin:getMargins(500),
        size: {
          orientation: PageOrientation.LANDSCAPE,
        },
      },
    },
    children: internalAuditPlanPageContent,
  });

}

  /// Audit Conclusion Page Content
  sectionTemp.push({
    properties: {
      page: {
        borders: commonPageBorder,
        margin:getMargins(500)
      },
    },
    children: auditConclusionPageContent,
  });



  /// internal audit summary report page content
  // sectionTemp.push({
  //   properties: {
  //     page: { borders: commonPageBorder,margin:getMargins(500) },
      
  //   },
  //   children: internalAuditSummaryReportContent,
  // });

  let allDescriptionArr =[];

  /// Audit finding Plan Page content & Nonconformity Page content
  Object.values(obj.processGroupDataList).map((data) => {
    let funcDescObj = {}
    let funcDescList =[];
    funcDescObj.functionName = data.functionName;
    funcDescObj.startDate = obj.assignmentTimeline.filter(e=>JSON.parse(e.functionId).includes(data.functionId)).map(e=>e.startDate)[0]||'';
    let tmpAuditorList = [... new Set(data.taskList.map(e=>e.userId))]|| [];

    let allAuditorList = obj.allAuditorList.filter(e=>tmpAuditorList.includes(e.userId)).map(e=>e.userName) || [];
    funcDescObj.allAuditorList = allAuditorList;

   
    funcDescObj.auditeeList = finalEmployeeList.filter(e=>e.functionId==data.functionId).map(e=>e.employeeName)||[];

    if (obj.assignmentDetails.assignmentArea === "Function") {
      let signatureDisplayIndex ="";

      data.processTaskList.map(((e,i)=>{
        if(e.taskList.length>0){
          signatureDisplayIndex=i
        }
      }))

    data.processTaskList.map((process,index) => {

      tmpAuditorList = [... new Set(process.taskList.map(e=>e.userId))]|| [];

      allAuditorList = obj.allAuditorList.filter(e=>tmpAuditorList.includes(e.userId)).map(e=>e.userName) || [];

      if(!isEmptyArray(process.taskList)) {
        let auditFindingPlanContent = getAuditFindingPlanPageContent(
          obj.assignmentDetails, obj.executionTemplateValuesMap, obj.taskTamplateValueMap, process.taskList,
          process.processName, data.functionName,data.functionId, imgblob,obj.companyDetials.companyLogo,obj.selectedTaskTemplateFields,
          obj.execTaskTemplateFields, finalEmployeeList,allAuditorList, index==signatureDisplayIndex?"SY":"SN"
        );

        sectionTemp.push({
          properties: {
            page: {
              borders: commonPageBorder,
              margin:getMargins(500),
              size: {
                orientation: PageOrientation.LANDSCAPE,
              },
            },
          },
          children: auditFindingPlanContent,
        });}
      });
    }

    let ncrCount = 1;
    Object.values(data.taskList).map((task) => {
      let tempAssignmentTaskId = task.assignmentTaskId;
      let tempTaskId = task.taskId;
      let clausesRefrence = [];
      let qmsRefrence = [];
      let requirement = "";
      let description ="";
      
      Object.entries(obj.executionTemplateValuesMap).map(([execKey, execValue]) => {
        if (execKey == tempAssignmentTaskId) {
          Object.values(execValue).map((val) => {

            if(val.fieldId=== 5)
              {
                description=val.value;

                // let tempVal=val.value
                // if(!isEmptyVariable(tempVal))
                // {
                //   const regex = /Description :(.*)Requirement :(.*)/s;
                //   const match = tempVal.match(regex);
                //   description = match[1].trim();
                //   requirement = match[2].trim();
                // }
              }
              if(val.value==="OBS" && !isEmptyVariable(description)){
                funcDescList.push(description);
              }
            if (val.value === "NC") {
              Object.entries(obj.taskTamplateValueMap).map(
                ([taskKey, taskValue]) => {
                  if (taskKey == tempTaskId) {
                    Object.values(taskValue).map((val) => {
                      if (val.fieldId === temprequirementId) {
                        requirement = val.value;
                      } else if (val.fieldId === tempqmsRefrenceId) {
                        qmsRefrence = !isEmptyVariable(val.value)
                          ? JSON.parse(val.value)
                          : [];
                      } else if (val.fieldId === tempclausesRefrenceId) {
                        clausesRefrence = !isEmptyVariable(val.value)
                          ? JSON.parse(val.value)
                          : [];
                      }
                    });
                  }
                }
              );
              
              let nonconformityReportPageContent =
                getNonconformityReportPageContent(
                  obj.assignmentDetails, clausesRefrence, qmsRefrence, requirement, ncrCount,
                  imgblob,obj.companyDetials.companyLogo,description,obj.allAuditorList,data.functionName,data.functionId,finalEmployeeList
                );
              sectionTemp.push({
                properties: {
                  page: {
                    borders: commonPageBorder,
                    margin:getMargins(500)
                  },
                },
                children: nonconformityReportPageContent,
              });
              ncrCount++;
            }
          });
        }
      });


      let tmpAssiAnnextures = obj.allAnnexureList.filter(e=>e.assignmentTaskId==tempAssignmentTaskId) || [];


      let imagContent =[];
      var fileExtensionPattern = /\.(jpg|jpeg|png)$/i;

      tmpAssiAnnextures = tmpAssiAnnextures.filter(e=>fileExtensionPattern.test(e.documentPath))

      if(tmpAssiAnnextures.length>0){

        tmpAssiAnnextures.map(e=>{
            let annexureImg = generateBlobFromUrl(e.documentPath);
            imagContent.push(
              new Paragraph({
                spacing:{
                  after:100,
                },
                children:[
                  new TextRun({
                    text: e.remark,
                    size: 25,
                  }),
                ]
              })
            )

            imagContent.push(
              new Paragraph({
                children:[
                  new ImageRun({
                      data: annexureImg,
                      transformation: {
                        width: 400,
                        height: 400,
                      },
                    }),
                  ]}
                )
            )

          imagContent.push(
            new Paragraph({
              children:[
                new TextRun({
                  break: 1,
                }),
              ]
            })
          )

          
        })

        sectionTemp.push({
          properties: {
            page: {
              borders: commonPageBorder,
              margin:getMargins(500)
            },
          },
          children: imagContent,
        });

        
      }

    });

    funcDescObj.descriptionList = funcDescList

    allDescriptionArr.push(funcDescObj);

  });

  // allDescriptionArr.map(itm=>{

  //   if(itm.descriptionList.length>0){

      // let observationCompilanceContent = getObservationCompilanceContnet(obj.assignmentDetails,imgblob,obj.companyDetials.companyLogo,itm);

  //     /// Observation Compilance report
  //     sectionTemp.push({
  //       properties: {
  //         page: {
  //           borders: commonPageBorder,
  //           margin:getMargins(500)
  //         },
  //       },
  //       children: observationCompilanceContent,
  //     });

  //   }

  // })

  /// Observation Compilance report
  sectionTemp.push({
    properties: {
      page: {
        borders: commonPageBorder,
        margin:getMargins(500)
      },
    },
    children: observationCompilanceContent,
  });

  reportName = String(obj.assignmentDetails.unitAddressList).split(":-:")[0]+" ("+ JSON.stringify(obj.assignmentDetails.unitNameCityList).split("(")[1].replace('"', "").trim()+" - Report" ;

  
const doc = new Document({
  sections: sectionTemp,
});

  Packer.toBlob(doc).then((blob) => {
     
    saveAs(blob, `${reportName}.doc`);
  });

}


else if(obj.downloadPlaceholder==="Requirements")
{
  let sectionTemp = [];
  let imgblob = generateBlobFromUrl(obj.companyDetials.companyLogo);
   /// Audit finding Plan Page content & Nonconformity Page content
   Object.values(obj.processGroupDataList).map((data) => {
    if (obj.assignmentDetails.assignmentArea === "Function") {
      Object.values(data.processTaskList).map((process,index) => {
      if(obj.FilterPlaceholder!=="All"?obj.FilterPlaceholder===process.functionName:true){
        if(!isEmptyArray(process.taskList)) {
          let auditFindingPlanContent = getAuditFindingPlanPageContent(
          obj.assignmentDetails, obj.executionTemplateValuesMap, obj.taskTamplateValueMap, process.taskList,
          process.processName, data.functionName,data.functionId,imgblob,obj.companyDetials.companyLogo,
          obj.selectedTaskTemplateFields, obj.execTaskTemplateFields, finalEmployeeList,obj.allAuditorList,index==data.processTaskList.length-1?"SY":"SN"
        );
        sectionTemp.push({
          properties: {
            page: {
              borders: commonPageBorder,
              margin:getMargins(500),
              size: {
                orientation: PageOrientation.LANDSCAPE,
              },
            },
          },
          children: auditFindingPlanContent,
        });}
      }
      });
    }

    if((obj.FilterPlaceholder!=="All"?obj.FilterPlaceholder===data.functionName:true))
    {
    let ncrCount = 1;
    
    Object.values(data.taskList).map((task) => {

      let tempAssignmentTaskId = task.assignmentTaskId;
      let tempTaskId = task.taskId;
      let clausesRefrence = [];
      let qmsRefrence = [];
      let requirement = "";
      let description ="";
   

      Object.entries(obj.executionTemplateValuesMap).map(([execKey, execValue]) => {
        if (execKey == tempAssignmentTaskId) {
          Object.values(execValue).map((val) => {
            if(val.fieldId=== 5)
            {
              description=val.value
              // let tempVal=val.value
              // if(!isEmptyVariable(tempVal))
              // {
              //   const regex = /Description :(.*)Requirement :(.*)/s;
              //   const match = tempVal.match(regex);
              //   description = match[1].trim();
              //   requirement = match[2].trim();

              // }
             
            }
            
            if (val.value === "NC") {
             
              Object.entries(obj.taskTamplateValueMap).map(
                ([taskKey, taskValue]) => {
                  if (taskKey == tempTaskId) {
                    Object.values(taskValue).map((val) => {
                      if (val.fieldId === temprequirementId) {
                        requirement = val.value;
                      } else if (val.fieldId === tempqmsRefrenceId) {
                        qmsRefrence = !isEmptyVariable(val.value)
                          ? JSON.parse(val.value)
                          : [];
                      } else if (val.fieldId === tempclausesRefrenceId) {
                        clausesRefrence = !isEmptyVariable(val.value)
                          ? JSON.parse(val.value)
                          : [];
                      }
                    });
                  }
                }
              );
              let nonconformityReportPageContent =
                getNonconformityReportPageContent(
                  obj.assignmentDetails, clausesRefrence, qmsRefrence, requirement, ncrCount,
                  imgblob,obj.companyDetials.companyLogo,description,obj.allAuditorList,data.functionName,data.functionId,finalEmployeeList
                );
              sectionTemp.push({
                properties: {
                  page: {
                    borders: commonPageBorder,
                    margin:getMargins(500)
                  },
                },
                children: nonconformityReportPageContent,
              });
              ncrCount++;
            }
          });
        }
      });

    });
  }
  });
  reportName = String(obj.assignmentDetails.unitAddressList).split(":-:")[0]+" ("+ JSON.stringify(obj.assignmentDetails.unitNameCityList).split("(")[1].replace('"', "").trim()+" - "+`${obj.FilterPlaceholder!="All"?obj.FilterPlaceholder:"Requirement"}`;

  
  const doc = new Document({
    sections: sectionTemp,
  });

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${reportName}.doc`);
  });


}
else if(obj.downloadPlaceholder==="Observation")
{
  let sectionTemp = [];
  let imgblob = generateBlobFromUrl(obj.companyDetials.companyLogo);
   Object.values(obj.processGroupDataList).map((data) => {
    if (obj.assignmentDetails.assignmentArea === "Function") {
      Object.values(data.processTaskList).map((process,index) => {
      if(obj.FilterPlaceholder!=="All"?obj.FilterPlaceholder===process.functionName:true){
        if(!isEmptyArray(process.taskList)) {
          let newProcessTaskList =[];
          Object.values(process.taskList).map((task)=>{
            let tempAssignmentTaskId = task.assignmentTaskId;
            Object.entries(obj.executionTemplateValuesMap).map(([execKey, execValue]) => {
              if (execKey == tempAssignmentTaskId) {
                Object.values(execValue).map((val) => {
                  if (val.value === "OBS") {
                    newProcessTaskList.push(task)
                  }
                });
              }
            });
          })

          if(newProcessTaskList.length>0)
          {
          let auditFindingPlanContent = getAuditFindingPlanPageContent(
          obj.assignmentDetails, obj.executionTemplateValuesMap, obj.taskTamplateValueMap, newProcessTaskList,
          process.processName, data.functionName,data.functionId,imgblob,obj.companyDetials.companyLogo,
          obj.selectedTaskTemplateFields, obj.execTaskTemplateFields, finalEmployeeList,obj.allAuditorList,"SN"
        );
        sectionTemp.push({
          properties: {
            page: {
              borders: commonPageBorder,
              margin:getMargins(500),
              size: {
                orientation: PageOrientation.LANDSCAPE,
              },
            },
          },
          children: auditFindingPlanContent,
        });
          }
       }
      }
      });
    }
  });
  reportName = String(obj.assignmentDetails.unitAddressList).split(":-:")[0]+" ("+ JSON.stringify(obj.assignmentDetails.unitNameCityList).split("(")[1].replace('"', "").trim()+" - "+`Observation(s)`;

  const doc = new Document({
    sections: sectionTemp,
  });

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${reportName}.doc`);
  });

}
else if(obj.downloadPlaceholder==="NCR") {
  let sectionTemp = [];
   /// Audit finding Plan Page content & Nonconformity Page content
   Object.values(obj.processGroupDataList).map((data) => {
    let ncrCount = 1;
    Object.values(data.taskList).map((task) => {

      let tempAssignmentTaskId = task.assignmentTaskId;
      let tempTaskId = task.taskId;
      let clausesRefrence = [];
      let qmsRefrence = [];
      let requirement = "";
      let description ="";

      Object.entries(obj.executionTemplateValuesMap).map(([execKey, execValue]) => {
        if (execKey == tempAssignmentTaskId) {
          Object.values(execValue).map((val) => {
            if(val.fieldId=== 5)
            {
              description=val.value
              // let tempVal=val.value
              // if(!isEmptyVariable(tempVal))
              // {
              //   let regex = /Description :(.*)Requirement :(.*)/s;
              //   let str = tempVal.match(regex);
              //   description = str[1].trim();
              //   requirement = str[2].trim();
              // }
            }
            if (val.value === "NC") {
              Object.entries(obj.taskTamplateValueMap).map(
                ([taskKey, taskValue]) => {
                  if (taskKey == tempTaskId) {
                    Object.values(taskValue).map((val) => {
                      if (val.fieldId === temprequirementId) {
                        requirement = val.value;
                      } else if (val.fieldId === tempqmsRefrenceId) {
                        qmsRefrence = !isEmptyVariable(val.value)
                          ? JSON.parse(val.value)
                          : [];
                      } else if (val.fieldId === tempclausesRefrenceId) {
                        clausesRefrence = !isEmptyVariable(val.value)
                          ? JSON.parse(val.value)
                          : [];
                      }
                    });
                  }
                }
              );
              let nonconformityReportPageContent =
                getNonconformityReportPageContent(
                  obj.assignmentDetails, clausesRefrence, qmsRefrence, requirement, ncrCount,
                  imgblob,obj.companyDetials.companyLogo,description,obj.allAuditorList,data.functionName,data.functionId,finalEmployeeList
                );
              sectionTemp.push({
                properties: {
                  page: {
                    borders: commonPageBorder,
                    margin:getMargins(500)
                  },
                },
                children: nonconformityReportPageContent,
              });
              ncrCount++;
            }
          });
        }
      });

    });
  
  });

  reportName = String(obj.assignmentDetails.unitAddressList).split(":-:")[0]+" ("+ JSON.stringify(obj.assignmentDetails.unitNameCityList).split("(")[1].replace('"', "").trim()+" - "+`NCR(s)`;
  
  const doc = new Document({
    sections: sectionTemp,
  });

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, `${reportName}.doc`);
  });


}


};



export const generateBlobFromUrl = (logo) => {
  if (isEmptyVariable(logo)) {
    return null;
  }
  return fetch(process.env.REACT_APP_BASE_URL_API+ logo)
    .then((resp) => resp.blob())
    .then((data) => data);
};

export const getMargins = (data)=> { return { top: data, right: data, bottom: data, left: data, } }