import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,
    isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import TreeNodeDropdown from '../../../common/DropdownMenus/TreeNodeDropdown';
import {ExpandCollapseWrapper} from '../../../common/Global/globalStyles.style';

const userDetails  = getLocalStorageVariables();

const viewTasksObj = {
    icon:"view_list",
    label:"View Tasks"
}

const actionArr = [viewTasksObj];

const FunctionTree = (props) => {

    return <div className="dialog-tree-structure">
        {
            !isEmptyVariable(props.functionObj) &&
            <div className="tree-node" style={{marginLeft:(props.level*40)+"px"}}>
                <span 
                    onClick={()=>props.onClickNode(props.functionObj)}
                    class="material-icons tree-span-bg" style={{opacity:isEmptyArray(props.functionObj.children)?"0":"1"}}>
                    {props.functionObj.isShowingChildren?"keyboard_arrow_down":"keyboard_arrow_right"}
                </span>
                <p onClick={()=>props.showFunctionTasks(props.functionObj.functionId)}>{props.functionObj.functionName}</p>
                {/* <div className="tree-dropdown-layout">
                    <TreeNodeDropdown
                        placeholder={props.functionObj.functionName}
                        dropdownArr={actionArr}
                        labelParam={"label"}
                        onDropDownItemClick={props.onDropDownItemClick}
                        dropdownId={props.functionObj}
                        fontbold={props.fontbold}
                        themeSettings={props.themeSettings}
                    />
                </div> */}
            </div>
        }

        {
            //Exit Condition
            !isEmptyVariable(props.functionObj) &&
            props.functionObj.isShowingChildren &&
            props.getChildNodes(props.functionObj).map((child)=>{
                let levelTemp = props.level + 1;
                return <FunctionTree 
                    functionObj = {child} 
                    getChildNodes = {props.getChildNodes}
                    level={levelTemp}
                    onClickNode={props.onClickNode}
                    onDropDownItemClick={props.onDropDownItemClick}
                    rootNodeStatus = {props.rootNodeStatus}
                    showFunctionTasks={props.showFunctionTasks}
                    rootNodeAllowEdit = {props.rootNodeAllowEdit}
                    themeSettings={props.themeSettings}
                />
            })
        }
    </div>
}

class FunctionTreeDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLoader:false,
            functionMap:{},
        }
    }

    componentDidMount(){
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showFunctionsTreeDialog){
            this.setState({
                showLoader:false,
                functionMap:{},
            },()=>{
                this.getFunctionTree();
            })
            
        }
    }

    expandCollapseAll = (functionId,isChecked) => {
        this.state.functionMap[functionId].isShowingChildren = isChecked;

        this.state.functionMap[functionId].children.map(child => {
            this.expandCollapseAll(child.functionId,isChecked);
        })

        this.setState({
            functionMap:this.state.functionMap
        })
    }

    expandAll = () => {
        this.expandCollapseAll(this.props.rootFunctionId,true);
    }

    collapseAll = () => {
        this.expandCollapseAll(this.props.rootFunctionId,false);
    }

    //No need of return value since the array itself is passed and updated.
    addParentIdandHasChildrenFlag = (tempArr, functionId, parentFunctionId) => {
        tempArr[functionId].parentFunctionId = parentFunctionId;

        if(!isEmptyArray(tempArr[functionId].children)){
            tempArr[functionId].hasChildren = true;

            tempArr[functionId].children.map(childNode => {
                this.addParentIdandHasChildrenFlag(tempArr,childNode.functionId,functionId);
            });
        }else{
            tempArr[functionId].hasChildren = false;
        }
    }

    onDropDownItemClick = (item,functionObj) => {
        if(item.label === "View Tasks"){
            this.props.viewTaskFunctionOnClick(functionObj);
        }
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    showFunctionTasks = (functionId) => {
        this.props.handleFunctionTreeDialogClose();
        this.props.handleActionDropdownItemClick(this.props.checkListItem,this.props.currentPageNo,
            this.props.searchkey,
            this.props.resultSize,
            this.props.sort,this.props.sortDir,this.props.status,"addRemoveTasks","function",
            functionId,this.props.checkListHeadingId,this.props.checkListHeading,
            this.props.processDeptId);
    }
    /***************************Function Dialog functions************************/

    getChildNodes = (node) => {
        if(isEmptyArray(node.children)){
            return [];
        }else{
            return node.children.map(item => this.state.functionMap[item.functionId]);
        }
    }

    onClickNode = (node) => {
        if(this.state.functionMap[node.functionId].isShowingChildren){
            this.state.functionMap[node.functionId].isShowingChildren = false;
        }else{
            this.state.functionMap[node.functionId].isShowingChildren = true;
        }
        
        this.setState({
            refreshFlag:!this.state.refreshFlag
        })
    }

    /************************API CALLS **************************/

    getFunctionTree = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetFunctionsSubtree,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                rootFunctionId:this.props.rootFunctionId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                let temp = data.result.functionMap;
                //Add Parent Id and hasChildren flag to the map
                this.addParentIdandHasChildrenFlag(temp,this.props.rootFunctionId,this.props.rootFunctionId);

                //Expand root level node's children by default
                if(temp[this.props.rootFunctionId] && 
                    !isEmptyArray(temp[this.props.rootFunctionId].children)){
                    temp[this.props.rootFunctionId].isShowingChildren = true;
                }

                this.setState({
                    functionMap:temp,
                    showLoader:false,
                });
            }else{
                this.setState({
                    functionMap:{},
                    showLoader:false,
                });
            }
        });
    }

    render() {
        return(
            <div>
                <Modal className="show-functions-tree-dialog custom-dialog" 
                show={this.props.showFunctionsTreeDialog} onHide={this.props.handleFunctionTreeDialogClose}>
                    <Modal.Header>
                        <h5>Function Tree</h5>
                        <button 
                            onClick={this.props.handleFunctionTreeDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        {
                            this.state.showLoader &&
                            <div class="loader"></div>
                        }
                        <ExpandCollapseWrapper  themeSettings={this.props.themeSettings}>
                            <div className="expand-collapse-layout"
                                onClick={this.expandAll}
                                style={{marginRight:10}}
                                type="button">
                                <span class="material-icons">unfold_more</span>
                                <p>Expand All</p>
                            </div>
                            <div
                                className="expand-collapse-layout"
                                onClick={this.collapseAll}
                                style={{marginLeft:10}}
                                type="button">
                                <span class="material-icons">unfold_less</span>
                                <p>Collapse All</p>
                            </div>

                        </ExpandCollapseWrapper>
                        <div className="body-wrapper">
                            {
                                Object.keys(this.state.functionMap).length > 0 &&
                                <FunctionTree 
                                    functionObj = {this.state.functionMap[this.props.rootFunctionId]} 
                                    getChildNodes = {this.getChildNodes}
                                    level={0}
                                    onClickNode={this.onClickNode}  //EXPAND/COLLAPSE
                                    fontbold={true}
                                    onDropDownItemClick={this.onDropDownItemClick}
                                    rootNodeStatus={this.props.rootNode.status}
                                    rootNodeAllowEdit={this.props.rootNode.allowEdit}
                                    themeSettings={this.props.themeSettings}
                                    showFunctionTasks={this.showFunctionTasks}  
                                />
                            }
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default FunctionTreeDialog;