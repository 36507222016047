import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,
    isEmptyVariable, isEmptyArray} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import AlertDialog from '../../../common/AlertDialog';
import {SearchSpan,AddNewButtonLayout} from '../../../common/Global/globalStyles.style';
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
import Pagination from '../../../common/Global/pagination';
import ProcessTreeDialog from './processTree'
import FunctionTreeDialog from '../functionTree'

const userDetails  = getLocalStorageVariables();
const activeStatus="Active";
const underReviewStatus="Under Review";

class ProcessFunctionsList extends Component{
    constructor(props){
        super(props)

        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE_GRID,
            totalCount:0,
            datalist:[],

            statusPlaceholder:"Active status",
            resultSizePlaceholder:Constants.RESULT_SIZE_GRID,
            departmentArr:[],
            deptOnly:"N",
            // isProcessView:true,

            status:activeStatus,
            statusArr:[
                {
                    label:activeStatus,
                    isSelected:true
                },
                {
                    label:underReviewStatus,
                    isSelected:false
                },
            ],
            resultSizeArr:Constants.RESULT_SIZE_GRID_DD_ARR,

            processName:"",
            selectedId:"",
            rootId:"",
            showProcessTreeDialog:false,
        }
    }

    componentDidMount(){
        
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showProcessCardsDialog){
            this.getInitData();
        }
    }

    getInitData = () => {
        this.setState({
            showLoader:true,
        });
        this.getProcessInitData();
        
    }

    handleProcessTreeDialogClose = () => {
        this.setState({
            showProcessTreeDialog:false,
            rootId:""
        })
    }
    // handleProcessTreeDialogClose = (refreshFlag,processId,processName) => {
    //     console.log("___ ",refreshFlag)
    //     this.setState({
    //         showProcessTreeDialog:false,
    //         rootId:""
    //     },()=>{
    //         if(refreshFlag === true){
    //             this.props.handleProcessCardsDialogClose(refreshFlag,processId,processName)
    //         }
    //     })
    // }

    // handleFunctionTreeDialogClose = () => {
    //     this.setState({
    //         showFunctionsTreeDialog:false,
    //         rootId:""
    //     })
    // }

    // switchView = () => {
    //     this.getInitData(true);
    // }

    showTreeDialog = (item) =>{
        this.setState({
            showProcessTreeDialog:true,
            rootId:item.processId,
            rootNode:item
        })
        // if(this.state.isProcessView){
        // }else{
        //     this.setState({
        //         // showFunctionsTreeDialog:true,
        //         // rootId:item.functionId,
        //         // rootNode:item
        //     })
        // }
        
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getMasterList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getMasterList();
            })
        }
    }
    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo:1,
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getMasterList();
        })
    }


    getProcessInitData = () => {
        Promise.all([
            fetch(Constants.GetProcessCreateMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                })
            }),
            fetch(Constants.GetRootProcesses,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    departmentId:this.props.departmentId,
                    activeFlag:"Y"//get values from active tables
                })
            })
        ])
        .then(([masterRes,generalRes]) => { 
            return Promise.all([masterRes.json(),generalRes.json()]) 
        })
        .then(([masterRes,generalRes]) => {
            let datalistTemp = [];
            let totalCountTemp = 0;
            let deptTemp = [];

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                    deptTemp = masterRes.data.departments;
            }else{
                deptTemp = [];
            }

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = generalRes.data.result;
                totalCountTemp = generalRes.data.count;
            }else{
                datalistTemp = [];
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                datalist:datalistTemp,
                totalCount:totalCountTemp,
                departmentArr:deptTemp,
                // isProcessView:isSwitchView?!this.state.isProcessView:this.state.isProcessView
            })
        })
    }

    
    getMasterList = () => {
        this.setState({
            showLoader:true,
        });

        this.getProcessMasterList();
        // if(this.state.isProcessView){
        // }else{
        //     this.getFunctionMasterList();
        // }
    }

    getProcessMasterList = () => {
        fetch(Constants.GetRootProcesses,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                status:this.state.status,
                departmentId:this.props.departmentId,
                activeFlag:"Y"//get values from active tables
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            fetch(Constants.GetRootProcesses,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    departmentId:this.props.departmentId,
                    activeFlag:"Y"//get values from active tables
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    render(){
        return(
            <div>
            <Modal className="show-functions-tree-dialog custom-dialog" 
                show={this.props.showProcessCardsDialog} 
                onHide={this.props.handleProcessCardsDialogClose}>
                    <Modal.Header>
                        <h5>Process</h5>
                        <button 
                            onClick={this.props.handleProcessCardsDialogClose}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body">
                        {
                            this.state.showLoader &&
                            <div class="loader"></div>
                        }

                        {
                            this.state.componentDidMountFlag &&
                            <div>
                                <div className="search-addnew-bg m-t-0">
                                    <div className="search-addnew">
                                        
                                        <div className="search-filter-layout">
                                            <FilterDropDown
                                                placeholder={this.state.resultSizePlaceholder}
                                                dropdownArr={this.state.resultSizeArr}
                                                onDropDownItemClick={this.resultSizeDropdownClick}
                                                themeSettings={this.props.themeSettings}
                                                mleft={0}
                                            />
                                            <div className="search-col m-l-10">
                                                <input 
                                                    name="searchkey" 
                                                    type="text"
                                                    onChange={this.handleChangeSearch}
                                                    placeholder="Search" 
                                                    onKeyPress={this.onEnterBtnPress}
                                                    value={this.state.searchkey}
                                                />
                                                <SearchSpan 
                                                themeSettings={this.props.themeSettings}
                                                onClick = {this.onSearchIconClick} className="material-icons"> search </SearchSpan>
                                            </div>
                                            
                                        </div>

                                        {/* <div className="flex-center-layout">
                                            <AddNewButtonLayout themeSettings={this.props.themeSettings}>
                                                <a onClick={this.switchView} href="javascript:void(0);">
                                                    <span className="material-icons">swap_horiz</span>
                                                    <p>{this.state.isProcessView?"Functions":"Processes"}</p>
                                                </a>
                                            </AddNewButtonLayout>
                                        </div> */}
                                    </div>
                                </div>


                                <div className="row custom-row">
                                {
                                    !isEmptyArray(this.state.datalist) && 
                                    this.state.datalist.map((item,idx) => {
                                        let marTop = 10;
                                        if(idx === 0 || idx === 1 || idx === 2){
                                            marTop=0;
                                        }

                                        return <div className="col-md-4 custom-col-5">
                                            <div onClick={this.showTreeDialog.bind(this,item)}
                                            className="tree-list-card-layout c-pointer" style={{marginTop:marTop}}>
                                                <div className="tree-card-text-layout">
                                                    <p className="font-bold">{item.processName}</p>
                                                    {!isEmptyArray(this.state.departmentArr) && this.state.departmentArr.length > 1 && 
                                                    
                                                    <span>{item.deptName}</span>
                                    }
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                                </div>
                                {
                                    this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                    <div class="no-items-layout">
                                        <div class="no-items-card">
                                            <h6>
                                            {
                                                isEmptyVariable(this.state.apiSearchKey)?
                                                Constants.NO_RECORDS_WARNING
                                                :
                                                Constants.EMPTY_SEARCH_WARNING
                                            }
                                            </h6>
                                        </div>
                                    </div>
                                }
                                <div className="pagination-layout">
                                    <div className="row custom-row">
                                        <div className="col-md-9 custom-col">
                                        {
                                            (this.state.totalCount > this.state.datalist.length) && 
                                            <Pagination 
                                                totalLength ={this.state.totalCount} 
                                                items={this.state.datalist} 
                                                onChangePage={this.onChangePage} 
                                                pageSize={this.state.resultSize}
                                                currentPageNo = {this.state.currentPageNo}
                                                initialPage={this.state.currentPageNo} 
                                                themeSettings={this.props.themeSettings}/>
                                        }
                                        </div>
                                        <div className="col-md-3 custom-col">
                                            <p>{"Total Records: "+this.state.totalCount}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        
                    </div>
                </Modal>
                <ProcessTreeDialog
                    showProcessTreeDialog={this.state.showProcessTreeDialog}
                    handleProcessTreeDialogClose={this.handleProcessTreeDialogClose}
                    rootProcessId={this.state.rootId}
                    rootNode={this.state.rootNode}
                    themeSettings={this.props.themeSettings}
                    processDeptId = {this.props.departmentId}
                    taskId={this.props.taskId}
                    handleProcessCardsDialogClose={this.props.handleProcessCardsDialogClose}
                    
                    // addTaskOnClick={this.addTaskOnClick}
                    // viewTaskOnClick={this.viewTaskOnClick}
                    // handleActionDropdownItemClick={this.props.handleActionDropdownItemClick}
                    // currentPageNo={this.props.currentPageNo}
                    // searchkey={this.props.searchkey}
                    // resultSize={this.props.resultSize}
                    // sort={this.props.sort}
                    // sortDir={this.props.sortDir}
                    // status={this.props.status}
                    // checkListItem = {this.props.checkListItem}
                    // checkListHeadingId = {this.props.checkListHeadingId}
                    // checkListHeading = {this.props.checkListHeading}
                />

                {/* <FunctionTreeDialog
                    showFunctionsTreeDialog={this.state.showFunctionsTreeDialog}
                    handleFunctionTreeDialogClose={this.handleFunctionTreeDialogClose}
                    rootFunctionId={this.state.rootId}
                    rootNode={this.state.rootNode}
                    departmentArr={this.state.departmentArr}
                    viewTaskFunctionOnClick={this.viewTaskFunctionOnClick}
                    themeSettings={this.props.themeSettings}

                    currentPageNo={this.props.currentPageNo}
                    searchkey={this.props.searchkey}
                    resultSize={this.props.resultSize}
                    sort={this.props.sort}
                    sortDir={this.props.sortDir}
                    status={this.props.status}
                    handleActionDropdownItemClick={this.props.handleActionDropdownItemClick}
                    checkListItem = {this.props.checkListItem}
                    checkListHeadingId = {this.props.checkListHeadingId}
                    checkListHeading = {this.props.checkListHeading}
                    processDeptId = {this.props.departmentId}
                /> */}
            </div>
        )
    }
}

export default ProcessFunctionsList;