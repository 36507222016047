import React, { Component } from "react";
import * as Constants from "../../common/Global/constants";
import * as TemplateConstants from "../../common/Global/templateConstants";
import { AddNewButtonLayout, DialogSaveButton, TableCheckBox } from '../../common/Global/globalStyles.style';
import Sidebar from "../../common/SidebarCollapse";
import Topbar from "../../common/Topbar";
import TableDropDown from "../../common/DropdownMenus/TableDropdown";
import MasterMenuTabs from "./Common/topmenu";
import AlertDialog from "../../common/AlertDialog";
import SelectRiskDialog from "./Tasks/selectRisk";
import SelectValueAdditionDialog from "./Tasks/selectValueAddition";
import { getObservationTasks } from "../../common/Global/reviewFunctions";
import { getLocalStorageVariables, getThemeSettingsVariables, isEmptyVariable, isEmptyArray,
     removeHtmlTags, GetOrderedMasterTabMenuExecute, isJsonString, getStringWithSpaceAfterComma, 
     getOnlyFileNameFromPath} from "../../common/Global/commonFunctions";
import RegularDropdown from '../../common/DropdownMenus/RegularDropdownTable';
import { getCheckListScoresFlag, getOnlyMyTasks, getMatrixandMyDetails, getRankingArray } from './Tasks/execTaskUtilFunctions';
import UpdateAssignmentTaskReviewStatus from "./Tasks/AssignmentTaskupdatereviewstatusdialog";
import RegularDD from '../../common/DropdownMenus/RegularDropdown';
import { BrowserRouter as Router } from "react-router-dom";
import ReviewCommentsDialog from "./Tasks/reviewCommentsdialog";
import TaskDetailsLayout from '../Tasks/taskDetailsWithTemplates';
import ExecTableDialog from "./Common/execTableDialog";
import AlertWithTextarea from "../../common/AlertDialog/alertwithTextarea";
import { Document, Packer, PageOrientation } from "docx";
import { saveAs } from "file-saver";
import { getParagraphStyles, getTableContent, } from "./Common/createExecuteTaskDownloadFunctions"
import { getExcelJsContentsWithTemplates } from "./Common/createTaskInExcelDownloadFunctions"
import Excel from 'exceljs';
import LeftSideSelectDialog from '../Tasks/Common/selectDropdownValues';
import moment from "moment";
import DatePickerComponent from "../Tasks/Common/dateSelectComponent";
import UploadAnnexureDialog from "./Tasks/UploadAnnexure";
import FilterDropDown from "../../common/DropdownMenus/FilterDropdown";
import PreviousAssignmentDialog from './Common/previousAssignmentDialog'
import ExecutionTasksActionDialog from "./Tasks/ExecutionTasksActionDialog";
import TopmenuReports from "../../common/TopmenuReports";
import AssignFunctionDialog from "./Common/assignFunctionDialog";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Modal } from "react-bootstrap";
import CommonAlertMessageDialog from "../../common/AlertDialog/commonAlertMessage";

import TemplateFieldsFilter from "./Common/templateFieldsFilter";
const defaultWeightPlaceholder = "Rank";
const defaultYNPlaceholder = "Rank";
const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();
const inActiveStatus = "Inactive";

const downloadReportPlaceholder = "Download Report";
const TASKLIST_DOWNLOAD_ARR = [
    {
        label: "Docx format",
        format: "Docx format"
    },
    {
        label: "Xlsx format",
        format: "Xlsx format"
    }
];

const FILTER_DROPDOWN_ARR = [
    {
        label: "All",
        isSelected: true,
    },
    {
        label: "Pending",
        isSelected: false,
    },
    {
        label: "Completed",
        isSelected: false,
    },
]

class OtherMasters extends Component {
    constructor(props) {
        super(props);
        this.state = {
            assignmentId: this.props.match.params.id,
            componentDidMountFlag: false,
            apiSearchKey: "",
            searchkey: "",
            currentPageNo: 1,
            showLoader: false,
            resultSize: Constants.RESULT_SIZE,
            totalCount: 0,
            datalist: [],
            oldDatalist:[],
            allDataList: [],
            statusPlaceholder: "Active",
            resultSizePlaceholder: Constants.RESULT_SIZE,
            sort: "",
            sortDir: "",
            assignmentStatus: "",
            assignmentArea: "",
            assignmentDetails: "",
            company: {},
            selectedTaskTemplateFields: [],
            taskTemplateValuesMap: {},
            execTaskTemplateFields: [],
            executionTemplateValuesMap: {},
            assignmentRiskMatrix: [],
            selectedAssignmentTaskId: "",
            selectedAssignmentTaskRiskLevelId: "",
            assignmentTaskAllowEdit: "N",
            valueAdditions: [],
            executionTemplateFixedFields: {},

            //Value Addition
            showSelectVADialog: false,
            selectedVAObj: [],

            showAlertDialog: false,
            operationType: "",
            deleteReactivateId: "",
            deleteReactivateMasterType: "",
            reviewLevel: "",
            alertDialogMessage: "",
            alertDialogHeading: "",
            proceedBtnLabel: "",
            showBtnLoader: false,

            showAlertDialogInfo: false,
            alertDialogMessageInfo: "",

            showUpdateReviewDialog: false,
            showReviewCommentsDialog: false,
            type: "",

            showTaskDetailsLayout: false,
            selectedTaskIdForDetails: "",
            user: {},

            assignmentMatrix: [],
            selectedResourceUserName: "",
            selectedResourceId: "",
            myPosition: "",

            showAlertWithTextareaDialog: false,
            alertWithTextareaDialogMessage: "",
            remarks: "",

            overallChecklistScoreObj: {},
            checklistMaxRank: 0,

            showSelectDropdownDialog: false,
            templateFieldItem: {},

            downloadReportArr: TASKLIST_DOWNLOAD_ARR,
            downloadReportPlaceholder: downloadReportPlaceholder,
            showSelectUploadAnnexureDialog: false,
            isBackup: false,
            assignmentTaskUserId: "",

            filterDataList: [],
            FilterDropDownArr: [],
            FilterPlaceholder: "All",
            completeOngingFilterPlaceholder: "All",
            completeOngingFilterArr: FILTER_DROPDOWN_ARR,
            companyDetials: {},
            unitDetials: [],
            allAnnexureList:[],
            allAuditorList:[],
            allViewList:[],
            downloadButtonArr:[],
            downloadReportData:[],

            showPreviousAssignmentData:false,
            assignmentExecuteFields:[],
            previousAssignmentDataList:[],
            showExecutionTasksActionPopup: false,
            showCheckboxColumnDataList: false,
            checkedTaskListIds: [],
            executionTaskAction: "",
            likelihoodRiskMatrix: [],
            selectedLikelihoodRiskId: "",

            selectFilterData:[],
            selectFilterDropDownArr:[],
            selectFilterPlaceholder:"Previous",
            previousAssignmentTemplateData:[],
            selectFilterValue:{},

            taskDataList:[],
            showAssignFunc:false,
            showQuillAlertDialog:false,
            singleSelectFilter:[],
            weightArr:[],
            yesNoRankArr:[],
            rankingArr:[],
            isIndustryFlag:false
        };
    }

    componentDidMount() {
        this.getInitData();
    }

    // Risk
    handleSelectRiskDialogClose = (reloadFlag) => {
        this.setState(
            {
                showSelectRiskDialog: false,
                selectedAssignmentTaskId: "",
                selectedAssignmentTaskRiskLevelId: "",
                assignmentTaskAllowEdit: "N",
                selectedLikelihoodRiskId: "",
                isLikelihood:false
            },
            () => {
                if (reloadFlag === true) {
                    this.GetExecuteTasksList();
                }
            }
        );
    };

    handleSelectRiskDialogShow = (assignmentTaskId,assignmentTaskRiskLevelId,allowEdit,likelihoodRiskId) => {
        let isLikelihood = this.state.allSettings.some(e => e.settingColumn.includes(Constants.DISABLEDLIKELIHOOD) && e.settingColumnEnabled === "N" && 
                    e.departmentId === this.state.assignmentDetails.departmentId && e.assignmentTypeId === this.state.assignmentDetails.assignmentTypeId);

        this.setState({
            showSelectRiskDialog: true,
            selectedAssignmentTaskId: assignmentTaskId,
            selectedAssignmentTaskRiskLevelId: assignmentTaskRiskLevelId,
            assignmentTaskAllowEdit: allowEdit,
            selectedLikelihoodRiskId: likelihoodRiskId,
            isLikelihood:isLikelihood
        });
    };

    // Value addition
    handleSelectVADialogClose = (reloadFlag) => {
        this.setState({
            showSelectVADialog: false,
            selectedAssignmentTaskId: "",
        },
        () => {
            if (reloadFlag === true) {
                this.GetExecuteTasksList();
            }
        });
    };

    handleAlertDialogClose = () => {
        this.setState({
            showAlertDialog: false,
            alertDialogMessage: "",
        });
    };

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo: false,
            alertDialogMessageInfo: "",
        });
        if(this.state.responseCode === Constants.CODE_ASSIGNMENT_ACCESS_ERROR ||
            this.state.responseCode === Constants.CODE_ASSIGNMENT_SPECIFIC_ACCESS_ERROR){
			this.props.history.push(Constants.URL_EXECUTEASSIGNMENTS);
		}
    };

    getAllowEdit = (allowEdit, completionStatus, userId) => {
        if (this.state.assignmentStatus !== "Ongoing") {
            return "N";
        }

        //Now check reviewAssignmentTask flag
        if (this.state.assignmentDetails?.reviewAssignmentTask === "Y") {
            return allowEdit;
        }

        if (completionStatus === "Completed") {
            //allow Edit for Reviewer
            if (this.state.myPosition === "Reviewer") {
                return "Y";
            }
        } else {
            //allow edit for only assigned user
            if (userId === userDetails.userId) {
                return "Y";
            }
        }

        return "N";
    };

    handleAlertWithTextareaDialogClose = (remarks) => {
        this.setState(
            {
                showAlertWithTextareaDialog: false,
                alertWithTextareaDialogMessage: "",
                remarks: remarks,
            },
            () => {
                this.handleRecordOperation();
            }
        );
    };

    onTableDropDownItemClick = (item, dropdownItem) => {
        if (item.label === "Show Guidance") {
            this.setState({
                operationType: item.label,
                alertDialogHeading: "Guidance",
                proceedBtnLabel: "Okay",
                showAlertDialog: true,
                alertDialogMessage: isEmptyVariable(dropdownItem.rankingGuidance)
                    ? "N/A"
                    : dropdownItem.rankingGuidance,
            });
        } else if (item.label === "Mark as Completed") {
            this.setState({
                operationType: item.label,
                alertDialogHeading: "Mark as Completed",
                proceedBtnLabel: "Okay",
                deleteReactivateId: dropdownItem.assignmentTaskId,
                showAlertDialog: true,
                alertDialogMessage:
                    "Are you sure you want to mark " +
                    removeHtmlTags(dropdownItem.task) +
                    " as completed?",
            });
        } else if (item.label === "Reopen Task") {
            if (dropdownItem.userId == userDetails.userId) {
                this.setState({
                    operationType: item.label,
                    alertDialogHeading: "Reopen Task",
                    proceedBtnLabel: "Okay",
                    deleteReactivateId: dropdownItem.assignmentTaskId,
                    showAlertDialog: true,
                    alertDialogMessage:
                        "Are you sure you want to reopen " +
                        removeHtmlTags(dropdownItem.task) +
                        " task?",
                });
            } else {
                this.setState({
                    operationType: item.label,
                    alertDialogHeading: "Reopen Task",
                    proceedBtnLabel: "Okay",
                    deleteReactivateId: dropdownItem.assignmentTaskId,

                    showAlertWithTextareaDialog: true,
                    alertWithTextareaDialogMessage:
                        "Are you sure you want to reopen " +
                        removeHtmlTags(dropdownItem.task) +
                        " task?",
                });
            }
        } else if (item.label === "Submit For Review") {
            this.setState({
                operationType: item.label,
                alertDialogHeading: "Submit For Review",
                proceedBtnLabel: "Submit",
                deleteReactivateId: dropdownItem.assignmentTaskId,
                showAlertDialog: true,
                alertDialogMessage:
                    "Are you sure you want to submit " +
                    removeHtmlTags(dropdownItem.task) +
                    " for review?",
            });
        } else if (item.label === "Update Review Status") {
            this.setState({
                reviewLevel: dropdownItem.reviewLevel,
                deleteReactivateId: dropdownItem.assignmentTaskId,
                showUpdateReviewDialog: true,
            });
        } else if (item.label === "Review History") {
            this.setState({
                deleteReactivateId: dropdownItem.id,
                showReviewHistoryDialog: true,
            });
        } else if (item.label === "Review Remarks") {
            this.setState({
                operationType: item.label,
                alertDialogHeading: "Review Remarks",
                proceedBtnLabel: "Okay",
                showAlertDialog: true,
                alertDialogMessage: '"' + dropdownItem.lastReviewRemark + '"',
            });
        } else if (item.label === "Value Additions") {
            this.setState({
                showSelectVADialog: true,
                selectedAssignmentTaskId: dropdownItem.assignmentTaskId,
                selectedVAObj: dropdownItem.valueAdditionList,
            });
        } else if (item.label === "Previous Assignment Data") {
            this.previousAssignmentData(dropdownItem);
        }
    };

    maxrDropdownClick = (selectedItem, udf) => {
        this.setState({
            showLoader: true,
        });

        let iapArr = udf.split(":-:");
        let index = iapArr[0];
        let parentId = iapArr[1];
        let list = this.state.datalist;
        list[parentId].taskList[index].rank = selectedItem.id;

        this.setState({
            datalist: list,
            showLoader: false,
        },
        () => {
            this.handleSaveAssignmentTaskProperties(
                list[parentId].taskList[index].assignmentTaskId,
                selectedItem.id,
                "rank"
            );
        });
    };

    handleReviewCommentsDialogClose = (reloadFlag) => {
        let type = this.state.type;

        this.setState({
            showReviewCommentsDialog: false,
            deleteReactivateId: "",
            type: "",
        },
        () => {
            // if(reloadFlag && type ==="Remark"){
            if (reloadFlag === true) {
                this.GetExecuteTasksList();
            }
        });
    };

    handleReviewCommentsDialogShow = (id, remarksList) => {
        if (this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_ONGOING) {
            if (isEmptyVariable(remarksList)) {
                //Do nothing
            } else {
                this.setState({
                    showReviewCommentsDialog: true,
                    deleteReactivateId: id,
                    type: "Remark",
                });
            }
        }
    };

    handleShowTaskDetailsLayout = (taskId) => {
        this.setState({
            showTaskDetailsLayout: true,
            selectedTaskIdForDetails: taskId,
        });
    };

    handleHideTaskDetailsLayout = () => {
        this.setState({
            showTaskDetailsLayout: false,
            selectedTaskIdForDetails: "",
        });
    };

    handleShowAddEditObservation = (taskItem) => {
        if (isEmptyVariable(taskItem.observationId) && isEmptyVariable(taskItem.observationStatus) &&
            taskItem.completionStatus === "Completed") {
            // this task is completed with out linking any observations, so do nothing
        } else {
            if (taskItem.allowEdit === "Y" && isEmptyVariable(taskItem.observationId) &&
                isEmptyVariable(taskItem.observationStatus) ) {
                this.props.history.push(
                    Constants.URL_ADDTASK + "/" + this.state.assignmentId + "-" + taskItem.assignmentTaskId
                );
            } else if (taskItem.allowEdit === "Y" && !isEmptyVariable(taskItem.observationId) && taskItem.observationStatus !== inActiveStatus) {
                this.props.history.push(
                    Constants.URL_EDITOBSERVATION + "/" + taskItem.observationId + "-" + this.state.assignmentId
                );
            }
        }
    };

    resourceDropdownItemClick = (selectedItem) => {
        let arr = this.getUserTasksFromUserId(selectedItem.id);
        let tempFilterArray = [
            {
                label: "All",
                isSelected: true,
            },
        ];

        // if (this.state.assignmentArea === "Process") {
        Object.values(arr).map((item) => {
            let uid = 0;
            Object.values(item.taskList).map((task) => {
                uid = task.userId;
            });
            if (uid == selectedItem.id) {

                tempFilterArray.push({
                    label: this.state.assignmentArea === "Process" ? item.processName : this.state.assignmentArea === "Function" ? item.functionName : item.heading,
                    isSelected: false,
                });
            }
        });
        // }
        let completedOngoingArr = this.state.completeOngingFilterArr
        completedOngoingArr.map((item) => {
            if (item.label === "All") {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        });

        this.setState({
            selectedResourceId: selectedItem.id,
            selectedResourceUserName: selectedItem.label,
            datalist: arr,
            oldDatalist:arr,
            FilterDropDownArr: tempFilterArray,
            FilterPlaceholder: "All",
            completeOngingFilterPlaceholder: "All",
            completedOngoingArr: completedOngoingArr,
            filterDataList: [],

        });
    };

    getUserTasksFromUserId = (userId) => {
        let allData = JSON.parse(JSON.stringify(this.state.allDatalist));
        let arr = allData.map((process) => {
            let taskList = [];
            let maxScore = 0;
            let totalScore = 0;
            process.taskList.forEach((task) => {
                if (task.userId + "" === userId + "") {
                    taskList.push(task);

                    //Now if it is checklist, then based on the type of checklist we need to assign scores
                    if (this.state.assignmentArea === "CheckList") {
                        let weight = isEmptyVariable(task.weight) ? 1 : task.weight;
                        let rank =
                            isEmptyVariable(task.rank) || task.rank === -1 ? 0 : task.rank;

                        //if rank is NA then that should be considered for max score also
                        let maxRankNew = task.rank === -1 ? 0 : this.state.checklistMaxRank;
                        maxScore += weight * maxRankNew;
                        totalScore += weight * rank;
                    }
                }
            });
            process.taskList = taskList;
            process.maxScore = maxScore;
            process.totalScore = totalScore;

            return process;
        });
        return arr;
    };

    getTaskDisplayValues = (taskId, fieldItem) => {

        if (isEmptyVariable(this.state.taskTemplateValuesMap[taskId])) {
            return "-";
        }

        let fieldId = fieldItem.fieldId;
        if (isEmptyVariable(this.state.taskTemplateValuesMap[taskId][fieldId])) {
            return "-";
        }

        let displayValue =
            this.state.taskTemplateValuesMap[taskId][fieldId]["value"];
        if (isEmptyVariable(displayValue)) {
            return "-";
        }

        switch (fieldItem.fieldType) {
            case TemplateConstants.FIELD_TYPE_TEXT:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_DATE:
                return isEmptyVariable(displayValue)
                    ? ""
                    : moment(displayValue).format("DD/MM/YYYY");
            case TemplateConstants.FIELD_TYPE_SINGLE_SELECT:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_DROPDOWN:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_MULTI_SELECT:
                if (!isJsonString(displayValue)) {
                    return "-";
                }
                return JSON.parse(displayValue).join(", ");
            case TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI:
                if (!isJsonString(displayValue)) {
                    return "-";
                }
                return JSON.parse(displayValue).join(", ");
        }
        return "-";
    };

    handledownloadTasks = () => {
        //first lets initialise the styles
        let paraStyles = getParagraphStyles();

        // let headersContent=  getHeaderContent(
        // 	this.state.companyDownloadReport?.clientName,
        // 	this.state.companyDownloadReport?.assignmentTypeName,
        // );
        // let footersContent=  getFooterContent(
        // 	this.state.companyDownloadReport?.companyName
        // );

        // let assignementTableHeadingContent = getAssignementTableHeadingContent();

        let tableContent = getTableContent(
            this.state.datalist
            // this.state.companyDownloadReport?.unitList,
        );

        const doc = new Document({
            styles: {
                paragraphStyles: paraStyles,
            },

            sections: [
                {
                    properties: {
                        page: {
                            size: {
                                orientation: PageOrientation.LANDSCAPE,
                            },
                        },
                    },
                    // headers:headersContent,
                    // footers:footersContent,
                    children: tableContent,
                },
            ],
        });

        Packer.toBlob(doc).then((blob) => {
            saveAs(blob, "Tasks.docx");
        });
    };

    handledownloadTasksinExceljs = () => {
        const workSheetName = "Tasks";
        const workBookName = "Tasks";
        const workbook = new Excel.Workbook();

        //get excel content in required format
        //SAMPLE: [{"name":["Adding Sibling Test"],"tableCols":[{"name":"No.","filterButton":false},{"name":"Task","filterButton":false},{"name":"Remarks","filterButton":false},{"name":"Risk","filterButton":false}],"tableValue":[["1","test one task","",""]]}]
        //   let excelJsContent=getExcelJsContents(this.state.datalist,this.state.showWeight,this.state.showRanking,this.state.showYesNoDD)
        let excelJsContent = getExcelJsContentsWithTemplates(
            this.state.datalist,
            this.state.selectedTaskTemplateFields,
            this.state.taskTemplateValuesMap,
            this.state.execTaskTemplateFields,
            this.state.executionTemplateValuesMap
        );

        try {
            const fileName = workBookName;
            // creating one worksheet in workbook
            const worksheet = workbook.addWorksheet(workSheetName);
            // line count to get start of the each table in excel
            let lineCount = 1;

            worksheet.addRow(["Company", this.state.assignmentDetails.companyName]);
            lineCount++;

            let unitNameCityList = getStringWithSpaceAfterComma(
                this.state.assignmentDetails.unitNameCityList
            );
            worksheet.addRow(["Units", unitNameCityList]);
            lineCount++;

            worksheet.addRow([
                "Reference No.",
                this.state.assignmentDetails.referenceNo,
            ]);
            lineCount++;

            worksheet.addRow([
                "Assignment Type",
                this.state.assignmentDetails.assignmentTypeName,
            ]);
            lineCount++;

            let periodFromDate = moment(
                this.state.assignmentDetails.periodFromDate
            ).format("DD/MM/YYYY");
            let periodToDate = moment(
                this.state.assignmentDetails.periodToDate
            ).format("DD/MM/YYYY");
            let period = periodFromDate + " - " + periodToDate;
            worksheet.addRow(["Sample Period", period]);
            lineCount++;

            worksheet.addRow([]);
            lineCount++;

            excelJsContent.forEach((item) => {
                //Add Heading/Name
                let rowNameObj = worksheet.addRow(item.name);
                rowNameObj.font = { bold: true };
                lineCount++;

                //add task tables
                worksheet.addTable({
                    name: "table" + lineCount,
                    ref: `A${lineCount}`, //starting position of table
                    headerRow: true,
                    style: {
                        // theme: 'TableStyleDark3',
                        showRowStripes: true,
                        showFirstColumn: true,
                    },
                    columns: item.tableCols,
                    rows: item.tableValue,
                });
                worksheet.addRow({});
                lineCount += 2;
                lineCount += item.tableValue.length;
            });

            //find max string length of column and assign it to column width
            worksheet.columns.forEach((column) => {
                let maxColumnLength = 0;
                column.eachCell({ includeEmpty: true }, (cell) => {
                    cell.alignment = { wrapText: true };
                    // cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
                    maxColumnLength = Math.max(
                        maxColumnLength,
                        10,
                        cell.value ? cell.value.toString().length : 0
                    );
                });
                //max column width should be 50, if it is more than 50 then reset it to 50
                column.width = maxColumnLength + 2 > 50 ? 50 : maxColumnLength + 2;
            });

            // write the content using writeBuffer
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer]), `Tasks.xlsx`);
            });
        } catch (error) {
        } finally {
            // removing worksheet's instance to create new one
            workbook.removeWorksheet(workSheetName);
        }
    };

    downloadReportDropdownClick = (item) => {
        this.setState(
            {
                downloadReportPlaceholder: item.label,
            },
            () => {
                if (item.label === "Docx format") {
                    this.handledownloadTasks();
                } else if (item.label === "Xlsx format") {
                    this.handledownloadTasksinExceljs();
                }
            }
        );
    };

    // Upload annexure
    uploadAnnexuresBackupIconClick = (assignmentTaskId,type,assignmentTaskAllowEdit,assignmentTaskUserId) => {
        if (type === "Annexures") {
            this.setState({
                showSelectUploadAnnexureDialog: true,
                deleteReactivateId: assignmentTaskId,
                isBackup: false,
                assignmentTaskAllowEdit:this.state.assignmentStatus===Constants.ASSIGNMENT_STATUS_DRAFT?"N":assignmentTaskAllowEdit
            });
        } else if (type === "Backups") {
            this.setState({
                showSelectUploadAnnexureDialog: true,
                deleteReactivateId: assignmentTaskId,
                isBackup: true,
                assignmentTaskAllowEdit:this.state.assignmentStatus===Constants.ASSIGNMENT_STATUS_DRAFT?"N":assignmentTaskAllowEdit
            });
        } else if (type === "Review Comments") {
            this.setState({
                deleteReactivateId: assignmentTaskId,
                showReviewCommentsDialog: true,
                assignmentTaskUserId: assignmentTaskUserId,
                type: "Comment",
                assignmentTaskAllowEdit:this.state.assignmentStatus===Constants.ASSIGNMENT_STATUS_DRAFT?"N":assignmentTaskAllowEdit
            });
        }
    };

    handleSelectUploadAnnexureDialogClose = (reloadFlag) => {
        this.setState({
            showSelectUploadAnnexureDialog: false,
            isBackup: false,
        },
        () => {
            if (reloadFlag === true) {
                this.GetExecuteTasksList();
            }
        });
    };

    handleSelectUploadAnnexureDialogSaveClose = (uploadedAnnexureArr,annexure_NR) => {
        this.setState({
            showSelectUploadAnnexureDialog: false,
            isBackup: false,
            uploadedAnnexureArr: uploadedAnnexureArr,
            annexure_NR: annexure_NR,
        });
    };

    handleUpdateReviewDialogClose = (reloadFlag) => {
        this.setState(
            {
                showUpdateReviewDialog: false,
                deleteReactivateMasterType: "",
                deleteReactivateId: "",
                reviewLevel: "",
            },
            () => {
                if (reloadFlag === true) {
                    this.GetExecuteTasksList();
                }
            }
        );
    };

    /*################################## EXEC TASK RELATED #################################*/
    getExecTableDataDisplayValue = (assignmentTaskId, fieldItem) => {
        if (isEmptyVariable(this.state.executionTemplateValuesMap[assignmentTaskId])) {
            return "";
        }

        let fieldId = fieldItem.fieldId;
        if (isEmptyVariable(this.state.executionTemplateValuesMap[assignmentTaskId][fieldId])) {
            return "";
        }

        let displayValue = this.state.executionTemplateValuesMap[assignmentTaskId][fieldId]["value"];
        if (isEmptyVariable(displayValue)) {
            return "";
        }

        switch (fieldItem.fieldType) {
            case TemplateConstants.FIELD_TYPE_TEXT:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_DATE:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_SINGLE_SELECT:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_DROPDOWN:
                return displayValue;
            case TemplateConstants.FIELD_TYPE_MULTI_SELECT:
                if (!isJsonString(displayValue)) {
                    return "";
                }
                return JSON.parse(displayValue).join(", ");
            case TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI:
                if (!isJsonString(displayValue)) {
                    return "";
                }
                return JSON.parse(displayValue).join(", ");
            case TemplateConstants.FIELD_TYPE_TABLE:
                if (!isJsonString(displayValue)) {
                    return "";
                }
                return JSON.parse(displayValue);
        }
        return "";
    };

    getExecTableDataDisplayValueNR = (assignmentTaskId, fieldItem) => {
        if (isEmptyVariable(this.state.executionTemplateValuesMap[assignmentTaskId])) {
            return "";
        }

        let fieldId = fieldItem.fieldId;
        if (isEmptyVariable(this.state.executionTemplateValuesMap[assignmentTaskId][fieldId])) {
            return "";
        }

        let displayValue = this.state.executionTemplateValuesMap[assignmentTaskId][fieldId]["valueNR"];
        if (displayValue === "Y") {
            return "Y";
        }else{
            return "N"
        }
    };

    modules = {
		toolbar: [
			["bold", "italic", "underline", "strike",{ list: "ordered" }, { list: "bullet" }, { color: [] }, { background: [] }, { align: [] }, "link","clean",{ expand: "expand" }],
		],
	};
    fullScreenModules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline", "strike", { color: [] }, { background: [] }],
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }, "link","image"],
            ["clean"],
        ]
    }

    onCustomExecuteTextFieldFocus = (assignmentTaskId, fieldItem) => {
        let tempArr = [...this.state.datalist];
        let execTaskTemplateFields = [...this.state.execTaskTemplateFields];
        tempArr.map(item => {
            item.taskList.map(taskItem => {
                if(taskItem.assignmentTaskId === assignmentTaskId){
                    taskItem.isFocus = true;
                    taskItem[`displayValue_${fieldItem.fieldId}`] = this.getExecTableDataDisplayValue(
                        assignmentTaskId,
                        fieldItem
                    );
                    taskItem[`oldDisplayValue_${fieldItem.fieldId}`] = taskItem[`displayValue_${fieldItem.fieldId}`];
                }else{
                    taskItem.isFocus = false;
                }
                return taskItem;
            })
            return item;
        });

        execTaskTemplateFields.map(item => {
            if(item.fieldId == fieldItem.fieldId){
                item.isFocus = true;
            }else{
                item.isFocus = false;
            }
            return item;
        });

        this.setState({
            execTaskTemplateFields:execTaskTemplateFields,
            datalist:tempArr
        });
    }

    handleChangeExecute = (assignmentTaskId, fieldItem, value) => {
        let tempArr = [...this.state.datalist];
        tempArr.map(item => {
            item.taskList.map(taskItem => {
                if(taskItem.assignmentTaskId === assignmentTaskId){
                    taskItem[`displayValue_${fieldItem.fieldId}`] = value;
                    taskItem[`oldDisplayValue_${fieldItem.fieldId}`] = this.getExecTableDataDisplayValue(
                        assignmentTaskId,
                        fieldItem
                    );
                }
                return taskItem;
            });
            return item;
        });
    }

    handleExecuteTextFieldBlur = (assignmentTaskId, fieldItem) => {
        const datalist = this.state.datalist
        .filter(item => item.taskList.some(task => task.assignmentTaskId === assignmentTaskId))
        .map(item => item.taskList.find(task => task.assignmentTaskId === assignmentTaskId))[0]; 

        if ((removeHtmlTags(datalist[`displayValue_${fieldItem.fieldId}`]).length == 0 ? removeHtmlTags(datalist[`displayValue_${fieldItem.fieldId}`]) : datalist[`displayValue_${fieldItem.fieldId}`]) !== datalist[`oldDisplayValue_${fieldItem.fieldId}`]) {
            this.saveAssignmentTaskTemplateValues(
                assignmentTaskId,
                fieldItem,
                removeHtmlTags(datalist[`displayValue_${fieldItem.fieldId}`]).length == 0 ? removeHtmlTags(datalist[`displayValue_${fieldItem.fieldId}`]) : datalist[`displayValue_${fieldItem.fieldId}`],
                {}
            );

            let tempArr = [...this.state.datalist];
            tempArr.map(item => {
                item.taskList.map(taskItem => {
                    if(taskItem.assignmentTaskId === assignmentTaskId){
                        item.isFocus = false;
                        item[`oldDisplayValue_${fieldItem.fieldId}`] = ""
                    }
                    return taskItem;
                });
                return item;
            });

            this.setState({
                datalist:tempArr
            });
        }
    };

    showQuillDialog = (assignmentTaskId, fieldItem) => {
        this.setState({
            showFullScreenEditor:true,
            assignmentTaskId:assignmentTaskId,
            fieldItem:fieldItem
        });
    }

    handleQuillDialogCloseWithoutSaving = () => {
        this.setState({
            showQuillAlertDialog:true
        })
    }

    handleQuillDialogClose = (reloadFlag) => {
        if(reloadFlag === true){
            this.handleExecuteTextFieldBlur(this.state.assignmentTaskId,this.state.fieldItem)
        };
        this.setState({
            showFullScreenEditor:false,
            assignmentTaskId:"",
            fieldItem:""
        });
    }

    handleAlertDialogSaveButtonClick = () => {
        this.handleExecuteTextFieldBlur(this.state.assignmentTaskId,this.state.fieldItem);
        this.setState({
            assignmentTaskId:"",
            fieldItem:"",
            showQuillAlertDialog:false,
            showFullScreenEditor:false
        });
    }

    handleAlertDialogCloseButtonClick = () => {
        this.setState({
            assignmentTaskId:"",
            fieldItem:"",
            showQuillAlertDialog:false,
            showFullScreenEditor:false
        });
    }

    getExecTaskDisplayTableData = (assignmentTaskId,fieldItem,assignmentTaskAllowEdit,taskId,taskIsFocus,executeIsFocus) => {
        switch (fieldItem.fieldType) { 
            case TemplateConstants.FIELD_TYPE_TEXT:
                return (
                    <td style={{height:"40%"}} className={assignmentTaskAllowEdit === "Y" ? "template-text": "template-text template-text-disabled"}>

                        {/* <div
                            contenteditable={assignmentTaskAllowEdit === "Y" ? "true" : "false"}
                            onBlur={this.handleExecuteTextField.bind(
                                this,
                                assignmentTaskId,
                                fieldItem
                            )}
                            className="exec-task-text-field"
                        >
                            {this.getExecTableDataDisplayValue(assignmentTaskId, fieldItem)}
                        </div> */}
                        {(taskIsFocus && executeIsFocus) &&
                                assignmentTaskAllowEdit === "Y" && 
                            <span class="material-icons" onClick={this.showQuillDialog.bind(this,assignmentTaskId,fieldItem)}>open_in_new</span>
                        }
                        <ReactQuill
					    	value={this.getExecTableDataDisplayValue(assignmentTaskId,fieldItem)} 
					    	onChange={this.handleChangeExecute.bind(this, assignmentTaskId, fieldItem)}
					    	className={(taskIsFocus && executeIsFocus) ?
                                (assignmentTaskAllowEdit === "Y"?"two-lines-focus my-1":"two-lines my-1"):"two-lines my-1"}
					    	modules={this.modules}
					    	onBlur={this.handleExecuteTextFieldBlur.bind(this,assignmentTaskId,fieldItem)}
					    	onFocus={this.onCustomExecuteTextFieldFocus.bind(this,assignmentTaskId,fieldItem)}
					    	readOnly={assignmentTaskAllowEdit==="Y"?false:true}
					    />
                    </td>
                );
            case TemplateConstants.FIELD_TYPE_DATE:
                let displayLabelDate = this.getExecTableDataDisplayValue(
                    assignmentTaskId,
                    fieldItem
                );
                return (
                    <td>
                        <DatePickerComponent
                            templateItemIndex={assignmentTaskId}
                            fieldItem={fieldItem}
                            onDateChange={this.onExecuteDateFieldChange}
                            placeholderValue={"Enter " + fieldItem.fieldName}
                            fieldValue={
                                !isEmptyVariable(displayLabelDate) ? displayLabelDate : ""
                            }
                            isDisabled={assignmentTaskAllowEdit === "Y" ? "N" : "Y"}
                        />
                    </td>
                );
            case TemplateConstants.FIELD_TYPE_SINGLE_SELECT:
                let displayLabel = this.getExecTableDataDisplayValue(
                    assignmentTaskId,
                    fieldItem
                );
                let displayLabelNR = this.getExecTableDataDisplayValueNR(
                    assignmentTaskId,
                    fieldItem
                );
                let newFieldItem = {
                    fieldValue: displayLabel,
                    fieldValueNR: displayLabelNR,
                    ...fieldItem,
                };
                if(!isEmptyVariable(this.state.selectFilterValue.fieldId) && fieldItem.fieldId===this.state.selectFilterValue.fieldId){
                    let value = this.state.previousAssignmentTemplateData.filter(e => taskId == e.taskId).map(e => e.value)[0] || "-";
                    return (
                        <>
                            <td
                                onClick={this.handleSelectDropdownDialogShow.bind(
                                    this,
                                    newFieldItem,
                                    assignmentTaskId,
                                    assignmentTaskAllowEdit
                                )}
                            >
                                {displayLabelNR == "Y"?"NA":displayLabel}
                            </td>
                            <td>{value}</td>
                        </>
                    );
                }else{
                    return (
                        <td
                            onClick={this.handleSelectDropdownDialogShow.bind(
                                this,
                                newFieldItem,
                                assignmentTaskId,
                                assignmentTaskAllowEdit
                            )}
                        >
                            {displayLabelNR == "Y"?"NA":displayLabel}
                        </td>
                    );
                }
            case TemplateConstants.FIELD_TYPE_MULTI_SELECT:
            case TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI:
                let displayLabelMulti = this.getExecTableDataDisplayValue(
                    assignmentTaskId,
                    fieldItem
                );
                let displayLabelMulitArr = displayLabelMulti.split(", ");
                let displayLabelMultiNR = this.getExecTableDataDisplayValueNR(
                    assignmentTaskId,
                    fieldItem
                );

                let newFieldItemMulti = {
                    fieldValue: displayLabelMulitArr,
                    fieldValueNR: displayLabelMultiNR,
                    ...fieldItem,
                };
                if(!isEmptyVariable(this.state.selectFilterValue.fieldId) && fieldItem.fieldId===this.state.selectFilterValue.fieldId){
                    let value = this.state.previousAssignmentTemplateData.filter(e => taskId == e.taskId).map(e => e.value)[0] || "";
                    return (
                        <>
                            <td
                                onClick={this.handleSelectDropdownDialogShow.bind(
                                    this,
                                    newFieldItemMulti,
                                    assignmentTaskId,
                                    assignmentTaskAllowEdit
                                )}
                            >
                                {displayLabelMultiNR == "Y"?"NA":displayLabelMulti}
                            </td>
                            <td>{!isEmptyVariable(value) ? isJsonString(value) && JSON.parse(value).join(", ") : "-"}</td>
                        </>
                    );
                }else{
                    return (
                        <td onClick={this.handleSelectDropdownDialogShow.bind(
                            this,
                            newFieldItemMulti,
                            assignmentTaskId,
                            assignmentTaskAllowEdit
                            )}
                        >
                            {displayLabelMultiNR == "Y"?"NA":displayLabelMulti}
                        </td>
                    );
                }
            case TemplateConstants.FIELD_TYPE_TABLE:
                let parsedJsonTableObj = this.getExecTableDataDisplayValue(
                    assignmentTaskId,
                    fieldItem
                );
                let displayLabelTableNR = this.getExecTableDataDisplayValueNR(
                    assignmentTaskId,
                    fieldItem
                );

                let newFieldItemTable = {
                    ...fieldItem,
                    fieldValue: parsedJsonTableObj,
                    fieldValueNR: displayLabelTableNR,
                };
                return (
                    <td
                        onClick={this.handleExecTableDialogShow.bind(
                            this,
                            newFieldItemTable,
                            assignmentTaskId,
                            assignmentTaskAllowEdit
                        )}
                    >
                        {
                            displayLabelTableNR == "Y"
                            ?"NA"
                            :(isEmptyVariable(parsedJsonTableObj) ? "-" : this.getAssignmentTaskExecTable(parsedJsonTableObj))
                        }
                        {/* {isEmptyVariable(parsedJsonTableObj) ? "-" : this.getAssignmentTaskExecTable(parsedJsonTableObj)} */}
                    </td>
                );
        }
    };

    onExecuteDateFieldChange = (dateValue, assignmentTaskId, fieldItem) => {
        this.saveAssignmentTaskTemplateValues(assignmentTaskId,fieldItem,dateValue,{});
    };

    getFirstRowItemValue = (item,idx) =>{
        let tempFirstRowList =["yes / no with disable","yes / no / na with disable","yes / no","yes / no / na","textfield"]
        if(idx==0)
        {
            if(!tempFirstRowList.includes(item.toLocaleLowerCase()) )
            {
                return item
            }
            
            else
            {
                return ""
            }
            
        }
        else if(idx!=0){
            return item
        }
       
    }

    getAssignmentTaskExecTable = (parsedJsonTableObj) => {
        return <table className="my-2">
            {
                Object.values(parsedJsonTableObj.tableArray).map((item, idx) => {
                    return <tr>
                        <td style={{ fontSize: 10, minwidth: "300px", Height: "22px" }}>{item[0]}</td>
                        <td style={{ fontSize: 10, minwidth: "300px", Height: "22px" }}>{this.getFirstRowItemValue(item[1],idx)}</td>
                    </tr>
                })
            }
        </table>
    }

    // handleExecuteTextField = (assignmentTaskId, fieldItem,e) => {
    //     const value = e.currentTarget.textContent;
    //     let currentRemark = this.getExecTableDataDisplayValue(
    //         assignmentTaskId,
    //         fieldItem
    //     );

    //     if (currentRemark !== value) {
    //         this.saveAssignmentTaskTemplateValues(
    //             assignmentTaskId,
    //             fieldItem,
    //             value,
    //             {}
    //         );
    //     }
    // };

    handleSelectDropdownDialogShow = (item, index, assignmentTaskAllowEdit) => {
        let templateFieldItem = { ...item };
        if (item.isFromRiskMaster) {
            templateFieldItem.dropdownValues = this.state.businessRisk;
        }
        this.setState({
            showSelectDropdownDialog: true,
            templateFieldItem: templateFieldItem,
            deleteReactivateId: index,
            assignmentTaskAllowEdit: assignmentTaskAllowEdit,
        });
    };

    handleSelectDropdownDialogClose = () => {
        this.setState({
            showSelectDropdownDialog: false,
            templateFieldItem: {},
            deleteReactivateId: "",
            assignmentTaskAllowEdit: "N",
        });
    };

    handleExecTableDialogShow = (item,assignmentTaskId,assignmentTaskAllowEdit) => {
        let templateFieldItem = { ...item };
        this.setState({
            showExecTableDialog: true,
            templateFieldItem: templateFieldItem,
            deleteReactivateId: assignmentTaskId,
            assignmentTaskAllowEdit: assignmentTaskAllowEdit,
        });
    };

    handleExecTableDialogClose = () => {
        this.setState({
            showExecTableDialog: false,
            templateFieldItem: {},
            deleteReactivateId: "",
            assignmentTaskAllowEdit: "N",
        });
    };

    saveTableValues = (tableObj, valueNR) => {
        let removeData = false;

        if (tableObj.isFirstRowTypeDisable === "Y") {
            Object.values(tableObj.tableArray).map((item, idx) => {

                if (idx === 0) {
                    if (item[1].toLocaleLowerCase() === "no" || item[1].toLocaleLowerCase() === "na") {
                        removeData = true
                    }
                }
                else if (removeData) {
                    item[1] = ""
                }
            })
        }

        const resetStateValues = {
            showExecTableDialog: false,
            templateFieldItem: {},
            deleteReactivateId: "",
            assignmentTaskAllowEdit: "N",
        };
        this.saveAssignmentTaskTemplateValues(
            this.state.deleteReactivateId,
            this.state.templateFieldItem,
            JSON.stringify(tableObj),
            resetStateValues,
            valueNR
        );
    };

    dialogSelectedValues = (selectedValObj, assignmentTaskId, valueNR) => {
        let selectedValuesStr = "";

        if(valueNR === "Y"){

        }else{
            if (selectedValObj.fieldType === TemplateConstants.FIELD_TYPE_SINGLE_SELECT) {
                selectedValuesStr = selectedValObj.selectedValues[0];
            } else if (selectedValObj.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT) {
                if (!isEmptyArray(selectedValObj.selectedValues)) {
                    selectedValuesStr = JSON.stringify(selectedValObj.selectedValues);
                }
            }
        }
        
        let resetStateValues = {
            showSelectDropdownDialog: false,
            templateFieldItem: {},
            deleteReactivateId: "",
            assignmentTaskAllowEdit: "N",
        };
        this.saveAssignmentTaskTemplateValues(
            assignmentTaskId,
            this.state.templateFieldItem,
            selectedValuesStr,
            resetStateValues,
            valueNR
        );
    };

    // This function show the Assign Function modal and display task based on click item
    handleAssignFunction = (event) => {
        event.preventDefault();
        let tempDataList = this.state.filterDataList.length > 0 ? 
        this.state.filterDataList : 
        this.state.datalist;

        if(tempDataList.length === tempDataList.filter(item => isEmptyArray(item.taskList)).length){
            this.setState({
                showAlertDialogInfo:true,
                alertDialogMessageInfo:"No task to link with function"
            })
        }else{
            tempDataList.map(data => {
                data.taskList.map(item => {
                    data.isSelected = false;
                    item.isTaskCheckboxSelected = false;
                })
            })
            
            this.setState({
                showAssignFunc:true,
                taskDataList:tempDataList
            })
        }
    }

    // This function handle the close event of Assign Function Modal
    handleAssignFuncDialogClose = () => {
        this.setState({
            showAssignFunc:false,
            taskDataList:[]
        },)
    }
    /*################################## EXEC TASK RELATED ENDS#################################*/

    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader: true,
        });

        Promise.all([
            fetch(Constants.GetExecutetasks, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.state.assignmentId,
                    empStatus:"Active"
                }),
            }),
            fetch(Constants.GetUserMenu, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                }),
            }),
            fetch(Constants.GetAssignmentsDetails, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentId: this.state.assignmentId,
                }),
            }),
            fetch(Constants.GetOtherMasters, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    masterType: "Risk",
                    pageNo: 1,
                    resultsize: 100,
                    status: "Active",
                }),
            })
        ])
            .then(([taskRes, menuRes, assignmentRes, otherMasterRes]) => {
                return Promise.all([
                    taskRes.json(),
                    menuRes.json(),
                    assignmentRes.json(),
                    otherMasterRes.json()
                ]);
            })
            .then(([taskRes, menuRes, assignmentRes, otherMasterRes]) => {

                let allDatalistTemp = [];
                let myDatalistTemp = [];
                let selectFilterData = [];
                let tempSelectFilterData = [];
                let totalCountTemp = 0;
                let menusTemp = {};
                let assignmentStatus = "";
                let assignmentArea = "";
                let assignmentDetails = {};
                let userTemp = {};
                let tempCompanyDetials = taskRes.data?.company ?? {};
                let tempUnitDetials = taskRes.data?.units;
                const assignTaskRes = {...taskRes.data?.assignTaskMasterData};
                let companyTemp = {};
                let allSettings = [];
                let isIndustryFlag = false;
                let businessRisks = [];
                let businessRiskArr = [];

                if (
                    otherMasterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    otherMasterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                ) {
                    localStorage.clear();
                    window.location = "/";
                } else if (otherMasterRes.responseCode === Constants.CODE_SUCCESS) {
                    let businessRiskObj = {
                        businessRiskId: -1,
                        shortName: "",
                        name: "Other",
                        description: "",
                    };
                    businessRisks = otherMasterRes.data.result;
                    businessRisks.push(businessRiskObj);

                    businessRisks.forEach((item) => {
                        item.value = item.name;
                        businessRiskArr.push(item);
                    });
                } else {
                }

                //Since we need assignment area lets check this first
                let selectedTaskTemplateFields = [];
                let execTaskTemplateFields = [];
                let taskTemplateValuesMap = {};
                let executionTemplateValuesMap = {};
                let executionTemplateFixedFields = {};
                let assignmentRiskMatrix = [];
                let valueAdditions = [];
                let likelihoodRiskMatrix = [];


                if (
                    assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                ) {
                    localStorage.clear();
                    window.location = "/";
                } else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
                    assignmentStatus =
                        assignmentRes.data.assignmentDetails.assignmentStatus;
                    assignmentArea = assignmentRes.data.assignmentDetails.assignmentArea;
                    assignmentDetails = assignmentRes.data.assignmentDetails;


                    //TASK TEMPLATE RELATED STUFFS
                    taskTemplateValuesMap = assignmentRes.data.taskTemplateValuesMap;
                    let selectedTaskTemplateFieldsStr =
                        assignmentRes.data.executionTemplateFields?.taskTemplateFields; //Template field IDs
                    if (
                        !isEmptyVariable(selectedTaskTemplateFieldsStr) &&
                        isJsonString(selectedTaskTemplateFieldsStr)
                    ) {
                        selectedTaskTemplateFields = JSON.parse(
                            selectedTaskTemplateFieldsStr
                        );
                    }

                    //EXEC TASK RELATED STUFFS
                    executionTemplateValuesMap =
                        assignmentRes.data.executionTemplateValuesMap;

                    let execTaskTemplateFieldsStr =
                        assignmentRes.data.executionTemplateFields?.templateFields;
                    if (
                        !isEmptyVariable(execTaskTemplateFieldsStr) &&
                        isJsonString(execTaskTemplateFieldsStr)
                    ) {
                        execTaskTemplateFields = JSON.parse(execTaskTemplateFieldsStr);
                    }


                    //EXEC TASK FIXED FIELDS
                    let fixedTemplateFieldsStr =
                        assignmentRes.data.executionTemplateFields?.fixedTemplateFields;
                    if (
                        !isEmptyVariable(fixedTemplateFieldsStr) &&
                        isJsonString(fixedTemplateFieldsStr)
                    ) {
                        executionTemplateFixedFields = JSON.parse(fixedTemplateFieldsStr);
                    }

                    assignmentRiskMatrix = assignmentRes.data.riskLevels;
                    valueAdditions = assignmentRes.data.valueAdditions;
                    likelihoodRiskMatrix = assignmentRes.data.likelihoodLevels;
                } else {
                }

                let maxRank = 0;
                let showChecklistScores = false;
                let overallChecklistScoreObj = {};
                let assignmentChecklistObj = {};

                let matrixandMyDetails = {};
                let annexureList = [];
                let isRisk = false;

                if (
                    taskRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    taskRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                ) {
                    localStorage.clear();
                    window.location = "/";
                } else if (taskRes.responseCode === Constants.CODE_SUCCESS) {
                    allDatalistTemp = taskRes.data.assignedTasks;
                    let allDataList = JSON.parse(JSON.stringify(allDatalistTemp));
                    annexureList = taskRes.data.annexureList;
                    selectFilterData = taskRes.data.selectFilterData;

                    let departmentId = taskRes.data.assignmentDetails.departmentId;
                    this.getDownloadReportList(departmentId);
                    //if assignment area is checklist we may need to show scores in certain cases
                    if (assignmentArea === "CheckList") {
                        
                        assignmentChecklistObj = taskRes.data.assignmentCheckList;
                        if(!isEmptyArray(assignmentChecklistObj))
                        {
                            overallChecklistScoreObj = taskRes.data.assignmentScore;
                            showChecklistScores = getCheckListScoresFlag(
                                assignmentChecklistObj.checkListType
                            );
                            if (showChecklistScores) {
                                maxRank = assignmentChecklistObj.maxRank;
                            }
                        }
                        
                    }

                    //show only Selected User's/My tasks from the tasks list
                    myDatalistTemp = getOnlyMyTasks(allDataList, assignmentArea, maxRank);

                    //need list of users/reviewers
                    //Sample:- 31:Suhas L5:Product Manager,39:Sumanth S:Product Manager
                    let assignmentMatrixObj = taskRes.data.assignmentMatrix;

                    //Get memberslist & Reviewers list for dropdown
                    matrixandMyDetails = getMatrixandMyDetails(assignmentMatrixObj);
                } else if (taskRes.responseCode === Constants.CODE_ASSIGNMENT_ACCESS_ERROR) {
					this.setState({
						showAlertDialogInfo: true,
						alertDialogMessageInfo: taskRes.responseMessage,
						responseCode: taskRes.responseCode
					})
				} else {
                }

                if (
                    menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                ) {
                    localStorage.clear();
                    window.location = "/";
                } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                    menusTemp = menuRes.data.menus;
                    userTemp = menuRes.data.user;
                    companyTemp = menuRes.data.company;
                    allSettings = menuRes.data.allSettings;
                    if(allSettings.find(item => item.settingDisplayColumn === Constants.SCREEN_INDUSTRY).settingColumnEnabled === "Y"){
                        isIndustryFlag = true;
                    }
                    isRisk = allSettings.some(e => e.settingColumn.includes(Constants.DISABLEDRISK) && e.settingColumnEnabled === "N" && 
                        e.departmentId === assignmentDetails.departmentId && e.assignmentTypeId === assignmentDetails.assignmentTypeId);

                } else {
                    menusTemp = {};
                }
                let menusTemp2 = GetOrderedMasterTabMenuExecute(
                    menusTemp,
                    assignmentDetails.observationTemplateId
                );

                //Lets check few things that are needed for checklist based assignment
                let showWeight = false;
                let showRanking = false;
                let showYesNoDD = false;
                let minRank = 0;
                let rankingArray = getRankingArray(minRank, maxRank);

                if (!isEmptyArray(allDatalistTemp) && assignmentArea === "CheckList") {
                    let firstItem = allDatalistTemp[0];
                    minRank = firstItem.taskList[0].minRank;

                    if (!isEmptyArray(firstItem.taskList)) {
                        if (firstItem.taskList[0].checkListType === "WR") {
                            showWeight = true;
                            showRanking = true;
                        } else if (firstItem.taskList[0].checkListType === "R") {
                            showRanking = true;
                        } else if (firstItem.taskList[0].checkListType === "Y/N") {
                            showYesNoDD = true;
                        }
                    }
                }

                let tempFilterArray = [
                    {
                        label: "All",
                        isSelected: true,
                    },
                ];

                tempSelectFilterData = [
                    {
                        label:"Clear",
                        isSelected:false
                    }
                ]

                Object.values(myDatalistTemp).map((item) => {
                    let uid = 0;
                    Object.values(item.taskList).map((task) => {
                        uid = task.userId;
                    });

                    if (uid === userDetails.userId) {
                        tempFilterArray.push({
                            label: assignmentArea === "Process" ? item.processName : assignmentArea === "Function" ? item.functionName : item.heading,
                            isSelected: false,
                        });
                    }
                });

                let tempAuditorList=[];
                let tempViewerList=[];



                if (assignTaskRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || assignTaskRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (assignTaskRes.responseCode === Constants.CODE_SUCCESS) {

                     Object.values(assignTaskRes.data.assignmentReviewMatrix).filter(e=>e.status=="Active")
                     .map((item)=>{
                        tempAuditorList.push({
                            "userName":item.userName,
                            "userId":parseInt(item.userId),
                            "level":item.level
                        })
                    })
                        
                    Object.values(assignTaskRes.data.assignmentMemberMatrix).filter(e=>e.status=="Active")
                    .map((item)=>{
                        tempAuditorList.push({
                            "userName":item.userName,
                            "userId":parseInt(item.userId),
                            "level":0
                        })
                    })
                    
                    Object.values(assignTaskRes.data.assignmentViewMatrix).filter(e=>e.status=="Active")
                    .map((item)=>{
                        tempViewerList.push(item.userName)
                    })

                }

                //Set filter for single select or Multi Select
                Object.values(selectFilterData).forEach(item => {
                    tempSelectFilterData.push({
                        label: item.fieldName,
                        isSelected: false
                    })
                })


                this.setState({
                    componentDidMountFlag: true,
                    showLoader: false,
                    datalist: myDatalistTemp,
                    oldDatalist: myDatalistTemp,
                    allDatalist: allDatalistTemp,
                    totalCount: totalCountTemp,

                    selectedTaskTemplateFields: selectedTaskTemplateFields,
                    taskTemplateValuesMap: taskTemplateValuesMap,
                    execTaskTemplateFields: execTaskTemplateFields,
                    executionTemplateValuesMap: executionTemplateValuesMap,
                    executionTemplateFixedFields: executionTemplateFixedFields,
                    assignmentRiskMatrix: assignmentRiskMatrix,
                    valueAdditions: valueAdditions,

                    menus: menusTemp2,
                    company: companyTemp,
                    isIndustryFlag: isIndustryFlag,
                    assignmentStatus: assignmentStatus,
                    assignmentArea: assignmentArea,
                    assignmentDetails: assignmentDetails,
                    assignmentMatrix: matrixandMyDetails.assignmentMatrix,
                    selectedResourceUserName: matrixandMyDetails.selectedResourceUserName,
                    selectedResourceId: userDetails.userId,
                    myPosition: matrixandMyDetails.myPosition,

                    showWeight: showWeight,
                    showRanking: showRanking,
                    showYesNoDD: showYesNoDD,
                    checklistMaxRank: maxRank,
                    showChecklistScores: showChecklistScores,
                    overallChecklistScoreObj: overallChecklistScoreObj,
                    rankingArray: rankingArray,
                    businessRisk: businessRiskArr,
                    user: userTemp,
                    FilterDropDownArr: tempFilterArray,
                    selectFilterData: selectFilterData,
                    selectFilterDropDownArr:tempSelectFilterData,

                    companyDetials: tempCompanyDetials,
                    unitDetials: tempUnitDetials,
                    allAuditorList:tempAuditorList,
                    allViewList:tempViewerList,

                    likelihoodRiskMatrix: likelihoodRiskMatrix,
                    allAnnexureList:annexureList,
                    allSettings:allSettings,
                    isRisk:isRisk
                });
            });

    };

    getDownloadReportList = (departmentId) => {
        fetch(Constants.GetDownloadReportList, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                departmentId: departmentId,
            }),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                let downloadReportData = data.data;
                let downloadButtonArr = [];
                if(!isEmptyArray(downloadReportData)){
                    downloadReportData.forEach((item) => {
                        if(item.screenName == Constants.SCREEN_EXECUTE){
                            downloadButtonArr.push({
                                label: item.displayReportName,
                                isSelected: false
                            })
                        }
                    })
                }

                this.setState({
                    downloadButtonArr:downloadButtonArr,
                    downloadReportData:downloadReportData
                })
            }
        })
    }

    downloadReportItemOnClick = (selectItem)=>{
        let downloadReportData = this.state.downloadReportData;
        const selectedReport = downloadReportData.find(item => item.displayReportName == selectItem.label)

        this.setState({
            showLoader:true
        })

        fetch(Constants.GetClientReport, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams({
              email: userDetails.email,
              accessToken: userDetails.accessToken,
              assignmentId:this.state.assignmentId,
              clientDownloadReportMapId:selectedReport.clientDownloadReportMapId
            }),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                let documentPath = data.data.documentPath;
                this.downloadReport(documentPath);
                this.setState({
                    showLoader:false
                });
                
            }else{
                this.setState({
                    showLoader:false
                });
            }
        })
    }

    downloadReport = (documentPath) => {
        fetch(Constants.API_SERVER_PATH+"public/"+documentPath)
        .then(response => { return response.blob(); } )
        .then(blob =>
        {
            let fileName = getOnlyFileNameFromPath(documentPath);
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    getProcessGroupingTaskList = (Datalist, assignmentArea) => {
        if (assignmentArea === "Function") {
            let tempProcessList = [];
            let processName = [];
            let tempAllDataList = Object.values(Datalist);
            Object.values(Datalist).map((item) => {
                Object.values(item.taskList).map((task) => {
                    if (!processName.includes(task.processNameList)) {
                        processName.push(task.processNameList)
                    }
                })
            })

            tempAllDataList.map((item) => {
                let tempTaskList = [];
                tempProcessList = [];
                let tempItem = item;
                let tempProcessId = "";
                processName.map((process) => {

                    tempTaskList = [];

                    Object.values(item.taskList).map((task) => {
                        tempProcessId = task.processIdList
                        if (task.processNameList === process) {
                            tempTaskList.push(task)
                        }

                    })

                    tempProcessList.push({
                        processName: process,
                        functionName: item.functionName,
                        processId: tempProcessId,
                        taskList: tempTaskList
                    })
                    tempItem["processTaskList"] = tempProcessList
                })

            })
            return tempAllDataList
        }
        else {
            return Datalist
        }
    }

    GetExecuteTasksList = () => {
        this.setState({
            showLoader: true,
        });

        fetch(Constants.GetExecutetasks, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                assignmentId: this.state.assignmentId,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                ) {
                    localStorage.clear();
                    window.location = "/";
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    let assignmentChecklistObj = {};
                    let overallChecklistScoreObj = {};

                    let allDatalistTemp = data.data.assignedTasks;
                    let allDataList = JSON.parse(JSON.stringify(allDatalistTemp));

                    let maxRank = 0;
                    if (this.state.assignmentArea === "CheckList") {
                        assignmentChecklistObj = data.data.assignmentCheckList;
                        overallChecklistScoreObj = data.data.assignmentScore;

                        if (
                            assignmentChecklistObj.checkListType === "Y/N" ||
                            assignmentChecklistObj.checkListType === "WR" ||
                            assignmentChecklistObj.checkListType === "R"
                        ) {
                            maxRank = assignmentChecklistObj.maxRank;
                        }
                    }

                    //show only Selected User's/My tasks
                    let myDatalistTemp = allDataList.map((process) => {
                        let taskList = [];
                        let maxScore = 0;
                        let totalScore = 0;
                        process.taskList.forEach((task) => {
                            if (task.userId + "" === this.state.selectedResourceId + "") {
                                taskList.push(task);

                                //Now if it is checklist, then based on the type of checklist we need to assign scores
                                if (this.state.assignmentArea === "CheckList") {
                                    let weight = isEmptyVariable(task.weight) ? 1 : task.weight;
                                    let rank =
                                        isEmptyVariable(task.rank) || task.rank === -1
                                            ? 0
                                            : task.rank;

                                    let maxRankNew = task.rank === -1 ? 0 : maxRank;
                                    maxScore += weight * maxRankNew;
                                    totalScore += weight * rank;
                                }
                            }
                        });
                        process.taskList = taskList;
                        process.maxScore = maxScore;
                        process.totalScore = totalScore;

                        return process;
                    });

                    this.setState({
                        showLoader: false,
                        datalist: myDatalistTemp,
                        allDatalist: allDatalistTemp,
                        overallChecklistScoreObj: overallChecklistScoreObj,
                    },()=>{
                        this.completeOngoingDropdownClick({label:this.state.completeOngingFilterPlaceholder},this.state.FilterPlaceholder);
                    });
                } else if (data.responseCode === Constants.CODE_ASSIGNMENT_ACCESS_ERROR) {
					this.setState({
						showAlertDialogInfo: true,
						alertDialogMessageInfo: data.responseMessage,
						responseCode: data.responseCode
					})
				} else {
                    this.setState({
                        datalist: [],
                        showLoader: false,
                    });
                }
            });
    };

    GetAssignmentDetails = (resetStateValues) => {
        this.setState({
            showLoader: true,
        });

        fetch(Constants.GetAssignmentsDetails, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                assignmentId: this.state.assignmentId,
            }),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                ) {
                    localStorage.clear();
                    window.location = "/";
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    let executionTemplateValuesMap = data.data.executionTemplateValuesMap;
                    let executionTemplateFixedFields = {};
                    let fixedTemplateFieldsStr =
                        data.data.executionTemplateFields?.fixedTemplateFields;
                    if (
                        !isEmptyVariable(fixedTemplateFieldsStr) &&
                        isJsonString(fixedTemplateFieldsStr)
                    ) {
                        executionTemplateFixedFields = JSON.parse(fixedTemplateFieldsStr);
                    }
                    this.setState({
                        executionTemplateValuesMap: executionTemplateValuesMap,
                        executionTemplateFixedFields: executionTemplateFixedFields,
                        showLoader: false,
                        ...resetStateValues,
                    });
                } else {
                }
            });
    };

    handleRecordOperation = () => {
        let url = "";
        let postParam = {
            email: userDetails.email,
            accessToken: userDetails.accessToken,
        };
        let callApi = true;

        if (this.state.operationType === "Mark as Completed") {
            url = Constants.MarkTaskComplete;
            postParam.assignmentTaskId = "[" + this.state.deleteReactivateId + "]";
            postParam.assignmentId = this.state.assignmentId;
        } else if (this.state.operationType === "Reopen Task") {
            url = Constants.ReopenTask;
            postParam.assignmentTaskId = this.state.deleteReactivateId;
            // add remarks
            if (!isEmptyVariable(this.state.remarks)) {
                postParam.remark = this.state.remarks;
            } else {
                postParam.remark = "";
            }
        } else if (this.state.operationType === "Show Guidance") {
            callApi = false;
            this.setState({
                deleteReactivateId: "",
                deleteReactivateMasterType: "",
                operationType: "",
                alertDialogHeading: "",
                proceedBtnLabel: "",
                alertDialogMessage: "",
                showAlertDialog: false,

                remarks: "",
            });
        } else if (this.state.operationType === "Submit For Review") {
            url = Constants.SubmitAssignmentTaskForReview;
            postParam.assignmentTaskId = "[" + this.state.deleteReactivateId + "]";
            postParam.assignmentId = this.state.assignmentId;
        } else if (this.state.operationType === "Review Remarks") {
            callApi = false;
            this.setState({
                operationType: "",
                alertDialogHeading: "",
                proceedBtnLabel: "",
                alertDialogMessage: "",
                showAlertDialog: false,
            });
        }

        if (callApi) {
            this.setState({
                showBtnLoader: true,
            });

            fetch(url, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams(postParam),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (
                        data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                        data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                    ) {
                        localStorage.clear();
                        window.location = "/";
                    } else if (data.responseCode === Constants.CODE_SUCCESS) {
                        this.setState(
                            {
                                deleteReactivateId: "",
                                deleteReactivateMasterType: "",
                                operationType: "",
                                alertDialogHeading: "",
                                proceedBtnLabel: "",
                                alertDialogMessage: "",
                                showAlertDialog: false,
                                remarks: "",
                                showBtnLoader: false
                            },
                            () => {
                                this.hideExecutionTasks();
                                this.GetExecuteTasksList();
                            }
                        );
                    } else {
                        this.setState({
                            showBtnLoader: false,
                            deleteReactivateId: "",
                            deleteReactivateMasterType: "",
                            operationType: "",
                            alertDialogHeading: "",
                            proceedBtnLabel: "",
                            alertDialogMessage: "",
                            showAlertDialog: false,
                            showAlertDialogInfo: true,
                            alertDialogMessageInfo: data.responseMessage,
                            remarks: "",
                        });
                    }
                });
        }
    };

    handleSaveAssignmentTaskProperties = (assignmentTaskId,remark,fieldName) => {
        let postParam = {
            email: userDetails.email,
            accessToken: userDetails.accessToken,
            assignmentId: this.state.assignmentId,
            assignmentTaskId: assignmentTaskId,
            [fieldName]: remark,
        };
        if (fieldName === "remark") {
            postParam.type = "Remark";
        }

        fetch(Constants.SaveAssignmentTaskProperties, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams(postParam),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                ) {
                    localStorage.clear();
                    window.location = "/";
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    this.setState({}, () => {
                        this.GetExecuteTasksList();
                    });
                } else {
                }
            });
    };

    saveAssignmentTaskTemplateValues = (assignmentTaskId,fieldItem,value,resetStateValues,valueNR) => {
        let postObj = {
            email: userDetails.email,
            accessToken: userDetails.accessToken,
            assignmentTaskId: assignmentTaskId,
            fieldId: fieldItem.fieldId,
            [fieldItem.fieldId]: value,
        }

        if(valueNR === "Y"){
            postObj[fieldItem.fieldId+"_NR"] = "Y";
        }

        fetch(Constants.SaveAssignmentTaskTemplateValues, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams(postObj),
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                ) {
                    localStorage.clear();
                    window.location = "/";
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    this.GetAssignmentDetails(resetStateValues);
                } else {
                }
            });
    };

    getPreviousAssignmentTemplateData = (fieldId) => {
        this.setState({
            showLoader: true,
        });

        fetch(Constants.GetPreviousAssignmentTemplateData, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                assignmentId: this.state.assignmentId,
                fieldId: fieldId
            }),
        })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                let previousAssignmentTemplateData = data.data.previousAssignmentTemplateData;
                this.setState({
                    showLoader: false,
                    previousAssignmentTemplateData:previousAssignmentTemplateData
                })
            } else {
                this.setState({
                    showLoader: false,
                    selectFilterValue:"",
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage
                });
            }
        });
    };

    previousAssignmentData = (taskItem) => {
        fetch(Constants.PreviousAssignmentData,{
            method: "POST",
            mode: "cors",
            body: new URLSearchParams({
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                assignmentId: this.state.assignmentId,
                taskId:taskItem.taskId,
                assignmentTaskId:taskItem.assignmentTaskId
            }),
        }).then((response) => {
            return response.json();
        }).then((data)=>{
            if (
                data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                this.setState({
                    showPreviousAssignmentData:true,
                    assignmentExecuteFields:data.executeField,
                    previousAssignmentDataList:data.data
                })
                
            }
        })
    }
    /*******************API CALLS END HERE*******************/

    completeOngoingDropdownClick = (selectedItem, selectedIdx) => {
        let tempFilterDataArray = this.state.completeOngingFilterArr;
        let tempFilterDataList = [];
        let arr = this.getUserTasksFromUserId(this.state.selectedResourceId);

        if (selectedItem.label === 'All' && this.state.FilterPlaceholder === "All") {
            this.setState({
                filterDataList: [],
            })
        }

        else if (selectedItem.label === "Completed" && this.state.FilterPlaceholder === 'All') {

            let tempItem = [];

            Object.values(arr).map((item) => {
                let tempDataTaskList = []
                tempItem = item
                Object.values(item.taskList).map((task) => {
                    if (task.completionStatus === "Completed") {
                        tempDataTaskList.push(task)
                    }

                })
                item.taskList = tempDataTaskList
                tempFilterDataList.push(item)
            })
        }

        else if (selectedItem.label !== "Completed" && this.state.FilterPlaceholder === 'All') {
            let tempItem = [];
            Object.values(arr).map((item) => {
                let tempDataTaskList = []
                tempItem = item
                Object.values(item.taskList).map((task) => {
                    if (task.completionStatus !== "Completed") {
                        tempDataTaskList.push(task)
                    }

                })
                item.taskList = tempDataTaskList
                tempFilterDataList.push(item)
            })
        }

        else if (selectedItem.label === "Completed" && this.state.FilterPlaceholder !== 'All') {
            let tempItem = [];
            Object.values(arr).map((item) => {
                let tempDataTaskList = []
                tempItem = item
                Object.values(item.taskList).map((task) => {
                    if (task.completionStatus === "Completed" && (this.state.FilterPlaceholder === (this.state.assignmentArea === "Process" ? item.processName : this.state.assignmentArea === "Function" ? item.functionName : item.heading))) {
                        tempDataTaskList.push(task)
                    }
                })
                item.taskList = tempDataTaskList
                tempFilterDataList.push(item)
            })
        }

        else if (selectedItem.label === "Pending" && this.state.FilterPlaceholder !== 'All') {

            Object.values(arr).map((item) => {
                let tempDataTaskList = []
                Object.values(item.taskList).map((task) => {

                    if (task.completionStatus !== "Completed" && (this.state.FilterPlaceholder === (this.state.assignmentArea === "Process" ? item.processName : this.state.assignmentArea === "Function" ? item.functionName : item.heading))) {
                        tempDataTaskList.push(task)
                    }
                })
                item.taskList = tempDataTaskList
                tempFilterDataList.push(item)
            })
        }

        else if (selectedItem.label === "All" && this.state.FilterPlaceholder !== 'All') {
            let tempItem = [];
            Object.values(arr).map((item) => {
                let tempDataTaskList = []
                tempItem = item
                Object.values(item.taskList).map((task) => {

                    if (this.state.FilterPlaceholder === (this.state.assignmentArea === "Process" ? item.processName : this.state.assignmentArea === "Function" ? item.functionName : item.heading)) {
                        tempDataTaskList.push(task)
                    }
                })
                item.taskList = tempDataTaskList
                tempFilterDataList.push(item)
            })
        }

        tempFilterDataArray.map((item) => {
            if (item.label === selectedItem.label) {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        });
        this.setState({
            completeOngingFilterPlaceholder: selectedItem.label,
            filterDataList: tempFilterDataList,
            completeOngingFilterArr: tempFilterDataArray
        })

    }

    filterDropDownOnClick = (selectedItem) => {
        let tempFilterDataList = [];
        const executionTasksArr = this.getFilteredTasksFromExecutionTaskAction(this.state.executionTaskAction);
        let arr = !isEmptyVariable(this.state.executionTaskAction) ? executionTasksArr : this.getUserTasksFromUserId(this.state.selectedResourceId);
        let tempFilterDataArray = this.state.FilterDropDownArr;

        if (selectedItem.label === 'All' && this.state.completeOngingFilterPlaceholder === "All") {
            if (!isEmptyVariable(this.state.executionTaskAction)) {
                Object.values(arr).map((item) => {
                    let tempDataTaskList = [];
                    Object.values(item.taskList).map((task) => {
                        tempDataTaskList.push(task);
                    })
                    item.taskList = tempDataTaskList
                    tempFilterDataList.push(item)
                })
            }
            this.setState({
                filterDataList: tempFilterDataList,
            })
        }
        else if (selectedItem.label !== "All" && this.state.completeOngingFilterPlaceholder === "Completed") {
            let tempItem = [];
            Object.values(arr).map((item) => {
                let tempDataTaskList = []
                tempItem = item
                Object.values(item.taskList).map((task) => {
                    if (task.completionStatus === "Completed" && (selectedItem.label === (this.state.assignmentArea === "Process" ? item.processName : this.state.assignmentArea === "Function" ? item.functionName : item.heading))) {
                        tempDataTaskList.push(task)
                    }
                })
                item.taskList = tempDataTaskList
                tempFilterDataList.push(item)
            })
        }
        else if (selectedItem.label !== "All" && this.state.completeOngingFilterPlaceholder === "Pending") {
            let tempItem = [];
            Object.values(arr).map((item) => {
                let tempDataTaskList = []
                tempItem = item
                Object.values(item.taskList).map((task) => {

                    if (task.completionStatus !== "Completed" && (selectedItem.label === (this.state.assignmentArea === "Process" ? item.processName : this.state.assignmentArea === "Function" ? item.functionName : item.heading))) {
                        tempDataTaskList.push(task)
                    }
                })
                item.taskList = tempDataTaskList
                tempFilterDataList.push(item)
            })
        }
        else if (selectedItem.label !== "All" && this.state.completeOngingFilterPlaceholder === "All") {
            let tempItem = [];
            Object.values(arr).map((item) => {
                let tempDataTaskList = []
                tempItem = item
                Object.values(item.taskList).map((task) => {

                    if (selectedItem.label === (this.state.assignmentArea === "Process" ? item.processName : this.state.assignmentArea === "Function" ? item.functionName : item.heading)) {
                        tempDataTaskList.push(task)
                    }
                })
                item.taskList = tempDataTaskList
                tempFilterDataList.push(item)
            })
        }
        else if (selectedItem.label === "All" && this.state.completeOngingFilterPlaceholder === "Pending") {

            let tempItem = [];
            Object.values(arr).map((item) => {
                let tempDataTaskList = []
                tempItem = item
                Object.values(item.taskList).map((task) => {
                    if (task.completionStatus !== "Completed") {
                        tempDataTaskList.push(task)
                    }

                })
                item.taskList = tempDataTaskList
                tempFilterDataList.push(item)
            })
        }

        else if (selectedItem.label === "All" && this.state.completeOngingFilterPlaceholder === 'Completed') {

            let tempItem = [];

            Object.values(arr).map((item) => {
                let tempDataTaskList = []
                tempItem = item
                Object.values(item.taskList).map((task) => {
                    if (task.completionStatus === "Completed") {
                        tempDataTaskList.push(task)
                    }

                })
                item.taskList = tempDataTaskList
                tempFilterDataList.push(item)
            })
        }
        tempFilterDataArray.map((item) => {
            if (item.label === selectedItem.label) {
                item.isSelected = true;
            } else {
                item.isSelected = false;
            }
        });
        this.setState({
            FilterPlaceholder: selectedItem.label,
            filterDataList: tempFilterDataList,
            FilterDropDownArr: tempFilterDataArray
        })

    };

    selectFilterDropdownClick = (selectedItem) => {
        // If the selected item is "Clear", reset the filter state.
        if(selectedItem.label == "Clear"){
            let tempSelectFilterDataArray = this.state.selectFilterDropDownArr;
            tempSelectFilterDataArray.map((item) => {
                item.isSelected = false;
            })
            this.setState({
                selectFilterPlaceholder:"Previous",
                previousAssignmentTemplateData:[],
                selectFilterValue:{},
                selectFilterDropDownArr:tempSelectFilterDataArray
            })
        }else{
            let tempSelectFilterDataArray = this.state.selectFilterDropDownArr;
            let selectFilterData = this.state.selectFilterData;
            tempSelectFilterDataArray.map((item) => {
                if (item.label === selectedItem.label) {
                    item.isSelected = true;
                } else {
                    item.isSelected = false;
                }
            });

            selectFilterData.filter(item => {
                if(item.fieldName == selectedItem.label){
                    this.setState({
                        selectFilterValue:item
                    },() => {
                        this.getPreviousAssignmentTemplateData(item.fieldId);
                    })
                }
            })

            this.setState({
                selectFilterPlaceholder:selectedItem.label,
                selectFilterDropDownArr:tempSelectFilterDataArray,
            })
        }
    }

    getAllAnnexureList = (taskItem) => {
        let tempObj ={}; 
        tempObj.assignmentTaskId = taskItem.assignmentTaskId;
       let  url = Constants.GetAssignmentTaskAnnexures;
        return fetch(url, {
            method: "POST",
            mode: "cors",
            body: new URLSearchParams({
              email: userDetails.email,
              accessToken: userDetails.accessToken,
              assignmentTaskId:taskItem.assignmentTaskId,
            }),
          })
          .then((response) => {
            return response.json();
          }).then((data) => {
            if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                localStorage.clear();
                window.location = "/";
            } else if (data.responseCode === Constants.CODE_SUCCESS) {
                tempObj.annexureList = data.data.annexures
                this.state.allAnnexureList.push(tempObj)
            } 
          })
    }

    // Handles the click event of the filter button.
    showFilter = () => {
        // If the filter has been saved, just show the filter.
        if(this.state.isSave){
            this.setState({
                showFilter:true
            })
        }else{
            // Filter the template fields to get Single-select, Multi-select and Dropdown Multiselect filters.
            let execTaskTemplateFields = [...this.state.execTaskTemplateFields];
            let singleSelectFilter = execTaskTemplateFields.filter(field => 
                field.fieldType === TemplateConstants.FIELD_TYPE_SINGLE_SELECT ||
                field.fieldType === TemplateConstants.FIELD_TYPE_MULTI_SELECT ||
                field.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI
            ).filter(item => !isEmptyArray(item.dropdownValues));
    
            // Add blank and not applicable options to each filter.
            const updateSingleSelectFilter = singleSelectFilter.map(item => ({
                ...item,
                dropdownValues: [
                    ...item.dropdownValues,
                    {
                        "value": "NA",
                        "description": "Not Applicable",
                        "valueNdesc": ""
                    },
                    {
                        "value": "Blank",
                        "description": "Blank",
                        "valueNdesc": ""
                    }
                ]
            }));

            const list = [...this.state.datalist];
            let weightArr = []
            let weight = [];
            let taskList = [];
            if(!isEmptyArray(list)){
                taskList = list.filter(item => !isEmptyArray(item.taskList))
                weightArr = list.flatMap(item => item.taskList.filter(taskItem => !isEmptyVariable(taskItem?.weight))).map(item => item.weight);
                weightArr = [...new Set(weightArr)].sort((a, b) => a - b)
            }

            weightArr.map(item => {
                weight.push({value:item,isSelected:false})
            })

            let yesNoRankArr = [];
            let rankingArr = []
            let assignmentRiskMatrix = [];

            if(this.state.showYesNoDD){
                yesNoRankArr = Constants.yesNoRankArr;
                yesNoRankArr = yesNoRankArr.concat({ label: "Blank", id: null })
            }

            if(!isEmptyArray(taskList) && this.state.showRanking){
                let minRank = taskList[0].taskList[0].minRank;
                let maxRank = taskList[0].taskList[0].maxRank;
                rankingArr = getRankingArray(minRank,maxRank)
                rankingArr = rankingArr.concat({ label: "Blank", id: null })
            }

            if(isEmptyArray(taskList)){
                this.setState({
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:"No data to filter."
                })
            }

            if(!isEmptyArray(this.state.assignmentRiskMatrix)){
                this.state.assignmentRiskMatrix.map(item => {
                    assignmentRiskMatrix.push({
                        riskLevel: item.riskLevel,
                        riskLevelId: item.riskLevelId
                    })
                })
                assignmentRiskMatrix = assignmentRiskMatrix.concat({ riskLevel: "Blank", riskLevelId: null })
            }

            this.setState({
                showFilter:!isEmptyArray(taskList),
                singleSelectFilter:updateSingleSelectFilter,
                weightArr:weight,
                yesNoRankArr:yesNoRankArr,
                rankingArr:rankingArr,
                riskArr:assignmentRiskMatrix,
                assignRisk:this.state.executionTemplateFixedFields?.assignRisk??"N"
            })
        }
    }

    // Handles the checkbox click event for each filter option.
    handleCheck = (e) => {
        let isChecked = e.target.checked;
        let name = e.target.name;
        let arr = name.split(":-:");

        let singleSelectFilter = [...this.state.singleSelectFilter];
        if(!isEmptyArray(singleSelectFilter)){
            singleSelectFilter[parseInt(arr[0])].dropdownValues.forEach(it => {
                if (it.value === arr[1]) {
                    it.isSelected = isChecked;
                }
            });
        }

        this.setState({
            singleSelectFilter:singleSelectFilter
        })
    }

    // Handles the checkbox click event for each filter option.
    handleRiskCheck = (e) => {
        let isChecked = e.target.checked;
        let name = e.target.name;
        let arr = name.split(":-:");

        let assignmentRiskMatrix = [...this.state.assignmentRiskMatrix];
        if(this.state.executionTemplateFixedFields?.assignRisk === "Y"){
            if(!isEmptyArray(this.state.assignmentRiskMatrix)){
                assignmentRiskMatrix.forEach(it => {
                    if(it.riskLevelId == arr[1]){
                        it.isSelected = isChecked;
                    }
                })
            }
        }

        this.setState({
            assignmentRiskMatrix:assignmentRiskMatrix
        })
    }

    handleWeightCheck = (e) => {
        let isChecked = e.target.checked;
        let name = e.target.name;
        let arr = name.split(":-:");

        let weightArr = [...this.state.weightArr];
        if(this.state.executionTemplateFixedFields?.assignRisk === "Y"){
            if(!isEmptyArray(this.state.weightArr)){
                weightArr.forEach(it => {
                    if(it.value == arr[1]){
                        it.isSelected = isChecked;
                    }
                })
            }
        }

        this.setState({
            weightArr:weightArr
        })
    }

    handleYesNoRankArr = (e) => {
        let isChecked = e.target.checked;
        let name = e.target.name;
        let arr = name.split(":-:");

        let yesNoRankArr = [...this.state.yesNoRankArr];
        if(!isEmptyArray(this.state.yesNoRankArr)){
            yesNoRankArr.forEach(it => {
                if(it.label == (arr[1] == 'null' ? null : arr[1])){
                    it.isSelected = isChecked;
                }
            })
        }

        this.setState({
            yesNoRankArr:yesNoRankArr
        })
    }

    handleRankingArr = (e) => {
        let isChecked = e.target.checked;
        let name = e.target.name;
        let arr = name.split(":-:");

        let rankingArr = [...this.state.rankingArr];
        if(!isEmptyArray(this.state.rankingArr)){
            rankingArr.forEach(it => {
                if(it.id == (arr[1] == 'null' ? null : arr[1])){
                    it.isSelected = isChecked;
                }
            })
        }

        this.setState({
            rankingArr:rankingArr
        })
    }

    // Handles the save filter button click event.
    handleSaveFilter = () => {
        let singleSelectFilter = this.state.singleSelectFilter;
        let dataList = this.state.oldDatalist;
        let executionTemplateValuesMap = this.state.executionTemplateValuesMap;
        let assignmentRiskMatrix = this.state.assignmentRiskMatrix;
        let weightArr = this.state.weightArr;
        let yesNoRankArr = this.state.yesNoRankArr;
        let rankingArr = this.state.rankingArr;

        // Extract selected values and their corresponding field IDs
        const selectedValuesMap = singleSelectFilter.reduce((acc, item) => {
            item.dropdownValues.forEach(value => {
                if (value.isSelected) {
                    if (!acc[item.fieldId]) {
                        acc[item.fieldId] = [];
                    }
                    acc[item.fieldId].push(value.value);
                }
            });
            return acc;
        }, {});

        // Extract keys from executionTemplateValuesMap
        const executionTemplateIds = new Set(Object.keys(executionTemplateValuesMap));

        // Filter assignmentTaskIds not present in executionTemplateValuesMap
        const filteredTaskIds = dataList.flatMap(process => 
            process.taskList
                .filter(task => !executionTemplateIds.has(task.assignmentTaskId.toString()))
                .map(task => task.assignmentTaskId)
        );

        // Filter executionTemplateValuesMap based on selected values
        const filteredAssignmentTaskIds = Object.keys(executionTemplateValuesMap).filter(assignmentTaskId => {
            const fields = executionTemplateValuesMap[assignmentTaskId];
            return Object.entries(selectedValuesMap).every(([fieldId, values]) => {
                const field = fields[fieldId];
                // Check if the field value matches any of the selected values
                if (field) {
                    if (values.includes(field.value)) {
                        return true;
                    }
                    if(values.includes("NA") && field.valueNR === "Y"){
                        return true;
                    }
                    // Handle multi-select values
                    const fieldValues = isJsonString(field.value);
                    if (fieldValues && Array.isArray(JSON.parse(field.value))) {
                        const fieldValues = JSON.parse(field.value);
                        if (values.some(value => fieldValues.includes(value))) {
                            return true;
                        }
                    }
                    if (values.includes("Blank") && field.value === "") {
                        return true;
                    }
                } else {
                    // Handle "Blank" condition
                    if (values.includes("Blank")) {
                        return true;
                    }
                }
                return false;
            });
        })
        .map(assignmentTaskId => parseInt(assignmentTaskId));

        let selectedAssignmentTaskId = filteredAssignmentTaskIds;
        Object.entries(selectedValuesMap).every(([fieldId, values]) => {
            if (values.includes("Blank")) {
                selectedAssignmentTaskId.push(...filteredTaskIds)
            }
        })

        // Filter datalist based on assignmentTaskId
        const filteredData = dataList.map(data => ({
            ...data,
            taskList: data.taskList.filter(task => {
                const matchesAssignment = selectedAssignmentTaskId.includes(task.assignmentTaskId);
                const matchesRisk = assignmentRiskMatrix.filter(item => item.isSelected).map(item => item.riskLevelId).includes(task.assignmentTaskRiskLevelId);
                const matchesWeight = weightArr.filter(item => item.isSelected).map(item => item.value).includes(task.weight);
                const matchesYesNoRank = yesNoRankArr.filter(item => item.isSelected).map(item => item.label).includes(task.rank);
                const matchesRanking = rankingArr.filter(item => item.isSelected).map(item => item.id).includes(task.rank);
    
                return matchesAssignment || matchesRisk || matchesWeight || matchesYesNoRank || matchesRanking;
            })
        }));

        this.setState({
            datalist:filteredData,
            showFilter:false,
            isSave:true
        });
    }

    // Reset the filter options by unchecking all checkboxes.
    resetFilterOptions = () => {
        const resetSelection = (array) => {
            array.forEach(item => {
                if (item.dropdownValues) {
                    item.dropdownValues.forEach(it => {
                        it.isSelected = false;
                    });
                } else {
                    item.isSelected = false;
                }
            });
        };
    
        resetSelection(this.state.singleSelectFilter);
        resetSelection(this.state.assignmentRiskMatrix);
        resetSelection(this.state.weightArr);
        resetSelection(this.state.yesNoRankArr);
        resetSelection(this.state.rankingArr);
    }

    // Handles the reset filter button click event.
    handleResetFilter = (reloadFlag) => {
        // If reloadFlag is true, restore the original datalist.
        if(reloadFlag == true){
            this.setState({
                datalist:this.state.oldDatalist
            });
        }

        this.resetFilterOptions();

        this.setState({
            singleSelectFilter:this.state.singleSelectFilter,
            assignmentRiskMatrix:this.state.assignmentRiskMatrix,
            weightArr:this.state.weightArr,
            yesNoRankArr:this.state.yesNoRankArr,
            rankingArr:this.state.rankingArr,
            isReset:true,
            isSave:false
        })
    }

    // Handles the cancel filter button click event.
    handleCancelFilter = () => {
        // If the filter has been reset, restore the original filter options and datalist.
        if(this.state.isReset){
            this.resetFilterOptions();

            this.setState({
                singleSelectFilter:this.state.singleSelectFilter,
                assignmentRiskMatrix:this.state.assignmentRiskMatrix,
                weightArr:this.state.weightArr,
                yesNoRankArr:this.state.yesNoRankArr,
                rankingArr:this.state.rankingArr,
                datalist:this.state.oldDatalist,
                isReset:false
            })
        }
        this.setState({
            showFilter:false,
        })
    }

    closePreviousAssignDialogBox = () =>{
        this.setState({
            showPreviousAssignmentData:false
        })
    }

    showExecutionTasksActionPopup = (event) => {
        event.preventDefault();
        this.setState({
            showExecutionTasksActionPopup: true,
        });
    }

    hideExecutionTasks = (event) => {
        event?.preventDefault && event.preventDefault();
        this.setState({
            filterDataList: [],
            showCheckboxColumnDataList: false,
            checkedTaskListIds: [],
            executionTaskAction: "",
        });
    }

    closeExecutionTaskActionDialog = () => {
        this.setState({
            showExecutionTasksActionPopup: false,
        });
    }

    getFilteredTasksFromExecutionTaskAction = (action, checkedIds = []) => {
        const arr = this.getUserTasksFromUserId(this.state.selectedResourceId);
        if (action === "") return arr;

        arr.forEach(a => {
            a.taskList = a.taskList.filter(task => {
                if (action === "Mark as Completed") {
                    let isFilter = task.completionStatus !== "Completed";
                    if (isFilter) {
                        for (let i = 0; i < this.state.execTaskTemplateFields.length; i++) {
                            const field = this.state.execTaskTemplateFields[i];
                            if(Object.values(this.state.executionTemplateValuesMap).length){
                                const execField = Object.values(this.state.executionTemplateValuesMap[task.assignmentTaskId]).find(aTask => aTask.fieldId.toString() === field.fieldId);
                                if (field.isMandatorySelected === "Y") {
                                    isFilter = !isEmptyVariable(execField?.value);
                                    if (!isFilter) break;
                                }
                            }
                        };
                    }
                    if (isFilter) checkedIds.push(task.assignmentTaskId.toString());
                    return isFilter;
                }
                let isFilter = task.completionStatus === "Completed" && task.currentReviewStatus === "Waiting for Review";
                if (isFilter) checkedIds.push(task.assignmentTaskId.toString());
                return isFilter;
            });
        });
        return arr;
    }

    handleExecutionTaskActionOk = (action) => {
        const checkedIds = [];
        const arr = this.getFilteredTasksFromExecutionTaskAction(action, checkedIds);
        let completeOngingFilterArr = this.state.completeOngingFilterArr;
        completeOngingFilterArr = completeOngingFilterArr.map(filter => {
            filter.isSelected = filter.label === "All";
            return filter;
        });

        let FilterDropDownArr = this.state.FilterDropDownArr;
        FilterDropDownArr = FilterDropDownArr.map(filter => {
            filter.isSelected = filter.label === "All";
            return filter;
        });

        this.setState({
            filterDataList: arr,
            showCheckboxColumnDataList: true,
            checkedTaskListIds: checkedIds,
            executionTaskAction: action,
            completeOngingFilterPlaceholder: "All",
            FilterPlaceholder: "All",
            completeOngingFilterArr: completeOngingFilterArr,
            FilterDropDownArr: FilterDropDownArr,
        });
    }

    handleCheckTasks = (e) => {
        e.stopPropagation();
        let isChecked = e.target.checked, taskId = e.target.name, checkedTaskListIds = this.state.checkedTaskListIds;
        if (isChecked) {
            checkedTaskListIds.push(taskId.toString());   
        }
        else {
            let i = checkedTaskListIds.findIndex(task => task === taskId);
            if (i >= 0) {
                checkedTaskListIds.splice(i, 1);
            }
        }
        this.setState({
            checkedTaskListIds: checkedTaskListIds,
        });
    }

    applyExecutionTasksAction = (event) => {
        event.preventDefault();
        if (this.state.checkedTaskListIds.length === 0) {
            this.setState({
                showAlertDialogInfo: true,
                alertDialogMessageInfo: "Please select at least one task",
            });
        }
        else {
            let url = "";
            let postParam = {
                email: userDetails.email,
                accessToken: userDetails.accessToken,
                assignmentId: this.state.assignmentId,
                assignmentTaskId: "[" + this.state.checkedTaskListIds + "]",
            };
            if (this.state.executionTaskAction === "Mark as Completed") {
                url = Constants.MarkTaskComplete;
            }
            else if (this.state.executionTaskAction === "Submit For Review") {
                url = Constants.SubmitAssignmentTaskForReview;
            }

            if (url) {
                this.setState({
                    showLoader: true,
                });
    
                fetch(url, {
                    method: "POST",
                    mode: "cors",
                    body: new URLSearchParams(postParam),
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        if (
                            data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                            data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
                        ) {
                            localStorage.clear();
                            window.location = "/";
                        } else if (data.responseCode === Constants.CODE_SUCCESS) {
                            this.hideExecutionTasks();
                            this.GetExecuteTasksList();
                        } else {
                            this.setState({
                                showLoader: false,
                                checkedTaskListIds: "",
                                executionTaskAction: "",
                                showCheckboxColumnDataList: false,
                                filterDataList: [],
                                showAlertDialogInfo: true,
                                alertDialogMessageInfo: data.responseMessage,
                            });
                        }
                    });
            }
        }
    }

    render() {
        let showEmptyCard = true;
        return (
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />
                        <div className="dashboard-right-bg-collpase">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                {
                                    this.state.componentDidMountFlag && (
                                    <div className="d-flex align-items-center">
                                        <div className="flex-fill">
                                            <MasterMenuTabs
                                                assignmentId={this.state.assignmentId}
                                                masterMenus={this.state.menus}
                                                themeSettings={themeSettings}
                                            />
                                        </div>
                                        <div>
                                            <TopmenuReports
                                                assignmentId={this.state.assignmentId}
                                                selectedResourceId = {this.state.selectedResourceId}
                                                userDetails ={userDetails}
                                                themeSettings={themeSettings}
                                                company={this.state.company}
                                            />
                                        </div>
                                    </div>
                                    )
                                }

                                {
                                    this.state.showLoader && (
                                    <div class="loader"></div>
                                )}
                                {
                                    !this.state.showTaskDetailsLayout && (
                                    <div className="exe-resource-dd flex-center-layout mt-1 mb-1">
                                        <div style={{ marginRight: 10 }}>
                                            {!isEmptyArray(this.state.datalist) && this.state.assignmentArea !== "Function" &&
                                                <div className="bordered-anchor">
                                                    <a onClick={this.handleAssignFunction} href="javascript:void(0)">
                                                        <span className="material-icons"></span>
                                                        <p>Assign Function</p>
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                        <div style={{ marginRight: 10 }}>
                                            <div className="bordered-anchor">
                                                {
                                                    isEmptyVariable(this.state.executionTaskAction) ?
                                                    <a onClick={this.showExecutionTasksActionPopup} href="#">
                                                        <span class="material-icons-outlined">
                                                        check_box
                                                        </span>
                                                        <p>Completed/Review</p>
                                                    </a>
                                                    : <a onClick={this.hideExecutionTasks} href="#">
                                                        <span class="material-icons-outlined">
                                                        check_box
                                                        </span>
                                                        <p>Unselect All</p>
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                        <div style={{ marginRight: 10 }}>
                                            <FilterDropDown
                                                placeholder={this.state.selectFilterPlaceholder}
                                                dropdownArr={this.state.selectFilterDropDownArr}
                                                onDropDownItemClick={this.selectFilterDropdownClick}
                                                themeSettings={themeSettings}
                                                name={"label"}
                                                dropdownWidth="200px"
                                            />
                                        </div>
                                        {
                                            !isEmptyVariable(this.state.executionTaskAction) &&
                                            <div>
                                                <AddNewButtonLayout themeSettings={themeSettings}>
                                                    <a href="#" onClick={this.applyExecutionTasksAction}>
                                                        <p style={{ marginLeft: 0 }}>Submit</p>
                                                    </a>
                                                </AddNewButtonLayout>
                                            </div>
                                        }
                                        <div style={{ marginRight: 10 }}>
                                            <FilterDropDown
                                                placeholder={this.state.completeOngingFilterPlaceholder=="All"?"Status":this.state.completeOngingFilterPlaceholder}
                                                dropdownArr={this.state.completeOngingFilterArr}
                                                onDropDownItemClick={this.completeOngoingDropdownClick}
                                                themeSettings={themeSettings}
                                                name={"label"}
                                                dropdownWidth="100px"
                                                disabled={!isEmptyVariable(this.state.executionTaskAction)}
                                            />
                                        </div>
                                        <div style={{ marginRight: 30 }}>
                                            <FilterDropDown
                                                placeholder={this.state.FilterPlaceholder=="All"?this.state.assignmentArea:this.state.FilterPlaceholder}
                                                dropdownArr={this.state.FilterDropDownArr}
                                                onDropDownItemClick={this.filterDropDownOnClick}
                                                themeSettings={themeSettings}
                                                name={"label"}
                                                dropdownWidth="200px"
                                            />
                                        </div>
                                        {(!isEmptyVariable(this.state.myPosition) && this.state.myPosition === "Viewer" ||
                                        this.state.myPosition === "Reviewer") && 
                                            <div style={{ marginRight: 10 }}>
                                                <RegularDD
                                                    placeholder={this.state.selectedResourceUserName}
                                                    dropdownArr={this.state.assignmentMatrix}
                                                    labelParam="label"
                                                    onDropDownItemClick={this.resourceDropdownItemClick}
                                                />
                                            </div>
                                        }
                                        <span className="tool" data-title="Filters">
                                            <AddNewButtonLayout themeSettings={themeSettings}>
                                                <a href="javascript:void(0);" className="filter_btn">
                                                    <span className="material-icons filter_alt" onClick={this.showFilter}>filter_alt</span>
                                                    {this.state.isSave &&
                                                        <span className="material-icons filter_alt_off ml-2" onClick={() => this.handleResetFilter(true)}>filter_alt_off</span>
                                                    }
                                                </a>
                                            </AddNewButtonLayout>
                                        </span>
                                         <div style={{ marginRight: 30 }}>
                                            <FilterDropDown
                                                // placeholder={"Report"}
                                                icon={'download'}
                                                mleft={"0"}
                                                dropdownArr={this.state.downloadButtonArr}
                                                onDropDownItemClick={this.downloadReportItemOnClick}
                                                name={"label"}
                                                themeSettings={themeSettings}
                                                dropdownWidth={"120px"}
                                            />
                                        </div>
                                    </div>
                                )}
                                {
                                    // Need to check component did mount flag, other wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    !this.state.showTaskDetailsLayout && (
                                        <div className="body-wrapper common-table-scroll">
                                            {
                                                !isEmptyArray(this.state.datalist) && this.state.assignmentArea !== "Function" &&
                                                (this.state.filterDataList.length > 0 ? this.state.filterDataList : this.state.datalist).map((item, index) => {
                                                    return (
                                                        !isEmptyArray(item.taskList) && (
                                                            <div>
                                                                <div className="flex-center-layout" style={{ marginTop: index === 0 ? 15 : 10 }}>
                                                                    <h6  className="small-h6" style={{ margin: 0 }}>
                                                                        {isEmptyVariable(item.processName) ? isEmptyVariable(item.functionName) ? item.heading : item.functionName : item.processName}
                                                                    </h6>
                                                                </div>
                                                                {
                                                                    !isEmptyArray(item.taskList) && (
                                                                    <div style={{ marginTop:10 }}>
                                                                        <div className="common-tab-bg">
                                                                            <div className="common-table" style={{ width: "100%" }} >
                                                                                <table className="table">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th className="text-center" width="38px"> No.</th>
                                                                                            <th width="1%">
                                                                                                <div className="flex-center-layout justify-content-around">
                                                                                                    <span className="tool" data-title="Review Comments">C</span>
                                                                                                    {this.state.executionTemplateFixedFields?.annexures === "Y" && (
                                                                                                        <span className="tool" data-title="Annexure">A</span>
                                                                                                    )}
                                                                                                    {this.state.executionTemplateFixedFields?.backups === "Y" && (
                                                                                                        <span className="tool" data-title="Backups">B</span>
                                                                                                    )}
                                                                                                    <span className="tool" data-title="History">H</span>
                                                                                                </div>
                                                                                            </th>
                                                                                            {
                                                                                                this.state.selectedTaskTemplateFields.map(
                                                                                                (item) => {
                                                                                                    return (
                                                                                                        <th className={item.fieldType === TemplateConstants.FIELD_TYPE_TEXT ? "scrollable-table-task-text-th" : "scrollable-table-task-others-th"}>
                                                                                                            {item.fieldName}
                                                                                                        </th>
                                                                                                    );
                                                                                                }
                                                                                            )}
                                                                                            {
                                                                                                this.state.isRisk && this.state.executionTemplateFixedFields?.assignRisk === "Y" && 
                                                                                                <th>Risk</th>
                                                                                            }
                                                                                            {
                                                                                                this.state.showWeight &&
                                                                                                <th>Weight</th>
                                                                                            }
                                                                                            {
                                                                                                this.state.showYesNoDD &&
                                                                                                <th>Rate</th>
                                                                                            }
                                                                                            {
                                                                                                this.state.showRanking && 
                                                                                                <th>Rate</th>
                                                                                            }
                                                                                            {
                                                                                                this.state.execTaskTemplateFields.map(item => {
                                                                                                    let showHeader = true;
                                                                                                    if (item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI) {
                                                                                                        if (item.isFromRiskMaster) {
                                                                                                            if (item.isRiskMasterEnabled === "Y") {
                                                                                                                showHeader = true;
                                                                                                            } else {
                                                                                                                showHeader = false;
                                                                                                            }
                                                                                                        } else {
                                                                                                            showHeader = true;
                                                                                                        }
                                                                                                    }

                                                                                                    if(!isEmptyVariable(this.state.selectFilterValue.fieldId) && item.fieldId===this.state.selectFilterValue.fieldId){
                                                                                                        return ( showHeader &&
                                                                                                            <>
                                                                                                                <th className={item.fieldType === TemplateConstants.FIELD_TYPE_TEXT
                                                                                                                    ? "scrollable-table-task-text-th" : "scrollable-table-task-others-th"}>
                                                                                                                    {item.fieldName}
                                                                                                                    {item.isMandatorySelected === "Y" ? "*" : ""}
                                                                                                                </th>
                                                                                                                <th className="scrollable-table-task-others-th">{"Previous "+this.state.selectFilterValue.fieldName}</th>
                                                                                                            </>
                                                                                                            )
                                                                                                    }else{
                                                                                                        return showHeader && <th className={item.fieldType === TemplateConstants.FIELD_TYPE_TEXT
                                                                                                            ? "scrollable-table-task-text-th" : "scrollable-table-task-others-th"}>
                                                                                                            {item.fieldName}
                                                                                                            {item.isMandatorySelected === "Y" ? "*" : ""}
                                                                                                        </th>
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                            {
                                                                                                !isEmptyVariable(this.state.assignmentDetails.observationTemplateId) &&
                                                                                                <th>Observation</th>
                                                                                            }
                                                                                            {/* <th style={{padding:0}}></th> */}
                                                                                            {
                                                                                                !isEmptyVariable(this.state.assignmentStatus) && 
                                                                                                this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_ONGOING &&
                                                                                                <th className="text-center"></th>
                                                                                            }
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {
                                                                                            !isEmptyArray(item.taskList) &&
                                                                                            item.taskList.map((taskItem, idx) => {
                                                                                                showEmptyCard = false;
                                                                                                let yNRank = defaultYNPlaceholder;
                                                                                                let actionObj = getObservationTasks(this.state.assignmentArea, taskItem.allowEdit, taskItem.allowReview, taskItem.completionStatus, taskItem.observationStatus,
                                                                                                    this.state.myPosition, this.state.executionTemplateFixedFields?.valueAddition, this.state.assignmentDetails?.reviewAssignmentTask);
                                                                                                // For Y/N, get label from yesNoRankArr
                                                                                                if (this.state.showYesNoDD && !isEmptyVariable(taskItem.rank)) {
                                                                                                    Constants.yesNoRankArr.forEach((rankItem) => { if (taskItem.rank === rankItem.id) { yNRank = rankItem.label; } });
                                                                                                }
                                                                                                //check allowEdit
                                                                                                let assignmentTaskAllowEdit = this.getAllowEdit(taskItem.allowEdit, taskItem.completionStatus, taskItem.userId);
                                                                                                return (
                                                                                                    <tr style={{ background: assignmentTaskAllowEdit === "Y" ? "#fff" : "#f5f5f5", }}>
                                                                                                        <td className="text-center"> {(this.state.currentPageNo - 1) * this.state.resultSize + (idx + 1)}</td>
                                                                                                        <td>
                                                                                                            <div className="flex-center-layout exec-task-indicators">
                                                                                                                <div className="review-comment-layout">
                                                                                                                    <span style={{
                                                                                                                        color: taskItem.remarkCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`,
                                                                                                                        opacity: taskItem.remarkCount === 0 ? "0.6" : "1",
                                                                                                                    }}
                                                                                                                        onClick={this.uploadAnnexuresBackupIconClick.bind(this, taskItem.assignmentTaskId, "Review Comments", assignmentTaskAllowEdit, taskItem.userId)}
                                                                                                                        className="material-icons">
                                                                                                                        chat
                                                                                                                    </span>
                                                                                                                    {taskItem.newRemarkCount != 0 && (
                                                                                                                        <div class="review-comment-badge">
                                                                                                                            <span>{taskItem.newRemarkCount}</span>
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                </div>
                                                                                                                {this.state.executionTemplateFixedFields?.annexures === "Y" && (
                                                                                                                    <span
                                                                                                                        className="material-icons"
                                                                                                                        style={{
                                                                                                                            color: taskItem.annexureCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`,
                                                                                                                            opacity: taskItem.annexureCount === 0 ? "0.6" : "1"
                                                                                                                        }}
                                                                                                                        onClick={this.uploadAnnexuresBackupIconClick.bind(this, taskItem.assignmentTaskId, "Annexures", assignmentTaskAllowEdit)}>
                                                                                                                        file_present
                                                                                                                    </span>
                                                                                                                )}
                                                                                                                {this.state.executionTemplateFixedFields?.backups === "Y" && (
                                                                                                                    <span
                                                                                                                        className="material-icons"
                                                                                                                        style={{ color: taskItem.backupCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`, opacity: taskItem.backupCount === 0 ? "0.6" : "1", }}
                                                                                                                        onClick={this.uploadAnnexuresBackupIconClick.bind(this, taskItem.assignmentTaskId, "Backups", assignmentTaskAllowEdit)} >
                                                                                                                        cloud_upload
                                                                                                                    </span>
                                                                                                                )}
                                                                                                                <span
                                                                                                                    className="material-icons"
                                                                                                                    style={{ color: taskItem.backupCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`, opacity: taskItem.backupCount === 0 ? "0.6" : "1", }}
                                                                                                                    onClick={this.previousAssignmentData.bind(this,taskItem)}>
                                                                                                                    manage_search
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        {
                                                                                                            this.state.selectedTaskTemplateFields.map((item, taskIdx) => {
                                                                                                                return (
                                                                                                                    <td onClick={taskIdx + "" === "0" ? this.handleShowTaskDetailsLayout.bind(this, taskItem.taskId) : null} style={taskIdx + "" === "0" ? { borderLeft: "5px solid " + taskItem.colorCode } : {}} >
                                                                                                                        <div className={item.fieldId + "" === "1" ? "dangerouslysethtml-flex" : ""}>
                                                                                                                            <div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{ __html: this.getTaskDisplayValues(taskItem.taskId, item) }} />
                                                                                                                            {
                                                                                                                                taskItem.completionStatus === "Completed" && item.fieldId + "" === "1" && (
                                                                                                                                    <span className="material-icons exec-task-done" >
                                                                                                                                        done_all
                                                                                                                                    </span>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                );
                                                                                                            }
                                                                                                        )}
                                                                                                        {
                                                                                                            this.state.isRisk && this.state.executionTemplateFixedFields?.assignRisk === "Y" &&
                                                                                                            <td onClick={this.handleSelectRiskDialogShow.bind(this, taskItem.assignmentTaskId, taskItem.assignmentTaskRiskLevelId, assignmentTaskAllowEdit, taskItem.assignmentTaskLikelihoodLevelId)} >
                                                                                                                {isEmptyVariable(taskItem.assignmentTaskRiskLevel) ? "-" : taskItem.assignmentTaskRiskLevel}
                                                                                                            </td>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.showWeight && <td className="text-center"> {taskItem.weight} </td>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.showYesNoDD &&
                                                                                                            <td>
                                                                                                                <RegularDropdown
                                                                                                                    placeholder={isEmptyVariable(taskItem.rank) ? defaultYNPlaceholder : yNRank}
                                                                                                                    dropdownArr={Constants.yesNoRankArr}
                                                                                                                    labelParam="label"
                                                                                                                    onDropDownItemClick={this.maxrDropdownClick}
                                                                                                                    udf={idx + ":-:" + index}
                                                                                                                    disabled={assignmentTaskAllowEdit === "Y" ? false : true}
                                                                                                                />
                                                                                                            </td>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.showRanking &&
                                                                                                            <td>
                                                                                                                <RegularDropdown
                                                                                                                    placeholder={isEmptyVariable(taskItem.rank) ? defaultWeightPlaceholder : taskItem.rank === -1 ? "N/A" : taskItem.rank}
                                                                                                                    dropdownArr={getRankingArray(taskItem.minRank,taskItem.maxRank)}
                                                                                                                    labelParam="label"
                                                                                                                    onDropDownItemClick={this.maxrDropdownClick}
                                                                                                                    udf={idx + ":-:" + index}
                                                                                                                    disabled={assignmentTaskAllowEdit === "Y" ? false : true}
                                                                                                                />
                                                                                                            </td>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.execTaskTemplateFields.map((item) => {
                                                                                                                let showHeader = true;
                                                                                                                if (item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI) {
                                                                                                                    if (item.isFromRiskMaster) {
                                                                                                                        if (item.isRiskMasterEnabled === "Y") {
                                                                                                                            showHeader = true;
                                                                                                                        } else {
                                                                                                                            showHeader = false;
                                                                                                                        }
                                                                                                                    } else {
                                                                                                                        showHeader = true;
                                                                                                                    }
                                                                                                                }
                                                                                                                return showHeader && this.getExecTaskDisplayTableData(taskItem.assignmentTaskId, item, assignmentTaskAllowEdit, taskItem.taskId, taskItem.isFocus ?? false, item.isFocus ?? false); 
                                                                                                            }
                                                                                                        )}
                                                                                                        {/* if task has no observation linked to it then show create button
                                                                                                        if task has no observation linked to it but completion status is completed then show just "-" */}
                                                                                                        {
                                                                                                            !isEmptyVariable(this.state.assignmentDetails.observationTemplateId) &&
                                                                                                            <td onClick={this.handleShowAddEditObservation.bind(this, taskItem)} >
                                                                                                                {isEmptyVariable(taskItem.observationStatus) && taskItem.completionStatus === "Completed" ? "-" : isEmptyVariable(taskItem.observationStatus) ? "Create" : taskItem.observationStatus}
                                                                                                            </td>
                                                                                                        }

                                                                                                        {
                                                                                                            !isEmptyVariable(this.state.assignmentStatus) && this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_ONGOING && (
                                                                                                            <td className="text-center">
                                                                                                                {
                                                                                                                    actionObj.actionArr && actionObj.actionArr.length > 0 && (
                                                                                                                    <TableDropDown
                                                                                                                        actionArr={actionObj.actionArr}
                                                                                                                        onDropDownItemClick={this.onTableDropDownItemClick}
                                                                                                                        dropDownId={taskItem}
                                                                                                                        dropdownWidth={actionObj.dropdownWidth}
                                                                                                                        themeSettings={themeSettings}
                                                                                                                    />
                                                                                                                )}
                                                                                                            </td>
                                                                                                        )}
                                                                                                    </tr>
                                                                                                );
                                                                                            }
                                                                                        )}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            this.state.showChecklistScores &&
                                                                            <div className="checklist-scores-layout">
                                                                                <p>{"Max Score: " + item.maxScore}</p>
                                                                                <p> {"Total Score: " + item.totalScore} </p>
                                                                                <p> {"Percentage: " + ((item.totalScore / item.maxScore) * 100).toFixed(1) + "%"} </p>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                )}

                                                                {
                                                                    isEmptyArray(item.taskList) &&
                                                                    <div class="no-items-layout">
                                                                        <div class="no-items-card" style={{ padding: "10px" }} >
                                                                            <h6 style={{ margin: 0, fontSize: "13px", }} >
                                                                                {isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}
                                                                            </h6>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        )
                                                    );
                                                })
                                            }

                                            {
                                                !isEmptyArray(this.state.datalist) && this.state.assignmentArea === "Function" &&
                                                (this.state.filterDataList.length > 0 ? this.getProcessGroupingTaskList(this.state.filterDataList, this.state.assignmentArea)
                                                    : this.getProcessGroupingTaskList(this.state.datalist, this.state.assignmentArea)
                                                ).map((process) => {
                                                    return !isEmptyArray(process.processTaskList) && Object.values(process.processTaskList).map((item, index) => {
                                                        return (
                                                            !isEmptyArray(item.taskList) && (
                                                                <div>
                                                                    <div className="sticky-exec-header" style={{height: 38}}>
                                                                    {
                                                                        // index === 0 && 
                                                                        <h6 className="small-h6"> 
                                                                            {!isEmptyVariable(item.processName) ? item.processName : ""} 
                                                                            {!isEmptyVariable(process.functionName)
                                                                                ? <>
                                                                                    <span className="mx-2">&gt;</span>
                                                                                    {process.functionName}
                                                                                </>
                                                                                : ""
                                                                            }
                                                                        </h6>
                                                                    }
                                                                    </div>

                                                                    {
                                                                        !isEmptyArray(item.taskList) && (
                                                                        <div>
                                                                            <div className="common-tab-bg">
                                                                                <div className="common-table" style={{ width: "100%" }} >
                                                                                    <table className="table">
                                                                                        <thead className="sticky-exec-header" style={{top:38}}>
                                                                                            <tr>
                                                                                                {this.state.showCheckboxColumnDataList &&
                                                                                                    <th className="text-center" width={60}></th>
                                                                                                }
                                                                                                <th className="text-center" width="38px" > No. </th>
                                                                                                <th width="1%">
                                                                                                    <div className="flex-center-layout justify-content-around">
                                                                                                        <span className="tool" data-title="Review Comments">C</span>
                                                                                                        {this.state.executionTemplateFixedFields?.annexures === "Y" && (
                                                                                                            <span className="tool" data-title="Annexure">A</span>
                                                                                                        )}
                                                                                                        {this.state.executionTemplateFixedFields?.backups === "Y" && (
                                                                                                            <span className="tool" data-title="Backups">B</span>
                                                                                                        )}
                                                                                                        <span className="tool" data-title="History">H</span>
                                                                                                    </div>
                                                                                                </th>

                                                                                                {
                                                                                                    this.state.selectedTaskTemplateFields.map((item) => {
                                                                                                        return (
                                                                                                            <th className={item.fieldType === TemplateConstants.FIELD_TYPE_TEXT ? "scrollable-table-task-text-th" : "scrollable-table-task-others-th"} > {item.fieldName} </th>
                                                                                                        );
                                                                                                    })
                                                                                                }
                                                                                                {this.state.isRisk && this.state.executionTemplateFixedFields?.assignRisk === "Y" && (<th>Risk</th>)}
                                                                                                {this.state.showWeight && (<th>Weight</th>)}
                                                                                                {this.state.showYesNoDD && (<th>Rank</th>)}
                                                                                                {this.state.showRanking && (<th>Rank</th>)}
                                                                                                {
                                                                                                    this.state.execTaskTemplateFields.map(item => {
                                                                                                        let showHeader = true;
                                                                                                        if (item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI) {
                                                                                                            if (item.isFromRiskMaster) {
                                                                                                                if (item.isRiskMasterEnabled === "Y") {
                                                                                                                    showHeader = true;
                                                                                                                } else {
                                                                                                                    showHeader = false;
                                                                                                                }
                                                                                                            } else {
                                                                                                                showHeader = true;
                                                                                                            }
                                                                                                        }
                                                                                                        if(!isEmptyVariable(this.state.selectFilterValue.fieldId) && item.fieldId===this.state.selectFilterValue.fieldId){
                                                                                                            return ( showHeader &&
                                                                                                                <>
                                                                                                                    <th className={item.fieldType === TemplateConstants.FIELD_TYPE_TEXT
                                                                                                                        ? "scrollable-table-task-text-th" : "scrollable-table-task-others-th"}>
                                                                                                                        {item.fieldName}
                                                                                                                        {item.isMandatorySelected === "Y" ? "*" : ""}
                                                                                                                    </th>
                                                                                                                    <th className="scrollable-table-task-others-th">{"Previous "+this.state.selectFilterValue.fieldName}</th>
                                                                                                                </>
                                                                                                            )
                                                                                                        }else{
                                                                                                            return showHeader && <th className={item.fieldType === TemplateConstants.FIELD_TYPE_TEXT
                                                                                                                ? "scrollable-table-task-text-th" : "scrollable-table-task-others-th"}>
                                                                                                                {item.fieldName}
                                                                                                                {item.isMandatorySelected === "Y" ? "*" : ""}
                                                                                                            </th>
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                                {
                                                                                                    !isEmptyVariable(this.state.assignmentDetails.observationTemplateId) &&
                                                                                                    <th>Observation</th>
                                                                                                }
                                                                                                {
                                                                                                    !isEmptyVariable(this.state.assignmentStatus) && 
                                                                                                    this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_ONGOING &&
                                                                                                    <th className="text-center"></th>
                                                                                                }
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {
                                                                                                !isEmptyArray(item.taskList) &&
                                                                                                item.taskList.map((taskItem, idx) => {
                                                                                                    showEmptyCard = false;
                                                                                                    let yNRank = defaultYNPlaceholder;
                                                                                                    let actionObj = getObservationTasks(this.state.assignmentArea, taskItem.allowEdit, taskItem.allowReview, taskItem.completionStatus,
                                                                                                        taskItem.observationStatus, this.state.myPosition, this.state.executionTemplateFixedFields?.valueAddition,
                                                                                                        this.state.assignmentDetails?.reviewAssignmentTask);
                                                                                                    // For Y/N, get label from yesNoRankArr
                                                                                                    if (this.state.showYesNoDD && !isEmptyVariable(taskItem.rank)) {
                                                                                                        Constants.yesNoRankArr.forEach((rankItem) => {
                                                                                                            if (taskItem.rank === rankItem.id) { yNRank = rankItem.label }
                                                                                                        }
                                                                                                        );
                                                                                                    }
                                                                                                    //check allowEdit
                                                                                                    let assignmentTaskAllowEdit = this.getAllowEdit(taskItem.allowEdit, taskItem.completionStatus, taskItem.userId);

                                                                                                    return (
                                                                                                        <tr style={{ background: assignmentTaskAllowEdit === "Y" ? "#fff" : "#f5f5f5", }} >
                                                                                                            {this.state.showCheckboxColumnDataList &&
                                                                                                                <td className="text-center" width={60}>
                                                                                                                    <TableCheckBox
                                                                                                                        themeSettings={themeSettings}>
                                                                                                                        <input 
                                                                                                                            type="checkbox"
                                                                                                                            name={taskItem.assignmentTaskId}
                                                                                                                            id={taskItem.assignmentTaskId}
                                                                                                                            onChange={this.handleCheckTasks}
                                                                                                                            checked={this.state.checkedTaskListIds.includes(taskItem.assignmentTaskId.toString())}
                                                                                                                        />
                                                                                                                        <label htmlFor={taskItem.assignmentTaskId} style={{marginLeft:5}}></label>
                                                                                                                    </TableCheckBox>
                                                                                                                </td>
                                                                                                            }
                                                                                                            <td className="text-center"> {(this.state.currentPageNo - 1) * this.state.resultSize + (idx + 1)} </td>
                                                                                                            <td>
                                                                                                                <div className="flex-center-layout exec-task-indicators">
                                                                                                                    <div className="review-comment-layout">
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                color: taskItem.remarkCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`,
                                                                                                                                opacity: taskItem.remarkCount === 0 ? "0.6" : "1",
                                                                                                                            }}
                                                                                                                            onClick={this.uploadAnnexuresBackupIconClick.bind(this, taskItem.assignmentTaskId, "Review Comments", assignmentTaskAllowEdit, taskItem.userId)}
                                                                                                                            className="material-icons" >
                                                                                                                            chat
                                                                                                                        </span>
                                                                                                                        {
                                                                                                                            taskItem.newRemarkCount != 0 && (
                                                                                                                            <div class="review-comment-badge">
                                                                                                                                <span> {taskItem.newRemarkCount} </span>
                                                                                                                            </div>
                                                                                                                        )}
                                                                                                                    </div>
                                                                                                                    {this.state.executionTemplateFixedFields?.annexures === "Y" && (
                                                                                                                        <span className="material-icons"
                                                                                                                            style={{
                                                                                                                                color: taskItem.annexureCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`,
                                                                                                                                opacity: taskItem.annexureCount === 0 ? "0.6" : "1",
                                                                                                                            }}
                                                                                                                            onClick={this.uploadAnnexuresBackupIconClick.bind(this, taskItem.assignmentTaskId, "Annexures", assignmentTaskAllowEdit)} >
                                                                                                                            file_present
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                                    {
                                                                                                                        this.state.executionTemplateFixedFields?.backups === "Y" && (
                                                                                                                        <span
                                                                                                                            className="material-icons"
                                                                                                                            style={{
                                                                                                                                color: taskItem.backupCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`,
                                                                                                                                opacity: taskItem.backupCount === 0 ? "0.6" : "1",
                                                                                                                            }}
                                                                                                                            onClick={this.uploadAnnexuresBackupIconClick.bind(this, taskItem.assignmentTaskId, "Backups", assignmentTaskAllowEdit)} >
                                                                                                                            cloud_upload
                                                                                                                        </span>
                                                                                                                    )}
                                                                                                                    <span
                                                                                                                        className="material-icons"
                                                                                                                        style={{ color: taskItem.backupCount === 0 ? "#777777" : `rgba(${themeSettings.themeColor.r},${themeSettings.themeColor.g},${themeSettings.themeColor.b},1)`, opacity: taskItem.backupCount === 0 ? "0.6" : "1", }}
                                                                                                                        onClick={this.previousAssignmentData.bind(this,taskItem)}>
                                                                                                                        manage_search
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            {
                                                                                                                this.state.selectedTaskTemplateFields.map((item, taskIdx) => {
                                                                                                                return (
                                                                                                                    <td onClick={taskIdx + "" === "0" ? this.handleShowTaskDetailsLayout.bind(this, taskItem.taskId) : null}
                                                                                                                        style={taskIdx + "" === "0" ? { borderLeft: "5px solid " + taskItem.colorCode, } : {}} >
                                                                                                                        <div className={item.fieldId + "" === "1" ? "dangerouslysethtml-flex" : ""}>
                                                                                                                            <div className='task-dangerouslysethtml' dangerouslySetInnerHTML={{ __html: this.getTaskDisplayValues(taskItem.taskId, item) }} />
                                                                                                                            {
                                                                                                                                taskItem.completionStatus === "Completed" && item.fieldId + "" === "1" && (
                                                                                                                                    <span className="material-icons exec-task-done"> done_all </span>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                );
                                                                                                            }
                                                                                                            )}
                                                                                                            {
                                                                                                                this.state.isRisk && this.state.executionTemplateFixedFields?.assignRisk === "Y" && 
                                                                                                                <td onClick={this.handleSelectRiskDialogShow.bind(this, taskItem.assignmentTaskId, taskItem.assignmentTaskRiskLevelId, assignmentTaskAllowEdit, taskItem.assignmentTaskLikelihoodLevelId)} >
                                                                                                                    {isEmptyVariable(taskItem.assignmentTaskRiskLevel) ? "-" : taskItem.assignmentTaskRiskLevel}
                                                                                                                </td>
                                                                                                            }
                                                                                                            {this.state.showWeight && (<td className="text-center"> {taskItem.weight} </td>)}
                                                                                                            {
                                                                                                                this.state.showYesNoDD && (
                                                                                                                <td>
                                                                                                                    <RegularDropdown
                                                                                                                        placeholder={isEmptyVariable(taskItem.rank) ? defaultYNPlaceholder : yNRank}
                                                                                                                        dropdownArr={Constants.yesNoRankArr}
                                                                                                                        labelParam="label"
                                                                                                                        onDropDownItemClick={this.maxrDropdownClick}
                                                                                                                        udf={idx + ":-:" + index}
                                                                                                                        disabled={assignmentTaskAllowEdit === "Y" ? false : true}
                                                                                                                    />
                                                                                                                </td>
                                                                                                            )}
                                                                                                            {
                                                                                                                this.state.showRanking && (
                                                                                                                <td>
                                                                                                                    <RegularDropdown
                                                                                                                        placeholder={isEmptyVariable(taskItem.rank) ? defaultWeightPlaceholder : taskItem.rank === -1 ? "N/A" : taskItem.rank}
                                                                                                                        dropdownArr={this.state.rankingArray}
                                                                                                                        labelParam="label"
                                                                                                                        onDropDownItemClick={this.maxrDropdownClick}
                                                                                                                        udf={idx + ":-:" + index}
                                                                                                                        disabled={assignmentTaskAllowEdit === "Y" ? false : true}
                                                                                                                    />
                                                                                                                </td>
                                                                                                            )}

                                                                                                            {
                                                                                                                this.state.execTaskTemplateFields.map((item) => {
                                                                                                                    let showHeader = true;
                                                                                                                    if (item.fieldType === TemplateConstants.FIELD_TYPE_DROPDOWN_MULTI) {
                                                                                                                        if (item.isFromRiskMaster) {
                                                                                                                            if (item.isRiskMasterEnabled === "Y") {
                                                                                                                                showHeader = true;
                                                                                                                            } else {
                                                                                                                                showHeader = false;
                                                                                                                            }
                                                                                                                        } else {
                                                                                                                            showHeader = true;
                                                                                                                        }
                                                                                                                    }
                                                                                                                    return showHeader && this.getExecTaskDisplayTableData(taskItem.assignmentTaskId, item, assignmentTaskAllowEdit, taskItem.taskId, taskItem.isFocus ?? false, item.isFocus ?? false);
                                                                                                                }
                                                                                                            )}
                                                                                                            {/* if task has no observation linked to it then show create button if task has no observation linked to it but completion status is completed then show just "-" */}
                                                                                                            {
                                                                                                                !isEmptyVariable(this.state.assignmentDetails.observationTemplateId) &&
                                                                                                                <td onClick={this.handleShowAddEditObservation.bind(this, taskItem)} >
                                                                                                                    {isEmptyVariable(taskItem.observationStatus) && taskItem.completionStatus === "Completed" ? "-" : isEmptyVariable(taskItem.observationStatus) ? "Create" : taskItem.observationStatus}
                                                                                                                </td>
                                                                                                            }
                                                                                                            
                                                                                                            {
                                                                                                                !isEmptyVariable(this.state.assignmentStatus) && this.state.assignmentStatus === Constants.ASSIGNMENT_STATUS_ONGOING && (
                                                                                                                <td className="text-center">
                                                                                                                    {actionObj.actionArr && actionObj.actionArr.length > 0 && (
                                                                                                                        <TableDropDown
                                                                                                                            actionArr={actionObj.actionArr}
                                                                                                                            onDropDownItemClick={this.onTableDropDownItemClick}
                                                                                                                            dropDownId={taskItem}
                                                                                                                            dropdownWidth={actionObj.dropdownWidth}
                                                                                                                            themeSettings={themeSettings}
                                                                                                                        />
                                                                                                                    )}
                                                                                                                </td>
                                                                                                            )}
                                                                                                        </tr>
                                                                                                    );
                                                                                                }
                                                                                                )}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                this.state.showChecklistScores && (
                                                                                <div className="checklist-scores-layout">
                                                                                    <p>{"Max Score: " + item.maxScore}</p>
                                                                                    <p> {"Total Score: " + item.totalScore} </p>
                                                                                    <p> {"Percentage: " + ((item.totalScore / item.maxScore) * 100).toFixed(1) + "%"} </p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    )}
                                                                    {
                                                                        isEmptyArray(item.taskList) && (
                                                                        <div class="no-items-layout">
                                                                            <div class="no-items-card" style={{ padding: "10px" }} >
                                                                                <h6 style={{ margin: 0 }} >
                                                                                    {isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}
                                                                                </h6>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )
                                                        );
                                                    })
                                                })
                                            }
                                            {
                                                this.state.componentDidMountFlag && showEmptyCard && (
                                                <div class="no-items-layout m-t-10">
                                                    <div class="no-items-card">
                                                        <h6>No Tasks</h6>
                                                    </div>
                                                </div>
                                            )}
                                            {
                                                this.state.showChecklistScores && (
                                                <div className="checklist-scores-layout">
                                                    <p> {"Checklist - Max Score: " + this.state.overallChecklistScoreObj?.maxScore} </p>
                                                    <p> {"Total Score: " + this.state.overallChecklistScoreObj?.actualScore} </p>
                                                    <p> {"Percentage: " + this.state.overallChecklistScoreObj?.scorePct + "%"} </p>
                                                </div>
                                            )}
                                        </div>

                                    )
                                }
                                {
                                    this.state.showTaskDetailsLayout && (
                                    <TaskDetailsLayout
                                        taskId={this.state.selectedTaskIdForDetails}
                                        handleHideTaskDetailsLayout={this.handleHideTaskDetailsLayout}
                                        subscriptionType={this.state.user.subscriptionType}
                                        handleShowTaskDetailsLayout={this.handleShowTaskDetailsLayout}
                                        isIndustryFlag={this.state.isIndustryFlag}
                                    />
                                )}
                            </div>
                        </div>
                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={this.state.operationType === "Delete" ? Constants.ALERT_TYPE_WARNING : this.state.operationType === "Review Comments" ? Constants.ALERT_TYPE_ALERT : Constants.ALERT_TYPE_INFO}
                            alertDialogHeading={this.state.alertDialogHeading}
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.handleRecordOperation}
                            proceedBtnLabel={this.state.proceedBtnLabel}
                            showLoader={this.state.showBtnLoader}
                            themeSettings={themeSettings}
                        />

                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type={Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                            themeSettings={themeSettings}
                        />
                        <ReviewCommentsDialog
                            showReviewCommentsDialog={this.state.showReviewCommentsDialog}
                            handleReviewCommentsDialogClose={this.handleReviewCommentsDialogClose}
                            masterDataId={this.state.deleteReactivateId}
                            assignmentId={this.state.assignmentId}
                            themeSettings={themeSettings}
                            assignmentMatrix={this.state.assignmentMatrix}
                            type={this.state.type}
                            assignmentTaskAllowEdit={this.state.assignmentTaskAllowEdit}
                            assignmentTaskUserId={this.state.assignmentTaskUserId}
                        />
                        <AlertWithTextarea
                            showAlertDialog={this.state.showAlertWithTextareaDialog}
                            handleAlertDialogClose={this.handleAlertWithTextareaDialogClose}
                            alertDialogHeading={this.state.alertDialogHeading}
                            alertDialogMessage={this.state.alertWithTextareaDialogMessage}
                            proceedBtnClick={this.handleAlertWithTextareaDialogClose}
                            proceedBtnLabel={this.state.proceedBtnLabel}
                            showLoader={this.state.showBtnLoader}
                            themeSettings={themeSettings}
                        // showRadio={this.state.showRadio}
                        />
                        <LeftSideSelectDialog
                            showSelectDropdownDialog={this.state.showSelectDropdownDialog}
                            handleSelectDropdownDialogClose={this.handleSelectDropdownDialogClose}
                            fieldObj={this.state.templateFieldItem}
                            viewOnly={"N"}
                            isEditDescription={true}
                            themeSettings={themeSettings}
                            dialogSelectedValues={this.dialogSelectedValues}
                            fieldArrIndex={this.state.deleteReactivateId}
                            assignmentTaskAllowEdit={this.state.assignmentTaskAllowEdit}
                        />
                        <UploadAnnexureDialog
                            showUploadAnnexureDialog={this.state.showSelectUploadAnnexureDialog}
                            handleSelectUploadAnnexureDialogClose={this.handleSelectUploadAnnexureDialogClose}
                            // handleSelectUploadAnnexureDialogSaveClose={this.handleSelectUploadAnnexureDialogSaveClose}
                            // uploadedAnnexureArr={this.state.uploadedAnnexureArr}
                            type={this.state.isBackup ? "Backup" : "Annexure"}
                            assignmentTaskId={this.state.deleteReactivateId}
                            assignmentTaskAllowEdit={this.state.assignmentTaskAllowEdit}
                            themeSettings={themeSettings}
                        />
                        <UpdateAssignmentTaskReviewStatus
                            showUpdateReviewDialog={this.state.showUpdateReviewDialog}
                            handleUpdateReviewDialogClose={this.handleUpdateReviewDialogClose}
                            masterDataId={this.state.deleteReactivateId}
                            reviewLevel={this.state.reviewLevel}
                            themeSettings={themeSettings}
                        />

                        <SelectRiskDialog
                            showRiskDialog={this.state.showSelectRiskDialog}
                            handleSelectRiskDialogClose={this.handleSelectRiskDialogClose}
                            selectedRiskId={this.state.selectedAssignmentTaskRiskLevelId}
                            assignmentTaskId={this.state.selectedAssignmentTaskId}
                            riskArr={isEmptyArray(this.state.assignmentRiskMatrix) ? [] : this.state.assignmentRiskMatrix}
                            selectedLikelihoodRiskId={this.state.selectedLikelihoodRiskId}
                            likelihoodRiskMatrix={this.state.likelihoodRiskMatrix}
                            assignmentTaskAllowEdit={this.state.assignmentTaskAllowEdit}
                            themeSettings={themeSettings}
                            isLikelihood={this.state.isLikelihood}
                        />

                        <ExecTableDialog
                            showExecTableDialog={this.state.showExecTableDialog}
                            handleExecTableDialogClose={this.handleExecTableDialogClose}
                            assignmentTaskId={this.state.selectedAssignmentTaskId}
                            assignmentTaskAllowEdit={this.state.assignmentTaskAllowEdit}
                            fieldObj={this.state.templateFieldItem}
                            themeSettings={themeSettings}
                            saveTableValues={this.saveTableValues}
                        />
                    

                        {
                            // !isEmptyArray(this.state.valueAdditions) &&
                            this.state.executionTemplateFixedFields?.valueAddition === "Y" && (
                                <SelectValueAdditionDialog
                                    showVADialog={this.state.showSelectVADialog}
                                    handleSelectVADialogClose={this.handleSelectVADialogClose}
                                    selectedVAObj={this.state.selectedVAObj}
                                    assignmentTaskId={this.state.selectedAssignmentTaskId}
                                    valueAdditionArr={this.state.valueAdditions}
                                    themeSettings={themeSettings}
                                />
                            )
                        }

                        <PreviousAssignmentDialog
                            showDialogBox={this.state.showPreviousAssignmentData}
                            closeDialogBox={this.closePreviousAssignDialogBox}
                            assignmentExecuteFields ={this.state.assignmentExecuteFields}
                            dataList={this.state.previousAssignmentDataList}
                            themeSettings={themeSettings}
                        />

                        <ExecutionTasksActionDialog
                            showDialog={this.state.showExecutionTasksActionPopup}
                            handleExecutionTaskActionDialogClose={this.closeExecutionTaskActionDialog}
                            themeSettings={themeSettings}
                            handleExecutionTaskActionOk={this.handleExecutionTaskActionOk}
                        />

                        {/* {this.state.showAssignFunc &&  */}
                            <AssignFunctionDialog
                                showAssignFuncDialog={this.state.showAssignFunc}
                                assignmentDetails={this.state.assignmentDetails}
                                assignmentId={this.state.assignmentId}
                                company={this.state.company}
                                
                                selectFilterValue={this.state.selectFilterValue}
                                showWeight={this.state.showWeight}
                                showYesNoDD={this.state.showYesNoDD}
                                showRanking={this.state.showRanking}
                                myPosition={this.state.myPosition}
                                showChecklistScores={this.state.showChecklistScores}
                                execTaskTemplateFields={this.state.execTaskTemplateFields}
                                executionTemplateFixedFields={this.state.executionTemplateFixedFields}
                                selectedTaskTemplateFields={this.state.selectedTaskTemplateFields}

                                getTaskDisplayValues={this.getTaskDisplayValues}
                                getExecTaskDisplayTableData={this.getExecTaskDisplayTableData}
                                handleAssignFuncDialogClose={this.handleAssignFuncDialogClose}
                                dataList={this.state.taskDataList}
                                themeSettings={themeSettings}
                            />
                        {/* } */}
                        <CommonAlertMessageDialog
                            showAlertDialog={this.state.showQuillAlertDialog}
                            alertDialogHeadingIcon={'error_outline'}
                            type={Constants.ALERT_TYPE_INFO}
                            alertDialogMessage={"Are you sure you want to close the editor without saving?"}
                            proceedBtnClick={this.handleAlertDialogCloseButtonClick}
                            proceedBtnLabel={"Save"}
                            cancelBtnClick={this.handleAlertDialogSaveButtonClick}
                            cancelBtnLabel={"Close Without Saving"}
                            themeSettings={themeSettings}
                        />
                        <Modal className="task-select-table-dialog template-modal-editor" show={this.state.showFullScreenEditor} backdrop="static">
                            <div class="modal-body ">
                                <ReactQuill
                                    value={this.getExecTableDataDisplayValue(this.state.assignmentTaskId,this.state.fieldItem)} 
                                    onChange={this.handleChangeExecute.bind(this, this.state.assignmentTaskId, this.state.fieldItem)}
                                    modules={this.fullScreenModules}
                                    className="editor-height"
                                />
                                <span class="material-icons" onClick={() => this.handleQuillDialogCloseWithoutSaving()}>open_in_new_down</span>
                            </div>
                            <div class="modal-close-save">
                                <div class="close-save-col">
                                    <button onClick={() => this.handleQuillDialogClose()} type="button" class="modal-close">Cancel</button>
                                    <DialogSaveButton themeSettings={themeSettings} onClick={() => this.handleQuillDialogClose(true)} type="button">Save</DialogSaveButton>
                                </div>
                            </div>
                        </Modal>
                        {this.state.showFilter && 
                            <TemplateFieldsFilter
                                screenName={"Execute"}
                                showFilter={this.state.showFilter}
                                filterDataList={this.state.singleSelectFilter}
                                themeSettings={themeSettings}
                                handleCheck={this.handleCheck}
                                handleRiskCheck={this.handleRiskCheck}
                                handleWeightCheck={this.handleWeightCheck}
                                handleYesNoRankArr={this.handleYesNoRankArr}
                                handleRankingArr={this.handleRankingArr}
                                handleSaveFilter={this.handleSaveFilter}
                                handleResetFilter={this.handleResetFilter}
                                handleCancelFilter={this.handleCancelFilter}
                                weightArr={this.state.weightArr}
                                rankingArr={this.state.rankingArr}
                                yesNoRankArr={this.state.yesNoRankArr}
                                riskArr={this.state.riskArr}
                                assignRisk={this.state.assignRisk}
                            />
                        }
                    </section>
                </main>
            </Router>
        );
    }
}

export default OtherMasters;
