import React, { Component } from "react";
import * as Constants from "../../../common/Global/constants";
import { DialogSaveButton, FilterCheckBox } from "../../../common/Global/globalStyles.style";
import { getLocalStorageVariables, isEmptyVariable, isEmptyArray, getThemeSettingsVariables,isNumber } from "../../../common/Global/commonFunctions";
import { Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AlertDialog from "../../../common/AlertDialog";

const userDetails = getLocalStorageVariables();
const themeSettings = getThemeSettingsVariables();

class SelectValueAddtion extends Component {
	constructor(props) {
		super(props);
		this.state = {
			componentDidMountFlag: false,

			currentPageNo: 1,
			showLoader: false,
			resultSize: Constants.RESULT_SIZE,

			datalist: [],
			showAlertDialogInfo:false,
            alertDialogMessageInfo:""
		};
	}

	componentDidMount() {}

	componentDidUpdate(prevProps) {
		if (JSON.stringify(prevProps) !== JSON.stringify(this.props) && this.props.showVADialog) {
			let map = {};
            //sample 222:-:N:-:48;;23:-::-:47
            let vaTempArr = this.props.selectedVAObj.split(";;")
            vaTempArr.forEach(item=>{
                let vaItemArr = item.split(":-:");
                map[vaItemArr[2]] = {
                    valueAdditionValue:vaItemArr[0],
                    valueAddition_NR:vaItemArr[1]
                }
            })
			console.log(JSON.stringify(map));
			
			let valueAdditionArrNew = [];
			this.props.valueAdditionArr.forEach((item) => {
				if (!isEmptyVariable(map[item.valueAdditionId])) {
					item.value = map[item.valueAdditionId].valueAdditionValue;
					item.is_NRSelected = map[item.valueAdditionId].valueAddition_NR === "Y" ? true : false;
				}
				valueAdditionArrNew.push(item);
			});
			this.setState({
				datalist: valueAdditionArrNew,
				componentDidMountFlag: true,
			});
		}
	}
	handleChange = (e) => {
		const { name, value } = e.target;
		const index = name;
		let newDataList = this.state.datalist;
		newDataList[index].value = value;
		this.setState({
			datalist: newDataList,
		});
	};
	handleCheck = (e) => {
		let isChecked = e.target.checked;
		let index = e.target.name;
		let newDataList = this.state.datalist;
		newDataList[index].is_NRSelected = isChecked;
		if (isChecked) {
			newDataList[index].value = "";
		}
		this.setState({
			datalist: newDataList,
		});
	};
	handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
    }
	returnSelectedMasterId = () => {
		
		let isError = false;
		let vaList = [];

		this.state.datalist.forEach((item) => {

			if(item.isNumeric === "Y" && !item.is_NRSelected && 
				!isEmptyVariable(item.value) ){

					if(!isNumber(item.value)){
						isError = true;
						item.numericErr = " Value should be number";
					}
					
			}
			vaList.push(item)
		});

		if(!isError){
            let selectedVAObjArr = [];
			this.state.datalist.forEach((item) => {
				let obj = {
					valueAdditionId: item.valueAdditionId,
					valueAdditionValue: isEmptyVariable(item.value) ? "" : item.value,
					valueAddition_NR: isEmptyVariable(item.is_NRSelected) ? "" : item.is_NRSelected ? "Y" : "N",
				};
				selectedVAObjArr.push(obj);
			});

            let url = Constants.SaveAssignmentTaskValueAdditions;

            if(this.props.isDraftOnward){
                url = Constants.SaveDraftAssignmentTaskValueAdditions;
            }

            //api call
            fetch(url, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                    assignmentTaskId: this.props.assignmentTaskId,
                    valueAdditions: JSON.stringify(selectedVAObjArr),
                }),
            })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                if (data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID || data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED) {
                    localStorage.clear();
                    window.location = "/";
                } else if (data.responseCode === Constants.CODE_SUCCESS) {
                    this.props.handleSelectVADialogClose(true);
                } else {
                    this.setState({
                        showAlertDialogInfo:true,
            			alertDialogMessageInfo:data.responseMessage
                    });
                }
            });
	
		}else{
			this.setState({
				datalist:vaList
			});

			setTimeout(() => {
				vaList = [];
				this.state.datalist.forEach((item)=>{
					item.numericErr = ""
					vaList.push(item);
				});

				this.setState({
					datalist:vaList
				});
			},Constants.WRNG_MSG_TIMEOUT);

		}
	};
	/*****************************API*********************************/

	render() {
		return (
			<section>
				<Modal className="task-select-table-dialog custom-dialog" show={this.props.showVADialog} backdrop="static">
					<Modal.Header>
						<h5>Value Addition</h5>
						<button onClick={this.props.handleSelectVADialogClose} type="button" data-dismiss="modal">
							<span class="material-icons">close</span>
						</button>
					</Modal.Header>
					<hr />
					<div className="modal-body">
						{this.state.showLoader && (
							<div class="loader"></div>
						)}
						{
							// Need to check component did mount flag, other wise until api is called,
							// it shows no items found layout.
							this.state.componentDidMountFlag && (
								<div className="body-wrapper">
									<div style={{ position: "relative" }}>
										{
                                            !isEmptyArray(this.state.datalist) &&
											this.state.datalist.map((item, index) => {
												return (
													<div>
														<div class="addnew-modal-form-group">
															<div class="row custom-row">
																<div class="col-md-6 custom-col">
																	<div className="add-new-form-group">
																		<p>{item.name}</p>
																		<input
																			type="text"
																			placeholder="Enter Value"
																			name={index}
																			onChange={this.handleChange}
																			value={item.value}
																			disabled={item.is_NRSelected}
																		/>
																	</div>
																	{!isEmptyVariable(item.numericErr) && (
																		<span className="cm-error">{item.numericErr}</span>
																	)}
																</div>

																<div class="col-md-6 custom-col">
																	<div className="add-new-form-group">
																		<p>&nbsp;</p>
																		<FilterCheckBox themeSettings={themeSettings} style={{ padding: "7px 0px", marginLeft: 0 }}>
																			<input
																				type="checkbox"
																				name={index}
																				id={item.valueAdditionId}
																				onChange={this.handleCheck}
																				checked={item.is_NRSelected ? true : false}
																			/>
																			<label for={item.valueAdditionId}>Not Required</label>
																		</FilterCheckBox>
																	</div>
																</div>
																
															</div>
														</div>
													</div>
												);
											})
                                        }
                                        {/* {!isEmptyVariable(this.state.apiErr) && (
                                            <span className="cm-error">{this.state.apiErr}</span>
                                        )} */}
										{this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && (
											<div class="no-items-layout">
												<div class="no-items-card">
													<h6>{isEmptyVariable(this.state.apiSearchKey) ? Constants.NO_RECORDS_WARNING : Constants.EMPTY_SEARCH_WARNING}</h6>
												</div>
											</div>
										)}

										{/* <div className="overlay-nr" style={{ display: this.state.assertion_NR === "Y" ? "block" : "none" }}></div> */}
									</div>
									{/* {!isEmptyVariable(this.state.valueAdditionErr) && <span className="cm-error">{this.state.valueAdditionErr}</span>} */}
									<div className="modal-close-save m-t-15">
										<div className="close-save-col">
											<button onClick={this.props.handleSelectVADialogClose} type="button" className="modal-close m-b-0">
												Cancel
											</button>
											<DialogSaveButton
												themeSettings={this.props.themeSettings}
												onClick={this.returnSelectedMasterId}
												type="button"
												className="modal-save m-b-0"
											>
												Save
											</DialogSaveButton>
										</div>
									</div>
								</div>
							)
						}
					</div>
				</Modal>
				<AlertDialog
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type={Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                    themeSettings={this.props.themeSettings}
                />
			</section>
		);
	}
}

export default SelectValueAddtion;
