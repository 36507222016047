import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout,DialogSaveButton,FilterCheckBox} from '../../common/Global/globalStyles.style';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import TableDropDown from '../../common/DropdownMenus/TableDropdown';
import AlertDialog from '../../common/AlertDialog';
import Pagination from '../../common/Global/pagination';
import UpdateReviewStatusDialog from './checklistupdatereviewstatusdialog'
import ReviewHistoryDialog from '../../common/reviewhistorydialog'
import {getLocalStorageVariables,getThemeSettingsVariables,isEmptyVariable,sortTable,truncateString,
    SplitJoinString,isEmptyArray,ifEmptyReturnEmptyStr,getStatusSpan} from '../../common/Global/commonFunctions';
import {getCheckListDropdownActionArray} from '../../common/Global/reviewFunctions';
import HeadingTree from "./HeadingDialog/headingTree"
import HeadingTreeDetails from "./HeadingDialog/headingTreeDetails"
import ChecklistDetailsDialog from "./checklistDetailsDialog"
import ChecklistVersionsDialog from "./listChecklistVersions"

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();

const sortShortName = "checkListName";
const sortProcessNameList = "processNameList";
const sortType = "checkListType";

const activeStatus="Active";
const inActiveStatus="Inactive";
const underReviewStatus="Under Review";
const departmentFilterDropdownDefaultValue = "All";

class CheckLists extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:props.searchkey,
            searchkey:props.searchkey,
            currentPageNo:props.currentPageNo,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            resultSize:props.resultSize,
            resultSizePlaceholder:props.resultSize,
            sort:props.sort,
            sortDir:props.sortDir,

            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            reviewLevel:"",
            alertDialogMessage:"",
            alertDialogHeading:"",
            proceedBtnLabel:"",
            operationSelectedDeptId:"",
            actionType:"",
            showBtnLoader:false,

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            showUpdateReviewDialog:false,
            showReviewHistoryDialog:false,

            status:props.status,
            statusPlaceholder:props.status,
            statusArr:[
                {
                    label:activeStatus,
                    isSelected:props.status===activeStatus?true:false
                },
                {
                    label:underReviewStatus,
                    isSelected:props.status===underReviewStatus?true:false
                },
                {
                    label:inActiveStatus,
                    isSelected:props.status===inActiveStatus?true:false
                },
            ],
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,

            // formErrors:{},
            submitClickedAtleastOnce:false,

            showChecklistDetailsDialog:false,
            selectedItem:{},

            showHeadingTreeDetailsDialog:false,

            showChecklistVersionsDialog: false,

        }
    }

    componentDidMount(){
        this.getInitData();
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getCheckLists();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getCheckLists();
            })
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleChecklistDetailsDialogClose = () => {
        this.setState({
            showChecklistDetailsDialog:false,
            selectedItem:{}
        })
    }

    handleHeadingTreeDialogClose = () => {
        this.setState({
            showHeadingTreeDialog:false,
            deleteReactivateId:"",
            rootNode:"",
        })
    }

    handleHeadingTreeDetailsDialogClose = () => {
        this.setState({
            showHeadingTreeDetailsDialog:false,
            deleteReactivateId:"",
            headingTreeChecklistName:"",
            rootNodeDetails:"",
        })
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo:1,
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getCheckLists();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getCheckLists();
        })
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Delete Master",
                proceedBtnLabel:"Delete",
                deleteReactivateId:dropdownItem.checkListVersionId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete checklist "+dropdownItem.checkListName+"?"
            })
        }else if(item.label === "Reactivate"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Activate Master",
                proceedBtnLabel:"Reactivate",
                deleteReactivateId:dropdownItem.checkListVersionId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate checklist "+dropdownItem.checkListName+"?"
            })
        }else if(item.label === "Edit"){
            this.props.handleActionDropdownItemClick(dropdownItem,this.state.currentPageNo,
            this.state.searchkey,
            this.state.resultSize,
            this.state.sort,this.state.sortDir,this.state.status,"addNewChecklist");
        }else if(item.label === "Add/Remove Tasks"){
            this.setState({
                showHeadingTreeDialog:true,
                deleteReactivateId:dropdownItem.checkListVersionId,
                rootNode:dropdownItem,
                operationSelectedDeptId:dropdownItem.departmentId,
            })
        }else if(item.label === "Submit For Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Submit For Review",
                proceedBtnLabel:"Submit",
                deleteReactivateId:dropdownItem.checkListVersionId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to submit "+dropdownItem.checkListName+" for review?"
            })
        }else if(item.label === "Start Review"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Start Review",
                proceedBtnLabel:"Start",
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.checkListVersionId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to start review of "+dropdownItem.checkListName
            })
        }else if(item.label === "Update Review Status"){
            this.setState({
                reviewLevel:dropdownItem.reviewLevel,
                deleteReactivateId:dropdownItem.checkListVersionId,
                showUpdateReviewDialog:true,
            })
        }else if(item.label === "Review History"){
            this.setState({
                deleteReactivateId:dropdownItem.checkListVersionId,
                showReviewHistoryDialog:true,
            })
        }else if(item.label === "Review Remarks"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Review Remarks",
                proceedBtnLabel:"Okay",
                showAlertDialog:true,
                alertDialogMessage:'\"'+dropdownItem.lastReviewRemark+'\"'
            })
        }else if(item.label === "Details"){
            this.handleDetailsDialogShow(dropdownItem)
        }else if(item.label === "Versions"){
            this.setState({
                
                showChecklistVersionsDialog:true,
                deleteReactivateId:dropdownItem.checkListVersionId,

            })
        }else if(item.label === "Discard Changes"){
            this.setState({
                operationType:item.label,
                alertDialogHeading:"Discard Changes",
                proceedBtnLabel:"Discard",
                deleteReactivateId:dropdownItem.checkListVersionId,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to discard changes of "+dropdownItem.checkListName+"?",
                actionType: dropdownItem.previousStatus === "Updated"?"Update":(dropdownItem.previousStatus === "Deleted"?"Delete":"")
            })
        }
    }

    handleDetailsTreeComponentShow = (dropdownItem) => {
        this.setState({
            showHeadingTreeDetailsDialog:true,
            deleteReactivateId:dropdownItem.checkListVersionId,
            headingTreeChecklistName:dropdownItem.checkListName,
            rootNodeDetails:dropdownItem,
            operationSelectedDeptId:dropdownItem.departmentId,
        })
    }

    handleReviewHistoryDialogClose = () =>{
        this.setState({
            showReviewHistoryDialog:false,
            deleteReactivateId:"",
        });
    }

    handleUpdateReviewDialogClose = (reloadFlag) =>{
        this.setState({
            showUpdateReviewDialog:false,
            deleteReactivateId:"",
            reviewLevel:"",
        },()=>{
            if(reloadFlag === true){
                this.getCheckLists();
            }
        });
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getCheckLists();
        })
    }

    addNewChecklist = () => {
        this.props.handleActionDropdownItemClick("",this.state.currentPageNo,this.state.searchkey,
        this.state.resultSize,
        this.state.sort,this.state.sortDir,this.state.status,"addNewChecklist");

    }

    handleDetailsDialogShow = (item) => {
        this.setState({
            showChecklistDetailsDialog:true,
            selectedItem:item
        })
    }

    deptFilterDropdownClick = (item,selectedIndex) => {
        let placeholder = "";
        let id = "";
        let filterDeptArr = this.state.filterDeptArr;

        for(const [i,value] of filterDeptArr.entries()){
            if(i === selectedIndex){
                filterDeptArr[i].isSelected = true;
                placeholder = value.name;
                id = value.departmentId;
            }else{
                filterDeptArr[i].isSelected = false;
            }
        }
        
        this.setState({
            currentPageNo: 1,
            selectedFilterDepartmentName:placeholder,
            selectedFilterDepartmentId:id
        },() => {
            this.getCheckLists();
        })
    }

    handleChecklistVersionsDialogShow = () => {
		this.setState({
			showChecklistVersionsDialog: true,
		});
	};

	handleChecklistVersionsDialogClose = () => {
		this.setState({
			showChecklistVersionsDialog: false,
            deleteReactivateId:""
		});
	};

    /*******************API CALLS*******************/
    getInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetChecklists,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                })
            })
        ])
        .then(([generalRes]) => { 
            return Promise.all([generalRes.json()]) 
        })
        .then(([generalRes]) => {
            let datalistTemp = [];
            let totalCountTemp = 0;

            if(generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                generalRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(generalRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = generalRes.data.result;
                totalCountTemp = generalRes.data.count;
            }else{
                datalistTemp = [];
            }

            //Filter Dept array
            let filterDeptArr = [];
            let selectedFilterDepartmentId = "-100";
            let selectedFilterDepartmentName = "All";
            if(!isEmptyArray(this.props.departments) && this.props.departments.length===1){

            }else{
                let obj = {
                    departmentId:"-100",
                    name:departmentFilterDropdownDefaultValue,
                    isSelected:true
                }
                filterDeptArr = JSON.parse(JSON.stringify(this.props.departments));
                filterDeptArr.splice(0,0,obj);
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                datalist:datalistTemp,
                totalCount:totalCountTemp,

                filterDeptArr:filterDeptArr,
                selectedFilterDepartmentId:selectedFilterDepartmentId,
                selectedFilterDepartmentName:selectedFilterDepartmentName,
            })
        })
    }

    getCheckLists = () => {
        this.setState({
            showLoader:true,
        });

        let postParams = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            pageNo:this.state.currentPageNo,
            search:this.state.searchkey,
            resultsize:this.state.resultSize,
            status:this.state.status,
            sort:this.state.sort,
            sortDir:this.state.sortDir,
        }

        if(this.state.filterDeptArr.length > 1 &&
            this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
                postParams.departmentId = this.state.selectedFilterDepartmentId;
        }

        fetch(Constants.GetChecklists,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams(postParams)
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            let postParams = {
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:page,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                status:this.state.status,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
            }

            if(this.state.filterDeptArr.length > 1 &&
                this.state.selectedFilterDepartmentName !== departmentFilterDropdownDefaultValue){
                    postParams.departmentId = this.state.selectedFilterDepartmentId;
            }

            fetch(Constants.GetChecklists,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParams)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    handleRecordOperation = () => {
        let url = "";
        let postParam = {
            email:userDetails.email,
            accessToken:userDetails.accessToken,
            checkListVersionId:this.state.deleteReactivateId,
        }
        let callApi = true;

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteChecklist;
        }else if(this.state.operationType === "Reactivate"){
            url = Constants.ActivateChecklist;
        }else if(this.state.operationType === "Discard Changes"){
            url = Constants.DiscardChangesChecklist;
            postParam.actionType = this.state.actionType;
        }else if(this.state.operationType === "Submit For Review"){
            url = Constants.SubmitForReviewChecklist;
        }else if(this.state.operationType === "Start Review"){
            url = Constants.UpdateChecklistReviewStatus;
            postParam.reviewLevel = this.state.reviewLevel;
            postParam.reviewStatus = Constants.REVIEW_STATUS_STARTED;
        }else if(this.state.operationType === "Review Remarks"){
            callApi = false;
            this.setState({
                operationType:"",
                alertDialogHeading:"",
                proceedBtnLabel:"",
                alertDialogMessage:"",
                showAlertDialog:false,
            })
        }

        if(callApi){
            this.setState({
                showBtnLoader:true,
            });

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams(postParam)
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        deleteReactivateId:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        actionType:"",
                        showBtnLoader:false
                    },() => {
                        this.getCheckLists();
                    });
                }else{
                    this.setState({
                        showLoader:false,
                        deleteReactivateId:"",
                        operationType:"",
                        alertDialogHeading:"",
                        proceedBtnLabel:"",
                        alertDialogMessage:"",
                        showAlertDialog:false,
                        actionType:"",
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage,
                        showBtnLoader:false
                    })
                }
            });
        }
    }

    render(){
        return(
            <div>
                {
                    this.state.showLoader &&
                    <div class="loader"></div>
                }
                {
                    // Need to check component did mount flag, other wise until api is called,
                    // it shows no items found layout.
                    this.state.componentDidMountFlag &&
                    !this.state.showHeadingTreeDetailsDialog &&
                    <div className="body-wrapper">
                        <div className="search-addnew-bg">
                            <div className="search-addnew">
                                
                                <div className="search-filter-layout">
                                    <FilterDropDown
                                        placeholder={this.state.resultSizePlaceholder}
                                        dropdownArr={this.state.resultSizeArr}
                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                        themeSettings={themeSettings}
                                        mleft={0}
                                    />
                                    <div className="search-col m-l-10">
                                        <input 
                                            name="searchkey" 
                                            type="text"
                                            onChange={this.handleChangeSearch}
                                            placeholder="Search" 
                                            onKeyPress={this.onEnterBtnPress}
                                            value={this.state.searchkey}
                                        />
                                        <SearchSpan 
                                        themeSettings={themeSettings}
                                        onClick = {this.onSearchIconClick} 
                                        className="material-icons"> search </SearchSpan>
                                    </div>

                                    <FilterDropDown
                                        placeholder={this.state.statusPlaceholder}
                                        dropdownArr={this.state.statusArr}
                                        onDropDownItemClick={this.statusDropdownClick}
                                        themeSettings={themeSettings}
                                    />
                                    
                                    {
                                        this.state.filterDeptArr.length > 0 &&
                                        <FilterDropDown
                                            placeholder={this.state.selectedFilterDepartmentName}
                                            dropdownArr={this.state.filterDeptArr}
                                            onDropDownItemClick={this.deptFilterDropdownClick}
                                            name={"name"}
                                            themeSettings={themeSettings}
                                            dropdownWidth={"120px"}
                                        />
                                    }
                                </div>
                                {
                                    this.props.masterMenus[Constants.SCREEN_CHECKLIST] ==="ALL" && (
                                        <AddNewButtonLayout themeSettings={themeSettings}>
                                            <a onClick={this.addNewChecklist} href="javascript:void(0);">
                                                <span className="material-icons">add_circle</span>
                                                <p>Add New</p>
                                            </a>
                                        </AddNewButtonLayout>
                                    )   
                            }
                            </div>
                        </div>

                        {
                            !isEmptyArray(this.state.datalist) && 
                            <div className="common-tab-bg">
                                <div className="common-table" style={{width:"100%"}}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                            <th className="text-center" width={"5%"}>No.</th>
                                            <th width={"25%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortShortName)}>
                                                <div className="sort-header">Name
                                                <span className={(this.state.sort === sortShortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            {/* <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortName)}> */}
                                            { this.props.company?.companyType!== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                <th >
                                                    <div className="sort-header">Company Name
                                                    {/* <span className={(this.state.sort === sortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span> */}
                                                    </div>
                                                </th>
                                            }
                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortProcessNameList)}>
                                                <div className="sort-header">Process
                                                <span className={(this.state.sort === sortProcessNameList)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            <th width={"7%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortType)}>
                                                <div className="sort-header">Type
                                                <span className={(this.state.sort === sortType)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                </div>
                                            </th>
                                            <th width={"7%"} >
                                                <div className="sort-header">Version
                                                </div>
                                            </th>
                                            {/* <th width={"10%"}>Users Count</th> */}
                                            <th className="text-center"  width={"5%"}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            !isEmptyArray(this.state.datalist) && 
                                            this.state.datalist.map((item,idx) => {

                                                let actionObj = getCheckListDropdownActionArray(item.allowEdit, item.allowReview, 
                                                    item.status, item.currentReviewStatus, this.props.deletePermission, item.previousStatus);
                                                let spanObj = getStatusSpan(item.previousStatus,themeSettings);
                                                return <tr onClick={this.handleDetailsTreeComponentShow.bind(this,item)}>
                                                    <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                    <td>
                                                        {item.checkListName}
                                                        {
                                                            this.state.status === "Under Review" &&
                                                            <span style={spanObj.spanStyle} className="span-tag">
                                                                {spanObj.spanText}
                                                            </span>
                                                        }
                                                    </td>
                                                    { this.props.company?.companyType!== Constants.USER_ENGAGEMENT_TYPE_SELF &&
                                                        <td>{truncateString(SplitJoinString(item.companyList,",",":-:",","),Constants.OTHERMASTERS_STRING_LENGTH)}</td>
                                                    }
                                                    <td>{ifEmptyReturnEmptyStr(item.processNameList,"-")}</td>
                                                    <td>{item.checkListType}</td>
                                                    <td className="text-center" >{item.version}</td>
                                                    <td className="text-center">
                                                        {
                                                            actionObj.actionArr && actionObj.actionArr.length > 0 &&
                                                            <TableDropDown 
                                                                actionArr={actionObj.actionArr}
                                                                onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                dropDownId = {item}
                                                                dropdownWidth={actionObj.dropdownWidth}
                                                                themeSettings={themeSettings}
                                                            />
                                                        }
                                                    </td>
                                                </tr>
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {
                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                            <div class="no-items-layout">
                                <div class="no-items-card">
                                    <h6>
                                    {
                                        isEmptyVariable(this.state.apiSearchKey)?
                                        Constants.NO_RECORDS_WARNING
                                        :
                                        Constants.EMPTY_SEARCH_WARNING
                                    }
                                    </h6>
                                </div>
                            </div>
                        }
                        <div className="pagination-layout">
                            <div className="row custom-row">
                                <div className="col-md-9 custom-col">
                                {
                                    (this.state.totalCount > this.state.datalist.length) && 
                                    <Pagination 
                                        totalLength ={this.state.totalCount} 
                                        items={this.state.datalist} 
                                        onChangePage={this.onChangePage} 
                                        pageSize={this.state.resultSize}
                                        currentPageNo = {this.state.currentPageNo}
                                        initialPage={this.state.currentPageNo} 
                                        themeSettings={themeSettings}/>
                                }
                                </div>
                                <div className="col-md-3 custom-col">
                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.showHeadingTreeDetailsDialog &&
                    <HeadingTreeDetails
                        showHeadingTreeDialog={this.state.showHeadingTreeDetailsDialog}
                        handleHeadingTreeDialogClose={this.handleHeadingTreeDetailsDialogClose}
                        rootCheckListVersionId={this.state.deleteReactivateId}
                        rootNodeDetails={this.state.rootNodeDetails}
                        headingTreeChecklistName={this.state.headingTreeChecklistName}
                        themeSettings={themeSettings}

                        currentPageNo={this.state.currentPageNo}
                        searchkey={this.state.searchkey}
                        resultSize={this.state.resultSize}
                        sort={this.state.sort}
                        sortDir={this.state.sortDir}
                        status={this.state.status}
                        departmentId= {this.state.operationSelectedDeptId}
                        handleActionDropdownItemClick={this.props.handleActionDropdownItemClick}
                    />
                }

                <AlertDialog
                    showAlertDialog={this.state.showAlertDialog}
                    handleAlertDialogClose={this.handleAlertDialogClose}
                    type={
                        (this.state.operationType === "Delete")
                        ?
                        Constants.ALERT_TYPE_WARNING
                        :
                        (this.state.operationType === "Review Remarks")
                        ?
                        Constants.ALERT_TYPE_ALERT
                        :
                        Constants.ALERT_TYPE_INFO
                    }
                    alertDialogHeading={this.state.alertDialogHeading}
                    alertDialogMessage={this.state.alertDialogMessage}
                    proceedBtnClick={this.handleRecordOperation}
                    proceedBtnLabel={this.state.proceedBtnLabel}
                    showLoader={this.state.showBtnLoader}
                    themeSettings={themeSettings}
                />

                <AlertDialog 
                    showAlertDialog={this.state.showAlertDialogInfo}
                    handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                    type= {Constants.ALERT_TYPE_ALERT}
                    alertDialogMessage={this.state.alertDialogMessageInfo}
                    proceedBtnClick={this.handleAlertDialogCloseInfo}
                    proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                    themeSettings={themeSettings}
                />

                <HeadingTree
                    rootCheckListVersionId={this.state.deleteReactivateId}
                    themeSettings={themeSettings}
                    showHeadingTreeDialog={this.state.showHeadingTreeDialog}
                    rootNode={this.state.rootNode}
                    handleHeadingTreeDialogClose={this.handleHeadingTreeDialogClose}

                    currentPageNo={this.state.currentPageNo}
                    searchkey={this.state.searchkey}
                    resultSize={this.state.resultSize}
                    sort={this.state.sort}
                    sortDir={this.state.sortDir}
                    status={this.state.status}
                    departmentId= {this.state.operationSelectedDeptId}
                    handleActionDropdownItemClick={this.props.handleActionDropdownItemClick}
                />

                <UpdateReviewStatusDialog
                    showUpdateReviewDialog={this.state.showUpdateReviewDialog}
                    handleUpdateReviewDialogClose={this.handleUpdateReviewDialogClose}
                    masterType = {""}
                    masterDataId = {this.state.deleteReactivateId}
                    reviewLevel={this.state.reviewLevel}
                    themeSettings={themeSettings}
                />

                <ReviewHistoryDialog
                    showReviewHistoryDialog={this.state.showReviewHistoryDialog}
                    handleReviewHistoryDialogClose={this.handleReviewHistoryDialogClose}
                    masterType = {"Checklist"}
                    masterDataId = {this.state.deleteReactivateId}
                />

                <ChecklistDetailsDialog
                    showChecklistDetailsDialog = {this.state.showChecklistDetailsDialog}
                    handleChecklistDetailsDialogClose = {this.handleChecklistDetailsDialogClose}
                    selectedItem = {this.state.selectedItem}
                    company={this.props.company}
                />
                <ChecklistVersionsDialog
                    showChecklistVersionsDialog={this.state.showChecklistVersionsDialog}
                    handleChecklistVersionsDialogClose={this.handleChecklistVersionsDialogClose}
                    checkListVersionId = {this.state.deleteReactivateId}
                    themeSettings={themeSettings}
                    company={this.props.company}
                />
            </div>
        )
    }
}

export default CheckLists;