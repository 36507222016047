import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import AlertDialogRemoveResource from '../../../common/AlertDialog/alertwithradioRemoveResourceFollowUp';
import AlertDialogAddResource from '../../../common/AlertDialog/alertAddUser';
import {isEmptyVariable,isEmptyArray,
    getLocalStorageVariables,
    ifEmptyReturnEmptyStr} from '../../../common/Global/commonFunctions';
import {ResouceAddButton,ResouceRemoveButton, TreeNodeCheckBox} from '../../../common/Global/globalStyles.style';
import AlertDialog from '../../../common/AlertDialog';

let userDetails = {};

class AssignResourcesCompEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            showLoader:false,
            totalCount:0,
            currentPageNo: 1,
            // formErrors:{},
            reviewCycleReq:"Y",

            selectedL0Ids:[],
            selectedViewIds:[],

            selectedL1User:{},
            selectedL2User:{},
            selectedL3User:{},
            selectedL4User:{},
            selectedL5User:{},
            selectedL6User:{},
            selectedL7User:{},
            createResourceResponse:"",

            showAlertDialog:false,

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",
        }
    }

    componentDidMount(){
        userDetails  = getLocalStorageVariables();
        this.setState({
            reviewCycleReq:ifEmptyReturnEmptyStr(this.props.reviewAssignmentTask,"Y")
        },()=>{
            this.getEditInitData();
        })
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleSelectionReviewCycle = (e) => {
        let isChecked = e.target.checked;

        this.setState({
            reviewCycleReq:isChecked?"Y":"N",
        },()=>{
            //TODO API CALL - updatereviewassignmenttask

            fetch(Constants.UpdateReviewAssignmentTask,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    reviewAssignmentTask:this.state.reviewCycleReq,
                    assignmentId:this.props.assignmentId
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                }else{
                    this.setState({
                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage
                    })
                }
            });

        })
    }

    handleRemoveAlertDialogShow = (user,level) => {
        if(this.props.selectedAssignmentStatus === "Completed" || this.state.addedResources.length > 1){
            let resources = [];
            // if(level === "L0"){
            //     resources = this.state.addedResources.filter(item => item.userId != user.userId);
            // }else{
                resources = this.state.remainingResources;
            // }
            this.setState({
                showAlertDialog:true,
                alertDialogHeading:"Remove Resource",
                alertDialogMessage:"Are you sure you want to remove resource "+user.fullName+"?",
                proceedBtnLabel:"Remove",
                resourcesListBasedOnLevel:resources,
                removeUserId:user.userId,
                isLastResource:this.state.addedResources.length > 1?"N":"Y"
            })
        }else{
            this.setState({
                showAlertDialogInfo:true,
                alertDialogMessageInfo:"You are not allowed to delete the last assigned resource. Please add a new resource or choose a replacement resource"
            })
        }
    }

    handleRemoveAlertDialogClose = (reloadFlag) => {
        this.setState({
            showAlertDialog:false,
            alertDialogHeading:"",
            alertDialogMessage:"",
            proceedBtnLabel:"",
            removeUserId:"",
            resourcesListBasedOnLevel:[],
            isLastResource:"N"
        },()=>{
            if(reloadFlag === true) this.getEditInitData();
        })
    }

    handleAddAlertDialogShow = (level) => {
        this.setState({
            showAddAlertDialog:true,
            alertDialogHeading:"Add Resource",
            proceedBtnLabel:"Add",
            addUserLevel:level,
        })
    }

    handleAddAlertDialogClose = (reloadFlag) => {
        this.setState({
            showAddAlertDialog:false,
            alertDialogHeading:"",
            proceedBtnLabel:"",
            addUserLevel:"",
        },()=>{
            if(reloadFlag === true) this.getEditInitData();
        })
    }

    getEditInitData = () => {
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetFollowUpAssignedResources,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId:this.props.assignmentId,
                })
            }),
            fetch(Constants.AssignResourceMasterData,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId:this.props.assignmentId,
                })
            })
        ])
        .then(([assignedRes,masterRes]) => { 
            return Promise.all([assignedRes.json(),masterRes.json()]) 
        })
        .then(([assignedRes,masterRes]) => { 
            let datalist =[];
            let assignmentReviewMatrix =[];
            let assignmentMemberMatrix =[];
            // let assignmentViewMatrix =[];

            if(assignedRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignedRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(assignedRes.responseCode === Constants.CODE_SUCCESS){
                assignmentMemberMatrix = assignedRes.data.assignmentMemberMatrix;
                assignmentReviewMatrix = assignedRes.data.assignmentReviewMatrix;
                // assignmentViewMatrix = assignedRes.data.assignmentViewMatrix;
            }else{
            }

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                datalist = masterRes.data.users;
            }else{
            }

            //Lets modify the datalist
            let selectedL0Ids = assignmentMemberMatrix.map(resItem => {
                return parseInt(resItem.userId);
            }) 

            let datalistNew = datalist.map(item=>{
                let itemNew = item;
                for (const [index, value] of assignmentMemberMatrix.entries()) {
                    if(value.userId+"" === item.userId+""){
                        itemNew.isSelected = true;
                        itemNew.position = "TM";
                        itemNew.status = value.status;
                    }
                }
                return itemNew;
            })
            //Lets modify the datalist
            // let selectedViewIds = assignmentViewMatrix.map(resItem => {
            //     return parseInt(resItem.userId);
            // }) 
            // let datalistNew2 = datalistNew.map(item=>{
            //     let itemNew = item;
            //     for (const [index, value] of assignmentViewMatrix.entries()) {
            //         if(value.userId+"" === item.userId+""){
            //             itemNew.isSelected = true;
            //             itemNew.position = "View";
            //             itemNew.status = value.status;
            //         }
            //     }
            //     return itemNew;
            // })

            let selectedReviewUsers = {
                selectedL1User:{},
                selectedL2User:{},
                selectedL3User:{},
                selectedL4User:{},
                selectedL5User:{},
                selectedL6User:{},
                selectedL7User:{},
            }

            let finalDataList = [];

            if(isEmptyArray(assignmentReviewMatrix)){
                finalDataList = datalistNew;
            }else{
                    // selectedReviewUsers["selectedL"+value.level+"User"] = value;
                    // finalDataList = this.changeMainArray(datalistNew2,true,"L"+value.level,value.userId,value.status)
                    finalDataList = datalistNew.map(item=>{
                        let itemNew = item;
                        for(const [index,value] of assignmentReviewMatrix.entries()){
                            selectedReviewUsers["selectedL"+value.level+"User"] = value;
                            if(value.userId+"" === item.userId+""){
                                itemNew.isSelected = true;
                                itemNew.position = "L"+value.level;
                                itemNew.status = value.status;
                            }
                        }
                        return itemNew;
                    })
            }

            let remainingResources = finalDataList.filter(item=>item.isSelected !== true);
            let addedResources = finalDataList.filter(item=>item.isSelected === true && item.status === "Active");
            
            this.setState({
                componentDidMountFlag:true,
                showLoader:false,

                assignmentMemberMatrix:assignmentMemberMatrix,

                remainingResources:remainingResources,
                addedResources:addedResources,

                selectedL0Ids:selectedL0Ids,
                // selectedViewIds:selectedViewIds,
                ...selectedReviewUsers
            })
        })
    }

    render() {
        return(
            <>
            <div className="modal-body">
                {
                    this.state.showLoader &&
                    <div class="loader"></div>
                }
                
                {
                    this.state.componentDidMountFlag &&
                    <>
                    <div className="row custom-row">
                        <div className="col-md-6 custom-col reassign-layout">
                            <p className='heading'>Team Members</p>
                            {
                                this.state.assignmentMemberMatrix.map(item=>{
                                    return <div className='flex-center-layout just-space-between m-b-10'>
                                        <p>
                                            {item.fullName}
                                            {
                                                item.status === "Inactive" &&
                                                <span style={{background:"#ddd",color:"#111",display:"inline-block"}} 
                                                className="span-tag">
                                                    Removed
                                                </span>
                                            }
                                        </p>
                                        {
                                            item.status === "Active" &&
                                            <ResouceRemoveButton 
                                            themeSettings={this.props.themeSettings}
                                            onClick={this.handleRemoveAlertDialogShow.bind(this,item,"L0")}>
                                                Remove
                                            </ResouceRemoveButton>
                                        }
                                    </div>
                                })
                            }

                            <ResouceAddButton 
                            themeSettings={this.props.themeSettings}
                            onClick={this.handleAddAlertDialogShow.bind(this,"L0")}>
                                Add Team Member
                            </ResouceAddButton>

                        </div>
                        <div className="col-md-6 custom-col reassign-layout">
                            {
                                [1,2,3,4,5].map(item=>{
                                    let userObj = this.state["selectedL"+item+"User"];
                                    if(!isEmptyVariable(userObj?.userId)){
                                        return <>
                                            <p className='heading'>
                                                {
                                                    item === 1
                                                    ?
                                                    "Team Leader"
                                                    :
                                                    "Review Level "+(item-1)
                                                }
                                            </p>
                                            <div className='flex-center-layout just-space-between m-b-10'>
                                                {
                                                    userObj.status === "Inactive" ?
                                                    <p style={{opacity:0.7}}><i>Not Assigned</i></p>
                                                    :<p>{userObj.fullName}</p>
                                                }
                                                {
                                                    userObj.status === "Active" &&
                                                    <ResouceRemoveButton 
                                                    themeSettings={this.props.themeSettings}
                                                    onClick={this.handleRemoveAlertDialogShow.bind(this,userObj,"L"+item)}>
                                                        Remove
                                                    </ResouceRemoveButton>
                                                }
                                                {
                                                    userObj.status === "Inactive" &&
                                                    <ResouceAddButton 
                                                    themeSettings={this.props.themeSettings}
                                                    onClick={this.handleAddAlertDialogShow.bind(this,"L"+item)}
                                                    >
                                                        Add
                                                    </ResouceAddButton>
                                                }
                                            </div>
                                            {
                                                !isEmptyVariable(userObj.origUserId) &&
                                                <div className='flex-center-layout just-space-between m-b-10'>
                                                    <p>
                                                        {userObj.origUserName+"("+userObj.origDesignation+")"}
                                                        <span style={{background:"#ddd",color:"#111",display:"inline-block"}} 
                                                        className="span-tag">
                                                            Removed
                                                        </span>
                                                    </p>
                                                </div>
                                            }

                                        </>
                                    }else{
                                        return <>
                                            <p className='heading'>
                                                {
                                                    item === 1
                                                    ?
                                                    "Team Leader"
                                                    :
                                                    "Review Level "+(item-1)
                                                }
                                            </p>
                                            <div className='flex-center-layout just-space-between m-b-10'>
                                                <p style={{opacity:0.7}}><i>Not Assigned</i></p>
                                                <ResouceAddButton 
                                                themeSettings={this.props.themeSettings}
                                                onClick={this.handleAddAlertDialogShow.bind(this,"L"+item)}
                                                >
                                                    Add
                                                </ResouceAddButton>
                                            </div>

                                        </>
                                    }
                                })
                            }
                        </div>
                    </div>
                    </>
                }
            </div>

            <AlertDialogRemoveResource
                showAlertDialog={this.state.showAlertDialog}
                alertDialogHeading={this.state.alertDialogHeading}
                alertDialogMessage={this.state.alertDialogMessage}
                proceedBtnLabel={this.state.proceedBtnLabel}
                removeUserId={this.state.removeUserId}
                usersList={this.state.resourcesListBasedOnLevel}
                handleAlertDialogClose={this.handleRemoveAlertDialogClose}
                assignmentId = {this.props.assignmentId}
                selectedAssignmentStatus = {this.props.selectedAssignmentStatus}
                isLastResource = {this.state.isLastResource}
            />
            <AlertDialogAddResource
                showAlertDialog={this.state.showAddAlertDialog}
                alertDialogHeading={this.state.alertDialogHeading}
                proceedBtnLabel={this.state.proceedBtnLabel}
                usersList={this.state.remainingResources}
                addUserLevel={this.state.addUserLevel}
                resourceAssignmentType = {"Follow Up"}
                handleAlertDialogClose={this.handleAddAlertDialogClose}
                assignmentId = {this.props.assignmentId}
                themeSettings={this.props.themeSettings}
            />
            <AlertDialog 
                showAlertDialog={this.state.showAlertDialogInfo}
                handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                type= {Constants.ALERT_TYPE_ALERT}
                alertDialogMessage={this.state.alertDialogMessageInfo}
                proceedBtnClick={this.handleAlertDialogCloseInfo}
                proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                themeSettings={this.props.themeSettings}
            />
            </>
        );
    }
}

export default AssignResourcesCompEdit;