import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable, isEmptyArray, truncateString} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import {DialogSaveButton,TreeNodeCheckBox, ExpandCollapseWrapper} from '../../../common/Global/globalStyles.style';

const userDetails  = getLocalStorageVariables();

const FunctionTree = (props) => {

    return <div className="dialog-tree-structure">
        <div className="tree-node checkboxparent" style={{marginLeft:(props.level*40)+"px"}}>
            <span 
                onClick={()=>props.onClickNode(props.functionObj)}
                class="material-icons" style={{opacity:isEmptyArray(props.functionObj.children)?"0":"1"}}>
                {props.functionObj.isShowingChildren?"keyboard_arrow_down":"keyboard_arrow_right"}
            </span>
            <TreeNodeCheckBox
            themeSettings={props.themeSettings}>
                <input 
                    type="checkbox"
                    name={props.functionObj.functionId}
                    id={props.functionObj.functionId}
                    onChange={props.handleSelection}
                    checked={props.functionObj.isSelected?true:false}
                    disabled={props.functionObj.isDisabled}
                />
                <label for={props.functionObj.functionId} 
                style={{marginLeft:6}}>{props.functionObj.functionName}</label>
            </TreeNodeCheckBox>
            {/* <p for={props.functionObj.functionId}>{props.functionObj.functionName}</p> */}
        </div>

        {
            //Exit Condition
            props.functionObj.isShowingChildren &&
            props.getChildNodes(props.functionObj).map((child, idx)=>{
                let levelTemp = props.level + 1;
                return <FunctionTree 
                    functionObj = {child} 
                    getChildNodes = {props.getChildNodes}
                    level={levelTemp}
                    handleSelection={props.handleSelection}
                    onClickNode={props.onClickNode}
                    themeSettings={props.themeSettings}
                />
            })
        }
    </div>
}

class SelectFunctionDialog extends Component{
    constructor(props){
        super(props);
        this.state={
            functionMap:{},
            rootNodes:[],
            refreshFlag:false,
            showLoader:false,
            functionFormErr:""
        }
        this.selectedFunctionObjArr = [];
    }

    componentDidUpdate(prevProps){
        if(JSON.stringify(prevProps) !== JSON.stringify(this.props) && 
        this.props.showFunctionsDialog){
            this.selectedFunctionObjArr = [];
            this.setState({
                functionMap:{},
                rootNodes:[],
                functionFormErr:"",
                refreshFlag:false,
                showLoader:false,
            },()=>{
                this.selectedFunctionObjArr = this.props.selectedFunctionObjArr;
                this.getFunctionTree();
            })
            
        }
    }

    onClickNode = (node) => {
        if(this.state.functionMap[node.functionId].isShowingChildren){
            this.state.functionMap[node.functionId].isShowingChildren = false;
        }else{
            this.state.functionMap[node.functionId].isShowingChildren = true;
        }
        
        this.setState({
            refreshFlag:!this.state.refreshFlag
        })
    }

    handleSelectFuncDialogClose = () => {

    }

    getRootNodes = (functionMap) => {
        return Object.values(functionMap).filter(node => node.level === 0)
    }

    getChildNodes = (node) => {
        if(isEmptyArray(node.children)){
            return [];
        }else{
            return node.children.map(item => this.state.functionMap[item.functionId]);
        }
    }

    disableEnableAllChildren = (functionId,isChecked,isParent) => {
        // if(!isParent && !this.state.functionMap[functionId].isDisabled){
        //     this.state.functionMap[functionId].isSelected = isChecked;
        // }
        // this.state.functionMap[functionId].children.map(child => {
        //     this.disableEnableAllChildren(child.functionId,isChecked,false);
        // })
    }

    handleSelection = (e) => {
        // e.stopPropagation();
        let isChecked = e.target.checked;
        let functionId = e.target.name;

        // this.selectUnSelectAllChildren(functionId,isChecked);
        this.disableEnableAllChildren(functionId,isChecked,true);

        let functionMap = this.state.functionMap;
        functionMap[functionId].isSelected = isChecked;

        this.setState({
            functionMap:functionMap
        });
    }

    selectUnSelectAllChildren = (functionId,isChecked) => {
        this.state.functionMap[functionId].isSelected = isChecked;
        //show children of checked item
        if(isChecked && !isEmptyArray(this.state.functionMap[functionId].children)){
            this.state.functionMap[functionId].isShowingChildren = true;
        }

        this.state.functionMap[functionId].children.map(child => {
            this.selectUnSelectAllChildren(child.functionId,isChecked);
        })
    }

    returnSelectedFunctionId = () => {
        this.selectedFunctionObjArr = [];
        let functionFormErrTemp = "";

        for (const [key, item] of Object.entries(this.state.functionMap)) {
            if(item.isSelected){
                this.selectedFunctionObjArr.push(item);
            }else{
            }
        }

        if(isEmptyArray(this.selectedFunctionObjArr)){
            functionFormErrTemp =  "Select atleast one function";
            this.setState({
                functionFormErr:functionFormErrTemp,
            })

            setTimeout(function(){
                this.setState({
                    functionFormErr:"",
                });
            }.bind(this),Constants.WRNG_MSG_TIMEOUT);
        }else{
            // alert(this.selectedFunctionObjArr);
            this.props.handleSelectFunctionDialogSaveClose(this.selectedFunctionObjArr);
        }
    }

    expandCollapseAll = (functionId,isChecked) => {
        this.state.functionMap[functionId].isShowingChildren = isChecked;

        this.state.functionMap[functionId].children.map(child => {
            this.expandCollapseAll(child.functionId,isChecked);
        })

        this.setState({
            functionMap:this.state.functionMap
        })
    }

    expandAll = () => {
        this.state.rootNodes.map((functionObj)=>{
            this.expandCollapseAll(functionObj.functionId,true);
        })
    }

    collapseAll = () => {
        this.state.rootNodes.map((functionObj)=>{
            this.expandCollapseAll(functionObj.functionId,false);
        })
    }

    /***********************API CALLS***********************/
    getFunctionTree = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetFunctionsSubtree,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                departmentId:this.props.departmentId,
                // rootFunctionId:this.props.rootFunctionId,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                const functionMap = data.result.functionMap;
                const rootNodes = this.getRootNodes(functionMap);

                //Select Checkboxes from selectedFunctionObjArr array
                this.selectedFunctionObjArr.map((functionObj)=>{
                    functionMap[functionObj.functionId].isSelected =true;
                    // functionMap[functionObj.functionId].isDisabled =true;
                })

                this.setState({
                    showLoader:false,
                    functionMap:functionMap,
                    rootNodes:rootNodes
                });
            }else{
                this.setState({
                    functionMap:{},
                    showLoader:false,
                });
            }
        });
    }

    render(){
        
        return(
            <Modal className="task-tree-dialog custom-dialog" 
            show={this.props.showFunctionsDialog} onHide={this.props.handleSelectFuncDialogClose}>
                <Modal.Header>
                    <h5>Function</h5>
                    <button 
                        onClick={this.props.handleSelectFuncDialogClose}
                        type="button" data-dismiss="modal">
                        <span class="material-icons">close</span>
                    </button>
                </Modal.Header>

                <hr />
                <div className="modal-body">
                    {
                        this.state.showLoader &&
                        <div class="loader"></div>
                    }
                    <ExpandCollapseWrapper themeSettings={this.props.themeSettings}>
                            <div className="expand-collapse-layout"
                                onClick={this.expandAll}
                                style={{marginRight:10}}
                                type="button">
                                <span class="material-icons">unfold_more</span>
                                <p>Expand All</p>
                            </div>
                            <div
                                className="expand-collapse-layout"
                                onClick={this.collapseAll}
                                style={{marginLeft:10}}
                                type="button">
                                <span class="material-icons">unfold_less</span>
                                <p>Collapse All</p>
                            </div>

                        </ExpandCollapseWrapper>
                    <div className="body-wrapper">
                        <div className="tree-wrapper">
                            {
                                this.state.rootNodes.map((item)=>{
                                    return <FunctionTree 
                                        functionObj = {item} 
                                        getChildNodes = {this.getChildNodes}
                                        level={0}
                                        handleSelection={this.handleSelection}
                                        themeSettings = {this.props.themeSettings}
                                        onClickNode={this.onClickNode}  //EXPAND/COLLAPSE
                                    />
                                })
                            }
                            {
                                !isEmptyVariable(this.state.functionFormErr) &&
                                <span className="cm-error">{this.state.functionFormErr}</span>
                            }
                        </div>
                        <div className="modal-close-save m-t-15">
                            <div className="close-save-col">
                                <button onClick={this.props.handleSelectFuncDialogClose} type="button" className="modal-close m-b-0">Cancel</button>
                                <DialogSaveButton  themeSettings={this.props.themeSettings} 
                                onClick={this.returnSelectedFunctionId} type="button" 
                                className="modal-save m-b-0">Save</DialogSaveButton>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        )
    }
}

export default SelectFunctionDialog;