import React, { Component } from 'react';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import ListCities from './listCities';
import ListStates from './listStates';
import ListCountries from './listCountries';
import {
    BrowserRouter as Router,
} from 'react-router-dom';
import { getThemeSettingsVariables } from '../../common/Global/commonFunctions';
import CityMasterTopMenu from './cityMasterTopMenu';

const themeSettings  = getThemeSettingsVariables();
class CityMasterMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayingCities:true,
            displayingStates:false,
            displayingCountries:false
        }
    }

    // This function to handle click events on the City tab
    cityTabItemOnClick = () => {
        this.setState({
            displayingCities:true,
            displayingStates:false,
            displayingCountries:false
        })
    }

    // This function to handle click events on the State/Province tab
    stateTabItemOnClick = () => {
        this.setState({
            displayingCities:false,
            displayingStates:true,
            displayingCountries:false
        })
    }

    // This function to handle click events on the Country tab
    countryTabItemOnClick = () => {
        this.setState({
            displayingCities:false,
            displayingStates:false,
            displayingCountries:true
        })
    }

    render() {
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                <CityMasterTopMenu 
                                    displayingCities={this.state.displayingCities}
                                    displayingStates={this.state.displayingStates}
                                    displayingCountries={this.state.displayingCountries}
                                    cityTabItemOnClick={this.cityTabItemOnClick}
                                    stateTabItemOnClick={this.stateTabItemOnClick}
                                    countryTabItemOnClick={this.countryTabItemOnClick}
                                    themeSettings = {themeSettings}
                                />
                                
                                {this.state.displayingCities &&
                                    <ListCities />
                                }
                                {this.state.displayingStates &&
                                    <ListStates />
                                }
                                {this.state.displayingCountries &&
                                    <ListCountries />
                                }
                            </div>
                        </div>
                    </section>
                </main>
            </Router>
        )
    }
}

export default CityMasterMain;

