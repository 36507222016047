import React, { Component } from 'react';
import * as Constants from '../../../common/Global/constants';
import {isEmptyVariable} from '../../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import * as TemplateConstants from "../../../common/Global/templateConstants";
import Pagination from '../../../common/Global/pagination';
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';

const RESULT_SIZE_DD_ARR =[
  {
    label:5,
    isSelected:true
  },
  {
    label:10,
    isSelected:false
  },
  {
    label:15,
    isSelected:false
  },
]

class PreviousAssignmentDialog extends Component{
    
    constructor(props) {
        super(props);

        this.state = {
          dataList:[],
          tableHeader:[],
          totalCount:0,
          resultSize:Constants.RESULT_SIZE,
          currentPageNo: 1,
          resultSizePlaceholder:5,
          resultSizeArr:RESULT_SIZE_DD_ARR,
        }
    }

    componentDidMount()
    {
      this.setState({
        tableHeader:this.props.assignmentExecuteFields,
        dataList:this.props.dataList,
      })

    }
  
    

    componentDidUpdate(prevProps)
    {
      if (JSON.stringify(prevProps) !== JSON.stringify(this.props) 
        && this.props.showDialogBox) {
          this.setState({
            tableHeader:this.props.assignmentExecuteFields,
            dataList:this.props.dataList,
            totalCount:this.props.dataList.length
          })
        }
    }

    displayTableData = (data) =>{

      if(data.fieldType==TemplateConstants.FIELD_TYPE_TABLE)
      {
        let value = JSON.parse(data.value);
       
        return (
          <td> {isEmptyVariable(value) ? "-" : this.getAssignmentTaskExecTable(value)} </td>
      );
      }
      else{
        return  <td> {isEmptyVariable(data.value) ? "-" : data.value} </td>

      }
    }

    getFirstRowItemValue = (item,idx) =>{
      let tempFirstRowList =["yes / no with disable","yes / no / na with disable","yes / no","yes / no / na","textfield"]
      if(idx==0)
      {
          if(!tempFirstRowList.includes(item.toLocaleLowerCase()) )
          {
              return item
          }
          
          else
          {
              return ""
          }
          
      }
      else if(idx!=0){
          return item
      }
     
  }

  getAssignmentTaskExecTable = (parsedJsonTableObj) => {
      return <table className="my-2">
          {
              Object.values(parsedJsonTableObj.tableArray).map((item, idx) => {
                  return <tr>
                      <td style={{ fontSize: 10, minwidth: "300px", Height: "22px" }}>{item[0]}</td>
                      <td style={{ fontSize: 10, minwidth: "300px", Height: "22px" }}>{this.getFirstRowItemValue(item[1],idx)}</td>
                  </tr>
              })
          }
      </table>
  }

  onChangePage = (page) =>{
    this.setState({
      currentPageNo:page
    })
    
  }
  resultSizeDropdownClick = (selectedItem) =>{
    let tempArr = RESULT_SIZE_DD_ARR;
     
    Object.values(tempArr).map((item)=>{
      if(item.label==selectedItem.label)
      {
        item.isSelected=true
      }
      else{
        item.isSelected=false
      }
    })
   
    this.setState({
      resultSizeArr:tempArr,
      resultSizePlaceholder:selectedItem.label
    })

    
  }
    render(){
        return(
            <section>
                <Modal className="task-select-dept-dialog custom-dialog"
                    show={this.props.showDialogBox} 
                    onHide={this.props.closeDialogBox}>
                    <Modal.Header>
                    <h5>Previous Assignment Detials</h5>
                        <button 
                            onClick={this.props.closeDialogBox}
                            type="button" data-dismiss="modal">
                            <span class="material-icons">close</span>
                        </button>
                    </Modal.Header>
                    <hr />
                    <div className="modal-body pt-0">
                      <div className="body-wrapper">
                      { Object.values(this.state.dataList).length>0 &&
                        <div className="search-addnew-bg">
                          <div className="search-addnew">
                              <div className="search-filter-layout">
                                 <FilterDropDown
                                    placeholder={this.state.resultSizePlaceholder}
                                    dropdownArr={this.state.resultSizeArr}
                                    onDropDownItemClick={this.resultSizeDropdownClick}
                                    themeSettings={this.props.themeSettings}
                                    mleft={0}
                                  />
                     
                                </div>
                              </div>
                          </div>
                        }
                        <div className="common-tab-bg">
                        { Object.values(this.state.dataList).length>0 &&
                          <div className="common-table" style={{width:"100%"}}>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>No.</th>
                                {
                                Object.values(this.state.tableHeader).map((item)=>{

                                    return <th>{item}</th>
                                  })
                                }
                              </tr>
                            </thead>
                            <tbody>
                              {
                                Object.values(this.state.dataList).map((item,idx)=>{
                                  if((idx>=(this.state.currentPageNo-1)*this.state.resultSizePlaceholder) && idx<(this.state.currentPageNo*this.state.resultSizePlaceholder))
                                  {
                                    return (
                                      <tr style={{ background: idx%2==0 ? "#fff" : "#f5f5f5", }}>
                                       <td>{idx+1}</td>
                                       <td>{isEmptyVariable(item.groupingName)?"-":item.groupingName}</td>
                                       <td>{(new Date(item.startDate).getMonth())+"/"+(new Date(item.startDate).getFullYear())}</td>
                                       {
                                         Object.values(this.state.tableHeader).map((head,i)=>{
                                           return Object.values(item.executeValues).length>0?Object.values(item.executeValues).map((data)=>{
                                             if(head==data.fieldName )
                                             {
                                               return this.displayTableData(data)
                                             }
                                             
                                           }):(i>1&&<td>-</td> )
                                         })
                                       }
                                     </tr>
                                     )

                                  }
                                })
                              }
                            </tbody>
                          </table>
                          </div>
                        }
                       
                        </div>
                        { Object.values(this.state.dataList).length==0 &&
                          <div className="no-items-layout">
                            <div className="no-items-card">
                                <h6> { Constants.NO_RECORDS_WARNING } </h6>
                            </div>
                          </div>
                        }
                        <div className="pagination-layout">
                                <div className="row custom-row">
                                    <div className="col-md-9 custom-col">
                                    {
                                        <Pagination 
                                            totalLength ={this.state.totalCount} 
                                            items={this.state.dataList} 
                                            onChangePage={this.onChangePage} 
                                            pageSize={this.state.resultSizePlaceholder}
                                            currentPageNo = {this.state.currentPageNo}
                                            initialPage={this.state.currentPageNo}
                                            themeSettings={this.props.themeSettings} />
                                    }
                                    </div>
                                    <div className="col-md-3 custom-col">
                                        <p>{"Total Records: "+this.state.totalCount}</p>
                                    </div>
                                </div>
                            </div>
                      </div>
                    </div>
                   
                </Modal>
            </section>
        )
    }

}

export default PreviousAssignmentDialog;