import React, { Component } from 'react'
import { BrowserRouter as Router } from "react-router-dom";
import Sidebar from "../../../common/SidebarCollapse";
import Topbar from "../../../common/Topbar";
import MasterMenuTabs from '../Common/topmenu';
import * as Constants from '../../../common/Global/constants';
import {getLocalStorageVariables,isEmptyVariable,getThemeSettingsVariables,sortTableTwoStates,truncateString,
    SplitJoinString,isEmptyArray,GetOrderedMasterTabMenuExecute} from '../../../common/Global/commonFunctions';
import {SearchSpan,AddNewButtonLayout} from '../../../common/Global/globalStyles.style';
import FilterDropDown from '../../../common/DropdownMenus/FilterDropdown';
import Pagination from '../../../common/Global/pagination';
import SelectAuditeeDialog from "./selectAuditeeDialog"
import AssignmentAuditeeFunctionDialog from "./assignmentAuditeeFunctionDialog";
import TopmenuReports from '../../../common/TopmenuReports';
import AddEmployeeDialog from '../../Companies/addemployeedialog';
import EmployeeDetailsDialog from './employeeDetailDialog';
import AlertDialog from '../../../common/AlertDialog';
import { matchPath } from 'react-router-dom';

const themeSettings = getThemeSettingsVariables();
const userDetails  = getLocalStorageVariables();

const sortFirstName = "firstName";
const sortDesignation = "designation";
const sortUnitName = "unitName";

export default class AssignmentEmployee extends Component {
    constructor(props)
    {
        super(props);
        this.state ={
            assignmentId: this.props.match.params.id,
            showLoader: false,
            componentDidMountFlag: false,
            searchKey:"",
            currentPageNo: 1,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],

            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:sortFirstName,
            sortDir:"asc",
            status:"Active",
            parentEmpFuncStr: "", 
            assignmentDetails:{},

            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,
            functionsArr:"",
            
            //Add Employee
            showAddEmployeeDialog:false,
            showAssignFunc: false,
            showEmpDetailsDialog:false,
            selectedItem:"",
            isEdit:false,

            showAddNewEmployeeDialog:false,
            departmentArr:[],
            designationArr:[],
            selectedEmpDetails:"",
            assignmentTaskAllowEdit:"N",
        }

    }

    componentDidMount(){
        this.getInitData()
    }

    getInitData = () => {
        // let companyId = assignmentDetails.companyId; 
        this.setState({
            showLoader:true,
        });

        Promise.all([
            fetch(Constants.GetEmployeeCreateMasterData,
            {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams(
                {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    assignmentId:this.state.assignmentId
                })
            }),
            fetch(Constants.GetAssignmentEmployees,
            {
                method:"POST",
                mode:"cors",
                body: new URLSearchParams(
                {
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:this.state.currentPageNo,
                    assignmentId: this.state.assignmentId,
                    search:this.state.searchKey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                })
            }),
            fetch(Constants.GetUserMenu, {
                method: "POST",
                mode: "cors",
                body: new URLSearchParams({
                    email: userDetails.email,
                    accessToken: userDetails.accessToken,
                }),
            }),
            fetch(Constants.GetAssignmentsDetails, {
				method: "POST",
				mode: "cors",
				body: new URLSearchParams({
					email: userDetails.email,
					accessToken: userDetails.accessToken,
					assignmentId: this.state.assignmentId,
				}),
			}),
        ])
        .then(([masterRes,employeeRes,menuRes,assignmentRes]) => {
            return Promise.all([masterRes.json(),employeeRes.json(),menuRes.json(),assignmentRes.json()]) 
        })
        .then(([masterRes,employeeRes,menuRes,assignmentRes]) => {
            let datalistTemp = [];
            let totalCountTemp = 0;

            let functionsArr = [];
            let designationArr = [];

            let assignmentDetails ={};
            let assignmentStatus = "";

            if(masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                masterRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(masterRes.responseCode === Constants.CODE_SUCCESS){
                functionsArr = masterRes.data.functions;
                designationArr = masterRes.data.designations;
            }else{
                functionsArr = "";
            }

            if(employeeRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                employeeRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(employeeRes.responseCode === Constants.CODE_SUCCESS){
                datalistTemp = employeeRes.data.result;
                totalCountTemp = employeeRes.data.count;
                assignmentDetails = employeeRes.data.assignmentData
            }else{
                datalistTemp = [];
                totalCountTemp = 0;
                this.setState({
                    showAlertDialogInfo: true,
                    alertDialogMessageInfo: employeeRes.responseMessage,
                    responseCode: employeeRes.responseCode
                })
            }

            let menusTemp={};
            let companyTemp={};
    

            if (
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                menuRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (menuRes.responseCode === Constants.CODE_SUCCESS) {
                menusTemp = menuRes.data.menus;
                companyTemp = menuRes.data.company;
            } else {
                menusTemp = {};
                
            }
            let menusTemp2 = GetOrderedMasterTabMenuExecute(
                menusTemp
            );

            if (
                assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                assignmentRes.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED
            ) {
                localStorage.clear();
                window.location = "/";
            } else if (assignmentRes.responseCode === Constants.CODE_SUCCESS) {
                assignmentStatus = assignmentRes.data.assignmentDetails.assignmentStatus;
            }

            this.setState({
                componentDidMountFlag:true,
                showLoader:false,
                functionsArr:functionsArr,
                datalist:datalistTemp,
                totalCount:totalCountTemp,
                menus: menusTemp2,
                company: companyTemp,
                assignmentDetails: assignmentDetails,
                assignmentStatus:assignmentStatus,
                designationArr:designationArr
            })
        });
    }

    updateParentEmpFuncStr = (empFuncStr) => {
        this.setState({ parentEmpFuncStr: empFuncStr });
    };
    
    handleAddEmployeeDialogClose = (reloadFlag) => {
        this.setState({
            selectedItem:"",
            showAddEmployeeDialog:false,
        },()=>{
            if(reloadFlag === true){
                this.getEmployeesList();
            }
        })
    }

    handleAssignFuncDialogClose = (reloadFlag) => {
        this.setState({
            selectedItem:"",
            showAssignFunc:false,
        },()=>{
            if(reloadFlag === true){
                this.getEmployeesList();
            }
        })
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTableTwoStates(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getEmployeesList();
        })
    }

    getEmployeesList = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetAssignmentEmployees,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                assignmentId: this.state.assignmentId,
                search:this.state.searchKey,
                resultsize:this.state.resultSize,
                status:this.state.status,
                sort:this.state.sort,
                sortDir:this.state.sortDir,
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    searchKey:this.state.searchKey,
                })
            }else{
                this.setState({
                    datalist:[],
                    totalCount:0,
                    showLoader:false,
                    componentDidMountFlag:true,
                    searchKey:this.state.searchKey,
                    showAlertDialogInfo: true,
                    alertDialogMessageInfo: data.responseMessage,
                    responseCode: data.responseCode
                })
            }
        });
    }

    handleEmpDetailsDialogShow = (item) => {
        this.setState({
            showEmpDetailsDialog:true,
            selectedEmpDetails:item,
        });
    }
   
    handleEmpDetailsDialogClose = () => {
        this.setState({
            showEmpDetailsDialog:false,
            selectedEmpDetails:""
        })
    }
  
    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });
            
            fetch(Constants.GetAssignmentEmployees,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    assignmentId: this.state.assignmentId,
                    search:this.state.searchKey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir,
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        componentDidMountFlag:true,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        searchKey:this.state.searchKey,
                        currentPageNo:page,
                    })
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        componentDidMountFlag:true,
                        searchKey:this.state.searchKey,
                        showAlertDialogInfo: true,
                        alertDialogMessageInfo: data.responseMessage,
                        responseCode: data.responseCode
                    })
                }
            });
        }
    }
    
    onSearchIconClick = () => {
        this.getEmployeesList();
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.getEmployeesList();
        }
    }
   
    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label ;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getEmployeesList();
        })
    }

    selectAuditeeClick = (event) => {
        event.preventDefault();
        this.setState({
            showAddEmployeeDialog:true,
            isEdit:false,
            selectedItem:""
        })
    }

    assignFunctionClick = (event) => {
        event.preventDefault();
        this.setState({
            showAssignFunc:true,
            isEdit:false,
            selectedItem:""
        })
    }

    addNewEmployeeOnClick = () =>{
        this.setState({
            showAddNewEmployeeDialog:true
        })
    }

    closeAddEmpDilogBox = (reloadFlag)=>{
        this.setState({
            showAddNewEmployeeDialog:false
        },()=>{
            if(reloadFlag === true){
                this.getEmployeesList();
            }
        })
    }

    handleAlertDialogCloseInfo = () => {
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        })
        if(this.state.responseCode === Constants.CODE_ASSIGNMENT_ACCESS_ERROR ||
            this.state.responseCode === Constants.CODE_ASSIGNMENT_SPECIFIC_ACCESS_ERROR){
			this.props.history.push(Constants.URL_EXECUTEASSIGNMENTS);
		}
    }
   
  render() {
    return (
        <Router>
            <main className="main-dashboard">
                <section className="dashboard-bg">
                    <Sidebar />
                    <div className="dashboard-right-bg-collpase">
                         <div className="dashboard-wrapper">
                            <Topbar />
                            {
                                this.state.componentDidMountFlag && (
                                <div className="d-flex align-items-center">
                                    <div className="flex-fill">
                                        <MasterMenuTabs
                                            assignmentId={this.state.assignmentId}
                                            masterMenus={this.state.menus}
                                            themeSettings={themeSettings}
                                        />
                                    </div>
                                    <div>
                                        <TopmenuReports
                                            assignmentId={this.state.assignmentId}
                                            userDetails ={userDetails}
                                            themeSettings={themeSettings}
                                            company={this.state.company}
                                        />
                                    </div>
                                </div>
                            )}
                            
                            {
                                this.state.showLoader && (
                                <div class="loader"></div>
                            )}

                            {
                                  this.state.componentDidMountFlag &&
                                  <div className="body-wrapper">
                                    <div className="search-addnew-bg">
                                        <div className="search-addnew">
                                            <div className="search-filter-layout">
                                                <FilterDropDown
                                                    placeholder={this.state.resultSizePlaceholder}
                                                    dropdownArr={this.state.resultSizeArr}
                                                    onDropDownItemClick={this.resultSizeDropdownClick}
                                                    themeSettings={themeSettings}
                                                    mleft={0}
                                                />
                                                <div className="search-col m-l-10">
                                                    <input 
                                                        name="searchKey" 
                                                        type="text"
                                                        onChange={this.handleChange}
                                                        placeholder="Search" 
                                                        onKeyPress={this.onEnterBtnPress}
                                                        value={this.state.searchKey} />
                                                    <SearchSpan 
                                                    themeSettings={themeSettings} 
                                                    onClick = {this.onSearchIconClick} 
                                                    className="material-icons"> search </SearchSpan>
                                                </div>
                                            </div>
                                            {this.state.assignmentStatus !== Constants.ASSIGNMENT_STATUS_DRAFT &&
                                                <div className="flex-center-layout">
                                                    <AddNewButtonLayout themeSettings={themeSettings} style={{marginRight:"10px"}}>
                                                        <a  onClick={this.addNewEmployeeOnClick}>
                                                            <span className="material-icons"></span>
                                                            <p>Add Employee</p>
                                                        </a>
                                                    </AddNewButtonLayout>
                                                    <AddNewButtonLayout themeSettings={themeSettings} style={{marginRight:"10px"}}>
                                                        <a onClick={this.assignFunctionClick}>
                                                            <span className="material-icons"></span>
                                                            <p>Assign Function</p>
                                                        </a>
                                                    </AddNewButtonLayout>
                                                    <AddNewButtonLayout themeSettings={themeSettings}>
                                                        <a  onClick={this.selectAuditeeClick}>
                                                            <span className="material-icons"></span>
                                                            <p>Select Assignment Auditee</p>
                                                        </a>
                                                    </AddNewButtonLayout>
                                                </div>    
                                            }
                                        </div>
                                    </div>
                                <div>
                            </div>

                                    {
                                !(isEmptyArray(this.state.datalist)) &&
                                <div className="common-tab-bg">
                                    <div className="common-table" style={{width:"100%"}}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                <th className="text-center" width={60}>No.</th>
                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortFirstName)} width="20%">
                                                    <div className="sort-header">Name
                                                        <span className={(this.state.sort === sortFirstName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                    </div>
                                                </th>
                                                <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDesignation)} width="20%">
                                                    <div className="sort-header">Designation
                                                        <span className={(this.state.sort === sortDesignation)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                    </div>
                                                </th>
                                                <th className="c-pointer" width="20%">
                                                    <div className="sort-header">Units
                                                        <span className={(this.state.sort === sortUnitName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                    </div>
                                                </th>
                                                <th className="c-pointer">
                                                    <div className="sort-header">Function </div>
                                                </th>
                                                {/* <th className="text-center"></th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    !isEmptyArray(this.state.datalist) && 
                                                    this.state.datalist.map((item,idx) => {
                                                        return <tr key={idx + "_" + item.employeeId} onClick={this.handleEmpDetailsDialogShow.bind(this,item)}>
                                                            <td className="text-center" key={idx + "_" + item.employeeId}>{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                            <td>{truncateString(SplitJoinString(item.name,",",":-:",", "),Constants.DEPT_STRING_LENGTH)}</td>
                                                            <td>{truncateString(SplitJoinString(item.designation,",",":-:",", "),Constants.DEPT_STRING_LENGTH)}</td>
                                                            <td>{isEmptyArray(item.units)?"":item.units.map(e=>e.unitName).join(", ") ||''}</td>
                                                            <td>{!isEmptyArray(item.scope)?item.scope.map(scope => scope.functionName).join(", "): ""}</td>
                                                            {/* <td className="text-center">
                                                                <TableDropDown 
                                                                    actionArr={item.employeeStatus === "Active"?actionArr:actionArrReactivate}
                                                                    onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                    dropDownId = {item}
                                                                    dropdownWidth = {"140px"}
                                                                    themeSettings={themeSettings}
                                                                />
                                                            </td> */}
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                
                                    </div>
                                </div>
                            }
                             {
                                this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) && 
                                <div class="no-items-layout">
                                    <div class="no-items-card">
                                        <h6>
                                        {
                                            isEmptyVariable(this.state.searchKey)?
                                            Constants.NO_RECORDS_WARNING
                                            :
                                            Constants.EMPTY_SEARCH_WARNING
                                        }
                                        </h6>
                                    </div>
                                </div>
                            }
                            <div className="pagination-layout">
                                <div className="row custom-row">
                                    <div className="col-md-9 custom-col">
                                    {
                                        (this.state.totalCount > this.state.datalist.length) && 
                                        <Pagination 
                                            totalLength ={this.state.totalCount} 
                                            items={this.state.datalist} 
                                            onChangePage={this.onChangePage} 
                                            pageSize={this.state.resultSize}
                                            currentPageNo = {this.state.currentPageNo}
                                            initialPage={this.state.currentPageNo}
                                            themeSettings={themeSettings} />
                                    }
                                    </div>
                                    <div className="col-md-3 custom-col">
                                        <p>{"Total Records: "+this.state.totalCount}</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    }
                            

                         </div>

                    </div>
                    

                    {this.state.showAddEmployeeDialog && 
                        <SelectAuditeeDialog
                            showDocumentDialog={this.state.showAddEmployeeDialog}
                            handleSelectDocDialogClose={this.handleAddEmployeeDialogClose}
                            assignmentId={this.state.assignmentId}
                            departmentId={this.state.selectedDepartmentId}
                            getEmployeesList={this.getEmployeesList}
                            assignmentDetails={this.state.assignmentDetails}
                            themeSettings={themeSettings}
                        /> 
                    }

                    <AddEmployeeDialog 
                        isEdit = {this.state.isEdit}
                        selectedItem = {this.state.selectedItem}
                        showAddEmployeeDialog = {this.state.showAddNewEmployeeDialog}
                        handleAddEmployeeDialogClose = {this.closeAddEmpDilogBox}
                        departmentArr ={this.state.departmentArr}
                        designationArr ={this.state.designationArr}
                        themeSettings={themeSettings}
                        isShowUnit={"N"}
                        assignmentUnitId={[this.state.assignmentDetails.unitId]}
                        assignmentId ={this.state.assignmentId}
                    />

                    {this.state.showAssignFunc && 
                        <AssignmentAuditeeFunctionDialog
                            showAssignFuncDialog={this.state.showAssignFunc}
                            handleAssignFuncDialogClose={this.handleAssignFuncDialogClose}
                            assignmentId={this.state.assignmentId}
                            unitId={this.state.unitId}
                            dataList= {this.state.datalist}
                            updateParentEmpFuncStr={this.updateParentEmpFuncStr}
                            assignmentDetails={this.state.assignmentDetails}
                            themeSettings={themeSettings}
                        />
                    }
                    <EmployeeDetailsDialog
                        employeeData={this.state.selectedEmpDetails}
                        showEmpDetailsDialog={this.state.showEmpDetailsDialog}
                        handleEmpDetailsDialogClose = {this.handleEmpDetailsDialogClose}
                    />

                    <AlertDialog
                        showAlertDialog={this.state.showAlertDialogInfo}
                        handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                        type={Constants.ALERT_TYPE_ALERT}
                        alertDialogMessage={this.state.alertDialogMessageInfo}
                        proceedBtnClick={this.handleAlertDialogCloseInfo}
                        proceedBtnLabel={Constants.ALERT_TYPE_OKAY_LABEL}
                        themeSettings={themeSettings}
                    />
                </section>

            </main>

        </Router>
    )
  }
}
