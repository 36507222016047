import React, { Component } from 'react';
import * as Constants from '../../common/Global/constants';
import {SearchSpan,AddNewButtonLayout,DialogSaveButton} from '../../common/Global/globalStyles.style';
import Sidebar from '../../common/Sidebar';
import Topbar from '../../common/Topbar';
import UserManagementTopMenu from './topmenu';
import FilterDropDown from '../../common/DropdownMenus/FilterDropdown';
import TableDropDown from '../../common/DropdownMenus/TableDropdown';
import AlertDialog from '../../common/AlertDialog';
import Pagination from '../../common/Global/pagination';
import {getLocalStorageVariables,isEmptyVariable, sortTable,truncateString,
    isEmptyArray,getThemeSettingsVariables} from '../../common/Global/commonFunctions';
import {Modal} from 'react-bootstrap';
import {
    BrowserRouter as Router,
} from 'react-router-dom';

const userDetails  = getLocalStorageVariables();
const themeSettings  = getThemeSettingsVariables();
const sortShortName = "shortName";
const sortName = "name";
const sortDescription = "description";
const sortDeptUserCount = "deptUserCount";

class UserDepartments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            componentDidMountFlag:false,
            apiSearchKey:"",
            searchkey:"",
            currentPageNo: 1,
            showLoader:false,
            resultSize:Constants.RESULT_SIZE,
            totalCount:0,
            datalist:[],
            masterType:"Department",
            statusPlaceholder:"Active",
            resultSizePlaceholder:Constants.RESULT_SIZE,
            sort:"",
            sortDir:"",

            showAlertDialog:false,
            operationType:"",
            deleteReactivateId:"",
            alertDialogMessage:"",
            showBtnLoader:false,

            showAlertDialogInfo:false,
            alertDialogMessageInfo:"",

            status:"Active",
            statusArr:[
                {
                    label:"Active",
                    isSelected:true
                },
                {
                    label:"Inactive",
                    isSelected:false
                }
            ],
            resultSizeArr:Constants.RESULT_SIZE_DD_ARR,

            name:"",
            shortName:"",
            description:"",
            formErrors:{},

            showDetailsDialog:false,
            selectedItem:"",
        }
        this.textInput = React.createRef();
    }

    componentDidMount(){
        this.getUserDepartmentList();
    }

    handleChange = (e) => {
        const { name, value } = e.target;

        let FormErrorsTemp = this.state.formErrors;

        if(name === "shortName"){
            if(isEmptyVariable(value)){
                FormErrorsTemp['shortName'] = "Please enter department short name";
            }else{
                FormErrorsTemp['shortName'] = "";
            }
        }else if(name === "name"){
            console.log("Inside name");
            if(isEmptyVariable(value)){
                console.log("Inside name value:"+value);
                FormErrorsTemp['name'] = "Please enter department name";
            }else{
                FormErrorsTemp['name'] = "";
            }
        }else if(name === "description"){
            if(!isEmptyVariable(value) && value.length > Constants.DESC_STRING_LENGTH){
                FormErrorsTemp['description'] = "Description must be less then 500 characters";
            }else{
                FormErrorsTemp['description'] = "";
            }
        }

        this.setState({ 
            [name]: value ,
            formErrors:FormErrorsTemp
        });
    }

    handleChangeSearch = (e) => {
        const { name, value } = e.target;

        this.setState({ 
            [name]: value
        });
    }

    onSearchIconClick = () => {
        this.setState({
            currentPageNo:1
        },()=>{
            this.getUserDepartmentList();
        })
    }

    onEnterBtnPress = (e) => {
        var code = e.keyCode || e.which;
        if(code === 13){
            this.setState({
                currentPageNo:1
            },()=>{
                this.getUserDepartmentList();
            })
        }
    }

    handleAlertDialogClose = () =>{
        this.setState({
            showAlertDialog:false,
            alertDialogMessage:""
        });
    }

    handleAlertDialogCloseInfo = () =>{
        this.setState({
            showAlertDialogInfo:false,
            alertDialogMessageInfo:""
        });
    }

    handleCreateDialogClose = () =>{
        this.setState({
            showCreateDialog:false,
            isEdit:false,
            selectedId:"",
            name:"",
            shortName:"",
            description:"",
        });
    }

    handleDetailsDialogClose = () =>{
        this.setState({
            showDetailsDialog:false,
            selectedItem:"",
        });
    }

    handleDetailsDialogShow = (item) => {
        this.setState({
            showDetailsDialog:true,
            selectedItem:item,
        });
    }

    showCreateDialog = () => {
        this.setState({
            showCreateDialog:true,
            isEdit:false,
            selectedId:"",
            name:"",
            shortName:"",
            description:"",
            formErrors:{}
        },()=>{
            this.textInput.current.focus();
        });
    }

    statusDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.statusArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            statusArr:tempArr,
            status:item.label,
            statusPlaceholder:placeholder
        }, () => {
            this.getUserDepartmentList();
        })
    }

    resultSizeDropdownClick = (item,selectedIndex) => {
        let tempArr = this.state.resultSizeArr;
        let placeholder = "";

        for(const [i,value] of tempArr.entries()){
            if(i === selectedIndex){
                tempArr[i].isSelected = true;
                placeholder = value.label;
            }else{
                tempArr[i].isSelected = false;
            }
        }
        
        this.setState({
            resultSizeArr:tempArr,
            resultSize:item.label,
            resultSizePlaceholder:placeholder
        },() => {
            this.getUserDepartmentList();
        })
    }

    onTableDropDownItemClick = (item, dropdownItem) => {
        if(item.label === "Delete"){
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.id,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to delete user department "+dropdownItem.name+"?"
            })
        }else if(item.label === "Reactivate"){
            this.setState({
                operationType:item.label,
                deleteReactivateId:dropdownItem.id,
                showAlertDialog:true,
                alertDialogMessage:"Are you sure you want to reactivate user department "+dropdownItem.name+"?"
            })
        }else if(item.label === "Edit"){
            this.setState({
                showCreateDialog:true,
                isEdit:true,
                selectedId:dropdownItem.id,
                name:dropdownItem.name,
                shortName:dropdownItem.shortName,
                description:dropdownItem.description,
                formErrors:{}
            },()=>{
                this.textInput.current.focus();
            });
        }
    }

    sortTableLocal = (sortColumn) => {
        let sortObj = sortTable(sortColumn,this.state.sort,this.state.sortDir);

        this.setState({
            sort:sortObj.sortTemp,
            sortDir:sortObj.sortDirTemp,
        },()=>{
            this.getUserDepartmentList();
        })
    }
    
    /*******************API CALLS*******************/

    getUserDepartmentList = () => {
        this.setState({
            showLoader:true,
        });

        fetch(Constants.GetDepartments,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                pageNo:this.state.currentPageNo,
                search:this.state.searchkey,
                resultsize:this.state.resultSize,
                status:this.state.status,
                sort:this.state.sort,
                sortDir:this.state.sortDir
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    showLoader:false,
                    componentDidMountFlag:true,
                    datalist:data.data.result,
                    totalCount:data.data.count,
                    apiSearchKey:this.state.searchkey,
                });
            }else{
                this.setState({
                    datalist:[],
                    showLoader:false,
                    totalCount:0,
                    componentDidMountFlag:true,
                    apiSearchKey:this.state.searchkey,
                });
            }
        });
    }

    onChangePage = (page) => {
        // update state with new page of items
        if(page !== this.state.currentPageNo){
            this.setState({
                showLoader:true,
            });

            fetch(Constants.GetDepartments,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    pageNo:page,
                    search:this.state.searchkey,
                    resultsize:this.state.resultSize,
                    status:this.state.status,
                    sort:this.state.sort,
                    sortDir:this.state.sortDir
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showLoader:false,
                        datalist:data.data.result,
                        totalCount:data.data.count,
                        apiSearchKey:this.state.searchkey,
                        currentPageNo:page,
                    });
                }else{
                    this.setState({
                        datalist:[],
                        showLoader:false,
                        apiSearchKey:this.state.searchkey,
                    });
                }
            });
        }
    }

    deleteActivateUserDept = () => {
        let url = "";

        if(this.state.operationType === "Delete"){
            url = Constants.DeleteGeneralMasters;
        }else if(this.state.operationType === "Reactivate"){
            url = Constants.ReactivateGeneralMasters;
        }

        this.setState({
            showBtnLoader:true,
        });

        fetch(url,
        {
            method: "POST",
            mode:'cors',
            body: new URLSearchParams({
                email:userDetails.email,
                accessToken:userDetails.accessToken,
                masterType:this.state.masterType,
                masterDataId:this.state.deleteReactivateId
            })
        })
        .then(response => { return response.json(); } )
        .then(data =>
        {
            if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                localStorage.clear();
                window.location="/";
            }else if(data.responseCode === Constants.CODE_SUCCESS){
                this.setState({
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                    showBtnLoader:false
                },() => {
                    this.getUserDepartmentList();
                });
            }else{
                this.setState({
                    showBtnLoader:false,
                    deleteReactivateId:"",
                    operationType:"",
                    alertDialogMessage:"",
                    showAlertDialog:false,
                    showAlertDialogInfo:true,
                    alertDialogMessageInfo:data.responseMessage,
                })
            }
        });
    }

    handleCreateDepartment = () => {
        this.setState({
            showLoader:true,
        });
        
        let isError = false;
        let FormErrorsTemp = {};

        if(isEmptyVariable(this.state.name)){
            isError = true;
            FormErrorsTemp['name'] = "Please enter department name";
        }

        if(isEmptyVariable(this.state.shortName)){
            isError = true;
            FormErrorsTemp['shortName'] = "Please enter department short name";
        }

        if(!isEmptyVariable(this.state.description)){
            if(this.state.description.length > Constants.DESC_STRING_LENGTH){
                isError = true;
                FormErrorsTemp['description'] = "Description must be less then 500 characters";
            }
        }

        if(isError){
            this.setState({
                formErrors:FormErrorsTemp,
                showLoader:false
            });

        }else{
            let url = "";

            if(this.state.isEdit){
                url = Constants.EditGeneralMasters;
            }else{
                url = Constants.CreateGeneralMasters;
            }

            fetch(url,
            {
                method: "POST",
                mode:'cors',
                body: new URLSearchParams({
                    email:userDetails.email,
                    accessToken:userDetails.accessToken,
                    shortName:this.state.shortName,
                    name:this.state.name,
                    description:this.state.description,
                    masterType:this.state.masterType,
                    masterDataId:this.state.selectedId
                })
            })
            .then(response => { return response.json(); } )
            .then(data =>
            {
                if(data.responseCode === Constants.CODE_ACCESS_TOKEN_INVALID ||
                    data.responseCode === Constants.CODE_ACCESS_TOKEN_EXPIRED){
                    localStorage.clear();
                    window.location="/";
                }else if(data.responseCode === Constants.CODE_SUCCESS){
                    this.setState({
                        showCreateDialog:false,
                        showLoader:false,
                        isEdit:false,
                        selectedId:"",
                        name:"",
                        shortName:"",
                        description:"",
                    },() => {
                        this.getUserDepartmentList();
                    });
                }else{
                    this.setState({
                        showCreateDialog:false,
                        showLoader:false,
                        isEdit:false,
                        selectedId:"",
                        name:"",
                        shortName:"",
                        description:"",

                        showAlertDialogInfo:true,
                        alertDialogMessageInfo:data.responseMessage,
                    })
                }
            });
        }
    }

    /*******************API CALLS END HERE*******************/

    render() {
        const actionArr = [
            {
                icon:"edit",
                label:"Edit"
            },
            {
                icon:"delete",
                label:"Delete"
            },
        ]

        const actionArrReactivate = [
            {
                icon:"check_circle",
                label:"Reactivate"
            },
        ]
        return(
            <Router>
                <main className="main-dashboard">
                    <section className="dashboard-bg">
                        <Sidebar />

                        <div className="dashboard-right-bg position-relative">
                            <div className="dashboard-wrapper">
                                <Topbar />
                                {
                                    this.state.showLoader &&
                                    <div class="loader"></div>
                                }
                                <UserManagementTopMenu 
                                    themeSettings = {themeSettings}
                                />
                                {
                                    // Need to check component did mount flag, General wise until api is called,
                                    // it shows no items found layout.
                                    this.state.componentDidMountFlag &&
                                    <div className="body-wrapper">
                                        <div className="search-addnew-bg">
                                            <div className="search-addnew">
                                                <div className="search-filter-layout">
                                                    <FilterDropDown
                                                        placeholder={this.state.resultSizePlaceholder}
                                                        dropdownArr={this.state.resultSizeArr}
                                                        onDropDownItemClick={this.resultSizeDropdownClick}
                                                        themeSettings = {themeSettings}
                                                        mleft={0}
                                                    />
                                                    <div className="search-col m-l-10">
                                                        <input 
                                                            name="searchkey" 
                                                            type="text"
                                                            onChange={this.handleChangeSearch}
                                                            placeholder="Search" 
                                                            onKeyPress={this.onEnterBtnPress}
                                                            value={this.state.searchkey}
                                                        />
                                                        <SearchSpan 
                                                            className="material-icons"
                                                            themeSettings = {themeSettings}
                                                            onClick = {this.onSearchIconClick}>search</SearchSpan>
                                                    </div>

                                                    <FilterDropDown
                                                        placeholder={this.state.statusPlaceholder}
                                                        dropdownArr={this.state.statusArr}
                                                        onDropDownItemClick={this.statusDropdownClick}
                                                        themeSettings = {themeSettings}
                                                    />
                                                </div>

                                                <AddNewButtonLayout
                                                    themeSettings = {themeSettings}
                                                >
                                                    <a onClick={this.showCreateDialog} href="javascript:void(0);">
                                                        <span className="material-icons">add_circle</span>
                                                        <p>Add New</p>
                                                    </a>
                                                </AddNewButtonLayout>
                                            </div>
                                        </div>

                                        {
                                            !isEmptyArray(this.state.datalist) && 
                                            <div className="common-tab-bg">
                                                <div className="common-table" style={{width:"100%"}}>
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                            <th className="text-center" width={"5%"}>No.</th>
                                                            <th width={"20%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortName)}>
                                                                <div className="sort-header">Name
                                                                <span className={(this.state.sort === sortName)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDescription)}>
                                                                <div className="sort-header">Description
                                                                <span className={(this.state.sort === sortDescription)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            <th width={"8%"} className="c-pointer" onClick={this.sortTableLocal.bind(this,sortDeptUserCount)}>
                                                                <div className="sort-header">Users
                                                                <span className={(this.state.sort === sortDeptUserCount)?"material-icons":"material-icons hide-sort-arrow"}>{this.state.sortDir === "asc"?"arrow_upward":"arrow_downward"}</span>
                                                                </div>
                                                            </th>
                                                            {/* <th width={"10%"}>Users Count</th> */}
                                                            <th className="text-center"  width={"8%"}>Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            !isEmptyArray(this.state.datalist) && 
                                                            this.state.datalist.map((item,idx) => {
                                                                return <tr onClick={this.handleDetailsDialogShow.bind(this,item)}>
                                                                    <td className="text-center">{((this.state.currentPageNo - 1) * this.state.resultSize) + (++idx)}</td>
                                                                    <td>{item.name}</td>
                                                                    <td>{truncateString(item.description,80)}</td>
                                                                    <td>{item.deptUserCount}</td>
                                                                    <td className="text-center">
                                                                        <TableDropDown 
                                                                            actionArr={item.status === "Active"?actionArr:actionArrReactivate}
                                                                            onDropDownItemClick = {this.onTableDropDownItemClick}
                                                                            dropDownId = {item}
                                                                            themeSettings = {themeSettings}
                                                                        />
                                                                    </td>
                                                                </tr>
                                                            })
                                                        }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        }
                                        {
                                            this.state.componentDidMountFlag && isEmptyArray(this.state.datalist) &&
                                            <div class="no-items-layout">
                                                <div class="no-items-card">
                                                    <h6>
                                                    {
                                                        isEmptyVariable(this.state.apiSearchKey)?
                                                        Constants.NO_RECORDS_WARNING
                                                        :
                                                        Constants.EMPTY_SEARCH_WARNING
                                                    }
                                                    </h6>
                                                </div>
                                            </div>
                                        }
                                        <div className="pagination-layout">
                                            <div className="row custom-row">
                                                <div className="col-md-9 custom-col">
                                                {
                                                    (this.state.totalCount > this.state.datalist.length) && 
                                                    <Pagination 
                                                        totalLength ={this.state.totalCount} 
                                                        items={this.state.datalist} 
                                                        onChangePage={this.onChangePage} 
                                                        pageSize={this.state.resultSize}
                                                        currentPageNo = {this.state.currentPageNo}
                                                        initialPage={this.state.currentPageNo} 
                                                        themeSettings = {themeSettings}
                                                        />
                                                }
                                                </div>
                                                <div className="col-md-3 custom-col">
                                                    <p>{"Total Records: "+this.state.totalCount}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>

                        <AlertDialog
                            showAlertDialog={this.state.showAlertDialog}
                            handleAlertDialogClose={this.handleAlertDialogClose}
                            type={
                                (this.state.operationType === "Delete")
                                ?
                                Constants.ALERT_TYPE_WARNING
                                :
                                Constants.ALERT_TYPE_INFO
                            }
                            alertDialogHeading={
                                (this.state.operationType === "Delete")
                                ?
                                "Delete User Department"
                                :
                                "Activate User Department"
                            }
                            alertDialogMessage={this.state.alertDialogMessage}
                            proceedBtnClick={this.deleteActivateUserDept}
                            proceedBtnLabel={
                                (this.state.operationType === "Delete")
                                ?
                                "Delete"
                                :
                                "Reactivate"
                            }
                            showLoader={this.state.showBtnLoader}
                            themeSettings = {themeSettings}
                        />

                         <AlertDialog 
                            showAlertDialog={this.state.showAlertDialogInfo}
                            handleAlertDialogClose={this.handleAlertDialogCloseInfo}
                            type= {Constants.ALERT_TYPE_ALERT}
                            alertDialogMessage={this.state.alertDialogMessageInfo}
                            proceedBtnClick={this.handleAlertDialogCloseInfo}
                            proceedBtnLabel={ Constants.ALERT_TYPE_OKAY_LABEL }
                            themeSettings = {themeSettings}
                        />

                        <Modal className="custom-dialog" show={this.state.showCreateDialog} 
                        backdrop="static">
                            <Modal.Header>
                                <h5>{this.state.isEdit?"Edit Department":"Add New Department"}</h5>
                                <button 
                                    onClick={this.handleCreateDialogClose}
                                    type="button" data-dismiss="modal">
                                    <span class="material-icons">close</span>
                                </button>
                            </Modal.Header>
                            <hr />
                            <div class="modal-body">
                                <div class="addnew-modal-form">
                                    <div class="addnew-modal-form-group">
                                        <p>Short Name*</p>
                                        <input type="text" placeholder="Enter Short Name" ref={this.textInput} 
                                        name="shortName" value={this.state.shortName} onChange={this.handleChange}/>
                                        {
                                            !isEmptyVariable(this.state.formErrors['shortName']) &&
                                            <span class="cm-error">{this.state.formErrors['shortName']}</span>
                                        }
                                    </div>
                                    <div class="addnew-modal-form-group">
                                        <p>Name*</p>
                                        <input type="text" placeholder="Enter Name" spellCheck="true" 
                                        name="name" value={this.state.name} onChange={this.handleChange}/>
                                        {
                                            !isEmptyVariable(this.state.formErrors['name']) &&
                                            <span class="cm-error">{this.state.formErrors['name']}</span>
                                        }
                                    </div>
                                    <div class="addnew-modal-form-group">
                                        <p>Description</p>
                                        <textarea type="text" placeholder="Enter Description" spellCheck="true" 
                                        name="description" value={this.state.description} onChange={this.handleChange}/>
                                        {
                                            !isEmptyVariable(this.state.formErrors['description']) &&
                                            <span class="cm-error">{this.state.formErrors['description']}</span>
                                        }
                                    </div>
                                </div>
                                <div class="modal-close-save">
                                    <div class="close-save-col">
                                        <button onClick={this.handleCreateDialogClose} type="button" class="modal-close">Cancel</button>
                                        <DialogSaveButton 
                                            themeSettings = {themeSettings}
                                            onClick={this.handleCreateDepartment} type="button">Save</DialogSaveButton>
                                    </div>
                                </div>
                            </div>

                        </Modal>

                        <Modal className="custom-dialog" show={this.state.showDetailsDialog} onHide={this.handleDetailsDialogClose}>
                            <Modal.Header>
                                <h5>{this.state.selectedItem.shortName}</h5>
                                <button 
                                    onClick={this.handleDetailsDialogClose}
                                    type="button" data-dismiss="modal">
                                    <span class="material-icons">close</span>
                                </button>
                            </Modal.Header>
                            <hr />
                            <div class="modal-body">
                                <div className="details-table-layout">
                                    <div className="row custom-row">
                                        <div className="col-md-12 custom-col">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td width={"24%"}>Short Name</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.state.selectedItem.shortName}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Name</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.state.selectedItem.name}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Description</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.state.selectedItem.description}</td>
                                                    </tr>
                                                    <tr>
                                                        <td width={"24%"}>Users Count</td>
                                                        <td width={"4%"}>:</td>
                                                        <td width={"72%"}>{this.state.selectedItem.deptUserCount}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </section>
                </main>
            </Router>
        );
    }
}

export default UserDepartments;